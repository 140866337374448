import React, { useEffect } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../reducers";
import { AddressRow } from "../customers/AddressRow";
import { entityByID } from "@rpforms/shared";

const EntityInfoModal = ({ id, entityInfoModalShow, setEntityInfoModalShow }) => {
    const entity = useSelector<RootState, any>((store) => {
        return store.entities.entities.find((e) => e.id === id);
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (entityInfoModalShow) {
            dispatch(entityByID(id));
        }
    }, [entityInfoModalShow]);

    return (
        <Modal
            show={entityInfoModalShow === id}
            onHide={() => {
                setEntityInfoModalShow(0);
            }}
        >
            {entity && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{entity.name} – Adressen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col sm={12}>
                            {entity.address && <AddressRow address={entity.address} slim={false} />}
                            {!entity.address && <>keine Adresse eingetragen</>}
                        </Col>
                    </Modal.Body>
                </>
            )}
            {!entity && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Wird geladen...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Spinner animation={"border"} />
                    </Modal.Body>
                </>
            )}
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setEntityInfoModalShow(0);
                    }}
                >
                    schließen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withRouter(EntityInfoModal);
