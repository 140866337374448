import { Editor } from "@tinymce/tinymce-react";
import { closeEditor } from "@rpforms/shared/build/actions/editor";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

interface IModalFolderNoteEditorProps {
    editor: IEditor;
    next: (payload: any) => void;
}

export const ModalFolderNoteEditor = ({ editor, next }: IModalFolderNoteEditorProps) => {
    const isNew = editor.config?.new;
    const note = editor.config.preload?.note;
    const folderId = note?.folder_id ?? editor.config.preload?.folder?.id;

    const dispatch = useDispatch();
    const editorRef = useRef<any>(null);

    const [title, setTitle] = useState(editor.config.preload?.note?.title ?? "");

    const close = () => {
        dispatch(closeEditor());
    };

    const removeHTMLTags = (text: string): string => {
        const textContent = document.createElement("div");
        textContent.innerHTML = text;
        // remove all html tags
        return textContent.textContent || textContent.innerText || ""; // Extract text content
    };

    const copyToClipboard = () => {
        if (!editorRef.current) return;
        const textContent = editorRef.current.getContent({ format: "text" });

        navigator.clipboard
            .writeText(textContent)
            .then(() => {
                toast.success("Inhalt kopiert", { autoClose: 2000 });
            })
            .catch((err) => {
                toast.error("Fehler beim Kopieren", { autoClose: 2000 });
            });
    };

    useEffect(() => {
        return () => {
            if (editorRef.current) {
                editorRef.current.remove();
                editorRef.current = null;
            }
        };
    }, []);

    const save = () => {
        if (!editorRef.current) return;
        const content = editorRef.current.getContent();
        next({
            ...editor.config.preload,
            folder_note: {
                id: note?.id,
                folder_id: folderId,
                title,
                content: content,
            },
        });
        toast.success("Änderungen gespeichert", { autoClose: 2000 });
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{isNew ? "Neue Notiz erstellen" : "Notiz bearbeiten"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>Name</label>
                <input
                    type="text"
                    className="mb-3 form-control"
                    placeholder="Name"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
                <label>Inhalt</label>
                <Editor
                    tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                    }}
                    initialValue={note?.content ?? ""}
                    init={{
                        height: 400,
                        width: "100%",
                        menubar: false,
                        plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        block_formats: "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;",
                        init_instance_callback: (editor) => {
                            if (!isNew) return;
                            editor.execCommand("mceToggleFormat", false, "h1");
                        },
                        setup: (editor) => {
                            let isDefaultFormat = isNew;

                            editor.on("keydown", (e) => {
                                if (e.key === "Enter" && isDefaultFormat) {
                                    setTimeout(() => {
                                        editor.execCommand("FormatBlock", false, "h1");
                                    }, 0);
                                }
                            });

                            editor.on("FormatApply", (e) => {
                                if (isDefaultFormat && e.format === "h1") return;
                                isDefaultFormat = false;
                            });
                        },
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={close}>
                    Schließen
                </Button>
                <Button variant="secondary" onClick={copyToClipboard}>
                    Inhalt kopieren
                </Button>
                <Button variant="primary" onClick={save}>
                    Änderungen speichern
                </Button>
            </Modal.Footer>
        </>
    );
};
