// @ts-ignore
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
// @ts-ignore
import { useFormSchema } from "@rpforms/shared/build/hooks/useFormSchema";
// @ts-ignore
import { SchemaEntry } from "@rpforms/shared/build/spec/SchemaEntry";
// @ts-ignore
import { fromEntries, htmlId, isEqual, templateVariables } from "@rpforms/shared/build/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useForm from "react-hook-form";
import { toast } from "react-toastify";

import styled from "styled-components";
import db from "../db";
import { AutoSaveComponent } from "./AutoSaveComponent";
import { Field } from "./Field";
import { usePreventAccidentalNavigation } from "../hooks/usePreventAccidentalNavigation";
import { ChatGptTrigger } from "./chatgpt/ChatGptTrigger";

export const Form = styled.form`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    width: 62%;
    min-height: 297mm;
    margin: 40px auto;
    position: relative;
    padding-bottom: 120px;
`;

export const FormPortrait = styled.form`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    max-width: 210mm;
    min-height: 297mm;
    margin: 40px auto;
    padding-bottom: 20px;
    position: relative;
    padding-bottom: 120px;
`;

export const FormLandscape = styled.form`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    min-width: 297mm;
    min-height: 210mm;
    margin: 40px auto;
    padding-bottom: 20px;
`;

export const FormTitle = styled.h2`
    padding: 0;
    margin: 0;
    max-width: 80%;
    word-break: break-word;
    hyphens: auto;
`;

export const FormHeader = styled.div`
    border-bottom: 1px solid #ddd;
    padding: 20px;
    margin: -40px -20px 40px;
    display: flex;
    border-bottom: 1px solid #ddd;

    justify-content: space-between;

    img {
        height: 80px;
    }
`;

export const FormContent = styled.div`
    padding-top: 25px;
    display: flex;
    flex-direction: column;
`;

export const FormFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #666;
    color: #fff;
`;

const FabButton = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #1a8ed7;
    color: #fff;
    font-size: 24px;
    transition: all linear 0.2s;
    cursor: pointer;
    i {
        position: relative;
        top: -2px;
    }

    :hover {
        transform: scale(1.1);
        box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.1);
    }
`;

const SNAPSHOT_SAVE_THRESHOLD = 1000 * 60 * 5;

export const FormRenderer = ({
    readonly = false,
    formEntry,
    conditionals = true,
    onSubmit,
    onChange = null,
    name = null,
}) => {
    const [snapshotApply, setSnapshotApply] = useState(false);
    const formRef = useRef();
    const { register, handleSubmit, errors, watch } = useForm();
    const { schema, config, ready } = useFormSchema(formEntry.form_revision.form_data);

    ((window as any) as any).__GLOBAL_CONDITIONALS = conditionals;

    const [initSnapshot, setInitSnapshot] = useState(false);
    const capture = async (snapshot) => {
        if (!formEntry.form_id || !formEntry.entity_id || !formEntry.customer_id) {
            return snapshot;
        }

        if ((window as any).skipSaveSnapshots) {
            return snapshot;
        }

        if (!(window as any).lastSave) {
            const oldSave = await db.snapshots
                .where({
                    form_id: formEntry.form_id,
                    entity_id: formEntry.entity_id,
                    customer_id: formEntry.customer_id,
                })
                .last();
            (window as any).lastSave = oldSave ? oldSave.created : +new Date() - 31;
        }

        if (+new Date() - (window as any).lastSave > SNAPSHOT_SAVE_THRESHOLD) {
            const toastId = toast("Speichern...", {
                position: "bottom-left",
            });
            const savePromise = await db.snapshots.put({
                form_id: formEntry.form_id,
                entity_id: formEntry.entity_id,
                customer_id: formEntry.customer_id,
                data: JSON.stringify(snapshot),
                created: +new Date(),
            });

            toast.update(toastId, {
                render: "Gespeichert.",
                type: "success",
                isLoading: false,
                autoClose: 300,
            } as any);

            (window as any).lastSave = +new Date();
        }

        // remove all snapshots older than 1 day
        await db.snapshots
            .where("created")
            .below(+new Date() - 86400000)
            .delete();

        return snapshot;
    };

    usePreventAccidentalNavigation([formRef.current, formEntry]);

    useEffect(() => {
        if (readonly) {
            return;
        }
        let snapshot = fromEntries(formRef.current);
        const formId = formEntry.form_id;

        const subscription = setInterval(() => {
            if (!onChange) {
                return;
            }

            const newSnapshot = fromEntries(formRef.current);

            if (!isEqual(snapshot, newSnapshot) && isEqual(formId, formEntry.form_id)) {
                const entry: any = new SchemaEntry(schema, newSnapshot);
                snapshot = newSnapshot;
                onChange(newSnapshot);
                capture(newSnapshot).catch((c) => console.error(c));
            }
        }, 100);
        if (!initSnapshot) {
            // notify pane one time
            setTimeout(() => {
                setInitSnapshot(true);
                onChange(snapshot);
            });
        }
        const unsubscribe = () => clearInterval(subscription);

        return unsubscribe; // unmount
    }, [formRef.current, formEntry]);

    const inputFields = useMemo(() => {
        return schema.fields.map((field) => {
            field.formName = htmlId(formEntry);
            return (
                <Field
                    readonly={readonly}
                    key={formEntry.id + "-" + field.hashCode}
                    field={field}
                    register={register}
                    initialValue={formEntry.form_data}
                    variables={templateVariables(formEntry)}
                />
            );
        });
    }, [snapshotApply, formEntry.form_revision.form_data, formEntry.entity, formEntry.customer]);

    const hasFooter = !!schema.fields.find((field) => field.fieldType === "Footer");
    const hasHeader = !!schema.fields.find((field) => field.fieldType === "Header");
    const applySnapshot = async (s) => {
        const snap = await db.snapshots.get(s.value);
        (window as any).skipSaveSnapshot = true;
        const data = JSON.parse(snap.data);
        setSnapshotApply(true);
        for (const key in data) {
            if (!formRef.current) {
                return;
            }
            if (!key.endsWith("-snapshot-slot")) {
                // skip snapshot-slot
                // @ts-ignore
                const field2: any = formRef.current!.querySelector(
                    `[name='${key}-snapshot-slot']`
                )!;
                if (field2!) {
                    field2.value = data[key];
                } else {
                    // @ts-ignore
                    const field: any = formRef.current!.querySelector(`[name='${key}']`)!;
                    if (field!) {
                        field.value = data[key];
                    }
                }
            }
        }
        setTimeout(() => {
            setSnapshotApply(false);
        }, 3000);
        (window as any).skipSaveSnapshot = false;
    };
    return (
        <div>
            <Form
                id={htmlId(formEntry)}
                ref={formRef}
                className={"full"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <AutoSaveComponent
                    formEntry={formEntry}
                    onApply={applySnapshot}
                    forForm={formRef}
                />
                {!hasHeader && (
                    <FormHeader>
                        <FormTitle>{name}</FormTitle>
                        <img src={((window as any) as any).LOGO_URL} />
                    </FormHeader>
                )}

                <FormContent>
                    <div className="d-flex flex-wrap">{inputFields}</div>
                </FormContent>

                {!hasFooter && (
                    <FormFooter>
                        <div className={"rpfe-footnote"}>
                            Die Prüfung wurde nach bestem Wissen und Gewissen gemäß
                            Herstellerangaben einschließlich der gültigen EN und DIN durchgeführt.
                            Für versteckte Mängel, die bei der Anwendung der erforderlichen Sorgfalt
                            nicht zu erkennen sind, wird eine Haftung ausgeschlossen. Weitere
                            Hinweise entnehmen Sie dem Prüfbuch, dem dieses Prüfprotokoll beizufügen
                            ist. Das Prüfbuch ist, sofern nötig, vom Betreiber zu pflegen und
                            aufzubewahren und auf Verlangen bereitzuhalten.
                        </div>
                    </FormFooter>
                )}
            </Form>
            <ChatGptTrigger>
                <FabButton>
                    <i className="fa fa-android"></i>
                </FabButton>
            </ChatGptTrigger>
        </div>
    );
};
