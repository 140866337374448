import React from "react";
import styled from "styled-components";

const ErrorBox = styled.div`
    p {
        color: #ff4444;
    }
    padding: 5px 10px;
    border-radius: 8px;
    display: inline-block;
    margin: 0 10px;
    .title,
    .title * {
        color: #000;
        margin: 0;
        padding: 0;
    }
`;

export class ErrorBoundary extends React.Component<any> {
    public static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    public state = {
        hasError: false,
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    public componentDidCatch(error, errorInfo) {
        debugger;
        console.error("error", error, errorInfo);
    }

    public render() {
        if (this.props.fallback) {
            return this.props.fallback;
        }

        if (this.state.hasError) {
            return (
                <ErrorBox>
                    <div
                        className={"title"}
                        dangerouslySetInnerHTML={{ __html: this.props.title || "" }}
                    ></div>
                    <p>{this.props.message}</p>
                </ErrorBox>
            );
        }

        return <>{this.props.children}</>;
    }
}
