import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class ImageTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Bildupload";
    public icon = "picture-o";
    public fieldType = "Bildupload";
    public namePattern = "";
    public attributeNames = [
        "namePattern",
        "allowedExtensions",
        "maxFiles",
        "allowMultiple",
        "onlyCamera",
    ];

    public getConfigurables() {
        return [Width];
    }

    public toHotColumn() {
        return {
            hashCodeRef: this.rawField.hashCode,
            type: "imageUpload",
            namePattern: this.namePattern,
        };
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label}
                    ref={register}
                />

                <label className="mt-3">Bildname Muster</label>
                <div className="text-muted">
                    Nach welchem Muster soll der Bildname festgelegt werden? Textbausteine können
                    verwendet werden.
                </div>

                <input
                    type={"text"}
                    name={field.inputName("namePattern")}
                    className="form-control"
                    defaultValue={field.namePattern || "{form:name}_{entity:name}.{file:extension}"}
                    ref={register}
                />
            </FieldWrapper>
        );
    }

    public InputComponent({
        schema,
        layout,
        field,
        entry = null,
        register,
        variables = { isPrinting: false },
    }) {
        if (variables.isPrinting) {
            return null;
        }
        return super.InputComponent({ schema, layout, field, entry, register });
    }
}
