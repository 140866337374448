import {
    fetchCustomers,
    fetchCustomersForAutocomplete,
    toSelectOptions,
    toSelectOptionsWithAll
} from "@rpforms/shared";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const CustomerFilter = ({
    onSelect,
    value = null,
    includeAll = "alle Kunden",
    showAll = true,
    ...props
}) => {
    const dispatch = useDispatch();
    const customers = useSelector<RootState, any>((store) => store.customers.autocomplete);

    useEffect(() => {
        if ((!customers.isLoading && !customers) || customers.length <= 0) {
            dispatch(
                fetchCustomersForAutocomplete(-1, (data) => {
                    onSelect(getDefaultValue(data));
                })
            );
        } else {
            onSelect(getDefaultValue(customers));
        }
    }, []);

    const getDefaultValue = (d) => {
        let dv = value;
        if (!Array.isArray(dv)) {
            dv = [dv];
        }
        const defaultValueIds = dv.filter((x) => x != null).map((x) => parseInt(x.value, 10));
        const arr = d.filter((c) => defaultValueIds.includes(c.id));
        const ret = props.isMulti ? toSelectOptions(arr) : toSelectOptionsWithAll(arr, includeAll);
        return props.isMulti ? ret : ret[0];
    };

    const onChange = (option) => {
        onSelect(option);
    };

    return (
        <Select
            onChange={onChange}
            options={
                props.isMulti
                    ? toSelectOptions(customers)
                    : toSelectOptionsWithAll(customers, includeAll)
            }
            value={value}
            placeholder={includeAll}
            {...props}
        />
    );
};
