import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
    createCalendarEventType,
    updateCalendarEventType,
} from "@rpforms/shared/build/actions/calendar_event_types";
import { withRouter } from "react-router";
import { SwatchesPicker } from "react-color";

const CalendarEventTypeModal = ({
    modalVisible,
    setModalVisible,
    type,
    setType,
}: {
    modalVisible: boolean;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    type?: any;
    setType?: any;
}) => {
    const closeModal = () => setModalVisible(false);
    const [name, setName] = useState("");
    const [color, setColor] = useState("");
    const dispatch = useDispatch();
    const handleSaveClick = () => {
        if (!type) {
            dispatch(createCalendarEventType({ name, color })).then(closeModal);
            return;
        }
        dispatch(updateCalendarEventType({ id: type.id, name, color })).then(closeModal);
        setType(null);
    };

    const handleOnColorChangeComplete = (color: { hex: string }) => {
        setColor(color.hex);
    };

    useEffect(() => {
        if (type) {
            setName(type.name);
            setColor(type.color);
        }
    }, [type]);

    return (
        <Modal show={modalVisible} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Typen {type ? "bearbeiten" : "erstellen"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <label>Name</label>
                    <br />
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div>
                    <br />
                    <div className="d-flex mb-1">
                        <label>Farbe</label>
                        <div
                            className="ml-2"
                            style={{
                                width: 30,
                                height: 30,
                                backgroundColor: color,
                                borderRadius: "5px",
                            }}
                        />
                    </div>
                    <div style={{ width: 300 }}>
                        <SwatchesPicker
                            color={color}
                            onChangeComplete={handleOnColorChangeComplete}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={handleSaveClick}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CalendarEventTypeModal;
