import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import {
    CREATE_CALENDAR_EVENT_PARTICIPANT,
    DELETE_CALENDAR_EVENT_PARTICIPANT,
    GET_CALENDAR_EVENT_PARTICIPANTS,
    UPDATE_CALENDAR_EVENT_PARTICIPANT,
} from "./types";

export function getCalendarEventParticipants() {
    return (dispatch: IDispatch<any>) => {
        axios
            .get(`${API_URL}/calendar_event_participants.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_CALENDAR_EVENT_PARTICIPANTS,
                    payload: response.data,
                });
            });
    };
}

export function createCalendarEventParticipant(type: any) {
    return (dispatch: IDispatch<any>) =>
        axios
            .post(
                `${API_URL}/calendar_event_participants.json`,
                {
                    calendar_event_type: type,
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                const data = response.data;
                dispatch({
                    payload: data,
                    type: CREATE_CALENDAR_EVENT_PARTICIPANT,
                });
            })
            .catch((err) => {
                throw err;
            });
}

export function updateCalendarEventParticipant(type: any) {
    return (dispatch: IDispatch<any>) =>
        axios
            .patch(
                `${API_URL}/calendar_event_participants/${type.id}.json`,
                {
                    calendar_event_type: type,
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: UPDATE_CALENDAR_EVENT_PARTICIPANT,
                });
            })
            .catch((err) => {
                throw err;
            });
}

export function deleteCalendarEventParticipant(type: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/calendar_event_participants/${type.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: DELETE_CALENDAR_EVENT_PARTICIPANT,
                });
                // toast.success("Das Event wurde erfolgreich gelöscht", { autoClose: 2000 });
                // dispatch(push("/calendar"));
            })
            .catch((err) => {
                toast.error("Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut", {
                    autoClose: 3000,
                });
                throw err;
            });
}
