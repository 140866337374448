import { getDevices } from "@rpforms/shared/build/actions/devices";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { Component, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import DeviceList from "../../../components/devices/DeviceList";
import { RootState } from "../../../reducers";

export const ListDevicePage = (props) => {
    const dispatch = useDispatch();
    const devices = useSelector<RootState, any>((store) => store.device.devices);

    useEffect(() => {
        dispatch(getDevices());
    }, []);

    useEffect(() => {
        props.setNumberOfEntries(devices.length);
    }, [devices]);

    return (
        <Content>
            <DeviceList devices={devices} />
        </Content>
    );
};

export default withRouter(ListDevicePage);
