import { FORMS_SYNC_SELECTION } from "@rpforms/shared";
import * as actions from "@rpforms/shared/build/actions/devices";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { Component, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { RootState } from "../../reducers";
import EditFormPage from "./EditFormPage";
import { FormsSyncModal } from "./FormsSyncModal";
import ListFormPage from "./ListFormPage";
import NewFormPage from "./NewFormPage";
import PreviewFormPage from "./PreviewFormPage";

const FormsPage = () => {
    const forms = useSelector<RootState, any>((state) => state.forms);
    const dispatch = useDispatch();
    const [syncModalVisible, setSyncModalVisible] = useState(false);

    return (
        <>
            <Switch>
                <Route path="/settings/protocols/forms/edit-:id"></Route>
                <Route path="/settings/protocols/forms/preview-:id"></Route>
                <Route path="/settings/protocols/forms/view-:id"></Route>
                <Route>
                    <PageNavbar
                        title="Formulare"
                        description="Hier können Sie angelegte Formulare verwalten und bearbeiten."
                    >
                        {!forms.syncSelectionEnabled && (
                            <PageNavAction
                                onClick={() => {
                                    dispatch({
                                        type: FORMS_SYNC_SELECTION,
                                        payload: true,
                                    });
                                }}
                                icon="check-square"
                                title="Markieren …"
                            />
                        )}
                        {forms.syncSelectionEnabled && (
                            <PageNavAction
                                onClick={() => {
                                    dispatch({
                                        type: FORMS_SYNC_SELECTION,
                                        payload: false,
                                    });
                                }}
                                icon="ban"
                                title="Abbrechen …"
                            />
                        )}
                        {forms.syncSelectionEnabled && (
                            <PageNavAction
                                danger={true}
                                onClick={() => {
                                    setSyncModalVisible(true);
                                }}
                                disabled={forms.syncForms.length <= 0}
                                icon="upload"
                                title="Sync"
                            />
                        )}
                        <PageNavAction
                            link="/settings/protocols/forms/new"
                            primary={true}
                            icon="plus"
                            title="Neues Formular"
                        />
                        <PageNavAction
                            link="/settings/protocols/forms"
                            icon="list"
                            title="Alle Formulare"
                        />
                    </PageNavbar>
                </Route>
            </Switch>
            <Switch>
                <Route path="/settings/protocols/forms/new" component={NewFormPage} />
                <Route path="/settings/protocols/forms/edit-:id" component={EditFormPage} />
                <Route path="/settings/protocols/forms/preview-:id" component={PreviewFormPage} />
                <Route path="/settings/protocols/forms/view-:id" component={PreviewFormPage} />
                <Route path="/settings/protocols/forms" exact component={ListFormPage} />
            </Switch>
            {syncModalVisible && (
                <FormsSyncModal
                    show={syncModalVisible}
                    setVisibility={(b) => {
                        setSyncModalVisible(b);
                    }}
                />
            )}
        </>
    );
};

function mapStateToProps(state) {
    return { state: state.device };
}

export default withRouter(connect(mapStateToProps, actions)(FormsPage));
