import { createForm } from "@rpforms/shared/build/actions/forms";
import renderField, { renderTextarea } from "@rpforms/shared/build/components/layout/Forms";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { RootState } from "../../reducers";
import { FormGroupSelect } from "./FormGroupSelect";

function validate(formProps) {
    const errors: any = {};

    if (!formProps.title) {
        errors.title = "Das Formular muss einen Namen haben.";
    }

    return errors;
}

const NewForm = ({ handleSubmit }) => {
    const [validated, setValidated] = useState(false);
    const [group, setGroup] = useState(null);

    const dispatch = useDispatch();
    const company = useSelector<RootState, any>((state) => {
        return state.company;
    });

    const handleFormSubmit = async (props) => {
        await dispatch(createForm(company, { ...props, ...group }));
        toast.success("✌️Formular erstellt", { autoClose: 2000 });
    };

    const changeGroup = (newGroup) => {
        setGroup({ form_group_id: newGroup.value });
    };

    const toggleValidation = () => {
        setValidated(!validated);
    };

    return (
        <div className="form-container">
            <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
                {/* Name */}
                <Field
                    name="name"
                    required="true"
                    component={renderField}
                    type="text"
                    placeholder="Name des Formulars"
                />

                <br />
                <br />
                <p>Gruppe</p>
                <FormGroupSelect onSelect={changeGroup} />

                <br />
                <br />
                {/* Name */}
                <p>Importiere ein bestehendes Formular</p>
                <Field
                    name="code"
                    required="true"
                    component={renderTextarea}
                    type="text"
                    placeholder="Import"
                />

                {/* Create button */}
                <button
                    type="submit"
                    onClick={() => {
                        toggleValidation();
                    }}
                    className="btn btn-primary"
                >
                    Weiter <i className="fa fa-chevron-right" />
                </button>
            </form>
        </div>
    );
};

export default withRouter(reduxForm({ form: "newform", validate })(NewForm));
