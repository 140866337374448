import { BootstrapLayout } from "@rpforms/shared/build/components/BootstrapLayout";
import { SchemaBuilder } from "@rpforms/shared/build/spec/SchemaBuilder";
import React, { useEffect } from "react";
import styled from "styled-components";
import { PDFPresenter } from "../PDFPresenter";

const PDFWrapper = styled.div`
    iframe {
        height: 1200px;
        margin-top: -40px;
        margin-left: -20px;
        margin-bottom: -25px;
        width: calc(100% + 40px);
        border: none;
    }
`;

const ViewPdfEntry = ({ entry, form, isPrinting }: any) => {
    useEffect(() => {
        if (!entry || !form) {
            return;
        }

        setTimeout(() => ((window as any).PDF_READY = !!(entry && form)), 3000);
    }, [entry, form]);

    const schemaBuilder = new SchemaBuilder();
    const formData =
        typeof entry.form_revision.form_data === "string"
            ? JSON.parse(entry.form_revision.form_data)
            : entry.form_revision.form_data;
    const saveState = entry.form_data;
    schemaBuilder.use(formData);
    const dataPresenter = schemaBuilder.dataPresenter(entry);

    return (
        <>
            <PDFPresenter
                layout={BootstrapLayout}
                entry={entry}
                schema={schemaBuilder}
                form={form}
                presenter={dataPresenter}
                saveState={saveState}
                isPrinting={isPrinting}
            />
        </>
    );
};

export default ViewPdfEntry;
