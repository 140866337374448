import { SmallButton, SmallDangerButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import moment from "moment";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ListRow } from "../../forms/FormListRow";

export const DeviceName = styled.div`
    font-weight: bold;
`;

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Dot = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${(props) => props.color || "#00F"};
    margin-right: 5px;
`;

const CalendarEventListRow = ({ customer, entity, event, device, type }: any) => {
    return (
        <>
            <ListRow>
                <div>
                    <TopRow>
                        <Dot color={type?.color} />
                        <DeviceName>{event.name}</DeviceName>
                    </TopRow>
                    <small>
                        Zeitraum:{" "}
                        <strong>
                            {" "}
                            {event.scheduled_from
                                ? `${moment(event.scheduled_from).format("DD.MM")} - ${moment(
                                      event.scheduled_to
                                  ).format("DD.MM")}`
                                : "Kein Zeitraum"}
                        </strong>
                        {" | "}
                        Gerät: <strong>{device?.name || "Nicht zugewiesen"}</strong>
                        {" | "}
                        Typ: <strong>{type?.name || "Ausführung"}</strong>
                        {" | "}
                        Kunde: <strong>{customer?.name}</strong>
                        {" | "}
                        Objekt: <strong>{entity?.name}</strong>
                    </small>
                </div>
                <RightAligned>
                    <Link
                        to={`/master_data/devices/view-${event.device_id}/event-${event.id}?material=true`}
                    >
                        {event.needs_material && (
                            <SmallDangerButton>Ersatzteile angekommen</SmallDangerButton>
                        )}
                    </Link>
                    <Link to={`/master_data/devices/view-${event.device_id}/event-${event.id}`}>
                        <SmallButton>Ansehen</SmallButton>
                    </Link>
                </RightAligned>
            </ListRow>
        </>
    );
};

export default withRouter(CalendarEventListRow);
