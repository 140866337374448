import { fetchDirectoryTree } from "@rpforms/shared/build/actions/folders";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TreeViewMenu from "react-simple-tree-menu";
import "react-simple-tree-menu/dist/main.css";

export const FolderTreeSelect = ({ onClickItem, folderId, parentFoldersUntil = "Folder" }) => {
    const dispatch = useDispatch();
    const tree = useSelector<any, any>((state) => state.directory.subTree);
    const treeIsLoading = useSelector<any, any>((state) => state.directory.subTreeIsLoading);

    useEffect(() => {
        if (!folderId) {
            return;
        }

        dispatch(fetchDirectoryTree(folderId, parentFoldersUntil));
    }, [folderId]);

    if (treeIsLoading) {
        return <Loader />;
    }

    return <TreeViewMenu data={tree} onClickItem={onClickItem} />;
};
