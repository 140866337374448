import { getFolderLocation, markNotificationAsRead } from "@rpforms/shared";
import {
    SmallButton,
    SmallDangerButton,
    SmallPrimaryButton,
} from "@rpforms/shared/build/components/layout/Button";
import { push } from "connected-react-router";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";

interface BellIconProps {
    total?: number;
}

const BellWrapper = styled.span`
    position: relative !important;
    display: inline-block !important;
    font-family: -apple-system, "Segoe UI", sans-serif !important;

    a {
        user-select: none !important;
        color: #212529 !important;
    }

    a:hover {
        transform: none !important;
        --webkit-transform: none !important;
        color: #16181b !important;
        text-decoration: none !important;
        background-color: #f8f9fa !important;
    }
`;

const Bell = styled.i<BellIconProps>`
    cursor: pointer;

    &::after {
        display: ${(props) => (props.total > 0 ? "block" : "none")};
        content: "${(props) => props.total}";
        position: absolute;
        background: #dc3545;
        height: 1.5rem;
        top: -0.5rem;
        left: 0.7rem;
        width: 1.5rem;
        text-align: center;
        line-height: 1.2rem;
        font-size: 0.8rem;
        border-radius: 50%;
        color: white;
        border: 1px solid #dc3545;
        z-index: 2;
        font-family: -apple-system, "Segoe UI", sans-serif;
    }
`;

const RowIcon = styled.div`
    margin-right: 1rem;
    color: #6e6e6e;

    small {
        margin-left: 0.5rem;
    }
`;

const DropdownButton = styled(Dropdown.Item)`
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 500;
    background: linear-gradient(to bottom, rgba(33, 150, 243, 0.8), #2196f3);
    color: #ffffff !important;

    &:hover,
    &:visited,
    &:focus {
        color: #ffffff !important;
    }
`;

function truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export const NotificationRow = ({ notification }) => {
    const dispatch = useDispatch();
    const dateOfNotification = () => {
        const currentDate = new Date().getTime();
        const notifDate = new Date(notification.created_at).getTime();
        const timeBetween = currentDate - notifDate;
        const daysBetween = Math.ceil(timeBetween / (1000 * 60 * 60 * 24));

        let message = "";

        if (daysBetween < 2) {
            message = "vor einem Tag";
        } else if (daysBetween > 7) {
            message = new Date(notification.created_at).toLocaleDateString("de-DE");
        } else {
            message = `vor ${daysBetween} Tagen`;
        }

        return message;
    };

    const actionLink = () => {
        switch (notification.resource_type) {
            case "Customer":
                dispatch(push(`/master_data/customers/view-${notification.resource.id}`));
                break;
            case "Entity":
                if (notification.url) {
                    dispatch(push(`${notification.url}`));
                } else {
                    dispatch(
                        push(`/master_data/customers/view-${notification.resource.customer_id}`)
                    );
                }
                break;
            case "FormEntry":
                dispatch(push(`/entries?ids=${notification.resource_id}`));
                break;
            case "EmployeeCert":
                dispatch(push(`/master_data/employees/view-${notification.resource.employee_id}`));
                break;
            case "Folder":
                if (notification.url) {
                    dispatch(push(`${notification.url}`));
                } else {
                    dispatch(
                        push(`/master_data/customers/view-${notification.resource.customer_id}`)
                    );
                }
                break;
        }
    };

    const actionData = () => {
        const result = {
            name: "Öffnen",
            icon: "",
        };
        switch (notification.resource_type) {
            case "Customer":
                result.name = "Kunde öffnen";
                result.icon = "fa fa-users-rectangle";
                break;
            case "Entity":
                result.name = "Objekt öffnen";
                result.icon = "fa fa-industry";
                break;
            case "FormEntry":
                result.name = "Protokoll öffnen";
                result.icon = "fa fa-file-text";
                break;
            case "EmployeeCert":
                result.name = "Mitarbeiteransicht öffnen";
                result.icon = "fa fa-user";
                break;
            case "Folder":
                result.name = "Ansehen";
                result.icon = "fa fa-table";
                break;
        }
        return result;
    };

    const actionLinkText = () => {
        const r = actionData();
        return (
            <>
                <i className={r.icon} /> {r.name}
            </>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}
            data-id={notification.id}
        >
            <i className="fa fa-file-text-o" />
            <RowIcon></RowIcon>
            <div style={{ flexGrow: 2 }}>
                <small style={{}}>{notification.message}</small>
            </div>
            <div>
                <SmallButton
                    onClick={() => {
                        actionLink();
                    }}
                >
                    {actionLinkText()}
                </SmallButton>
                {!["EmployeeCert"].includes(notification.resource_type) && (
                    <>
                        <SmallPrimaryButton
                            onClick={async () => {
                                const req = await dispatch(
                                    getFolderLocation({
                                        resource_id: notification.resource_id,
                                        resource_type: notification.resource_type,
                                    })
                                );
                                dispatch(
                                    push(
                                        "/folders?open=" +
                                            encodeURIComponent(JSON.stringify(req.data))
                                    )
                                );
                            }}
                        >
                            <i className="fa fa-folder-open" /> Ordnerstruktur
                        </SmallPrimaryButton>
                    </>
                )}
                <SmallDangerButton
                    onClick={async () => {
                        dispatch(markNotificationAsRead(notification.id));
                    }}
                >
                    Abschließen
                </SmallDangerButton>
                <small style={{ marginBottom: "-3px" }}>{dateOfNotification()}</small>
            </div>
        </div>
    );
};

export default withRouter(NotificationRow);
