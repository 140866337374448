import { moveFolders, setFolderSelection, setFolderSelectionItems } from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../reducers";
import { FolderIcon } from "./FolderIcon";
import { FolderTreeSelect } from "./FolderTreeSelect";

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    font-size: 16px !important;
    width: 100%;
    // align svg icons with fonts
    svg {
        position: relative;
        top: -2px;
    }
`;

export const UploadMover = ({ folder, setVisibility }) => {
    const { selection, selectionEnabled } = useSelector<RootState, any>((state) => state.directory);
    const [target, setTarget] = useState<any>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (typeof folder === "object" && folder?.id) {
            dispatch(setFolderSelectionItems([folder]));
            dispatch(setFolderSelection(true));
        }
    }, [folder]);

    const move = async () => {
        const folderIds = selection.map((f) => f.id);
        await dispatch(
            moveFolders({
                parent_folder_id: target.id,
                folder_ids: folderIds,
            })
        );
        setVisibility(false);
        window.location.reload(true);
    };

    return (
        <Modal
            show={folder}
            onHide={() => setVisibility(false)}
            size={"lg"}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <StyledModalTitle>
                    <div>
                        Verschiebe {selection.length} <FolderIcon height={16} width={16} /> Dateien
                    </div>
                </StyledModalTitle>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
                <Row style={{ padding: "10px 20px" }}>
                    <Col>Verschoben werden: {selection.map((f) => f.name).join(", ")}</Col>
                </Row>
                <Row style={{ padding: "20px" }}>
                    <Col>
                        <FolderTreeSelect
                            onClickItem={(item) => {
                                setTarget(item);
                            }}
                            folderId={selection.map((f) => f.parent_id)[0]}
                            parentFoldersUntil={"Folder"}
                        />
                    </Col>
                </Row>
                <div style={{ padding: "20px" }}>
                    <PrimaryButton disabled={!target?.upload_receivable} onClick={move}>
                        Verschieben
                    </PrimaryButton>
                    <br />
                </div>
            </Modal.Body>
        </Modal>
    );
};
