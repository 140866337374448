import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { hashCode, uniqueID } from "../../utils";
import { FRONTEND_URL } from "../../config";

export const LabelInput = ({ keyName = "label", register, field }) => {
    const uid = `LABEL_${field.hashCode}`;
    return (
        <div
            style={{
                marginLeft: "-20px",
                marginRight: "-20px",
                width: "calc(100% + 40px)",
            }}
        >
            <p style={{ padding: "0 20px" }}>Überschrift</p>

            <Editor
                tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
                onEditorChange={(a, editor) => {
                    try {
                        const data = editor.getContent();
                        (document.querySelector(`#${uid}`) as any).value = data;
                        (document.querySelector(`#${uid}`) as any).blur();
                    } catch (e) {
                        // tslint:disable-next-line:no-console
                        console.error("error: ", e);
                    }
                }}
                initialValue={field[keyName]}
                init={{
                    height: 150,
                    menubar: false,
                    statusbar: false,
                    plugins: [
                        "charmap",
                        "searchreplace",
                        "visualblocks",
                        "fullscreen",
                        "paste",
                        "code",
                        "custom_templates",
                        "link",
                        "table",
                        "lists",
                    ],
                    external_plugins: {
                        custom_templates: `${FRONTEND_URL}/tinymce/plugins/custom_templates.js`,
                    },
                    branding: false,
                    toolbar:
                        "undo redo | bold italic underline strikethrough backcolor removeformat | numlist bullist | table",
                }}
            />

            <input
                id={uid}
                type={"text"}
                style={{ display: "none" }}
                name={field.inputName(keyName)}
                className="form-control"
                defaultValue={field[keyName]}
                ref={register}
            />
        </div>
    );
};

export const Label = ({ layout, field }) => {
    const printLines = (text) => {
        return (
            <div className="rpfe-label">
                {text.split("\n").map((p) => {
                    return <p key={hashCode(p)} dangerouslySetInnerHTML={{ __html: p }}></p>;
                })}
            </div>
        );
    };
    return layout.wrapLabel(field.configureInput(<>{printLines(field.label)}</>));
};
