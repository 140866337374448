import { fetchEntitiesForAutocomplete } from "@rpforms/shared";
import { toSelectOptions } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const EntitySelect = ({
    entityValue = null,
    onSelect,
    filterBy = (entity) => {
        return true;
    },
    defaultValue = null,
    ...props
}) => {
    const [value, setValue] = useState(null);

    const entities = useSelector<RootState, any>((store) => store.entities);
    const defaultEntity = useSelector<RootState, any>((store) => {
        return typeof defaultValue === "number"
            ? toSelectOptions([store.entities.autocomplete.find((c) => c.id === defaultValue)])[0]
            : defaultValue;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (entities.autocompleteIsLoading) {
            return;
        }
        if (!entities.autocomplete || entities.autocomplete.length <= 0) {
            dispatch(
                fetchEntitiesForAutocomplete((entitiesData) => {
                    if (defaultValue != null) {
                        setValue(
                            toSelectOptions(entitiesData.filter((c) => c.id === defaultValue))[0]
                        );
                    }
                })
            );
        } else {
            if (defaultValue != null) {
                setValue(
                    toSelectOptions(entities.autocomplete.filter((c) => c.id === defaultValue))[0]
                );
            }
        }
    }, []);

    useEffect(() => {
        if (entities.autocompleteIsLoading || entityValue == null) {
            return;
        }
        if (!entities.autocomplete || entities.autocomplete.length <= 0) {
            dispatch(
                fetchEntitiesForAutocomplete((entitiesData) => {
                    if (defaultValue != null) {
                        setValue(
                            toSelectOptions(entitiesData.filter((c) => c.id === entityValue?.id))[0]
                        );
                    }
                })
            );
        } else {
            setValue(
                toSelectOptions(entities.autocomplete.filter((c) => c.id === entityValue?.id))[0]
            );
        }
    }, [entityValue, entities.autocompleteIsLoading]);

    const onChange = (x) => {
        setValue(x);
        onSelect(x);
    };

    return (
        <Select
            onChange={onChange}
            defaultValue={defaultEntity}
            options={toSelectOptions(entities.autocomplete.filter(filterBy))}
            value={value}
            placeholder={"Auswählen"}
            {...props}
        />
    );
};
