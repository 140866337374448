import axios from "axios";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { API_URL, COMPANY_ID } from "../config";
import { defaultOpts, getCollection, user } from "./actions_helper";
import {
    ADD_DEVICE,
    DELETE_DEVICE,
    DELETE_DEVICE_TASK,
    DEVICES_AUTOCOMPLETE,
    DEVICES_AUTOCOMPLETE_START,
    GET_DEVICES,
    LOADING_START,
    LOADING_STOP,
    UPDATE_DEVICE,
} from "./types";

/**
 * Fetch all users
 */
export function getDevices() {
    return (dispatch: IDispatch<any>) => {
        dispatch({ type: LOADING_START });
        axios
            .get(`${API_URL}/devices.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_DEVICES,
                    payload: response.data,
                });

                dispatch({ type: LOADING_STOP });
            });
    };
}

export function deviceByID(id: number) {
    return (dispatch: IDispatch<any>) =>
        axios
            .get(`${API_URL}/devices/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({ type: ADD_DEVICE, payload: response.data });
            })
            .catch((err) => {
                throw err;
            });
}

export function createDevice(device: any) {
    return (dispatch: IDispatch<CallHistoryMethodAction<any>>) =>
        axios
            .post(
                `${API_URL}/devices.json`,
                {
                    device: {
                        ...device,
                        company_id: COMPANY_ID,
                    },
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                dispatch(push(`/master_data/devices/view-${response.data.id}/`));
            })
            .catch((err) => {
                throw err;
            });
}

export function deleteDeviceTask(opts: any) {
    return (dispatch: IDispatch<any>) => {
        if (!window.confirm("Sind Sie sicher diese Aufgabe vom iPad zu entfernen?")) {
            return;
        }

        axios
            .delete(`${API_URL}/devices/${opts.device_id}/device_tasks/${opts.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_DEVICE_TASK,
                    payload: response.data,
                });

                getDevices()(dispatch);
            });
    };
}

export function deleteDeviceTasks(opts: any) {
    return (dispatch: IDispatch<any>) => {
        if (!window.confirm("Sind Sie sicher diese Aufgabe vom iPad zu entfernen?")) {
            return;
        }

        axios
            .delete(`${API_URL}/devices/${opts.device_id}/device_tasks/${opts.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_DEVICE_TASK,
                    payload: response.data,
                });

                getDevices()(dispatch);
            });
    };
}

export function changeDeviceCalendarColor(device, color) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.patch(
            `${API_URL}/devices/${device.id}.json`,
            {
                device: {
                    ...device,
                    calendar_color: color,
                },
            },
            { headers: { authorization: user().token } }
        );
        dispatch({
            type: UPDATE_DEVICE,
            payload: response.data,
        });
    };
}

export function deleteDevice(device: IDevice) {
    return (dispatch: IDispatch<IReduxAction<JSON>>) => {
        axios
            .delete(`${API_URL}/devices/${device.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_DEVICE,
                    payload: response.data,
                });
            });
    };
}

export function fetchDevices(page = 1, cb: any, opts = defaultOpts, pageSize = "all") {
    opts.page_size = pageSize;
    opts.page = page;
    return getCollection("devices", opts, cb);
}
export function fetchDevicesForAutocomplete() {
    return (dispatch) => {
        dispatch({
            type: DEVICES_AUTOCOMPLETE_START,
        });
        return axios
            .get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/devices/`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DEVICES_AUTOCOMPLETE,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            });
    };
}
