import { SuccessButton } from "@rpforms/shared";
import {
    createEmployeeSpecialVacation,
    deleteEmployeeSpecialVacation,
    updateEmployeeSpecialVacation,
} from "@rpforms/shared/build/actions/employees";
import {
    SmallDangerButton,
    SmallPrimaryButton,
    SmallSuccessButton,
} from "@rpforms/shared/build/components/Button";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import { useDispatch } from "react-redux";

export const EmployeeSpecialVacation = ({ create, editable = false, specialVacation }) => {
    const dispatch = useDispatch();
    const [editing, setEditing] = useState(false);
    const [startDate, setStartDate] = useState(
        specialVacation?.extra_vac_from
            ? moment(specialVacation?.extra_vac_from, "YYYY-MM-DD")
            : null
    );
    const [endDate, setEndDate] = useState(
        specialVacation?.extra_vac_to ? moment(specialVacation.extra_vac_to, "YYYY-MM-DD") : null
    );
    const [focusedInput, setFocusedInput] = useState(null);

    const [freeVacationDays, setFreeVacationDays] = useState(null);
    const [extraVac, toggleExtraVac] = useState(specialVacation?.has_extra_vac);
    const [extraVacName, setExtraVacName] = useState(specialVacation?.extra_vac_name);
    const [data, setCert] = useState({
        ...specialVacation,
        extra_vac_to: endDate,
        extra_vac_from: startDate,
    });

    const inputHandle = (name) => {
        return {
            onChange(e) {
                setCert({
                    ...data,
                    [name]: e.target.value,
                });
            },
        };
    };

    const createCert = () => {
        dispatch(createEmployeeSpecialVacation(data));
    };
    const editCert = () => {
        dispatch(updateEmployeeSpecialVacation(data));
    };
    const deleteCert = () => {
        dispatch(deleteEmployeeSpecialVacation(data));
    };

    if (!create && !editing) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                }}
            >
                <strong>{specialVacation.extra_vac_name}</strong> /{" "}
                {moment(specialVacation.extra_vac_from).format("DD.MM.YYYY")} -{" "}
                {moment(specialVacation.extra_vac_to).format("DD.MM.YYYY")}
                {editable && (
                    <SmallPrimaryButton onClick={() => setEditing(!editing)}>
                        Bearbeiten
                    </SmallPrimaryButton>
                )}
                {editable && (
                    <SmallDangerButton onClick={() => deleteCert()}>Löschen</SmallDangerButton>
                )}
            </div>
        );
    }

    return (
        <div>
            <div
                style={{
                    padding: "10px",
                    background: "#fff",
                    border: "1px solid #ccc",
                    maxWidth: "950px",
                }}
            >
                <h1 style={{ fontSize: "20px" }}>{specialVacation ? specialVacation.title : ""}</h1>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "30px",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ margin: "30px 0" }}>
                        <div
                            style={{
                                padding: "20px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <label>Freitext Feld</label>
                                <input
                                    style={{
                                        minHeight: "40px",
                                        width: "100%",
                                        marginRight: "20px",
                                    }}
                                    {...inputHandle("extra_vac_name")}
                                    defaultValue={specialVacation.extra_vac_name}
                                />
                            </div>
                            <div
                                style={{
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                }}
                            >
                                <label>Zeitraum</label>
                                <DateRangePicker
                                    startDate={data.extra_vac_from || null} // momentPropTypes.momentObj or null,
                                    startDateId="scheduled_from" // PropTypes.string.isRequired,
                                    endDate={data.extra_vac_to || null} // momentPropTypes.momentObj or null,
                                    endDateId="scheduled_to" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        setCert({
                                            ...data,
                                            extra_vac_from: startDate,
                                            extra_vac_to: endDate,
                                        });
                                    }} // PropTypes.func.isRequired,
                                    minimumNights={0}
                                    displayFormat={"DD.MM.YYYY"}
                                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={(xFocusedInput) =>
                                        setFocusedInput(xFocusedInput)
                                    } // PropTypes.func.isRequired,
                                />
                            </div>
                            {create && (
                                <div>
                                    <label style={{ height: "30px" }}>&nbsp;</label>
                                    <SuccessButton
                                        style={{ position: "relative", top: "20px" }}
                                        onClick={createCert}
                                    >
                                        Anlegen
                                    </SuccessButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {!create && editing && (
                    <div>
                        <SmallPrimaryButton onClick={editCert}>Speichern</SmallPrimaryButton>
                        <span
                            style={{
                                width: "50px",
                                display: "inline-block",
                            }}
                        >
                            &nbsp;
                        </span>
                        <SmallDangerButton onClick={() => deleteCert()}>Löschen</SmallDangerButton>
                    </div>
                )}
            </div>
            <div></div>
        </div>
    );
};

export default EmployeeSpecialVacation;
