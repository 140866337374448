import React from "react";
import styled from "styled-components";
import { FieldWrapper } from "../components/FieldWrapper";
import { uniqueID } from "../utils";
import { BaseField } from "./BaseField";

const FooterWrapper = styled.div`
    color: #29338a;
    font-size: 8px;
    text-align: center;
    page-break-inside: avoid;
    break-inside: avoid;
    position: static;
    width: 100%;
    img {
        height: 72px;
        position: relative;
        top: -2px;
    }
    p {
        margin: 0;
        padding: 0;
    }
`;

const Sep = styled.span`
    color: #00b3d0;
    font-weight: bold;
    font-size: 13px;
`;

export class FooterField extends BaseField {
    public name = "";
    public label = "";
    public hashCode = uniqueID();
    public icon = "minus";
    public fieldType = "Footer";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                Hier lässt sich nichts bearbeiten.
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({ layout, schema, field, entry = null, register, name = field.name }) {
        return <></>;
    }

    /**
     * Do not wrap element with the usual divs which are required for layout
     */
    public WrapComponent({ layout, entry, schema, field, component }) {
        return <>{component}</>;
    }
}
