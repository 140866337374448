import { PrimaryButton } from "@rpforms/shared";
import { SmallButton, SmallPrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import EmployeeCertificate from "./EmployeeCertificate";
import { EmployeeSpecialVacation } from "./EmployeeSpecialVacation";

export const EmployeeSpecialVacations = ({ employeeId }) => {
    const [showForm, setShowForm] = useState(false);
    const employee = useSelector<RootState, any>((state) =>
        state.employees.employees.find((e) => e.id == parseInt(employeeId))
    );

    return (
        <div>
            <h2 style={{ marginTop: "60px" }}>Anderweitige Abwesenheiten</h2>
            <div>
                {employee.employee_special_vacations.map((cert) => {
                    return (
                        <EmployeeSpecialVacation
                            specialVacation={cert}
                            create={false}
                            editable={true}
                        />
                    );
                })}
            </div>
            {showForm && (
                <div>
                    <EmployeeSpecialVacation
                        specialVacation={{ employeeId: employeeId }}
                        create={true}
                    />
                </div>
            )}
            <div style={{ marginTop: "40px" }}>
                <PrimaryButton onClick={() => setShowForm(true)}>Neu hinzufügen</PrimaryButton>
            </div>
        </div>
    );
};

export default EmployeeSpecialVacations;
