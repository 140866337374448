import { default as React, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { FinancesButtonContainer, FinancesSelectorButton } from "./FinancesInvoiceTypeSelector";

import { FINANCES_CHANGE_INVOICE_TYPE, getRevenues } from "@rpforms/shared";
import { FinancesType } from "../../pages/finances/FinancesPage";

export const FinancesOfferTypeSelector = ({ maxDate }: { maxDate: number }) => {
    const [customerId, setCustomerId] = useState(null);
    const [entityId, setEntityId] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const {
        customer_id,
        entity_id,
        offerType,
        selectedYear,
        selectedMonth,
        selectedDay,
    } = useSelector<RootState, any>((state) => state.finances);

    useEffect(() => {
        setCustomerId(customer_id);
        setEntityId(entity_id);
        setYear(selectedYear);
        setMonth(selectedMonth);
        setDay(selectedDay);
    }, [customer_id, entity_id, selectedYear, selectedMonth, selectedDay]);
    const dispatch = useDispatch();

    const handleClick = (buttonId, offerType) => {
        dispatch({ type: FINANCES_CHANGE_INVOICE_TYPE, payload: { buttonId, offerType } });
        dispatch(
            getRevenues(
                null,
                customerId,
                entityId,
                year || new Date().getFullYear(),
                month,
                day,
                FinancesType.OFFER,
                offerType
            )
        );
    };

    return (
        <FinancesButtonContainer style={{ margin: "2rem 0" }}>
            <FinancesSelectorButton active={offerType === ""} onClick={() => handleClick(0, null)}>
                Gesamt
            </FinancesSelectorButton>
            <FinancesSelectorButton
                active={offerType === "beauftragt"}
                onClick={() => handleClick(1, "beauftragt")}
            >
                Beauftragt
            </FinancesSelectorButton>
            <FinancesSelectorButton
                active={offerType === "notBeauftragt"}
                onClick={() => handleClick(2, "notBeauftragt")}
            >
                Nicht beauftragt
            </FinancesSelectorButton>
            <FinancesSelectorButton
                active={offerType === "withoutInvoice"}
                onClick={() => handleClick(4, "withoutInvoice")}
            >
                Beauftragt & Ausstehend
            </FinancesSelectorButton>
        </FinancesButtonContainer>
    );
};
