import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import { ConfigField } from "@rpforms/shared/build/fields/ConfigField";
import { useSchema } from "@rpforms/shared/build/hooks/useSchema";
import { SchemaEntry } from "@rpforms/shared/build/spec/SchemaEntry";
import React, { useEffect } from "react";
import useForm from "react-hook-form";
import styled from "styled-components";
import { ErrorBoundary } from "@rpforms/shared";

export const Form = styled.form`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    max-width: 210mm;
    min-height: 297mm;
    margin: 40px auto;
    padding-bottom: 20px;
`;

export const FormPortrait = styled.form`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    max-width: 210mm;
    min-height: 297mm;
    margin: 40px auto;
    padding-bottom: 20px;
    position: relative;
    padding-bottom: 120px;
`;

export const FormLandscape = styled.form`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    min-width: 297mm;
    min-height: 210mm;
    margin: 40px auto;
    padding-bottom: 20px;
`;

export const FormTitle = styled.h2`
    padding: 0;
    margin: 0;
    max-width: 80%;
    word-break: break-word;
    hyphens: auto;
`;

export const FormHeader = styled.div`
    border-bottom: 1px solid #ddd;
    padding: 20px;
    margin: -40px -20px 40px;
    display: flex;
    border-bottom: 1px solid #ddd;

    justify-content: space-between;
    img {
        height: 80px;
    }
`;

// tslint:disable-next-line:no-var-requires
const logoPath = require("../images/logo.svg");

/**
 * Presents a schemaBuilder in a way that users can submit entries
 * @constructor
 */
export const FormPresenter = ({ hideSubmit = false, layout, form, presenter }) => {
    const { schemaBuilder: schema, isLoading } = useSchema(form);
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // @ts-ignore
        const formData = fromEntries(document.querySelector(".form-container form"));

        const entry = new SchemaEntry(schema, formData);
        presenter.createEntry(entry);
        // remove prepopulated data
        schema.fields().map((field) => {
            localStorage.removeItem("_prefetch_" + field.hashCode);
        });
    };

    useEffect(() => {});

    useEffect(() => {
        if (!schema) {
            return;
        }
        schema.fields().map((field) => {
            field.wasRendered();
        });
    }, [schema]);

    let formID = "";
    let formName = "";
    if (form && form.name) {
        formID = form.name.split("_")[0];
        formName = form.name.replace(formID + "_", "");
    }

    if (!schema || isLoading) {
        return <Loader />;
    }

    const config = { layout: FormPortrait };
    // find a config block
    schema.fields().map((field) => {
        if (field instanceof ConfigField) {
            config.layout = field.displayLandscape === "on" ? FormLandscape : config.layout;
        }
    });

    const cssPrintPage = {
        [FormPortrait]: `@page { size: A4; }`,
        [FormLandscape]: `@page { size: A4 landscape; }`,
    };

    const styleSheet = document.createElement("style");
    styleSheet.innerHTML = cssPrintPage[config.layout];
    document.body.appendChild(styleSheet);

    (window as any).__GLOBAL_CONDITIONALS = true;
    (window as any).EDIT_MODE = true;

    if (schema.getConfig()) {
        formName = schema.getConfig().title;
    }

    const hasFooter = !!schema.fields().find((field) => field.fieldType === "Footer");
    const hasHeader = !!schema.fields().find((field) => field.fieldType === "Header");

    return (
        <config.layout onSubmit={handleSubmit(onSubmit)}>
            {!hasHeader && (
                <FormHeader>
                    <FormTitle>{formName}</FormTitle>
                    <img src={logoPath} />
                </FormHeader>
            )}

            <div className="d-flex flex-wrap">
                {schema.fields().map((field) => {
                    return (
                        <ErrorBoundary
                            title={field.label}
                            message={
                                "Dieses Feld ist fehlerhaft und es wurde zur Sicherheit entfernt."
                            }
                        >
                            <field.WrapComponent
                                layout={layout}
                                schema={schema}
                                field={field}
                                entry={null}
                                component={
                                    <field.InputComponent
                                        layout={layout}
                                        register={register}
                                        schema={schema}
                                        field={field}
                                        entry={null}
                                    />
                                }
                            />
                        </ErrorBoundary>
                    );
                })}
            </div>
            {!hasFooter && (
                <div className={"rpfe-footnote"}>
                    Die Prüfung wurde nach bestem Wissen und Gewissen gemäß Herstellerangaben
                    einschließlich der gültigen EN und DIN durchgeführt. Für versteckte Mängel, die
                    bei der Anwendung der erforderlichen Sorgfalt nicht zu erkennen sind, wird eine
                    Haftung ausgeschlossen. Weitere Hinweise entnehmen Sie dem Prüfbuch, dem dieses
                    Prüfprotokoll beizufügen ist. Das Prüfbuch ist, sofern nötig, vom Betreiber zu
                    pflegen und aufzubewahren und auf Verlangen bereitzuhalten.
                </div>
            )}
        </config.layout>
    );
};
