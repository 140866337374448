import { fetchCustomersForAutocomplete } from "@rpforms/shared";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { sort } from "@rpforms/shared/build/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomerList from "../../../components/customers/CustomerList";
import { RootState } from "../../../reducers";

export const ListCustomerPage = (props) => {
    const customers = sort(useSelector<RootState, any>((store) => store.customers.autocomplete));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCustomersForAutocomplete({ with: "terms" }));
    }, []);

    const untaggedCustomers = () => {
        return customers.filter(
            (e) => e.terms && e.terms.filter((t) => t.name === "Gekündigt").length <= 0
        );
    };

    useEffect(() => {
        props.setNumberOfEntries(untaggedCustomers().length);
    }, [untaggedCustomers]);

    return (
        <Content>
            <CustomerList customers={customers} />
        </Content>
    );
};

export default withRouter(ListCustomerPage);
