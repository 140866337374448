import { useMemo } from "react";
import { SchemaBuilder } from "../spec/SchemaBuilder";

export const useFormSchema = (formData) => {
    const data = typeof formData === "string" ? JSON.parse(formData) : formData;

    const manager: any = useMemo(() => {
        if (!data) {
            return new SchemaBuilder();
        }

        const builder = new SchemaBuilder();
        builder.use(data);
        return builder;
    }, [data]);

    const getFields = (prev: any[], cur: any) => {
        const children = cur.subfields.reduce(getFields, []);
        return [...prev, cur, ...children];
    };

    const config = useMemo(() => {
        if (!data) {
            return {};
        }
        const flattenedFields = data.fields.reduce(getFields, []);
        const configField = flattenedFields.find((field: any) => field.type === "ConfigField");
        return configField || {};
    }, [data]);

    return { schema: manager.schema, config, ready: !!manager.schema };
};
