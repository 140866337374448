import moment from "moment";
import "moment/locale/de";
import { Calendar, EventPropGetter, View, momentLocalizer } from "react-big-calendar";
import React from "react";
import { push } from "connected-react-router";

const baseEventProps = {
    borderRadius: "0px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "flex",
    fontSize: "13px",
};

interface BaseCalendarProps {
    onSelectEvent: any;
    events: any[];
    views?: View[];
    defaultView: View;
    onRangeChange?: any;
    eventPropGetter?: any;
}

export const BaseCalendar = ({
    onSelectEvent,
    events,
    views,
    defaultView,
    onRangeChange,
    eventPropGetter,
}: BaseCalendarProps) => {
    const localizer = momentLocalizer(moment);

    const mergedEventPropGetter = (event, start, end, isSelected) => {
        const props = eventPropGetter(event, start, end, isSelected);
        return {
            style: {
                ...baseEventProps,
                ...props,
            },
        };
    };
    return (
        <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            defaultView={defaultView}
            onRangeChange={onRangeChange}
            style={{ height: 1000 }}
            eventPropGetter={mergedEventPropGetter}
            views={views ?? ["month", "week", "day", "agenda"]}
            messages={{
                date: "Datum",
                time: "Zeit",
                month: "Monat",
                week: "Woche",
                day: "Tag",
                today: "Heute",
                previous: "Zurück",
                next: "Weiter",
                noEventsInRange: "Es gibt keine Termine in diesem Zeitraum.",
            }}
            onSelectEvent={onSelectEvent}
            events={events}
        />
    );
};

export const onSelectVacationEvent = (event, dispatch) => {
    dispatch(push(`/master_data/employees/view-${event.employee_id}`));
};

export const onSelectRegularCalendarEvent = (event) => {
    window.location.href = event.was_submitted
        ? `/master_data/devices/view-${event.device_id}/event-${event.id}?submitted=true`
        : `/master_data/devices/view-${event.device_id}/event-${event.id}`;
};

export const mapVacationsFromEmployeeForCalendar = (employees) => {
    return employees
        .map((e) =>
            [
                ...e.vacations.map((vacation) => ({
                    employee_id: e.id,
                    title: `${e.name} | Freigegeben: ${vacation.permitted ? "ja" : "nein"}`,
                    start: moment(vacation.from).toDate(),
                    end: moment(vacation.to).endOf("day").toDate(),
                    color: e.color,
                    permitted: vacation.permitted,
                    calendar_event_type_id: -1,
                })),
                ...e.employee_special_vacations.map((vacation) => ({
                    employee_id: e.id,
                    title: `${e.name} | ${vacation.extra_vac_name}`,
                    start: moment(vacation.extra_vac_from).toDate(),
                    end: moment(vacation.extra_vac_to).endOf("day").toDate(),
                    color: e.color,
                    permitted: true,
                    calendar_event_type_id: -1,
                })),
            ].filter((v) => !!v)
        )
        .flat();
};
