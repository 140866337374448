import { fetchFormGroups, FORMS_SYNC, getForms } from "@rpforms/shared";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormList from "../../components/forms/FormList";
import { RootState } from "../../reducers";
import ListFormGroups from "./ListFormGroups";

const ListFormPage = () => {
    const forms = useSelector<RootState, any>((state) => state.forms);
    const ungroupedForms = useSelector<any, any>((state) =>
        state.forms.forms.filter((f) => {
            return !f.form_group;
        })
    );
    const groups = useSelector<RootState, any>((state) => state.formGroups);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getForms(() => {}, { for_overview: true }));
        dispatch(fetchFormGroups());
    }, []);

    return (
        <Content>
            {forms.isLoading ? (
                <Loader />
            ) : (
                <>
                    {forms.syncSelectionEnabled && (
                        <>
                            <input
                                type="checkbox"
                                id={"sync-check-all"}
                                style={{ margin: "10px" }}
                                onClick={(ev) => {
                                    if (ev.currentTarget.checked) {
                                        dispatch({
                                            type: FORMS_SYNC,
                                            payload: forms.forms.map((f) => f.id),
                                        });
                                    } else {
                                        dispatch({
                                            type: FORMS_SYNC,
                                            payload: [],
                                        });
                                    }
                                }}
                            />
                            <label htmlFor="sync-check-all">alle markieren</label>
                        </>
                    )}
                    <ListFormGroups groups={groups.items} />
                    {ungroupedForms.length > 0 && (
                        <div>
                            <h2>Ungruppiert</h2>
                            <FormList forms={ungroupedForms} />
                        </div>
                    )}
                </>
            )}
        </Content>
    );
};

export default ListFormPage;
