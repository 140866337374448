import { getCollection } from "./actions_helper";

export function fetchNotificationGroups(
    page = 1,
    cb: any,
    clear = false,
    opts = {
        page_size: undefined,
        page: undefined,
        hidden_fields: undefined,
        state: undefined,
    },
    pageSize = 10
) {
    opts.page_size = pageSize;
    opts.page = page;
    opts.hidden_fields = "notifications,entity,form,form_data";
    return getCollection("notification_groups", opts, cb);
}
