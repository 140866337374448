import { deleteEntity } from "@rpforms/shared";
import { getFolderLocation } from "@rpforms/shared/build/actions";
import {
    SmallButton,
    SmallDangerButton,
    SmallPrimaryButton,
} from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { RootState } from "../../reducers";
import { ListRow } from "../forms/FormListRow";
import EntityFormEntries from "./EntityFormEntries";

export const EntityName = styled.div`
    font-weight: bold;
`;

const EntityListRow = ({ entity }: any) => {
    const dispatch = useDispatch();
    const customers = useSelector<RootState, any>((state) => state.customers);
    const customer = customers.autocomplete.find((c) => c.id === entity.customer_id);
    const [entries, setEntries] = useState(false);

    const openInFolder = async () => {
        const req: any = await dispatch(
            getFolderLocation({ resource_id: entity.id, resource_type: "Entity" })
        );
        dispatch(push("/folders?open=" + encodeURIComponent(JSON.stringify(req.data))));
    };

    const confirmAction = (cb) => {
        if (confirm("Sind Sie sich sicher dieses Objekt zu löschen?")) {
            cb();
        }
    };

    const deleteEntityAction = () => {
        dispatch(deleteEntity(entity));
    };

    return (
        <>
            <ListRow>
                <div>
                    <EntityName>
                        {entity.name}
                        {entity.terms &&
                            entity.terms.map((term) => {
                                return (
                                    <Badge
                                        key={`${entity.id}_${term.id}`}
                                        className="mx-1"
                                        style={{
                                            backgroundColor:
                                                term.name === "Wartungsvertrag"
                                                    ? "#777"
                                                    : term.options.backgroundColor,
                                            color: term.options.color,
                                        }}
                                    >
                                        {term.name}
                                    </Badge>
                                );
                            })}
                    </EntityName>
                    <small>
                        Kunde:
                        {customer && (
                            <Link to={"/master_data/customers/view-" + customer.id}>
                                <strong> {customer.name} </strong>
                            </Link>
                        )}
                        {!customer && (
                            <>
                                <strong> - </strong>
                            </>
                        )}
                    </small>
                </div>
                <RightAligned>
                    {entity.customer_id && (
                        <Link
                            to={
                                entity.customer_id &&
                                `/master_data/customers/view-${entity.customer_id}` +
                                    `?entity=${entity.id}`
                            }
                        >
                            <SmallButton disabled={!entity.customer_id}>Ansehen</SmallButton>
                        </Link>
                    )}

                    <SmallButton active={entries} onClick={() => setEntries(!entries)}>
                        Einträge ansehen
                    </SmallButton>
                    <SmallPrimaryButton onClick={openInFolder}>
                        <i className="fa fa-folder-open" /> Ordnerstruktur
                    </SmallPrimaryButton>

                    <SmallDangerButton onClick={() => confirmAction(() => deleteEntityAction())}>
                        <i className={"fa fa-trash"}></i>
                    </SmallDangerButton>
                </RightAligned>
            </ListRow>
            {entries && <EntityFormEntries entity={entity}></EntityFormEntries>}
        </>
    );
};

function mapStateToProps(state) {
    return { state: state.entities };
}

export default withRouter(EntityListRow);
