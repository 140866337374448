import { toSelectOptions, toSelectOptionsWithAll } from "@rpforms/shared";
import { fetchStates } from "@rpforms/shared/build/actions/states";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const StateFilter = ({ onSelect, value = null, includeAll = "alle Stati", ...props }) => {
    const dispatch = useDispatch();
    const states = useSelector<RootState, any>((store) => store.states.data.states);

    useEffect(() => {
        if ((!states.isLoading && !states) || states.length <= 0) {
            dispatch(
                fetchStates(-1, (data) => {
                    onSelect(getDefaultValue(data.states));
                })
            );
        } else {
            onSelect(getDefaultValue(states));
        }
    }, []);

    const getDefaultValue = (d) => {
        let dv = value;
        if (!Array.isArray(dv)) {
            dv = [dv];
        }
        const defaultValueIds = dv.filter((x) => x != null).map((x) => x.value);
        const arr = d.filter((c) => defaultValueIds.includes(c.value));
        const ret = props.isMulti
            ? toSelectOptions(arr, "name", "value")
            : toSelectOptionsWithAll(arr, includeAll, "name", "value");
        return props.isMulti ? ret : ret[0];
    };

    const onChange = (option) => {
        onSelect(option);
    };

    return (
        <Select
            onChange={onChange}
            options={
                props.isMulti
                    ? toSelectOptions(states, "name", "value")
                    : toSelectOptionsWithAll(states, includeAll, "name", "value")
            }
            value={value}
            placeholder={includeAll}
            {...props}
        />
    );
};
