import {inventoryItemFields} from "@rpforms/shared";
import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {useSearchParam} from "react-use";
import InventoryItemTable from "./InventoryItemTable";

export const InventoryItemTableHead = ({onSelect, type = "inventoryItem"}) => {
    const initialPage = parseInt(useSearchParam("page"), 10) || 1;
    const initialPageSize = parseInt(useSearchParam("page_size"), 10) || -1;
    const [page, setPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const initialOrderBy = useSearchParam("order_by");
    const initialOrderDir = useSearchParam("order_dir");
    const [orderBy, setOrderBy] = useState(initialOrderBy || "name");
    const [orderDir, setOrderDir] = useState(initialOrderDir || "asc");
    const setQueryParam = (event) => {
        event.preventDefault();
        const params = {page, pageSize, orderBy, orderDir};
        const key = event.currentTarget.getAttribute("data-key");
        const v = event.currentTarget.getAttribute("data-value");
        setOrderBy(v);
        // toggle order direction if same order by context
        if (v === orderBy && orderDir === "asc") {
            params.orderDir = "desc";
            setOrderDir("desc");
        } else if (v === orderBy && orderDir === "desc") {
            params.orderDir = "asc";
            setOrderDir("asc");
        }
        params[key] = v;
        onSelect(params);
        return false;
    };

    return (
        <thead>
        <tr>
            {inventoryItemFields(type).map((header) => {
                if (!header.sortable) {
                    return <th key={header.name}>{header.name}</th>;
                }

                return (
                    <th key={header.name}>
                        <a
                            onClick={setQueryParam}
                            href=""
                            data-key="orderBy"
                            data-value={header.sortable}
                        >
                            {header.name}
                            &nbsp;
                            {orderBy === header.sortable && orderDir === "asc" && (
                                <i className={"fa fa-arrow-circle-up"}></i>
                            )}
                            {orderBy === header.sortable && orderDir === "desc" && (
                                <i className={"fa fa-arrow-circle-down"}></i>
                            )}
                        </a>
                    </th>
                );
            })}
            <th>Aktion</th>
        </tr>
        </thead>
    );
};

export default InventoryItemTableHead;
