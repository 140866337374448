import React from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { NEW_UPLOADS } from "@rpforms/shared";
import { Dispatch } from "redux";

const StyledDropDown = styled.div`
    margin-left: 8px;

    #dropdown-basic::after {
        display: none;
    }
`;

const uploadModal = (entry_id: number, dispatch: Dispatch<any>) => {
    dispatch(
        openEditor({
            endpoint: `form_entries/${entry_id}/upload`,
            endpointCompany: false,
            new: true,
            type: NEW_UPLOADS,
        })
    );
};

export default function EntryRowOptions({
    entry,
    migrate,
    previousPage,
    setShow,
    deleteEntry,
    template,
    regenerate,
    createEditTaskClick,
}) {
    const { regeneratingPDF } = useSelector<any, any>((state) => state.formEntries);
    const dispatch = useDispatch();
    return (
        <StyledDropDown>
            <Dropdown>
                <Dropdown.Toggle
                    id="dropdown-basic"
                    as="i"
                    style={{ cursor: "pointer", margin: "0 10px" }}
                >
                    <i className="fa fa-ellipsis-h" style={{ fontSize: "24px" }}></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        as={Link}
                        disabled={entry.cancellation}
                        to={"/entries/editor/entry-edit-" + entry.id + "?page=" + previousPage}
                    >
                        <i className="fa fa-pencil"></i>
                        {`Bearbeiten / Bewegen`}
                    </Dropdown.Item>

                    <Dropdown.Item onClick={migrate}>
                        <i className="fa fa-exchange" />
                        {` Verwenden`}
                    </Dropdown.Item>

                    <Dropdown.Item onClick={template}>
                        <i className="fa fa-pencil-square" />
                        {` als Vorlage nutzen`}
                    </Dropdown.Item>

                    {entry.pdf && (
                        <Dropdown.Item onClick={() => uploadModal(entry.id, dispatch)}>
                            <i className="fa fa-arrow-circle-up" />
                            {` Neue Datei hochladen`}
                        </Dropdown.Item>
                    )}

                    {regeneratingPDF ? (
                        <Dropdown.Item onClick={() => void 0}>
                            <Spinner animation={"border"} size={"sm"} /> Ein PDF wird noch
                            generiert...
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item onClick={regenerate}>
                            <i className="fa fa-file-pdf-o" />
                            {` PDF neu generieren`}
                        </Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={() => deleteEntry()} disabled={entry.cancellation}>
                        <i className="fa fa-trash" />
                        {` Löschen`}
                    </Dropdown.Item>
                    {entry.device_name !== "-" && (
                        <Dropdown.Item onClick={() => createEditTaskClick(entry.id)}>
                            <i className="fa fa-edit" />
                            {` Nachbearbeitung erstellen`}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </StyledDropDown>
    );
}
