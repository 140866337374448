import {
    deleteFormEntry,
    deleteUpload,
    setFolderSelection,
    setFolderSelectionItems,
} from "@rpforms/shared/build/actions";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import { API_URL } from "@rpforms/shared/build/config";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../reducers";
import { FolderMerger } from "./FolderMerger";

import { uniqueArray } from "@rpforms/shared/build/utils";
import { FolderMover } from "./FolderMover";
import { FolderSearch } from "./FolderSearch";
import { UploadMover } from "./UploadMover";

const FolderToolbarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 123px;
    > div {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;

export const FolderToolbar = ({ onSync, selectedFolder }) => {
    const dispatch = useDispatch();
    const { selection, selectionEnabled } = useSelector<RootState, any>((state) => state.directory);
    const [folderToMove, setFolderToMove] = useState(false);
    const [merger, setMergerVisible] = useState(false);
    const [uploadMover, setUploadMoverVisible] = useState(false);

    const toggleSelection = () => {
        dispatch(setFolderSelection(!selectionEnabled));
    };

    const resetSelection = () => {
        dispatch(setFolderSelection(false));
        dispatch(setFolderSelectionItems([]));
    };

    const moveSelection = () => {
        setFolderToMove(selection);
    };

    const moveUploadSelection = () => {
        setUploadMoverVisible(true);
    };

    const mergeSelection = () => {
        setMergerVisible(true);
    };

    const open = () => {
        window.open(`${API_URL}/uploads/${selectedFolder.resource.id}.json?redirect=1`);
    };

    const editFormEntry = () => {
        dispatch(push("/entries/entry-edit-" + selectedFolder.resource.id));
    };

    const removeUpload = () => {
        if (confirm("Sind Sie sicher? Diese Aktion kann nicht rückgängig gemacht werden.")) {
            dispatch(deleteUpload(selectedFolder.resource));
        }
    };

    const removeFormEntry = () => {
        if (confirm("Sind Sie sicher? Diese Aktion kann nicht rückgängig gemacht werden.")) {
            dispatch(deleteFormEntry(selectedFolder.resource));
        }
    };

    const openCustomer = () => {
        if (selectedFolder.resource_type === "Customer") {
            dispatch(push("/master_data/customers/view-" + selectedFolder.resource.id));
        } else {
            dispatch(push("/master_data/customers/view-" + selectedFolder.resource.customer_id));
        }
    };

    const sameSelectionResources = () => {
        return uniqueArray(selection.map((s2) => s2.resource_type)).length === 1;
    };

    // used for entities
    const onlyEntitiesSelected = () => {
        return (
            selectionEnabled &&
            selection.length > 0 &&
            selection.every((s) => s.movable && s.resource_type === "Entity") &&
            sameSelectionResources()
        );
    };

    // used for all folders below the entity level
    const onlyUploadsSelected = () => {
        return (
            selectionEnabled &&
            selection.length > 0 &&
            selection.every((s) => s.movable && s.resource_type !== "Entity") &&
            sameSelectionResources()
        );
    };

    const selectionMergable = () => {
        return (
            selectionEnabled &&
            selection.length > 1 &&
            selection.every((s) => s.mergable) &&
            sameSelectionResources()
        );
    };

    return (
        <FolderToolbarWrapper>
            <FolderSearch />
            <FolderMover folder={folderToMove} setVisibility={setFolderToMove} />
            <UploadMover folder={uploadMover} setVisibility={setUploadMoverVisible} />
            <FolderMerger folder={merger} setVisibility={setMergerVisible} />
            <div>
                {(selectedFolder.resource_type === "Customer" ||
                    selectedFolder.resource_type === "Entity") && (
                    <PageNavAction title="Ansehen" icon="eye" onClick={openCustomer} />
                )}

                {selectedFolder.resource_type === "Upload" && (
                    <PageNavAction title="Ansehen" icon="eye" onClick={open} />
                )}
                {selectedFolder.resource_type === "FormEntry" && (
                    <PageNavAction title="Ansehen" icon="eye" onClick={editFormEntry} />
                )}

                {!selectionEnabled && (
                    <PageNavAction title="Markieren" icon="th-list" onClick={toggleSelection} />
                )}
                {selectionEnabled && selection.length === 0 && (
                    <PageNavAction title="Markieren..." icon="th-list" onClick={toggleSelection} />
                )}
                {onlyEntitiesSelected() && (
                    <PageNavAction title="Verschieben..." icon="th-list" onClick={moveSelection} />
                )}
                {onlyUploadsSelected() && (
                    <PageNavAction
                        title="Verschieben..."
                        icon="th-list"
                        onClick={moveUploadSelection}
                    />
                )}
                {selectionMergable() && (
                    <PageNavAction
                        title="Zusammenführen..."
                        icon="th-list"
                        onClick={mergeSelection}
                    />
                )}

                {selectionEnabled && selection.length > 0 && (
                    <PageNavAction
                        danger={true}
                        title="Abbrechen"
                        icon="th-list"
                        onClick={resetSelection}
                    />
                )}

                <PageNavAction success={true} title="Sync" icon="repeat" onClick={onSync} />
            </div>
        </FolderToolbarWrapper>
    );
};
