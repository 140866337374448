import { CheckboxField } from "../fields/CheckboxField";
import { ColumnField } from "../fields/ColumnField";
import { ConfigField } from "../fields/ConfigField";
import { DateField } from "../fields/DateField";
import { EmailField } from "../fields/EmailField";
import { FooterField } from "../fields/FooterField";
import { GroupField } from "../fields/GroupField";
import { HeaderField } from "../fields/HeaderField";
import { HSpacerField } from "../fields/HSpacerField";
import { ImageField } from "../fields/ImageField";
import { IntegerField } from "../fields/IntegerField";
import { InvoicePositionsListField } from "../fields/InvoicePositionsListField";
import { LocationField } from "../fields/LocationField";
import { PageBreakField } from "../fields/PageBreakField";
import { ParagraphField } from "../fields/ParagraphField";
import { PasswordField } from "../fields/PasswordField";
import { PhonenumberField } from "../fields/PhonenumberField";
import { PictureField } from "../fields/PictureField";
import { RadioField } from "../fields/RadioField";
import { RepeaterField } from "../fields/RepeaterField";
import { RescheduleField } from "../fields/RescheduleField";
import { RowField } from "../fields/RowField";
import { ScriptField } from "../fields/ScriptField";
import { SeparatorField } from "../fields/SeparatorField";
import { SignatureField } from "../fields/SignatureField";
import { SpacerField } from "../fields/SpacerField";
import { StyleField } from "../fields/StyleField";
import { AutoCompleteTableRow } from "../fields/table/AutoCompleteTableRow";
import { CheckboxTableRow } from "../fields/table/CheckboxTableRow";
import { CustomRendererTableRow } from "../fields/table/CustomRendererTableRow";
import { DateTableRow } from "../fields/table/DateTableRow";
import { DropdownTableRow } from "../fields/table/DropdownTableRow";
import { ImageTableRow } from "../fields/table/ImageTableRow";
import { IndexTableRow } from "../fields/table/IndexTableRow";
import { NumericTableRow } from "../fields/table/NumericTableRow";
import { SelectTableRow } from "../fields/table/SelectTableRow";
import { TextTableRow } from "../fields/table/TextTableRow";
import { TimeTableRow } from "../fields/table/TimeTableRow";
import { TableField } from "../fields/TableField";
import { TextareaField } from "../fields/TextareaField";
import { TextField } from "../fields/TextField";
import { TitleField } from "../fields/TitleField";

/**
 * Map JSON string types into classes.
 */
export const FieldClassMapping = {
    TextField,
    TextareaField,
    RowField,
    ColumnField,
    RepeaterField,
    CheckboxField,
    RadioField,
    DateField,
    SignatureField,
    IntegerField,
    TitleField,
    ParagraphField,
    SeparatorField,
    PageBreakField,
    SpacerField,
    PictureField,
    PhonenumberField,
    PasswordField,
    IndexTableRow,
    LocationField,
    ImageField,
    EmailField,
    TableField,
    HSpacerField,
    HeaderField,
    GroupField,
    FooterField,
    AutoCompleteTableRow,
    CheckboxTableRow,
    ScriptField,
    StyleField,
    CustomRendererTableRow,
    ImageTableRow,
    DateTableRow,
    DropdownTableRow,
    NumericTableRow,
    SelectTableRow,
    TextTableRow,
    TimeTableRow,
    ConfigField,
    RescheduleField,
    InvoicePositionsListField,
};
