import { fetchFormEntriesAJAX } from "@rpforms/shared";
import { SmallSuccessButton } from "@rpforms/shared/build/components/Button";
import { map, uniq, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingContainer } from "../../../components/entries/FormEntries";
import { RootState } from "../../../reducers";

export const RepairForms = ({ entityId, entityName, onChange }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState([]);
    useEffect(() => {
        setLoading(true);
        fetchFormEntriesAJAX(entityId, null, [17, 21], { showData: true }).then((response) => {
            setForms(response.data.form_entries);
            setLoading(false);
        });
    }, []);
    return (
        <div>
            {entityName}
            <ul>
                {" "}
                {loading && <div>Laden...</div>}
                {forms.map((form) => {
                    return (
                        <li key={form.id}>
                            {form.name}{" "}
                            <SmallSuccessButton onClick={() => onChange(form)}>
                                Auswählen
                            </SmallSuccessButton>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
