import React from "react";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, MaxLength, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";
import { TextField } from "./TextField";

export class PhonenumberField extends TextField {
    public icon = "phone";
    public fieldType = "Telefonnr.";
    public inputType = "phone";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Required, Description, DefaultValue, Width, Hint];
    }
}
