import { useEffect } from "react";

const preventNavigation = (ev: Event) => {
    alert(
        "Achtung, Du bist dabei die Seite zu verlassen, bitte speichere erst die Änderungen an Deinem Formular"
    );
    ev.preventDefault();
    ev.stopPropagation();
    return false;
};

const lockedElementSelectors = [".main-nav", "[data-back-button]"];

const resolveSelectors = (selectors: string[]): HTMLElement[] => {
    return selectors
        .reduce((elements, selector) => {
            return [...elements, ...Array.from(document.querySelectorAll(selector))];
        }, [])
        .filter((s) => !!s);
};

export const usePreventAccidentalNavigation = (
    deps: any[],
    elementSelectors = lockedElementSelectors
) => {
    // Lock the page to prevent accidental submission
    useEffect(() => {
        const elements = resolveSelectors(elementSelectors);
        elements.forEach((element) => element.addEventListener("click", preventNavigation));
        console.info("Elements locked from accidental navigation:", elements, elementSelectors);

        return () => {
            elements.forEach((element) => element.removeEventListener("click", preventNavigation));
            console.info(
                "Elements un-locked from accidental navigation:",
                elements,
                elementSelectors
            );
        };
    }, deps);
};
