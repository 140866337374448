import * as actions from "@rpforms/shared/build/actions/devices";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import AllEntriesPage from "./AllEntriesPage";
import EditorPage from "./editor/EditorPage";
import ViewEntryPage from "./ViewEntryPage";
import ViewXlsxEntryPage from "./ViewXlsxEntryPage";

class EntriesPage extends Component {
    public props;

    public render() {
        return (
            <>
                <Switch>
                    <Route path="/entries/entry-edit-:id"></Route>
                </Switch>

                <Switch>
                    <Route path="/entries/entry-xlsx-:id" component={ViewXlsxEntryPage} />
                    <Route path="/entries/entry-:id" component={ViewEntryPage} />
                    <Route path="/entries" exact component={AllEntriesPage} />
                    <Route path="/entries/editor" component={EditorPage} />
                </Switch>
            </>
        );
    }
}

function mapStateToProps(state) {
    return { state: state.device };
}

export default withRouter(connect(mapStateToProps, actions)(EntriesPage));
