import { SEARCH_FOLDERS_QUERY } from "@rpforms/shared";
import { Gallery } from "@rpforms/shared/build/images/Gallery";
import { sort } from "@rpforms/shared/build/utils";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Folder, FolderTitle, FolderWrapper } from "./Folder";
import { FolderNote } from "./FolderNote";

const FolderListWrapper = styled.div`
    height: calc(100vh - 235px);
    max-width: ${(props) => props.maxWidth};
    width: auto;
    overflow: auto;
    border-right: ${(props) => props.borderRight} solid #ccc;
    ${FolderWrapper} {
        flex-direction: row;
        height: auto !important;
        padding: 0;
        padding-right: 20px;
        width: 100%;
        justify-content: flex-start;
        svg {
            width: 24px !important;
            min-width: 24px !important;
            height: 24px !important;
            min-height: 24px !important;
        }
    }

    ${FolderTitle} {
        max-width: 100%;
    }
`;
export const IMAGE_EXTENSIONS = [".jpg", ".png", ".jpeg"];

export const FolderList = ({
    activeFolder,
    hierarchy,
    onMove,
    movable,
    openFolder,
    parent,
    inSearch,
    isLast,
    depth,
}) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector<any>((state) => state.directory);
    const [currentGalleryElementId, setCurrentGalleryElementId] = useState(null);

    const resetSearch = () => {
        dispatch({
            type: SEARCH_FOLDERS_QUERY,
            payload: { query: "", scope: "folder" },
        });
    };

    const parentFolder = useSelector<any, any>((state) =>
        state.directory.folders.find((folder) => folder.id === parent.id)
    );

    const parentIsInformationenFolder = parent.resource_name === "Informationen";

    const folders = sort(
        useSelector<any, any>((state) =>
            state.directory.folders.filter((folder) => {
                return folder.parent_id === parent.id;
            })
        ),
        "resource_name"
    ).map((folder) => ({ ...folder, depth }));

    if (folders.length === 0 && isLoading) {
        return (
            <div className={"p-3"}>
                <Spinner animation={"border"} />
            </div>
        );
    }
    if (folders.length <= 0 && !parentIsInformationenFolder) {
        return <></>;
    }

    const filteredFolders = folders.filter(inSearch);
    if (filteredFolders.length === 0 && !parentIsInformationenFolder) {
        return (
            <small className={"p-3"}>
                <div>{folders.length} Ordner wegen Suche versteckt.</div>
                <Button onClick={resetSearch} size={"sm"}>
                    Alle Ordner anzeigen
                </Button>
            </small>
        );
    }
    const images = filteredFolders.filter((f) => IMAGE_EXTENSIONS.includes(f.resource.extension));

    return (
        <FolderListWrapper
            borderRight={isLast ? "0px" : "1px"}
            maxWidth={isLast ? "900px" : "450px"}
        >
            {parentFolder &&
                parentIsInformationenFolder &&
                parentFolder.folder_notes.map((n) => <FolderNote note={n} key={"note" - n.id} />)}
            {filteredFolders.map((folder) => {
                const inHierarchy = hierarchy.find((item) => item.id === folder.id);
                return (
                    <Folder
                        activeFolder={activeFolder}
                        onMove={onMove}
                        key={`child-${folder.id}`}
                        isSelected={inHierarchy}
                        onClick={() => {
                            openFolder(folder);
                        }}
                        folder={folder}
                        movable={movable}
                        setCurrentGalleryElementId={setCurrentGalleryElementId}
                    />
                );
            })}
            {currentGalleryElementId && (
                <Gallery
                    images={images}
                    startingActiveIndex={images.findIndex(
                        (el) => el.id === currentGalleryElementId
                    )}
                    onClose={() => setCurrentGalleryElementId(null)}
                />
            )}
        </FolderListWrapper>
    );
};
