import {fetchCustomers, fetchCustomersForAutocomplete} from "@rpforms/shared";
import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import { sort } from "@rpforms/shared/build/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import EntityListRow from "./EntityListRow";

const EntityList = ({ entities }) => {
    const dispatch = useDispatch();
    const customers = useSelector<RootState, any>((state) => state.customers);
    useEffect(() => {
        if (!customers.isLoading && customers.customers.length <= 0) {
            dispatch(fetchCustomersForAutocomplete());
        }
    }, []);
    return (
        <>
            {entities.length === 0 && (
                <EmptySpace
                    link="/master_data/objects/new"
                    title="Keine Objekte gefunden"
                    description="Nachdem Sie ein Objekte hinzugefügt haben, taucht es auf dieser Seite auf."
                    cta="Objekte anlegen"
                />
            )}

            {sort(entities).map((object) => {
                return <EntityListRow key={object.id} entity={object} />;
            })}
        </>
    );
};

export default EntityList;
