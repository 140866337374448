import React, { useState } from "react";
import { ChatGptModal } from "./ChatGptModal";

export const ChatGptTrigger: React.PropsWithChildren<any> = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const toggleGPT = () => {
        setVisible((on) => !on);
    };

    return (
        <>
            <ChatGptModal close={() => setVisible(false)} visible={visible} />
            <div onClick={toggleGPT}>{children}</div>
        </>
    );
};
