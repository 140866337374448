import React, { useMemo, useState } from "react";
import styled from "styled-components";
import AddField from "../components/AddField";
import { SmallDangerButton } from "../components/Button";
import { FieldWrapper } from "../components/FieldWrapper";
import { stripTags } from "../utils";
import { BaseField } from "./BaseField";
import { Description, Hint, MaxLength, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { LabelInput } from "./inputFields/label";
import { ISubfields } from "./interfaces/ISubfields";

const RepeaterItem = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const RepeaterContainer = styled.div`
    margin: 15px 0;
`;

/**
 * The repeater field is capable of repeating multiple subfields.
 */
export class RepeaterField extends BaseField implements ISubfields {
    public label = "Repeater";
    public subfields = [];
    public icon = "undo";
    public fieldType = "Repeater";

    public getConfigurables() {
        return [Conditional, MaxLength, Description, Width, Hint];
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        rawValue,
        saveState = {},
        readonly = false,
    }) {
        const [elements, setElements] = useState([]);
        if (!rawValue) {
            rawValue = field.rawValue;
        }
        const addElement = () => {
            setElements((currentElements) => {
                return [...currentElements, {}];
            });
        };

        const deleteElement = (index) => {
            setElements((currentElements: any) => {
                const newElements = [...currentElements];
                newElements[index].isHidden = true;
                return newElements;
            });
        };
        if (elements.length === 0 && rawValue && rawValue.length > 0) {
            setElements(rawValue.map((dataSet) => dataSet));
            return;
        }

        if (elements.length === 0) {
            addElement();
        }

        if (!rawValue && elements.length === 0) {
            addElement();
        }

        return field.configureInput(
            layout.wrapComponent(
                <RepeaterContainer>
                    {layout.wrapDescription(
                        field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                    )}
                    <div
                        className={layout.wrapComponentCSS(field.configureCSS(""))}
                        style={{ margin: "0 -20px" }}
                    >
                        <>
                            {elements.map((dataSet, index) => {
                                if (dataSet.isHidden) {
                                    return <div key={index}></div>;
                                }
                                return (
                                    <RepeaterItem
                                        data-repeater={`repeater_${field.hashCode}_${index}`}
                                        key={"_repeater_" + field.hashCode + "_" + index}
                                    >
                                        <div className={"hidden-print"}>
                                            <SmallDangerButton
                                                style={{
                                                    marginLeft: "15px",
                                                    display: "inline-block",
                                                }}
                                                onClick={(ev) => {
                                                    deleteElement(index);
                                                    ev.stopPropagation();
                                                    ev.preventDefault();
                                                    return false;
                                                }}
                                            >
                                                X
                                            </SmallDangerButton>
                                        </div>
                                        {field.subfields.map((subfield) => {
                                            const subfieldName =
                                                "repeater_" +
                                                index +
                                                field.hashCode +
                                                subfield.hashCode;

                                            let rawValue = subfield.rawValue;
                                            if (dataSet[subfield.hashCode]) {
                                                rawValue = dataSet[subfield.hashCode];
                                            }

                                            if (saveState[subfieldName]) {
                                                rawValue = saveState[subfieldName];
                                            }

                                            return subfield.configureInput(
                                                <subfield.WrapComponent
                                                    layout={layout}
                                                    key={subfield.hashCode + "_" + index}
                                                    schema={schema}
                                                    field={subfield}
                                                    entry={null}
                                                    component={
                                                        <div data-field={subfield.fieldType}>
                                                            <subfield.InputComponent
                                                                readonly={readonly}
                                                                layout={layout}
                                                                register={register}
                                                                key={
                                                                    subfield.hashCode + "_" + index
                                                                }
                                                                schema={schema}
                                                                name={subfieldName}
                                                                field={subfield}
                                                                rawValue={rawValue}
                                                                entry={null}
                                                            />
                                                        </div>
                                                    }
                                                />
                                            );
                                        })}
                                    </RepeaterItem>
                                );
                            })}
                        </>
                        <div
                            className={"hide-print"}
                            data-length={elements.length}
                            style={{ padding: "0 20px" }}
                        >
                            {field.configureInput(
                                <div>
                                    <div onClick={addElement} className={"btn btn-primary"}>
                                        {stripTags(field.label)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {layout.wrapHint(
                        field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>
                    )}
                </RepeaterContainer>
            )
        );
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        const [update, setUpdate] = useState(0);
        const handleNewField = (fieldClass) => {
            schema.subfield(field, new fieldClass(schema));
            designer.save(schema, { delaySave: true });
            setUpdate(update + 1);
        };

        const subfields = useMemo(() => {
            return field.subfields.map((subfield) => (
                <subfield.EditComponent
                    designer={designer}
                    register={register}
                    key={subfield.hashCode}
                    schema={schema}
                    field={subfield}
                    entry={null}
                />
            ));
        }, [field, update]);

        return (
            <FieldWrapper small={true} designer={designer} schema={schema} field={field}>
                <div className="form-group p-1">
                    <div className="powerform-flex">
                        <div className="powerform-w50">
                            <label>Name der Schaltfläche</label>
                            <LabelInput register={register} field={field} />
                        </div>
                    </div>
                    <div className="powerform-row-edit">{subfields}</div>
                    <div className="mt-5 pl-1">
                        <AddField
                            small={true}
                            handleNewField={handleNewField.bind(this)}
                        ></AddField>
                    </div>
                </div>
            </FieldWrapper>
        );
    }
}
