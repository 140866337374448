import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { createCustomField } from "../actions/customFields";
import ICONS from "../icons";

/**
 * Provides basic form control for modifying form properties
 * @constructor
 */
const FieldCreator = ({ hide, fieldData }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [icon, setIcon] = useState();

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            resolve(
                ICONS.map((icon: any) => {
                    return {
                        value: icon,
                        label: icon,
                    };
                }).filter((i) => i.label.includes(inputValue.toLowerCase()))
            );
        });

    const createNewCustomField = () => {
        dispatch(
            createCustomField({
                name: name,
                icon,
                field_data: JSON.stringify(fieldData.serialize()),
            })
        );
        hide();
    };

    const handleCreateButtonClick = () => {
        if (name.length > 0 && icon !== undefined) {
            createNewCustomField();
        }
    };

    const formatOptionLabel = ({ value, label }) => (
        <div
            style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
            }}
        >
            <i className={`fa fa-${value} fa-fw`}></i>
            <div>{label}</div>
        </div>
    );

    return (
        <>
            <div className="modal-body" data-backdrop="static" data-keyboard="false">
                <label>Feldname</label>
                <input
                    type={"text"}
                    onChange={(e) => setName(e.target.value)}
                    className={"form-control"}
                />
                <br />
                <label>Symbol</label>
                <AsyncSelect
                    formatOptionLabel={formatOptionLabel}
                    onChange={(icon: any) => setIcon(icon.value)}
                    placeholder={"Symbol auswählen..."}
                    cacheOptions
                    defaultOptions
                    loadOptions={promiseOptions}
                />
            </div>
            <div className="modal-footer">
                <button
                    onClick={() => handleCreateButtonClick()}
                    type="submit"
                    className="btn btn-primary"
                >
                    Erstellen
                </button>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={hide}
                    data-dismiss="modal"
                >
                    Schließen
                </button>
            </div>
        </>
    );
};

export default FieldCreator;
