import { SmallButton } from "@rpforms/shared/build/components/Button";
import { dig } from "@rpforms/shared/build/utils";
import React from "react";
import styled from "styled-components";

export const PaneAction = styled.div`
    position: sticky;
    top: 200px;
`;

export default ({ index, panes, copyFormData, undoCopyFormData, undoable }) => {
    if (index >= panes.length - 1) {
        return <></>;
    }

    return (
        <div>
            <PaneAction>
                <SmallButton onClick={() => copyFormData(index, index + 1)}>
                    Daten übernehmen<i className="fa fa-chevron-right"></i>
                </SmallButton>
                {undoable && (
                    <SmallButton onClick={() => undoCopyFormData()}>
                        rückgängig<i className="fa fa-undo"></i>
                    </SmallButton>
                )}
            </PaneAction>
        </div>
    );
};
