import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import React from "react";
import DeviceListRow from "./DeviceListRow";
import { sort } from "@rpforms/shared/build/utils";

const DeviceList = ({ devices }) => {
    return (
        <>
            {devices.length === 0 && (
                <EmptySpace
                    link="/master_data/devices/new"
                    title="Keine Geräte gefunden"
                    description="Nachdem Sie ein Gerät hinzugefügt haben, taucht es auf dieser Seite auf."
                    cta="Gerät registrieren"
                />
            )}

            {sort(devices).map((device, index) => {
                return <DeviceListRow key={`device-${index}-${device.id}`} device={device} />;
            })}
        </>
    );
};

export default DeviceList;
