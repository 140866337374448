import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class IndexTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "#";
    public icon = "sort-numeric-asc";
    public fieldType = "Index";
    public width = "50px";
    public getConfigurables() {
        return [];
    }

    public toHotColumn() {
        return {
            type: "index",
        };
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label || "#"}
                    ref={register}
                />
            </FieldWrapper>
        );
    }
}
