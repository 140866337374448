import { fetchDevices, getDevices, toSelectOptions } from "@rpforms/shared";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const DeviceSelector = ({
    onSelect,
    defaultValue = null,
    filterBy = (device) => true,
    includeAllOption = false,
    forceFetch = false,
    ...props
}) => {
    const [value, setValue] = useState(null);

    const devices = useSelector<RootState, any>((store) => store.device);
    const defaultDevice = useSelector<RootState, any>((store) => {
        return typeof defaultValue === "number"
            ? toSelectOptions([store.device.devices.find((c) => c.id === defaultValue)])[0]
            : defaultValue;
    });

    const options = () => {
        if (includeAllOption) {
            return toSelectOptions([
                {
                    id: 0,
                    name: "---ALLE GERÄTE---",
                },
                ...devices.devices.filter(filterBy),
            ]);
        } else {
            return toSelectOptions(devices.devices.filter(filterBy));
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (!devices.isLoading && (!devices.devices || devices.devices.length <= 0 || forceFetch)) {
            dispatch(
                fetchDevices(1, (devicesData) => {
                    if (defaultValue) {
                        setValue(
                            toSelectOptions(devicesData.filter((c) => c.id === defaultValue))[0]
                        );
                    }
                })
            );
        } else {
            if (defaultValue) {
                setValue(toSelectOptions(devices.devices.filter((c) => c.id === defaultValue))[0]);
            }
        }
    }, []);

    const onChange = (option) => {
        setValue(option);
        onSelect(option);
    };

    const defaultProps = () => {
        if (defaultDevice) {
            return { defaultValue: defaultDevice };
        } else {
            return {};
        }
    };

    return (
        <Select
            {...defaultProps()}
            onChange={onChange}
            options={options()}
            value={value}
            {...props}
        />
    );
};
