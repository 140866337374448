import { fetchCompany } from "@rpforms/shared/build/actions";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { COMPANY_ID } from "../config";
import { RootState } from "../reducers";

const Center = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const CompanyContainer = ({ children }) => {
    const company = useSelector<RootState, any>((state) => state.company);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!company.id) {
            dispatch(fetchCompany(COMPANY_ID));
        }
    }, []);

    const theme = () => {
        return company.theme;
    };

    return (
        <>
            {company.id && <ThemeProvider theme={theme()}>{children}</ThemeProvider>}
            {!company.id && (
                <Center>
                    <Spinner animation={"grow"} variant={"primary"} />
                    wird geladen...
                </Center>
            )}
        </>
    );
};
