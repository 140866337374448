import React from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import MigrationEntryPage from "./MigrationEntryPage";
import NewEntryPage from "./NewEntryPage";
import TemplateEntryPage from "./TemplateEntryPage";

export const EditorPage = () => {
    const dispatch = useDispatch();

    return (
        <>
            <Switch>
                <Route path="/entries/editor/entry-edit-:formEntryId" component={NewEntryPage} />
                <Route
                    path="/entries/editor/migrate/source/:sourceId"
                    component={MigrationEntryPage}
                />
                <Route
                    path="/entries/editor/template/source/:sourceId"
                    component={TemplateEntryPage}
                />
                <Route path="/entries/editor" exact component={NewEntryPage} />
            </Switch>
        </>
    );
};

export default EditorPage;
