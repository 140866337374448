import styled from "styled-components";

export const Content = styled.main`
    padding: 20px;
`;

export const LeadTitle = styled.h2`
    font-size: 24px;
`;

export const AlignCenterVertically = styled.div`
    display: flex;
    align-items: center;
`;
