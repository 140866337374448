import { GET_INVENTORY_ITEMS } from "../actions/types";

const initialState = {
    data: {
        inventory_items: [],
    },
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_INVENTORY_ITEMS:
            return { ...state, data: action.payload }; // überschreibt den state
    }
    return state;
}
