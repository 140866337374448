import React from "react";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";

/**
 * Applies maximum length to input field
 */
export class Unique extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = false;

    public keyName = "unique";
    public title = "Einzigartig?";
    public description = "Soll der Wert dieses Feldes im gesamten Formular einzigartig sein?";

    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: any;
    }): any {
        return (
            <AppearanceCheckbox
                label="Muss ausgefüllt werden"
                register={register}
                value={field.rawOptions[this.keyName]}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value !== false;
    }

    public wakeValue(value) {
        return value;
    }
}
