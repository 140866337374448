import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import SettingsPage from "./pages/settings/SettingsPage";
import EntriesPage from "./pages/entries/EntriesPage";
import InventoryItemsPage from "./pages/inventory_items/InventoryItemsPage";
import MasterDataPage from "./pages/master_data/MasterDataPage";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import requireAuth from "./components/hoc/RequireAuth";
import requireNoAuth from "./components/hoc/RequireNoAuth";
import { LoginPage } from "./pages/auth/LoginPage";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { sharedHistory } from "./shared_history";
import { composeWithDevTools } from "redux-devtools-extension";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FinancesPage from "./pages/finances/FinancesPage";
import * as moment from "moment";
import NotificationsPage from "./pages/notifications/NotificationPage";
import CalendarPage from "./pages/calendar/RoutingPage";

moment.locale("de");
import "./application.scss";
import "react-dates/initialize";
import { CompanyContainer } from "./components/CompanyContainer";
import { AUTH_USER } from "@rpforms/shared/build/actions";
import { CURRENT_USER } from "@rpforms/shared/build/actions";
import FoldersPage from "./pages/directory/FoldersPage";
import { ModalEditor } from "./components/modal/ModalEditor";
import { SearchPage } from "./pages/SearchPage";
import { Navbar } from "@rpforms/shared/build/components/universal/Navbar";
import { MigrationEntryPage } from "./pages/entries/editor/MigrationEntryPage";
import ViewPdfEntryPage from "./pages/entries/ViewPdfEntryPage";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import useTokenRefresher from "./hooks/useTokenRefresher";
const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(routerMiddleware(sharedHistory), reduxThunk))
);

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://88260a24d77d45cca0c3814d2505314c@o791122.ingest.sentry.io/4504095641501696",
        integrations: [
            new BrowserTracing({
                tracingOrigins: [
                    "https://api.rpforms.kega.network",
                    "https://api.staging.rpforms.kega.network",
                ],
            }),
        ],
        release: process.env.REACT_APP_COMMIT,
        environment: process.env.REACT_APP_ENVIRONMENT,
        tracesSampleRate: 0.1,
    });
}

export const RedirectApp = {
    Production() {
        window.location.assign("/app/index.html");
        return <div>Bitte warten...</div>;
    },

    Staging() {
        window.location.assign("/beta/index.html");
        return <div>Bitte warten...</div>;
    },
};

let hasRequestedSignIn = false;
const user = JSON.parse(localStorage.getItem("user"));

if (user && user.token) {
    const state = store.getState();
    if (state.user.username === "guest") {
        const FIFTEEN_MINUTES = 15 * 60 * 1000;
        if (!hasRequestedSignIn && parseInt(user.exp, 10) >= +new Date()) {
            store.dispatch({ type: AUTH_USER });
            store.dispatch({ type: CURRENT_USER, payload: user });
            hasRequestedSignIn = true;
        }
    }

    Sentry.setUser({ username: user.username, id: user.id });
} else {
    Sentry.setUser(null);
}

const Application = () => {
    const pdfRoute = window.location.href.indexOf("pdf-") > -1;
    useTokenRefresher();
    return (
        <Provider store={store}>
            <ToastContainer />
            <ConnectedRouter history={sharedHistory}>
                <Route path="/pdf-:id" component={ViewPdfEntryPage} />
                <CompanyContainer>
                    {!pdfRoute && <Navbar />}
                    <Route
                        path="/"
                        exact
                        render={() => {
                            return <Redirect to="/entries" />;
                        }}
                    />
                    <Route path="/app/" exact render={RedirectApp.Production} />
                    <Route path="/beta/" exact render={RedirectApp.Staging} />

                    <Route path="/settings" component={requireAuth(SettingsPage)} />
                    <Route
                        path={["/search/:query", "/search/"]}
                        component={requireAuth(SearchPage)}
                    />
                    <Route path="/entries" component={requireAuth(EntriesPage)} />
                    <Route path="/folders" component={requireAuth(FoldersPage)} />
                    <Route path="/auth/login" component={requireNoAuth(LoginPage)} />
                    <Route path="/master_data" component={requireAuth(MasterDataPage)} />
                    <Route path="/finances" component={requireAuth(FinancesPage)} />
                    <Route path="/notifications" component={requireAuth(NotificationsPage)} />
                    <Route path="/calendar" component={requireAuth(CalendarPage)} />

                    <Route
                        path="/migrate/source/:sourceId"
                        component={requireAuth(MigrationEntryPage)}
                    />
                    <Route
                        exact
                        path="/inventory_items/repair"
                        component={requireAuth(InventoryItemsPage)}
                    />
                    <Route
                        exact
                        path="/inventory_items/service"
                        component={requireAuth(InventoryItemsPage)}
                    />
                    <Route
                        exact
                        path="/inventory_items/disposal"
                        component={requireAuth(InventoryItemsPage)}
                    />
                    <ModalEditor />
                </CompanyContainer>
            </ConnectedRouter>
        </Provider>
    );
};

export default Application;
