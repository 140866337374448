import { BootstrapLayout } from "@rpforms/shared/build/components/BootstrapLayout";
import { SchemaBuilder } from "@rpforms/shared/build/spec/SchemaBuilder";
import React from "react";
import { DataPresenter } from "../DataPresenter";

const ViewEntry = ({ entry }: any) => {
    const schemaBuilder = new SchemaBuilder();
    const formData = JSON.parse(entry.form_revision.form_data);
    schemaBuilder.use(formData);
    const dataPresenter = schemaBuilder.dataPresenter(entry);
    return (
        <>
            <DataPresenter
                form={entry.form}
                schema={schemaBuilder}
                layout={BootstrapLayout}
                hideSubmit={false}
                presenter={dataPresenter}
            />
        </>
    );
};

export default ViewEntry;
