export const getUniqueRadioNames = (requiredFields) => {
    return new Set(
        requiredFields.filter((input) => input.type === "radio").map((input) => input.name)
    );
};

export const isInvalidField = (input) => {
    return (["checkbox", "radio"].includes(input.type) && !input.checked) || !input.value;
};

export const validateRequiredFields = (requiredFields, handleEmptyRequiredFields) => {
    const radioNames = getUniqueRadioNames(requiredFields);
    radioNames.forEach((name) => {
        const radioGroup = Array.from(
            document.querySelectorAll(`input[type="radio"][name="${name}"]`)
        );
        const isGroupValid = radioGroup.some((input: HTMLInputElement) => input.checked);
        if (isGroupValid) {
            requiredFields = requiredFields.filter(
                (input: HTMLInputElement) => input.name !== name
            );
        }
    });
    requiredFields = requiredFields.filter(isInvalidField);
    if (requiredFields.length > 0) {
        handleEmptyRequiredFields(requiredFields);
        return false;
    }
    return true;
};
