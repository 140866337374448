import React, { useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../reducers";
import { AddressRow } from "./AddressRow";
import { fetchCustomer } from "@rpforms/shared";

const CustomerInfoModal = ({ id, customerInfoModalShow, setCustomerInfoModalShow }) => {
    const customer = useSelector<RootState, any>((store) => {
        return store.customers.customers.find((c) => c.id === id);
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (customerInfoModalShow) {
            dispatch(fetchCustomer(id));
        }
    }, [customerInfoModalShow]);

    return (
        <Modal
            show={customerInfoModalShow === id}
            onHide={() => {
                setCustomerInfoModalShow(0);
            }}
        >
            {customer && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{customer.name} – Adressen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {customer.addresses &&
                            customer.addresses.map((address) => {
                                return (
                                    <div key={address.id}>
                                        <AddressRow address={address} slim={false} />
                                        <hr />
                                    </div>
                                );
                            })}
                    </Modal.Body>
                </>
            )}

            {!customer && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Wird geladen...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Spinner animation={"border"} />
                    </Modal.Body>
                </>
            )}
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setCustomerInfoModalShow(0);
                    }}
                >
                    schließen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withRouter(CustomerInfoModal);
