import {
    fetchDevicesForAutocomplete,
    toSelectOptions,
    toSelectOptionsWithAll
} from "@rpforms/shared";
import { fetchDevices } from "@rpforms/shared/build/actions/devices";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const DeviceFilter = ({ onSelect, includeAll = "alle Geräte", value, ...props }) => {
    const dispatch = useDispatch();
    const devices = useSelector<RootState, any>((store) => store.device.autocomplete);

    const withoutDeviceOption = {
        name: "Ohne Gerät",
        id: "-1",
    };

    useEffect(() => {
        if ((!devices.isLoading && !devices) || devices.length <= 0) {
            dispatch(
                fetchDevicesForAutocomplete(-1, (devicesData) => {
                    onSelect(getDefaultValue(devicesData));
                })
            );
        } else {
            onSelect(getDefaultValue(devices));
        }
    }, []);

    const getDefaultValue = (d) => {
        let dv = value;
        if (!Array.isArray(dv)) {
            dv = [dv];
        }
        const defaultValueIds = [
            withoutDeviceOption.id,
            ...dv.filter((x) => x != null).map((x) => parseInt(x.value, 10)),
        ];
        const arr = d.filter((c) => defaultValueIds.includes(c.id));
        const ret = props.isMulti
            ? toSelectOptions(arr, "name", "id")
            : toSelectOptionsWithAll(arr, includeAll, "name", "id");
        return props.isMulti ? ret : ret[0];
    };

    const onChange = (option) => {
        onSelect(option);
    };

    return (
        <Select
            onChange={onChange}
            options={
                props.isMulti
                    ? toSelectOptions([withoutDeviceOption, ...devices], "name", "id")
                    : toSelectOptionsWithAll(devices, includeAll)
            }
            value={value}
            placeholder={includeAll}
            {...props}
        />
    );
};
