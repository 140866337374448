import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

export default function(ComposedComponent) {
    const Authentication = (props) => {
        if (!props.authenticated) {
            return <Redirect to={"/auth/login"} />;
        }

        return <ComposedComponent {...props} />;
    };

    function mapStateToProps(state) {
        return { authenticated: state.auth.authenticated };
    }

    return withRouter(connect(mapStateToProps)(Authentication));
}
