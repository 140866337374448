import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import { withRouter } from "react-router-dom";
import EmployeeList from "../../../components/employees/EmployeeList";
import { fetchEmployees } from "@rpforms/shared/build/actions/employees";

export const ListEmployeePage = (props) => {
    const dispatch = useDispatch();
    const employees = useSelector<RootState, any>((store) => store.employees.employees);

    useEffect(() => {
        dispatch(fetchEmployees());
    }, []);

    useEffect(() => {
        props.setNumberOfEntries(employees.length);
    }, [employees]);

    return (
        <Content>
            <EmployeeList employees={employees} />
        </Content>
    );
};

export default withRouter(ListEmployeePage);
