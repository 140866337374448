import { Field } from "@rpforms/web/src/components/Field";
import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";
import { Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";

export class GroupField extends BaseField {
    public label = "Gruppe";
    public icon = "square-o";
    public fieldType = "Gruppe";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Width];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className={"p-3"}>
                    <label>Name</label>
                    <input
                        type="text"
                        name={field.inputName("label")}
                        className="form-control"
                        defaultValue={field.label}
                        ref={register}
                    />
                    <br />
                    <div>
                        {field.subfields ? (
                            field.subfields.map((subfield, index) => {
                                return (
                                    <subfield.EditComponent
                                        designer={designer}
                                        register={register}
                                        key={subfield.hashCode + "_" + index}
                                        schema={schema}
                                        field={subfield}
                                        entry={null}
                                    />
                                );
                            })
                        ) : (
                            <span />
                        )}
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.name,
        variables = {},
        saveState = {},
        readonly = false,
    }) {
        return (
            <>
                {field.configureInput(
                    <div
                        data-id={field.label}
                        style={{ margin: "0 -20px !important", display: "flex", flexWrap: "wrap" }}
                    >
                        {field.subfields ? (
                            field.subfields.map((subfield, index) => {
                                const rawValue = saveState[subfield.hashCode];
                                return (
                                    <subfield.WrapComponent
                                        layout={layout}
                                        key={subfield.hashCode + "_" + index + "_" + Math.random()}
                                        schema={schema}
                                        field={subfield}
                                        entry={entry}
                                        component={
                                            <subfield.InputComponent
                                                readonly={readonly}
                                                layout={layout}
                                                key={Math.random()}
                                                register={register}
                                                name={subfield.hashCode}
                                                schema={schema}
                                                field={subfield}
                                                entry={entry}
                                                rawValue={rawValue}
                                                variables={variables}
                                                saveState={saveState}
                                            />
                                        }
                                    />
                                );
                            })
                        ) : (
                            <span />
                        )}
                    </div>,
                    register,
                    field
                )}
            </>
        );
    }

    public configureInput(inputEl, register, field) {
        let newInput = super.configureInput(inputEl, register, field);
        newInput = React.cloneElement(
            newInput,
            {
                style: {
                    margin: "0 !important",
                },
            },
            newInput.props.children
        );
        return newInput;
    }

    public PresenterComponent({ field }) {
        return <div>Group...</div>;
    }
}
