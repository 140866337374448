import React from "react";

import { FieldWrapper } from "../components/FieldWrapper";
import { Content } from "../components/layout/Content";
import { TextField } from "./TextField";

export class ConfigField extends TextField {
    public icon = "cog";
    public fieldType = "Konfiguration";
    public state;
    public setState;
    public displayLandscape;
    public attributeNames = [
        "title",
        "slug",
        "displayLandscape",
        "overrideName",
        "overridePattern",
        "datedAtPattern",
        "connectedInventory",
        "overrideSummary",
        "summaryPattern",
        "layer3_name",
    ];

    public getConfigurables() {
        return [];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("displayLandscape")}
                            className="form-control"
                            defaultChecked={field.displayLandscape}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Im Landscape Modus darstellen</label>
                        <div className="text-muted">
                            Soll das Formular vertikal dargestellt werden?
                        </div>
                    </div>
                </div>

                <div className="mt-3">
                    <label>Kurzname</label>
                    <input
                        type="text"
                        name={field.inputName("slug")}
                        className="form-control"
                        defaultValue={field.slug}
                        ref={register}
                    />
                    <div className="text-muted">Kann via {"{config:slug}"} angefordert werden.</div>
                </div>

                <div className="mt-3">
                    <label>Formulartitel</label>
                    <input
                        type="text"
                        name={field.inputName("title")}
                        className="form-control"
                        defaultValue={field.title}
                        ref={register}
                    />
                    <div className="text-muted">
                        Wird im PDF als Überschrift angezeigt sofern vorhanden. Kann via{" "}
                        {"{config:title}"} angefordert werden.
                    </div>
                </div>

                <hr />

                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("overrideName")}
                            className="form-control"
                            defaultChecked={field.overrideName}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Name überschreiben</label>
                    </div>
                </div>

                <div className="mt-3">
                    <label>Name Pattern</label>
                    <input
                        type="text"
                        name={field.inputName("overridePattern")}
                        className="form-control"
                        defaultValue={field.overridePattern}
                        ref={register}
                    />
                    <div className="text-muted">
                        Sie können Textbausteine wie {"{entity:name}"} oder {"{field:<identifier>}"}{" "}
                        verwenden.
                    </div>
                </div>

                <div className="mt-3">
                    <label>Datiert am Pattern</label>
                    <input
                        type="text"
                        name={field.inputName("datedAtPattern")}
                        className="form-control"
                        defaultValue={field.datedAtPattern}
                        ref={register}
                    />
                    <div className="text-muted">
                        Sie können Textbausteine wie {"{entity:name}"} oder {"{field:<identifier>}"}{" "}
                        verwenden. Bei ungültigen Datumsangaben greift der Fallback. Als Fallback
                        dient das Erstellungsdatum bzw., wenn vorhanden, das "Datum"-Feld
                    </div>
                </div>

                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("connectedInventory")}
                            className="form-control"
                            defaultChecked={field.connectedInventory}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Rechnungspositionen an Inventar koppeln</label>
                    </div>
                </div>

                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("overrideSummary")}
                            className="form-control"
                            defaultChecked={field.overrideSummary}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Name überschreiben</label>
                    </div>
                </div>

                <div className="mt-3">
                    <label>Summary Pattern</label>
                    <input
                        type="text"
                        name={field.inputName("summaryPattern")}
                        className="form-control"
                        defaultValue={field.summaryPattern}
                        ref={register}
                    />
                    <div className="text-muted">
                        Sie können Textbausteine wie {"{entity:name}"} oder {"{field:<identifier>}"}{" "}
                        verwenden.
                    </div>
                </div>

                <div className="mt-3">
                    <label>Ordner-Name für 3. Ebene (Standard = Jahreszahl)</label>
                    <input
                        type="text"
                        name={field.inputName("layer3_name")}
                        className="form-control"
                        defaultValue={field.layer3_name}
                        ref={register}
                    />
                    <div className="text-muted">
                        Die Ordner-Namen für die 1. und 2. Ebene sind fest und unveränderlich
                        (/KUNDE/OBJEKT/). In der 3. Ebene wird standardmäßig die Jahreszahl des
                        Erstellungsdatum verwendet. Sie können Textbausteine wie{" "}
                        {"{date:current:YYYY}"} oder {"{field:<identifier>}"} verwenden und
                        kombinieren {"{field:<identifier>:date:YYYY}"}.
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
    }: {
        layout: any;
        schema: any;
        field: any;
        entry?: any;
        register: any;
        name?: any;
    }): any {
        return <></>;
    }
}
