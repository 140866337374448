import {
    CREATE_VACATION,
    DELETE_VACATION,
    GET_VACATIONS,
    GET_VACATIONS_FOR_EMPLOYEE,
    UPDATE_VACATION,
} from "../actions/types";

const initialState = {
    list: [],
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_VACATIONS:
            return { ...state, list: action.payload };
        case GET_VACATIONS_FOR_EMPLOYEE:
            return { ...state, list: action.payload };
        case UPDATE_VACATION:
            const list = state.list.map((v) => {
                if (v.id === action.payload.id) {
                    return action.payload;
                }
                return v;
            });
            return { ...state, list };
        case CREATE_VACATION:
            if (state.list.find((v: any) => v.id === action.payload.id)) {
                return { ...state };
            }
            return { ...state, list: [...state.list, action.payload] };
        case DELETE_VACATION:
            return {
                ...state,
                list: state.list.filter((v) => {
                    return v.id !== action.payload.id;
                }),
            };
    }

    return state;
}
