import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class CheckboxTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Ja/Nein Auswahl";
    public icon = "check";
    public fieldType = "Ja/Nein Auswahl";

    public getConfigurables() {
        return [Width];
    }

    public toHotColumn() {
        return {
            type: "checkbox",
            data: this.label,
        };
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label}
                    ref={register}
                />
            </FieldWrapper>
        );
    }
}
