import {
    fetchCustomersForAutocomplete,
    fetchEntitiesForAutocomplete,
    moveFolders,
    setFolderSelection,
    setFolderSelectionItems,
} from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../reducers";
import { CustomerSelect } from "../customers/select";
import { EntitySelect } from "../entities/select";
import { FolderIcon } from "./FolderIcon";

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    font-size: 16px !important;
    width: 100%;
    // align svg icons with fonts
    svg {
        position: relative;
        top: -2px;
    }
`;

export const FolderMover = ({ folder, setVisibility }) => {
    const { selection, selectionEnabled } = useSelector<RootState, any>((state) => state.directory);
    const [target, setTarget] = useState<any>(false);
    const dispatch = useDispatch();
    const [newCustomer, setNewCustomer] = useState(false);
    const [newEntity, setNewEntity] = useState(false);
    const [selectedTimestamp, setSelectedTimestamp] = useState(+new Date());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!folder) {
            return;
        }

        const ts = +new Date();
        setSelectedTimestamp(ts);
        const timer = setTimeout(() => {
            if (selectedTimestamp <= ts) {
                dispatch(fetchCustomersForAutocomplete());
                dispatch(fetchEntitiesForAutocomplete());
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [folder, selection]);

    useEffect(() => {
        if (typeof folder === "object" && folder?.id) {
            dispatch(setFolderSelectionItems([folder]));
            dispatch(setFolderSelection(true));
        }
    }, [folder]);

    const justEntities = () => {
        return selection.find((f) => f.resource_type !== "Entity") === undefined;
    };

    const move = async () => {
        setIsLoading(true);
        const folderIds = selection.map((f) => f.id);
        const parentResources = [];
        parentResources.push({
            id: newCustomer.value,
            type: "Customer",
        });
        if (newEntity.value != null) {
            parentResources.push({
                id: newEntity.value,
                type: "Entity",
            });
        }
        await dispatch(
            moveFolders({
                parent_resources: parentResources,
                folder_ids: folderIds,
            })
        );
        setIsLoading(false);
        setVisibility(false);
        window.location.reload(true);
    };

    const byCustomer = (entity) => {
        if (!newCustomer) {
            return true;
        }

        return entity.customer_id === newCustomer.value;
    };

    return (
        <Modal
            show={folder}
            onHide={() => setVisibility(false)}
            size={"lg"}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <StyledModalTitle>
                    <div>
                        Verschiebe {selection.length} <FolderIcon height={16} width={16} /> Ordner
                    </div>
                </StyledModalTitle>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
                <Row style={{ padding: "10px 20px" }}>
                    <Col>Verschoben werden: {selection.map((f) => f.name).join(", ")}</Col>
                </Row>
                <Row style={{ padding: "20px" }}>
                    <Col>
                        <h3>Kunde</h3>
                        <CustomerSelect onSelect={setNewCustomer} />
                    </Col>
                    {!justEntities() && (
                        <Col>
                            <h3>Objekt</h3>
                            <EntitySelect filter={byCustomer} onSelect={setNewEntity} />
                        </Col>
                    )}
                </Row>
                <div style={{ padding: "20px" }}>
                    {isLoading && <>speichert …</>}
                    {newCustomer && (
                        <PrimaryButton onClick={move} disabled={isLoading}>
                            Verschieben
                        </PrimaryButton>
                    )}
                    <br />
                </div>
            </Modal.Body>
        </Modal>
    );
};
