import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import React from "react";
import EmployeeListRow from "./EmployeeListRow";
import { sort } from "@rpforms/shared/build/utils";

const EmployeeList = ({ employees }) => {
    return (
        <>
            {employees.length === 0 && (
                <EmptySpace
                    title="Keine Mitarbeiter gefunden"
                    description="Nachdem Sie einen Mitarbeiter hinzugefügt haben, taucht er auf dieser Seite auf."
                />
            )}

            {sort(employees).map((employee) => {
                return <EmployeeListRow key={employee.id} employee={employee} />;
            })}
        </>
    );
};

export default EmployeeList;
