import React, { useState } from "react";
import styled, { css } from "styled-components";
import { getContrastColor, translucentColor } from "../utils";

export const Button = styled.button`
    margin: 0 14px;
    font-size: 18px;
    background: linear-gradient(to top, rgba(235, 235, 235, 0.6), rgba(241, 241, 241, 0.6));
    border-radius: 5px;
    border: 1px solid #ddd;
    text-shadow: 1px 1px #fff;
    color: #444;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    :hover {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: #444;
        }
    }

    :focus {
        outline: none;
    }

    :active {
        box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
        background: linear-gradient(to bottom, rgba(235, 235, 235, 0.8), rgba(241, 241, 241, 0.6));
        border-color: #ccc;
        a {
            color: #444;
        }
    }
`;

export const SuccessButton = styled.button`
    margin: 0 14px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    text-shadow: 1px 1px #fff;
    color: #444;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    :hover {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: #444;
        }
    }

    :focus {
        outline: none;
    }

    :active {
        box-shadow: 1px 3px 5px rgba(50, 240, 95, 0.3);
        border: 1px solid rgb(0, 192, 32);
        background: linear-gradient(to bottom, rgb(18, 226, 28), rgb(52, 195, 17));
        a {
            color: #444;
        }
    }
    color: #fff;
    text-shadow: none;
    border: 1px solid rgb(0, 192, 32);
    background: linear-gradient(to bottom, rgb(18, 226, 28), rgb(52, 195, 17));

    :hover {
        box-shadow: 1px 3px 5px rgba(50, 240, 95, 0.3);
        a {
            color: #fff;
        }
    }

    :active {
        box-shadow: 0 0 0;
        background: rgb(17, 143, 15);
        border-color: rgba(50, 240, 95, 0.3);
        a {
            color: #fff;
        }
    }
`;

export const DangerButton = styled.button`
    margin: 0 14px;
    font-size: 18px;
    background: linear-gradient(to top, rgba(235, 235, 235, 0.6), rgba(241, 241, 241, 0.6));
    border-radius: 5px;
    border: 1px solid #ddd;
    text-shadow: 1px 1px #fff;
    color: #444;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    :hover {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: #444;
        }
    }

    :focus {
        outline: none;
    }

    :active {
        box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
        background: linear-gradient(to bottom, rgba(235, 235, 235, 0.8), rgba(241, 241, 241, 0.6));
        border-color: #ccc;
        a {
            color: #444;
        }
    }
    color: #fff;
    text-shadow: none;
    border: 1px solid rgb(192, 0, 18);
    background: linear-gradient(to bottom, rgb(226, 8, 34), rgb(194, 17, 41));

    :hover {
        box-shadow: 1px 3px 5px rgba(240, 50, 76, 0.3);
        a {
            color: #fff;
        }
    }

    :active {
        box-shadow: 0 0 0;
        background: rgb(143, 16, 33);
        border-color: rgba(240, 50, 76, 0.3);
        a {
            color: #fff;
        }
    }
`;
export const PrimaryButton = styled.button`
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    text-shadow: 1px 1px #fff;
    color: #444;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    :hover {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: #444;
        }
    }

    :focus {
        outline: none;
    }

    color: ${(props) => getContrastColor(props.theme.primary_color || "#00bcd4")} !important;
    text-shadow: 1px 1px ${(props) => props.theme.primary_color || "#00bcd4"};
    border: 1px solid ${(props) => props.theme.primary_color || "#00bcd4"};
    background: linear-gradient(
        to bottom,
        ${(props) => translucentColor(props.theme.primary_color || "#00bcd4", 0.8)},
        ${(props) => props.theme.primary_color || "#00bcd4"}
    );

    :hover {
        box-shadow: 1px 3px 5px
            ${(props) => translucentColor(props.theme.primary_color || "#00bcd4", 0.3)};
        a {
            color: ${(props) =>
                getContrastColor(props.theme.primary_color || "#00bcd4")} !important;
        }
    }

    :active {
        box-shadow: inset 1px 3px 5px
            ${(props) => translucentColor(props.theme.primary_color || "#00bcd4", 0.3)};
        background: linear-gradient(
            to top,
            ${(props) => translucentColor(props.theme.primary_color || "#00bcd4", 0.8)},
            ${(props) => props.theme.primary_color || "#00bcd4"}
        );
        border-color: ${(props) => translucentColor(props.theme.primary_color || "#00bcd4", 0.3)};
        a {
            color: ${(props) =>
                getContrastColor(props.theme.primary_color || "#00bcd4")} !important;
        }
    }
    &[disabled] {
        background: gray;
        text-shadow: none;
    }
`;

export const SmallButton = styled.button`
    margin: 0 14px;
    font-size: 18px;
    background: linear-gradient(to top, rgba(235, 235, 235, 0.6), rgba(241, 241, 241, 0.6));
    border-radius: 5px;
    border: 1px solid #ddd;
    text-shadow: 1px 1px #fff;
    color: #444;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    :hover {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: #444;
        }
    }

    :focus {
        outline: none;
    }

    :active {
        box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
        background: linear-gradient(to bottom, rgba(235, 235, 235, 0.8), rgba(241, 241, 241, 0.6));
        border-color: #ccc;
        a {
            color: #444;
        }
    }
    margin: 0 5px;
    font-size: 14px;
    padding: 4px 8px;

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

export const SmallPrimaryButton = styled(PrimaryButton)`
    margin: 0 14px;
    font-size: 18px;
    border-radius: 5px;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    margin: 0 5px;
    font-size: 14px;
    padding: 4px 8px;
`;

export const SmallDangerButton = styled.button`
    margin: 0 14px;
    font-size: 18px;
    border-radius: 5px;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    color: #fff;
    text-shadow: none;
    border: 1px solid rgb(192, 0, 18);
    background: linear-gradient(to bottom, rgb(226, 8, 34), rgb(194, 17, 41));

    :hover {
        box-shadow: 1px 3px 5px rgba(240, 50, 76, 0.3);
        a {
            color: #fff;
        }
    }

    :active {
        box-shadow: 0 0 0;
        background: rgb(143, 16, 33);
        border-color: rgba(240, 50, 76, 0.3);
        a {
            color: #fff;
        }
    }
    :focus {
        outline: none;
    }

    margin: 0 5px;
    font-size: 14px;
    padding: 4px 8px;
`;

export const SmallSuccessButton = styled(SuccessButton)`
    margin: 0 14px;
    font-size: 18px;
    border-radius: 5px;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    a {
        display: block;
        padding: 8px 16px;
    }

    margin: 0 5px;
    font-size: 14px;
    padding: 4px 8px;
`;

export const HoverButton = ({ normal, hover }: any) => {
    const [isHovered, setHover] = useState(false);
    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {isHovered ? (
                <React.Fragment>{hover}</React.Fragment>
            ) : (
                <React.Fragment>{normal}</React.Fragment>
            )}
        </div>
    );
};
