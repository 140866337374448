import { useEffect } from "react";
import { push } from "connected-react-router";
import { SEARCH_FOLDERS_QUERY } from "@rpforms/shared";
import { useDispatch } from "react-redux";

export const useFolderQueryString = ({ hierarchy, search, showFolderEdit }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!toolbar || showFolderEdit) {
            return;
        } // modal window should not update history
        const urlRepresentation = JSON.stringify(
            hierarchy
                .filter((node) => !!node.id)
                .map((node) => {
                    return {
                        id: node.id,
                        resource_name: node.resource_name || node.name,
                    };
                })
        );

        dispatch({
            type: SEARCH_FOLDERS_QUERY,
            payload: { query: "", scope: search.scope },
        });

        const searchField = document.querySelector("#search-field");
        if (searchField) {
            searchField.setAttribute("value", "");
        }
        const currentPath = `/folders?open=${encodeURIComponent(urlRepresentation)}`;
        if (document.location.search !== "?ref=nav") {
            sessionStorage.setItem("folderPath", currentPath);
        }
        dispatch(push(currentPath));
    }, [hierarchy]);
};
