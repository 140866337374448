import React from "react";
import { Button, Modal } from "react-bootstrap";

export const UploadModal = ({ children, isOpen, handleClose }: any) => {
    return (
        <Modal size="lg" show={isOpen} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Button variant="secondary" onClick={handleClose}>
                    Schließen
                </Button>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
        </Modal>
    );
};
