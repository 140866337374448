import React from "react";
import { useEffect, useState } from "react";

export const Typing = ({ text }) => {
    const parts = text.split("");
    const speed = 5;
    const [written, setWritten] = useState([]);
    useEffect(() => {
        const timer = setInterval(() => {
            if (parts.length === written.length) {
                return;
            }

            setWritten((p) => (p.length === parts.length ? p : [...p, parts[p.length]]));
        }, speed);
        return () => {
            clearInterval(timer);
        };
    }, [text]);
    const writtenText = written.join("");
    return <span>{writtenText}</span>;
};
