// @ts-ignore
import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";

export class StyleField extends BaseField {
    public label = "Stylesheet";
    public icon = "css3";
    public items = [];
    public code = ".rpfe-field { background: red; }";
    public fieldType = "Stylesheet";
    public attributeNames = ["code", "isActive"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>CSS Code</label>
                        <textarea
                            name={field.inputName("code")}
                            className={"form-control"}
                            defaultValue={field.code}
                            ref={register}
                        />
                    </div>
                </div>
                <div className={"text-muted"}>
                    Der Stylesheet wird sowohl im PDF als auch in der Formularansicht geladen.
                </div>

                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("isActive")}
                            className="form-control"
                            defaultChecked={field.isActive}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Style aktiveren</label>
                        <div className="text-muted">Soll der Stylesheet angewendet werden?</div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
    }) {
        if (field.isActive) {
            const styleTag = document.createElement("style");
            styleTag.innerText = field.code;
            document.body.appendChild(styleTag);
        }
        return <span></span>;
    }

    public PresenterComponent({ field }) {
        return <span></span>;
    }

    public rawValueToOption(field, optionId) {
        const option = field.items.find((item) => {
            return item.id === optionId;
        });
        if (!option) {
            return "Keine Option ausgewählt.";
        }
        return option.name;
    }
}
