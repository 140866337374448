import React from "react";
import AddField from "../components/AddField";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";
import { ColumnField } from "./ColumnField";
import { Description, Hint, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { ISubfields } from "./interfaces/ISubfields";

export class RowField extends BaseField implements ISubfields {
    public name = "";
    public label = "Reihe";
    public subfields = [];
    public icon = "indent";
    public fieldType = "Reihe";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Description, Width, Hint];
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        name,
        register,
        readonly = false,
    }) {
        const rowClass = "";

        return field.configureInput(
            <div>
                {layout.wrapDescription(
                    field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                )}
                {field.subfields ? (
                    field.subfields.map((subfield) => (
                        <subfield.InputComponent
                            readonly={readonly}
                            layout={layout}
                            register={register}
                            key={subfield.hashCode}
                            schema={schema}
                            name={subfield.hashCode}
                            field={subfield}
                            entry={null}
                        />
                    ))
                ) : (
                    <span></span>
                )}
                {layout.wrapHint(field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>)}
            </div>
        );
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        const handleNewField = (fieldClass) => {
            schema.subfield(field, new fieldClass(schema));
            designer.save(schema, { delaySave: true });
        };

        return (
            <FieldWrapper small={true} designer={designer} schema={schema} field={field}>
                <div className="form-group p-1">
                    <div className="powerform-row-edit d-flex">
                        {field.subfields ? (
                            field.subfields.map((subfield) => (
                                <div
                                    key={subfield.hashCode}
                                    style={{ width: "100%", margin: "0 10px" }}
                                >
                                    <subfield.EditComponent
                                        register={register}
                                        schema={schema}
                                        designer={designer}
                                        field={subfield}
                                        entry={null}
                                    />
                                </div>
                            ))
                        ) : (
                            <span></span>
                        )}
                    </div>
                    <div className="mt-5 pl-1">
                        <AddField
                            small={true}
                            fields={[ColumnField]}
                            handleNewField={handleNewField}
                        />
                    </div>
                </div>
            </FieldWrapper>
        );
    }
}
