import React from "react";
import { uniqueID } from "../utils";

import { BaseField } from "./BaseField";

import { Conditional, Description, Hint, Width } from "./configurables";

import { FieldWrapper } from "../components/FieldWrapper";

import { Label, LabelInput } from "./inputFields/label";

export class TitleField extends BaseField {
    public name = "";
    public label = "";
    public hashCode = uniqueID();
    public icon = "bold";
    public fieldType = "Titel";

    public getConfigurables() {
        return [Conditional, Width, Description];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <label>Titel</label>
                <LabelInput register={register} field={field} />
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({ layout, schema, field, entry = null, register, name = field.name }) {
        return (
            <>
                <h4>
                    <Label layout={layout} field={field} />
                </h4>
                {layout.wrapDescription(
                    field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                )}
            </>
        );
    }
}
