import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";

interface IModalEntity {
    editor: any;
    next: (payload) => {};
}

export const ModalDevice = ({ editor, next }: IModalEntity) => {
    const [extraArgs, setExtraArgs] = useState({});
    const [device, setDevice] = useState({ ...editor.config.preload });
    const { register, handleSubmit, watch, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        data = { ...data, company_id: 1 };
        const deviceStore: any = await next({
            device: { ...editor.config.preload, ...data, ...extraArgs },
        });
        if (editor.config.redirect) {
            await dispatch(push("/master_data/devices/view-" + deviceStore.id));
        }
    };

    const isNew = editor.config.new;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{isNew ? "Neues Gerät erstellen" : "Gerät bearbeiten"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label>Name</label>
                        <input
                            defaultValue={device.name}
                            className={"form-control"}
                            name="name"
                            ref={register({
                                required: "Name muss ausgefüllt sein",
                                pattern: {
                                    value: /.*([^\.,\-+#´`;:\(\)<>\{\}\[\]~&@?!/\\^° ])$/i,
                                    message: "Name muss mit Buchstabe oder Zahl enden",
                                },
                            })}
                        />
                        {errors.name && <InputError>{errors.name.message}</InputError>}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {editor.saving && <>speichert …</>}
                <PrimaryButton
                    disabled={editor.saving}
                    style={editor.saving ? { background: "gray" } : {}}
                    type={"submit"}
                >
                    {isNew ? "Erstellen" : "Speichern"}
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
