import { toSelectOptions } from "@rpforms/shared";
import {
    createCalendarEvent,
    getCalendarEventById,
    getDevices,
    getEntities,
    updateCalendarEvent,
} from "@rpforms/shared/build/actions";
import { getCalendarEventParticipants } from "@rpforms/shared/build/actions/calendar_event_participants";
import { getCalendarEventTypes } from "@rpforms/shared/build/actions/calendar_event_types";
import { fetchEmployees } from "@rpforms/shared/build/actions/employees";
import { SmallDangerButton } from "@rpforms/shared/build/components/layout/Button";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { push } from "connected-react-router";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import CalendarEventTypeSelector from "../../components/calendar/CalendarEventTypeSelector";
import { RootState } from "../../reducers";

const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

const NewCalendarEventPage = ({ hideNavbar }: any) => {
    const { id }: { id: string } = useParams();
    const deviceId = useLocation().search.split("?")[1]?.split("device=")[1] ?? null;

    const events = useSelector<RootState, any>((store) => store.calendarEvents.calendarEvents);
    const employees = useSelector<RootState, any>((store) => store.employees.employees);
    const allParticipants = useSelector<RootState, any>(
        (store) => store.calendarEventParticipants.participants
    );
    const entities = useSelector<RootState, any>((store) => store.entities.entities);
    const types = useSelector<RootState, any>((store) => store.calendarEventTypes.types);
    const devices = useSelector<any, any>((state) => state.device.devices);

    const [focusedInput, setFocusedInput] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [notes, setNotes] = useState("");
    const [showEntities, setShowEntities] = useState(false);
    const [eventType, setEventType] = useState<any>(null);
    const [startingTime, setStartingTime] = useState(null);
    const [endingTime, setEndingTime] = useState(null);
    const [participants, setParticipants] = useState<any>([]);
    const [location, setLocation] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectableEntities, setSelectableEntities] = useState(null);
    const [selectableDevices, setSelectableDevices] = useState(null);
    const [event, setEvent] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        if (types.length === 0 || id) {
            return;
        }
        //setEventType(() => types.find((e) => e.name === "Ausführung"));
    }, [types]);

    useEffect(() => {
        dispatch(getCalendarEventTypes());
        dispatch(getEntities());
        dispatch(getDevices());
        dispatch(getCalendarEventById(id));
    }, []);

    useEffect(() => {
        if (entities.length > 0) {
            setSelectableEntities(() => toSelectOptions(entities));
        }
    }, [entities]);

    useEffect(() => {
        if (devices.length > 0) {
            setSelectableDevices(() => toSelectOptions(devices));
        }
        if (deviceId) {
            setSelectedDevice(devices.find((d) => d.id === parseInt(deviceId)));
        }
    }, [devices]);

    useEffect(() => {
        if (!id || devices.length === 0 || types.length === 0 || entities.length === 0) {
            return;
        }
        const fetchedEvent = events.find((e) => e.id === parseInt(id));
        const type = types.find((t) => t.id === fetchedEvent?.calendar_event_type_id);
        setEvent(() => fetchedEvent);
        setEventType(() => type || types.find((t) => t.name === "Ausführung"));
        if (type?.name === "Seminar") {
            dispatch(getCalendarEventParticipants());
        }
        setName(() => fetchedEvent?.name);
        setNotes(() => fetchedEvent?.notes);
        setStartDate(() => moment.utc(fetchedEvent?.scheduled_from));
        setEndDate(() => moment.utc(fetchedEvent?.scheduled_to));
        setShowEntities(() => !!fetchedEvent?.entity_id);
        if (moment.utc(fetchedEvent?.scheduled_from).hours() > 0) {
            setStartingTime(() => moment.utc(fetchedEvent?.scheduled_from).format("HH:mm"));
        }
        if (moment.utc(fetchedEvent?.scheduled_to).hours() > 0) {
            setEndingTime(() => moment.utc(fetchedEvent?.scheduled_to).format("HH:mm"));
        }
        if (!deviceId) {
            setSelectedDevice(() => devices.find((d) => d.id === fetchedEvent?.device_id));
        }
        setSelectedEntity(() => entities.find((entity) => entity.id === fetchedEvent?.entity_id));
        setLocation(() => fetchedEvent?.location);
        setPhone(() => fetchedEvent?.phone);
        setContactPerson(() => fetchedEvent?.contactPerson);
    }, [id, events, devices, entities]);

    useEffect(() => {
        if (!eventType) {
            return;
        }
        if (eventType?.name === "Seminar" && employees.length === 0) {
            dispatch(fetchEmployees());
        }

        onCreateClick();
    }, [eventType]);

    useEffect(() => {
        if (
            allParticipants.length > 0 &&
            event &&
            eventType?.name === "Seminar" &&
            employees.length > 0
        ) {
            const eventParticipants = allParticipants
                .filter((p) => p.calendar_event_id === event.id)
                .map((p) => p.employee_id);
            setParticipants(() =>
                toSelectOptions(employees.filter((e) => eventParticipants.includes(e.id)))
            );
        }
    }, [allParticipants, event, employees]);

    const onCreateClick = async () => {
        const scheduled_from = moment
            .utc(
                moment.utc(startDate).format("YYYY-MM-DD") +
                    (startingTime ? startingTime : "00:00"),
                "YYYY-MM-DDhh:mm"
            )
            .format(dateFormat);
        const scheduled_to = moment
            .utc(
                moment.utc(endDate).format("YYYY-MM-DD") + (endingTime ? endingTime : "00:00"),
                "YYYY-MM-DDhh:mm"
            )
            .format(dateFormat);

        if (!eventType || eventType?.name === "Ausführung" || eventType?.name === "Besichtigung") {
            const params = {
                scheduled_from,
                scheduled_to,
                device_id: selectedDevice?.id ?? null,
                calendar_event_type_id:
                    eventType?.id || types.find((t) => t.name === "Ausführung").id,
            };
            await dispatch(
                id
                    ? updateCalendarEvent({ id: id, ...params }, true)
                    : createCalendarEvent(params, true)
            );
            if (id) {
                await dispatch(push(`/master_data/devices/view-${deviceId}/event-${id}#scroll`));
            }
            return;
        }

        const params = {
            notes,
            name,
            location,
            scheduled_from,
            scheduled_to,
            calendar_event_type_id: eventType?.id,
            participants: participants.length > 0 ? participants.map((p) => p.value) : null,
            phone,
            contactperson: contactPerson,
            entity_id: selectedEntity?.value || null,
            device_id: selectedDevice?.id ?? null,
        };
        if (!event) {
            await dispatch(createCalendarEvent(params));
        } else {
            await dispatch(updateCalendarEvent({ id, ...params }));
        }
        toast.success("✌️ Event gespeichert", { autoClose: 2000 });
        await dispatch(push(`/master_data/devices/view-${deviceId}/event-${id}#scroll`));
        return;
    };

    if (!types || (id && (!(selectableEntities?.length > 0) || !(selectableDevices?.length > 0)))) {
        return <Loader />;
    }

    return (
        <>
            {!hideNavbar && (
                <PageNavbar
                    title="Kalenderevent"
                    description={
                        event
                            ? "Hier können Sie ein bestehendes Kalenderevent bearbeiten."
                            : "Hier können Sie ein Kalenderevent erstellen. Bei Ausführungen können Sie im nächsten Schritt diesem Aufgaben hinzufügen und einen passenden geben."
                    }
                >
                    <PageNavAction
                        onClick={onCreateClick}
                        success={true}
                        icon="save"
                        title="Speichern"
                    />
                    <span className="mr-2"></span>
                    <PageNavAction
                        link="/calendar"
                        primary={true}
                        icon="calendar"
                        title="Zurück zum Terminplaner"
                    />
                </PageNavbar>
            )}
            <Content>
                <>
                    <div>
                        <Row>
                            <Col xs={6}>
                                <label>Typ</label>
                                {eventType?.color && (
                                    <span
                                        className="ml-2"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            backgroundColor: eventType?.color,
                                            borderRadius: "5px",
                                        }}
                                    />
                                )}
                                <CalendarEventTypeSelector
                                    types={types}
                                    eventType={eventType}
                                    setEventType={setEventType}
                                />
                            </Col>
                        </Row>
                    </div>
                </>
            </Content>
        </>
    );
};

export default NewCalendarEventPage;
