import {
    NOTIFICATION_GROUPS_LIST_LOADED,
    NOTIFICATION_GROUPS_LOADING_START,
    NOTIFICATION_GROUPS_LOADING_STOP,
} from "../actions/types";

const initialState = {
    notification_groups: [],
    total: 0,
    pages: 1,
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case NOTIFICATION_GROUPS_LIST_LOADED:
            return {
                ...state,
                notification_groups: action.payload,
            };
        case NOTIFICATION_GROUPS_LOADING_START:
            return { ...state, isLoading: true };
        case NOTIFICATION_GROUPS_LOADING_STOP:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}
