import moment from "moment";

/**
 * Generates GUIDs according to spec while also prefixing it with a _
 * to allow the GUID to be used as form keys and names.
 */
export const uniqueID = (prefix = "_") => {
    return `${prefix}xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, (c) => {
        // tslint:disable-next-line:one-variable-per-declaration no-bitwise
        const r = (Math.random() * 16) | 0,
            // tslint:disable-next-line:no-bitwise
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const toStr = (s) => {
    if (!s) {
        return "";
    }
    return s.toString();
};

export const isVisible = (e) => {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
};

/**
 * *
 * @param array
 * @param from
 * @param to
 */
export const arrayMoveMutate = (array, from, to) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
};

export const arrayMove = (array, from, to) => {
    array = [...array];
    arrayMoveMutate(array, from, to);
    return array;
};

export const withTasks = (entity: IEntity) => {
    return entity.device_tasks.length > 0;
};

export const shade = (color, percent) => {
    if (color.indexOf("#") === 0) {
        color = color.substr(1);
    }
    const num = parseInt(color, 16);
    const amt = Math.round(2.55 * percent);
    // tslint:disable-next-line:no-bitwise
    const R = (num >> 16) + amt;
    // tslint:disable-next-line:no-bitwise
    const B = ((num >> 8) & 0x00ff) + amt;
    // tslint:disable-next-line:no-bitwise
    const G = (num & 0x0000ff) + amt;

    return (
        "#" +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1)
    );
};

export const highestEntityNr = (entities: IEntity[]) => {
    const sortedEntitiesNrs = entities
        .map((c) => c.entity_nr || "0")
        .sort((a, b) => {
            a = a.replace(/[a-z]/i, "");
            b = b.replace(/[a-z]/i, "");
            if (parseInt(a, 10) > parseInt(b, 10)) {
                return 1;
            } else if (parseInt(a, 10) < parseInt(b, 10)) {
                return -1;
            } else {
                return 0;
            }
        });
    return last(sortedEntitiesNrs);
};

export const highestCustomerNr = (customers: ICustomer[]) => {
    const sortedCustomerNrs = customers
        .filter((c) => !!c)
        .map((c) => c.customer_nr || "0")
        .sort((a, b) => {
            a = a.replace(/[a-z]/i, "");
            b = b.replace(/[a-z]/i, "");
            if (parseInt(a, 10) > parseInt(b, 10)) {
                return 1;
            } else if (parseInt(a, 10) < parseInt(b, 10)) {
                return -1;
            } else {
                return 0;
            }
        });
    return last(sortedCustomerNrs);
};

/**
 * Increments 0001 to 0002 etc.
 * @param num
 */
export const padIncrement = (num) => {
    let prefix = "";
    // num might have a prefix
    if (/^[A-Z]/i.test(num)) {
        prefix = num[0];
        num = num.replace(/[a-z]/i, "");
    }

    const padLength = `${num}`.length;
    return pad(parseInt(num, 10) + 1, Math.max(padLength, 4));
};

export const pad = (num, length) => {
    return `${num}`.padStart(length, "0");
};

export const sort = (list: any, key = "name") => {
    return list.sort((a: any, b: any) => {
        if (typeof a === "string" && typeof b === "string") {
            return a.localeCompare(b);
        }

        return a[key] && String(a[key]).localeCompare(String(b[key]));
    });
};

export const filterBy = (list: any[], prop: string) => {
    return list.reduce((prev: any, cur: any) => {
        if (!prev.find((b: any) => cur[prop] === b[prop])) {
            return [...prev, cur];
        }
        return prev;
    }, []);
};

export const translucentColor = (hex: string, translucency: number) => {
    if (!hex) {
        return "";
    }
    const [r, g, b] = [
        parseInt(hex.slice(1, 3), 16),
        parseInt(hex.slice(3, 5), 16),
        parseInt(hex.slice(5, 7), 16),
    ];

    return `rgba(${r}, ${g}, ${b}, ${translucency})`;
};

export const getContrastColor = (hexcolor: string) => {
    if (!hexcolor) {
        return "black";
    }
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 168 ? "black" : "white";
};

export const stripTags = (str: string) => {
    const d = document.createElement("div");
    d.innerHTML = str;
    return d.textContent;
};

export const extensionFromMimeType = (mime: string) => {
    switch (mime) {
        case "image/jpeg":
        case "image/jpg":
            return "jpg";
        case "image/png":
            return "png";
        case "image/gif":
            return "gif";
        default:
            return mime.split("/")[1];
    }
};

export const getField = (fieldIdentifier: string, htmlFormId) => {
    let prefix = "";
    if (htmlFormId) {
        prefix = `#${htmlFormId} `;
    }
    const node: any = document.querySelector(`${prefix}[name='${fieldIdentifier}']`);
    if (node && node.tagName.toLowerCase() === "input") {
        return node.value;
    }

    const data = getFormData(htmlFormId);
    const value = data[fieldIdentifier];
    const optionField = document.querySelector(`${prefix}option[value='${value}']`);
    if (optionField) {
        return optionField.innerHTML;
    }

    if (value) {
        return value;
    }

    return false;
};

export const getFormData = (htmlFormId = "form") => {
    let prefix = "";

    if (htmlFormId) {
        prefix = `#${htmlFormId} `;
    }

    const elements: any = [...(document as any).querySelectorAll(`${prefix}[name]`)];
    return [...elements]
        .filter((e) => !!e)
        .reduce((p, c) => {
            if (!["checkbox", "radio"].includes(c.type) || c.checked) {
                p[c.name] = c.value;
            }
            return p;
        }, {});
};

const calculateAspectRatioFit = (
    srcWidth: number,
    srcHeight: number,
    maxWidth: number,
    maxHeight: number
) => {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const resizeImage = (url: string, maxWidth: number, maxHeight: number): Promise<string> => {
    return new Promise((resolve) => {
        const sourceImage = new Image();

        sourceImage.onload = () => {
            const canvas = document.createElement("canvas");
            const { width, height } = calculateAspectRatioFit(
                sourceImage.width,
                sourceImage.height,
                maxWidth,
                maxHeight
            );

            canvas.width = width;
            canvas.height = height;

            canvas.getContext("2d")!.drawImage(sourceImage, 0, 0, width, height);
            resolve(canvas.toDataURL());
        };

        sourceImage.src = url;
    });
};

export const doNothing = () => {
    return;
};

// https://gist.github.com/borteo/38c3f9aa40eba53768a5
export const rjust = (str, width, padding) => {
    padding = padding || " ";
    padding = padding.substr(0, 1);
    if (str.length < width) {
        return padding.repeat(width - str.length) + str;
    } else {
        return str;
    }
};

// https://stackoverflow.com/a/32922084/1268652
export const isEqual = (x, y) => {
    const tx = typeof x;
    const ty = typeof y;
    return x && y && tx === "object" && tx === ty
        ? Object.keys(x).length === Object.keys(y).length &&
              Object.keys(x).every((key) => isEqual(x[key], y[key]))
        : x === y;
};

export const isEmpty = (value) => {
    return value == null || value.length === 0;
};

export const template = (s: string, variables: any, htmlFormId = "form") => {
    const re = /{[:?(\w+|\.)]+}/gi;
    let templateStr = s;
    let m;

    if (!variables) {
        return s;
    }

    const isSubstitutable = (substitute) => {
        return typeof substitute === "string" || typeof substitute === "number";
    };

    const processMatch = (match: string) => {
        const matchParts = match.replace(/[{|}]/gi, "").split(":");
        const [
            propertyName,
            propertyField,
            propertyArgs,
            propertyArgs2,
            propertyArgs3,
        ] = matchParts;
        let substitute: any;
        if (propertyName === "subfield" && variables.subfields) {
            // get field value as replacement
            substitute = variables.subfields[propertyField];
            if (propertyField.startsWith("date")) {
                if (substitute === null || substitute === "") {
                    substitute = "";
                } else {
                    const dateFormat = propertyArgs || "DD.MM.YYYY";
                    substitute = moment(substitute.toString()).format(dateFormat);
                }
            }
        } else if (propertyName === "field") {
            // get field value as replacement
            substitute = getField(propertyField, htmlFormId);
            if (propertyArgs && propertyArgs.startsWith("date")) {
                const dateFormat = propertyArgs2 || "DD.MM.YYYY";
                substitute = moment(substitute).format(dateFormat);
            }
        } else if (
            propertyName === "config" &&
            variables.config &&
            !variables.config[propertyField]
        ) {
            substitute = variables.config.rawField[propertyField];
        } else if (propertyName === "date") {
            const dateFormat = propertyArgs || "DD.MM.YYYY";
            let date: any = new Date();
            if (propertyField !== "current") {
                date = propertyField;
            }
            substitute = moment(date).format(dateFormat);
        } else if (propertyName === "form_entry") {
            substitute = match;
        } else if (
            propertyName === "customer" &&
            propertyField === "addresses" &&
            propertyArgs != null
        ) {
            if (
                !isEmpty(variables[propertyName]) &&
                isEmpty(variables[propertyName][propertyField])
            ) {
                const addr = variables[propertyName][propertyField].find((a) => {
                    return a.address_type === propertyArgs;
                });
                if (addr && typeof addr === "object" && addr.name) {
                    substitute = addr.name;
                }
            }
        } else {
            substitute = getField(propertyField, htmlFormId);
        }
        if (!substitute) {
            if (variables && propertyName) {
                substitute = variables[propertyName];
            }

            if (
                substitute &&
                propertyField &&
                (substitute[propertyField] || Number.isInteger(substitute[propertyField]))
            ) {
                substitute = substitute[propertyField];
            }

            if (
                substitute &&
                propertyArgs &&
                (substitute[propertyArgs] || Number.isInteger(substitute[propertyArgs]))
            ) {
                substitute = substitute[propertyArgs];
            }

            if (
                substitute &&
                propertyArgs2 &&
                (substitute[propertyArgs2] || Number.isInteger(substitute[propertyArgs2]))
            ) {
                substitute = substitute[propertyArgs2];
            }

            if (
                substitute &&
                propertyArgs3 &&
                (substitute[propertyArgs3] || Number.isInteger(substitute[propertyArgs3]))
            ) {
                substitute = substitute[propertyArgs3];
            }
            if (typeof substitute === "number") {
                if (
                    propertyArgs === "next" ||
                    propertyArgs2 === "next" ||
                    propertyArgs3 === "next"
                ) {
                    substitute = substitute + 1;
                }
                if (
                    propertyArgs === "rjust3" ||
                    propertyArgs2 === "rjust3" ||
                    propertyArgs3 === "rjust3"
                ) {
                    substitute = rjust("" + substitute, 4, "0");
                }
            }
            /*if (substitute && typeof substitute === "object" && substitute.name) {
                substitute = substitute.name;
            }*/
        }
        if (
            typeof substitute === "string" &&
            substitute.startsWith(`[{"label"`) &&
            substitute.endsWith("}]")
        ) {
            substitute = JSON.parse(substitute)
                .map((o) => o.label)
                .join("-");
        }
        templateStr = isSubstitutable(substitute)
            ? templateStr.replace(match, substitute || "")
            : templateStr.replace(match, "");
    };

    // tslint:disable-next-line:no-conditional-assignment
    while ((m = re.exec(s)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === re.lastIndex) {
            re.lastIndex++;
        }

        try {
            m.forEach(processMatch);
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.warn(e);
        }
    }

    return templateStr;
};

/**
 * Generates a hashCode from the provided string
 * @param input
 */
export const hashCode = (input: string): number => {
    let hash: number = 0;
    let i: number;
    let chr: number;

    if (input.length === 0) {
        return hash;
    }
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash = (hash << 5) - hash + chr;
        // tslint:disable-next-line:no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

/**
 * Sends a GET request to the provided URL
 * @param url
 */
export const xhr = async (url: string) => {
    return new Promise((resolve, reject) => {
        const newXHR = new XMLHttpRequest();

        function reqListener() {
            if (newXHR.status === 200) {
                resolve(JSON.parse(newXHR.responseText));
            } else {
                reject();
            }
        }

        newXHR.addEventListener("load", reqListener);
        newXHR.open("GET", url);
        newXHR.send();
    });
};

/**
 * Sends a post request to the provided url
 * @param url
 * @param data
 */
export const xhrPost = async (url: string, data: any) => {
    return new Promise((resolve, reject) => {
        const newXHR = new XMLHttpRequest();

        // event handler
        function reqListener() {
            if (newXHR.status === 200) {
                resolve(JSON.parse(newXHR.responseText));
            } else {
                reject();
            }
        }

        newXHR.addEventListener("load", reqListener);
        newXHR.open("POST", url);
        newXHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

        const formattedJsonData = JSON.stringify(data);
        newXHR.send(formattedJsonData);
    });
};

/**
 * Generate a color based on a hashCode
 * @param input
 * @param alpha
 */
export const hueForHash = (input: string, alpha = 0.5) => {
    const hsl = Math.abs(hashCode(input)) % 361;
    return `hsla(${hsl}, 100%, 65%, ${alpha})`;
};

/**
 * Generate a lighter color based on a hashCode
 * @param input
 * @param alpha
 */
export const lightHueForHash = (input: string, alpha = 0.5) => {
    const hsl = Math.abs(hashCode(input)) % 361;
    return `hsla(${hsl}, 80%, 65%, ${alpha})`;
};

export const moveArrayElement = (arr: any[], oldIndex: number, newIndex: number) => {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
};

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const first = (array: any[]) => {
    return array[0];
};

export const last = (array: any[]) => {
    return array[array.length - 1];
};

export const range = (start = 0, end = 10) => {
    return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
};

export const toSelectOptions = (
    array: any[],
    labelKey = "name",
    valueKey = "id",
    transformLabel = (obj, label) => label,
    allOptionName = null
) => {
    if (array[0] && (typeof array[0] === "number" || typeof array[0] === "string")) {
        // convert to „object“
        array = array.map((n) => {
            const o = {};
            o[valueKey] = n;
            o[labelKey] = n;
            return o;
        });
    }
    if (allOptionName != null) {
        const allOption = {};
        allOption[labelKey] = allOptionName;
        allOption[valueKey] = "";
        array = [allOption, ...array];
    }
    return array
        .filter((x) => x != null && x !== "")
        .map((a) => {
            return {
                value: a[valueKey],
                label: transformLabel(a, a[labelKey]),
                isDisabled: false,
            };
        });
};

export const toSelectOptionsWithAll = (
    array: any[],
    allOptionName = null,
    labelKey = "name",
    valueKey = "id"
) => {
    return toSelectOptions(array, labelKey, valueKey, (obj, label) => label, allOptionName);
};

export const filterOptions = (inputValue: string, options: any[]) => {
    return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
};

export const toCurrencyString = (priceInCents: number) => {
    return (Math.round(priceInCents) / 100.0).toFixed(2);
};

// maybe use https://github.com/iriscouch/bigdecimal.js
export const toCentsInt = (strValue) => {
    return parseInt(parseFloat(strValue).toFixed(2).replace(".", ""), 10);
};

export const positiveNumberKeyDownEvent = (event) => {
    if (event.keyCode === 189 || event.keyCode === 109) {
        event.preventDefault();
        return false;
    }
};

export const swapArrayItems = (a, i, i2) => {
    if (i < 0 || i >= a.length || i2 < 0 || i2 >= a.length) {
        return a;
    }
    const arr = a.slice(0);
    const tmp = arr[i];
    arr[i] = arr[i2];
    arr[i2] = tmp;
    return arr;
};

let formDataEntries;

if (typeof FormData === "function" && "entries" in FormData.prototype) {
    formDataEntries = (form) => {
        return Array.from((new FormData(form) as any).entries());
    };
} else {
    formDataEntries = (form) => {
        const entries = [];

        for (const el of form.elements) {
            const tagName = el.tagName.toUpperCase();

            if (tagName === "SELECT" || tagName === "TEXTAREA" || tagName === "INPUT") {
                const type = el.type;
                const name = el.name;

                if (
                    name &&
                    !el.disabled &&
                    type !== "submit" &&
                    type !== "reset" &&
                    type !== "button" &&
                    ((type !== "radio" && type !== "checkbox") || el.checked)
                ) {
                    if (tagName === "SELECT") {
                        const options = el.getElementsByTagName("option");
                        for (const option of options) {
                            if (option.selected) {
                                entries.push([name, option.value]);
                            }
                        }
                    } else if (type === "file") {
                        // tslint:disable-next-line:no-console
                        console.warn("form-data-entries could not serialize <input type=file>", el);
                        entries.push([name, ""]);
                    } else {
                        entries.push([name, el.value]);
                    }
                }
            }
        }

        return entries;
    };
}

export default formDataEntries;

export const fromEntries = (formData) => {
    return Array.from(formDataEntries(formData)).reduce((memo: any, pair) => {
        const [field, value]: any = pair;
        return {
            ...memo,
            [field]: value,
        };
    }, {});
};

// inspired by https://github.com/joe-re/object-dig
export const dig = (target, ...keys) => {
    let digged = target;
    for (const key of keys) {
        if (typeof digged === "undefined" || digged === null) {
            return undefined;
        }

        digged = typeof key === "function" ? key(digged) : digged[key];
    }
    return digged;
};

export const findInFields = (fields, condition) => {
    let result = false;
    if (!fields || typeof fields !== "object") {
        return false;
    }
    result = fields.find((field) => {
        return condition.call(null, field);
    });
    if (result) {
        return result;
    }
    for (const field of fields) {
        result = findInFields(field.subfields, condition);
        if (result) {
            return result;
        }
    }
    return result;
};

export const templateVariables = (formEntry) => {
    const formData = dig(formEntry, "form_revision", "form_data");
    let configField = {};
    try {
        const fieldsArr = formData ? JSON.parse(formData) : null;
        configField = findInFields(fieldsArr.fields, (f) => {
            return f.type === "ConfigField";
        });
    } catch (e) {
        // tslint:disable-next-line:no-console
        console.warn(e);
    }

    return {
        form: dig(formEntry, "form"),
        company: dig(formEntry, "entity", "company"),
        entity: dig(formEntry, "entity"),
        customer: dig(formEntry, "customer"),
        config: configField,
    };
};

export const htmlId = (formEntry) => {
    return `form-entry-${formEntry.id || "new"}`;
};

// TODO: @pr => gestubbte Funktion umbauen analog jquery.parent()
export const findParent = (node, tagName = "form") => {
    return node;
};

export const toDigit = (input) => {
    if (!Number.isNaN(Number(input))) {
        return input;
    }

    input = input.replace(",", ".");
    input = input.replace(/.*?(([0-9]*\.)?[0-9]+).*/g, "$1");
    if (!Number.isNaN(Number(input))) {
        return input;
    }

    return 0;
};

export const now = () => {
    return moment(new Date());
};

export const currentPlatform = () => {
    if (!document.querySelector) {
        return "app";
    }
    if (document.querySelectorAll(".ion-page").length > 0) {
        return "app";
    } else {
        return "web";
    }
};

export const flatDeep = (arr, d = 1) => {
    return d > 0
        ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
        : arr.slice();
};

export const inventoryItemTypes = () => {
    return {
        repair: "Reparatur",
        service: "Service",
        disposal: "Verkauf",
    };
};

export const inventoryItemFields = (t) => {
    const def = [
        {
            name: "Artikel-Nr",
            attribute_name: "nr",
            sortable: "inventory_items.nr",
        },
        {
            name: "Name",
            attribute_name: "name",
            sortable: "inventory_items.name",
        },
        {
            name: "Beschreibung",
            attribute_name: "description",
            sortable: "inventory_items.description",
        },
        {
            name: "Hersteller",
            attribute_name: "manufacturer",
            sortable: "manufacturers.name",
        },
        {
            name: "Gewerk",
            attribute_name: "category",
            sortable: "categories.name",
        },
        {
            name: "Anzahl",
            attribute_name: "quantity",
            className: "text-right",
            sortable: false,
        },
        {
            name: "EK-Preis",
            attribute_name: "buying_price_formatted",
            className: "text-right",
            sortable: false,
        },
        {
            name: "VK-Preis",
            attribute_name: "sales_price_formatted",
            className: "text-right",
            sortable: false,
        },
        {
            name: "Bestandswert",
            attribute_name: "total_buying_price_formatted",
            className: "text-right",
            sortable: false,
        },
    ];
    const m = {
        repair: [
            {
                name: "Gewerk",
                attribute_name: "category",
                sortable: "categories.name",
            },
            {
                name: "Name",
                attribute_name: "name",
                sortable: "inventory_items.name",
            },
            {
                name: "Beschreibung",
                attribute_name: "description",
                sortable: "inventory_items.description",
            },
            {
                name: "VK-Preis",
                attribute_name: "sales_price_formatted",
                className: "text-right",
                sortable: false,
            },
        ],
        disposal: def,
        service: [
            {
                name: "Name",
                attribute_name: "name",
                sortable: false,
            },
            {
                name: "Beschreibung",
                attribute_name: "description",
                sortable: false,
            },
            {
                name: "Preis",
                attribute_name: "sales_price_formatted",
                className: "text-right",
                sortable: false,
            },
        ],
    };
    return m.hasOwnProperty(t) ? m[t] : def;
};

export const folderNameRegex = () => {
    /* eslint-disable */
    return /.*([^\.,\-+#´`;:\(\)<>\{\}\[\]~&@?!/\\^° ])$/i;
    /* eslint-enable */
};

export const uniqueArray = (a) => {
    return [...new Set(a)];
};
