import { FORMS_SYNC_SELECTION, syncForms, toSelectOptions } from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../reducers";
import Select from "react-select";

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    font-size: 16px !important;
    width: 100%;
    // align svg icons with fonts
    svg {
        position: relative;
        top: -2px;
    }
`;

enum ENVIRONMENT_NAMES {
    LIVE = "Live",
    STAGING = "Staging",
    CUSTOM = "Benutzerdefiniert",
}

const TARGET_ENVIRONMENTS = [
    {
        name: ENVIRONMENT_NAMES.LIVE,
        url: "https://api.rpforms.kega.network",
        deviceToken:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo4LCJkZXZpY2VfaWQiOjMsImV4cCI6MTY4MzQ4MjAwNH0.yPzrISelf2MU4k1p1q_o2W2Ir4JkvE2xQIlbjMohzww",
    },
    {
        name: ENVIRONMENT_NAMES.STAGING,
        url: "https://api.staging.rpforms.kega.network",
        deviceToken:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMCwiZGV2aWNlX2lkIjoxLCJleHAiOjE2OTg2NzM4NTB9._to-W5nCbmBcgMMT1V82oY6pf-n8T-l1p59hdpn06uw",
    },
    { name: ENVIRONMENT_NAMES.CUSTOM, url: "http://localhost:3009", deviceToken: "" },
];

export const FormsSyncModal = ({ show, setVisibility }) => {
    const forms = useSelector<RootState, any>((state) => state.forms);
    const dispatch = useDispatch();
    const [endpoint, setEndpoint] = useState(TARGET_ENVIRONMENTS[TARGET_ENVIRONMENTS.length - 1]);
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const company = useSelector<any, any>((state) => state.company);

    const onEnvironmentChange = ({ value, label }: { value: string; label: string }) => {
        setEndpoint(
            TARGET_ENVIRONMENTS.find((e) => e.url === value) ??
                TARGET_ENVIRONMENTS[TARGET_ENVIRONMENTS.length - 1]
        );
    };

    const sync = () => {
        dispatch(
            syncForms(forms.syncForms, endpoint.url, endpoint.deviceToken, (msg, statusCode) => {
                if (msg) {
                    setSuccess(true);
                    setMessage(msg);
                } else {
                    setMessage("Fehler beim syncen, abgelaufener Device-Token?");
                }
            })
        );
    };

    const close = () => {
        dispatch({ type: FORMS_SYNC_SELECTION, payload: false });
        setVisibility(false);
    };

    return (
        <Modal show={show} onHide={close} size={"lg"} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <StyledModalTitle>
                    <div>
                        Synchronisiere {forms.syncForms.length}{" "}
                        {forms.syncForms.length > 1 ? "Formulare" : "Formular"} von{" "}
                        {company.name ?? "Aktueller Umgebung"} nach {endpoint.name}
                    </div>
                </StyledModalTitle>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
                <Row style={{ padding: "10px 20px" }}>
                    <Col>
                        Hiermit werden die folgenden Formulare auf der Endpunktumgebung (Textfeld
                        Endpunkt) mit denen auf der aktuellen Umgebung ({company.name ?? ""})
                        überschrieben.
                    </Col>
                </Row>
                {!forms.syncIsLoading && !success && (
                    <Row style={{ padding: "10px 20px" }}>
                        <Col>
                            Synchronisiert werden die Formulare:{" "}
                            {forms.forms
                                .filter((f) => forms.syncForms.includes(f.id))
                                .map((f) => f.name)
                                .join(", ")}
                        </Col>
                    </Row>
                )}
                {forms.syncIsLoading && (
                    <div style={{ textAlign: "center" }}>
                        <img src="https://media.giphy.com/media/UEU7BpGn2W9Xy/giphy.gif" />
                    </div>
                )}
                {!forms.syncIsLoading && !success && (
                    <>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <h3>Ziel-Endpoint</h3>
                                <Select
                                    onChange={onEnvironmentChange}
                                    options={toSelectOptions(TARGET_ENVIRONMENTS, "name", "url")}
                                    placeholder={"Benutzerdefiniert"}
                                />
                                {endpoint.name === ENVIRONMENT_NAMES.CUSTOM && (
                                    <input
                                        type="text"
                                        style={{ marginTop: "10px" }}
                                        value={endpoint.url}
                                        onChange={(e) =>
                                            setEndpoint({ ...endpoint, url: e.target.value })
                                        }
                                        className={"form-control"}
                                    />
                                )}
                            </Col>
                        </Row>
                        {endpoint.name === ENVIRONMENT_NAMES.CUSTOM && (
                            <Row style={{ padding: "20px" }}>
                                <Col>
                                    <h3>Device-Token</h3>
                                    <textarea
                                        value={endpoint.deviceToken}
                                        onChange={(e) =>
                                            setEndpoint({
                                                ...endpoint,
                                                deviceToken: e.target.value,
                                            })
                                        }
                                        className={"form-control"}
                                    ></textarea>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                <div style={{ padding: "20px" }}>
                    {message}
                    <br />
                    {forms.syncIsLoading && <>speichert …</>}
                    <br />
                    {!success && (
                        <PrimaryButton onClick={sync} disabled={forms.syncIsLoading}>
                            Synchronisieren
                        </PrimaryButton>
                    )}
                    {success && (
                        <Button variant="success" onClick={close}>
                            Schließen
                        </Button>
                    )}
                    <br />
                </div>
            </Modal.Body>
        </Modal>
    );
};
