import React, { useEffect, useState } from "react";
import { API_URL } from "../../config";

export const LocalUploadPreview = ({ upload, full = false }) => {
    const [datauri, setdatauri] = useState("http://placehold.it/300x300");

    useEffect(() => {
        console.log(upload);
        (async () => {
            const imageReq = await fetch(`${API_URL}/form_entry_assets.json?uuid=${upload.uuid}`);
            if (imageReq.status !== 200) {
                if (window.AssetManager && upload.file && upload.file.path) {
                    window.AssetManager.toBlob(upload.file).then((uri) => {
                        console.log(uri);
                        setdatauri(uri);
                    });
                }
            }
            setdatauri(`${API_URL}/form_entry_assets.json?uuid=${upload.uuid || upload.file.uuid}`);
        })();
    }, [upload]);

    return (
        <div>
            <img width={full ? "100%" : "100px"} src={datauri} />
        </div>
    );
};
