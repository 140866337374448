import { SEARCH_FOLDERS_QUERY } from "@rpforms/shared/build/actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button, FormCheck } from "react-bootstrap";

const FolderSearchWrapper = styled.div`
    display: flex;
    align-items: center;

    i {
        padding: 0 15px;
    }
`;

const FolderSearchSettings = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;

    > div {
        min-width: 120px;
    }
`;

export const FolderSearch = () => {
    const currentSearch: any = useSelector<any>((state) => state.directory.search);
    const dispatch = useDispatch();

    const setQuery = (text) => {
        dispatch({
            type: SEARCH_FOLDERS_QUERY,
            payload: {
                query: text,
                scope: currentSearch.scope,
            },
        });
    };

    const setScope = (scope) => {
        dispatch({
            type: SEARCH_FOLDERS_QUERY,
            payload: {
                query: currentSearch.query,
                scope,
            },
        });
    };

    return (
        <FolderSearchWrapper>
            <i className={"fa fa-search fa-2x"}></i>
            <input
                id={"search-field"}
                placeholder="Suche"
                style={{ height: "50px" }}
                className="form-control input-lg"
                type={"text"}
                value={currentSearch.query}
                onChange={(ev) => setQuery(ev.target.value)}
            />
            <FolderSearchSettings>
                <div>Suche nach:</div>
                <div>
                    <FormCheck
                        checked={currentSearch.scope === "folder"}
                        onChange={(ev) => setScope(ev.target.value)}
                        type={"radio"}
                        value="folder"
                        name="search"
                        label={"Ordner"}
                    ></FormCheck>
                </div>
                <div>
                    <FormCheck
                        checked={currentSearch.scope === "contact"}
                        onChange={(ev) => setScope(ev.target.value)}
                        type={"radio"}
                        value="contact"
                        name="search"
                        label={"Ansprechpartner"}
                    ></FormCheck>
                </div>
            </FolderSearchSettings>
        </FolderSearchWrapper>
    );
};
