import {
    ADD_CUSTOMER,
    CREATE_CUSTOMER,
    CUSTOMER_NEW_ADDRESS,
    CUSTOMER_NEW_ENTITY,
    CUSTOMER_UPDATE_ADDRESS,
    CUSTOMER_UPDATE_ENTITY,
    CUSTOMERS_AUTOCOMPLETE,
    CUSTOMERS_AUTOCOMPLETE_START,
    CUSTOMERS_LOADING_START,
    CUSTOMERS_LOADING_STOP,
    DELETE_CUSTOMER_ADDRESS,
    GET_CUSTOMERS,
    SET_CUSTOMERS,
    UPDATE_CUSTOMER,
} from "../actions/types";

const initialState = {
    customers: [],
    isLoading: false,
    autocomplete: [],
    autocompleteIsLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case CUSTOMERS_AUTOCOMPLETE_START:
            return { ...state, autocompleteIsLoading: true };
        case CUSTOMERS_AUTOCOMPLETE:
            return {
                ...state,
                autocomplete: action.payload,
                autocompleteIsLoading: false,
            };
        case SET_CUSTOMERS:
            return { ...state, customers: action.payload };
        case GET_CUSTOMERS:
            return { ...state, customers: action.payload };
        case ADD_CUSTOMER:
        case CREATE_CUSTOMER:
            if (state.customers.find((c: ICustomer) => c.id === action.payload.id)) {
                return { ...state };
            }
            return { ...state, customers: [...state.customers, action.payload] };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                customers: [...state.customers].map((customer: ICustomer) => {
                    if (customer.id === action.payload.id) {
                        return { ...customer, ...action.payload };
                    }
                    return customer;
                }),
            };
        case DELETE_CUSTOMER_ADDRESS:
            return {
                ...state,
                customers: [...state.customers].map((customer: ICustomer) => {
                    // find matching customer
                    if (customer.id === action.payload.customer_id) {
                        return {
                            ...customer,
                            addresses: [...customer.addresses].filter((address: IAddress) => {
                                return address.id !== action.payload.id;
                            }),
                        };
                    }
                    return customer;
                }),
            };
        case CUSTOMER_NEW_ADDRESS:
            return {
                ...state,
                customers: [...state.customers].map((customer: ICustomer) => {
                    // find matching customer
                    if (customer.id === action.payload.customer_id) {
                        return {
                            ...customer,
                            addresses: [...customer.addresses, action.payload],
                        };
                    }
                    return customer;
                }),
            };
        case CUSTOMER_UPDATE_ADDRESS:
            return {
                ...state,
                customers: [...state.customers].map((customer: ICustomer) => {
                    // find matching customer
                    if (customer.id === action.payload.customer_id) {
                        return {
                            ...customer,
                            // update matching address
                            addresses: [...customer.addresses].map((address: IAddress) => {
                                if (address.id === action.payload.id) {
                                    return { ...address, ...action.payload };
                                }
                                return address;
                            }),
                        };
                    }
                    return customer;
                }),
            };
        case CUSTOMER_NEW_ENTITY:
            return {
                ...state,
                customers: [...state.customers].map((customer: ICustomer) => {
                    // find matching customer
                    if (customer.id === action.payload.customer_id) {
                        return {
                            ...customer,
                            entities: [...customer.entities, action.payload],
                        };
                    }
                    return customer;
                }),
            };
        case CUSTOMER_UPDATE_ENTITY:
            return {
                ...state,
                customers: [...state.customers].map((customer: ICustomer) => {
                    // find matching customer
                    if (customer.id === action.payload.customer_id) {
                        return {
                            ...customer,
                            // update matching address
                            entities: [...customer.entities].map((entity: IEntity) => {
                                if (entity.id === action.payload.id) {
                                    return { ...entity, ...action.payload };
                                }
                                return entity;
                            }),
                        };
                    }
                    return customer;
                }),
            };
        case CUSTOMERS_LOADING_STOP:
            return { ...state, isLoading: false };
        case CUSTOMERS_LOADING_START:
            return { ...state, isLoading: true };
    }

    return state;
}
