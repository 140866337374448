// @ts-ignore
import { EDITOR_FORM_SNAPSHOT_CHANGED } from "@rpforms/shared";
import { useLiveQuery } from "dexie-react-hooks";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import db from "../db";

const AutoSaveWrapper = styled.div`
    margin-bottom: 80px;
`;

const AutoSaveHeader = styled.div`
    padding: 20px;
    background-color: #fdfdea;
    color: #0b2e13;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    [class$="-container"] {
        width: 100% !important;
    }
`;

export const AutoSaveComponent: React.FunctionComponent<{
    forForm: React.Ref<HTMLFormElement>;
    formEntry: any;
    onApply: any;
}> = ({ forForm, formEntry, onApply }) => {
    const dispatch = useDispatch();
    const snapshots = useLiveQuery(
        () =>
            db.snapshots
                .where({
                    form_id: formEntry.form_id || 0,
                    entity_id: formEntry.entity_id || 0,
                    customer_id: formEntry.customer_id || 0,
                })
                .toArray(),
        [formEntry.form_id, formEntry.entity_id, formEntry.customer_id]
    );

    if (!snapshots || !snapshots.length) {
        return <></>;
    }

    const options = snapshots
        .sort((snapA, snapB) => {
            if (snapA.created > snapB.created) {
                return -1;
            }
            if (snapB.created > snapA.created) {
                return 1;
            }
            return 0;
        })
        .map((snapshot) => {
            return {
                value: snapshot.id,
                label:
                    moment(snapshot.created).format("DD.MM.YYYY HH:mm:ss") +
                    " (" +
                    moment(snapshot.created).fromNow() +
                    ")",
            };
        });

    return (
        <AutoSaveWrapper>
            <AutoSaveHeader>
                <div>
                    <div>
                        <strong>
                            {snapshots.length} Protokoll-Versionen in der Zeitmachine gefunden
                        </strong>
                    </div>
                    Möchten Sie eine alte, gespeicherte Version wiederherstellen?
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "400px",
                    }}
                >
                    <Select
                        onChange={(snapshot) => {
                            onApply(snapshot);
                            dispatch({
                                type: EDITOR_FORM_SNAPSHOT_CHANGED,
                                payload: {
                                    snapshot_nr: snapshot.value,
                                },
                            });
                        }}
                        options={options}
                        style={{ "min-width": "300px" }}
                        width={"300px"}
                    />
                </div>
            </AutoSaveHeader>
        </AutoSaveWrapper>
    );
};
