import React from "react";
import Select from "react-select";

export const ResourceSelect = ({ setResource, current = "Folder" }) => {
    const options = [
        {
            value: "Folder",
            label: "Ordner",
        },
        {
            value: "Customer",
            label: "Kunde",
        },
        {
            value: "Entity",
            label: "Objekt",
        },
        {
            value: "Form",
            label: "Protokoll",
        },
    ];

    const defaultValue = options.find((opt) => opt.value === current) || options[0];

    return (
        <Select
            defaultValue={defaultValue}
            onChange={(option) => setResource(option)}
            options={options}
        />
    );
};
