import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Typing } from "./Typing";
import { GptRole, IMessage } from "./types";
import { Button, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../config";
import { GPT_RULES } from "./constants";
import { clipboardWrite } from "./clipboard";
import { toast } from "react-toastify";

interface IChatGptModalProps {
    visible: boolean;
    close: () => void;
}

const StyledHeader = styled(Modal.Header)`
    padding: 5px 0 !important;
    margin: 0 -20px;
    display: flex;
    justify-content: center;
`;

const StyledModal = styled(Modal)`
    padding: 0 !important;

    .modal-content {
        padding: 10px 20px !important;
    }
`;

const StyledBody = styled(Modal.Body)`
    padding: 0 !important;
`;

const MessageBar = styled.div`
    width: 100%;
    flex-direction: column;
`;

const Message = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
`;

const GptMessage = styled(Message)`
    background: #eee;
    justify-content: space-between;
    align-items: flex-start;
`;

const UserMessage = styled(Message)``;

const ChatGptMessage: FC<IMessage & { index: number }> = ({ index, role, content }) => {
    const copy = () => {
        clipboardWrite(content);
        toast.success("Nachricht kopiert", {
            position: "bottom-left",
            delay: 200,
        });
    };
    if (role === "system") {
        return <></>;
    }

    if (role === "assistant") {
        return (
            <GptMessage className="gpt-msg" id={`msg-${index}`}>
                <Button size={"sm"} onClick={copy}>
                    <svg
                        style={{ color: "white", width: "20px", height: "24px" }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184"
                        />
                    </svg>
                </Button>

                <pre
                    style={{
                        width: "100%",
                        padding: "0 10px",
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        fontFamily:
                            "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
                    }}
                >
                    <Typing text={content} />
                </pre>
            </GptMessage>
        );
    }

    return (
        <UserMessage className="gpt-msg" id={`msg-${index}`}>
            {content}
        </UserMessage>
    );
};

const ChatGptInput = styled.textarea`
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    margin-block: 10px;
`;

const DEFAULT_MESSAGE = {
    id: 0,
    date: new Date(),
    enhance: false,
    role: "system" as GptRole,
    content: GPT_RULES,
};

export const ChatGptModal: FC<IChatGptModalProps> = ({ close, visible }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [messages, setMessages] = useState<IMessage[]>([DEFAULT_MESSAGE]);

    const submit = () => {
        setMessage("");
        setLoading(true);
        const msgs = [
            ...messages,
            {
                role: "user" as GptRole,
                content: message,
            },
        ];

        setMessages(() => msgs);
        axios
            .post<IMessage>(`${API_URL}/chatgpt`, {
                messages: msgs,
            })
            .then((res) => {
                setMessages(() => [...msgs, res.data]);
                setLoading(false);
            });
    };

    useEffect(() => {
        setImmediate(() => {
            const msgs = Array.from(document.querySelectorAll(".gpt-msg"));
            if (msgs.length === 0) {
                return;
            }
            const lastMsg = msgs[msgs.length - 1];
            lastMsg.scrollIntoView({ behavior: "smooth" });
        });
    }, [messages]);

    return (
        <StyledModal size={"xl"} show={visible} onHide={close}>
            <StyledHeader>KI Assistent</StyledHeader>
            <StyledBody id={"chatScroller"} style={{ paddingInline: 0 }}>
                <div style={{ width: "100%" }}>
                    {messages.map((msg, index) => {
                        return <ChatGptMessage index={index} {...msg} key={index} />;
                    })}

                    {loading && (
                        <GptMessage>
                            <Spinner animation={"grow"} />
                            Denkt...
                        </GptMessage>
                    )}
                </div>
                <MessageBar style={{ marginTop: "20px", marginRight: "10px" }}>
                    <div>Deine Nachricht:</div>
                    <ChatGptInput
                        placeholder="Schreiben Sie Chat-GPT"
                        value={message}
                        rows={4}
                        disabled={loading}
                        onInput={(ev) => setMessage((ev.target as HTMLTextAreaElement).value)}
                    ></ChatGptInput>
                    <Button onClick={close} variant={"danger"} disabled={loading}>
                        Schließen
                    </Button>
                    <span>&nbsp;</span>
                    <Button onClick={submit} disabled={loading}>
                        Abschicken
                    </Button>
                </MessageBar>
            </StyledBody>
        </StyledModal>
    );
};
