import { renameForm, updateForm, updateFormEntry } from "@rpforms/shared/build/actions";
import * as actions from "@rpforms/shared/build/actions/forms";
import { formByID } from "@rpforms/shared/build/actions/forms";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { Component, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useParams, withRouter } from "react-router-dom";
import EditForm from "../../components/forms/EditForm";
import { RootState } from "../../reducers";

const EditFormPage = () => {
    const { id } = useParams();
    const form = useSelector<RootState, any>((state) =>
        state.forms.forms.find((form) => form.id === parseInt(id))
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!form) {
            dispatch(formByID(id));
        }
    }, []);

    const saveForm = async () => {
        if (document.querySelector("#formSaveBtn")) {
            (document.querySelector("#formSaveBtn") as any).click();
        }
    };

    if (!form) {
        return (
            <>
                <PageNavbar title="Lade Formular..." description=""></PageNavbar>
                <Loader />
            </>
        );
    }

    const changeName = (newName) => {
        dispatch(renameForm({ ...form, name: newName }));
    };

    return (
        <>
            <PageNavbar
                rename={true}
                onRename={changeName}
                title={`${form.name}`}
                description="Hier können Sie das Formular bearbeiten, Einträge ansehen und Geräten zuweisen."
            >
                <Switch>
                    <Route
                        path="/settings/protocols/forms/edit-:id"
                        exact
                        render={() => (
                            <PageNavAction
                                onClick={saveForm}
                                link={`/settings/protocols/forms/edit-${form.id}`}
                                success={true}
                                icon="save"
                                title="Formular speichern"
                            />
                        )}
                    />
                </Switch>
                <PageNavAction
                    primary={true}
                    link={`/settings/protocols/forms/preview-${form.id}`}
                    icon="eye"
                    title="Vorschau"
                />
            </PageNavbar>
            <Switch>
                <Route
                    path="/settings/protocols/forms/edit-:id"
                    exact
                    render={(props) => <EditForm form={form} />}
                />
            </Switch>
        </>
    );
};

export default withRouter(EditFormPage);
