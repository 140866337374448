import React from "react";
import { Route, Switch } from "react-router-dom";
import ViewCustomerPage from "./customers/ViewCustomerPage";
import ViewCalendarEventPage from "./devices/ViewCalendarEventPage";
import ViewDevicePage from "./devices/ViewDevicePage";
import ViewEmployeePage from "./employees/ViewEmployeePage";
import ListMasterDataPage from "./ListMasterDataPage";
import { ScheduleEmployeePage } from "./employees/ScheduleEmployeePage";

export const MasterDataPage = () => {
    return (
        <Switch>
            <Route path="/master_data/customers" exact component={ListMasterDataPage} />
            <Route path="/master_data/customers/new" component={ListMasterDataPage} />
            <Route path="/master_data/customers/view-:id" component={ViewCustomerPage} />
            <Route path="/master_data/objects" component={ListMasterDataPage} />
            <Route path="/master_data" exact component={ListMasterDataPage} />
            <Route path="/master_data/employees" exact component={ListMasterDataPage} />
            <Route path="/master_data/devices" exact component={ListMasterDataPage} />
            <Route path="/master_data/devices/new" exact component={ListMasterDataPage} />
            <Route path="/master_data/devices/view-:id" exact component={ViewDevicePage} />
            <Route
                path="/master_data/devices/view-:id/event-:eventId"
                component={ViewCalendarEventPage}
            />
            <Route
                path="/master_data/employees/view-:employeeId"
                exact
                component={ViewEmployeePage}
            />
            <Route
                path="/master_data/employees/schedule-:employeeId"
                exact
                component={ScheduleEmployeePage}
            />
        </Switch>
    );
};

export default MasterDataPage;
