import { SET_COMPANY, UPDATE_COMPANY } from "../actions/types";

const initialState = {
    id: 0,
    name: "",
};

export default function(state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case SET_COMPANY:
            return action.payload;
        case UPDATE_COMPANY:
            return { ...state, ...action.payload };
    }

    return state;
}
