import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import { EDITOR_CLOSE, EDITOR_OPEN, EDITOR_SAVING } from "./types";

export function openEditor(editorConfig: IEditorConfig) {
    return (dispatch: IDispatch<any>) => {
        dispatch({
            payload: editorConfig,
            type: EDITOR_OPEN,
        });
    };
}

export function closeEditor() {
    return (dispatch: IDispatch<any>) => {
        dispatch({
            payload: null,
            type: EDITOR_CLOSE,
        });
    };
}

export function saveEditor(editorConfig: IEditorConfig, data: any, config = {}) {
    return async (dispatch: IDispatch<any>) => {
        const addHeaders =
            data instanceof FormData ? { "Content-Type": "multipart/form-data" } : {};

        dispatch({
            payload: true,
            type: EDITOR_SAVING,
        });

        // TODO: maybe a bit too much magic.
        const url = `${API_URL}/${editorConfig.endpointCompany ? `companies/${COMPANY_ID}/` : ""}${
            editorConfig.endpoint
        }${editorConfig.new ? "" : `/${editorConfig.preload.id}`}.json`;

        try {
            const result = await axios[editorConfig.new ? "post" : "patch"](
                url,
                data instanceof FormData
                    ? data
                    : {
                          ...data,
                          company_id: COMPANY_ID,
                      },
                {
                    headers: {
                        // tslint:disable-next-line
                        Authorization: user().token,
                        ...addHeaders,
                    },
                }
            );

            return result.data;
        } catch (e) {
            alert(
                `Fehler beim ${
                    editorConfig.new ? "anlegen" : "speichern"
                }. Bitte versuchen Sie erneut.`
            );
            console.error(e);
            throw e; // keep throwing to keep the modal open
        } finally {
            dispatch({
                payload: false,
                type: EDITOR_SAVING,
            });
        }
    };
}
