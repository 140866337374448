import { fetchFormGroups, getForms } from "@rpforms/shared";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormList from "../../components/forms/FormList";
import { RootState } from "../../reducers";

const FormGroup = ({ group }) => {
    const forms = useSelector<any, any>((state) =>
        state.forms.forms.filter((f) => {
            return f.form_group && f.form_group.id === group.id;
        })
    );

    return (
        <div>
            <h4>{group.name}</h4>
            <FormList showEmptySpace={false} forms={forms} />
            <hr />
        </div>
    );
};

export default FormGroup;
