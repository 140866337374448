import { fetchDirectory, fetchFolders } from "@rpforms/shared/build/actions/folders";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { FolderNavigation } from "../../components/folders/FolderNavigation";
import { FolderTreeSelect } from "../../components/folders/FolderTreeSelect";
import { RootState } from "../../reducers";
import { SettingsHeadline } from "./SettingsPage";

const FolderSettingsPage = () => {
    const directory = useSelector<RootState, any>((store) => store.directory);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDirectory());
        dispatch(fetchFolders({ parentId: false }));
    }, []);

    return (
        <div>
            <SettingsHeadline>
                <span>Ordner</span>
            </SettingsHeadline>
            <FolderNavigation />
            <hr />
            <SettingsHeadline>Test Komponenten</SettingsHeadline>
            <FolderTreeSelect onSelect={() => {}} />
        </div>
    );
};
export default withRouter(FolderSettingsPage);
