import { EMPLOYEES_SET_EDITING } from "@rpforms/shared";
import {
    createEmployeeCert,
    deleteEmployeeCert,
    updateEmployeeCert,
} from "@rpforms/shared/build/actions/employees";
import {
    SmallDangerButton,
    SmallPrimaryButton,
    SmallSuccessButton,
} from "@rpforms/shared/build/components/Button";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const EmployeeCertificate = ({ create, editable = false, cert }) => {
    const dispatch = useDispatch();
    const [editing, setEditing] = useState(false);
    const [data, setCert] = useState(cert);

    const inputHandle = (name) => {
        return {
            onChange(e) {
                setCert({
                    ...data,
                    [name]: e.target.value,
                });
            },
        };
    };

    const createCert = () => {
        dispatch(createEmployeeCert(data));
    };
    const editCert = () => {
        dispatch(updateEmployeeCert(data));
    };
    const deleteCert = () => {
        dispatch(deleteEmployeeCert(data));
    };

    if (!create && !editing) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                }}
            >
                <strong> {cert.title} </strong> / Hersteller: {cert.manufacturer} / Datum:{" "}
                {moment(cert.date).format("DD.MM.YYYY")} / Gültigkeit: {cert.valid_for_years}
                <span style={{ width: "40px" }}></span>
                {editable && (
                    <SmallPrimaryButton
                        onClick={() => {
                            setEditing(!editing);
                            dispatch({ type: EMPLOYEES_SET_EDITING, payload: true });
                        }}
                    >
                        Bearbeiten
                    </SmallPrimaryButton>
                )}
                {editable && (
                    <SmallDangerButton onClick={() => deleteCert()}>Löschen</SmallDangerButton>
                )}
            </div>
        );
    }

    return (
        <div>
            <div
                style={{
                    padding: "10px",
                    background: "#fff",
                    border: "1px solid #ccc",
                    maxWidth: "950px",
                }}
            >
                <h1 style={{ fontSize: "20px" }}>{cert ? cert.title : ""}</h1>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "30px",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <label style={{ display: "block" }}>Hersteller</label>
                        <input
                            defaultValue={cert.manufacturer}
                            {...inputHandle("manufacturer")}
                            type="text"
                            placeholder="Hersteller"
                        />
                    </div>
                    <div>
                        <label style={{ display: "block" }}>Titel</label>
                        <input
                            defaultValue={cert.title}
                            {...inputHandle("title")}
                            type="text"
                            placeholder="Titel"
                        />
                    </div>
                    <div>
                        <label style={{ display: "block" }}>Datum</label>
                        <input
                            defaultValue={
                                cert.date
                                    ? `${new Date(cert.date).getFullYear()}-${(
                                          "0" +
                                          (new Date(cert.date).getMonth() + 1)
                                      ).slice(-2)}-${
                                          new Date(cert.date).getDate() < 10
                                              ? `0${new Date(cert.date).getDate()}`
                                              : new Date(cert.date).getDate()
                                      }`
                                    : ""
                            }
                            {...inputHandle("date")}
                            type="date"
                            placeholder="Datum"
                        />
                    </div>
                    <div>
                        <label style={{ display: "block" }}>Gültig für Jahre:</label>
                        <select
                            defaultValue={cert.valid_for_years}
                            {...inputHandle("valid_for_years")}
                            name="valid_for_years"
                        >
                            {Array.from({ length: 10 }).map((_, i) => (
                                <option
                                    key={i}
                                    value={i + 1}
                                    selected={Number(cert.valid_for_years) === i + 1}
                                >
                                    {i + 1}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <h2
                    style={{
                        fontSize: "18px",
                        marginTop: "20px",
                    }}
                >
                    Ansprechpartner
                </h2>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <label style={{ display: "block" }}>Name</label>
                        <input
                            defaultValue={cert.contact_name}
                            {...inputHandle("contact_name")}
                            type="text"
                            placeholder="Name"
                        />
                    </div>

                    <div>
                        <label style={{ display: "block" }}>Mail</label>
                        <input
                            defaultValue={cert.contact_mail}
                            {...inputHandle("contact_mail")}
                            type="email"
                            placeholder="Mail"
                        />
                    </div>

                    <div>
                        <label style={{ display: "block" }}>Telefon</label>
                        <input
                            defaultValue={cert.contact_phone}
                            {...inputHandle("contact_phone")}
                            type="phone"
                            placeholder="Telefon"
                        />
                    </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                    {!create && editing && (
                        <div>
                            <SmallPrimaryButton onClick={editCert}>Speichern</SmallPrimaryButton>
                            <span
                                style={{
                                    width: "50px",
                                    display: "inline-block",
                                }}
                            >
                                &nbsp;
                            </span>
                            <SmallDangerButton onClick={() => deleteCert()}>
                                Löschen
                            </SmallDangerButton>
                        </div>
                    )}
                    {create && (
                        <div>
                            <SmallPrimaryButton onClick={createCert}>Erstellen</SmallPrimaryButton>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmployeeCertificate;
