import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";

interface IModalEntity {
    editor: any;
    next: (payload) => {};
}

const DEFAULT_VACATION_DAYS = 25;

const DEFAULT_EMPLOYEE = {
    vacation_days: DEFAULT_VACATION_DAYS,
    current_year_vacation_days: Math.floor(
        ((12 - new Date().getMonth()) / 12) * DEFAULT_VACATION_DAYS
    ), // calculate the remainder based on the current month and round it down
};

export const ModalEmployee = ({ editor, next }: IModalEntity) => {
    const [extraArgs, setExtraArgs] = useState({});
    const [employee, setEmployee] = useState({ ...editor.config.preload });
    const { register, handleSubmit, watch, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        const employeeStore: any = await next({
            employee: { ...editor.config.preload, ...data, ...extraArgs },
        });
        if (editor.config.redirect) {
            await dispatch(push("/master_data/employees/view-" + employeeStore.id));
        }
    };

    const isNew = editor.config.new;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isNew ? "Neuen Mitarbeiter erstellen" : "Mitarbeiter bearbeiten"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label>Name</label>
                        <input
                            defaultValue={employee.name}
                            className={"form-control"}
                            name="name"
                            ref={register({
                                required: "Name muss ausgefüllt sein",
                                pattern: {
                                    value: /.*([^\.,\-+#´`;:\(\)<>\{\}\[\]~&@?!/\\^° ])$/i,
                                    message: "Name muss mit Buchstabe oder Zahl enden",
                                },
                            })}
                        />
                        {errors.name && <InputError>{errors.name.message}</InputError>}
                    </Col>
                    <Col>
                        <label>Urlaubstage pro Jahr</label>
                        <input
                            defaultValue={employee.vacation_days ?? DEFAULT_EMPLOYEE.vacation_days}
                            className={"form-control"}
                            name="vacation_days"
                            ref={register({
                                required: "Urlaubstage müssen ausgefüllt werden.",
                                pattern: {
                                    value: /\b([0-9]|[1-9][0-9])\b/m,
                                    message: "Urlaubstage müssen eine Zahl zwischen 0 und 99 sein",
                                },
                            })}
                        />
                        {errors.vacation_days && (
                            <InputError>{errors.vacation_days.message}</InputError>
                        )}
                    </Col>
                </Row>
                {isNew && (
                    <Row style={{ marginTop: "10px" }}>
                        <Col>
                            <label>Verfügbare Urlaubstage im laufenden Jahr</label>
                            <input
                                defaultValue={DEFAULT_EMPLOYEE.current_year_vacation_days}
                                className={"form-control"}
                                name="current_year_vacation_days"
                                ref={register({
                                    required: "Verfügbare Urlaubstage müssen ausgefüllt werden.",
                                    pattern: {
                                        value: /\b([0-9]|[1-9][0-9])\b/m,
                                        message:
                                            "Urlaubstage müssen eine Zahl zwischen 0 und 99 sein",
                                    },
                                })}
                            />
                            {errors.current_year_vacation_days && (
                                <InputError>{errors.current_year_vacation_days.message}</InputError>
                            )}
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                {editor.saving && <>speichert …</>}
                <PrimaryButton
                    disabled={editor.saving}
                    style={editor.saving ? { background: "gray" } : {}}
                    type={"submit"}
                >
                    {isNew ? "Erstellen" : "Speichern"}
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
