import {
    CUSTOMER_NEW_ADDRESS,
    CUSTOMER_NEW_ENTITY,
    CUSTOMER_UPDATE_ADDRESS,
    CUSTOMER_UPDATE_ENTITY,
    UPDATE_CUSTOMER,
} from "@rpforms/shared/build/actions";
import { deleteCustomerAddress, fetchCustomer } from "@rpforms/shared/build/actions/customer";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { markNotificationAsRead } from "@rpforms/shared/build/actions/notifications";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import {
    BoxCard,
    CardAction,
    CardActions,
    CardBody,
    CardTitle,
} from "@rpforms/shared/build/components/layout/Widget";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { RowVerticallyCentered } from "@rpforms/shared/build/components/universal/RowVerticallyCentered";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, withRouter } from "react-router-dom";
import { AddressRow } from "../../../components/customers/AddressRow";
import EntityStatsModal from "../../../components/entities/EntityStatsModal";
import { RootState } from "../../../reducers";

export const ViewCustomerPage = ({ match }) => {
    const { id } = match.params;
    const searchParams = new URL(window.location.href).searchParams;

    const { search } = useLocation();
    const [customerNotificationCheck, setCustomerNotificationCheck] = useState([]);
    const [entityNotificationCheck, setEntityNotificationCheck] = useState([]);

    const dispatch = useDispatch();
    const customer = useSelector<RootState, ICustomer>((store) =>
        store.customers.customers.find((c) => c.id === parseInt(id, 10))
    );

    const customerNotifications = () => {
        return (customer?.notifications || []).filter(
            (n) => n.notification_group?.unique_name === "data_integrity"
        );
    };

    const entityNotifications = (entity) => {
        return (entity?.notifications || []).filter(
            (n) => n.notification_group?.unique_name === "data_integrity"
        );
    };

    useEffect(() => {
        if (customer) {
            setCustomerNotificationCheck(customerNotifications().map((n) => n.resource_id));
            let entityIds = [];
            customer.entities.forEach((e) => {
                entityIds += entityNotifications(e).map((n) => n.resource_id);
            });
            setEntityNotificationCheck(entityIds);
        }
    }, [customer]);

    const markNotification = (notificationId) => {
        dispatch(markNotificationAsRead(notificationId));
        dispatch(push(`/master_data/customers/view-${id}`));
    };

    useEffect(() => {
        if (!customer) {
            dispatch(fetchCustomer(id));
        }
    }, []);

    const deleteAddress = (address: IAddress) => {
        dispatch(deleteCustomerAddress(address));
    };

    const editAddress = (address: Partial<IAddress>) => {
        dispatch(
            openEditor({
                endpoint: `customers/${customer.id}/addresses`,
                endpointCompany: true,
                new: !address.id,
                preload: address,
                type: !address.id ? CUSTOMER_NEW_ADDRESS : CUSTOMER_UPDATE_ADDRESS,
            })
        );
    };

    const editEntity = (entity: Partial<IEntity>) => {
        dispatch(
            openEditor({
                endpoint: `entities`,
                endpointCompany: true,
                new: !entity.id,
                preload: { ...entity, address: entity.address || {} },
                type: !entity.id ? CUSTOMER_NEW_ENTITY : CUSTOMER_UPDATE_ENTITY,
            })
        );
    };

    const editCustomer = (shCustomer: Partial<ICustomer>) => {
        dispatch(
            openEditor({
                endpoint: `customers`,
                endpointCompany: true,
                new: false,
                preload: shCustomer,
                type: UPDATE_CUSTOMER,
            })
        );
    };

    const [settingsModalShow, setSettingsModalShow] = useState(0);

    if (!customer) {
        return <Loader />;
    }

    const customerAddress = (customer.addresses || [])[0] || {};

    const terms = () => {
        return (
            customer.terms &&
            customer.terms.map((term) => {
                return (
                    <Badge
                        key={`${customer.id}_${term.id}`}
                        style={{
                            backgroundColor: term.options.backgroundColor,
                            color: term.options.color,
                        }}
                    >
                        {term.name}
                    </Badge>
                );
            })
        );
    };

    return (
        <>
            <PageNavbar
                title={customer.name}
                description={
                    <>
                        {customer.customer_nr} <i className={"fa fa-fw fa-circle"}></i>{" "}
                        {customer.entities.length} Objekte <i className={"fa fa-circle fa-fw"}></i>{" "}
                        {terms()}
                    </>
                }
            >
                <PageNavAction
                    onClick={() => editCustomer(customer)}
                    primary={true}
                    icon="pencil"
                    title="Bearbeiten"
                />
                <PageNavAction link="/master_data/customers" icon="list" title="Alle Kunden" />
            </PageNavbar>
            <Content>
                <Row>
                    <Col>
                        <BoxCard>
                            <CardTitle>
                                Adressen
                                <CardActions>
                                    <CardAction
                                        onClick={() => editAddress({ customer_id: customer.id })}
                                    >
                                        <i className={"fa fa-2x fa-plus"} />
                                    </CardAction>
                                </CardActions>
                            </CardTitle>
                            <CardBody>
                                {customerNotificationCheck.includes(customer.id) && (
                                    <div
                                        className="mb-2 p-2 d-flex justify-content-between align-items-center w-100 "
                                        style={{ backgroundColor: "#E00" }}
                                    >
                                        <strong style={{ color: "white" }}>
                                            Bitte überprüfen Sie diese Adressen auf Aktualität.
                                        </strong>
                                        <Button
                                            variant="light"
                                            onClick={() => {
                                                markNotification(customerNotifications()[0].id);
                                                setCustomerNotificationCheck(
                                                    [...customerNotificationCheck].filter(
                                                        (i) => i !== customer.id
                                                    )
                                                );
                                            }}
                                        >
                                            Abschließen
                                        </Button>
                                    </div>
                                )}
                                {customer.addresses.map((address) => {
                                    return (
                                        <RowVerticallyCentered key={`address-${address.id}`}>
                                            <Col sm={10}>
                                                <AddressRow address={address} slim={false} />
                                            </Col>
                                            <Col sm={1}>
                                                <a
                                                    href="#"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        editAddress(address);
                                                    }}
                                                    className={"fa fa-pencil fa-fw fa-2x"}
                                                />
                                            </Col>
                                            <Col sm={1}>
                                                <a
                                                    href="#"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        deleteAddress(address);
                                                    }}
                                                    className={"fa fa-trash fa-fw fa-2x pr-3"}
                                                />
                                            </Col>
                                        </RowVerticallyCentered>
                                    );
                                })}
                            </CardBody>
                        </BoxCard>
                    </Col>
                    <Col>
                        <BoxCard>
                            <CardTitle>
                                Objekte
                                <CardActions>
                                    <CardAction
                                        onClick={() =>
                                            editEntity({
                                                address: { ...customerAddress },
                                                customer_id: customer.id,
                                            })
                                        }
                                    >
                                        <i className={"fa fa-2x fa-plus"} />
                                    </CardAction>
                                </CardActions>
                            </CardTitle>
                            <CardBody>
                                {customer.entities.map((entity) => {
                                    const highlighted =
                                        searchParams.get("entity") === `${entity.id}`;
                                    return (
                                        <div key={entity.id}>
                                            {entityNotificationCheck.includes(entity.id) && (
                                                <div
                                                    className="mb-2 p-2 d-flex justify-content-between align-items-center w-100 "
                                                    style={{ backgroundColor: "#E00" }}
                                                >
                                                    <strong style={{ color: "white" }}>
                                                        Bitte überprüfen Sie dieses Objekt auf
                                                        Aktualität: {entity.name}
                                                    </strong>
                                                    <Button
                                                        variant="light"
                                                        onClick={() => {
                                                            markNotification(
                                                                entity.notifications[0].id
                                                            );
                                                            setEntityNotificationCheck(
                                                                [...entityNotificationCheck].filter(
                                                                    (i) => i !== entity.id
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Abschließen
                                                    </Button>
                                                </div>
                                            )}
                                            <RowVerticallyCentered
                                                style={{
                                                    backgroundColor: highlighted
                                                        ? "#c76243"
                                                        : "transparent",
                                                }}
                                                id={`entity-${entity.id}`}
                                                key={`entity-${entity.id}`}
                                            >
                                                <Col sm={1}>
                                                    <i className={"fa fa-home fa-fw fa-2x"} />
                                                </Col>
                                                <Col sm={9}>
                                                    <strong>{entity.name}</strong>
                                                    {entity.terms &&
                                                        entity.terms.map((term) => {
                                                            return (
                                                                <Badge
                                                                    key={`${entity.id}_${term.id}`}
                                                                    className="mx-1"
                                                                    style={{
                                                                        backgroundColor:
                                                                            term.name ===
                                                                            "Wartungsvertrag"
                                                                                ? "#777"
                                                                                : term.options
                                                                                      .backgroundColor,
                                                                        color: term.options.color,
                                                                    }}
                                                                >
                                                                    {term.name}
                                                                </Badge>
                                                            );
                                                        })}
                                                    {entity.address && (
                                                        <AddressRow
                                                            slim={true}
                                                            address={entity.address}
                                                        />
                                                    )}
                                                </Col>
                                                <Col sm={2}>
                                                    <EntityStatsModal
                                                        entity={entity}
                                                        setSettingsModalShow={setSettingsModalShow}
                                                        settingsModalShow={settingsModalShow}
                                                    />
                                                    <a
                                                        title={"Objekt-Statistiken anzeigen"}
                                                        href="#"
                                                        className={"fa fa-signal fa-fw fa-2x"}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setSettingsModalShow(entity.id);
                                                        }}
                                                    />
                                                    <a
                                                        title={"Objekt bearbeiten"}
                                                        href="#"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            editEntity(entity);
                                                        }}
                                                        className={"fa fa-pencil fa-fw fa-2x"}
                                                    />
                                                </Col>
                                            </RowVerticallyCentered>
                                        </div>
                                    );
                                })}
                            </CardBody>
                        </BoxCard>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default withRouter(ViewCustomerPage);
