import { EMPLOYEES_SET_EDITING, PrimaryButton } from "@rpforms/shared";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import EmployeeCertificate from "./EmployeeCertificate";

export const EmployeeCertificates = ({ employeeId }) => {
    const [showForm, setShowForm] = useState(false);
    const employee = useSelector<RootState, any>((state) =>
        state.employees.employees.find((e) => e.id === parseInt(employeeId, 10))
    );
    const employeeEditing = useSelector<any, any>((state) => state.employees.isEditing);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: EMPLOYEES_SET_EDITING, payload: false });
    }, []);

    return (
        <div>
            <hr />

            <h2 style={{ marginTop: "60px" }}>Zertifizierungen</h2>
            <div>
                {employee.employee_certs.map((cert) => {
                    return (
                        <EmployeeCertificate
                            cert={cert}
                            create={false}
                            editable={true}
                            key={cert.id}
                        />
                    );
                })}
            </div>
            {showForm && (
                <div>
                    <EmployeeCertificate cert={{ employeeId: employeeId }} create={true} />
                </div>
            )}
            <div style={{ marginTop: "40px" }}>
                {!employeeEditing && !showForm && (
                    <PrimaryButton onClick={() => setShowForm(true)}>Neu hinzufügen</PrimaryButton>
                )}
            </div>
        </div>
    );
};

export default EmployeeCertificates;
