import React from "react";
import { InputGroup } from "react-bootstrap";
import CurrencyInput from "react-currency-masked-input";
import { positiveNumberKeyDownEvent } from "../../utils";
import styled from "styled-components";

const EuroSign = styled.span`
    position: absolute;
    ${(props: { isPrinting: boolean }) => (props.isPrinting ? `right: 3px;` : `right: 10px;`)}
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
`;

export const MoneyInput = (props) => {
    return (
        <InputGroup style={{ flexWrap: "inherit", position: "relative" }}>
            <CurrencyInput
                className={"form-control"}
                style={props.isPrinting ? { marginRight: `9px` } : { paddingLeft: `20px` }}
                {...props}
                onKeyDown={(e) =>
                    props.min !== null && props.min >= 0 ? positiveNumberKeyDownEvent(e) : null
                }
            />
            <EuroSign isPrinting={props.isPrinting}>€</EuroSign>
        </InputGroup>
    );
};
