import {
    createVacation,
    fetchVacationsForEmployee,
    updateVacation,
} from "@rpforms/shared/build/actions/vacations";
import { SmallDangerButton } from "@rpforms/shared/build/components/Button";
import { PrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { RootState } from "../../reducers";

const AddVacationWrapper = styled.div`
    padding: 20px 20px;
    background: #fafafa;
    width: 84%;
    .DateRangePicker {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

    label {
        margin-top: 20px;
    }
`;

export const AddVacation = ({
    employeeId,
    freeVacationDays,
    residualVacationDays,
    setResidualVacation,
    residualVacation,
    setFreeVacationDays,
    setResidualVacationDays,
    vacation = null,
    setShowEditId = null,
    holidayArr,
}) => {
    const [startDate, setStartDate] = useState(
        vacation ? moment(vacation.from, "YYYY-MM-DD") : null
    );
    const [endDate, setEndDate] = useState(vacation ? moment(vacation.to, "YYYY-MM-DD") : null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [calculatedVacationDays, setCalculatedVacationDays] = useState(0);
    const [needsPermission, setNeedsPermission] = useState(vacation ? !vacation.permitted : false);
    const [extraHoliday, setExtraHoliday] = useState(vacation ? vacation.extra_holiday : false);

    const [holidays, setHolidays] = useState(0);
    const dispatch = useDispatch();
    const vacations = useSelector<RootState, any>((state) => state.vacations.list);

    useEffect(() => {
        dispatch(fetchVacationsForEmployee({ id: employeeId }));
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            calculateHolidays(moment(startDate), moment(endDate));
            calculateDays();
        }
    }, [extraHoliday, startDate, endDate]);

    const calculateHolidays = (startDay, endDay) => {
        let res = 0;
        holidayArr.map((holiday) => {
            if (holiday.isBetween(startDay, endDay, null, "[]")) {
                res++;
            }
        });
        console.log(holidayArr);
        setHolidays(res);
        return res;
    };

    const calculateDays = () => {
        const startDay = moment(startDate).startOf("day");
        const endDay = moment(endDate).startOf("day");
        const startWeekday = startDay.day();
        const holidays = calculateHolidays(startDay, endDay);
        const daysWithWeekends = moment.duration(endDay.diff(startDay)).asDays() + 1;
        const weeks = Math.floor(daysWithWeekends / 7);
        const remainingDays = daysWithWeekends % 7;
        let remainingWeekendDays = 0;
        for (let i = 0; i < remainingDays; i++) {
            const num = (startWeekday + i) % 7;
            if (num === 0 || num === 6) remainingWeekendDays++;
        }
        const daysWithoutWeekends = daysWithWeekends - weeks * 2;
        const totalWeekdays = Math.ceil(daysWithoutWeekends - remainingWeekendDays);
        if (holidays > totalWeekdays) {
            toast.error("Die Anzahl der Feiertage überschreitet den Zeitraum", {
                autoClose: 2000,
            });
        }
        setCalculatedVacationDays(Math.ceil(totalWeekdays - holidays - (extraHoliday ? 1 : 0)));
    };

    const editVacation = async () => {
        const params = {
            ...vacation,
            from: moment(startDate).format("DD-MM-YYYY"),
            to: moment(endDate).format("DD-MM-YYYY"),
            days: calculatedVacationDays,
            permitted: !needsPermission,
            extra_holiday: extraHoliday,
        };
        const oldDays = vacation.days;
        await dispatch(updateVacation(params));
        setShowEditId(0);
        toast.success("Urlaub bearbeitet", { autoClose: 2000 });
        if (!moment(endDate) <= moment().endOf("year")) {
            if (residualVacation) {
                setFreeVacationDays(
                    parseInt(residualVacationDays) - (calculatedVacationDays - oldDays)
                );
                return;
            }
            setFreeVacationDays(
                parseInt(residualVacationDays) - (calculatedVacationDays - oldDays)
            );
        }
    };

    const createNewVacation = async () => {
        const params = {
            from: moment(startDate).format("DD-MM-YYYY"),
            to: moment(endDate).format("DD-MM-YYYY"),
            days: calculatedVacationDays,
            permitted: !needsPermission,
            employee_id: parseInt(employeeId, 10),
            extra_holiday: extraHoliday,
            residual_vacation: residualVacation,
        };

        await dispatch(createVacation(params));
        toast.success("Urlaub erstellt", { autoClose: 2000 });
        if (!moment(endDate) <= moment().endOf("year")) {
            if (residualVacation) {
                setResidualVacationDays(parseInt(residualVacationDays) - calculatedVacationDays);
                return;
            }
            setFreeVacationDays(parseInt(freeVacationDays) - calculatedVacationDays);
        }
    };

    const onCreateClick = async () => {
        if (!validateInput()) return;
        try {
            if (vacation) {
                await editVacation();
            } else {
                await createNewVacation();
            }
        } catch (e) {
            console.log(e);
            toast.error("Es ist etwas schiefgelaufen, bitte versuchen Sie es später erneut.", {
                autoClose: 2000,
            });
        }
    };

    const validateInput = () => {
        if (!startDate || !endDate) {
            toast.error("Bitte Zeitraum auswählen.", {
                autoClose: 2000,
            });
            return false;
        }
        if (calculatedVacationDays === 0) {
            toast.error("Diese Eingabe würde zu einem Urlaub von 0 Tagen führen.", {
                autoClose: 2000,
            });
            return false;
        }

        if (residualVacation && residualVacationDays - calculatedVacationDays < 0) {
            toast.error("Zu wenig Resturlaub vorhanden um Urlaub abzudecken.", { autoClose: 3000 });
            return false;
        }

        if (
            !residualVacation &&
            (vacation
                ? moment(vacation.to) <= moment().endOf("year") &&
                  freeVacationDays - (calculatedVacationDays - vacation.days) < 0
                : moment(endDate) <= moment().endOf("year") &&
                  freeVacationDays - calculatedVacationDays < 0)
        ) {
            toast.error("Dieser Zeitraum überschreitet die verbliebenen Urlaubstage.", {
                autoClose: 2000,
            });
            return false;
        }
        for (let i = 0; i < vacations.length; i++) {
            if (vacation?.id === vacations[i].id) continue;
            const vac = vacations[i];
            const format = "YYYY-MM-DD";
            const from = moment(vac.from, format);
            const to = moment(vac.to, format);
            if (
                startDate.startOf("day").isBetween(from, to, null, "[]") ||
                endDate.startOf("day").isBetween(from, to, null, "[]")
            ) {
                toast.error("Der Mitarbeiter hat in diesem Zeitraum bereits Urlaub.", {
                    autoClose: 2000,
                });
                return false;
            }
        }
        if (
            moment(startDate) <= moment().endOf("year") &&
            moment(endDate) >= moment().endOf("year")
        ) {
            toast.error(
                "Bitte teilen Sie jahresübergreifende Urlaube auf. Einen für dieses Jahr und einen für das nachfolgende.",
                {
                    autoClose: 3000,
                }
            );
            return false;
        }
        return true;
    };

    if (holidayArr.length === 0) {
        return <></>;
    }

    return (
        <AddVacationWrapper>
            <Row>
                <Col xs={11}>
                    {vacation ? (
                        <p>Hier können Sie den ausgewählten Urlaub bearbeiten</p>
                    ) : (
                        <p>Hier können Sie einen neuen Urlaub für diesen Mitarbeiter hinzufügen.</p>
                    )}
                </Col>
                <Col>
                    {setShowEditId && (
                        <SmallDangerButton
                            style={{ position: "relative", top: "5px", right: "5px" }}
                            onClick={() => setShowEditId(0)}
                        >
                            x
                        </SmallDangerButton>
                    )}
                </Col>
            </Row>
            <Row>
                <Col sm={5}>
                    <label>Zeitraum (Wochenenden und Feiertage werden nicht mitgezählt)</label>
                </Col>
                <Col sm={5}>
                    <label>Anzahl der Feiertage innerhalb dieses Zeitraums</label>
                </Col>
            </Row>
            <Row style={{ alignItems: "center" }}>
                <Col sm={5}>
                    <DateRangePicker
                        startDate={startDate || null} // momentPropTypes.momentObj or null,
                        startDateId="scheduled_from" // PropTypes.string.isRequired,
                        endDate={endDate || null} // momentPropTypes.momentObj or null,
                        endDateId="scheduled_to" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                        }} // PropTypes.func.isRequired,
                        minimumNights={0}
                        displayFormat={"DD.MM.YYYY"}
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(xFocusedInput) => setFocusedInput(xFocusedInput)} // PropTypes.func.isRequired,
                    />
                </Col>
                <Col sm={5}>
                    <input style={{ width: "100%" }} value={holidays} disabled />
                </Col>
            </Row>
            <Row>
                <Col xs={10}>
                    <label>Berechnete Anzahl der Urlaubstage</label>
                    <input
                        disabled
                        style={{ width: "100%", backgroundColor: "#F2F2F2" }}
                        value={calculatedVacationDays}
                    />
                </Col>
            </Row>
            <label style={{ marginRight: "5px" }}>Erfordert Freigabe</label>
            <input
                type="checkbox"
                onChange={(e) => setNeedsPermission(e.target.checked)}
                checked={needsPermission}
            />
            <br />
            <label style={{ marginRight: "5px" }}>Mit Brückentag</label>
            <input
                type="checkbox"
                onChange={(e) => setExtraHoliday(e.target.checked)}
                checked={extraHoliday}
            />
            <br />
            <label style={{ marginRight: "5px" }}>
                Resturlaub ({residualVacationDays} {residualVacationDays === 1 ? "Tag" : "Tage"})
                verwenden
            </label>
            <input
                type="checkbox"
                onChange={(e) => setResidualVacation(e.target.checked)}
                checked={residualVacation}
            />

            <div style={{ marginTop: "10px" }}>
                <PrimaryButton onClick={onCreateClick}>
                    {vacation ? "Urlaub bearbeiten" : "Urlaub eintragen"}
                </PrimaryButton>
            </div>
        </AddVacationWrapper>
    );
};

export default AddVacation;
