import { EDIT_INVENTORY_ITEM, NEW_INVENTORY_ITEM } from "@rpforms/shared";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { SmallButton, SmallDangerButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import React from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { COMPANY_ID } from "../../config";

const ServicePositionTableRow = ({ servicePosition, deleteServicePosition = null }: any) => {
    const dispatch = useDispatch();

    const confirmAction = (cb) => {
        if (confirm("Sind Sie sich sicher dieses Objekt zu löschen?")) {
            cb();
        }
    };

    const editServicePosition = () => {
        dispatch(
            openEditor({
                endpoint: `inventory_items`,
                endpointCompany: false,
                new: !servicePosition.id,
                preload: {
                    company_id: COMPANY_ID,
                    inventory_type: "service",
                    ...servicePosition,
                },
                type: !servicePosition.id ? NEW_INVENTORY_ITEM : EDIT_INVENTORY_ITEM,
            })
        );
    };

    if (!servicePosition) {
        return <></>;
    }

    return (
        <tr>
            <td>
                <strong>{servicePosition.name}</strong>
            </td>
            <td>{servicePosition.description}</td>
            <td className={"text-right"}>
                <strong>{servicePosition.sales_price_formatted}</strong>
            </td>
            <td>
                <RightAligned>
                    <SmallButton onClick={editServicePosition}>Bearbeiten</SmallButton>
                    {deleteServicePosition !== null && (
                        <SmallDangerButton
                            onClick={() =>
                                confirmAction(() => deleteServicePosition(servicePosition))
                            }
                        >
                            <i className={"fa fa-trash"}></i>
                        </SmallDangerButton>
                    )}
                </RightAligned>
            </td>
        </tr>
    );
};

export default withRouter(ServicePositionTableRow);
