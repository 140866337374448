import React from "react";
import { UploadField } from "../../components/uploadField/UploadField";
import { Configurable } from "./Configurable";
import { AppearanceSlider } from "./fields/AppearanceSlider";

/**
 * Applies minimum amount of images to input field
 */
export class RequiredImageAmount extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = 0;

    public keyName = "requiredImageAmount";
    public title = "Mindestanzahl Bilder?";
    public description = "Muss eine Mindestanzahl an Bildern hochgeladen werden?";
    public value = "";

    constructor({ register, schema, designer, field }) {
        super({ register, schema, designer, field });
        this.widget = (
            <AppearanceSlider
                register={register}
                placeholder={this.title}
                value={this.fieldOptions[this.keyName] ?? this.defaultValue}
                field={field}
                min={0}
                max={10}
                step={1}
                label={""}
                hidePercentage={true}
                keyName={this.keyName}
            />
        );
        this.field = field;
    }

    public configureInput(inputEl): any {
        if (!inputEl) return;
        if (inputEl.type.toString() === "Symbol(react.fragment)") {
            return inputEl;
        }
        const children = inputEl.props.children;
        const input = children.find((c) => c.type === "input");
        if (!input) return;
        const numberOfUploads = this.getNumberOfUploads(input);
        const canBeSubmitted = numberOfUploads >= this.value;
        return React.cloneElement(
            inputEl,
            {
                required: !canBeSubmitted,
                requiredimages: this.value, // TODO: change to custom attribute
                ...inputEl.props,
            },
            inputEl.props.children
        );
    }

    private getNumberOfUploads(inputEl: React.ReactElement) {
        const uploads = inputEl.props.value;
        const uploadArray = JSON.parse(uploads);
        return uploadArray.length;
    }
}
