import * as actions from "@rpforms/shared/build/actions/forms";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import PreviewForm from "../../components/forms/PreviewForm";

export class PreviewFormPage extends React.Component {
    public props;

    public componentWillMount() {
        const { id } = this.props.match.params;
        this.props.formByID(id);
    }

    public render() {
        let form: any = {};
        this.props.forms.forEach((newForm) => {
            if (newForm.id === parseInt(this.props.match.params.id, 10)) {
                form = newForm;
            }
        });

        const isLoading = !form.id;

        return (
            <>
                {isLoading ? (
                    <>
                        <PageNavbar title="Lade Formular..." description=""></PageNavbar>
                        <Loader />
                    </>
                ) : (
                    <>
                        <PageNavbar title={`${form.name}`} description="Vorschau">
                            <Route
                                path="/settings/protocols/forms/preview-:id"
                                exact
                                render={(props) => {
                                    return (
                                        <>
                                            <PageNavAction
                                                onClick={() => {
                                                    window.print();
                                                }}
                                                icon="print"
                                                link={`/settings/protocols/forms/preview-${form.id}`}
                                                title="Drucken"
                                            />
                                            <PageNavAction
                                                onClick={() => {
                                                    const clearAllIntervals = () => {
                                                        const highestId = window.setTimeout(() => {
                                                            for (let i = 0; i < highestId; i++) {
                                                                window.clearInterval(highestId - i);
                                                            }
                                                        }, 0);
                                                    };
                                                    clearAllIntervals();
                                                }}
                                                link={`/settings/protocols/forms/edit-${form.id}`}
                                                icon="edit"
                                                title="Bearbeiten"
                                                primary={true}
                                            />
                                        </>
                                    );
                                }}
                            />
                        </PageNavbar>

                        <Content>
                            <Route
                                path="/settings/protocols/forms/preview-:id"
                                exact
                                render={(props) => <PreviewForm form={form} />}
                            />
                            <Route
                                path="/settings/protocols/forms/view-:id"
                                exact
                                render={(props) => <PreviewForm form={form} />}
                            />
                        </Content>
                    </>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return state.forms;
}

export default withRouter(connect(mapStateToProps, actions)(PreviewFormPage));
