import React from "react";
import { Button, Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const EntityStatsModal = ({
    entity,
    settingsModalShow,
    setSettingsModalShow,
    filterByYear = null,
}) => {
    return (
        <Modal
            show={settingsModalShow === entity.id}
            onHide={() => {
                setSettingsModalShow(0);
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{entity.name} – Statistiken</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Object.keys(entity.stats).map((year, yearI) => {
                    return (
                        (filterByYear === null || filterByYear === year) && (
                            <div key={`yearstat-${year}`}>
                                <h6>{year}</h6>
                                <ul>
                                    {Object.keys(entity.stats[year])
                                        .sort()
                                        .map((stat, statI) => {
                                            return (
                                                <li key={`stat-${stat}`}>
                                                    <small key={`small-stat-${stat}`}>
                                                        {stat}: {entity.stats[year][stat]}
                                                    </small>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        )
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setSettingsModalShow(0);
                    }}
                >
                    schließen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default withRouter(EntityStatsModal);
