import { PrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { ModalAddressForm } from "./ModalAddressForm";

interface IModalAddress {
    editor: IEditor;
    next: (payload) => {};
}

export const ModalAddress = ({ editor, next }: IModalAddress) => {
    const [address, setAddress] = useState<IAddress>({ ...editor.config.preload });
    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data) => {
        next({ address: data });
    };

    const isNew = editor.config.new;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{isNew ? "Neue Adresse erstellen" : "Adresse bearbeiten"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalAddressForm address={address} register={register} errors={errors} />
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton type={"submit"}>{isNew ? "Erstellen" : "Speichern"}</PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
