import { useEffect, useState } from "react";

export enum ViewMode {
    EDIT = 1,
    PDF = 2,
    JSON = 3,
    PREVIEW = 4,
}

export const useViewMode = () => {
    const [viewMode, setViewMode] = useState(
        (window as any).EDIT_MODE ? ViewMode.EDIT : ViewMode.PDF
    );

    useEffect(() => {
        if ((window as any).EDIT_MODE) {
            setViewMode(ViewMode.EDIT);
        } else {
            setViewMode(ViewMode.PDF);
        }
    }, []);

    return viewMode;
};
