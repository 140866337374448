import { fetchFormEntriesAJAX, getEntityFormsAJAX } from "@rpforms/shared/build/actions";
import { newDeviceTasks } from "@rpforms/shared/build/actions/calendar_events";
import { createEntityAJAX, getEntityAJAX } from "@rpforms/shared/build/actions/entities";
import { getFormsAJAX } from "@rpforms/shared/build/actions/forms";
import { PrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import { BoxCard, CardBody, CardTitle } from "@rpforms/shared/build/components/layout/Widget";
import { range, toSelectOptions } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";
import styled from "styled-components";

const DeviceTaskWrapper = styled.div`
    padding: 20px 20px;
    background: #fafafa;

    .DateRangePicker {
        margin-top: 0 !important;
        margin-bottom: 20px;
    }

    label {
        margin-top: 20px;
    }
`;

const AddDeviceTask = ({ save, name, eventId, eventType }: any) => {
    const defaultFormValue = {
        value: null,
        label: "Alle Formulare",
    };
    const [loading, setLoading] = useState(false);
    const [afterTask, setAfterTask] = useState("donothing");
    const [addNextEventIter, setAddNextEventIter] = useState(false);
    const [forms, setForms] = useState([]);
    const [entity, setEntity] = useState(null);
    const [year, setYear] = useState({
        label: new Date().getFullYear() - 1,
        value: new Date().getFullYear() - 1,
    });

    const [formEntryTemplated, setFormEntryTemplated] = useState({
        value: false,
        label: "nein",
    });

    useEffect(() => {
        if (eventType && eventType === "Nachbearbeitung") {
            setFormEntryTemplated({
                value: true,
                label: "ja",
            });
        }
    }, [eventType]);

    const [formOptionsLoading, setFormOptionsLoading] = useState(true);
    const [formOptions, setFormOptions] = useState([defaultFormValue]);
    const [selectedFormOptions, setSelectedFormOptions] = useState([defaultFormValue]);

    const [formEntryTemplates, setFormEntryTemplates] = useState([]);
    const [selectedFormEntryTemplateIds, setSelectedFormEntryTemplateIds] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [overwriteOldEntries, setOverwriteOldEntries] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (formEntryTemplated && entity && year && !loading) {
            updateAvailableFormOptions();
            updateFormEntryTemplates([defaultFormValue]);
        } else {
            setFormEntryTemplates([]);
        }
    }, [entity, year]);

    useEffect(() => {
        if (formEntryTemplated && entity && year && !loading) {
            const formIds = selectedFormOptions.map((f) => f.value).filter((v) => v != null);
            setLoading(true);
            fetchFormEntriesAJAX(entity.value, year.value, formIds, {
                order_by: "form_entries.name",
                order_dir: "asc",
            }).then((response) => {
                setLoading(false);
                setFormEntryTemplates(response.data.form_entries);
            });
        }
    }, [selectedFormOptions, entity, year]);

    const dispatchDeviceTask = async (id) => {
        if (id === "all") {
            return;
        }
        if (formEntryTemplated.value && eventType && eventType === "Nachbearbeitung") {
            // ? selectedFormEntryTemplateIds;
        }
        const params = {
            calendar_event_id: parseInt(id, 10),
            form_ids: forms.map((f) => f.value),
            entity_id: entity ? entity.value : null,
            afterTask,
            template_form_entry_ids: formEntryTemplated.value
                ? selectedFormEntryTemplateIds.join(",")
                : null,
            overwrite_entry_ids: formEntryTemplated.value
                ? eventType && eventType === "Nachbearbeitung"
                    ? selectedFormEntryTemplateIds.join(",")
                    : overwriteOldEntries.join(",")
                : null,
        };

        if (name) {
            name();
        }

        if (formEntryTemplated.value) {
            if (selectedFormEntryTemplateIds.length === 0) {
                toast.error("Bitte Protokolle auswählen", { autoClose: 2000 });
                return;
            } else {
                await dispatch(newDeviceTasks(params));
                setSelectedFormEntryTemplateIds([]);
                setTimeout(() => save(), 500);
                toast.success("✌️Aufgaben gespeichert", { autoClose: 2000 });
                return;
            }
        }

        await dispatch(newDeviceTasks(params));
        setTimeout(() => save(), 500);
        toast.success("✌️Aufgabe gespeichert", { autoClose: 2000 });
    };

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            setLoading(true);

            getFormsAJAX(inputValue).then((response) => {
                setLoading(false);
                resolve(
                    response.data.map((xForm) => {
                        return {
                            value: xForm.id,
                            label: xForm.name,
                        };
                    })
                );
            });
        });

    const promiseEntityOptions = (inputValue) =>
        new Promise((resolve) => {
            getEntityAJAX(inputValue).then((response) => {
                resolve(
                    response.data.map((xEntity) => {
                        return {
                            value: xEntity.id,
                            label: xEntity.name,
                        };
                    })
                );
            });
        });

    const createEntity = (inputVal) => {
        createEntityAJAX({ title: inputVal })(0).then((r) => {
            setEntity({
                value: (r as any).data.id,
                label: inputVal,
            });
        });
    };

    const yearsSinceBeginOfApp = toSelectOptions(range(2019, new Date().getFullYear()));

    const updateAvailableFormOptions = () => {
        setFormOptionsLoading(true);
        setLoading(true);
        getEntityFormsAJAX(entity.value).then((response) => {
            const fOptions = [defaultFormValue].concat(toSelectOptions(response.data));
            setFormOptions(fOptions);
            setLoading(false);
            setSelectedFormOptions([defaultFormValue]);
            setFormOptionsLoading(false);
        });
    };

    const updateFormEntryTemplates = (opt) => {
        if (opt && selectedFormOptions[0]) {
            const allFormsSelectedBefore = selectedFormOptions[0].value === null;
            const cleanedOpt = opt.filter((o) => o.value !== null);
            const allFormsSelected = cleanedOpt.length !== opt.length;
            if (!allFormsSelectedBefore && allFormsSelected) {
                setSelectedFormOptions([defaultFormValue]);
            } else {
                setSelectedFormOptions(cleanedOpt);
            }
        } else {
            setSelectedFormOptions([defaultFormValue]);
        }
    };

    const toggleEntryCheckBox = (event) => {
        const tId = event.target.value;
        if (!tId) {
            const checkbox = event.target.querySelector('input[type="checkbox"]');
            if (checkbox) {
                checkbox.click();
            }
            return;
        }
        const formEntryId = parseInt(tId, 10);
        return formEntryId;
    };

    const toggleFormEntryTemplateId = (event) => {
        const formEntryId = toggleEntryCheckBox(event);
        if (!formEntryId) {
            return;
        }
        if (!selectedFormEntryTemplateIds.includes(formEntryId)) {
            setSelectedFormEntryTemplateIds(selectedFormEntryTemplateIds.concat([formEntryId]));
        } else {
            setSelectedFormEntryTemplateIds(
                selectedFormEntryTemplateIds.filter((a) => a !== formEntryId)
            );
        }
    };

    const toggleOverwriteOldEntry = (event) => {
        const formEntryId = toggleEntryCheckBox(event);
        if (!formEntryId) {
            return;
        }
        if (!overwriteOldEntries.includes(formEntryId)) {
            setOverwriteOldEntries(overwriteOldEntries.concat([formEntryId]));
        } else {
            setOverwriteOldEntries(overwriteOldEntries.filter((a) => a !== formEntryId));
        }
    };

    const selectAll = () => {
        if (!allSelected) {
            setAllSelected(true);
            setSelectedFormEntryTemplateIds(formEntryTemplates.map((fe) => fe.id));
        } else {
            setAllSelected(false);
            setSelectedFormEntryTemplateIds([]);
        }
    };

    useEffect(() => {
        if (addNextEventIter && !formEntryTemplated.value && forms && forms.length > 0) {
            setAddNextEventIter(false);
        }
    }, [addNextEventIter]);

    return (
        <BoxCard>
            <CardBody>
                <DeviceTaskWrapper>
                    <p>
                        Hier können Sie eine Aufgabe für dieses Kalenderevent anlegen. Das Formular
                        erscheint während des ausgewählten Zeitraums auf dem jeweiligen Gerät des
                        Nutzers.
                    </p>
                    <Row>
                        <Col>
                            <label>Bestehenden Eintrag verwenden?</label>
                            <Select
                                value={formEntryTemplated}
                                onChange={(opt, action) => setFormEntryTemplated(opt)}
                                isDisabled={
                                    loading || (eventType && eventType === "Nachbearbeitung")
                                }
                                options={[
                                    { value: false, label: "nein" },
                                    {
                                        value: true,
                                        label: "ja",
                                    },
                                ]}
                            />
                        </Col>
                        <Col>
                            {formEntryTemplated.value && (
                                <>
                                    <label>Jahr</label>
                                    <Select
                                        value={year}
                                        onChange={(opt, action) => setYear(opt)}
                                        options={yearsSinceBeginOfApp}
                                        isDisabled={loading}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Objekt</label>
                            <AsyncCreatableSelect
                                value={entity}
                                onCreateOption={createEntity}
                                creatable
                                onChange={(opt) => {
                                    setEntity(opt);
                                    setSelectedFormEntryTemplateIds([]);
                                }}
                                placeholder={"Objekt auswählen..."}
                                cacheOptions
                                isDisabled={loading}
                                defaultOptions
                                loadOptions={promiseEntityOptions}
                            />
                        </Col>
                        <Col>
                            {!formEntryTemplated.value && entity && (
                                <>
                                    <label>Formular</label>
                                    <AsyncSelect
                                        onChange={(opt) => {
                                            setAddNextEventIter(true);
                                            setForms(opt);
                                            setSelectedFormEntryTemplateIds([]);
                                        }}
                                        isDisabled={loading || formOptionsLoading}
                                        placeholder={"Formular auswählen..."}
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={promiseOptions}
                                        isMulti
                                    />
                                </>
                            )}
                            {formEntryTemplated.value && entity && (
                                <>
                                    <label>Formular</label>
                                    <Select
                                        value={selectedFormOptions}
                                        defaultValue={defaultFormValue}
                                        isMulti
                                        isDisabled={loading || formOptionsLoading}
                                        onChange={(opt) => {
                                            updateFormEntryTemplates(opt);
                                            setSelectedFormEntryTemplateIds([]);
                                        }}
                                        placeholder={"Formulare auswählen..."}
                                        options={formOptions}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    {loading && <Spinner animation={"grow"} />}
                    {!loading && !formOptionsLoading && formEntryTemplated.value && (
                        <Row>
                            <Col>
                                <br />
                                <BoxCard>
                                    <CardTitle>Protokolle</CardTitle>
                                    <CardBody>
                                        <Form.Check type="checkbox" id={"formEntry-all"}>
                                            <Form.Check.Input
                                                type="checkbox"
                                                onChange={selectAll}
                                            />
                                            <Form.Check.Label className={"mt-0"}>
                                                <strong>alle auswählen</strong>
                                            </Form.Check.Label>
                                        </Form.Check>
                                        <Table striped bordered hover size="sm">
                                            <tbody>
                                                {formEntryTemplates.map((formEntry) => {
                                                    return (
                                                        <tr key={formEntry.id}>
                                                            <td onClick={toggleFormEntryTemplateId}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={"formEntry-" + formEntry.id}
                                                                >
                                                                    <Form.Check.Input
                                                                        checked={selectedFormEntryTemplateIds.includes(
                                                                            formEntry.id
                                                                        )}
                                                                        type="checkbox"
                                                                        value={formEntry.id}
                                                                        onChange={
                                                                            toggleFormEntryTemplateId
                                                                        }
                                                                    />
                                                                    <Form.Check.Label
                                                                        className={"mt-0"}
                                                                    >
                                                                        {formEntry.name}
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </td>
                                                            <td>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id={
                                                                        "overwrite-entry-" +
                                                                        formEntry.id
                                                                    }
                                                                >
                                                                    <Form.Check.Input
                                                                        checked={
                                                                            (eventType &&
                                                                                eventType ===
                                                                                    "Nachbearbeitung") ||
                                                                            overwriteOldEntries.includes(
                                                                                formEntry.id
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            eventType &&
                                                                            eventType ===
                                                                                "Nachbearbeitung"
                                                                        }
                                                                        type="checkbox"
                                                                        value={formEntry.id}
                                                                        onChange={
                                                                            toggleOverwriteOldEntry
                                                                        }
                                                                    />
                                                                    <Form.Check.Label
                                                                        className={"mt-0"}
                                                                    >
                                                                        <p>
                                                                            Bestehenden Eintrag
                                                                            überschreiben?
                                                                        </p>
                                                                    </Form.Check.Label>
                                                                </Form.Check>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </BoxCard>
                            </Col>
                        </Row>
                    )}
                    <br />
                    <div
                        style={
                            !entity
                                ? {
                                      pointerEvents: "none",
                                      opacity: 0.7,
                                  }
                                : {}
                        }
                    >
                        <PrimaryButton
                            onClick={() => dispatchDeviceTask(eventId)}
                            disabled={
                                (formEntryTemplated.value &&
                                    selectedFormEntryTemplateIds.length <= 0) ||
                                !entity
                            }
                        >
                            {formEntryTemplated.value && selectedFormEntryTemplateIds.length > 1
                                ? "Aufgaben erstellen"
                                : "Aufgabe erstellen"}
                        </PrimaryButton>
                    </div>
                </DeviceTaskWrapper>
            </CardBody>
        </BoxCard>
    );
};

export default withRouter(AddDeviceTask);
