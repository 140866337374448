import React, { useState } from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { uniqueID } from "../utils";
import { BaseField } from "./BaseField";
import { AppearanceSlider, PercentageIndicator } from "./configurables/fields/AppearanceSlider";

export class HSpacerField extends BaseField {
    public name = "";
    public label = "";
    public hashCode = uniqueID();
    public icon = "arrows-h";
    public fieldType = "Abstand";

    public attributeNames = ["spaceWidth"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        const [percentage, setPercentage] = useState(field.spaceWidth);

        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Feldname (optional)</label>
                        <div className="text-muted">
                            Bitte nur alphanumerische Buchstabenkombinationen verwenden. Keine
                            Sonderzeichen. Keine Leerzeichen. Darf nicht mit einer Zahl anfangen.
                        </div>
                        <input
                            type={field.designerInputType}
                            name={field.inputName("label")}
                            className="form-control"
                            defaultValue={field.label}
                            ref={register}
                        />

                        <input
                            className="form-control"
                            defaultValue={field.spaceWidth || 100}
                            type="range"
                            placeholder="Abstand in Prozent"
                            ref={register}
                            min={0}
                            max={100}
                            step={1}
                            onChange={(ev) => {
                                setPercentage(ev.target.value);
                            }}
                            name={field.inputName("spaceWidth")}
                        />
                        <PercentageIndicator>{percentage || 100}%</PercentageIndicator>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({ layout, schema, field, entry = null, register, name = field.name }) {
        return <div style={{ display: "inline-flex", width: field.spaceWidth + "%" }}></div>;
    }

    /**
     * Do not wrap element with the usual divs which are required for layout
     */
    public WrapComponent({ layout, entry, schema, field, component }) {
        return <>{component}</>;
    }
}
