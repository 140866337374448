import {
    CREATE_MANUFACTURER,
    GET_MANUFACTURERS,
    MANUFACTURERS_LOADING_START,
    MANUFACTURERS_LOADING_STOP,
} from "../actions/types";

const initialState = {
    manufacturers: [],
    manufacturer: {},
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_MANUFACTURERS:
            return { ...state, ...action.payload };
        case CREATE_MANUFACTURER:
            return {
                ...state,
                manufacturer: action.payload,
                manufacturers: [...state.manufacturers, action.payload],
            };
        case MANUFACTURERS_LOADING_START:
            return { ...state, isLoading: true };
        case MANUFACTURERS_LOADING_STOP:
            return { ...state, isLoading: false };
    }
    return state;
}
