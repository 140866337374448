import { deleteCustomer, fetchCustomers } from "@rpforms/shared";
import {
    PrimaryButton,
    SmallDangerButton,
    SmallPrimaryButton,
} from "@rpforms/shared/build/components/Button";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../reducers";
import { SettingsHeadline } from "./SettingsPage";

const AdminCustomersPage = () => {
    const customers = useSelector<RootState, any>((store) => store.customers.customers);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(
            fetchCustomers(1, () => {
                setLoading(false);
            })
        );
    }, []);

    return (
        <div>
            <SettingsHeadline>
                <span>Kunden</span>
                <PrimaryButton style={{ float: "right" }}>
                    <i className={"fa fa-fw fa-plus-circle"}></i> Hinzufügen
                </PrimaryButton>
            </SettingsHeadline>
            <Table bordered striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Aktion</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={2}>
                                <Loader />
                            </td>
                        </tr>
                    )}
                    {!loading &&
                        customers.map((customer) => {
                            return (
                                <tr key={customer.id}>
                                    <td>{customer.name}</td>
                                    <td>
                                        <SmallPrimaryButton>Bearbeiten</SmallPrimaryButton>{" "}
                                        <SmallDangerButton
                                            onClick={() => {
                                                if (confirm("Sind Sie sicher?")) {
                                                    setLoading(true);
                                                    dispatch(
                                                        deleteCustomer(customer, (data) => {
                                                            location.reload();
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            Entfernen
                                        </SmallDangerButton>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </Table>
        </div>
    );
};

export default withRouter(AdminCustomersPage);
