import { PrimaryButton } from "@rpforms/shared";
import React from "react";
import { Modal } from "react-bootstrap";
import useForm from "react-hook-form";

export const ModalUploadInfo = ({ editor, next }) => {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (data) => {
        await next({});
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>Hinweis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Upload erfolgreich!
                    {/*
                    Wenn ein Lieferschein hochgeladen wurde bitte Termin für Instandsetzung ansetzen
                    / Wenn eine Auftragsbestätigung hochgeladen wurde bitte ggf. Ersatzmaterial
                    bestellen
                    */}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton type={"submit"}>OK</PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
