import React, { useState } from "react";

export default ({ autogrow, onUpdate, ...props }: any) => {
    const [rows, setRows] = useState(1);

    const checkRows = (ev) => {
        if (!autogrow) {
            return;
        }
        setRows(Math.max(ev.target.value.split("\n").length, 1));
    };

    return <textarea rows={rows} onChange={onUpdate} {...props} onKeyUp={checkRows} />;
};
