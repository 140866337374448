const asLines = (input) => input.split(/\n/);

const extractKey = (key) => {
    if (key[0] === "*" || key[0] === "#") {
        return key.substr(1);
    }
    return key;
};

const extractOptions = (key) => {
    const options: any = {};
    if (key[0] === "*") {
        options.selected = true;
    }

    return options;
};

const label = (str) => {
    return str
        .split(" ")
        .map((word) => {
            return word ? `${word[0].toUpperCase()}${word.substr(1)}` : "";
        })
        .join(" ");
};

const parseOptionLine = (line) => {
    let option = {
        key: "",
        label: "",
    };

    const parts = line.split("=").map((part) => part.trim());

    //
    if (parts.length === 2) {
        option.key = extractKey(parts[0]);
        option.label = parts[1];
        option = { ...option, ...extractOptions(parts[0]) };
    }

    if (parts.length === 1) {
        option.key = parts[0];
        option.label = label(parts[0]);
    }

    return option;
};

const isValidOption = (option) => !!option.key && option.key[0] !== "#";

export const parseOptionString = (optionString) => {
    if (!optionString) {
        return [];
    }
    const options = asLines(optionString).map(parseOptionLine).filter(isValidOption);
    return options;
};

export default {
    isValidOption,
    parseOptionString,
    parseOptionLine,
    extractOptions,
    extractKey,
    asLines,
};
