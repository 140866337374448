import React from "react";
import { Configurable } from "./Configurable";
import { AppearanceSlider } from "./fields/AppearanceSlider";
import { AppearanceText } from "./fields/AppearanceText";

/**
 * Applies maximum length to input field
 */
export class MaxLength extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = 80;

    public keyName = "maxlength";
    public title = "Maximale Länge";
    public description = "Hier können Sie einstellen wieviele Zeichen in diesem Feld erlaubt sind";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: any;
    }): any {
        return (
            <AppearanceText
                register={register}
                value={field.rawOptions[this.keyName] || this.defaultValue}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    /**
     * Applies max length to the input element on rendering
     * @param inputEl
     */
    public configureInput(inputEl): any {
        if (!inputEl) {
            return inputEl;
        }
        if (inputEl.type.toString() === "Symbol(react.fragment)") {
            return inputEl;
        }

        return React.createElement(inputEl.type, { maxLength: this.value, ...inputEl.props });
    }
}
