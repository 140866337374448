import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import {
    CREATE_MANUFACTURER,
    GET_MANUFACTURERS,
    MANUFACTURERS_LOADING_START,
    MANUFACTURERS_LOADING_STOP,
} from "./types";

export function getManufacturers(page = 1, pageSize = -1) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        dispatch({ type: MANUFACTURERS_LOADING_START });
        axios
            .get(
                `${API_URL}/manufacturers.json?order_by=name&order_dir=ASC&page=${page}&page_size=${pageSize}`,
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_MANUFACTURERS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                dispatch({ type: MANUFACTURERS_LOADING_STOP });
            });
    };
}

export function createManufacturerAJAX(manufacturer: any) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        dispatch({ type: MANUFACTURERS_LOADING_START });
        try {
            const res = await axios.post(
                `${API_URL}/manufacturers.json`,
                {
                    manufacturer: { ...manufacturer },
                },
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: CREATE_MANUFACTURER,
                payload: res.data,
            });
            dispatch({ type: MANUFACTURERS_LOADING_STOP });
            return res.data;
        } catch (err) {
            throw err;
        }
    };
}
