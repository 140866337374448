import React from "react";
import { Breadcrumb, BreadcrumbItem } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";

const BreadcrumbWrapper = styled(Breadcrumb)`
    ol {
        border-radius: 0;
        margin: 0;
    }
`;

export const FolderBreadcrumbs = ({ navigateTo, hierarchy = [] }) => {
    const company = useSelector<any, any>((state) => state.company.name);
    return (
        <div>
            <BreadcrumbWrapper>
                {hierarchy.map((folder) => {
                    return (
                        <BreadcrumbItem onClick={() => navigateTo(folder)} key={folder.id}>
                            {folder.name || folder.resource_name}
                        </BreadcrumbItem>
                    );
                })}
            </BreadcrumbWrapper>
        </div>
    );
};
