import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { fetchCompany } from "./company";
import { AUTH_USER, CURRENT_USER, SIGNIN_FAILURE, UNAUTH_USER } from "./types";

/**
 * Error helper
 */
export function authError(CONST: any, error: any) {
    return {
        payload: error,
        type: CONST,
    };
}

/**
 * Sign in
 */
export function signinUser(props: any) {
    const { email, password } = props;

    return (dispatch: IDispatch<any>) => {
        axios
            .post(`${API_URL}/auth/login.json`, { email, password })
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response.data));
                dispatch({ type: CURRENT_USER, payload: response.data });
                dispatch({ type: AUTH_USER });
                fetchCompany(Number(COMPANY_ID))(dispatch);
            })
            .catch(() => dispatch(authError(SIGNIN_FAILURE, "Email or password isn't right")));
    };
}

/**
 * Sign out
 */
export function signoutUser() {
    return async (dispatch: IDispatch<any>) => {
        localStorage.clear();

        dispatch({
            type: UNAUTH_USER,
        });
        await fetchCompany(Number(COMPANY_ID))(dispatch);
    };
}
