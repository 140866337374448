import { useEffect, useState } from "react";

export const useGlobalError = () => {
    const [error, setError] = useState(null);

    useEffect(() => {
        const handler = (ev) => {
            setError(ev);
        };

        window.addEventListener("error", handler);
        return () => {
            window.removeEventListener("error", handler);
        };
    });

    return [error, setError];
};
