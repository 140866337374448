import * as actions from "@rpforms/shared/build/actions/formEntries";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import ViewEntry from "../../components/entries/ViewEntry";

class ViewEntryPage extends Component {
    public props;
    public state = {};

    public componentDidMount() {
        this.props.getFormEntry(this.props.match.params.id, () => {});
    }

    public render() {
        const entry = this.props.formEntries.byId[`entry_${this.props.match.params.id}`];
        return <>{entry && entry.form_data ? <ViewEntry entry={entry} /> : <Loader />}</>;
    }
}

function mapStateToProps(state) {
    return { device: state.device, formEntries: state.formEntries };
}

export default withRouter(connect(mapStateToProps, actions)(ViewEntryPage));
