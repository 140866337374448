import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FinancesType } from "../../pages/finances/FinancesPage";
import FinancesCustomerDetails from "./FinancesCustomerDetails";
import FinancesDateSelector from "./FinancesDateSelector";
import FinancesFilter from "./FinancesFilter";

export const OffersOverview = ({ maxDate }) => {
    return (
        <Container fluid>
            <Row style={{ marginTop: "2rem", alignItems: "flex-end" }}>
                <Col>
                    <FinancesFilter />
                </Col>
                <Col lg={4}>
                    <FinancesDateSelector
                        hideDay={true}
                        hideMonth={true}
                        type={FinancesType.OFFER}
                    />
                </Col>
            </Row>{" "}
            <FinancesCustomerDetails type={"offer"} maxDate={maxDate} />
        </Container>
    );
};
