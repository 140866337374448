import React from "react";
import useForm from "react-hook-form";
import AddField from "./AddField";
import { Button, PrimaryButton, SuccessButton } from "./layout/Button";

/**
 * Provides basic form control for modifying form properties
 * @param schema
 * @param designer
 * @param handleFormSave
 * @constructor
 */
export const FormDesigner = ({ schema, designer, handleFormSave }) => {
    const { handleSubmit, register, errors }: any = (useForm as any)();
    const onSubmit = (values) => {
        handleFormSave(values);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {schema.fields().map((field, index) => {
                return (
                    <div key={field.hashCode}>
                        <field.EditComponent
                            designer={designer}
                            register={register}
                            schema={schema}
                            field={field}
                        />
                        {index < schema.fields().length - 1 ? (
                            <div>
                                <AddField
                                    showCustomFields={true}
                                    attached={true}
                                    small={true}
                                    after={field}
                                    handleNewField={designer.newFieldBelow(field).bind(designer)}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            })}

            <div className="d-flex mt-5 pr-0 pl-0">
                <SuccessButton id={"formSaveBtn"} type="submit">
                    <i className="fa fa-save"></i> Formular speichern
                </SuccessButton>

                <AddField
                    showCustomFields={true}
                    handleNewField={designer.newField.bind(designer)}
                />

                <PrimaryButton
                    id={"moveUp"}
                    onClick={() => {
                        document.querySelector("html").scrollTop = 0;
                        return false;
                    }}
                    type="button"
                >
                    <i className="fa fa-chevron-up"></i> Nach oben
                </PrimaryButton>
            </div>
        </form>
    );
};
