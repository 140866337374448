import { PrimaryButton } from "@rpforms/shared";
import {
    changeEmployeeColor,
    deleteEmployee,
    fetchEmployeeById,
    updateEmployee,
} from "@rpforms/shared/build/actions/employees";

import { DangerButton } from "@rpforms/shared/build/components/Button";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SwatchesPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import AddVacation from "../../../components/employees/AddVacation";
import { VacationList } from "../../../components/employees/VacationList";
import EmployeeCertificates from "./EmployeeCertificates";
import EmployeeSpecialVacations from "./EmployeeSpecialVacations";

//fetches all of hessens holidays for one year
const fetchHolidays = (year: number) => {
    return fetch(`https://feiertage-api.de/api/?jahr=${year}&nur_land=HE`)
        .then((res) => res.json())
        .then((data) => {
            return Object.values(data)
                .map((holidayObj: any) => {
                    return moment(holidayObj.datum).startOf("day");
                })
                .filter((date: any) => date.day() !== 6 && date.day() !== 0);
        });
};

const replaceCommaWithNL = (txt: string) => {
    return txt.replace(/,/g, "\n");
};

const replaceNLWithComma = (txt) => {
    return txt
        .replace(/\n/g, ",")
        .split(",")
        .map((d) => d.trim())
        .join(",");
};

export const ViewEmployeePage = () => {
    const { id }: { id: string } = useParams();
    const dispatch = useDispatch();
    const [calendarColor, setCalendarColor] = useState("");
    const [colorSwitcherShown, setColorSwitcherShown] = useState(false);

    const handleOnChangeColorClick = () => {
        setColorSwitcherShown(true);
    };

    const handleColorChange = () => {
        dispatch(changeEmployeeColor({ employeeId, color: calendarColor }));
        setColorSwitcherShown(false);
    };

    const handleColorChangeCancelClick = () => {
        setColorSwitcherShown(false);
    };

    const handleOnColorChangeComplete = (color: { hex: string }) => {
        setCalendarColor(color.hex);
    };
    const { employeeId }: { employeeId: string } = useParams();

    // TODO: refactor into object.
    const [name, setName] = useState(null);
    const [dob, setDob] = useState(null);
    const [uid, setUID] = useState(null);
    const [vacationDays, setVacationDays] = useState(null);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [holidayArr, setHolidayArr] = useState([]);
    const [residualVacation, setResidualVacation] = useState(false);
    const employee = useSelector<any, any>((state) =>
        state.employees.employees.find((e) => e.id == parseInt(employeeId))
    );
    const [startDate, setStartDate] = useState(
        employee?.extra_vac_from ? moment(employee?.extra_vac_from, "YYYY-MM-DD") : null
    );
    const [endDate, setEndDate] = useState(
        employee?.extra_vac_to ? moment(employee.extra_vac_to, "YYYY-MM-DD") : null
    );
    const [freeVacationDays, setFreeVacationDays] = useState(null);
    const [residualVacationDays, setResidualVacationDays] = useState(null);
    const [extraVac, toggleExtraVac] = useState(employee?.has_extra_vac);
    const [extraVacName, setExtraVacName] = useState(employee?.extra_vac_name);
    useEffect(() => {
        dispatch(fetchEmployeeById(employeeId));
        const getAllHolidays = async () => {
            const thisYearsHolidays = await fetchHolidays(moment().year());
            const nextYearsHolidays = await fetchHolidays(moment().year() + 1);
            setHolidayArr(thisYearsHolidays.concat(nextYearsHolidays));
        };
        getAllHolidays();
    }, []);

    useEffect(() => {
        // TODO: Thank god react supports batching right... right?
        if (!employee) {
            setResidualVacationDays(0);
            setFreeVacationDays(0);
            setVacationDays(0);
            return;
        }

        setResidualVacationDays(employee.residual_vacation_days);
        setFreeVacationDays(employee.free_vacation_days);
        setVacationDays(employee.vacation_days);
        setStartDate(
            employee.extra_vac_from ? moment(employee.extra_vac_from, "YYYY-MM-DD") : null
        );
        setEndDate(employee.extra_vac_to ? moment(employee.extra_vac_to, "YYYY-MM-DD") : null);
        setExtraVacName(employee.extra_vac_name);
        toggleExtraVac(employee.has_extra_vac);
        setDob(moment(employee.dob, "YYYY-MM-DD"));
    }, [employee]);
    const [focusedInput, setFocusedInput] = useState(null);

    if (!employee) {
        return <Loader />;
    }

    const onSaveClick = () => {
        const params = {
            ...employee,
            name: name || employee.name,
            dob: dob || employee.dob,
            vacation_days: vacationDays || employee.vacation_days,
        };
        if (uid) {
            params.time_tracking_uid = uid
                .split(",")
                .map((s) => s.trim())
                .join(",");
        }

        if (freeVacationDays - (employee.vacation_days - vacationDays) < 0) {
            toast.error(
                "Diese Änderung würde dazu führen, dass der Mitarbeiter eine negative Anzahl freier Urlaubstage bekommt",
                { autoClose: 3000 }
            );
            setVacationDays(employee.vacation_days);
            return;
        }
        dispatch(updateEmployee(params));
        toast.success("Mitarbeiter gespeichert", { autoClose: 2000 });
        dispatch(fetchEmployeeById(employeeId));
        setIsInEditMode(false);
    };

    const onDeleteClick = () => {
        dispatch(deleteEmployee(employee));
    };

    const deleteExtraVac = () => {
        const params = {
            ...employee,
            name: name || employee.name,
            vacation_days: vacationDays || employee.vacation_days,
            has_extra_vac: false,
        };

        dispatch(updateEmployee(params));
        toast.success("Mitarbeiter gespeichert", { autoClose: 2000 });
        dispatch(fetchEmployeeById(employeeId));
        setIsInEditMode(false);
    };

    const addExtraVac = () => {
        const params = {
            ...employee,
            name: name || employee.name,
            vacation_days: vacationDays || employee.vacation_days,
            has_extra_vac: true,
            extra_vac_from: moment(startDate).format("DD-MM-YYYY"),
            extra_vac_to: moment(endDate).format("DD-MM-YYYY"),
            extra_vac_name: extraVacName,
            residual_vacation: residualVacation,
        };

        if (freeVacationDays - (employee.vacation_days - vacationDays) < 0) {
            toast.error(
                "Diese Änderung würde dazu führen, dass der Mitarbeiter eine negative Anzahl freier Urlaubstage bekommt",
                { autoClose: 3000 }
            );
            setVacationDays(employee.vacation_days);
            return;
        }
        dispatch(updateEmployee(params));
        toast.success("Mitarbeiter gespeichert", { autoClose: 2000 });
        dispatch(fetchEmployeeById(employeeId));
        setIsInEditMode(false);
    };

    return (
        <>
            <PageNavbar
                title="Mitarbeiteransicht"
                description="Hier können Sie die gespeicherten Informationen zu einem Mitarbeiter einsehen und bearbeiten."
            >
                {!isInEditMode ? (
                    <PageNavAction
                        onClick={() => setIsInEditMode(true)}
                        title="Mitarbeiter bearbeiten"
                        icon="edit"
                        primary={true}
                    />
                ) : (
                    <>
                        <PageNavAction
                            onClick={onSaveClick}
                            success={true}
                            icon="save"
                            title="Speichern"
                        />
                        <PageNavAction
                            onClick={onDeleteClick}
                            danger={true}
                            icon="trash"
                            title="Mitarbeiter löschen"
                        />
                    </>
                )}
                <PageNavAction
                    title="Zum Urlaubskalender"
                    link="/calendar/vacations"
                    icon="calendar"
                />
                <PageNavAction
                    title="Zu allen Mitarbeitern"
                    link="/master_data/employees"
                    icon="list"
                />
            </PageNavbar>
            <Content>
                <Row>
                    <Col sm={8}>
                        <Row style={{ marginTop: "5px", marginLeft: "1px" }}>
                            <Col sm={8}>
                                <Row>
                                    <label>Name</label>
                                </Row>
                                <Row>
                                    <input
                                        type="text"
                                        style={{ width: "100%" }}
                                        defaultValue={employee.name}
                                        disabled={!isInEditMode}
                                        onChange={(input) => setName(input.target.value)}
                                    />
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row>
                                    <label style={{ marginLeft: "20px" }}>Geburtsdatum</label>
                                </Row>
                                <Row>
                                    <input
                                        type="date"
                                        style={{ marginLeft: "20px", width: "100%" }}
                                        defaultValue={employee.dob}
                                        disabled={!isInEditMode}
                                        onChange={(input) => setDob(input.target.value)}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <p
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "30px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <strong>Terminplanerfarbe:</strong>
                                <i
                                    style={{
                                        color: calendarColor || employee.color || "#3174ad",
                                        paddingLeft: "5px",
                                        paddingRight: "10px",
                                    }}
                                    className="fa fa-square fa-2x"
                                ></i>
                                {!colorSwitcherShown && (
                                    <PrimaryButton onClick={handleOnChangeColorClick}>
                                        Farbe ändern
                                    </PrimaryButton>
                                )}
                                {colorSwitcherShown && (
                                    <div style={{ width: 300 }}>
                                        <SwatchesPicker
                                            color={calendarColor}
                                            onChangeComplete={handleOnColorChangeComplete}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <DangerButton onClick={handleColorChangeCancelClick}>
                                                Abbrechen
                                            </DangerButton>
                                            <PrimaryButton onClick={handleColorChange}>
                                                Bestätigen
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </p>

                        <Row style={{ marginTop: "5px", marginLeft: "1px" }}>
                            <Col sm={5}>
                                <Row>
                                    <label>Urlaubstage pro Jahr</label>
                                </Row>
                                <Row>
                                    <input
                                        type="text"
                                        style={{ width: "99%" }}
                                        disabled={!isInEditMode}
                                        value={vacationDays}
                                        onChange={(input) =>
                                            setVacationDays(parseInt(input.target.value, 10))
                                        }
                                    />
                                    Resturlaub: {residualVacationDays}
                                    {residualVacationDays === 1 ? " Tag" : " Tage"}
                                </Row>
                            </Col>
                            <Col sm={5}>
                                <Row>
                                    <label>Freie Urlaubstage für dieses Jahr</label>
                                </Row>
                                <Row>
                                    <input
                                        type="text"
                                        style={{ width: "100%" }}
                                        disabled
                                        value={freeVacationDays}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <br />
                    </Col>
                    <Col sm={4}>
                        {isInEditMode && (
                            <Row style={{ marginTop: "5px", marginLeft: "1px" }}>
                                <Col sm={12}>
                                    <div>Zeiterfassung - Chip Kennungen</div>
                                    <span style={{ color: "#888" }}>Eine Kennung pro Zeile</span>
                                    <Row>
                                        <textarea
                                            rows={6}
                                            style={{ marginLeft: "15px" }}
                                            placeholder={"Tracker"}
                                            defaultValue={replaceCommaWithNL(
                                                employee.time_tracking_uid
                                            )}
                                            onChange={(input) =>
                                                setUID(replaceNLWithComma(input.target.value))
                                            }
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>

                <h3>Eingetragene Urlaube</h3>
                <VacationList
                    employee={employee}
                    freeVacationDays={freeVacationDays}
                    setFreeVacationDays={setFreeVacationDays}
                    holidayArr={holidayArr}
                />
                <br />
                {holidayArr.length > 0 && (
                    <AddVacation
                        employeeId={employeeId}
                        freeVacationDays={freeVacationDays}
                        residualVacationDays={residualVacationDays}
                        residualVacation={residualVacation}
                        setResidualVacationDays={setResidualVacationDays}
                        setResidualVacation={setResidualVacation}
                        setFreeVacationDays={setFreeVacationDays}
                        holidayArr={holidayArr}
                    />
                )}
                <br />
                <EmployeeSpecialVacations employeeId={employeeId} />
                <EmployeeCertificates employeeId={employeeId} />
                <br />
            </Content>
        </>
    );
};

export default ViewEmployeePage;
