import { GET_TERMS } from "../actions/types";

const initialState = {
    data: {
        terms: [],
    },
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_TERMS:
            return { ...state, data: action.payload }; // überschreibt den state
    }
    return state;
}
