// @ts-ignore
import React from "react";

import TwoWayQuerybuilder from "react-two-way-querybuilder";
import styled from "styled-components";
import { stripTags } from "../../utils";
import { Configurable } from "./Configurable";

const QueryBuilderWrapper = styled.div`
    .condition {
        box-shadow: none;
        .rule {
            box-shadow: none;
        }
        button {
            background: none;
            box-shadow: none;
        }
        .queryButtonPrimary {
            color: #0090bb;
        }
        .queryButtonDelete {
            color: red;
        }
        .queryInput {
            height: 31.5px;
        }
    }
`;

/**
 * Applies maximum length to input field
 */
export class Conditional extends Configurable {
    public schema;
    public designer;
    public field;
    public inputEl;
    public defaultValue = "";
    public keyName = "conditional";
    public title = "Anzeigen wenn...";
    public description =
        "Hier können Sie einstellen unter welchen Bedingungen dieses Feld angezeigt wird.";
    public value: any = null;

    constructor({ field }) {
        super({ field });
        this.field = field;

        if (Array.isArray(field.options)) {
            this.value = field.rawOptions.conditional;
        }
    }

    public logQuery(query) {
        this.value = query;
    }

    public EditComponent({ register, schema, designer, field }): any {
        // this.value = this.field.rawOptions.conditional ? this.field.rawOptions.conditional.query : "";
        const config = {
            operators: [
                { operator: "=", label: "Gleich" },
                { operator: "<>", label: "Ungleich" },
                { operator: "<", label: "Kleiner als" },
                { operator: ">", label: "Größer als" },
                { operator: ">=", label: "Größer gleich" },
                { operator: "<=", label: "Kleiner gleich" },
                { operator: "is null", label: "Ist leer" },
                { operator: "is not null", label: "Ist nicht leer" },
                { operator: "in", label: "Beinhaltet..." },
                { operator: "not in", label: "Beinhaltet nicht..." },
            ],
            combinators: [
                { combinator: "AND", label: "UND" },
                { combinator: "OR", label: "ODER" },
                { combinator: "NOT", label: "NICHT" },
            ],
            query: this.value ? this.value.query : "",
        };

        const buttonsText = {
            addRule: "Neue Regel",
            addGroup: "Neue Gruppe",
            clear: "Zurücksetzen",
            delete: "Löschen",
        };

        const queryBuilderFields = [];
        schema.flatFieldHierachy().forEach((field) => {
            queryBuilderFields.push({
                name: field.hashCode,
                operators: "all",
                label: stripTags(field.label),
                input: { type: "text" },
            });
        });

        return (
            <QueryBuilderWrapper>
                <TwoWayQuerybuilder
                    buttonsText={buttonsText}
                    config={config}
                    fields={queryBuilderFields}
                    onChange={(query) => this.logQuery(query)}
                />
            </QueryBuilderWrapper>
        );
    }

    public sleepValue(value): any {
        return value;
    }

    public wakeValue(value) {
        return value;
    }

    public configureInput(inputEl, register): any {
        if (!(window as any).__GLOBAL_CONDITIONALS || inputEl.props.readOnly) {
            return inputEl;
        }

        if ((window as any).__GLOBAL_COND_TIMER) {
            clearInterval((window as any).__GLOBAL_COND_TIMER);
        }

        (window as any).__GLOBAL_COND_TIMER = setInterval(() => {
            this.checkRules();
        }, 240);

        if (typeof (window as any).__GLOBAL_RULE_EVALUATION_MAP === "undefined") {
            (window as any).__GLOBAL_RULE_EVALUATION_MAP = {};
        }

        if (!this.value || this.value.query === "()") {
            inputEl = React.cloneElement(
                inputEl,
                {
                    onClick: (ev) => {
                        if (
                            !this.value ||
                            this.value.query === "()" ||
                            this.value.data.rules.length === 0
                        ) {
                            setTimeout(() => this.checkRules(), 100);
                            return;
                        }
                        if (this.formStateMatches(this.value.data, this)) {
                            this.getParentWrapper(ev.target).style.display = "block";
                            setTimeout(() => window.dispatchEvent(new Event("resize")));
                        } else {
                            this.getParentWrapper(ev.target).style.display = "none";
                            setTimeout(() => window.dispatchEvent(new Event("resize")));
                        }
                        this.checkRules();
                    },
                    onBlur: (ev) => {
                        this.checkRules();
                    },
                    onChange: (ev) => {
                        this.checkRules();
                    },
                    onInput: (ev) => {
                        this.checkRules();
                    },
                    ...inputEl.props,
                },
                inputEl.props.children
            );
            return inputEl;
        }

        (window as any).__GLOBAL_RULE_EVALUATION_MAP[this.field.hashCode] = this.value.data;

        inputEl = React.cloneElement(
            inputEl,
            {
                onClick: (ev) => {
                    if (this.formStateMatches(this.value.data, this)) {
                        this.getParentWrapper(ev.target).style.display = "block";
                        setTimeout(() => window.dispatchEvent(new Event("resize")));
                    } else {
                        this.getParentWrapper(ev.target).style.display = "none";
                        setTimeout(() => window.dispatchEvent(new Event("resize")));
                    }
                    this.checkRules();
                },
                onBlur: (ev) => {
                    if (this.formStateMatches(this.value.data, this)) {
                        this.getParentWrapper(ev.target).style.display = "block";
                    } else {
                        this.getParentWrapper(ev.target).style.display = "none";
                    }
                    this.checkRules();
                },
                onChange: (ev) => {
                    if (this.formStateMatches(this.value.data, this)) {
                        this.getParentWrapper(ev.target).style.display = "block";
                        setTimeout(() => window.dispatchEvent(new Event("resize")));
                    } else {
                        this.getParentWrapper(ev.target).style.display = "none";
                        setTimeout(() => window.dispatchEvent(new Event("resize")));
                    }
                    this.checkRules();
                },
                onInput: (ev) => {
                    if (this.formStateMatches(this.value.data, this)) {
                        this.getParentWrapper(ev.target).style.display = "block";
                        setTimeout(() => window.dispatchEvent(new Event("resize")));
                    } else {
                        this.getParentWrapper(ev.target).style.display = "none";
                        setTimeout(() => window.dispatchEvent(new Event("resize")));
                    }
                    this.checkRules();
                },
                ...inputEl.props,
            },
            inputEl.props.children
        );
        return inputEl;
    }

    public checkRules() {
        const formScope = this.field.formName
            ? document.querySelector(`#${this.field.formName}`) || document.body
            : document.body;

        for (const key in (window as any).__GLOBAL_RULE_EVALUATION_MAP) {
            const fields: any = [
                ...Array.from(formScope.querySelectorAll(`[data-name$="${key}"]`)),
                ...Array.from(formScope.querySelectorAll(`[data-name^="${key}"]`)),
            ];

            if (!fields.length) {
                // might be PDF field
                const pdfField: any = formScope.querySelector("#" + key);
                if (pdfField) {
                    const rule = (window as any).__GLOBAL_RULE_EVALUATION_MAP[key];
                    if (!rule) {
                        return;
                    }

                    if (this.formStateMatches(rule, pdfField)) {
                        pdfField.style.display = "block";
                        setTimeout(() => window.dispatchEvent(new Event("resize")), 10);
                    } else {
                        pdfField.style.display = "none";
                        setTimeout(() => window.dispatchEvent(new Event("resize")), 10);
                    }
                }
                continue;
                return;
            }

            fields.forEach((field) => {
                const rule = (window as any).__GLOBAL_RULE_EVALUATION_MAP[key];
                if (!rule) {
                    console.groupEnd();
                    return;
                }
                if (this.formStateMatches(rule, field)) {
                    this.getParentWrapper(field).style.display = "block";
                    setTimeout(() => window.dispatchEvent(new Event("resize")), 10);
                } else {
                    this.getParentWrapper(field).style.display = "none";
                    setTimeout(() => window.dispatchEvent(new Event("resize")), 10);
                }
            });
        }
    }

    public formStateMatches(ruleSet, field) {
        const formScope = this.field.formName
            ? document.querySelector(`#${this.field.formName}`) || document.body
            : document.body;
        const results = [];

        // parse conditions
        const runGroup = (rule) => {
            let scope: any = formScope;

            // fields in a repeater can only act upon sibling fields.
            if (field.name && field.name.indexOf("repeater_") === 0) {
                // get repeater index
                const index = field.name.split("repeater_")[1].split("_")[0];
                const repeaterHashCode = "_" + field.name.split("_")[2].split("_")[0];
                scope = document.querySelector(
                    `[data-repeater='repeater_${repeaterHashCode}_${index}']`
                );
            }

            let companionInput: any = scope.querySelector(`[name^='${rule.field}']`);
            if (!companionInput) {
                companionInput = scope.querySelector(`[name$='${rule.field}']`);
            }

            // scope
            if (!companionInput) {
                // try to find it outside of the scope
                companionInput = formScope.querySelector(`[name^='${rule.field}']`);
                if (!companionInput) {
                    companionInput = formScope.querySelector(`[name$='${rule.field}']`);
                }

                if (!companionInput) {
                    return;
                }
            }

            const nodeValue = this.getNodeValue(companionInput, rule.field);

            // compare with operators
            switch (rule.operator.toUpperCase()) {
                case "=":
                    results.push(nodeValue === rule.value);
                    break;
                case "<":
                    results.push(nodeValue.length < rule.value);
                    break;
                case ">":
                    results.push(nodeValue.length > rule.value);
                    break;
                case "<=":
                    results.push(nodeValue.length <= rule.value);
                    break;
                case ">=":
                    results.push(nodeValue.length >= rule.value);
                    break;
                case "IS NULL":
                    results.push(!nodeValue);
                    break;
                case "IS NOT NULL":
                    results.push(!!nodeValue);
                    break;
                case "IN":
                    results.push(
                        Array.from(rule.value).indexOf(nodeValue) > -1 ||
                            Array.from(nodeValue).indexOf(rule.value) > -1
                    );
                    break;
                case "NOT IN":
                    results.push(
                        Array.from(rule.value).indexOf(nodeValue) === -1 &&
                            Array.from(nodeValue).indexOf(rule.value) === -1
                    );
                    break;
            }
        };

        ruleSet.rules.forEach(runGroup);

        let match = false;

        // nothing to compare.
        if (results.length === 0) {
            return match;
        }

        // parse combinators
        switch (ruleSet.combinator) {
            case "AND": // all results have to match
                match = results.reduce((prev, cur) => {
                    if (!cur) {
                        return false;
                    }
                    return prev;
                }, true);
                break;

            case "OR": // at least 1 result has to match
                match = results.reduce((prev, cur) => {
                    if (cur) {
                        return true;
                    }
                    return prev;
                }, false);
                break;

            case "NOT": // results have to NOT match
                match = results.reduce((prev, cur) => {
                    if (!cur) {
                        return false;
                    }
                    return prev;
                }, false);
                break;
        }

        return match;
    }

    /**
     * Returns the comparable value of an HTML input element
     * @param node
     */
    public getNodeValue(node, fieldHashCode) {
        if (node.type && node.type === "checkbox") {
            if (node.classList.contains("switch-check-input")) {
                // collect all checked boxes and return them as array.
                const allCheckboxes = Array.from(
                    document.querySelectorAll(`[data-key='${node.dataset.key}']`)
                );
                const tickedCheckboxes = allCheckboxes.filter(
                    (box: HTMLInputElement) => box.checked
                );
                return tickedCheckboxes.map((box: HTMLInputElement) => box.dataset.label);
            }
            return node.checked;
        }

        // RadioField.tsx logic
        if (node.type && node.type.indexOf("select") > -1) {
            return node.querySelector(`option[value='${node.value}']`).innerHTML;
        }

        if (node.type && node.type === "radio") {
            const radios: any = Array.from(
                document.querySelectorAll(`input[type='radio'][name='${fieldHashCode}']`)
            );
            for (const radio of radios) {
                if (radio.checked) {
                    const radioLabel = document.querySelector(`label[for='${radio.value}']`);
                    if (!radioLabel) {
                        return "";
                    }
                    return radioLabel.innerHTML;
                }
            }
            return "";
        }

        // special care taking for switch buttons
        if (node.type && node.type === "hidden" && node.dataset.overrideType === "radio") {
            return node.dataset.label;
        }

        return node.value;
    }

    public getParentWrapper(el) {
        let parent = el.parentElement;

        while (
            parent &&
            !parent.classList.contains("rpfe-field") &&
            parent.tagName.toLowerCase() !== "body"
        ) {
            parent = parent.parentElement;
        }

        if (parent.tagName === "body") {
            return document.createElement("span");
        }

        return parent;
    }

    public fieldWasRendered() {
        this.checkRules();
    }
}
