import React from "react";

export const FormVariablesPage = () => {
    return (
        <div>
            Die <code>AutoFill</code> Option ermöglicht es Ihnen Informationen eines Formulars
            vorauszufüllen, um Ihren Mitarbeitern Zeit und zusätzliche Arbeit zu ersparen.
            <br />
            <br />
            Sie können neben einfachem Text auch intelligente Textbausteine verwenden z.B.:
            <br />
            <code>
                Sie befinden sich in/auf/bei {"{entity:name}"} und Ihr Ansprechpartner heißt{" "}
                {"{customer:name}"}
            </code>
            <br />
            <br />
            <br />
            Folgende Bausteine werden unterstützt:
            <br />
            <br />
            <ul>
                <li>
                    <strong>entity:name</strong> — Name des zugehörigen Objektes
                </li>
                <li>
                    <strong>entity:address</strong> — Addresse des zugehörigen Objektes (mehrzeilige
                    Ausgabe)
                </li>
                <li>
                    <strong>entity:address:compact_name</strong> — Addresse des zugehörigen Objektes
                    (einzeilige Ausgabe)
                </li>
                <li>
                    <strong>entity:entity_nr</strong> — Nummer des zugehörigen Objektes
                </li>
                <li>
                    <strong>entity:contact_name</strong> — Ansprechpartner
                </li>
                <li>
                    <strong>entity:contact_phone</strong> — Telefonnummer
                </li>
                <li>
                    <strong>date:current</strong> — Aktuelles Datum
                </li>
                <li>
                    <strong>date:current:DD.MM.YYYY</strong> — Aktuelles Datum im Format
                </li>
                <li>
                    <strong>customer:name</strong> — Name des zugehörigen Kunden
                </li>
                <li>
                    <strong>customer:customer_nr</strong> — Nummer des zugehörigen Kunden
                </li>
                <li>
                    <strong>customer:addresses:billing</strong> — Rechnungsadresse des Kunden
                </li>
                <li>
                    <strong>customer:addresses:work</strong> — Sitz des Kunden
                </li>
                <li>
                    <strong>form:form_group:total_form_entries</strong> — Anzahl der Protokolle der
                    Formular-Gruppe
                </li>
                <li>
                    <strong>form:form_group:total_form_entries:next</strong> — Anzahl+1 der
                    Protokolle der Formular-Gruppe
                </li>
                <li>
                    <strong>form:form_group:total_form_entries:next:rjust3</strong> — Anzahl+1 der
                    Protokolle der Formular-Gruppe (mit 3 führenden Nullen)
                </li>
                <li>
                    <strong>form:total_form_entries</strong> — Anzahl der Protokolle des
                    Formular-Typs
                </li>
                <li>
                    <strong>form:total_form_entries:next</strong> — Anzahl+1 der Protokolle des
                    Formular-Typs
                </li>
                <li>
                    <strong>form:name</strong> — Name des Formulars
                </li>
                <li>
                    <strong>user:firstname</strong> — Vorname des aktuellen Benutzers
                </li>
                <li>
                    <strong>user:lastname</strong> — Nachname des aktuellen Benutzers
                </li>
                <li>
                    <strong>field:XXXXXXXX</strong> — Der Wert eines bestehenden Felds im Formular
                    (XXXXXXXX = Name Identifier)
                </li>
                <li>
                    <strong>field:XXXXXXXX_YYYYYYYY_123</strong> — Der Wert eines bestehenden Felds
                    im Repeater (XXXXXXXX = Name Identifier des Repeaters) (YYYYYYYY = Name
                    Identifier des Feldes im Repeater) (123 = Der Index des Repeaters (beginnt mit
                    0)). Sie können anstelle von Index auch {"{self:index}"} verwenden.
                </li>
            </ul>
        </div>
    );
};
