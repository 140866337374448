import { SchemaBuilder } from "./SchemaBuilder";

/**
 * Prepares an entity that is submitted to the endpoint using AJAX
 * This class handles serialization of form inputs.
 */
export class SchemaEntry {
    constructor(private schema: SchemaBuilder, public originalValues) {}

    /**
     * Serialize the provided schemaBuilder and the originalValues provided by react-hooks-form
     */
    public serialize() {
        const entry = {
            createdAt: +new Date(),
        };

        this.schema.flatFields().forEach((field) => {
            entry[field.name] = {
                value: field.sleepValue(this.originalValues[field.name]),
                field,
            };
        });

        return entry;
    }
}
