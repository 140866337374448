import React, { useEffect, useState, useMemo } from "react";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { useParams, withRouter } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { RootState } from "../../reducers";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import {
    deleteCustomField,
    updateCustomField,
    getCustomFieldByID,
} from "@rpforms/shared/build/actions";
import FieldDataSetter from "../../components/custom_fields/FieldDataSetter";
import { push } from "connected-react-router";
import { SchemaBuilder } from "@rpforms/shared/build/spec/SchemaBuilder";

export const EditFieldPage = () => {
    const { id } = useParams();
    const [icon, setIcon] = useState("");
    const [name, setName] = useState("");
    const [fieldData, setFieldData] = useState("");
    const field = useSelector<RootState, any>((state) =>
        state.customFields.customFields.find((field) => field.id === parseInt(id, 10))
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!field) {
            dispatch(getCustomFieldByID(id));
        }
    }, []);

    useEffect(() => {
        if (field) {
            setName(field.name);
            setIcon(field.icon);
            setFieldData(JSON.parse("[" + field.field_data + "]"));
        }
    }, [field]);

    const handleDeleteButtonClick = () => {
        dispatch(deleteCustomField(id));
    };

    const handleSaveButtonClick = async () => {
        if (isValidInput()) {
            await dispatch(
                updateCustomField(field, {
                    name: name,
                    icon: icon,
                    field_data: JSON.stringify(fieldData[0]),
                })
            );
            dispatch(push("/settings/protocols/custom_fields"));
        }
    };

    const isValidInput = () => {
        return name.length > 0 && icon.length > 0 && fieldData.length === 1;
    };

    const schemaBuilder: any = useMemo(() => {
        if (!field) return null;
        const builder = new SchemaBuilder();
        const parsedField = { fields: [JSON.parse(field.field_data)] };
        builder.use(parsedField);
        return builder;
    }, [field?.field_data]);

    if (!field || !schemaBuilder) return <Loader />;

    return (
        <>
            <PageNavbar
                title="Feld bearbeiten"
                description="Hier können sie ein bestehendes Feld bearbeiten."
            >
                <PageNavAction
                    success={true}
                    onClick={() => handleSaveButtonClick()}
                    icon="save"
                    title="Speichern & Abschicken"
                />

                <PageNavAction
                    link="/settings/protocols/custom_fields"
                    onClick={() => handleDeleteButtonClick()}
                    danger={true}
                    icon="trash"
                    title="Feld Löschen"
                />

                <PageNavAction
                    link="/settings/protocols/custom_fields"
                    icon="list"
                    title="Alle Felder"
                />
            </PageNavbar>

            <FieldDataSetter
                name={name}
                setName={setName}
                icon={icon}
                setIcon={setIcon}
                fieldData={fieldData}
                setFieldData={setFieldData}
                schemaBuilder={schemaBuilder}
            />
        </>
    );
};

export default withRouter(EditFieldPage);
