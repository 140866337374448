import { getCalendarEvents } from "@rpforms/shared/build/actions";
import { getDevices } from "@rpforms/shared/build/actions/devices";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CalendarEventListRow from "../../components/devices/calendar_events/CalendarEventListRow";
import { RootState } from "../../reducers";

const filterAndSortUnterminatedEvents = (events) => {
    return events
        .filter((event) => event.scheduled_from === null)
        .sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
};

export const PlanningPage = () => {
    const calendarEventState = useSelector<any, any>((state) => state.calendarEvents);
    const deviceState = useSelector<any, any>((state) => state.device);
    const types = useSelector<RootState, any>((store) => store.calendarEventTypes.types);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCalendarEvents());
        dispatch(getDevices());
    }, []);
    if (calendarEventState.isLoading || deviceState.isLoading) {
        return <Loader />;
    }

    const calendarEvents = calendarEventState.calendarEvents.filter(
        (event) => !event.was_submitted
    );
    const devices = deviceState.devices;

    return (
        <>
            <Content>
                <br />
                <h3>Events ohne Zeitraum</h3>
                {filterAndSortUnterminatedEvents(calendarEvents).map((event) => {
                    return (
                        <CalendarEventListRow
                            type={types.find((t) => t.id === event.calendar_event_type_id)}
                            key={event.id}
                            event={event}
                            entity={event.entity}
                            customer={event.customer}
                            device={devices.find((d) => d.id === event.device_id)}
                        />
                    );
                })}
                <br />
                <h3>Events mit Zeitraum</h3>
                {calendarEvents
                    .filter((event) => event.scheduled_from !== null)
                    .map((event) => {
                        return (
                            <CalendarEventListRow
                                type={types.find((t) => t.id === event.calendar_event_type_id)}
                                key={event.id}
                                event={event}
                                entity={event.entity}
                                customer={event.customer}
                                device={devices.find((d) => d.id === event.device_id)}
                            />
                        );
                    })}
            </Content>
            ´{" "}
        </>
    );
};

export default withRouter(PlanningPage);
