import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CustomerSelect } from "../customers/select";
import { EntitySelect } from "../entities/select";
import { FormSelector } from "../forms/FormSelector";
import { FormEntrySelector } from "./FormEntrySelector";

export default ({
    onUpdateCustomer = (option) => null,
    onUpdateEntity = (option) => null,
    onUpdateForm = (option) => null,
    onUpdateFormEntry = (option) => null,
    onUpdateFormEntryFilter = (checkbox) => null,
    onUpdateEntityFilter = (checkbox) => null,
    onUpdateCustomerAndEntityLock = (enabled) => null,
    formIncludeAllOption = true,
    hideCustomerSelector = false,
    hideEntitySelector = false,
    hideFormSelector = false,
    hideFormEntrySelector = true,
    hideTermsCheckbox = true,
    hideEntityFilterCheckbox = true,
    customerAndEntity = "editable",
    formFilter = (form) => true,
    formEntry,
}) => {
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedEntityId, setSelectedEntityId] = useState(null);
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [selectedFormEntryId, setSelectedFormEntryId] = useState(null);
    const [formEntryHasTermId, setFormEntryHasTermId] = useState(false);
    const [allEntitiesChecked, setAllEntitiesChecked] = useState(false);
    const [metaSelectionEnabled, setMetaSelectionEnabled] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (formEntry) {
            if (formEntry.customer_id) {
                setSelectedCustomerId(formEntry.customer_id);
            }
            if (formEntry.form_id) {
                setSelectedFormId(formEntry.form_id);
            }
            if (formEntry.entity_id) {
                setSelectedEntityId(formEntry.entity_id);
            }
        }
    }, [formEntry]);

    const onCustomerSelect = (option) => {
        setSelectedCustomerId(option.value);
        onUpdateCustomer(option);
    };

    const onEntitySelect = (option) => {
        setSelectedEntityId(option.value);
        onUpdateEntity(option);
    };

    const onFormSelect = (option) => {
        setSelectedFormId(option.value);
        onUpdateForm(option);
    };

    const onFormEntrySelect = (option) => {
        setSelectedFormEntryId(option.value);
        onUpdateFormEntry(option);
    };

    const onFormEntryFilterSelect = (checkboxEvent) => {
        onUpdateFormEntryFilter(!formEntryHasTermId);
        setFormEntryHasTermId(!formEntryHasTermId);
    };

    const onEntityFilterSelect = (checkboxEvent) => {
        onUpdateEntityFilter(!allEntitiesChecked);
        setAllEntitiesChecked(!allEntitiesChecked);
    };

    const onMetaLockChanged = () => {
        const newVal = !metaSelectionEnabled;
        setMetaSelectionEnabled(newVal);
        onUpdateCustomerAndEntityLock(newVal);
    };

    const formEntryFilter = (formEntry2) => {
        return (
            formEntry2.customer_id === selectedCustomerId &&
            formEntry2.entity_id === selectedEntityId &&
            (selectedFormId === 0 || formEntry2.form_id === selectedFormId) &&
            (!formEntryHasTermId || formEntry2.terms.length > 0)
        );
    };

    const isNew = () => {
        if (formEntry.created_at) {
            return false;
        }
        return formEntry.id === undefined || formEntry.id === null;
    };

    const selectedEntity = selectedEntityId ? formEntry.entity : null;
    const selectedForm = selectedFormId ? formEntry.form : null;

    const formTypeSelector = selectedFormId ? (
        <FormSelector
            formValue={selectedForm}
            onSelect={onFormSelect}
            defaultValue={selectedFormId}
            includeAllOption={formIncludeAllOption}
            filterBy={formFilter}
            isDisabled={hideFormSelector}
        />
    ) : (
        <div>
            <FormSelector
                onSelect={onFormSelect}
                includeAllOption={formIncludeAllOption}
                disabled={hideFormSelector}
            />
        </div>
    );

    const formEntrySelector = formEntry.id ? (
        <FormEntrySelector
            filterBy={formEntryFilter}
            onSelect={onFormEntrySelect}
            defaultValue={formEntry.id}
        />
    ) : (
        <div>
            <FormEntrySelector filterBy={formEntryFilter} onSelect={onFormEntrySelect} />
        </div>
    );

    return (
        <Container>
            <Row style={{ alignItems: "flex-end" }}>
                {customerAndEntity !== "hidden" && (
                    <Col>
                        <Button
                            onClick={onMetaLockChanged}
                            disabled={customerAndEntity === "readable"}
                            variant="outline-secondary"
                        >
                            <i className={metaSelectionEnabled ? "fa fa-unlock" : "fa fa-lock"}></i>
                        </Button>
                    </Col>
                )}
                <Col style={{ flexGrow: 90 }}>
                    <Row>
                        {!hideCustomerSelector && (
                            <Col>
                                Kunde:{" "}
                                <CustomerSelect
                                    customerValue={formEntry.customer}
                                    onSelect={onCustomerSelect}
                                    defaultValue={formEntry.customer_id}
                                    isDisabled={isNew() ? false : !metaSelectionEnabled}
                                />{" "}
                            </Col>
                        )}
                        {!hideEntitySelector && (
                            <Col>
                                Objekt:{" "}
                                <EntitySelect
                                    entityValue={selectedEntity}
                                    filterBy={(entity) =>
                                        allEntitiesChecked ||
                                        entity.customer_id === selectedCustomerId
                                    }
                                    onSelect={onEntitySelect}
                                    defaultValue={selectedEntity}
                                    isDisabled={isNew() ? false : !metaSelectionEnabled}
                                />{" "}
                                {!hideEntityFilterCheckbox && (
                                    <Form.Check
                                        className={"text-left"}
                                        type={"checkbox"}
                                        id={"entity-filter"}
                                        label={"alle Objekte"}
                                        checked={allEntitiesChecked}
                                        onChange={onEntityFilterSelect}
                                    />
                                )}
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>Formular-Typ: {formTypeSelector}</Col>
                {!hideFormEntrySelector && (
                    <Col>
                        Eintrag: {formEntrySelector}
                        {!hideTermsCheckbox && (
                            <Form.Check
                                className={"text-left"}
                                type={"checkbox"}
                                id={"taggedFormEntries"}
                                label={"nur getaggte Einträge"}
                                checked={formEntryHasTermId}
                                onChange={onFormEntryFilterSelect}
                            />
                        )}
                    </Col>
                )}
            </Row>
        </Container>
    );
};
