import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { flatDeep } from "../utils";
import { user } from "./actions_helper";
import {
    CREATE_INVENTORY_ITEM,
    DELETE_INVENTORY_ITEM,
    GET_INVENTORY_ITEMS,
    INVENTORY_ITEM_LOADING_START,
    INVENTORY_ITEM_LOADING_STOP,
} from "./types";

interface IFetchType {
    page?: number;
    page_size?: number;
    order_by?: string;
    order_dir?: string;
    hidden_fields?: any;
}

export function getInventoryItems(opts: IFetchType = {}, type = "repair") {
    const qsOpts = {
        page: opts.page || 1,
        page_size: opts.page_size || null,
        order_by: opts.order_by || "name",
        order_dir: opts.order_dir || "asc",
    };
    const qs = Object.keys(qsOpts)
        .map((k) => {
            return qsOpts[k] != null ? `${k}=${qsOpts[k]}` : null;
        })
        .filter(String)
        .join("&");

    // const types = [type].flatMap((t) => `type[]=${t}`).join("&"); # "target": "es6", "lib": [ "es2019"
    const types = flatDeep([type])
        .map((t) => `type[]=${t}`)
        .join("&");
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        const response = await axios.get(
            `${API_URL}/companies/${COMPANY_ID}/inventory_items.json?${types}&${qs}`,
            {
                headers: { authorization: user().token },
            }
        );
        dispatch({
            type: GET_INVENTORY_ITEMS,
            payload: response.data,
        });
        return response.data;
    };
}

export const getInventoryItemsAJAX = (opts: IFetchType = {}, type: any = "repair") => {
    const page = opts.page || 1;
    const pageSize = opts.page_size || -1;
    const orderBy = opts.order_by || "name";
    const orderDir = opts.order_dir || "asc";
    const hiddenFields =
        opts.hidden_fields ||
        "total_buying_price,total_sales_price,limit_value,total_pages,current_page,next_page,prev_page,first_page?,last_page?,out_of_range?,total_quantity,count";

    const deviceToken = localStorage.getItem("deviceToken");
    // const types = [type].flatMap((t) => `type[]=${t}`).join("&"); # "target": "es6", "lib": [ "es2019"
    const types = flatDeep([type])
        .map((t) => `type[]=${t}`)
        .join("&");
    return axios.get(
        `${API_URL}/companies/${COMPANY_ID}/inventory_items.json?${types}&page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&hidden_fields=${hiddenFields}`,
        {
            headers: { authorization: deviceToken ?? user().token },
        }
    );
};

export function createInventoryItemAJAX(inventoryItem: any, type = "repair") {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        dispatch({ type: INVENTORY_ITEM_LOADING_START });
        axios
            .post(`${API_URL}/inventory_items.json`, {
                inventory_item: {
                    ...inventoryItem,
                    company_id: COMPANY_ID,
                    type,
                },
            })
            .then((res) => {
                dispatch({
                    type: CREATE_INVENTORY_ITEM,
                    payload: res.data,
                });
                getInventoryItems()(dispatch);
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                dispatch({ type: INVENTORY_ITEM_LOADING_STOP });
            });
    };
}

export function deleteInventoryItem(inventoryItem: any) {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({ type: INVENTORY_ITEM_LOADING_START });
        axios
            .delete(`${API_URL}/inventory_items/${inventoryItem.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_INVENTORY_ITEM,
                    payload: {},
                });
                getInventoryItems({}, inventoryItem.inventory_type)(dispatch);
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                dispatch({ type: INVENTORY_ITEM_LOADING_STOP });
            });
    };
}
