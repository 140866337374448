import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class NumericTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Numerisch";
    public icon = "sort-numeric-asc";
    public fieldType = "Numerisch";

    public getConfigurables() {
        return [Width];
    }

    public toHotColumn(): {} {
        return {
            type: "numeric",
            numericFormat: {
                pattern: "0.0,00€",
            },
        };
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label}
                    ref={register}
                />
            </FieldWrapper>
        );
    }
}
