import { openEditor } from "@rpforms/shared/build/actions/editor";
import { createPdfForAll } from "@rpforms/shared/build/actions/employees";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-use";
import LegacyEntityFilter from "../../components/entities/LegacyEntityFilter";
import ListCustomerPage from "./customers/ListCustomerPage";
import ListEmployeePage from "./employees/ListEmployeePage";
import ListEntityPage from "./entities/ListEntityPage";

import {
    ADD_DEVICE,
    CREATE_CUSTOMER,
    CREATE_EMPLOYEE,
    CUSTOMER_NEW_ENTITY,
} from "@rpforms/shared/build/actions";
import { push } from "connected-react-router";
import { RootState } from "../../reducers";
import ListDevicePage from "./devices/ListDevicePage";
import { API_URL } from "../../config";

export const ListMasterDataPage = () => {
    const dispatch = useDispatch();

    const navigate = (url) => dispatch(push(url));

    const location = useLocation();
    const pathEnding = location.pathname.split("/").pop();
    const [numberOfEntries, setNumberOfEntries] = useState(0);
    const [entityFilter, setEntityFilter] = useState("alle");
    const getTypeOnPathEndingNew = () => {
        const path = location.pathname.split("/");
        path.pop();
        return path.pop();
    };

    const createOnPathEndingNew = () => {
        if (pathEnding === "new") {
            createNewMasterData(getTypeOnPathEndingNew());
        }
    };

    useEffect(() => {
        createOnPathEndingNew();
    }, []);

    const setType = () => {
        if (pathEnding === "new") {
            return getTypeOnPathEndingNew();
        }
        if (pathEnding === "master_data") {
            return "customers";
        }
        return pathEnding;
    };

    const type = setType();

    const createCustomer = () => {
        dispatch(
            openEditor({
                endpoint: `customers`,
                endpointCompany: true,
                new: true,
                redirect: true,
                preload: {},
                type: CREATE_CUSTOMER,
            })
        );
    };

    const createEntity = () => {
        dispatch(
            openEditor({
                endpoint: `entities`,
                endpointCompany: true,
                new: true,
                preload: { address: {} },
                type: CUSTOMER_NEW_ENTITY,
            })
        );
    };

    const createEmployee = () => {
        dispatch(
            openEditor({
                endpoint: `employees`,
                endpointCompany: false,
                new: true,
                preload: {},
                type: CREATE_EMPLOYEE,
            })
        );
    };

    const createDevice = () => {
        dispatch(
            openEditor({
                endpoint: `devices`,
                endpointCompany: false,
                new: true,
                preload: {},
                type: ADD_DEVICE,
            })
        );
    };

    const createNewMasterData = (type: string) =>
        type === "objects"
            ? createEntity()
            : type === "customers"
            ? createCustomer()
            : type === "devices"
            ? createDevice()
            : createEmployee();

    const renderListCustomerPage = () => {
        return (props: any) => (
            <ListCustomerPage {...props} setNumberOfEntries={setNumberOfEntries} />
        );
    };
    const renderListEntityPage = () => {
        return (props: any) => (
            <ListEntityPage
                {...props}
                entityFilter={entityFilter}
                setNumberOfEntries={setNumberOfEntries}
            />
        );
    };

    const renderListEmployeePage = () => {
        return (props: any) => (
            <ListEmployeePage {...props} setNumberOfEntries={setNumberOfEntries} />
        );
    };

    const renderListDevicePage = () => {
        return (props: any) => (
            <ListDevicePage {...props} setNumberOfEntries={setNumberOfEntries} />
        );
    };

    return (
        <>
            <PageNavbar
                title="Stammdaten"
                description="Hier können Sie Ihre Stammdaten einsehen und verwalten."
                subtitle={
                    (type === "objects"
                        ? "Objekte"
                        : type === "customers"
                        ? "Kunden"
                        : type === "employees"
                        ? "Personal"
                        : "Geräte") +
                    ": " +
                    numberOfEntries
                }
            >
                {type === "employees" && (
                    <>
                        <PageNavAction
                            link="/calendar/vacations"
                            icon="calendar"
                            title="Zum Urlaubskalender"
                        />
                        <PageNavAction
                            icon="file"
                            title="Alle Mitarbeiter als PDF exportieren"
                            onClick={() => dispatch(createPdfForAll())}
                        />
                        <PageNavAction
                            icon="file"
                            title="Arbeitszeiten exportieren"
                            onClick={() => window.open(`${API_URL}/employees/timetracking.pdf`)}
                        />
                    </>
                )}

                {type === "objects" && (
                    <div className="d-flex flex-column mr-3" style={{ width: "300px" }}>
                        <span>Filter:</span>
                        <LegacyEntityFilter
                            entityFilter={entityFilter}
                            setEntityFilter={setEntityFilter}
                        />
                    </div>
                )}
                <Tab.Container
                    defaultActiveKey={type}
                    onSelect={(tab) => {
                        navigate(`/master_data/${tab}`);
                    }}
                >
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="customers">Kunden</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="objects">Objekte</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="employees">Personal</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="devices">Geräte</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>

                <PageNavAction
                    onClick={() => createNewMasterData(type)}
                    primary={true}
                    icon="plus"
                    title="Hinzufügen"
                />
            </PageNavbar>
            <Switch>
                <Route path="/master_data" exact render={renderListCustomerPage()} />
                <Route path="/master_data/customers" exact render={renderListCustomerPage()} />
                <Route path="/master_data/customers/new" render={renderListCustomerPage()} />
                <Route path="/master_data/objects" exact render={renderListEntityPage()} />
                <Route path="/master_data/objects/new" render={renderListEntityPage()} />
                <Route path="/master_data/employees" exact render={renderListEmployeePage()} />
                <Route path="/master_data/employees/new" render={renderListEmployeePage()} />
                <Route path="/master_data/devices" exact render={renderListDevicePage()} />
                <Route path="/master_data/devices/new" render={renderListDevicePage()} />
            </Switch>
        </>
    );
};

export default ListMasterDataPage;
