import { updateForm } from "@rpforms/shared";
import { SmallButton } from "@rpforms/shared/build/components/Button";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const StructureForm = ({ form, close }) => {
    const [structure, setStructure] = useState("");
    const dispatch = useDispatch();

    const onStructureSave = async (e) => {
        const parsed = JSON.parse(structure);
        await dispatch(
            updateForm(form, {
                form_data: parsed,
            })
        );
        toast.success("Struktur gespeichert");
        close();
    };
    return (
        <div>
            <div className="form-group">
                <textarea
                    className="form-control rounded-0"
                    id="json-structure"
                    rows={10}
                    placeholder="JSON Struktur"
                    value={structure}
                    onChange={(e) => setStructure(e.target.value)}
                ></textarea>
            </div>
            <Button variant="success" type="button" onClick={onStructureSave} block>
                Speichern
            </Button>
        </div>
    );
};

export default function FormStructure({ form }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <SmallButton variant="warning" onClick={handleShow}>
                    Struktur bearbeiten
                </SmallButton>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Formular Struktur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StructureForm close={handleClose} form={form} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
