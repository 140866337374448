import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

import { fetchTemplates } from "@rpforms/shared/build/actions";

export const TemplateSelect = ({ setTemplate }) => {
    const dispatch = useDispatch();
    const templates = useSelector<RootState, any>((store) => store.directory.templates);

    useEffect(() => {
        dispatch(fetchTemplates());
    }, []);

    const options = templates.map((template) => {
        return {
            label: template.name,
            value: template.id,
        };
    });

    return <Select onChange={(option) => setTemplate(option)} options={options} />;
};
