import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { Switch } from "../components/layout/Switch";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";
import { IsChecked } from "./configurables/IsChecked";
import { Label, LabelInput } from "./inputFields/label";

export class CheckboxField extends BaseField {
    public label = "Checkbox";
    public icon = "check-square-o";
    public fieldType = "Ja/Nein Auswahl";

    public attributeNames = ["offLabel", "onLabel", "simpleDisplay"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Required, Description, Width, Hint, IsChecked];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <LabelInput register={register} field={field} />

                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("simpleDisplay")}
                                    className="form-control"
                                    defaultChecked={field.simpleDisplay}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Als einfache Checkbox anzeigen</label>
                                <div className="text-muted">
                                    Möchten Sie anstelle der Ja/Nein Auswahl eine einfache Checkbox
                                    anzeigen?
                                </div>
                            </div>
                        </div>

                        <label className="mt-3">Texte der Schaltflächen</label>
                        <div className="row">
                            <div className="col-6">
                                <label>
                                    Text bei <strong>aktiver</strong> Schaltfläche
                                </label>
                                <input
                                    type={"text"}
                                    name={field.inputName("onLabel")}
                                    className="form-control"
                                    defaultValue={field.onLabel}
                                    ref={register}
                                />
                            </div>
                            <div className="col-6">
                                <label>
                                    Text bei <strong>nicht aktiver</strong> Schaltfläche
                                </label>
                                <input
                                    type={"text"}
                                    name={field.inputName("offLabel")}
                                    className="form-control"
                                    defaultValue={field.offLabel}
                                    ref={register}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.name,
        rawValue,
        readonly = false,
    }) {
        if (!rawValue) {
            rawValue = field.rawValue;
        }
        return layout.wrapComponent(
            <div
                className={layout.wrapComponentCSS(field.configureCSS())}
                style={{ position: "relative", margin: "20px 0" }}
            >
                {layout.wrapFieldset(
                    <>
                        {layout.wrapDescription(
                            field.rawOptions.description ? (
                                <p>{field.rawOptions.description}</p>
                            ) : (
                                <></>
                            )
                        )}
                        <div
                            className="d-flex"
                            style={{ width: "100%", justifyContent: "space-between" }}
                        >
                            <Label layout={layout} field={field} />
                            {field.simpleDisplay ? (
                                field.configureInput(
                                    <input
                                        style={{
                                            position: "relative",
                                            top: "5px",
                                            left: "10px",
                                            width: "32px",
                                            height: "32px",
                                        }}
                                        type={"checkbox"}
                                        defaultChecked={rawValue === "on"}
                                        ref={register}
                                        name={field.hashCode}
                                    />
                                )
                            ) : (
                                <Switch
                                    onLabel={field.onLabel || "JA"}
                                    offLabel={field.offLabel || "NEIN"}
                                    label={field.hashCode}
                                    hashCode={field.hashCode}
                                    wrapInput={field.configureInput.bind(field)}
                                    wrapInputArgs={[register, field]}
                                    checked={rawValue === "on"}
                                    theme="success"
                                    readOnly={readonly}
                                    idPrefix={`${field.formName}-`}
                                />
                            )}
                        </div>
                        {layout.wrapHint(
                            field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>
                        )}
                    </>
                )}
            </div>
        );
    }
}
