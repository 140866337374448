import * as actions from "@rpforms/shared/build/actions/forms";
import { SmallButton } from "@rpforms/shared/build/components/Button";
import { SmallDangerButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import { translucentColor } from "@rpforms/shared/build/utils";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import FormStructure from "./FormStructure";

export const ListRow = styled.div`
    flex-grow: 1;
    display: flex;
    background: #fff;
    position: relative;
    padding: 20px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #f1f1f1;
    &:hover {
        background-color: ${(props) =>
            translucentColor(props.theme.primary_color, 0.05)} !important;
    }
`;

export const DeviceName = styled.div`
    font-weight: bold;
`;

const FormListRow = ({ form, deleteForm }: any) => {
    const confirmDelete = (cb) => {
        if (confirm("Sind Sie sicher dieses Formular zu löschen?")) {
            cb();
        }
    };

    return (
        <ListRow>
            <DeviceName>{form.name}</DeviceName>
            <RightAligned>
                <FormStructure form={form} />

                <Link to={`/settings/protocols/forms/preview-${form.id}`}>
                    <SmallButton success={true}>Vorschau</SmallButton>
                </Link>
                <Link to={`/settings/protocols/forms/edit-${form.id}`}>
                    <SmallButton>Bearbeiten</SmallButton>
                </Link>

                <SmallDangerButton onClick={() => confirmDelete(() => deleteForm(form))}>
                    Löschen
                </SmallDangerButton>
            </RightAligned>
        </ListRow>
    );
};

function mapStateToProps(state) {
    return { state: state.device };
}

export default withRouter(connect(mapStateToProps, actions)(FormListRow));
