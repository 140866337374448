import {
    ADD_PERMISSION,
    PERMISSIONS_LOADING_START,
    PERMISSIONS_LOADING_STOP,
    REMOVE_PERMISSION,
    SET_PERMISSIONS,
    SET_PERMISSIONS_RESOURCES,
    SET_PERMISSIONS_ROLES,
    SET_PERMISSIONS_USERS,
    SET_ROLES,
} from "../actions/types";

const initialState = {
    isLoading: false,
    permissions: [],
    resources: [],
    roles: [],
    users: [],
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case SET_PERMISSIONS_RESOURCES:
            return {
                ...state,
                resources: action.payload,
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload.permissions,
            };
        case SET_ROLES:
            return {
                ...state,
                roles: action.payload,
            };
        case ADD_PERMISSION:
            return {
                ...state,
                permissions: [...state.permissions, action.payload],
            };
        case REMOVE_PERMISSION:
            return {
                ...state,
                permissions: [...state.permissions].filter(
                    (permission: any) => permission.id !== action.payload.id
                ),
            };
        case SET_PERMISSIONS_ROLES:
            return {
                ...state,
                roles: action.payload,
            };

        case SET_PERMISSIONS_USERS:
            return { ...state, users: action.payload };

        case PERMISSIONS_LOADING_START:
            return {
                ...state,
                isLoading: true,
            };
        case PERMISSIONS_LOADING_STOP:
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
}
