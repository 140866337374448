import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import { fetchNotificationGroups } from "./notification_groups";

export function markNotificationAsRead(id: string) {
    return (dispatch: IDispatch<any>) => {
        axios
            .put(
                `${API_URL}/notifications/${id}/read.json`,
                {},
                {
                    headers: {
                        authorization: user().token,
                    },
                }
            )
            .then(() => {
                dispatch(fetchNotificationGroups(1, () => {}));
            })
            .catch((error) => {
                throw error;
            });
    };
}
