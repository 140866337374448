import React from "react";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";
import { AppearanceSlider } from "./fields/AppearanceSlider";
import { AppearanceText } from "./fields/AppearanceText";

/**
 * Applies maximum length to input field
 */
export class Width extends Configurable {
    public schema;
    public designer;
    public field;

    public keyName = "width";
    public title = "Breite des Feldes";
    public description = "Hier können Sie die maximale Breite des Feldes festlegen";
    public isTable = false;

    /**
     * Uses a slider widget to modify the width
     * @param register
     * @param schema
     * @param designer
     * @param field
     */
    constructor({ register, schema, designer, field }) {
        super({ register, schema, designer, field });

        this.widget = (
            <AppearanceSlider
                register={register}
                placeholder={this.title}
                value={this.fieldOptions[this.keyName]}
                field={field}
                min={0}
                max={100}
                step={1}
                label={""}
                keyName={this.keyName}
            />
        );

        if (field.icon === "table") {
            this.isTable = true;
        }

        this.field = field;
    }

    /**
     * TODO: this component should not have to deal with margins...
     * @param el
     */
    public configureComponent(el) {
        let display = "inline-block";
        if (this.isTable) {
            display = "block";
        }
        if (this.value) {
            return (
                <div
                    data-field-box
                    data-field={this.field ? this.field.fieldType : "unspecified"}
                    style={{
                        position: "relative",
                        display,
                        verticalAlign: "top",
                        width: `calc(${this.value}% - 40px)`,
                        margin: "0 20px",
                    }}
                >
                    {el}
                </div>
            );
        } else {
            return (
                <div
                    data-field-box
                    data-field={this.field ? this.field.fieldType : "unspecified"}
                    style={{
                        position: "relative",
                        display,
                        width: `calc(100% - 40px)`,
                        maxWidth: "1200px",
                        margin: "0 20px",
                    }}
                >
                    {el}
                </div>
            );
        }
    }
}
