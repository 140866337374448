import React from "react";
import { BaseField } from "../BaseField";
import { Configurable } from "./Configurable";
import { AppearanceSlider } from "./fields/AppearanceSlider";
import { AppearanceText } from "./fields/AppearanceText";

/**
 * Applies maximum length to input field
 */
export class AutoFill extends Configurable {
    public schema;
    public designer;
    public field;

    public keyName = "autofill";
    public title = "Automatisch ausfüllen";
    public description = "";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: BaseField;
    }): any {
        return (
            <>
                <AppearanceText
                    register={register}
                    value={field.rawOptions[this.keyName]}
                    placeholder={this.title}
                    field={field}
                    keyName={this.keyName}
                />
                <p>
                    Hier können Sie festlegen mit welchen Daten dieses Feld vorausgefüllt werden
                    soll. Eine ausführliche Dokumentation finden Sie{" "}
                    <u>
                        <a href={"/settings/protocols/variables"} target={"_blank"}>
                            hier
                        </a>
                    </u>
                </p>
            </>
        );
    }

    public sleepValue(value): any {
        return value;
    }

    public wakeValue(value) {
        return value;
    }
}
