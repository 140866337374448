import {
    CREATE_EMPLOYEE,
    CREATE_FOLDER_NOTE,
    NEW_MIGRATION,
    NEW_TEMPLATE,
    NEW_UPLOAD,
    NEW_UPLOAD_INFO,
    NEW_UPLOADS,
    UPDATE_FOLDER_NOTE,
} from "@rpforms/shared";
import {
    ADD_DEVICE,
    CREATE_CUSTOMER,
    CUSTOMER_NEW_ADDRESS,
    CUSTOMER_NEW_ENTITY,
    CUSTOMER_UPDATE_ADDRESS,
    CUSTOMER_UPDATE_ENTITY,
    EDIT_INVENTORY_ITEM,
    FORM_ENTRIES_CREATE,
    NEW_INVENTORY_ITEM,
    UPDATE_CUSTOMER,
    ADD_FOLDERS,
} from "@rpforms/shared/build/actions";
import { closeEditor, saveEditor } from "@rpforms/shared/build/actions/editor";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { ModalAddress } from "./ModalAddress";
import { ModalCustomer } from "./ModalCustomer";
import { ModalDevice } from "./ModalDevice";
import { ModalEmployee } from "./ModalEmployee";
import { ModalEntity } from "./ModalEntity";
import { ModalFormEntry } from "./ModalFormEntry";
import { ModalInventoryItem } from "./ModalInventoryItem";
import { ModalMigration } from "./ModalMigration";
import { ModalMultipleUpload } from "./ModalMultipleUpload";
import { ModalTemplate } from "./ModalTemplate";
import { ModalUpload } from "./ModalUpload";
import { ModalUploadInfo } from "./ModalUploadInfo";
import { ModalFolderNoteEditor } from "./ModalFolderNoteEditor";

export const ModalEditor = () => {
    const modalEditor = useSelector<RootState, IEditor>((state) => state.editor);
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeEditor());
    };

    const next = async (data, transform = (x) => x) => {
        if ([NEW_UPLOAD_INFO, NEW_MIGRATION, NEW_TEMPLATE].includes(config.type)) {
            return close();
        }

        try {
            const response = await dispatch(saveEditor(modalEditor.config, transform(data)));
            await dispatch({
                payload: response,
                type: modalEditor.config.type,
            });
            close();
            return response;
        } catch (e) {
            console.log(e);
        }
    };

    const { config } = modalEditor;
    const isAddress =
        config && (config.type === CUSTOMER_UPDATE_ADDRESS || config.type === CUSTOMER_NEW_ADDRESS);
    const isEntity =
        config && (config.type === CUSTOMER_UPDATE_ENTITY || config.type === CUSTOMER_NEW_ENTITY);
    const isCustomer =
        config && (config.type === UPDATE_CUSTOMER || config.type === CREATE_CUSTOMER);
    const isInventoryItem =
        config && (config.type === EDIT_INVENTORY_ITEM || config.type === NEW_INVENTORY_ITEM);
    const isFolderNote =
        config && (config.type === UPDATE_FOLDER_NOTE || config.type === CREATE_FOLDER_NOTE);

    const isFormEntry = config && config.type === FORM_ENTRIES_CREATE;
    const isUpload = config && config.type === NEW_UPLOAD;
    const isUploads = config && config.type === NEW_UPLOADS;
    const isUploadInfo = config && config.type === NEW_UPLOAD_INFO;
    const isNewMigration = config && config.type === NEW_MIGRATION;
    const isNewTemplate = config && config.type === NEW_TEMPLATE;
    const isEmployee = config && config.type === CREATE_EMPLOYEE;
    const isDevice = config && config.type === ADD_DEVICE;
    const componentProps = { next, editor: modalEditor };

    return (
        <>
            <Modal
                show={modalEditor.open}
                onHide={close}
                size={"xl"}
                backdrop="static"
                keyboard={false}
            >
                {isAddress && <ModalAddress {...componentProps} />}
                {isEntity && <ModalEntity {...componentProps} />}
                {isCustomer && <ModalCustomer {...componentProps} />}
                {isFormEntry && <ModalFormEntry {...componentProps} />}
                {isInventoryItem && <ModalInventoryItem {...componentProps} />}
                {isUpload && <ModalUpload {...componentProps} />}
                {isUploads && <ModalMultipleUpload {...componentProps} />}
                {isUploadInfo && <ModalUploadInfo {...componentProps} />}
                {isNewMigration && <ModalMigration {...componentProps} />}
                {isNewTemplate && <ModalTemplate {...componentProps} />}
                {isEmployee && <ModalEmployee {...componentProps} />}
                {isDevice && <ModalDevice {...componentProps} />}
                {isFolderNote && <ModalFolderNoteEditor {...componentProps} />}
            </Modal>
        </>
    );
};
