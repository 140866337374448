import React from "react";
import FormGroup from "./FormGroup";

const ListFormGroups = ({ groups }) => {
    return (
        <div>
            {groups.map((group) => {
                return <FormGroup key={group.id} group={group} />;
            })}
        </div>
    );
};

export default ListFormGroups;
