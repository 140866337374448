import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button, DangerButton, PrimaryButton, SuccessButton } from "../layout/Button";

const Action = styled(Button)`
    padding: 0;
`;
const Title = styled.span`
    color: currentColor;
`;

const Icon = styled.i`
    color: currentColor;
    padding-right: 4px;
`;

const PageNavAction = ({
    onClick = () => {},
    title,
    icon,
    link = "",
    success = false,
    danger = false,
    primary = false,
    disabled = false,
    hint = "",
    ...props
}) => {
    let ActionEl = Button;
    if (primary) {
        ActionEl = PrimaryButton;
    }
    if (danger) {
        ActionEl = DangerButton;
    }
    if (success) {
        ActionEl = SuccessButton;
    }

    return (
        <ActionEl {...props} onClick={onClick} disabled={disabled} title={hint || title}>
            {link && (
                <NavLink to={link} activeClassName="active">
                    <Icon className={`fa fa-fw fa-${icon}`} />
                    {title && <Title>{title}</Title>}
                </NavLink>
            )}
            {!link && (
                <>
                    <Icon className={`fa fa-fw fa-${icon}`} />
                    {title && <Title>{title}</Title>}
                </>
            )}
        </ActionEl>
    );
};

export default PageNavAction;
