import { getCalendarEvents } from "@rpforms/shared/build/actions";
import { fetchEmployees } from "@rpforms/shared/build/actions/employees";

import { getDevices } from "@rpforms/shared/build/actions/devices";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../../reducers";
import {
    BaseCalendar,
    mapVacationsFromEmployeeForCalendar,
    onSelectRegularCalendarEvent,
    onSelectVacationEvent,
} from "../../calendar/BaseCalendar";
import CalendarEventTypeSelector from "../../calendar/CalendarEventTypeSelector";

export const EventCalendar = () => {
    const calendarEventState = useSelector<any, any>((state) => state.calendarEvents);
    const events = useSelector<any, any>((state) => state.calendarEvents.calendarEvents);
    const deviceState = useSelector<any, any>((state) => state.device);
    const types = useSelector<RootState, any>((store) => store.calendarEventTypes.types);
    const employees = useSelector<RootState, any>((state) => state.employees.employees);

    const dispatch = useDispatch();
    const [showSubmittedEvents, setShowSubmittedEvents] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(0);
    const [selectedType, setSelectedType] = useState(null);
    const [showVacations, setShowVacations] = useState(true);
    const [range, setRange] = useState({
        start: moment(+new Date()).startOf("week"),
        end: moment(+new Date()).endOf("week"),
    });

    const handleFilters = (events: any[]): any[] => {
        const ausfuehrungsId = types.find((t) => t.name === "Ausführung")?.id;

        let filteredEvents = events.filter((event) => {
            if (!selectedType || selectedType.id === -1) return true;

            if (ausfuehrungsId && selectedType.id === ausfuehrungsId) {
                return (
                    event.calendar_event_type_id === ausfuehrungsId || !event.calendar_event_type_id
                );
            }

            return event.calendar_event_type_id === selectedType.id;
        });

        if (!showSubmittedEvents) {
            filteredEvents = filteredEvents.filter((e) => !e.was_submitted);
        }

        if (selectedDevice !== 0) {
            filteredEvents = filteredEvents.filter((event) => event.device_id === selectedDevice);
        }

        if (showVacations) {
            filteredEvents = filteredEvents.concat(mapVacationsFromEmployeeForCalendar(employees));
        }

        return filteredEvents;
    };

    useEffect(() => {
        dispatch(getDevices());
        dispatch(fetchEmployees());
    }, []);

    useEffect(() => {
        dispatch(getCalendarEvents(range));
    }, [range]);

    const devices = deviceState.devices;

    const mappedDevicesForSelect = [
        { value: 0, label: "Alle" },
        { value: null, label: "Ohne Gerät" },
    ].concat(
        devices.map((device) => {
            return { value: device.id, label: device.name };
        })
    );

    const eventStyleGetter = (event, start, end, isSelected) => {
        const ausführungsId = types.find((t) => t.name === "Ausführung")?.id;
        const type = types.find((t) => t.id === event.calendar_event_type_id);
        const isAusführung =
            event.calendar_event_type_id === ausführungsId || !event.calendar_event_type_id;
        const device = devices.find((d) => event.device_id === d.id);

        const getBackgroundColor = () => {
            if (event.color) return event.color;
            if (!isAusführung) return type?.color;
            return device?.calendar_color || "#3174ad";
        };

        return {
            backgroundColor: getBackgroundColor(),
            fontWeight: event.was_submitted ? "normal" : "bold",
            fontStyle: event.was_submitted ? "italic" : "normal",
        };
    };

    const onSelectEvent = (event) => {
        if (event.employee_id) return onSelectVacationEvent(event, dispatch);
        return onSelectRegularCalendarEvent(event);
    };

    const setNewRange = (l) => {
        setRange({
            start: moment(l[0]).startOf("day"),
            end: moment(l[l.length - 1]).endOf("day"),
        });
    };

    return (
        <>
            <Row>
                <Col>
                    <label>Wählen Sie einen Eventtypen aus</label>
                    <CalendarEventTypeSelector
                        types={[
                            {
                                id: -1,
                                name: "Alle",
                                color: "rgba(0,0,0,0)",
                            },
                            ...types,
                        ]}
                        eventType={selectedType}
                        setFirstAsDefault
                        setEventType={setSelectedType}
                    />
                </Col>
                <Col>
                    <label>Wählen Sie die Geräte aus, die angezeigt werden sollen</label>
                    <Select
                        options={mappedDevicesForSelect}
                        defaultValue={mappedDevicesForSelect[0]}
                        onChange={(selected) => setSelectedDevice(selected.value)}
                        styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                    />
                </Col>
            </Row>
            <Row className="py-3">
                <Col>
                    <label className="mr-2">Auch bereits abgeschlossene Events anzeigen?</label>
                    <input
                        type="checkbox"
                        onChange={(e) => setShowSubmittedEvents(e.target.checked)}
                    />
                </Col>
                <Col>
                    <label className="mr-2">Auch Urlaube anzeigen?</label>
                    <input
                        type="checkbox"
                        defaultChecked={true}
                        onChange={(e) => setShowVacations(e.target.checked)}
                    />
                </Col>
            </Row>
            {(calendarEventState.isLoading || deviceState.isLoading || !types) && (
                <div style={{ position: "absolute" }}>
                    <Spinner animation="border" size={"lg"} variant={"primary"} />
                </div>
            )}
            <BaseCalendar
                onSelectEvent={onSelectEvent}
                onRangeChange={setNewRange}
                eventPropGetter={eventStyleGetter}
                defaultView={"week"}
                views={["month", "week", "day", "agenda"]}
                events={handleFilters(events).map((event: any) => mapEventForDisplay(event))}
            />
        </>
    );
};

const mapEventForDisplay = (event: any) => {
    const eventStart = event.start || event.scheduled_from;
    const eventEnd = event.end || event.scheduled_to;
    const eventTitle = event.calendar_title || "Urlaub: " + event.title;
    return {
        ...event,
        start: moment(eventStart).toDate(),
        end: moment(eventEnd).toDate(),
        title: eventTitle,
        allDay: event.employee_id,
    };
};

export default EventCalendar;
