import React from "react";
import { BaseField } from "../BaseField";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";

/**
 * set if data is applyable on migration
 */
export class NonMigratable extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = false;

    public keyName = "nonmigratable";
    public title = "Mirgierten Inhalt nicht übernehmen";
    public description =
        "Wenn diese Option aktiviert ist, werden beim Verwenden die Daten von der Quelle nicht übernommen";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: BaseField;
    }): any {
        return (
            <AppearanceCheckbox
                label={this.title}
                register={register}
                value={field.rawOptions[this.keyName]}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value !== false;
    }

    public wakeValue(value) {
        return value;
    }
}
