import React from "react";
import styled from "styled-components";

export const Card = styled.div`
    background: #fff;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
`;

export const InputError = styled.small`
    color: red;
`;

export const BoxCard = styled.div`
    border: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1), -2px -2px 8px rgba(255, 255, 255, 0.8);
`;

export const CardTitle = styled.div`
    padding: 15px 20px;
    font-size: 24px;
    background: #fff;
    color: ${(props) => props.theme.primary_color || "#00bcd4"};
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px 6px 0 0;
`;

export const CardActions = styled.div``;
export const CardAction = styled.div``;

export const CardBody = styled.div`
    padding: 20px;
    background: #fafafa;
    border-radius: 0 0 6px 6px;
    ${(props: any) => props.noPadding && `padding: 0 !important`}
`;

export default {
    CardTitle,
    CardBody,
    Card,
    BoxCard,
};
