import { last } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useSearchParam } from "react-use";
import styled from "styled-components";
import { RootState } from "../../reducers";
import { FolderBreadcrumbs } from "./FolderBreadcrumbs";
import { FolderEdit } from "./FolderEdit";
import { FolderList } from "./FolderList";
import { FolderToolbar } from "./FolderToolbar";
import { FolderViewPane, FolderViewPanes } from "./FolderViewPane";
import { useFolderQueryString } from "./hooks/useFolderQueryString";
import { useFetchFolders } from "./hooks/useFetchFolders";

const FolderThumbs = styled.div`
    display: flex;
    padding: 20px;
    flex-wrap: wrap;
    flex: 0 50%;

    .react-contextmenu-wrapper > div {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }
`;

const FolderNavigationWrapper = styled.div`
    background: #fff;
    border: 1px solid #ddd;
    border-top: none;
    z-index: 105;
    position: sticky;
`;

const ROOT_DIRECTORY = { id: null, resource_name: "Hauptverzeichnis" };

export const FolderNavigation = ({ onSelect = (x) => {}, movable = true, toolbar = true }) => {
    const dispatch = useDispatch();
    const openFolders = useSearchParam("open");
    const openFoldersHierarchy = openFolders
        ? JSON.parse(decodeURIComponent(openFolders) || "[]")
        : [];

    const [showFolderEdit, setShowFolderEdit] = useState(false);

    const [hierarchy, setHierarchy] = useState([ROOT_DIRECTORY, ...openFoldersHierarchy]);
    const [activeFolder, setActiveFolder] = useState(
        hierarchy.length ? last(hierarchy) : ROOT_DIRECTORY
    );
    const { selection, selectionEnabled, search } = useSelector<RootState, any>(
        (state) => state.directory
    );

    useFolderQueryString({ hierarchy, search, showFolderEdit });
    useFetchFolders({ search, hierarchy });

    useEffect(() => {
        if (showFolderEdit) {
            return;
        }
        onSelect(activeFolder);
    }, [activeFolder]);

    const folders = useSelector<any, any>((state) =>
        state.directory.folders.filter((folder) => {
            return folder.parent_id === activeFolder.id;
        })
    );

    useEffect(() => {
        if (showFolderEdit) {
            return;
        }

        // check if hierarchy contains any date folders and open current one
        const children = folders.filter((f) => f.parent_id === activeFolder.id);
        children.forEach((child) => {
            if (child.name === `${new Date().getFullYear()}`) {
                openPaneFolder(child);
            }
        });
    }, [folders]);

    // -> Highlight selected folder in folder pane
    useEffect(() => {
        setTimeout(() => {
            const panes = Array.from(document.querySelectorAll("[data-pane]"));
            panes.pop();
            panes.forEach((p) => {
                const selected = p.querySelector("[data-selected='true']");
                if (selected) {
                    selected.scrollIntoView();
                }
            });
        }, 50);
    }, [hierarchy, activeFolder, folders]);

    const inSearch = (folder) => {
        if (!search || !search.query) {
            return true;
        }

        let queryParts = [folder.name];
        if (search.scope === "contact") {
            queryParts = [
                ...queryParts,
                folder.resource.timing_contact_name,
                folder.resource.contact_name,
                folder.resource.contacts,
            ];
        }
        queryParts = queryParts.filter((q) => !!q);

        return queryParts.some((q) => q.toLowerCase().indexOf(search.query.toLowerCase()) > -1);
    };

    const openPaneFolder = (folder) => {
        if (selectionEnabled) {
            return;
        }

        if (folder.resource_type === "Upload") {
            setActiveFolder(folder);
            return;
        }

        const index = hierarchy.findIndex((f) => f.id === folder.parent_id);
        setHierarchy((current) => {
            return [...current.slice(0, index + 1), { ...folder }];
        });

        setActiveFolder(folder);
    };

    const onMove = () => {};

    const sync = () => {
        toast.success(`✅ Sync angestoßen`, { autoClose: 1000 });
    };

    // pop hierarchy until we reach desired point
    const navigateTo = (folder) => {
        if (folder.id === null) {
            return navigateToRoot();
        }
        setHierarchy((current) => {
            const index = current.findIndex((f) => f.id === folder.id);
            return [...current.slice(0, index + 1)];
        });
        setActiveFolder(folder);
    };

    const navigateToRoot = () => {
        setHierarchy([ROOT_DIRECTORY]);
        setActiveFolder(ROOT_DIRECTORY);
    };

    return (
        <FolderNavigationWrapper>
            {toolbar && <FolderToolbar selectedFolder={activeFolder} onSync={sync} />}
            {toolbar && <FolderBreadcrumbs navigateTo={navigateTo} hierarchy={hierarchy} />}
            <FolderViewPanes>
                {/* root pane */}
                {hierarchy.map((folder, i) => {
                    const isLast = hierarchy.length - 2 <= i; // hide border of last two open folders (last is empty)
                    return (
                        <FolderViewPane key={folder.id}>
                            <FolderList
                                inSearch={inSearch}
                                onMove={onMove}
                                activeFolder={activeFolder}
                                hierarchy={hierarchy}
                                openFolder={openPaneFolder}
                                movable={movable}
                                parent={folder}
                                isLast={isLast}
                                depth={i}
                            />
                        </FolderViewPane>
                    );
                })}
            </FolderViewPanes>
            {showFolderEdit && (
                <FolderEdit
                    parent={activeFolder}
                    editFolder={activeFolder}
                    onHide={() => setShowFolderEdit(false)}
                ></FolderEdit>
            )}
        </FolderNavigationWrapper>
    );
};
