import { range } from "@rpforms/shared";
import {
    deleteInventoryItem,
    getInventoryItems,
} from "@rpforms/shared/build/actions/inventory_items";
import * as actions from "@rpforms/shared/build/actions/inventory_items";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import { push } from "connected-react-router";
import React, { Component, useEffect, useState } from "react";
import { Pagination, Table } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { useSearchParam } from "react-use";
import styled from "styled-components";
import InventoryItemTableHead from "../../components/inventory_items/InventoryItemTableHead";
import ServicePositionTableFoot from "../../components/inventory_items/ServicePositionTableFoot";
import ServicePositionTableRow from "../../components/inventory_items/ServicePositionTableRow";
import { RootState } from "../../reducers";

const Centered = styled.div`
    display: flex;
    justify-content: center;
`;

const TableServicePositionsPage = () => {
    const data = useSelector<RootState, any>((state) => state.inventoryItems.data);
    const isLoading = useSelector<RootState, any>((state) => state.inventoryItems.isLoading);
    const initialPage = parseInt(useSearchParam("page"), 10) || 1;
    const initialPageSize = parseInt(useSearchParam("page_size"), 10) || -1;
    const [page, setPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const initialOrderBy = useSearchParam("order_by") || "name";
    const initialOrderDir = useSearchParam("order_dir") || "asc";
    const [orderBy, setOrderBy] = useState(initialOrderBy);
    const [orderDir, setOrderDir] = useState(initialOrderDir);
    const dispatch = useDispatch();

    const initPage = (params) => {
        const qsParams = {};
        if (params.page >= 2) {
            qsParams.page = params.page;
        }
        if (params.pageSize >= 1) {
            qsParams.page_size = params.pageSize;
        }
        if (params.orderBy !== "name") {
            qsParams.order_by = params.orderBy;
        }
        if (params.orderDir !== "asc") {
            qsParams.order_dir = params.orderDir;
        }

        const qsKeys = Object.keys(qsParams);
        const qs =
            qsKeys.length > 0 ? "?" + qsKeys.map((k) => `${k}=${qsParams[k]}`).join("&") : "";
        dispatch(push(`/inventory_items/service${qs}`));
        dispatch(getInventoryItems(qsParams, "service"));
    };

    useEffect(() => {
        initPage({ page, pageSize, orderBy, orderDir });
    }, [page, pageSize, orderBy, orderDir]);

    const openPage = (newPage) => {
        setPage(newPage);
    };

    const pages = () => {
        const pagesOffset = 5;
        const totalPages = data.inventory_items.length > 0 ? data.total_pages : 1;
        return [
            1,
            ...range(Math.max(2, page - pagesOffset), Math.min(totalPages, page + pagesOffset)),
        ];
    };

    const removeServicePosition = async (inv) => {
        await dispatch(deleteInventoryItem(inv));
    };

    return (
        <>
            {isLoading && <Loader />}
            {!isLoading && (
                <div>
                    <Content>
                        {!isLoading && data.inventory_items && data.inventory_items.length > 0 && (
                            <Table striped bordered hover size="sm">
                                <InventoryItemTableHead onSelect={initPage} type={"service"} />
                                <tbody>
                                    {data.inventory_items &&
                                        data.inventory_items.map((inv) => {
                                            return (
                                                <ServicePositionTableRow
                                                    key={inv.id}
                                                    servicePosition={inv}
                                                    deleteServicePosition={removeServicePosition}
                                                />
                                            );
                                        })}
                                </tbody>
                                <ServicePositionTableFoot data={data} />
                            </Table>
                        )}
                        {!isLoading && data.inventory_items && data.inventory_items.length <= 0 && (
                            <Content>
                                <EmptySpace
                                    title={"Keine Service-Positionen"}
                                    description={"Füge neue Positions über Hinzufügen hinzu"}
                                    cta={false}
                                />
                            </Content>
                        )}
                        <div>
                            <Centered>
                                <Pagination>
                                    {pages().map((p) => {
                                        return (
                                            <Pagination.Item
                                                active={p === page}
                                                key={`page_${p}`}
                                                onClick={() => openPage(p)}
                                            >
                                                {p}
                                            </Pagination.Item>
                                        );
                                    })}
                                </Pagination>
                            </Centered>
                        </div>
                    </Content>
                </div>
            )}
        </>
    );
};

function mapStateToProps(state) {
    return { state: state.inventory_items };
}

export default withRouter(connect(mapStateToProps, actions)(TableServicePositionsPage));
