// @ts-ignore
import React from "react";
import { AppearanceBox } from "./fields/AppearanceBox";
import { AppearanceDescription } from "./fields/AppearanceDescription";
import { AppearanceLabel } from "./fields/AppearanceLabel";
import { AppearanceText } from "./fields/AppearanceText";

/**
 * Provides an interface to configure options per field.
 * Each field can define their own configurables and can apply them
 * during rendering of the field.
 */
export class Configurable {
    public schema;
    public designer;
    public field;
    public register;
    public value = "";
    public widget;
    public fieldOptions;
    public raw_value;

    public keyName = "field";
    public title = "";
    public description = "";
    constructor({ register = null, schema = null, designer = null, field = null }) {
        this.schema = schema;
        this.register = register;
        this.designer = designer;
        this.field = field;
        this.widget = (
            <AppearanceText
                value=""
                field={field}
                register={register}
                keyName={this.field.hashCode}
            />
        );
        this.fieldOptions = field.options;

        if (Array.isArray(field.options)) {
            this.fieldOptions = field.serializeConfigurables(field.options);
        }
        this.value = field.rawOptions[this.keyName] || this.fieldOptions[this.keyName];
    }

    /**
     * Renders the element in the modal view of each field
     * You can override this function to provide custom logic for your configurables.
     * (e.g.) Picking from a set of colors.
     * @constructor
     */
    public EditComponent({ register, schema, designer, field }) {
        if (!field) {
            return <div></div>;
        }
        return <AppearanceBox key={field.hashCode}>{this.widget}</AppearanceBox>;
    }

    public sleepValue(value) {
        return value;
    }

    public wakeValue(value) {
        return value;
    }

    public validate(component) {}

    public extend(component) {}

    public save(component) {}

    /**
     * @event
     */
    public fieldWasRendered() {}

    /**
     * Apply certain actions to the input element before it is rendered
     * You can set DOM attributes etc. It's a regular JSX react object.
     * You can also mess with the props and and references.
     * @param inputEl
     */
    public configureInput(inputEl, register) {
        return inputEl;
    }

    public configureComponent(component) {
        return component;
    }

    public configureLabel(inputEl, register) {
        return inputEl;
    }

    public configureCSS(val) {
        return val;
    }
}
