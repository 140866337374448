// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useRouteMatch } from "react-router";
import { FieldWrapper } from "../components/FieldWrapper";
import { UploadField } from "../components/uploadField/UploadField";
import { API_URL } from "../config";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, MaxLength, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";
import { RequiredImageAmount } from "./configurables/RequiredImageAmount";
import { Label, LabelInput } from "./inputFields/label";

export class PictureField extends BaseField {
    public icon = "camera";
    public fieldType = "Bild Upload";
    public attributeNames = [
        "namePattern",
        "allowedExtensions",
        "maxFiles",
        "allowMultiple",
        "onlyCamera",
    ];

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Description, Width, Hint, RequiredImageAmount];
    }

    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Titel</label>
                        <LabelInput register={register} field={field} />

                        <label className="mt-3">Erlaubte Dateitypen</label>
                        <div className="text-muted">
                            Kommaseparierte Liste an Datei Endungen. Leer lassen um alle Dateien zu
                            erlauben. Beispiel: png,jpeg,gif,pdf.
                        </div>

                        <input
                            type={"text"}
                            name={field.inputName("allowedExtensions")}
                            className="form-control"
                            defaultValue={field.allowedExtensions}
                            ref={register}
                        />

                        <label className="mt-3">Bildname Muster</label>
                        <div className="text-muted">
                            Nach welchem Muster soll der Bildname festgelegt werden? Textbausteine
                            können verwendet werden.
                        </div>

                        <input
                            type={"text"}
                            name={field.inputName("namePattern")}
                            className="form-control"
                            defaultValue={
                                field.namePattern || "{form:name}_{entity:name}.{file:extension}"
                            }
                            ref={register}
                        />
                        <label className={"mt-3"}>Unterfelder</label>
                        <div className="powerform-row-edit">
                            {field.subfields ? (
                                field.subfields.map((subfield) => (
                                    <subfield.EditComponent
                                        designer={designer}
                                        register={register}
                                        key={subfield.hashCode}
                                        schema={schema}
                                        field={subfield}
                                        entry={null}
                                    />
                                ))
                            ) : (
                                <span />
                            )}
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        designer,
        schema,
        register,
        field,
        rawValue,
        readonly = true,
        variables: inputVariables = { isPrinting: false },
    }) {
        if (inputVariables.isPrinting) {
            return null;
        }
        const params: any = useParams();
        const { id } = params;
        const { url } = useRouteMatch();
        const pdfRendering = url.includes("pdf");

        const formEntry: any = useSelector<any>(
            (state) =>
                state.formEntries && state.formEntries.byId && state.formEntries.byId[`entry_${id}`]
            // app also uses a formEntries reducer but it lacks the "byId" call. this formEntry should only be set for desktop devices.
        );
        const entity = useSelector((state: any) => {
            return state.entities?.entities.find((entity: any) => {
                return String(entity.id) === params.entity_id;
            });
        });

        const task = useSelector((state: any) => {
            return state.tasks?.tasks.find((task: any) => {
                return String(task.id) === params.task_id;
            });
        });

        const form = useSelector<any, any>((state) => {
            return state.forms?.forms.find((f) => f.id === task?.form_id);
        });

        let initial;
        try {
            initial = JSON.parse(rawValue || field.rawValue || "[]");
        } catch (e) {
            initial = [];
        }

        const [uploads, setUploads] = useState(initial);

        const data = JSON.stringify(uploads);
        const variables = task
            ? {
                  formEntry,
                  uploads,
                  entity: { ...entity, ...(formEntry ? formEntry.entity : {}) },
                  task,
                  form: {
                      ...form,
                      ...(formEntry ? formEntry.form : {}),
                      slug: form ? form.slug || form.name : null,
                  },
              }
            : { uploads };

        return layout.wrapInput(
            field.configureInput(
                <div>
                    <Label layout={layout} field={field} />
                    {layout.wrapDescription(
                        field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                    )}
                    {field.rawOptions.requiredImageAmount > 0 &&
                        layout.wrapDescription(
                            <p>
                                (Bitte laden Sie mindestens {field.rawOptions.requiredImageAmount}{" "}
                                Bilder hoch)
                            </p>
                        )}
                    <input value={data} type={"hidden"} name={field.hashCode} />
                    {layout.wrapComponent(
                        <UploadField
                            setUploads={setUploads}
                            namePattern={field.namePattern}
                            layout={layout}
                            subfields={field.subfields}
                            uploads={uploads}
                            variables={variables}
                            showImages={!pdfRendering}
                            hashCode={field.hashCode}
                            readonly={readonly}
                        />
                    )}
                    {layout.wrapHint(
                        field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>
                    )}
                </div>
            )
        );
    }

    public PresenterComponent({ rawValue, field, name, layout }: any): any {
        let data: any = [];
        try {
            data = JSON.parse(rawValue);
        } catch (e) {
            data = [];
        }
        if (data && data.id) {
            return (
                <div>
                    <Label layout={layout} field={field} />
                    {layout.wrapDescription(
                        field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                    )}
                    <input type={"hidden"} name={name} value={JSON.stringify(data)} />
                    <p>
                        <i className="fa fa-file"></i> 1 Anhang
                    </p>
                </div>
            );
        }

        return (
            <div>
                <Label layout={layout} field={field} />
                {layout.wrapDescription(
                    field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                )}
                <input type={"hidden"} name={name} value={JSON.stringify(data)} />
                <div>
                    <i className="fa fa-file"></i> {data.length}{" "}
                    {data.length === 1 ? "Anhang" : "Anhänge"}
                </div>
            </div>
        );
    }
}
