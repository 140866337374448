import { fetchEmployees } from "@rpforms/shared/build/actions/employees";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import { push } from "connected-react-router";
import moment from "moment";
import "moment/locale/de";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../reducers";
import {
    BaseCalendar,
    mapVacationsFromEmployeeForCalendar,
    onSelectVacationEvent,
} from "../calendar/BaseCalendar";

export const VacationCalendar = () => {
    const employees = useSelector<RootState, any>((state) => state.employees.employees);
    const dispatch = useDispatch();
    const [showNoPermission, setShowNoPermission] = useState(false);

    useEffect(() => {
        dispatch(fetchEmployees());
    }, []);

    if (!employees) {
        return <Loader />;
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            backgroundColor: event?.color || "#3174ad",
        };
    };

    const getVacations = () => {
        const vacations = mapVacationsFromEmployeeForCalendar(employees);
        if (!showNoPermission) {
            return vacations.filter((v) => v.permitted);
        }
        return vacations;
    };

    return (
        <>
            <div className="row pb-5">
                <div className="col d-flex flex-column align-items-start">
                    <label>Auch noch nicht genehmigte Urlaube anzeigen?</label>
                    <input
                        type="checkbox"
                        onChange={(e) => setShowNoPermission(e.target.checked)}
                    />
                </div>
            </div>
            <BaseCalendar
                eventPropGetter={eventStyleGetter}
                views={["month", "agenda"]}
                defaultView="month"
                onSelectEvent={(event) => onSelectVacationEvent(event, dispatch)}
                events={getVacations()}
            />
        </>
    );
};

export default withRouter(VacationCalendar);
