/* eslint-disable */
import { persistStore, persistReducer } from "redux-persist";
import { sharedHistory } from "@rpforms/shared";

import authReducer from "@rpforms/shared/build/reducers/authReducer";
import calendarEventParticipantReducer from "@rpforms/shared/build/reducers/calendarEventParticipantReducer";
import calendarEventReducer from "@rpforms/shared/build/reducers/calendarEventReducer";
import calendarEventTypeReducer from "@rpforms/shared/build/reducers/calendarEventTypeReducer";
import categoryReducer from "@rpforms/shared/build/reducers/categoryReducer";
import companyReducer from "@rpforms/shared/build/reducers/companyReducer";
import customerReducer from "@rpforms/shared/build/reducers/customerReducer";
import customFieldsReducer from "@rpforms/shared/build/reducers/customFieldsReducer";
import deviceReducer from "@rpforms/shared/build/reducers/deviceReducer";
import directoryReducer from "@rpforms/shared/build/reducers/directoryReducer";
import editorReducer from "@rpforms/shared/build/reducers/editorReducer";
import employeeReducer from "@rpforms/shared/build/reducers/employeeReducer";
import entityReducer from "@rpforms/shared/build/reducers/entityReducer";
import financesReducer from "@rpforms/shared/build/reducers/financesReducer";
import formDataReducer from "@rpforms/shared/build/reducers/formDataReducer";
import formEntriesReducer from "@rpforms/shared/build/reducers/formEntriesReducer";
import formGroupReducer from "@rpforms/shared/build/reducers/formGroupReducer";
import formReducer from "@rpforms/shared/build/reducers/formReducer";
import inventoryItemReducer from "@rpforms/shared/build/reducers/inventoryItemReducer";
import manufacturerReducer from "@rpforms/shared/build/reducers/manufacturerReducer";
import notificationGroupReducer from "@rpforms/shared/build/reducers/notificationGroupReducer";
import permissionsReducer from "@rpforms/shared/build/reducers/permissionsReducer";
import searchReducer from "@rpforms/shared/build/reducers/searchReducer";
import statesReducer from "@rpforms/shared/build/reducers/statesReducer";
import termsReducer from "@rpforms/shared/build/reducers/termsReducer";
import userReducer from "@rpforms/shared/build/reducers/userReducer";
import usersReducer from "@rpforms/shared/build/reducers/usersReducer";
import vacationReducer from "@rpforms/shared/build/reducers/vacationReducer";
import timeTrackingReducer from "@rpforms/shared/build/reducers/timeTrackingReducer";

import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import storage from "redux-persist-indexeddb-storage";

// TODO: add correct state typings for each element here.
export interface RootState {
    company: any;
    users: any;
    auth: any;
    directory: any;
    user: any;
    device: any;
    employees: any;
    forms: any;
    formEntries: any;
    entities: any;
    customFields: any;
    router: any;
    tasks: any;
    formRevision: any;
    formGroups: any;
    folders: any;
    permissions: any;
    customers: any;
    editor: any;
    search: any;
    inventoryItems: any;
    manufacturers: any;
    categories: any;
    calendarEvents: any;
    notificationGroups: any;
    finances: any;
    vacations: any;
    calendarEventTypes: any;
    calendarEventParticipants: any;
    zipUrl: any;
    terms: any;
    states: any;
    timeTrackings: any;
}

const rootReducer = combineReducers({
    form,
    calendarEvents: calendarEventReducer,
    company: companyReducer,
    customers: customerReducer,
    editor: editorReducer,
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    device: deviceReducer,
    forms: formReducer,
    employees: employeeReducer,
    vacations: vacationReducer,
    timeTrackings: timeTrackingReducer,
    // special care taking to make fields compatible with app.
    // TODO: migrate app reducers into shared package so we can omit this.
    tasks: (s = { tasks: [] }) => s,
    formGroups: formGroupReducer,
    formEntries: formEntriesReducer,
    formData: formDataReducer,
    entities: entityReducer,
    search: searchReducer,
    customFields: customFieldsReducer,
    directory: directoryReducer,
    permissions: permissionsReducer,
    inventoryItems: inventoryItemReducer,
    manufacturers: manufacturerReducer,
    categories: categoryReducer,
    notificationGroups: notificationGroupReducer,
    finances: financesReducer,
    calendarEventTypes: calendarEventTypeReducer,
    calendarEventParticipants: calendarEventParticipantReducer,
    terms: termsReducer,
    states: statesReducer,
    router: connectRouter(sharedHistory),
});

export default rootReducer;
