import { fetchFolders, NEW_UPLOAD_INFO, PrimaryButton } from "@rpforms/shared";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import React, { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const ModalMultipleUpload = ({ editor, next }) => {
    const ALLOWED_MIME_TYPES = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
    ];

    const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10MB
    const [uploads, setUploads] = useState<any>([]);
    const [uploading, setUploading] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        setUploading(true);
        await next({}, (payload) => {
            // transform payload before transmitting
            const formData = new FormData();
            [...uploads].forEach((file, i) => {
                const uploadData = {
                    ...editor.config.preload,
                    name: file.name,
                };
                formData.append(`file[]`, file.file);
                formData.append(`data[]`, JSON.stringify(uploadData));
            });
            return formData;
        });
        setUploading(false);
        toast.success("Dateien hochgeladen");
        if (editor.config.preload?.folder) {
            dispatch(fetchFolders(editor.config.preload.folder));
        }
        dispatch(
            openEditor({
                type: NEW_UPLOAD_INFO,
            })
        );
    };

    const processUpload = (ev) => {
        const { files } = ev.target;

        const uploadedFiles = files;

        [...uploadedFiles].forEach((upload) => {
            const reader = new FileReader();
            reader.onload = (onloadEv) => {
                const { result: preview } = onloadEv.target;
                setUploads((currentUploads) => {
                    return [
                        ...currentUploads,
                        {
                            file: upload,
                            name: upload.name,
                            size: upload.size,
                            type: upload.type,
                            preview: upload.type.indexOf("image/") === 0 ? preview : false,
                        },
                    ];
                });
            };

            reader.onerror = () => {
                setUploads((currentUploads) => {
                    return [
                        ...currentUploads,
                        {
                            file: upload,
                            name: upload.name,
                            size: upload.size,
                            type: upload.type,
                            preview: false,
                        },
                    ];
                });
            };
            reader.readAsDataURL(upload);
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Modal.Header closeButton>
                <Modal.Title>
                    {editor.config.preload?.title || "Neue Dateien erstellen"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label>Name</label>
                        <input
                            className={"form-control"}
                            type={"file"}
                            multiple
                            name="name"
                            onChange={processUpload}
                            ref={register({
                                required: "Datei muss ausgewählt sein",
                            })}
                        />
                        {errors.name && errors.name.message}
                        <br />
                    </Col>
                </Row>
                {uploads &&
                    uploads.map((upload, i) => {
                        return (
                            <Row key={`upload-${i}`}>
                                <Col>
                                    {upload.size > MAX_FILE_SIZE && (
                                        <Alert variant={"danger"}>
                                            Diese Datei ist zu groß und kann nicht hochgeladen
                                            werden.
                                        </Alert>
                                    )}
                                    {!ALLOWED_MIME_TYPES.includes(upload.type) && (
                                        <Alert variant={"danger"}>
                                            Nur PDFs und Bilder werden unterstützt.
                                        </Alert>
                                    )}
                                    {upload.name}
                                </Col>
                            </Row>
                        );
                    })}
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton disabled={uploading} type={"submit"}>
                    Hochladen
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
