import React from "react";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";
import { AppearanceSlider } from "./fields/AppearanceSlider";
import { AppearanceText } from "./fields/AppearanceText";

/**
 * Applies maximum length to input field
 */
export class Required extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = true;

    public keyName = "required";
    public title = "Pflichtfeld?";
    public description = "Muss dieses Feld ausgefüllt werden?";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: any;
    }): any {
        return (
            <AppearanceCheckbox
                label="Muss ausgefüllt werden"
                register={register}
                value={field.rawOptions[this.keyName]}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value !== false;
    }

    public configureInput(inputEl, register): any {
        if (this.value) {
            inputEl = React.cloneElement(
                inputEl,
                {
                    "required": true,
                    "data-required": true,
                    ...inputEl.props,
                },
                inputEl.props.children
            );
            return inputEl;
        }
        return inputEl;
    }

    public wakeValue(value) {
        return value;
    }
}
