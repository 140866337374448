import {
    CREATE_CALENDAR_EVENT_PARTICIPANT,
    DELETE_CALENDAR_EVENT_PARTICIPANT,
    GET_CALENDAR_EVENT_PARTICIPANTS,
    UPDATE_CALENDAR_EVENT_PARTICIPANT,
} from "./../actions/types/index";

const initialState = {
    participants: [],
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case CREATE_CALENDAR_EVENT_PARTICIPANT:
            if (
                state.participants.find((participant: any) => participant.id === action.payload.id)
            ) {
                return { ...state };
            }
            return { ...state, participants: [...state.participants, action.payload] };
        case DELETE_CALENDAR_EVENT_PARTICIPANT:
            return {
                ...state,
                participants: state.participants.filter(
                    (participant: any) => participant.id !== action.payload.id
                ),
            };
        case GET_CALENDAR_EVENT_PARTICIPANTS:
            return { ...state, participants: action.payload };
        case UPDATE_CALENDAR_EVENT_PARTICIPANT:
            const participants = state.participants.map((participant: any) => {
                if (participant.id === action.payload.id) {
                    return action.payload;
                }
                return participant;
            });
            return { ...state, participants };
    }

    return state;
}
