import React, { useRef, useState } from "react";
import { useGeolocation } from "react-use";
import { SmallButton, SmallDangerButton } from "../components/Button";
import { DefaultValue, Description, Hint, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";
import { Label } from "./inputFields/label";
import { TextField } from "./TextField";

export class LocationField extends TextField {
    public icon = "map-pin";
    public fieldType = "Standort";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Required, Description, DefaultValue, Width, Hint];
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
        rawValue,
    }) {
        const [enableGeolocation, setEnableGeolocation] = useState(false);
        const [showLocationText, setShowLocationText] = useState(false);
        const [manualLocation, setManualLocation] = useState<any>();
        const manualLocationField = useRef();

        if (!rawValue) {
            rawValue = field.rawValue;
        }

        const geoState = useGeolocation();
        if (!(window as any).EDIT_MODE && rawValue && typeof rawValue !== "object") {
            return (
                <div>
                    <div className="rpfe-label">
                        <p>Standort</p>
                    </div>
                    <div>{rawValue !== "null,null" ? rawValue : "-"}</div>
                </div>
            );
        }

        if ((window as any).EDIT_MODE && rawValue && typeof rawValue !== "object") {
            return (
                <div>
                    <Label layout={layout} field={field} />
                    {layout.wrapInput(
                        field.configureInput(
                            <input
                                type="text"
                                name={name}
                                className="form-control"
                                defaultValue={rawValue}
                            />,
                            register,
                            schema
                        )
                    )}
                </div>
            );
        }

        const FetchGeolocation = (
            <div>
                <SmallButton
                    onClick={(e) => {
                        setEnableGeolocation(true);
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }}
                    type="button"
                >
                    <i className="fa fa-fw fa-map-pin" /> Via GPS
                </SmallButton>
                <SmallButton
                    onClick={(e) => {
                        setShowLocationText(true);
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }}
                    type="button"
                >
                    <i className="fa fa-fw fa-edit" /> Eingeben...
                </SmallButton>
            </div>
        );

        const EnterLocation = (
            <div>
                <input type={"text"} ref={manualLocationField} className={"form-control"} />
                <SmallButton
                    onClick={() => {
                        if (manualLocationField && manualLocationField.current) {
                            setManualLocation((manualLocationField as any).current.value);
                        }
                        setShowLocationText(false);
                        return false;
                    }}
                >
                    Standort übernehmen
                </SmallButton>
                <SmallDangerButton
                    onClick={() => {
                        setManualLocation("");
                        setShowLocationText(false);
                        return false;
                    }}
                >
                    Abbrechen
                </SmallDangerButton>
            </div>
        );

        const LoadingGeolocation = (
            <div>
                <SmallButton onClick={() => setEnableGeolocation(true)} type="button">
                    <i className="fa fa-fw fa-spinner fa-spin" /> GPS
                </SmallButton>
            </div>
        );

        const HasGeolocation = (
            <div>
                <span className={"text-success"}>
                    <i className={"fa fa-check"} />
                </span>
                Standort
                <br />{" "}
                {manualLocation
                    ? manualLocation
                    : geoState.latitude + "," + geoState.longitude}{" "}
                <i
                    className={"fa fa-fw fa-edit text-muted"}
                    onClick={() => {
                        setEnableGeolocation(false);
                        setManualLocation(false);
                    }}
                />
            </div>
        );

        const ShowGeolocation =
            geoState.loading && !manualLocation ? LoadingGeolocation : HasGeolocation;
        return layout.wrapComponent(
            <div className={layout.wrapComponentCSS(field.configureCSS())}>
                {layout.wrapFieldset(
                    <>
                        <Label layout={layout} field={field} />
                        {layout.wrapInput(
                            field.configureInput(
                                <input
                                    type="hidden"
                                    name={name}
                                    className="form-control"
                                    defaultValue={
                                        entry
                                            ? entry.value
                                            : manualLocation
                                            ? manualLocation
                                            : `${geoState.latitude},${geoState.longitude}`
                                    }
                                />,
                                register,
                                schema
                            )
                        )}
                        {layout.wrapDescription(
                            field.rawOptions.description ? (
                                <p>{field.rawOptions.description}</p>
                            ) : (
                                <></>
                            )
                        )}

                        <div className={"hidden-print"}>
                            {showLocationText
                                ? EnterLocation
                                : enableGeolocation || manualLocation
                                ? ShowGeolocation
                                : FetchGeolocation}
                        </div>
                        <div className={"visible-print"}>-</div>

                        {layout.wrapHint(
                            field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>
                        )}
                    </>
                )}
            </div>
        );
    }
}
