import axios from "axios";
import { push } from "connected-react-router";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import {
    CREATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    GET_EMPLOYEE_BY_ID,
    GET_EMPLOYEES,
    UPDATE_EMPLOYEE,
} from "./types";

export function deleteEmployee(employee: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/employees/${employee.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: DELETE_EMPLOYEE,
                });
            })
            .then(() => dispatch(push("/master_data/employees")))
            .catch((err) => console.error(err));
}

export function updateEmployeeSpecialVacation(special_vacation: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .put(
                `${API_URL}/employee_special_vacations/${special_vacation.id}.json`,
                special_vacation,
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                window.location.reload();

                if (response.data.id) {
                    window.location.reload();
                }
            })
            .then(() => null)
            .catch((err) => console.error(err));
}

export function createEmployeeSpecialVacation(special_vacation: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .post(`${API_URL}/employee_special_vacations.json`, special_vacation, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                if (response.data.id) {
                    window.location.reload();
                }
            })
            .then(() => null)
            .catch((err) => console.error(err));
}

export function changeEmployeeColor(data) {
    return (dispatch) => {
        axios
            .post(`${API_URL}/employee_color.json`, data, {
                headers: { authorization: user().token },
            })
            .then((response) => {})
            .then(() => null)
            .catch((err) => console.error(err));
    };
}

export function deleteEmployeeSpecialVacation(special_vacation: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .get(`${API_URL}/employee_special_vacations/delete.json?id=${special_vacation.id}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                window.location.reload();
            })
            .then(() => null)
            .catch((err) => console.error(err));
}

export function updateEmployeeCert(cert: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .put(`${API_URL}/employee_certs/${cert.id}.json`, cert, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                window.location.reload();
                if (response.data.id) {
                    window.location.reload();
                }
            })
            .then(() => null)
            .catch((err) => console.error(err));
}

export function createEmployeeCert(cert: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .post(`${API_URL}/employee_certs.json`, cert, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                if (response.data.id) {
                    window.location.reload();
                }
            })
            .then(() => null)
            .catch((err) => console.error(err));
}

export function deleteEmployeeCert(cert: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .get(`${API_URL}/employee_certs/delete.json?id=${cert.id}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                window.location.reload();
            })
            .then(() => null)
            .catch((err) => console.error(err));
}

export function createPdf(id: any) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios
            .get(`${API_URL}/employees/${id}.json?pdf=true`, {
                headers: {
                    authorization: user().token,
                },
                responseType: "blob",
            })
            .then((response) => response.data)
            .then((blob) => {
                const data = window.URL.createObjectURL(blob);
                window.location.href = data;
            })
            .catch((err) => console.error(err));
    };
}

export function createPdfForAll() {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios
            .get(`${API_URL}/employees.json?pdf=true`, {
                headers: {
                    authorization: user().token,
                },
                responseType: "blob",
            })
            .then((response) => response.data)
            .then((blob) => {
                const data = window.URL.createObjectURL(blob);
                window.location.href = data;
            })
            .catch((err) => console.error(err));
    };
}

export function fetchEmployeeById(id: string) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.get(`${API_URL}/employees/${id}.json`, {
            headers: {
                authorization: user().token,
            },
        });
        dispatch({
            payload: response.data,
            type: GET_EMPLOYEE_BY_ID,
        });
    };
}

export function createEmployee(employee: any) {
    return async (dispatch: IDispatch<any>) => {
        try {
            const response = await axios.post(
                `${API_URL}/employees.json`,
                {
                    employee,
                },
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                payload: response.data,
                type: CREATE_EMPLOYEE,
            });
        } catch (err) {
            throw err;
        }
    };
}

export function updateEmployee(employee: any) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.patch(
            `${API_URL}/employees/${employee.id}.json`,
            {
                employee,
            },
            {
                headers: {
                    authorization: user().token,
                },
            }
        );
        dispatch({
            payload: response.data,
            type: UPDATE_EMPLOYEE,
        });
    };
}

export const fetchEmployees = () => {
    return async (dispatch: IDispatch<any>) => {
        return await axios
            .get(`${API_URL}/employees.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_EMPLOYEES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};
