import { getFolderLocation } from "@rpforms/shared";
import { SmallButton, SmallPrimaryButton } from "@rpforms/shared/build/components/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import { push } from "connected-react-router";
import React from "react";
import { Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ListRow } from "../forms/FormListRow";

export const CustomerName = styled.div`
    font-weight: bold;
`;

const CustomerListRow = ({ customer }: any) => {
    const dispatch = useDispatch();
    return (
        <ListRow>
            <CustomerName>
                {customer.name}
                {customer.terms &&
                    customer.terms.map((term) => {
                        return (
                            <Badge
                                key={`${customer.id}_${term.id}`}
                                style={{
                                    backgroundColor: term.options.backgroundColor,
                                    color: term.options.color,
                                }}
                            >
                                {term.name}
                            </Badge>
                        );
                    })}
            </CustomerName>
            <RightAligned>
                <SmallPrimaryButton
                    onClick={async () => {
                        const req = await dispatch(
                            getFolderLocation({
                                resource_id: customer.id,
                                resource_type: "Customer",
                            })
                        );
                        dispatch(
                            push("/folders?open=" + encodeURIComponent(JSON.stringify(req.data)))
                        );
                    }}
                >
                    <i className="fa fa-folder-open" /> Ordnerstruktur
                </SmallPrimaryButton>
                <Link to={`/master_data/customers/view-${customer.id}`}>
                    <SmallButton>Ansehen</SmallButton>
                </Link>
            </RightAligned>
        </ListRow>
    );
};

export default withRouter(CustomerListRow);
