import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import {
    ADD_FOLDER_TO_SELECTION,
    ADD_FOLDERS,
    CLEAR_FOLDERS,
    FOLDERS_LOADING_START,
    FOLDERS_LOADING_STOP,
    FOLDERS_SUBTREE_LOADING_START,
    FOLDERS_SUBTREE_LOADING_STOP,
    FOLDERS_TREE_LOADING_START,
    FOLDERS_TREE_LOADING_STOP,
    REMOVE_FOLDER_FROM_SELECTION,
    SEARCH_FOLDERS,
    SEARCH_FOLDERS_LOADING_START,
    SEARCH_FOLDERS_LOADING_STOP,
    SET_DIRECTORY,
    SET_FOLDER_SELECTION,
    SET_FOLDER_SELECTION_ITEMS,
    SET_SUBTREE,
    SET_TEMPLATES,
    SET_TREE,
} from "./types";

export function setFolderSelection(state: boolean) {
    return async (dispatch: IDispatch<any>) => {
        dispatch({
            type: SET_FOLDER_SELECTION,
            payload: state,
        });
    };
}

export function setFolderSelectionItems(items: any) {
    return async (dispatch: IDispatch<any>) => {
        dispatch({
            type: SET_FOLDER_SELECTION_ITEMS,
            payload: items,
        });
    };
}

export function addFolderToSelection(folder: any) {
    return async (dispatch: IDispatch<any>) => {
        dispatch({
            type: ADD_FOLDER_TO_SELECTION,
            payload: folder,
        });
    };
}

export function moveFolders(options: {
    folder_ids: [any];
    parent_folder_id?: number; // target
    parent_resources?: Array<{ id: number; type: string }>; // either parent_folder or parent_resource
}) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.post(
            `${API_URL}/companies/${COMPANY_ID}/directory/folders/move`,
            options,
            {
                headers: { authorization: user().token },
            }
        );

        return response.data;
    };
}

export function mergeFolders(options: {
    folder_ids: [any];
    target_folder_id?: number; // target
    name: string;
}) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.post(
            `${API_URL}/companies/${COMPANY_ID}/directory/folders/merge`,
            options,
            {
                headers: { authorization: user().token },
            }
        );

        return response.data;
    };
}

export function removeFolderFromSelection(folder: any) {
    return async (dispatch: IDispatch<any>) => {
        dispatch({
            type: REMOVE_FOLDER_FROM_SELECTION,
            payload: folder,
        });
    };
}

export function fetchDirectory() {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        try {
            dispatch({
                type: FOLDERS_LOADING_START,
            });
            const response = await axios.get(`${API_URL}/companies/${COMPANY_ID}/directory.json`, {
                headers: { authorization: user().token },
            });
            dispatch({
                type: SET_DIRECTORY,
                payload: response.data,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: FOLDERS_LOADING_STOP,
            });
        }
    };
}

export function fetchDirectoryTree(folderId, parentFoldersUntil) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        try {
            dispatch({
                type: folderId ? FOLDERS_SUBTREE_LOADING_START : FOLDERS_TREE_LOADING_START,
            });
            const response = await axios.get(
                `${API_URL}/companies/${COMPANY_ID}/directory/tree.json?folder_id=${folderId}&parent_folders_until=${parentFoldersUntil}`,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: folderId ? SET_SUBTREE : SET_TREE,
                payload: response.data,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: folderId ? FOLDERS_SUBTREE_LOADING_STOP : FOLDERS_TREE_LOADING_STOP,
            });
        }
    };
}

export function createFolder(folder: any) {
    return async (dispatch: IDispatch<IReduxAction<any[]>>) => {
        try {
            dispatch({
                type: FOLDERS_LOADING_START,
            });
            const response = await axios.post(
                `${API_URL}/companies/${COMPANY_ID}/directory/folders.json`,
                {
                    folder,
                },
                {
                    headers: {
                        authorization: user().token,
                    },
                }
            );
            dispatch({
                type: ADD_FOLDERS,
                payload: [response.data],
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: FOLDERS_LOADING_STOP,
            });
        }
    };
}

export function updateFolder(folder: any) {
    return async (dispatch: IDispatch<IReduxAction<any[]>>) => {
        try {
            dispatch({
                type: FOLDERS_LOADING_START,
            });
            const response = await axios.patch(
                `${API_URL}/companies/${COMPANY_ID}/directory/folders/${folder.id}.json`,
                {
                    folder,
                },
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: ADD_FOLDERS,
                payload: [response.data],
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: FOLDERS_LOADING_STOP,
            });
        }
    };
}

export function fetchTemplates() {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        try {
            const response = await axios.get(
                `${API_URL}/companies/${COMPANY_ID}/directory/folders.json?startWith=\\_`,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: SET_TEMPLATES,
                payload: response.data,
            });
        } catch (err) {
            throw err;
        }
    };
}

export function searchFolder(query: string) {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({
            payload: null,
            type: SEARCH_FOLDERS_LOADING_START,
        });
        try {
            const response = await axios.get(
                `${API_URL}/companies/${COMPANY_ID}/directory/folders.json?${query}`,
                {
                    headers: { authorization: user().token },
                }
            );

            dispatch({
                type: SEARCH_FOLDERS,
                payload: response.data,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: SEARCH_FOLDERS_LOADING_STOP,
                payload: null,
            });
        }
    };
}

export function getFolderLocation({ resource_id, resource_type }: any) {
    return async () => {
        return axios.get(
            `${API_URL}/companies/${COMPANY_ID}/directory/folders/path.json?resource_id=${resource_id}&resource_type=${resource_type}`,
            {
                headers: { authorization: user().token },
            }
        );
    };
}

export function fetchFolders({ id, scope, query }: any) {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        const queryParams = id ? `parentId=${id}` : "";
        try {
            dispatch({
                type: FOLDERS_LOADING_START,
            });
            const response = await axios.get(
                `${API_URL}/companies/${COMPANY_ID}/directory/folders.json?scope=${
                    scope ?? "folder"
                }&query=${query ?? ""}&${queryParams}`,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: ADD_FOLDERS,
                payload: response.data,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: FOLDERS_LOADING_STOP,
            });
        }
    };
}
export function clearFolders() {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({
            type: CLEAR_FOLDERS,
            payload: [],
        });
    };
}

export function deleteFolder(id: any, cb = (data) => {}) {
    return () =>
        axios
            .delete(`${API_URL}/folders/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                if (cb) {
                    cb(response);
                }
            })
            .catch((error) => {
                window.alert(error.response.data.message);
            });
}
