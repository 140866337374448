import React from "react";
import { uniqueID } from "../../utils";

export const Switch = (props) => {
    const toCamelCase = function (x) {
        return x.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
            if (p2) {
                return p2.toUpperCase();
            }
            return p1.toLowerCase();
        });
    };

    const { className, label, noText } = props;
    const switchClass = className;
    const id = `${props.idPrefix || ""}checkbox_${uniqueID()}`;
    const onLabel = props.onLabel || "AN";
    const offLabel = props.offLabel || "AUS";
    const name = props.hashCode || id;
    const checked = props.checked;
    const readOnly = props.readOnly;

    const wrapInput = props.wrapInput ? props.wrapInput : (i) => i;
    const wrapInputArgs = props.wrapInputArgs ? props.wrapInputArgs : [];

    return (
        <div aria-label={label} className={switchClass}>
            <label className="switch__label" htmlFor={id}>
                {wrapInput(
                    <input
                        role="switch"
                        type="checkbox"
                        className="switch__input"
                        name={name}
                        id={id}
                        defaultChecked={checked}
                        disabled={readOnly}
                    />,
                    ...wrapInputArgs
                )}
                <span className="switch__text" data-on={onLabel} data-off={offLabel} />
                <span className="switch__handle" />
            </label>
        </div>
    );
};
