import moment from "moment";
import React, { FormEvent, useState } from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { DefaultValue, Description, Hint, Required, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { NonMigratable } from "./configurables/NonMigratable";
import { NonTemplatable } from "./configurables/NonTemplatable";
import { Label, LabelInput } from "./inputFields/label";
import { TextField } from "./TextField";

moment.locale("de");

export class DateField extends TextField {
    public icon = "calendar";
    public enableTimePicker = true;
    public enableDatePicker = true;
    public enableDateRange = false;
    public fieldType = "Datum";
    public state;
    public setState;
    public attributeNames = [
        "enableDatePicker",
        "overwriteDate",
        "enableDateRange",
        "onlyFutureDates",
    ];

    public getConfigurables() {
        return [
            Conditional,
            Required,
            Description,
            DefaultValue,
            Width,
            Hint,
            NonMigratable,
            NonTemplatable,
        ];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <LabelInput register={register} field={field} />
                    </div>
                </div>
                <div className="powerform-flex mt-3">
                    <div className="powerform-w100">
                        <label>Einstellungen</label>
                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("enableDateRange")}
                                    className="form-control"
                                    defaultChecked={field.enableDateRange}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Zeitspanne auswählen</label>
                                <div className="text-muted">
                                    Soll der Benutzer eine Zeitspanne auswählen können?
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("overwriteDate")}
                                    className="form-control"
                                    defaultChecked={field.overwriteDate}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Datum auf iPad überschreiben</label>
                                <div className="text-muted">
                                    Soll das Datumsfeld beim Zurücksenden zum iPad automatisch
                                    überschrieben werden?
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("enableDatePicker")}
                                    className="form-control"
                                    defaultChecked={field.enableDatePicker}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Datumsauswahl nutzen</label>
                                <div className="text-muted">
                                    Soll der Benutzer einen Datumspicker angezeigt bekommen um das
                                    Datum auszuwählen?
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("onlyFutureDates")}
                                    className="form-control"
                                    defaultChecked={field.onlyFutureDates}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Muss in der Zukunft liegen</label>
                                <div className="text-muted">
                                    Klicken Sie diese Box an um zu versichern dass lediglich
                                    zukünftige Tage ausgewählt werden können
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
        rawValue,
        readonly = false,
    }: any): any {
        const [focused, setFocused] = useState();
        const [date, setDate] = useState();
        const [endDate, setEndDate] = useState();
        const required = field.rawOptions.required;
        if (rawValue && !(window as any).EDIT_MODE && typeof rawValue !== "object") {
            const parsed = moment(rawValue).format("DD.MM.YYYY");
            return (
                <>
                    <Label layout={layout} field={field} />
                    {field.rawOptions.description ? (
                        layout.wrapDescription(<p>{field.rawOptions.description}</p>)
                    ) : (
                        <></>
                    )}
                    <p
                        style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                        }}
                    >
                        {parsed || rawValue}
                        <input
                            type={"hidden"}
                            name={field.hashCode}
                            className="form-control"
                            defaultValue={rawValue}
                        />
                    </p>
                </>
            );
        }

        const local = new Date();
        local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
        const defaultDate = local.toJSON().slice(0, 10);

        return layout.wrapComponent(
            <div className={layout.wrapComponentCSS(field.configureCSS())}>
                {layout.wrapFieldset(
                    <>
                        <Label layout={layout} field={field} />
                        {field.rawOptions.description ? (
                            layout.wrapDescription(<p>{field.rawOptions.description}</p>)
                        ) : (
                            <></>
                        )}
                        {field.configureInput(
                            <input
                                readOnly={readonly}
                                type={"date"}
                                required={required}
                                name={field.hashCode}
                                className="form-control"
                                onInput={(v) => setDate((v.target as any).value)}
                                defaultValue={rawValue || date || defaultDate}
                            />
                        )}
                        {layout.wrapHint(
                            field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>
                        )}
                        {!readonly && (
                            <button
                                className={"hidden-print"}
                                onClick={(ev: FormEvent<HTMLButtonElement>) => {
                                    ev.currentTarget.parentElement.querySelector(
                                        "input"
                                    ).value = moment(new Date()).format("YYYY-MM-DD");
                                    setDate(moment(new Date()).format("YYYY-MM-DD") as any);
                                }}
                            >
                                Auf Heute setzen
                            </button>
                        )}
                    </>
                )}
            </div>
        );
    }
}
