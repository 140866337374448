import { createPdf } from "@rpforms/shared/build/actions/employees";
import {
    SmallButton,
    SmallDangerButton,
    SmallSuccessButton,
    SmallPrimaryButton,
} from "@rpforms/shared/build/components/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import React from "react";
import { Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ListRow } from "../forms/FormListRow";

export const EmployeeName = styled.div`
    font-weight: bold;
`;

const EmployeeListRow = ({ employee }: any) => {
    const employeeId = employee.id;
    const dispatch = useDispatch();

    const createPdfClick = () => {
        dispatch(createPdf(employee.id));
    };

    return (
        <ListRow>
            <EmployeeName>
                <Badge pill={true} variant={"primary"}>
                    ID {employeeId}
                </Badge>
                <span className={"pl-2"}>
                    {employee.name} (Noch verfügbarer Urlaub: {employee.free_vacation_days}/
                    {employee.vacation_days}){" "}
                    {employee.residual_vacation_days
                        ? ` (Hat Resturlaub: ${employee.residual_vacation_days} ${
                              employee.residual_vacation_days === 1 ? "Tag" : "Tage"
                          })`
                        : ""}
                </span>
            </EmployeeName>
            <RightAligned>
                <SmallButton onClick={createPdfClick}>
                    <i className="fa fa-file-pdf-o"></i> Als PDF exportieren
                </SmallButton>
                <Link to={`/master_data/employees/view-${employeeId}`}>
                    <SmallButton>Ansehen</SmallButton>
                </Link>
                <Link to={`/master_data/employees/schedule-${employeeId}`}>
                    {employee.checkedIn && <SmallSuccessButton>Zeiterfassung</SmallSuccessButton>}
                    {!employee.checkedIn && <SmallDangerButton>Zeiterfassung</SmallDangerButton>}
                </Link>
            </RightAligned>
        </ListRow>
    );
};

export default withRouter(EmployeeListRow);
