import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
export default function(ComposedComponent) {
    const Authentication = (props) => {
        if (props.authenticated) {
            props.history.push("/");
        }

        return <ComposedComponent {...props} />;
    };

    function mapStateToProps(state) {
        return { authenticated: state.auth.authenticated };
    }

    return withRouter(connect(mapStateToProps)(Authentication));
}
