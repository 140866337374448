import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useSearchParam } from "react-use";
// import { fetchAllFormEntries } from "@rpforms/shared";
import styled from "styled-components";

const CheckBoxContainer = styled.div`
    position: absolute;
    left: 0;
    margin-top: 2px;
    margin-left: 15px;
    color: #dc3545;
    display: flex;
    align-items: center;

    label {
        font-size: 0.8rem;
        display: inline-block;
        margin: 0;
        margin-left: 0.5rem;
        cursor: pointer;
    }

    input {
        cursor: pointer;
    }
`;

export default function NotificationsFilter({ value = false, onCheck, ...props }) {
    const onChange = (option) => {
        onCheck(option);
    };

    return (
        <CheckBoxContainer>
            <input
                type="checkbox"
                name="filter"
                id="filter"
                onChange={onChange}
                checked={value}
                {...props}
            />
            <label htmlFor="filter">Alle Benachrichtigungen anzeigen</label>
        </CheckBoxContainer>
    );
}
