import { getCalendarEventTypes } from "@rpforms/shared/build/actions/calendar_event_types";
import { SmallButton } from "@rpforms/shared/build/components/Button";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import { PageNavbar, RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CalendarEventTypeModal from "../../components/calendar/CalendarEventTypeModal";
import { ListRow } from "../../components/forms/FormListRow";
import { RootState } from "../../reducers";

const CalendarEventTypesPage = () => {
    const types = useSelector<RootState, any>((store) => store.calendarEventTypes.types);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const editModal = (type) => {
        setSelectedType(type);
        setModalVisible(true);
    };
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCalendarEventTypes());
    }, []);

    return (
        <div>
            <PageNavbar
                title="Eventtypen"
                description="Hier können Sie die Kalendareventtypen verwalten."
            >
                <PageNavAction
                    onClick={() => setModalVisible(true)}
                    primary={true}
                    icon="plus"
                    title="Neuer Typ"
                />
            </PageNavbar>
            <CalendarEventTypeModal
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                type={selectedType}
                setType={setSelectedType}
            />
            <Content>
                {types.map((type) => (
                    <ListRow key={type.id}>
                        <span
                            style={{
                                width: 30,
                                height: 30,
                                backgroundColor: type.color,
                                borderRadius: "5px",
                            }}
                        />
                        <div className="font-weight-bold ml-3">{type.name}</div>
                        <RightAligned>
                            <SmallButton onClick={() => editModal(type)}>Bearbeiten</SmallButton>
                        </RightAligned>
                    </ListRow>
                ))}
            </Content>
        </div>
    );
};
export default withRouter(CalendarEventTypesPage);
