import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { AppearanceBox } from "../fields/configurables/fields/AppearanceBox";
import { AppearanceDescription } from "../fields/configurables/fields/AppearanceDescription";
import { AppearanceLabel } from "../fields/configurables/fields/AppearanceLabel";
import { EditHeader } from "./EditHeader";
import { FieldAppearance } from "./FieldAppearance";
import FieldCreator from "./FieldCreator";

const ModalWrapper = styled(Modal)`
    .modal-dialog {
        max-width: 62vw;
    }
`;

const SiblingCard = styled.div`
    display: flex;
`;

const DynamicCard = styled.div`
    position: relative;
    width: 100%;
    .card {
        width: 100%;
    }
    display: flex;
`;

export const FieldWrapper = ({
    background = "#fff",
    small = false,
    register = () => {},
    designer,
    schema,
    field,
    children,
}: any) => {
    const [collapsed, setCollapsed] = useState(true);
    const [showModal, toggle]: any = useState();
    const [showFieldCreator, toggleFieldCreator] = useState<any>();

    const fieldAppearance = useRef();

    const handleTrash = () => {
        schema.remove(field);
        designer.save(schema, { silent: true });
    };

    const handleCopy = () => {
        schema.duplicate(field);
        designer.save(schema, { silent: true });
    };

    const handleMoveDown = () => {
        schema.moveFieldUp(field);
        designer.save(schema, { silent: true });
    };

    const handleSave = () => {
        if (document.querySelector("#formSaveBtn")) {
            (document.querySelector("#formSaveBtn") as any).click();
        }
    };

    const handleMoveUp = () => {
        schema.moveFieldDown(field);
        designer.save(schema, { silent: true });
    };

    if (showModal) {
        document.body.classList.add("modal-open");
    } else {
        document.body.classList.remove("modal-open");
    }

    let fieldBodyClass = collapsed ? "field-body field-body--collapsed" : "field-body";
    if (!children || children.length == 0) {
        fieldBodyClass = collapsed ? "field-body field-body--collapsed" : "field-children";
    }

    return (
        <SiblingCard className={`rpfe-field-${field.hashCode} rpfe-${small ? "small" : "regular"}`}>
            <DynamicCard>
                <div className="mt-2 card">
                    <EditHeader
                        handleSave={handleSave}
                        handleCreateField={() => toggleFieldCreator(!showFieldCreator)}
                        handleCopy={handleCopy}
                        handleMoveDown={handleMoveDown}
                        handleMoveUp={handleMoveUp}
                        collapsed={collapsed}
                        handleCollapse={() => {
                            schema.setFieldOption(field, "collapsed", !collapsed);
                            designer.save(schema, { delaySave: false, notify: false });
                            setCollapsed(!collapsed);
                        }}
                        handleSettings={() => toggle(!showModal)}
                        handleTrash={handleTrash}
                        field={field}
                    ></EditHeader>
                    <div
                        className={fieldBodyClass}
                        style={{ padding: "20px", paddingTop: 0, backgroundColor: background }}
                    >
                        {children}
                    </div>
                    <ModalWrapper
                        show={showModal}
                        hide={() => toggle(0)}
                        title={`Einstellungen`}
                        backdrop="static"
                        keyboard={false}
                    >
                        <>
                            <div style={{ padding: "15px" }}>
                                <AppearanceBox>
                                    <AppearanceLabel>Identifier / Name</AppearanceLabel>
                                    <AppearanceDescription>
                                        Setzen Sie hier einen einfachen Namen um das Feld später in
                                        Textblöcken oder Script Feldern anzusprechen.
                                    </AppearanceDescription>
                                    <input
                                        className="form-control"
                                        defaultValue={field.hashCode}
                                        type="text"
                                        onChange={(evt) =>
                                            schema.setHashCode(field, evt.target.value)
                                        }
                                        ref={register}
                                        name={"hashCode"}
                                    />
                                </AppearanceBox>
                            </div>
                            <FieldAppearance
                                ref={fieldAppearance}
                                hide={() => toggle(0)}
                                designer={designer}
                                field={field}
                                schema={schema}
                            />
                        </>
                    </ModalWrapper>

                    <Modal
                        show={showFieldCreator}
                        hide={() => toggleFieldCreator(0)}
                        title={`Als neues Feld speichern`}
                        backdrop="static"
                        keyboard={false}
                    >
                        <FieldCreator fieldData={field} hide={() => toggleFieldCreator(0)} />
                    </Modal>
                </div>
            </DynamicCard>
        </SiblingCard>
    );
};
