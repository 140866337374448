import { AUTH_USER, SIGNIN_FAILURE, UNAUTH_USER } from "../actions/types";

export default function(state = {}, action: IReduxAction<any>) {
    switch (action.type) {
        case SIGNIN_FAILURE:
            return { ...state, error: { signin: action.payload } };
        case AUTH_USER:
            return { ...state, authenticated: true, error: {} };
        case UNAUTH_USER:
            return { ...state, authenticated: false, error: {} };
    }

    return state;
}
