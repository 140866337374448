import { NEW_INVENTORY_ITEM } from "@rpforms/shared/build/actions";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-use";
import EditInventoryItemPage from "./EditInventoryItemPage";
import EditServicePositionPage from "./EditServicePositionPage";
import NewInventoryItemPage from "./NewInventoryItemPage";
import NewServicePositionPage from "./NewServicePositionPage";
import TableDisposalItemsPage from "./TableDisposalItemsPage";
import TableInventoryItemPage from "./TableInventoryItemsPage";
import TableServicePositionsPage from "./TableServicePositionsPage";

export const InventoryItemsPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const newInventoryItem = (inventoryType = "repair") => {
        dispatch(
            openEditor({
                endpoint: `inventory_items`,
                endpointCompany: false,
                new: true,
                preload: { inventory_type: inventoryType },
                type: NEW_INVENTORY_ITEM,
            })
        );
    };

    const type = location.pathname.split("/").pop();

    return (
        <>
            <Switch>
                <Route path="/settings/protocols/forms/edit-:id"></Route>
                <Route path="/settings/protocols/forms/preview-:id"></Route>
                <Route>
                    <PageNavbar
                        title="Leistungsverzeichnis"
                        description="Hier können Sie Ihre Leistungen verwalten."
                    >
                        <Tab.Container defaultActiveKey={type}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="repair" href="/inventory_items/repair">
                                        Reparatur
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="disposal" href="/inventory_items/disposal">
                                        Verkauf
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="service" href="/inventory_items/service">
                                        Service
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Tab.Container>
                        <PageNavAction
                            onClick={() => newInventoryItem(type)}
                            primary={true}
                            icon="plus"
                            title="Hinzufügen"
                        />
                    </PageNavbar>
                </Route>
            </Switch>
            <Switch>
                <Route path="/inventory_items/repair" exact component={TableInventoryItemPage} />
                <Route path="/inventory_items/repair/new" component={NewInventoryItemPage} />
                <Route path="/inventory_items/repair/edit-:id" component={EditInventoryItemPage} />
                <Route
                    path="/inventory_items/service"
                    exact
                    component={TableServicePositionsPage}
                />
                <Route path="/inventory_items/service/new" component={NewServicePositionPage} />
                <Route
                    path="/inventory_items/service/edit-:id"
                    component={EditServicePositionPage}
                />
                <Route path="/inventory_items/disposal" exact component={TableDisposalItemsPage} />
                <Route path="/inventory_items/disposal/new" component={NewServicePositionPage} />
                <Route
                    path="/inventory_items/disposal/edit-:id"
                    component={EditServicePositionPage}
                />
            </Switch>
        </>
    );
};

export default InventoryItemsPage;
