import { ADD_FOLDERS, UPDATE_FOLDER_NOTE } from "@rpforms/shared";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { default as React } from "react";
import { Folder } from "./Folder";
import { useDispatch } from "react-redux";

export const FolderNote = ({ note }) => {
    const dispatch = useDispatch();

    const openFolderNoteEditor = () => {
        dispatch(
            openEditor({
                endpoint: `folder_notes`,
                endpointCompany: false,
                new: false,
                preload: { note, id: note.id },
                type: UPDATE_FOLDER_NOTE,
            })
        );
    };

    return (
        <Folder
            activeFolder={false}
            onMove={() => {}}
            key={`child-note`}
            isSelected={false}
            onClick={openFolderNoteEditor}
            isNote={true}
            folder={{
                name: note.title,
                id: "Note-" + note.id,
                parent_id: note.folder_id,
                resource_name: "Notiz",
                resource_type: "Upload",
                resource: {
                    id: "Note-" + note.id,
                },
            }}
            movable={false}
        />
    );
};
