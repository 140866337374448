import { Field } from "../Field";
import AddField from "../AddField";
import React from "react";
import useForm from "react-hook-form";
import { SuccessButton } from "@rpforms/shared/build/components/Button";

export const CustomFieldDesigner = ({ designer, schema, handleFieldSave }) => {
    const { handleSubmit, register, errors }: any = useForm();
    const onSubmit = (values) => {
        handleFieldSave(values);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {schema.fields().map((field, index) => {
                return (
                    <div key={field.hashCode}>
                        <Field
                            Component={field.EditComponent}
                            field={field}
                            register={register}
                            extraProps={{ schema, designer }}
                        />
                        {index < schema.fields().length - 1 && (
                            <AddField
                                showCustomFields={true}
                                attached={true}
                                small={true}
                                after={field}
                                handleNewField={designer.newFieldBelow(field).bind(designer)}
                            />
                        )}
                    </div>
                );
            })}
            <div className="row ml-2">
                <SuccessButton success={true} type="submit">
                    <i className="fa fa-save"></i> Änderungen speichern
                </SuccessButton>
                <AddField
                    showCustomFields={true}
                    handleNewField={designer.newField.bind(designer)}
                />
            </div>
        </form>
    );
};

export default CustomFieldDesigner;
