import { moveEvents } from "@rpforms/shared";
import { batchDelete } from "@rpforms/shared/build/actions/actions_helper";
import { getCalendarEventTypes } from "@rpforms/shared/build/actions/calendar_event_types";
import { getCalendarEvents } from "@rpforms/shared/build/actions/calendar_events";
import { PrimaryButton, SmallButton } from "@rpforms/shared/build/components/layout/Button";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DeviceSelector } from "../DeviceSelector";

const CalendarListRow = styled.div`
    padding: 5px 0px;
    margin: 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    font-size: 16px !important;
    width: 100%;
    // align svg icons with fonts
    svg {
        position: relative;
        top: -2px;
    }
`;

export const ListCalendarEvents = ({ device, calendarEvents }) => {
    const [selectedCalendarEventIds, setSelectedCalendarEventIds] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [moveModalVisibility, setMoveModalVisibility] = useState(false);
    const [moveModalLoading, setMoveModalLoading] = useState(false);
    const [targetDevice, setTargetDevice] = useState(null);
    const types = useSelector<any, any>((store) => store.calendarEventTypes.types);
    useEffect(() => {
        dispatch(getCalendarEventTypes());
    }, []);

    const dispatch = useDispatch();

    const deleteSelectedTasks = () => {
        dispatch(
            batchDelete("calendar_events", selectedCalendarEventIds, () => {
                dispatch(getCalendarEvents(device));
                window.location.reload();
            })
        );
        setSelectedCalendarEventIds([]);
    };

    const selectAll = () => {
        if (!allSelected) {
            setAllSelected(true);
            setSelectedCalendarEventIds(calendarEvents.map((event) => event.id));
        } else {
            setAllSelected(false);
            setSelectedCalendarEventIds([]);
        }
    };

    const toggleDeviceTaskId = (event) => {
        const tId = event.target.value;
        if (!tId) {
            const checkbox = event.target.querySelector('input[type="checkbox"]');
            if (checkbox) {
                checkbox.click();
            }
            return;
        }
        const deviceTaskId = parseInt(tId, 10);
        if (!selectedCalendarEventIds.includes(deviceTaskId)) {
            setSelectedCalendarEventIds(selectedCalendarEventIds.concat([deviceTaskId]));
        } else {
            setSelectedCalendarEventIds(selectedCalendarEventIds.filter((a) => a !== deviceTaskId));
        }
    };

    const moveEventsToDevice = async () => {
        if (targetDevice && selectedCalendarEventIds.length > 0) {
            setMoveModalLoading(true);
            await dispatch(
                moveEvents({
                    ids: selectedCalendarEventIds,
                    device_id: targetDevice.value,
                })
            );
            setTimeout(() => {
                setMoveModalLoading(false);
                setMoveModalVisibility(false);
                window.location.reload(true);
            }, 1000);
        }
    };

    const events = calendarEvents.map((event) => {
        const type = types.find((t) => t.id === event.calendar_event_type_id);
        const startDate = moment(event.scheduled_from).format("DD.MM.YYYY");
        const endDate = moment(event.scheduled_to).format("DD.MM.YYYY");
        const name = event.name ? event.name : "";
        const classNames = [];
        if (event.is_due && event.was_submitted) {
            classNames.push("device_task--due");
        }

        return (
            <CalendarListRow key={event.id}>
                <Form.Check type="checkbox" id={"deviceTask-" + event.id}>
                    <Form.Check.Input
                        checked={selectedCalendarEventIds.includes(event.id)}
                        type="checkbox"
                        value={event.id}
                        onChange={toggleDeviceTaskId}
                    />
                    <Form.Check.Label className={"mt-0"}>
                        <span className={classNames.join(" ")}>
                            {event.was_submitted && <span className={"fa fa-check"}></span>}{" "}
                            {event.calendar_event_type_id === 5 && (
                                <span className={"fa fa-repeat"}></span>
                            )}
                            {event.title}{" "}
                            {event.entity && (
                                <span>
                                    <i className={"fa fa-home"}></i> {event.entity.name}
                                </span>
                            )}{" "}
                            {name}{" "}
                            {event.scheduled_from
                                ? `(${startDate} - ${endDate})`
                                : "(Kein Zeitraum)"}
                        </span>
                    </Form.Check.Label>
                </Form.Check>
                {event.calendar_event_type_id !== 5 && (
                    <Link
                        to={
                            type?.name !== "Ausführung"
                                ? `/calendar/event/${event.id}`
                                : `/master_data/devices/view-${event.device_id}/event-${event.id}`
                        }
                    >
                        <SmallButton>Ansehen</SmallButton>
                    </Link>
                )}
            </CalendarListRow>
        );
    });

    return (
        <>
            <br />
            <Form.Check type="checkbox" id={"formEntry-all"}>
                <Form.Check.Input type="checkbox" onChange={selectAll} />
                <Form.Check.Label className={"mt-0"}>
                    <strong>alle auswählen</strong>
                </Form.Check.Label>
            </Form.Check>
            {events}
            <div>
                <PrimaryButton
                    onClick={deleteSelectedTasks}
                    disabled={selectedCalendarEventIds.length <= 0}
                >
                    {selectedCalendarEventIds.length > 1 ? "Events löschen" : "Event löschen"}
                </PrimaryButton>
                <PrimaryButton
                    style={{ marginLeft: "10px" }}
                    disabled={selectedCalendarEventIds.length <= 0}
                    onClick={() => {
                        if (selectedCalendarEventIds.length >= 1) {
                            setMoveModalVisibility(true);
                        }
                    }}
                >
                    Verschieben
                </PrimaryButton>
                <Modal
                    show={moveModalVisibility}
                    onHide={() => setMoveModalVisibility(false)}
                    size={"lg"}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <StyledModalTitle>
                            <div>
                                Verschiebe {selectedCalendarEventIds.length}{" "}
                                {selectedCalendarEventIds.length > 1 ? "Events" : "Event"}
                            </div>
                        </StyledModalTitle>
                    </Modal.Header>
                    <Modal.Body style={{ padding: 0 }}>
                        <Row style={{ padding: "10px 20px" }}>
                            <Col>
                                Verschoben werden:
                                <ul>
                                    {selectedCalendarEventIds.map((id) => {
                                        const e = calendarEvents.find((e) => e.id === id);
                                        return <li key={e.id}>{e.name}</li>;
                                    })}
                                </ul>
                            </Col>
                        </Row>
                        <Row style={{ padding: "20px" }}>
                            <Col>
                                <h3>Gerät</h3>
                                <DeviceSelector
                                    forceFetch={true}
                                    onSelect={(d) => {
                                        setTargetDevice(d);
                                    }}
                                />
                            </Col>
                        </Row>
                        {moveModalLoading && (
                            <Row>
                                <Col>
                                    <Loader />
                                </Col>
                            </Row>
                        )}
                        <div style={{ padding: "20px" }}>
                            <PrimaryButton
                                disabled={targetDevice == null}
                                onClick={moveEventsToDevice}
                            >
                                Verschieben
                            </PrimaryButton>
                            <br />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default ListCalendarEvents;
