import { useMemo } from "react";
import { ConfigField } from "../fields/ConfigField";

export const useFormConfig = (fields: any = []) => {
    const getFields = (prev: any[], cur: any) => {
        const children = cur.subfields.reduce(getFields, []);
        return [...prev, cur, ...children];
    };

    return useMemo(() => {
        const flattenedFields = fields.reduce(getFields, []);
        const configField = flattenedFields.find(
            (field: any) => field.type === "ConfigField" || field instanceof ConfigField
        );
        return configField || {};
    }, [fields]);
};
