/**
 * BaseField
 */
import React from "react";
import { uniqueID } from "../utils";
import { Configurable } from "./configurables/Configurable";

export class BaseField {
    public static icon() {
        const klaas = new this();
        return klaas.icon;
    }

    public static fieldType() {
        const klaas = new this();
        return klaas.fieldType;
    }
    public __fieldclass__;
    public hashCode;
    public name = "";
    public label = "";
    public formName = "";
    public subfields = [];
    public leftSiblings = [];
    public rightSiblings = [];

    public rawValue;
    public rawField;
    public rawOptions = {
        autofill: undefined,
    };
    public icon = "align-left";
    public type = "BaseField";
    public fieldType = "Basis Feld";
    public globalAttributes = ["text", "collapsed"];
    public attributeNames = [];

    // Stores appearance settings
    public options: any = {};
    private schema;

    constructor(field = null) {
        this.hashCode = uniqueID();
        if (field) {
            this.rawField = field;
            this.rawOptions = field.options;
        }
        this.type = this.name;
    }

    public toHotColumn() {
        return {};
    }

    public getIcon() {
        return this.icon;
    }

    /**
     * Returns the schema for the current field
     */
    public getSchema() {
        return this.schema;
    }

    public getConfigurables(): any[] {
        return [];
    }

    /**
     * WrapComponent
     */
    public WrapComponent({ layout, entry, schema, field, component }) {
        return layout.wrapComponent(
            field.configureComponent(
                <div id={`${field.hashCode}`} className={"rpfe-field" + " " + field.name}>
                    <div
                        className={
                            "rpfe-component " + layout.wrapComponentCSS(field.configureCSS())
                        }
                    >
                        {layout.wrapFieldset(component)}
                    </div>
                </div>
            )
        );
    }

    /**
     * Renders the field in the designer view
     * @constructor
     */
    public EditComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        designer,
        provided = null,
    }) {
        return <p>This component does not have any editing capabilities</p>;
    }

    /**
     * Renders the field so that data can be viewed by an admin
     */
    public PresenterComponent({ field }) {
        if (!field) {
            return <></>;
        }
        return (
            <div key={field.hashCode}>
                <strong>{field.label}:</strong>
                <span>{field.rawValue}</span>
                {field.subfields && field.subfields.length ? (
                    <>
                        <div className="pl-3 pr-3">
                            {field.subfields.map((subfield) => {
                                return (
                                    <div key={subfield.hashCode}>
                                        <subfield.PresenterComponent></subfield.PresenterComponent>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        );
    }

    /**
     * Allows fields to render themselves as PDF following the react-pdf spec
     * @param field
     * @constructor
     */
    public PDFComponent({ field }) {
        return <></>;
    }

    /**
     * Copies the given element
     */
    public clone() {
        const newElement = new (this.constructor as any)(this.rawField);
        return newElement.unserialize(this.serialize());
    }

    /**
     * renders the field when opened in the presenter
     * @constructor
     */
    public InputComponent({}) {
        return <p>This component does not have any editing capabilities</p>;
    }

    /**
     * Because input field names have to be unique, we're prefixing them with the fields hashCode.
     * @param name
     */
    public inputName(name) {
        return `fields.${this.hashCode}.${name}`;
    }

    public appearanceName(name) {
        return `${name}`;
    }

    /**
     * Prepare embedding into the powerform schemaBuilder
     */
    public serialize() {
        const type = this.constructor.name;
        const object = {
            type,
            name: this.name,
            hashCode: this.hashCode,
            label: this.label,
            subfields: this.subfields ? this.subfields.map((subfield) => subfield.serialize()) : [],
            leftSiblings: this.leftSiblings
                ? this.leftSiblings.map((sibling) => sibling.serialize())
                : [],
            rightSiblings: this.rightSiblings
                ? this.rightSiblings.map((sibling) => sibling.serialize())
                : [],
            options: this.serializeConfigurables(this.options),
            rawOptions: this.rawOptions,
        };

        [...this.globalAttributes, ...this.attributeNames].forEach((key) => {
            object[key] = this[key];
        });

        return object;
    }

    /**
     * Populate field data from a schemaBuilder payload
     * @param payload
     */
    public unserialize(payload) {
        try {
            for (const key of payload) {
                if (payload.hasOwnProperty(key)) {
                    this[key] = payload[key];
                }
            }
        } catch (e) {}
        this.hashCode = payload.hashCode;
        this.name = payload.name;
        this.label = payload.label;
        this.subfields = payload.subfields;
        this.leftSiblings = payload.leftSiblings;
        this.rightSiblings = payload.rightSiblings;
        this.options = this.loadConfigurables(payload.options);

        [...this.globalAttributes, ...this.attributeNames].forEach((key) => {
            if (payload.hasOwnProperty(key)) {
                this[key] = payload[key];
            }
        });

        return this;
    }

    /**
     * Embed a value (which could be any type) into a JSON object
     * Each field should specify how to store their value in a json doc.
     * For example: The PictureField holds a File object which by itself
     * cant be embedded into a JSON doc. We'd have to convert it into a base64 string.
     * @param value
     */
    public sleepValue(value) {
        return value;
    }

    /**
     * Reads a JSON provided value and transforms it into the original desired type.
     * This process of serialization of a single value is similar to the process of serializing the input fields from
     * the designer. Each field should override this method if they use types not present in the JSON spec.
     * @see sleepValue
     * @param value
     */
    public wakeValue(value) {
        return value;
    }

    public loadConfigurables(options = {}) {
        const configurables = this.getConfigurables();
        return configurables.map((configurable) => {
            const configurableField = new configurable({ field: this });
            if (options.hasOwnProperty(configurableField.keyName)) {
                configurableField.value = configurableField.wakeValue(
                    options[configurableField.keyName]
                );
            }
            return configurableField;
        });
    }

    public serializeConfigurables(options = []) {
        const serialized = {};
        // TODO: the logic of configurables is messy as they are reinitiated after each save
        // Therefore they loose their value. I hold onto the value in the rawOptions field instead.
        try {
            options?.forEach((configurable) => {
                serialized[configurable.keyName] = this.rawOptions[configurable.keyName];
            });
        } catch (e) {}

        return serialized;
    }

    /**
     * Runs the input through all configurables which can extend the input automatically
     * @param inputEl
     */
    public configureInput(inputEl, register, field) {
        let newInput = inputEl;
        if (field && !newInput.props.name) {
            newInput = React.cloneElement(
                newInput,
                {
                    name: field.hashCode,
                },
                newInput.props.children
            );
        }
        if (Array.isArray(this.options)) {
            (this.options as [Configurable]).forEach((option) => {
                newInput = option.configureInput(newInput, register);
            });
        }
        return newInput;
    }

    public wasRendered() {
        if (Array.isArray(this.options)) {
            (this.options as [Configurable]).forEach((option) => {
                option.fieldWasRendered();
            });
        }
    }

    /**
     * Allows configurables to hook into labels
     * @param labelEl
     * @param register
     */
    public configureLabel(labelEl, register) {
        let newInput = labelEl;
        if (Array.isArray(this.options)) {
            (this.options as [Configurable]).forEach((option) => {
                newInput = option.configureLabel(newInput, register);
            });
        }
        return newInput;
    }

    /**
     * method to apply a value to a field. each field has to define its own method to add data to.
     * @param value
     */
    public applyRawValue(value) {}

    /**
     * Allows configurables to hook into components
     * @param componentEl
     */
    public configureComponent(componentEl) {
        let newComponent = componentEl;
        if (Array.isArray(this.options)) {
            (this.options as [Configurable]).forEach((option) => {
                newComponent = option.configureComponent(newComponent);
            });
        }
        return newComponent;
    }

    /**
     * Sends CSS classes to each option to add custom classes based on configured configurables
     * @param css
     */
    public configureCSS(css) {
        if (!Array.isArray(this.options)) {
            return css;
        }
        if (Array.isArray(this.options)) {
            (this.options as [Configurable]).forEach((option) => {
                css = option.configureCSS(css);
            });
        }
        return css;
    }
}
