import React, { useEffect, useState } from "react";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { BoxCard, CardBody, CardTitle } from "@rpforms/shared/build/components/layout/Widget";
import { Button, Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeeById } from "@rpforms/shared/build/actions/employees";
import {
    createTimeTracking,
    deleteTimeTracking,
    getTimeTrackings,
    updateTimeTracking,
} from "@rpforms/shared/build/actions/timeTrackings";

import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment/moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { API_URL } from "../../../config";

const DnDCalendar = withDragAndDrop(Calendar);
export const ScheduleEmployeePage = () => {
    const [view, setView] = useState(Views.WORK_WEEK);
    const dispatch = useDispatch();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { employeeId }: { employeeId: string } = useParams();
    const localizer = momentLocalizer(moment);
    const [range, setRange] = useState({
        start: moment(+new Date())
            .startOf("week")
            .toDate(),
        end: moment(+new Date())
            .endOf("week")
            .toDate(),
    });
    const employee = useSelector<any, any>((state) =>
        state.employees.employees.find((e) => e.id === parseInt(employeeId, 10))
    );
    const timeTrackings = useSelector<any, any>((state) => state.timeTrackings.timeTrackings);

    useEffect(() => {
        console.log(range.start, range.end);
        dispatch(fetchEmployeeById(employeeId));
        dispatch(getTimeTrackings(employeeId, range, view));
    }, [view, range, employeeId]);

    const handleDeleteEvent = () => {
        dispatch(deleteTimeTracking(selectedEvent.id));
        setSelectedEvent(null);
    };
    const setNewRange = (l) => {
        if (l.start) {
            // if its in month view... why not.
            return setRange({
                start: moment(l.start).startOf("day").toDate(),
                end: moment(l.end).endOf("day").toDate(),
            });
        }
        setRange({
            start: moment(l[0]).startOf("day").toDate(),
            end: moment(l[l.length - 1])
                .endOf("day")
                .toDate(),
        });
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };

    const handleCreateEvent = (slot) => {
        if (slot.action !== "select") {
            return;
        }

        dispatch(
            createTimeTracking({
                employee_id: employeeId,
                from: slot.start,
                to: slot.end,
            })
        );
    };

    const toCalendarEvent = (event) => {
        return {
            status: event.status,
            id: event.id,
            title: `${
                ((+new Date(event.to) - +new Date(event.from)) / 1000 / 3600).toFixed(2) +
                " Stunden"
            } ${event.status === "open" ? "...... Aktiv" : ""}`,
            allDay: false,
            start: new Date(event.from),
            end: new Date(event.to),
        };
    };

    const handleNavigate = (event) => {
        if (view === "month") {
            setRange(() => ({
                start: event,
                end: event,
            }));
        }
    };

    const handleResizeEvent = (event) => {
        dispatch(
            updateTimeTracking({
                id: event.event.id,
                employee_id: employeeId,
                from: event.start,
                to: event.end,
            })
        );
    };

    const asRunningEvent = () => {
        dispatch(
            updateTimeTracking({
                id: selectedEvent.id,
                employee_id: employeeId,
                status: 4,
                from: selectedEvent.from,
                to: selectedEvent.to,
            })
        );
        setSelectedEvent(null);
    };

    const handleExport = () => {
        window.open(
            `${API_URL}/employees/${employeeId}/timetracking.pdf?view=${view}&start=${range.start}&end=${range.end}`
        );
    };

    const getEventStyle = (event) => {
        if (event.end.getHours() >= 17) {
            return {
                style: {
                    borderColor: "#934949",
                    backgroundColor: "#cb7777",
                },
            };
        }

        if (event.end.getHours() < 7) {
            return {
                style: {
                    borderColor: "#934949",
                    backgroundColor: "#cb7777",
                },
            };
        }
        if (event.status === "open") {
            return {
                style: {
                    borderColor: "#4c9349",
                    backgroundColor: "#7acb77",
                },
            };
        }
        return {
            style: {
                backgroundColor: selectedEvent?.id === event.id ? "#1b3864" : "#097fd3",
            },
        };
    };

    const total = timeTrackings
        .reduce((acc, event) => {
            return acc + (+new Date(event.to) - +new Date(event.from)) / 1000 / 3600;
        }, 0)
        .toFixed(2);

    const viewLabel = {
        work_week: "Woche",
        day: "Tag",
        month: "Monat",
    };

    return (
        <div>
            <PageNavbar title="Zeiterfassung" description="">
                <PageNavAction
                    title="Zum Urlaubskalender"
                    link="/calendar/vacations"
                    icon="calendar"
                />
                <PageNavAction
                    title="Zu allen Mitarbeitern"
                    link="/master_data/employees"
                    icon="list"
                />
            </PageNavbar>

            <Container style={{ paddingTop: 40 }}>
                {!employee && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Spinner animation={"grow"} />
                    </div>
                )}
                {employee && (
                    <BoxCard>
                        <CardTitle>
                            <div>
                                <i className={"fa fa-user"} /> {employee.name}
                            </div>
                            <div>
                                {total} Stunden ({viewLabel[view]})
                            </div>
                            <div style={{ gap: "4px", display: "flex" }}>
                                <Button onClick={handleExport} variant={"primary"}>
                                    <i className={"fa fa-file-pdf-o"} /> PDF
                                </Button>
                                {selectedEvent && (
                                    <>
                                        <Button onClick={handleDeleteEvent} variant={"danger"}>
                                            <i className={"fa fa-trash"} /> Löschen
                                        </Button>

                                        <Button onClick={asRunningEvent} variant={"success"}>
                                            Fortlaufender Eintrag
                                        </Button>
                                    </>
                                )}
                            </div>
                        </CardTitle>
                        <CardBody>
                            <DnDCalendar
                                onRangeChange={setNewRange}
                                localizer={localizer}
                                onView={setView}
                                views={["day", "work_week", "month"]}
                                defaultView={Views.WORK_WEEK}
                                onNavigate={handleNavigate}
                                events={timeTrackings.map(toCalendarEvent)}
                                onSelectEvent={handleSelectEvent}
                                onSelectSlot={handleCreateEvent}
                                onEventResize={handleResizeEvent}
                                onEventDrop={handleResizeEvent}
                                onDropFromOutside={handleResizeEvent}
                                onDragOver={handleResizeEvent}
                                draggableAccessor={(event) => true}
                                resizableAccessor={(event) => true}
                                selectable
                                step={1}
                                timeslots={30}
                                resizable
                                eventPropGetter={getEventStyle}
                                messages={{
                                    date: "Datum",
                                    time: "Zeit",
                                    month: "Monat",
                                    week: "Woche",
                                    day: "Tag",
                                    work_week: "Woche",
                                    today: "Heute",
                                    previous: "Zurück",
                                    next: "Weiter",
                                    noEventsInRange: "Es gibt keine Termine in diesem Zeitraum.",
                                }}
                            />
                        </CardBody>
                    </BoxCard>
                )}
            </Container>
        </div>
    );
};
