import { fetchCustomers, SET_SEARCH_RESULTS } from "@rpforms/shared";
import { search } from "@rpforms/shared/build/actions/search";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomerListRow from "../components/customers/CustomerListRow";
import EntityListRow from "../components/entities/EntityListRow";
import EntryRow from "../components/entries/EntryRow";
import InventoryItemTableRow from "../components/inventory_items/InventoryItemTableRow";
import { RootState } from "../reducers";

export const SearchPage = () => {
    const { query } = useParams();
    const dispatch = useDispatch();
    const searchResults = useSelector<RootState, any>((state) => state.search.results);
    const formEntries = useSelector<RootState, any>((state) => state.formEntries);
    const customers = useSelector<RootState, any>((state) => state.customers);

    const MIN_CHARS = 2;

    useEffect(() => {
        if (!customers.isLoading && customers.customers.length <= 0) {
            dispatch(fetchCustomers(1, () => {}));
        }
    }, []);

    // Query Backend
    useEffect(() => {
        if (validQuery()) {
            dispatch(search(query));
        } else {
            const ts = +new Date();
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: {
                    customers: [],
                    entities: [],
                    form_entries: [],
                    inventory_items: [],
                    timestamp: ts,
                },
            });
        }
    }, [query]);

    const decodedQuery = decodeURIComponent(query);

    const validQuery = () => {
        return query && query.length >= MIN_CHARS;
    };

    const description = () => {
        return validQuery() ? (
            <>
                Gefunden: {searchResults.customers.length} Kunden, {searchResults.entities.length}{" "}
                Objekte, {searchResults.form_entries.length} Protokolle
            </>
        ) : (
            <>bitte mindestens {MIN_CHARS} Zeichen eingeben</>
        );
    };

    const title = () => {
        return validQuery() ? `Suche nach: ${decodedQuery}` : <>Suche</>;
    };

    const entries = () => {
        if (!searchResults.form_entries) {
            return [];
        }
        return (searchResults.form_entries = searchResults.form_entries.map((entry) => {
            const storedFormEntry = formEntries.byId[`entry_${entry.id}`];
            if (storedFormEntry) {
                return storedFormEntry;
            }
            return entry;
        }));
    };

    return (
        <>
            <PageNavbar title={title()} description={description()} />
            {validQuery() && (
                <Content>
                    <h4>Kunden</h4>
                    {searchResults.customers.map((customer: ICustomer) => {
                        return <CustomerListRow key={customer.id} customer={customer} />;
                    })}
                    <hr />

                    <h4>Objekte</h4>
                    {searchResults.entities.map((entity: IEntity) => {
                        return (
                            <EntityListRow key={entity.id} entity={entity} deleteEntity={null} />
                        );
                    })}
                    <hr />
                    <h4>Protokolle</h4>
                    {entries().map((entry: any) => {
                        return <EntryRow key={entry.id} entry={entry} deleteFormEntry={null} />;
                    })}
                    <hr />
                    <h4>Leistungsverzeichnis</h4>
                    {searchResults.inventory_items.map((inventoryItem: any) => {
                        return (
                            <InventoryItemTableRow
                                key={inventoryItem.id}
                                inventoryItem={inventoryItem}
                                presentationType={"list-row"}
                            />
                        );
                    })}
                </Content>
            )}
        </>
    );
};

export default SearchPage;
