// @ts-ignore
import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";

(window as any).F = {
    fields: {
        get(hashCode) {
            return document.querySelector(`#${hashCode}`);
        },
    },
};

export class ScriptField extends BaseField {
    public label = "Skript";
    public icon = "code";
    public items = [];
    public code = "alert('Hello World');";
    public fieldType = "Skript";
    public attributeNames = ["code", "isActive"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Code</label>
                        <textarea
                            name={field.inputName("code")}
                            className={"form-control"}
                            defaultValue={field.code}
                            ref={register}
                        />
                    </div>
                </div>
                <div className={"text-muted"}>
                    Das Skript wird beim Öffnen des Formulars im Kontext des aktuellen Geräts
                    ausgeführt. Über die Variable <code>F</code> steht eine API zum Interagieren mit
                    dem Formular bereit. Beispiel: <br />
                    <code>
                        F.fieldAtIndex(2).update("New Value")
                        <br />
                        F.submit({`{ background: true }`});
                    </code>
                </div>

                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("isActive")}
                            className="form-control"
                            defaultChecked={field.isActive}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Skript aktiv</label>
                        <div className="text-muted">
                            Soll das Skript beim Laden des Formulars ausgeführt werden?
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
    }) {
        if (field.isActive) {
            try {
                // tslint:disable-next-line:function-constructor
                const fn = new Function(`
                    try {
                        ${field.code}
                    } catch (e){}
                `);
                fn();
            } catch (e) {
                console.error("Failed to execute code: ", field.code);
                console.error(field, e);
            }
        }
        return <span></span>;
    }

    public PresenterComponent({ field }) {
        return <span></span>;
    }

    public rawValueToOption(field, optionId) {
        const option = field.items.find((item) => {
            return item.id === optionId;
        });
        if (!option) {
            return "Keine Option ausgewählt.";
        }
        return option.name;
    }
}
