import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FieldWrapper } from "../components/FieldWrapper";
import { SignaturePad } from "../components/SignaturePad";
import { BaseField } from "./BaseField";
import { Description, Hint, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { Label, LabelInput } from "./inputFields/label";
import { API_URL } from "../config";

export class SignatureField extends BaseField {
    public icon = "edit";
    public fieldType = "Signatur";
    public attributeNames = ["overwriteSignature", "addStamp", "smallSignature"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Description, Width, Hint];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Titel</label>
                        <LabelInput register={register} field={field} />

                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("addStamp")}
                                    className="form-control"
                                    defaultChecked={field.addStamp}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Stempel hinzufügen</label>
                                <div className="text-muted">
                                    Soll der Firmenstempel automatisch über die Signatur gesetzt
                                    werden?
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("overwriteSignature")}
                                    className="form-control"
                                    defaultChecked={field.overwriteSignature}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Signatur beim Zurücksenden leeren?</label>
                                <div className="text-muted">
                                    Soll die bestehende Signatur beim Zurücksenden auf das iPad
                                    geleert werden?
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex mt-3 align-items-center">
                            <div style={{ width: "50px" }}>
                                <input
                                    type="checkbox"
                                    name={field.inputName("smallSignature")}
                                    className="form-control"
                                    defaultChecked={field.smallSignature}
                                    ref={register}
                                />
                            </div>
                            <div style={{ width: "100%" }}>
                                <label>Kleines Signaturfeld</label>
                                <div className="text-muted">
                                    Soll das Signaturfeld kleiner dargestellt werden? (300x150)
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
        rawValue,
        readonly = true,
    }) {
        const [signatureEditorVisible, setSignatureEditorVisibility] = useState<boolean>(false);
        rawValue = rawValue || field.rawValue;
        const [signature, setSignature] = useState(typeof rawValue === "string" ? rawValue : "");

        useEffect(() => {
            if (signature && signature.startsWith("data:")) {
                return;
            }

            if (signature) {
                try {
                    const jsonSignature = JSON.parse(signature);
                    if (jsonSignature && jsonSignature.file) {
                        setSignature(
                            `${API_URL}/form_entry_assets.json?uuid=${jsonSignature.file.uuid}`
                        );
                    }
                } catch (e) {}
            }
        }, [signature]);

        const handleClose = () => {
            setSignatureEditorVisibility(!signatureEditorVisible);
        };

        const signatureUpdate = (newSignature) => {
            (window as any).LAST_SIGNATURE = newSignature;
        };

        const saveAndClose = () => {
            setTimeout(() => {
                setSignature((window as any).LAST_SIGNATURE);
            }, 300);
            setSignatureEditorVisibility(false);
        };

        return (
            <div>
                <Label layout={layout} field={field} />
                {field.rawOptions.description ? (
                    layout.wrapDescription(<p>{field.rawOptions.description}</p>)
                ) : (
                    <></>
                )}
                {field.configureInput(
                    <input
                        value={signature}
                        required={true}
                        data-required={true}
                        name={field.hashCode}
                        type={"hidden"}
                    />
                )}

                <Button
                    disabled={readonly}
                    className={"hidden-print"}
                    onClick={() => setSignatureEditorVisibility(() => true)}
                >
                    Signatur setzen
                </Button>
                {signature && (
                    <div>
                        <img
                            src={signature}
                            width={200}
                            style={{
                                backgroundImage: field.addStamp
                                    ? "url('" + (window as any).STAMP_URL + "')"
                                    : null,
                                backgroundColor: "rgba(255,255,255,0.7)",
                                backgroundBlendMode: "lighten",
                                backgroundSize: "100% 100%",
                            }}
                        />
                    </div>
                )}
                {!signature && <div className={"visible-print"}>-</div>}
                <Modal
                    size="lg"
                    show={signatureEditorVisible}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <SignaturePad
                            signatureUpdated={signatureUpdate}
                            addStamp={field.addStamp}
                            target={field.hashCode}
                            smallSignature={false}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose}>Schließen</Button>
                        <Button onClick={saveAndClose}>Speichern</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    public PresenterComponent({ field }: { field: any }): any {
        return (
            <div>
                <label>
                    <strong>{field.label}:</strong>
                </label>
                <div>
                    <img width={300} src={field.rawValue} />
                </div>
            </div>
        );
    }

    public PDFComponent({ field, styles }) {
        return <></>;
    }
}
