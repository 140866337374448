import React, { useEffect, useState, useMemo } from "react";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { useParams, withRouter } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";
import { RootState } from "../../reducers";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import { createCustomField } from "@rpforms/shared/build/actions";
import * as actions from "@rpforms/shared/build/actions/customFields";
import { SchemaBuilder } from "@rpforms/shared/build/spec/SchemaBuilder";
import FieldDataSetter from "../../components/custom_fields/FieldDataSetter";
import { push } from "connected-react-router";

export const NewFieldPage = () => {
    const dispatch = useDispatch();
    const [icon, setIcon] = useState("");
    const [name, setName] = useState("");
    const [fieldData, setFieldData] = useState("");

    const schemaBuilder: any = useMemo(() => {
        const builder = new SchemaBuilder();
        return builder;
    }, []);

    const handleCreateButtonClick = () => {
        if (isValidInput()) {
            dispatch(
                createCustomField({
                    name: name,
                    icon: icon,
                    field_data: JSON.stringify(fieldData[0]),
                })
            );
            dispatch(push("/settings/protocols/custom_fields"));
        }
    };

    const isValidInput = () => {
        return name.length > 0 && icon.length > 0 && fieldData.length === 1;
    };

    return (
        <>
            <PageNavbar
                title="Feld erstellen"
                description="Hier können Sie ein neues Feld erstellen."
            >
                <PageNavAction
                    success={true}
                    onClick={() => handleCreateButtonClick()}
                    icon="save"
                    title="Erstellen"
                />

                <PageNavAction
                    link="/settings/protocols/custom_fields"
                    icon="list"
                    title="Alle Felder"
                />
            </PageNavbar>
            <FieldDataSetter
                name={name}
                setName={setName}
                icon={icon}
                setIcon={setIcon}
                fieldData={fieldData}
                setFieldData={setFieldData}
                schemaBuilder={schemaBuilder}
            />
        </>
    );
};

export default withRouter(NewFieldPage);
