import * as actions from "@rpforms/shared/build/actions/formEntries";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import EntryRow from "./EntryRow";
import { ListRow } from "../forms/FormListRow";

export const NestedEntries = styled.div`
    padding: 0 20px 20px 20px;
    ${ListRow} {
        background-color: #f1f1f1;
        border-bottom: 1px solid #ddd;
    }
`;

export const LoadingContainer = styled.div`
    padding: 60px 20px;
    background: #f1f1f1;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: center;
`;

class FormEntries extends Component {
    public props;
    public state;
    public setState;

    public componentDidMount() {
        this.props.fetchFormFormEntries(this.props.form);
    }

    public render() {
        const isLoading =
            typeof this.props.formEntries.byForm["form_" + this.props.form.id] === "undefined";
        const entries = this.props.formEntries.byForm[`form_${this.props.form.id}`];

        return (
            <>
                <div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <NestedEntries>
                            {entries.map((entry) => {
                                return <EntryRow entry={entry} key={entry.id} />;
                            })}
                        </NestedEntries>
                    )}
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return { formEntries: state.formEntries };
}

export default withRouter(connect(mapStateToProps, actions)(FormEntries));
