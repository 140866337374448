import {
    FORM_ENTRIES_LOADING,
    FORM_ENTRIES_LOADING_STOP,
    FORM_ENTRIES_SET_MIGRATION_PANES,
    getFormEntry,
    PrimaryButton,
    updateFormEntryFrontend,
} from "@rpforms/shared";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../reducers";

export const ModalTemplate = ({ editor, next }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector<RootState>((state) => state.formEntries.isLoading);

    const save = (i, mode) => {
        const formEntries = { ...editor.config.preload };
        const formEntryId = formEntries[i].form_entry.id || formEntries[i].source_form_entry.id;
        if (
            mode === "override" &&
            formEntries[i].form_entry.form_id !== formEntries[i].source_form_entry.form_id
        ) {
            toast.error("Beim Überschreiben müssen Formular-Typen übereinstimmen");
            return;
        }
        dispatch({
            type: FORM_ENTRIES_LOADING,
            payload: [],
        });
        dispatch(
            updateFormEntryFrontend(
                formEntryId,
                formEntries[i].form_entry.form_data,
                {
                    name: formEntries[i].form_entry.name,
                    form_revision_id: formEntries[i].form_entry.form_revision_id,
                    form_id: formEntries[i].form_entry.form_id,
                    entity_id: formEntries[i].form_entry.entity_id,
                },
                mode,
                (fromEntryData) => {
                    dispatch({
                        type: FORM_ENTRIES_SET_MIGRATION_PANES,
                        payload: [],
                    });
                    dispatch({
                        type: FORM_ENTRIES_LOADING_STOP,
                        payload: [],
                    });
                    dispatch(getFormEntry(formEntryId));
                    toast.success("Gespeichert");
                    window.history.back();
                    next();
                }
            )
        );
    };

    const onSubmit = async (data) => {
        save(1, "template");
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Speichern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        Wollen Sie den Eintrag speichern?
                        {isLoading && <Loader />}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton
                    style={isLoading ? { background: "gray" } : {}}
                    type={"submit"}
                    onClick={onSubmit}
                    disabled={!!isLoading}
                >
                    als neuer Eintrag
                </PrimaryButton>
            </Modal.Footer>
        </>
    );
};
