import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useRef } from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import AutoTextArea from "../components/layout/AutoTextArea";
import { FRONTEND_URL } from "../config";
import { template, uniqueID } from "../utils";
import { BaseField } from "./BaseField";
import {
    AutoFill,
    Conditional,
    DefaultValue,
    Description,
    HashCode,
    Hint,
    MaxLength,
    ReadOnly,
    Required,
    Richtext,
    Width,
} from "./configurables";
import { Label, LabelInput } from "./inputFields/label";
import { useSelector } from "react-redux";

export class TextareaField extends BaseField {
    public name = "";
    public label = "";
    public inputType = "text";
    public designerInputType = "text";
    public hashCode = uniqueID();
    public icon = "asterisk";
    public fieldType = "Texteingabe (Mehrzeilig)";

    public attributeNames = ["collapsed"];

    public getConfigurables() {
        return [
            HashCode,
            AutoFill,
            Conditional,
            MaxLength,
            Required,
            Description,
            DefaultValue,
            Width,
            Hint,
            ReadOnly,
            Richtext,
        ];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field, provided = null }) {
        return (
            <FieldWrapper
                register={register}
                provided={provided}
                field={field}
                schema={schema}
                designer={designer}
            >
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <LabelInput register={register} field={field} />
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
        rawValue,
        variables = {},
        readonly = false,
    }: any) {
        const editor = useSelector<any, IEditor>((state) => state.editor);
        const tinyMce = useRef();
        useEffect(() => {
            setTimeout(() => {
                let inp: HTMLInputElement = document.querySelector(
                    `#form-entry-new [name="${name}"]`
                );
                if (!inp) {
                    inp = document.querySelector(`[name="${name}"]`);
                }
                const value = inp!.value;
                if (tinyMce.current && tinyMce.current) {
                    (tinyMce as any).current!.editor.setContent(value);
                }
            }, 100);
        }, [editor]);

        if (!rawValue) {
            rawValue = field.rawValue;
        }
        const showBillingAddressSelector = name === "billing_adress";
        const billingsAddresses = (variables?.customer?.addresses || []).filter(
            (a) => a.address_type === "billing"
        );
        const optionReadonly = field.rawOptions.readonly;
        const optionRichtext = field.rawOptions.richtext;

        if (
            (window as any).EDIT_MODE &&
            (readonly || !rawValue || optionReadonly) &&
            field.rawOptions.autofill
        ) {
            rawValue = template(field.rawOptions.autofill, variables, field.formName);
        }

        const inputAttributes = () => {
            if (optionReadonly) {
                return {
                    value: rawValue
                        ? rawValue
                        : showBillingAddressSelector &&
                          billingsAddresses &&
                          billingsAddresses.length > 0
                        ? billingsAddresses[0].name
                        : "",
                };
            }
            return {
                defaultValue:
                    rawValue && typeof rawValue !== "object"
                        ? rawValue
                        : showBillingAddressSelector &&
                          billingsAddresses &&
                          billingsAddresses.length > 0
                        ? billingsAddresses[0].name
                        : "",
            };
        };

        return (
            <>
                <Label layout={layout} field={field} />
                {layout.wrapDescription(
                    field.rawOptions.description ? (
                        <p key={`${field.rawOptions.hashCode}-01`}>
                            {field.rawOptions.description}
                        </p>
                    ) : (
                        <></>
                    )
                )}
                {rawValue && typeof rawValue !== "object" && !(window as any).EDIT_MODE && (
                    <div>
                        {field.hashCode === "billing_adress" && (
                            <small
                                style={{
                                    color: "#2196f3",
                                    display: "block",
                                    marginBottom: "10px",
                                }}
                            >
                                RP Brandschutz-Automatik-Automation GmbH
                                <br />
                                Postfach 51 05 | 61422 Oberursel
                            </small>
                        )}
                        <p
                            key={`${field.rawOptions.hashCode}-02`}
                            style={{
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                            }}
                            dangerouslySetInnerHTML={{ __html: rawValue }}
                        ></p>
                    </div>
                )}
                {rawValue && !(window as any).EDIT_MODE && (
                    <input
                        readOnly={readonly}
                        type="hidden"
                        defaultValue={rawValue && typeof rawValue !== "object" ? rawValue : ""}
                        className="hidden-field"
                        name={name}
                        ref={register}
                    />
                )}
                {(!rawValue || (window as any).EDIT_MODE) &&
                    !optionRichtext &&
                    layout.wrapInput(
                        field.configureInput(
                            <AutoTextArea
                                readOnly={readonly || optionReadonly}
                                ref={register}
                                showBillingAddressSelector={showBillingAddressSelector}
                                showBillingAddresses={billingsAddresses}
                                name={name}
                                className="form-control hidden-print"
                                onChange={(ev) =>
                                    localStorage.setItem(
                                        "_prefetch_" + field.hashCode,
                                        ev.currentTarget.value
                                    )
                                }
                                {...inputAttributes()}
                            />,
                            field
                        )
                    )}
                {(!rawValue || (window as any).EDIT_MODE) &&
                    optionRichtext &&
                    layout.wrapInput(
                        field.configureInput(
                            <div className="hidden-print">
                                <input
                                    type="hidden"
                                    defaultValue={
                                        rawValue && typeof rawValue !== "object" ? rawValue : ""
                                    }
                                    className="hidden-field"
                                    name={name}
                                    ref={register}
                                />
                                <Editor
                                    tinymceScriptSrc={"/tinymce/js/tinymce/tinymce.min.js"}
                                    disabled={readonly}
                                    onEditorChange={(value) => {
                                        let inp = document.querySelector(
                                            `#form-entry-new [name="${name}"]`
                                        );
                                        if (!inp) {
                                            inp = document.querySelector(`[name="${name}"]`);
                                        }
                                        inp.setAttribute("value", value);
                                    }}
                                    ref={tinyMce}
                                    initialValue={rawValue}
                                    init={{
                                        height: 150,
                                        menubar: false,
                                        statusbar: false,
                                        plugins: [
                                            "charmap",
                                            "searchreplace",
                                            "visualblocks",
                                            "fullscreen",
                                            "paste",
                                            "code",
                                            "custom_templates2",
                                            "autoresize",
                                        ],
                                        external_plugins: {
                                            custom_templates2: `${FRONTEND_URL}/tinymce/plugins/custom_templates2.js`,
                                        },
                                        branding: false,
                                        toolbar: readonly
                                            ? ""
                                            : "undo redo | " +
                                              "bold italic underline backcolor removeformat " +
                                              "custom_templates2",
                                    }}
                                    {...inputAttributes()}
                                />
                            </div>,
                            field
                        )
                    )}
                {(!rawValue || typeof rawValue === "object") && (
                    <div className="visible-print">-</div>
                )}

                {layout.wrapHint(
                    field.rawOptions.hint ? (
                        <p key={`${field.rawOptions.hashCode}-03`}>{field.rawOptions.hint}</p>
                    ) : (
                        <></>
                    )
                )}
            </>
        );
    }

    public PDFComponent({ field, styles }) {
        return <></>;
    }
}
