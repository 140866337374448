import { useEffect } from "react";
import { API_URL } from "../config";

const useTokenRefresher = () => {
    useEffect(() => {
        const fetchNewToken = async () => {
            let user = JSON.parse(localStorage.getItem("user"));
            if (!user || !user.token) return;

            try {
                const res = await fetch(`${API_URL}/auth/refresh.json`, {
                    method: "POST",
                    headers: {
                        Authorization: user.token,
                    },
                });
                const json = await res.json();
                if (json && json.token) {
                    user.token = json.token;
                    user.exp = json.exp;
                    localStorage.setItem("user", JSON.stringify(user));
                }
            } catch (error) {
                console.error("Error refreshing token:", error);
            }
        };

        const intervalId = setInterval(fetchNewToken, 1000 * 10 * 60); // 10 minutes

        // Cleanup
        return () => clearInterval(intervalId);
    }, [API_URL]);
};

export default useTokenRefresher;
