import React from "react";
import styled from "styled-components";

const AppearanceBoxWrapper = styled.div`
    margin: 20px 5px;
`;

export const AppearanceBox = (props: any) => {
    return <AppearanceBoxWrapper>{props.children}</AppearanceBoxWrapper>;
};
