import { FORM_GROUP_ADD, FORM_GROUP_LOADED, FORM_GROUPS_SET, GET_FORMS } from "../actions/types";

const initialState = {
    byId: {},
    items: [],
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case FORM_GROUP_LOADED:
            return {
                ...state,
            };

        case FORM_GROUPS_SET:
            return {
                ...state,
                items: action.payload,
            };

        case FORM_GROUP_ADD:
            return {
                ...state,
                items: [...state.items, action.payload],
            };

        // add forms to corresponding group
        case GET_FORMS:
            const newItems = [];
            const forms = action.payload;
            const groups = forms
                .filter((f) => !!f.form_group)
                .map((f) => f.form_group)
                .map((group) => {
                    if (
                        !newItems.find((newGroup) => newGroup.id === group.id) &&
                        !state.items.find((stateGroup) => stateGroup.id === group.id)
                    ) {
                        newItems.push(group);
                    }
                    return group;
                })
                .map((group) => {
                    return {
                        [group.id]: { ...group, forms: [] },
                    };
                });

            return {
                ...state,
                items: [...state.items, ...newItems],
                byId: {
                    ...state.byId,
                    ...groups,
                },
            };
    }

    return state;
}
