import React from "react";
import styled from "styled-components";
import { AlignCenterVertically } from "./layout/Content";
import { RightAligned } from "./universal/PageNavbar";

export const Header = styled.div`
    display: flex;
    padding: 10px;
    background: #f6f6f6;
    border-bottom: 1px solid #ccc;
    p {
        margin: 0;
    }
`;

export const FieldType = styled.span`
    color: #999;
    padding: 0 5px;
`;

export const EditHeaderGroup = styled.div`
    margin: 0 5px;
    button {
        margin: 0;
    }

    button:first-child {
        border-right: none !important;
        border-radius: 5px 0 0 5px;
    }
    button:last-child {
        border-radius: 0 5px 5px 0;
    }
`;

export const EditHeaderAction = styled.button`
    font-size: 18px;
    background: linear-gradient(to top, rgba(235, 235, 235, 0.6), rgba(241, 241, 241, 0.6));
    border-radius: 5px;
    border: 1px solid #ddd;
    text-shadow: 1px 1px #fff;
    color: #444;
    padding: 4px 8px;
    transition: linear 0.1s;
    transition-property: box-shadow, background;
    box-shadow: 0 0 0 #fff;
    a {
        display: block;
        padding: 8px 16px;
    }

    :hover {
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: #444;
        }
    }

    :focus {
        outline: none;
    }

    :active {
        box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
        background: linear-gradient(to bottom, rgba(235, 235, 235, 0.8), rgba(241, 241, 241, 0.6));
        border-color: #ccc;
        a {
            color: #444;
        }
    }
    margin: 0 5px;
    font-size: 14px;
    padding: 4px 8px;
`;

export const EditHeader = ({
    handleSave,
    collapsed,
    field,
    handleCopy,
    handleCreateField,
    handleMoveUp,
    handleMoveDown,
    handleTrash,
    handleCollapse,
    handleSettings,
}) => {
    const fieldIcon = `fa fa-fw fa-${field.getIcon()}`;
    return (
        <Header>
            <AlignCenterVertically>
                <i className={fieldIcon} title={field.fieldType}></i>{" "}
                <div dangerouslySetInnerHTML={{ __html: field.label }}></div>
            </AlignCenterVertically>
            <RightAligned className="edit-header-actions">
                <EditHeaderAction
                    type="button"
                    className="edit-header-action"
                    onClick={handleCreateField}
                >
                    <i className="fa fa-puzzle-piece" aria-hidden="true"></i>
                </EditHeaderAction>
                <EditHeaderAction type="button" className="edit-header-action" onClick={handleCopy}>
                    <i className="fa fa-clone" aria-hidden="true"></i>
                </EditHeaderAction>
                <EditHeaderGroup>
                    <EditHeaderAction
                        type="button"
                        className="edit-header-action"
                        onClick={handleMoveUp}
                    >
                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                    </EditHeaderAction>
                    <EditHeaderAction
                        type="button"
                        className="edit-header-action"
                        onClick={handleMoveDown}
                    >
                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    </EditHeaderAction>
                </EditHeaderGroup>
                <EditHeaderAction
                    type="button"
                    className="edit-header-action"
                    onClick={() => handleTrash()}
                >
                    <i className="fa fa-trash" />
                </EditHeaderAction>
                <EditHeaderAction
                    type="button"
                    className="edit-header-action"
                    onClick={handleSettings}
                >
                    <i className="fa fa-cog" />
                </EditHeaderAction>
                {collapsed ? (
                    <EditHeaderAction
                        type="button"
                        className="edit-header-action"
                        onClick={handleCollapse}
                    >
                        <i className="fa fa-chevron-right" />
                    </EditHeaderAction>
                ) : (
                    <EditHeaderAction
                        type="button"
                        className="edit-header-action"
                        onClick={handleCollapse}
                    >
                        <i className="fa fa-chevron-down" />
                    </EditHeaderAction>
                )}
            </RightAligned>
        </Header>
    );
};
