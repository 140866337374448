import { toSelectOptions } from "@rpforms/shared";
import React from "react";
import Select from "react-select";

export const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const CalendarEventTypeSelector = ({
    types,
    eventType,
    setEventType,
    setFirstAsDefault = false,
}: any) => {
    const selectOptions = toSelectOptions(types);
    if (types.length === 0) return <div></div>;
    return (
        <Select
            style={{ width: "100%" }}
            options={selectOptions}
            styles={{
                singleValue: (styles, { data }) => ({
                    ...styles,
                    ...dot(types.find((t) => data.value === t.id)?.color || "#000"),
                }),
                option: (styles, { data }) => ({
                    ...styles,
                    ...dot(types.find((t) => data.value === t.id)?.color || "#000"),
                }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            defaultValue={
                setFirstAsDefault
                    ? selectOptions[0]
                    : selectOptions.find((s) => s.label === eventType?.name)
            }
            onChange={(option) => setEventType(types.find((t) => option.value === t.id))}
        />
    );
};

export default CalendarEventTypeSelector;
