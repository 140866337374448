import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Form } from "react-bootstrap";
import styled from "styled-components";
import { FINANCES_SET_DATE, getRevenues, isEmpty } from "@rpforms/shared";

import { RootState } from "../../reducers";
import { FinancesButtonContainer } from "./FinancesInvoiceTypeSelector";
import { FinancesType } from "../../pages/finances/FinancesPage";

const ResetButtonContainer = styled(FinancesButtonContainer)`
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 1rem;

    .reset-date {
        position: absolute;
        top: 65px;
        right: 0;
        z-index: 99;

        button {
            border: none;
            background-color: transparent;
            font-size: 14px;
        }
    }

    .form-group {
        margin-bottom: 0;
    }
`;

// generate an array to create 31 day components
const dateArray = [...Array(31)];

// generate an array to create all years from current down to 2020
const currentYear = new Date(Date.now()).getFullYear();
const yearsTo2020 = currentYear - 2020;
const yearsArray = [...Array(yearsTo2020), "one more item needed"];

interface Props {
    hideMonth?: boolean;
    hideDay?: boolean;
    type?: FinancesType;
}

const FinancesInvoiceTypeSelector = ({ hideMonth, hideDay, type }: Props) => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [oType, setOType] = useState("");
    const [missingYear, setMissingYear] = useState(false);
    const [missingMonth, setMissingMonth] = useState(false);

    const dispatch = useDispatch();

    const {
        customer_id,
        entity_id,
        offerType,
        selectedYear,
        selectedMonth,
        selectedDay,
        page,
    } = useSelector<RootState, any>((state) => state.finances);

    useEffect(() => {
        setOType(offerType);
    }, [offerType]);

    useEffect(() => {
        if (type === "offer") handleSelectYear(currentYear);
    }, []);

    useEffect(() => {
        if (!isEmpty(selectedYear) || !isEmpty(selectedMonth) || !isEmpty(selectedDay)) {
            setYear(selectedYear);
            setMonth(selectedMonth);
            setDay(selectedDay);
            dispatch(
                getRevenues(
                    type === "invoice" ? oType : null,
                    customer_id,
                    entity_id,
                    year || new Date().getFullYear(),
                    month,
                    day,
                    `${type || "invoice"}s` as any,
                    offerType,
                    page
                )
            ); // maxDate
        }
    }, [selectedYear, selectedMonth, selectedDay]);

    const handleSelectYear = (year) => {
        setYear(year || new Date().getFullYear());
        setMissingYear(false);
        dispatch({ type: FINANCES_SET_DATE, payload: { year, month, day } });
    };

    const handleSelectMonth = (month) => {
        setMonth(month);
        setMissingMonth(false);

        if (year) {
            dispatch({ type: FINANCES_SET_DATE, payload: { year, month } });
        } else {
            setMissingYear(true);
        }
    };

    const handleSelectDay = (day) => {
        setDay(day);

        if (month && year) {
            dispatch({ type: FINANCES_SET_DATE, payload: { year, month, day } });
        } else {
            setMissingYear(true);
            setMissingMonth(true);
        }
    };

    const handleResetDate = () => {
        dispatch({
            type: FINANCES_SET_DATE,
            payload: {
                year: "",
                month: "",
                day: "",
            },
        });
        setYear("");
        setMonth("");
        setDay("");
        setMissingYear(false);
        setMissingMonth(false);
        dispatch(
            getRevenues(
                type === "invoice" ? oType : null,
                customer_id,
                entity_id,
                new Date().getFullYear(),
                null,
                null,
                type,
                offerType,
                page
            )
        );
    };

    return (
        <ResetButtonContainer>
            <Dropdown style={{ marginRight: "1rem" }}>
                <Dropdown.Toggle variant={missingYear ? "danger" : "secondary"} id="dropdown-basic">
                    {year ? year : "Jahr"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {yearsArray.map((y, i) => {
                        const currentYear = String(2020 + i);

                        return (
                            <Dropdown.Item onClick={() => handleSelectYear(currentYear)} key={i}>
                                {currentYear}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            {!hideMonth && (
                <Dropdown style={{ marginRight: "1rem" }}>
                    <Dropdown.Toggle
                        variant={missingMonth ? "danger" : "secondary"}
                        id="dropdown-basic"
                    >
                        {month ? month : "Monat"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleSelectMonth("01")}>
                            Januar
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("02")}>
                            Februar
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("03")}>März</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("04")}>April</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("05")}>Mai</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("06")}>Juni</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("07")}>Juli</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("08")}>
                            August
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("09")}>
                            September
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("10")}>
                            Oktober
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("11")}>
                            November
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSelectMonth("12")}>
                            Dezember
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )}
            {!hideDay && (
                <Form style={{ margin: "1rem 1rem 0 0" }}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control
                            as="select"
                            value={day ? day : "Tag"}
                            onChange={(e) => handleSelectDay(e.target.value)}
                            style={{ background: "#6c757d", color: "white" }}
                        >
                            <option value="">Tag</option>
                            {dateArray.map((d, i) => {
                                // Every month has 31 days
                                const dayString = String(i + 1);

                                return (
                                    <option key={i} value={dayString}>
                                        {dayString}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                </Form>
            )}
            {(year || month || day) && (
                <div className="reset-date">
                    <button onClick={handleResetDate}>Datum zurücksetzen</button>
                </div>
            )}
        </ResetButtonContainer>
    );
};

export default FinancesInvoiceTypeSelector;
