import React from "react";

import { FieldWrapper } from "../components/FieldWrapper";
import { TextField } from "./TextField";
export class PageBreakField extends TextField {
    public icon = "scissors";
    public fieldType = "Seitenumbruch";
    public state;
    public setState;

    public getConfigurables() {
        return [];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div>Dieses Feld hat keine Einstellungsmöglichkeit</div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
    }: {
        layout: any;
        schema: any;
        field: any;
        entry?: any;
        register: any;
        name?: any;
    }): any {
        return (
            <>
                <p className={"pagebreak"}></p>
            </>
        );
    }
}
