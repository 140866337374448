import {
    CREATE_CALENDAR_EVENT_TYPE,
    DELETE_CALENDAR_EVENT_TYPE,
    GET_CALENDAR_EVENT_TYPES,
    UPDATE_CALENDAR_EVENT_TYPE,
} from "./../actions/types/index";

const initialState = {
    types: [],
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case CREATE_CALENDAR_EVENT_TYPE:
            if (state.types.find((type: ICalendarEventType) => type.id === action.payload.id)) {
                return { ...state };
            }
            return { ...state, types: [...state.types, action.payload] };
        case DELETE_CALENDAR_EVENT_TYPE:
            return {
                ...state,
                types: state.types.filter(
                    (type: ICalendarEventType) => type.id !== action.payload.id
                ),
            };
        case GET_CALENDAR_EVENT_TYPES:
            return { ...state, types: action.payload };
        case UPDATE_CALENDAR_EVENT_TYPE:
            const types = state.types.map((type: ICalendarEventType) => {
                if (type.id === action.payload.id) {
                    return action.payload;
                }
                return type;
            });
            return { ...state, types };
    }

    return state;
}
