import React, { useState } from "react";
import { PopoverContent, PopoverTitle } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Popover, { ArrowContainer } from "react-tiny-popover";
import actions from "redux-form/lib/actions";
import styled from "styled-components";
import { CheckboxField } from "../fields/CheckboxField";
import { ColumnField } from "../fields/ColumnField";
import { ConfigField } from "../fields/ConfigField";
import { DateField } from "../fields/DateField";
import { EmailField } from "../fields/EmailField";
import { FooterField } from "../fields/FooterField";
import { GroupField } from "../fields/GroupField";
import { HeaderField } from "../fields/HeaderField";
import { HSpacerField } from "../fields/HSpacerField";
import { ImageField } from "../fields/ImageField";
import { IntegerField } from "../fields/IntegerField";
import { InvoicePositionsListField } from "../fields/InvoicePositionsListField";
import { LocationField } from "../fields/LocationField";
import { PageBreakField } from "../fields/PageBreakField";
import { ParagraphField } from "../fields/ParagraphField";
import { PasswordField } from "../fields/PasswordField";
import { PhonenumberField } from "../fields/PhonenumberField";
import { PictureField } from "../fields/PictureField";
import { RadioField } from "../fields/RadioField";
import { RepeaterField } from "../fields/RepeaterField";
import { RescheduleField } from "../fields/RescheduleField";
import { RowField } from "../fields/RowField";
import { ScriptField } from "../fields/ScriptField";
import { SeparatorField } from "../fields/SeparatorField";
import { SignatureField } from "../fields/SignatureField";
import { SpacerField } from "../fields/SpacerField";
import { StyleField } from "../fields/StyleField";
import { TableField } from "../fields/TableField";
import { TextareaField } from "../fields/TextareaField";
import { TextField } from "../fields/TextField";
import { TitleField } from "../fields/TitleField";
import { Button, SmallButton } from "./Button";
import { Scrollable } from "./layout/Popover";

export const WidgetCard = styled.div`
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 8px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f0f0f0;
    background: #fff;
    color: #007bff;
    :hover {
        background: #007bff;
        color: #fff;
        cursor: pointer;
    }
    padding: 5px;
`;

export const WidgetName = styled.div`
    font-size: 12px;
    text-align: center;
`;

export const WidgetIcon = styled.div`
    font-size: 24px;
`;

export const widgets = [
    TitleField,
    SpacerField,
    HSpacerField,
    ParagraphField,
    SeparatorField,
    TextField,
    TextareaField,
    ImageField,
    DateField,
    CheckboxField,
    EmailField,
    TableField,
    LocationField,
    RadioField,
    PasswordField,
    PictureField,
    PhonenumberField,
    SignatureField,
    IntegerField,
    GroupField,
    RowField,
    ColumnField,
    RepeaterField,
    PageBreakField,
    ConfigField,
    ScriptField,
    StyleField,
    HeaderField,
    FooterField,
    RescheduleField,
    InvoicePositionsListField,
];

/**
 * Add new fields to your form
 * @param showCustomFields
 * @param getWidgets
 * @param customWidgets
 * @param handleNewField
 * @param attached
 * @param small
 * @param fields
 * @param after
 * @constructor
 */
const AddField = ({
    showCustomFields = false,
    customWidgets = { widgets: [], loaded: false },
    handleNewField,
    attached = false,
    small = false,
    fields = [],
    after = null,
}: any) => {
    const [popover, setPopover] = useState(false);
    const customFields = customWidgets.widgets;
    if (showCustomFields) {
        if (!customWidgets.loaded) {
            //  getWidgets();
        }
    }

    if (fields.length === 0) {
        fields = widgets;
    }

    const label = after ? "Feld hier einfügen" : "Feld hinzufügen";

    const ButtonSize = small ? SmallButton : Button;
    const buttonStyle = attached
        ? {
              marginTop: "-2px",
              borderRadius: "0",
              marginLeft: "20px",
              marginBottom: "20px",
          }
        : {};

    return (
        <>
            <div>
                <Popover
                    isOpen={popover}
                    position={"right"} // preferred position
                    content={({ position = "top", targetRect, popoverRect }) => (
                        <ArrowContainer
                            position={position}
                            targetRect={targetRect}
                            popoverRect={popoverRect}
                            arrowColor={"white"}
                            arrowSize={10}
                            arrowStyle={{ opacity: 0.7 }}
                        >
                            <PopoverContent>
                                <PopoverTitle>Feldauswahl</PopoverTitle>
                                <Scrollable>
                                    {showCustomFields ? (
                                        <div>
                                            <strong>Eigene Felder</strong>
                                            <div className="d-flex">
                                                {customFields.length === 0 ? (
                                                    <div>
                                                        Noch kein eigenes Feld erstellt. Sie können
                                                        bestehende Felder über die Speichern (
                                                        <i className="fa fa-puzzle-piece" />)
                                                        Schaltfläche hier wiederverwenden
                                                    </div>
                                                ) : (
                                                    customFields.map((customField) => {
                                                        return (
                                                            <WidgetCard
                                                                key={`cf_${customField.id}`}
                                                                onClick={() => {
                                                                    setPopover(false);
                                                                    handleNewField(
                                                                        customField.field_data
                                                                    );
                                                                }}
                                                            >
                                                                <WidgetIcon>
                                                                    <i
                                                                        className={`fa fa-${customField.icon}`}
                                                                    />
                                                                </WidgetIcon>
                                                                <WidgetName>
                                                                    {customField.name}
                                                                </WidgetName>
                                                            </WidgetCard>
                                                        );
                                                    })
                                                )}
                                            </div>
                                            <hr />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div>
                                        {fields.map((field) => (
                                            <WidgetCard
                                                key={field}
                                                onClick={() => {
                                                    setPopover(false);
                                                    handleNewField(field);
                                                }}
                                            >
                                                <WidgetIcon>
                                                    <i className={`fa fa-${field.icon()}`} />
                                                </WidgetIcon>
                                                <WidgetName>{field.fieldType()}</WidgetName>
                                            </WidgetCard>
                                        ))}
                                    </div>
                                </Scrollable>
                            </PopoverContent>
                        </ArrowContainer>
                    )}
                >
                    <ButtonSize
                        style={buttonStyle}
                        type="button"
                        onClick={() => setPopover(!popover)}
                    >
                        <i className="fa fa-plus" /> {label}
                    </ButtonSize>
                </Popover>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return { customWidgets: state.customWidgets };
}

export default withRouter(connect(mapStateToProps, actions)(AddField)) as any;
