import React, { useRef, useState } from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";

const Navigation = styled.nav`
    background: #fff;
    border-bottom: 1px solid #ccc;
    padding: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 105;
    position: sticky;
    top: 0;
    transform: translate3d(0, 0, 1px);
`;

const Title = styled.h3`
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-family: "Merriweather", serif;
`;

const NavigationItems = styled.ul`
    list-style: none;
    display: flex;
    margin: 0;
`;

export const RightAligned = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
`;

const Description = styled.div`
    font-size: 16px;
`;

const PageNavbar = ({
    title,
    description,
    onRename = () => {},
    children = [],
    rename = false,
    subtitle = null,
}: any) => {
    const [editing, setEditing] = useState(false);
    const nameRef: any = useRef();
    const saveEdit = () => {
        setEditing(false);
        if (nameRef && nameRef.current) {
            onRename(nameRef.current.value);
        }
    };

    const cancelEdit = () => {
        setEditing(false);
    };

    return (
        <Navigation>
            <div style={{flexGrow: 1}}>
                {!editing && (
                    <Title>
                        {title}{" "}
                        {rename && (
                            <i onClick={() => setEditing(!editing)} className={"fa fa-edit"} />
                        )}
                        {subtitle && <Badge variant="secondary">{subtitle}</Badge>}
                    </Title>
                )}
                {editing && (
                    <Title>
                        <input type={"text"} ref={nameRef} defaultValue={title} />
                        <i onClick={() => saveEdit()} className={"fa fa-check"} />
                        <i onClick={() => cancelEdit()} className={"fa fa-close"} />
                    </Title>
                )}
                <Description>{description}</Description>
            </div>
            <RightAligned>
                <NavigationItems>{children}</NavigationItems>
            </RightAligned>
        </Navigation>
    );
};

export default PageNavbar;
