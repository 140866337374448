import {
    CREATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    EMPLOYEES_LOADING_START,
    EMPLOYEES_LOADING_STOP,
    EMPLOYEES_SET_EDITING,
    GET_EMPLOYEE_BY_ID,
    GET_EMPLOYEES,
    UPDATE_EMPLOYEE,
} from "../actions/types";

const initialState = {
    employees: [],
    isLoading: false,
    isEditing: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_EMPLOYEES:
            return { ...state, employees: action.payload };
        case GET_EMPLOYEE_BY_ID:
            return { ...state, employees: [action.payload] };
        case CREATE_EMPLOYEE:
            if (state.employees.find((e: any) => e.id === action.payload.id)) {
                return { ...state };
            }
            return { ...state, employees: [...state.employees, action.payload] };
        case UPDATE_EMPLOYEE:
            return {
                ...state,
                employees: [...state.employees].map((e: any) => {
                    if (e.id === action.payload.id) {
                        return { ...e, ...action.payload };
                    }
                    return e;
                }),
            };
        case DELETE_EMPLOYEE:
            return {
                ...state,
                employees: state.employees.filter((e) => {
                    e.id !== action.payload.id;
                }),
            };
        case EMPLOYEES_LOADING_STOP:
            return { ...state, isLoading: false };
        case EMPLOYEES_LOADING_START:
            return { ...state, isLoading: true };
        case EMPLOYEES_SET_EDITING:
            return { ...state, isEditing: action.payload };
    }

    return state;
}
