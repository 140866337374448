import * as actions from "@rpforms/shared/build/actions/entities";
import {PrimaryButton} from "@rpforms/shared/build/components/layout/Button";
import renderField from "@rpforms/shared/build/components/layout/Forms";
import {Loader} from "@rpforms/shared/build/components/universal/Loader";
import React, {Component, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {Field, reduxForm} from "redux-form";

class EditInventoryItemPage extends Component {
    public props;
    public state;
    public setState;

    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            validated: false,
        };
    }

    public async handleFormSubmit(props) {
        const entity = this.props.entities.entities.find(
            (e) =>
                String(e.id) === this.props.match.params.id ||
                e.id === this.props.match.params.id
        );
        this.props.renameEntity(entity, props);
        toast.success("✌️Objekt gespeichert", {autoClose: 2000});
    }

    public componentWillMount(): void {
        this.props.getEntities();
    }

    public render() {
        const {handleSubmit} = this.props;
        const entity = this.props.entities.entities.find(
            (e) =>
                String(e.id) === this.props.match.params.id ||
                e.id === this.props.match.params.id
        );
        if (!entity) {
            return (
                <div>
                    <Loader/>
                </div>
            );
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-8 m-auto pt-5">
                        <div className="card">
                            <div className="card-header">
                                <div className="m-0 card-title">
                                    Neuer Name für Objekt <b>{entity.name}</b>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-container">
                                    <form noValidate
                                          onSubmit={handleSubmit(this.handleFormSubmit)}>
                                        {/* Name */}
                                        <Field
                                            name="title"
                                            required="true"
                                            component={renderField}
                                            type="text"
                                            placeholder={entity.name}
                                        />

                                        {/* Server error message */}
                                        {this.props.errorMessage &&
                                        this.props.errorMessage.signin && (
                                            <div
                                                className="error-container signin-error">
                                                Oops! {this.props.errorMessage.signin}
                                            </div>
                                        )}

                                        {/* Create button */}
                                        <PrimaryButton type="submit">
                                            Speichern <i
                                            className="fa fa-chevron-right"/>
                                        </PrimaryButton>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function validate(formProps) {
    const errors: any = {};

    if (!formProps.title) {
        errors.title = "Das Objekt muss einen Namen haben.";
    }

    return errors;
}

function mapStateToProps(state) {
    return {entities: state.entities, errorMessage: state.forms.error};
}

export default withRouter(
    connect(
        mapStateToProps,
        actions
    )(reduxForm({ form: "newentity", validate })(EditInventoryItemPage))
);
