import { SmallDangerButton, SmallPrimaryButton } from "@rpforms/shared/build/components/Button";
import React from "react";
import { Table } from "react-bootstrap";

export const UserListing = ({ users }) => {
    return (
        <Table bordered striped>
            <thead>
                <tr>
                    <th>E-mail</th>
                    <th>Benutzername</th>
                    <th>Rollen</th>
                    <th>Aktion</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user) => {
                    return (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.username}</td>
                            <td>{user.roles.join(", ")}</td>
                            <td>
                                <SmallPrimaryButton>Bearbeiten</SmallPrimaryButton>{" "}
                                <SmallDangerButton>Entfernen</SmallDangerButton>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
