import {
    ADD_ENTITY,
    CREATE_ENTITY,
    DELETE_ENTITY,
    ENTITIES_AUTOCOMPLETE,
    ENTITIES_AUTOCOMPLETE_START,
    GET_ENTITIES,
    LOADING_START,
    LOADING_STOP,
    UPDATE_ENTITY,
} from "../actions/types";

const initialState = {
    entities: [],
    isLoading: false,
    autocomplete: [],
    autocompleteIsLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case ENTITIES_AUTOCOMPLETE_START:
            return { ...state, autocompleteIsLoading: true };
        case ENTITIES_AUTOCOMPLETE:
            return {
                ...state,
                autocomplete: action.payload,
                autocompleteIsLoading: false,
            };
        case ADD_ENTITY:
            return { ...state, entities: [action.payload, ...state.entities] };
        case GET_ENTITIES:
            return { ...state, entities: action.payload };
        case DELETE_ENTITY:
            return {
                ...state,
                entities: state.entities.filter((entity: any) => {
                    return entity.id !== action.payload.id;
                }),
            };
        case CREATE_ENTITY:
            return { ...state, entities: [action.payload, ...state.entities] };

        case LOADING_START:
            return { ...state, isLoading: true };
        case LOADING_STOP:
            return { ...state, isLoading: false };
        case UPDATE_ENTITY:
            const entities = state.entities.map((entity: any) => {
                if (entity.id === action.payload.id) {
                    return action.payload;
                }
                return entity;
            });
            return { ...state, entities };
    }

    return state;
}
