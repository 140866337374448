import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import {
    ADD_PERMISSION,
    REMOVE_PERMISSION,
    SET_PERMISSIONS,
    SET_ROLES,
    PERMISSIONS_LOADING_STOP,
    PERMISSIONS_LOADING_START,
} from "./types";

export function removePermission(permission: any) {
    return async (dispatch: IDispatch<any>) => {
        try {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_START,
            });
            const response = await axios.delete(
                `${API_URL}/companies/${COMPANY_ID}/resources/${permission.resource.resource_id}/permissions/${permission.id}.json?type=${permission.resource.resource_type}`,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                payload: response.data,
                type: REMOVE_PERMISSION,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_STOP,
            });
        }
    };
}

export function updatePermission() {
    return () => {
        throw new Error("Not implemented");
    };
}

export function createPermission(permission: any) {
    return async (dispatch: IDispatch<any>) => {
        try {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_START,
            });
            const response = await axios.post(
                `${API_URL}/companies/${COMPANY_ID}/resources/${permission.resource_id}/permissions.json?type=${permission.resource_type}`,
                { permission },
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                payload: response.data,
                type: ADD_PERMISSION,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_STOP,
            });
        }
    };
}

export function fetchPermissions(resource: any) {
    return async (dispatch: IDispatch<any>) => {
        try {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_START,
            });
            const response = await axios.get(
                `${API_URL}/companies/${COMPANY_ID}/resources/${resource.id}/permissions.json?type=${resource.type}`,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                payload: response.data,
                type: SET_PERMISSIONS,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_STOP,
            });
        }
    };
}

export function fetchRoles() {
    return async (dispatch: IDispatch<any>) => {
        try {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_START,
            });
            const response = await axios.get(`${API_URL}/companies/${COMPANY_ID}/roles.json`, {
                headers: { authorization: user().token },
            });
            dispatch({
                payload: response.data,
                type: SET_ROLES,
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                payload: null,
                type: PERMISSIONS_LOADING_STOP,
            });
        }
    };
}
