import {defaultOpts, getCollection} from "./actions_helper";

export function fetchTerms(
    page = 1,
    cb: any,
    opts = defaultOpts,
    pageSize = "all"
) {
    opts.page_size = pageSize;
    opts.page = page;
    return getCollection("terms", opts, cb);
}
