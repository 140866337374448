export class DataPresenter {
    public mappedSchema = [];

    constructor(private schemaBuilder, private entry) {
        this.mappedSchema = this.mapEntryToSchema();
    }

    /**
     *
     * @param entry
     */
    private mapEntryToSchema() {
        const walkFields = (field) => {
            // walk subfields
            if (field.subfields && field.subfields.length) {
                field.subfields = field.subfields.map(walkFields);
            }

            if (!this.entry.form_data) {
                this.entry.form_data = {};
            }

            // check if field hashcode is present in the entry
            if (this.entry.form_data.hasOwnProperty(field.hashCode)) {
                field.rawValue = this.entry.form_data[field.hashCode];
            } else {
                // might be suffixed with an index, lets test:
                const checkedValues = {};
                for (const hashCode in this.entry.form_data) {
                    if (hashCode.indexOf(field.hashCode) === 0) {
                        checkedValues[hashCode] = this.entry.form_data[hashCode];
                    }
                }
                if (Object.keys(checkedValues)) {
                    field.rawValue = checkedValues;
                }
            }

            // might be a date field
            for (const hashCode in this.entry.form_data) {
                if (hashCode.indexOf(field.hashCode) !== -1) {
                    if (hashCode.indexOf(".date") !== -1) {
                        field.rawValue = this.entry.form_data[hashCode];
                    }
                }
            }

            return field;
        };
        let mappedFields = this.schemaBuilder.schema.fields.map(walkFields);

        // special care taking for repeater fields
        const repeaterData = {};
        const hashCodeParser = (hashCode) => {
            // sample: repeater_2_1fadf53a-2ec4-49d5-983e-c4600ea87068_fa71135c-08fe-44c2-b278-a29de024448f
            const matcher = /repeater_(\d+)_(.*)_(.*)/i;
            const [results, index, repeaterHashCode, fieldHashCode] = hashCode.match(matcher);
            return {
                results,
                index,
                repeaterHashCode: "_" + repeaterHashCode,
                fieldHashCode: "_" + fieldHashCode,
            };
        };

        // this basically maps repeater_X_Y entries to their respective fields for later usage
        if (this.entry.form_data) {
            Object.keys(this.entry.form_data).forEach((hashCode) => {
                if (hashCode.indexOf("repeater_") !== 0) {
                    return;
                }
                const parsed = hashCodeParser(hashCode);
                if (!repeaterData.hasOwnProperty(parsed.repeaterHashCode)) {
                    repeaterData[parsed.repeaterHashCode] = [];
                }

                if (!repeaterData[parsed.repeaterHashCode].hasOwnProperty(parsed.index)) {
                    repeaterData[parsed.repeaterHashCode][parsed.index] = {};
                }

                repeaterData[parsed.repeaterHashCode][parsed.index][
                    parsed.fieldHashCode
                ] = this.entry.form_data[hashCode];
            });
        }

        // assign to repeater elements
        const walkRepeaterFields = (field) => {
            // walk subfields
            if (field.subfields && field.subfields.length) {
                field.subfields = field.subfields.map(walkRepeaterFields);
            }

            // check if field hashcode is present in the entry
            if (repeaterData[field.hashCode]) {
                field.rawValue = repeaterData[field.hashCode];
            }

            return field;
        };

        mappedFields = mappedFields.map(walkRepeaterFields);
        this.schemaBuilder.schema.fields = mappedFields;
        return this.mappedSchema;
    }
}
