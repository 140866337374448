import { fetchUsers } from "@rpforms/shared/build/actions/users";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { UserListing } from "../../components/users/listing";
import { RootState } from "../../reducers";
import { SettingsHeadline } from "./SettingsPage";

const AdminSettingsPage = () => {
    const users = useSelector<RootState, any>((store) => store.users.users);
    const admins = users.filter(
        (user) => user.roles.includes("admin") || user.roles.includes("superadmin")
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    return (
        <div>
            <SettingsHeadline>
                <span>Administratoren</span>
                <PrimaryButton style={{ float: "right" }}>
                    <i className={"fa fa-fw fa-plus-circle"}></i> Hinzufügen
                </PrimaryButton>
            </SettingsHeadline>
            <UserListing users={admins} />
        </div>
    );
};

export default withRouter(AdminSettingsPage);
