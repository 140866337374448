import React from "react";

class BootstrapLayout {
    public static wrapLabel(label: string) {
        return <>{label}</>;
    }

    public static wrapDescription(description: string) {
        return <div className="rpfe-description">{description}</div>;
    }

    public static wrapHint(hint: string) {
        return <div className="rpfe-hint">{hint}</div>;
    }

    public static wrapInput(input: string) {
        return <>{input}</>;
    }

    public static wrapComponent(component: any) {
        return component;
    }

    public static wrapComponentCSS(css = "") {
        css = css.replace(/powerform-cols/, this.columnClass());
        return css;
    }

    public static wrapField(field: any) {
        return <>{field}</>;
    }

    public static rowClass(row: any) {
        return "";
    }

    public static wrapFieldset(field: any) {
        return <div className="form-group">{field}</div>;
    }

    public static wrapRow(field: any) {
        return <div className="row">{field}</div>;
    }

    public static columnClass() {
        return "col-sm";
    }
}

export const Field = ({
    editMode = true,
    readonly = false,
    field,
    register,
    initialValue = {},
    extraProps = {},
    variables = {},
    Component = field.InputComponent,
}: any) => {
    (window as any).EDIT_MODE = editMode;
    return (
        // <ErrorBoundary
        //     title={field.label}
        //     message={"Dieses Feld ist fehlerhaft und es wurde zur Sicherheit entfernt."}
        // >
        <field.WrapComponent
            layout={BootstrapLayout}
            field={field}
            entry={null}
            component={
                <Component
                    readonly={readonly}
                    layout={BootstrapLayout}
                    register={register}
                    field={field}
                    {...extraProps}
                    variables={variables}
                    rawValue={initialValue[field.hashCode]}
                    entry={initialValue}
                    saveState={initialValue}
                />
            }
        />
        // </ErrorBoundary>
    );
};
