import { PrimaryButton } from "@rpforms/shared";
import { batchDelete } from "@rpforms/shared/build/actions/actions_helper";
import { fetchDeviceTasks } from "@rpforms/shared/build/actions/tasks";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

const TaskListRow = styled.div`
    padding: 5px 0px;
    margin: 5px 0px;
    display: flex;
`;

export const ListDeviceTasks = ({
    deviceTasks,
    deletable = true,
    calendarEvent,
}: {
    deviceTasks: any;
    deletable: boolean;
    calendarEvent?: any;
}) => {
    const [selectedDeviceTaskIds, setSelectedDeviceTaskIds] = useState([]);

    const dispatch = useDispatch();

    const deleteDeviceTasks = () => {
        if (selectedDeviceTaskIds.length === deviceTasks.length) {
            toast.error("Ein Event kann nicht ohne Aufgaben existieren", { autoClose: 2000 });
            return;
        }
        return dispatch(
            batchDelete("device_tasks", selectedDeviceTaskIds, () => {
                dispatch(fetchDeviceTasks(calendarEvent.id));
            })
        );
    };

    const toggleDeviceTaskId = (dt) => {
        const tId = dt.target.value;
        const deviceTaskId = parseInt(tId, 10);
        if (!selectedDeviceTaskIds.includes(deviceTaskId)) {
            setSelectedDeviceTaskIds(selectedDeviceTaskIds.concat([deviceTaskId]));
        } else {
            setSelectedDeviceTaskIds(selectedDeviceTaskIds.filter((a) => a !== deviceTaskId));
        }
    };

    return (
        <>
            {deviceTasks.map((dt) => {
                const id = dt.id;
                return (
                    <TaskListRow key={id}>
                        <Form.Check type="checkbox" id={"deviceTask-" + id}>
                            {deletable && (
                                <Form.Check.Input
                                    checked={selectedDeviceTaskIds.includes(id)}
                                    type="checkbox"
                                    value={id}
                                    onChange={toggleDeviceTaskId}
                                />
                            )}
                            <Form.Check.Label className={"mt-0"}>
                                <span>
                                    {dt.form_name}{" "}
                                    {dt.entity_name && (
                                        <span>
                                            <i className={"fa fa-home"}></i> {dt.entity_name}
                                        </span>
                                    )}{" "}
                                </span>
                            </Form.Check.Label>
                        </Form.Check>
                        {dt.is_overwrite && (
                            <span style={{ fontStyle: "italic", marginLeft: "5px" }}>
                                (Überschreibt alten Eintrag)
                            </span>
                        )}
                    </TaskListRow>
                );
            })}
            <div className="row ml-1">
                <PrimaryButton onClick={deleteDeviceTasks}>
                    {selectedDeviceTaskIds.length > 1 ? "Aufgaben löschen" : "Aufgabe löschen"}
                </PrimaryButton>
            </div>
        </>
    );
};
