import axios from "axios";
import { API_URL } from "../config";
import { sharedHistory } from "../history";
import { user } from "./actions_helper";
import { SET_COMPANY, UPDATE_COMPANY } from "./types";

export const fetchCompany = (companyId: number) => {
    const headers = user() ? { authorization: user().token } : {};

    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        try {
            if (localStorage.customer) {
                return dispatch({
                    payload: JSON.parse(localStorage.customer),
                    type: SET_COMPANY,
                });
            }
        } catch (e) {}

        axios
            .get(`${API_URL}/companies/${companyId}.json`, { headers })
            .then((response) => {
                localStorage.setItem("customer", JSON.stringify(response.data));
                dispatch({
                    payload: response.data,
                    type: SET_COMPANY,
                });
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    sharedHistory.push("/auth/login");
                }
            });
    };
};

export const updateCompany = (companyId: number, data: any) => {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        axios
            .patch(
                `${API_URL}/companies/${companyId}.json`,
                {
                    company: data,
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: UPDATE_COMPANY,
                });
            });
    };
};
