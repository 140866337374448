import React from "react";
import { Route, Switch } from "react-router-dom";
import CalendarPage from "./CalendarPage";
import NewCalendarEventPage from "../devices/NewCalendarEventPage";

const RoutingPage = () => {
    return (
        <>
            <Switch>
                <Route path="/calendar" exact component={CalendarPage} />
                <Route path="/calendar/vacations" exact component={CalendarPage} />
                <Route path="/calendar/planning" exact component={CalendarPage} />

                <Route exact path="/calendar/event/new" component={NewCalendarEventPage} />
                <Route exact path="/calendar/event/:id" component={NewCalendarEventPage} />
            </Switch>
        </>
    );
};

export default RoutingPage;
