import React from "react";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";
import { AppearanceText } from "./fields/AppearanceText";
import { AppearanceTextarea } from "./fields/AppearanceTextarea";

/**
 * Applies maximum length to input field
 */
export class Hint extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = "";
    public keyName = "hint";
    public title = "Hinweistext";
    public description =
        "Hier können Sie einen Hinweistext definieren der unter dem Feld angezeigt wird.";
    public value = "";

    constructor({ register, schema, designer, field }) {
        super({ register, schema, designer, field });
    }

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: any;
    }): any {
        return (
            <AppearanceTextarea
                register={register}
                value={field.rawOptions[this.keyName] || this.defaultValue}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value;
    }

    public wakeValue(value) {
        return value;
    }
}
