import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import {getFolderLocation, openInFolder} from "@rpforms/shared";
import { RootState } from "../../reducers";
import {push} from "connected-react-router";

interface IndicatorProps {
    color: string;
}

const ListContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin-top: 1rem;
    min-height: 250px;
`;

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
    margin-top: 5px;
    padding: 0.5rem 1rem;

    .left {
        display: flex;
    }

    &:nth-child(2n) {
        background-color: #dfdfdf;
    }

    button {
        border: none;
        background-color: transparent;
    }
`;

const StateIndicator = styled.span<IndicatorProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    width: 12px;
    height: 12px;
    padding: 12px;
    margin-right: 2rem;
`;

const FormEntry = styled.div`
    display: flex;
    align-items: center;
`;

export default function ListInvoices() {
    const { form_entries } = useSelector<RootState, any>((state) => state.finances);

    const dispatch = useDispatch();

    const clickHandler = async  (id) => {
        const req = await dispatch(
            getFolderLocation({
                resource_id: id,
                resource_type: "FormEntry",
            })
        );
        dispatch(push("/folders?open=" + encodeURIComponent(JSON.stringify(req.data))));
    };

    return (
        <ListContainer>
            {form_entries?.slice(0, 5).map((entry) => (
                <ListItem key={entry.id}>
                    <div className="left">
                        <StateIndicator color={entry?.state === "paid" ? "green" : "red"} />
                        <FormEntry>{entry.name}</FormEntry>
                    </div>
                    <button onClick={() => clickHandler(entry.id)}>Ansehen</button>
                </ListItem>
            ))}
        </ListContainer>
    );
}
