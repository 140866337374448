import {
    clearFolders,
    setFo,
    clearFolderslderSelection,
    setFolderSelection,
} from "@rpforms/shared";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "../../reducers";

import { fetchDirectory, fetchFolders } from "@rpforms/shared/build/actions/folders";
import { FolderNavigation } from "../../components/folders/FolderNavigation";
import { push } from "connected-react-router";

const FoldersPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFolderSelection(false));
        const folderPath = sessionStorage.getItem("folderPath");
        if (
            folderPath &&
            folderPath !== "" &&
            folderPath !== "/folders?open=%5B%5D" &&
            (document.location.search === "?ref=nav" || document.location.search === "?open=%5B%5D")
        ) {
            dispatch(push(folderPath));
        }
        dispatch(clearFolders());
        dispatch(fetchDirectory());
    }, []);

    return (
        <div>
            <FolderNavigation />
        </div>
    );
};
export default withRouter(FoldersPage);
