import { FORM_ENTRIES_SET_MIGRATION_PANES, getFormEntry, NEW_MIGRATION } from "@rpforms/shared";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { MigrationPane } from "../../../components/migrations/MigrationPane";
import MigrationPaneActions from "../../../components/migrations/MigrationPaneActions";
import Migrator from "../../../components/migrations/Migrator";

export const AlignHorizontal = styled.div`
    display: flex;
`;

export const MigrationEntryPage = () => {
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { sourceId } = useParams();
    const migrationPanes = useSelector<any, any>((state) => state.formEntries.migrationPanes);
    const [revertData, setRevertData] = useState(null);

    const addPane = (formEntry) => {
        dispatch({
            type: FORM_ENTRIES_SET_MIGRATION_PANES,
            payload: [...migrationPanes, formEntry],
        });
    };

    const setPanes = (formEntries) => {
        dispatch({
            type: FORM_ENTRIES_SET_MIGRATION_PANES,
            payload: formEntries,
        });
    };

    // preload required data
    useEffect(() => {
        const preload = async () => {
            if (sourceId) {
                dispatch(
                    getFormEntry(sourceId, (formEntryData, formValues) => {
                        setPanes([
                            { form_entry: { ...formEntryData } },
                            {
                                form_entry: {
                                    ...formEntryData,
                                    id: null,
                                    form_data: {},
                                    form_revision: { ...formEntryData.latest_revision },
                                    form_revision_id: formEntryData.latest_revision_id,
                                },
                                source_form_entry: { ...formEntryData },
                                isHidden: false,
                            },
                        ]);
                    })
                );
            }
        };
        preload();
    }, [sourceId]);

    const setPaneFormData = (to, formData) => {
        const newPanes = migrationPanes.map((p, i) => {
            if (i === to) {
                return {
                    ...p,
                    form_entry: {
                        ...p.form_entry,
                        form_data: formData,
                    },
                    isHidden: true,
                };
            }
            return p;
        });
        const newPanesVisible = migrationPanes.map((p, i) => {
            if (i === to) {
                return {
                    ...p,
                    form_entry: {
                        ...p.form_entry,
                        form_data: formData,
                    },
                    isHidden: false,
                };
            }
            return p;
        });
        requestAnimationFrame(() => {
            setPanes(newPanes);
            setPanes(newPanesVisible);
        });
    };

    const revertCopyFormData = () => {
        setPaneFormData(1, { ...revertData });
        setRevertData(null);
    };

    const getAllFieldsBy = (fields, condition) => {
        const key = "nonmigratable";
        let results = [];
        (fields || []).forEach((field) => {
            if (field.options[key] === condition) {
                results.push(field);
            }
            results = results.concat(getAllFieldsBy(field.subfields, condition));
        });

        return results;
    };

    const copyFormData = (from, to) => {
        const sourceFormDataStr = migrationPanes[to].form_entry?.form_revision?.form_data;
        const sourceFormData =
            "object" === typeof sourceFormDataStr
                ? sourceFormDataStr
                : JSON.parse(sourceFormDataStr || "{}");
        const nonApplyableFieldIds = getAllFieldsBy(sourceFormData.fields, true).map(
            (field) => field.hashCode
        );
        setRevertData({ ...migrationPanes[to].form_entry.form_data });
        const overrideData = { ...migrationPanes[from].form_entry.form_data };
        nonApplyableFieldIds.forEach((hc) => {
            delete overrideData[hc];
        });
        const newFormData = {
            ...migrationPanes[to].form_entry.form_data,
            ...overrideData,
        };
        setPaneFormData(to, newFormData);
    };

    const openSubmitFormEntryModal = () => {
        dispatch(
            openEditor({
                endpoint: `migrate`,
                endpointCompany: false,
                preload: migrationPanes,
                type: NEW_MIGRATION,
            })
        );
    };

    return (
        <>
            <Switch>
                <Route>
                    <PageNavbar
                        title={`Editor`}
                        description={
                            "Hier werden Formulare verwendet um ein neues zu erstellen. Wenn sie der gleichen Formulargruppe angehören, werden sie im gleichen Ordner abgespeichert"
                        }
                        subtitle={"Migrieren"}
                    >
                        <PageNavAction
                            onClick={() => window.history.back()}
                            icon="chevron-left"
                            title="Zurück"
                        />
                        {!isLoading && (
                            <PageNavAction
                                onClick={openSubmitFormEntryModal}
                                success={true}
                                icon="save"
                                title="Speichern"
                            />
                        )}
                    </PageNavbar>
                </Route>
            </Switch>
            <Migrator>
                {loading && (
                    <div>
                        <Loader />
                    </div>
                )}
                {!loading &&
                    migrationPanes.map((pane: any, index) => (
                        <AlignHorizontal key={index}>
                            <MigrationPane migrationPaneIndex={index} />
                            <MigrationPaneActions
                                copyFormData={copyFormData}
                                undoCopyFormData={revertCopyFormData}
                                undoable={revertData != null}
                                index={index}
                                panes={migrationPanes}
                            />
                        </AlignHorizontal>
                    ))}
            </Migrator>
        </>
    );
};

export default MigrationEntryPage;
