import React from "react";
import { Route, Switch } from "react-router-dom";
import ListNotificationPage from "./ListNotificationPage";

export const NotificationPage = () => {
    return (
        <Switch>
            <Route path="/notifications/all" exact component={ListNotificationPage} />
        </Switch>
    );
};

export default NotificationPage;
