import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import React from "react";
import CustomerListRow from "./CustomerListRow";

const CustomerList = ({ customers }) => {
    return (
        <>
            {customers.length === 0 && (
                <EmptySpace
                    link="/master_data/customers/new"
                    title="Keine Kunden gefunden"
                    description="Nachdem Sie einen Kunden hinzugefügt haben, taucht er auf dieser Seite auf."
                    cta="Kunde anlegen"
                />
            )}

            {customers.map((customer) => {
                return <CustomerListRow key={customer.id} customer={customer} />;
            })}
        </>
    );
};

export default CustomerList;
