import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import { SET_DEVICE_TASKS } from "./types";

export const fetchDeviceTasks = (eventId, cleanupTasks = false, showSubmitted = false) => {
    return (dispatch: IDispatch<any>) =>
        axios
            .get(
                `${API_URL}/calendar_events/${eventId}/device_tasks.json?showSubmitted=${showSubmitted}`,
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: SET_DEVICE_TASKS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            });
};
