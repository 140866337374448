import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { useViewMode, ViewMode } from "../hooks/useViewMode";
import { getField, uniqueID } from "../utils";
import { DefaultValue, Description, Hint, MaxLength, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { HashCode } from "./configurables/HashCode";
import { DateField } from "./DateField";
import { Label, LabelInput } from "./inputFields/label";

export class RescheduleField extends DateField {
    public name = "Reschedule";
    public label = "";
    public hashCode = uniqueID();
    public icon = "repeat";
    public fieldType = "Wiederholen";

    public attributeNames = ["collapsed", "source"];

    public getConfigurables() {
        return [HashCode, AutoFill, MaxLength, Required, Description, DefaultValue, Width, Hint];
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
        rawValue,
        readonly = false,
    }) {
        const [value, setValue] = useState(rawValue);
        const [duration, setDuration] = useState<any>(Number(rawValue || 0));
        const [source, setSource] = useState<any>(false);
        const viewMode = useViewMode();

        useEffect(() => {
            const sourceField = getField(field.source, field.formName);
            if (field) {
                setSource(sourceField);
            }

            const refreshTimer = setInterval(() => {
                const dateField = getField(field.source, field.formName);
                if (dateField !== source) {
                    setSource(dateField);
                }
            }, 100);

            return () => {
                clearInterval(refreshTimer);
            };
        }, [value, duration]);

        const rescheduleAfter = useMemo(() => {
            if (!source) {
                return "";
            }

            if (!duration) {
                return "o.A.";
            }

            return moment(source).add(duration, "months").format("DD.MM.YYYY");
        }, [duration, source]);

        const rescheduleAfterDate = useMemo(() => {
            if (!source) {
                return "";
            }

            if (!duration) {
                return "o.A.";
            }

            return moment(source).add(duration, "months").format("YYYY-MM-DD");
        }, [duration, source]);

        return layout.wrapComponent(
            <div className={layout.wrapComponentCSS(field.configureCSS())}>
                {layout.wrapLabel(field.configureLabel(<Label layout={layout} field={field} />))}
                {viewMode === ViewMode.EDIT && (
                    <select
                        disabled={readonly}
                        onChange={({ currentTarget }) => setDuration(currentTarget.value)}
                        className={"form-control"}
                        defaultValue={value}
                        name={"reschedule_after"}
                    >
                        <option value={0}>o.A.</option>
                        <option value={3}>3 Monate</option>
                        <option value={6}>6 Monate</option>
                        <option value={12}>1 Jahr</option>
                        <option value={24}>2 Jahre</option>
                        <option value={48}>4 Jahre</option>
                        <option value={96}>8 Jahre</option>
                    </select>
                )}
                {viewMode !== ViewMode.EDIT && <span>{rescheduleAfter}</span>}
                <input
                    type={"hidden"}
                    value={rescheduleAfterDate}
                    name={field.hashCode + "_date"}
                />
                <input type={"hidden"} value={duration} name={field.hashCode} />
            </div>
        );
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field, provided = null }) {
        return (
            <FieldWrapper
                register={register}
                provided={provided}
                field={field}
                schema={schema}
                designer={designer}
            >
                <div>
                    <LabelInput register={register} field={field} />
                </div>

                <div className="row">
                    <div className="col">
                        <label className="mt-3">Quelle</label>
                        <div className="text-muted">
                            Auf welches Datumsfeld soll sich dieses Feld beziehen?
                        </div>

                        <input
                            type={"string"}
                            name={field.inputName("source")}
                            className="form-control"
                            defaultValue={field.source || ""}
                            ref={register}
                        />
                    </div>
                </div>
            </FieldWrapper>
        );
    }
}
