import { fetchAllFormEntries } from "@rpforms/shared";
import { toSelectOptions } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const FormEntrySelector = ({
    onSelect,
    defaultValue = null,
    filterBy = (formEntry) => true,
    opts = {},
    includeAllOption = false,
}) => {
    const [value, setValue] = useState({});

    const formEntries = useSelector<RootState, any>((store) => store.formEntries.list);
    const defaultFormEntry = useSelector<RootState, any>((store) => {
        return typeof defaultValue === "number"
            ? toSelectOptions([store.formEntries.list.find((c) => c.id === defaultValue)])[0]
            : defaultValue;
    });

    const dot = (b) =>
        b
            ? {
                  alignItems: "center",
                  display: "flex",

                  ":before": {
                      backgroundColor: "#FF0000",
                      borderRadius: 10,
                      content: "' '",
                      display: "block",
                      marginRight: 8,
                      height: 10,
                      width: 10,
                  },
              }
            : {
                  alignItems: "center",
                  display: "flex",
              };

    const taggedStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const e = formEntries.find((c) => c.id === data.value);
            const dotStyle = dot(e && e.terms && e.terms.length > 0);
            return {
                ...styles,
                ...dotStyle,
            };
        },
        singleValue: (styles, { data }) => {
            const e = formEntries.find((c) => c.id === data.value);
            const dotStyle = dot(e && e.terms && e.terms.length > 0);
            return { ...styles, ...dotStyle };
        },
    };

    const options = () => {
        if (includeAllOption) {
            return [
                {
                    value: 0,
                    label: "---ALLE---",
                },
                ...toSelectOptions(formEntries.filter(filterBy)),
            ];
        } else {
            return toSelectOptions(formEntries.filter(filterBy));
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchAllFormEntries(
                1,
                (formEntriesData) => {
                    setValue(
                        toSelectOptions(
                            formEntriesData.form_entries.filter((c) => c.id === defaultValue)
                        )[0]
                    );
                },
                false,
                opts,
                10
            )
        );
    }, []);

    const onChange = (x) => {
        setValue(x);
        onSelect(x);
    };

    return (
        <Select
            onChange={onChange}
            options={options()}
            defaultValue={defaultFormEntry}
            value={value}
            styles={taggedStyles}
        />
    );
};
