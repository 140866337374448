import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { BaseField } from "../BaseField";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";

export class TableRow extends BaseField implements ISubfields {
    public subfields = [];
    public label = "Column";
    public icon = "columns";
    public fieldType = "Spalte";

    public getConfigurables(): any[] {
        return [Width];
    }

    public toHotColumn() {
        return {};
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        const handleNewField = (fieldClass) => {
            schema.subfield(field, new fieldClass(schema));
            designer.save(schema, { delaySave: false });
        };

        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <div className="form-group p-3">
                    <div className="powerform-row-edit">
                        {field.subfields ? (
                            field.subfields.map((subfield) => (
                                <subfield.EditComponent
                                    designer={designer}
                                    register={register}
                                    key={subfield.hashCode}
                                    schema={schema}
                                    field={subfield}
                                    entry={null}
                                />
                            ))
                        ) : (
                            <span></span>
                        )}
                    </div>
                    <div className="pl-4 pr-4">
                        {/*<AddField small={true} handleNewField={handleNewField}/>*/}
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({ schema, layout, field, entry = null, register }) {
        return layout.wrapComponent(
            <div className={layout.columnClass() + "-" + 12}>
                {field.subfields ? (
                    field.subfields.map((subfield) => (
                        <subfield.InputComponent
                            layout={layout}
                            register={register}
                            key={subfield.hashCode}
                            schema={schema}
                            field={subfield}
                            entry={null}
                        />
                    ))
                ) : (
                    <span></span>
                )}
            </div>
        );
    }
}
