import {getManufacturers} from "@rpforms/shared/build/actions";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from "react-select/creatable";

import {RootState} from "../../reducers";

export const ManufacturerSelect = ({...props}) => {
    const manufacturers = useSelector<RootState, any>((store) => store.manufacturers.manufacturers);
    const isLoading = useSelector<RootState, any>((store) => store.manufacturers.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getManufacturers());
    }, []);

    const toSelect = (category) => {
        return {
            value: category.id,
            label: category.name,
        };
    };

    const formatCreatingLabel = (inputValue) => {
        return `Erstelle … ${inputValue}`;
    };

    return (
        <CreatableSelect
            options={manufacturers.map(toSelect)}
            isLoading={isLoading}
            isDisabled={isLoading}
            formatCreateLabel={formatCreatingLabel}
            {...props}
        />
    );
};
