import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";
import { Width } from "./configurables";
import { ISubfields } from "./interfaces/ISubfields";

export class ColumnField extends BaseField implements ISubfields {
    public subfields = [];
    public label = "Spalte";
    public icon = "columns";
    public fieldType = "Spalte";

    public getConfigurables() {
        return [Width];
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper small={true} designer={designer} schema={schema} field={field}>
                <div className="form-group p-1">
                    <div className="powerform-row-edit">
                        {field.subfields ? (
                            field.subfields.map((subfield) => (
                                <subfield.EditComponent
                                    designer={designer}
                                    register={register}
                                    key={subfield.hashCode}
                                    schema={schema}
                                    field={subfield}
                                    entry={null}
                                />
                            ))
                        ) : (
                            <span />
                        )}
                    </div>
                    <div className="mt-2 ">
                        {/*<AddField small={true} handleNewField={handleNewField} />*/}
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public InputComponent({
        schema,
        layout,
        field,
        entry = null,
        saveState,
        variables,
        name = field.hashCode,
        register,
        readonly = false,
    }) {
        let colWidth = 12;
        const configurables = field.options.filter((option) => option instanceof Width);
        if (configurables.length) {
            colWidth = configurables[0].value;
        }

        return layout.wrapComponent(
            <div className={layout.columnClass() + "-" + colWidth + " powerform-column"}>
                {layout.wrapDescription(
                    field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                )}
                {field.subfields ? (
                    field.subfields.map((subfield, index) => {
                        const rawValue = saveState[subfield.hashCode];
                        return (
                            <subfield.InputComponent
                                readonly={readonly}
                                layout={layout}
                                key={subfield.hashCode + "_" + index}
                                register={register}
                                name={subfield.hashCode}
                                schema={schema}
                                field={subfield}
                                entry={entry}
                                rawValue={rawValue}
                                variables={variables}
                                saveState={saveState}
                            />
                        );
                    })
                ) : (
                    <span />
                )}
            </div>
        );
    }
}
