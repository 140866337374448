import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import {
    CALENDAR_EVENTS_LOADING_START,
    CREATE_TIME_TRACKING,
    DELETE_TIME_TRACKING,
    GET_TIME_TRACKING,
    UPDATE_TIME_TRACKING,
} from "./types";
import moment from "moment/moment";

export function deleteTimeTracking(id: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/timetrackings/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: DELETE_TIME_TRACKING,
                });
            })
            .catch((err) => console.error(err));
}

export function getTimeTrackings(employeeId, range, view) {
    return (dispatch: IDispatch<any>) => {
        dispatch({ type: CALENDAR_EVENTS_LOADING_START });
        axios
            .get(
                `${API_URL}/timetrackings.json?view=${view}&employee=${employeeId}&start=${range.start}&end=${range.end}`,
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_TIME_TRACKING,
                    payload: response.data,
                });
            });
    };
}

export function createTimeTracking(timeTracking) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .post(`${API_URL}/timetrackings.json`, timeTracking, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: CREATE_TIME_TRACKING,
                });
            })
            .catch((err) => console.error(err));
}

export function updateTimeTracking(timeTracking) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .put(`${API_URL}/timetrackings/${timeTracking.id}.json`, timeTracking, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: UPDATE_TIME_TRACKING,
                });
            })
            .catch((err) => console.error(err));
}

export function createPdf(id: any) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios
            .get(`${API_URL}/timetrackings/pdf?employee=${id}.json`, {
                headers: {
                    authorization: user().token,
                },
                responseType: "blob",
            })
            .then((response) => response.data)
            .then((blob) => {
                const data = window.URL.createObjectURL(blob);
                window.location.href = data;
            })
            .catch((err) => console.error(err));
    };
}
