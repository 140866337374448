import {inventoryItemFields} from "@rpforms/shared";
import React from "react";

export const InventoryItemTableFoot = ({data, type = "repair"}) => {
    return (
        <tfoot>
        <tr>
            <td>
                <small>
                    {data.total} {data.total === 1 ? "Ware" : "Waren"}
                </small>
            </td>
            {(inventoryItemFields(type).length - 5) > 0 &&
                <td colSpan={inventoryItemFields(type).length - 5}></td>
            }
            <td className={"text-right"}>
                {type === "disposal" && (
                    <small>
                        Gesamt: <strong>{data.total_quantity}</strong>
                    </small>
                )}
            </td>
            {(inventoryItemFields(type).length - 7) > 0 &&
                <td colSpan={inventoryItemFields(type).length - 7}></td>
            }
            <td className={"text-right"}>
                {type === "disposal" && (
                    <small>
                        Lagerbestandswert:
                        <strong>{data.total_buying_price_formatted}</strong>
                    </small>
                )}
            </td>
            <td></td>
        </tr>
        </tfoot>
    );
};

export default InventoryItemTableFoot;
