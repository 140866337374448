import React, { useState } from "react";
import { Gallery } from "../../images/Gallery";
import { UploadPreview } from "./UploadPreview";

export const UploadsList = ({ uploads, onEditUpload, onDeleteUpload }: any) => {
    const [activeUploadIndex, setActiveUploadIndex] = useState(null);
    return (
        <>
            {activeUploadIndex !== null && (
                <Gallery
                    images={uploads}
                    startingActiveIndex={activeUploadIndex}
                    onClose={() => setActiveUploadIndex(null)}
                />
            )}
            {uploads.map((upload: any, index: number) => (
                <UploadPreview
                    key={upload.uuid ?? upload.id ?? upload.upload_id}
                    upload={upload}
                    onDeleteUpload={onDeleteUpload}
                    onEditUpload={onEditUpload}
                    setActiveUploadIndex={setActiveUploadIndex}
                    index={index}
                />
            ))}
        </>
    );
};
