import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../reducers";
import { withRouter } from "react-router-dom";
import EntityList from "../../../components/entities/EntityList";
import * as actions from "@rpforms/shared/build/actions/entities";

export const ListEntityPage = (props) => {
    const dispatch = useDispatch();
    const entities = useSelector<RootState, any>((store) => store.entities.autocomplete);
    const untaggedEntities = () => {
        return entities.filter(
            (e) => e.terms && e.terms.filter((t) => t.name === "Gekündigt").length <= 0
        );
    };

    const handleEntityFilter = () => {
        if (props.entityFilter === "wartungsvertrag")
            return entities.filter(
                (e) => e.terms.filter((t) => t.name === "Wartungsvertrag").length > 0
            );
        return entities;
    };

    useEffect(() => {
        dispatch(actions.getEntitiesForMasterData());
    }, []);

    useEffect(() => {
        props.setNumberOfEntries(untaggedEntities().length);
    }, [untaggedEntities]);

    return (
        <Content>
            <EntityList entities={handleEntityFilter()} />
        </Content>
    );
};

export default withRouter(ListEntityPage);
