import { PrimaryButton, SuccessButton } from "@rpforms/shared";
import React from "react";
import useForm from "react-hook-form";
import { Field } from "./Field";
import AddField from "./AddField";
/**
 * Provides basic form control for modifying form properties
 * @param schema
 * @param designer
 * @param handleFormSave
 * @constructor
 */
export const FormDesigner = ({ schema, designer, handleFormSave }) => {
    const { handleSubmit, register, errors }: any = useForm();
    const onSubmit = (values) => {
        handleFormSave(values);
    };

    // @ts-ignore
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {schema.fields().map((field, index) => {
                return (
                    <div key={field.hashCode}>
                        <Field
                            Component={field.EditComponent}
                            field={field}
                            register={register}
                            extraProps={{ schema, designer }}
                        />
                        {index < schema.fields().length - 1 && (
                            <AddField
                                showCustomFields={true}
                                attached={true}
                                small={true}
                                after={field}
                                handleNewField={designer.newFieldBelow(field).bind(designer)}
                            />
                        )}
                    </div>
                );
            })}

            <div className="d-flex mt-5 pr-0 pl-0">
                <SuccessButton id={"formSaveBtn"} success={true} type="submit">
                    <i className="fa fa-save"></i> Formular speichern
                </SuccessButton>

                <AddField
                    showCustomFields={true}
                    handleNewField={designer.newField.bind(designer)}
                />

                <PrimaryButton
                    id={"moveUp"}
                    onClick={() => {
                        document.querySelector("html").scrollTop = 0;
                        return false;
                    }}
                    type="button"
                >
                    <i className="fa fa-chevron-up"></i> Nach oben
                </PrimaryButton>
            </div>
        </form>
    );
};
