import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import {
    ADD_CUSTOM_FIELD,
    CREATE_CUSTOM_FIELD,
    DELETE_CUSTOM_FIELD,
    GET_CUSTOM_FIELDS,
    UPDATE_CUSTOM_FIELD,
} from "./types";

export const getCustomFields = () => {
    return (dispatch: IDispatch<any>) =>
        axios
            .get(`${API_URL}/custom_fields.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_CUSTOM_FIELDS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            });
};

export const getCustomFieldByID = (id: number) => {
    return (dispatch: IDispatch<any>) =>
        axios
            .get(`${API_URL}/custom_fields/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({ type: ADD_CUSTOM_FIELD, payload: response.data });
            })
            .catch((err) => {
                throw err;
            });
};

export const updateCustomField = (customField: any, data: any) => {
    return (dispatch: IDispatch<any>) =>
        axios
            .patch(`${API_URL}/custom_fields/${customField.id}.json`, data, {
                headers: { authorization: user().token },
            })
            .then((res) => {
                dispatch({
                    type: UPDATE_CUSTOM_FIELD,
                    payload: res.data,
                });
            })
            .catch((err) => {
                throw err;
            });
};

export const createCustomField = (customField: any) => {
    return (dispatch: IDispatch<any>) =>
        axios
            .post(
                `${API_URL}/custom_fields.json`,
                {
                    custom_field: {
                        ...customField,
                        company_id: COMPANY_ID,
                    },
                },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((res) => {
                getCustomFields()(dispatch);
                dispatch({ type: CREATE_CUSTOM_FIELD, payload: res.data });
            })
            .catch((err) => {
                throw err;
            });
};

export const deleteCustomField = (id: number) => {
    return (dispatch: IDispatch<IReduxAction<JSON>>) => {
        axios
            .delete(`${API_URL}/custom_fields/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                getCustomFields()(dispatch);
                dispatch({
                    type: DELETE_CUSTOM_FIELD,
                    payload: response.data,
                });
            });
    };
};
