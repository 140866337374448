import {
    FINANCES_CHANGE_INVOICE_TYPE,
    FINANCES_CUSTOMER_CHANGED,
    FINANCES_ENTITY_CHANGED,
    FINANCES_RESET_STATE,
    FINANCES_SET_DATE,
    getTotalRevenues,
} from "@rpforms/shared";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { default as React, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, withRouter } from "react-router";
import styled from "styled-components";
import { OffersOverview } from "../../components/finances/OffersOverview";
import { TotalOverview } from "../../components/finances/TotalOverview";

export const FinanceContainer = styled.div`
    display: flex;
    background: #fff;
    padding: 20px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #f1f1f1;
`;

export enum FinancesType {
    OFFER = "offer",
    INVOICE = "invoice",
}

export const FinancesPage = () => {
    const location = useLocation();
    const view = location.pathname.split("/").pop();

    const dispatch = useDispatch();
    const nowFunc = () => {
        const date = new Date();
        date.setUTCHours(0, 0, 0, 0);
        return date;
    };
    const yesterdayFunc = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    };
    const today = nowFunc();
    const yesterday = yesterdayFunc();
    const [maxDate, setMaxDate] = useState(+yesterday);

    useEffect(() => {
        // load sum
        if (maxDate != null && view === "finances") {
            dispatch(getTotalRevenues(new Date(maxDate)));
            return () => dispatch({ type: FINANCES_RESET_STATE });
        }
    }, [maxDate]);

    return (
        <>
            <PageNavbar
                title={"Finanzen"}
                description={"Hier können Sie Ihre Finanzen und Statistiken verwalten"}
            >
                <Tab.Container defaultActiveKey={view}>
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="finances" href="/finances">
                                Finanzübersicht
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="offers" href="/finances/offers">
                                Angebotsübersicht
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>
            </PageNavbar>
            <Content>
                <FinanceContainer>
                    <Switch>
                        <Route
                            path="/finances"
                            exact
                            component={() => (
                                <TotalOverview
                                    maxDate={maxDate}
                                    setMaxDate={setMaxDate}
                                    yesterday={yesterday}
                                    today={today}
                                />
                            )}
                        />
                        <Route
                            path="/finances/offers"
                            component={() => <OffersOverview maxDate={maxDate} />}
                        />
                    </Switch>
                </FinanceContainer>
            </Content>
        </>
    );
};

export default withRouter(FinancesPage);
