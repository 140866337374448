import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";

export const user = () => JSON.parse(localStorage.getItem("user") || "{}") || {};

const typeStr = (type) => type.toUpperCase();

export const defaultOpts = {
    page_size: undefined,
    page: undefined,
    hidden_fields: undefined,
};
/*
const controller = new AbortController();

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const cancelCollectionRequest = () => {
    source.cancel('Operation canceled by the user.');
    controller.abort();
};
*/
export const getCollection = (
    type = "form_entries",
    opts?: {
        page_size?: number;
        page?: number;
        hidden_fields?: string;
    },
    cb = (data) => {},
    pathPrefix = "/"
) => {
    const companyTypes = ["entities", "customers", "inventory_items"];
    const prefixPath = companyTypes.includes(type)
        ? `/companies${pathPrefix}${COMPANY_ID}/`
        : pathPrefix;
    const qs =
        Object.keys(opts).length > 0
            ? "?without_data=true&" +
              Object.keys(opts)
                  .map((k) => {
                      if (Array.isArray(opts[k])) {
                          // deep combine
                          return opts[k]
                              .map((v) => {
                                  if (!v) {
                                      return "";
                                  }
                                  return `${k}=${v}`;
                              })
                              .join("&");
                      }
                      return `${k}=${opts[k]}`;
                  })
                  .join("&")
            : "";
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({ type: "LOADING_START" });
        dispatch({ type: typeStr(type) + "_LOADING_START" });
        return axios
            .get(`${API_URL}${prefixPath}${type}.json${qs}`, {
                headers: { authorization: user().token },
                /*cancelToken: source.token,
                signal: controller.signal // >= v22*/
            })
            .then((response) => {
                dispatch({
                    type: "GET_" + typeStr(type),
                    payload: response.data,
                });
                dispatch({
                    type: typeStr(type) + "_LIST_LOADED",
                    payload: response.data[type],
                });
                dispatch({ type: typeStr(type) + "_LOADING_STOP" });
                dispatch({ type: "LOADING_STOP" });
                setTimeout(() => {
                    cb(response.data);
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const createResource = (type = "uploads", data, cb = (callbackData) => {}) => (
    dispatch: IDispatch<any>
) => {
    dispatch({ type: "LOADING_START" });
    dispatch({ type: typeStr(type) + "_LOADING" });
    return axios
        .post(`${API_URL}/${type}.json`, data, {
            headers: { authorization: user().token },
        })
        .then((response) => {
            dispatch({ type: "UPLOAD_CREATED", payload: response.data });
            dispatch({ type: typeStr(type) + "_LOADING_STOP" });
            dispatch({ type: "LOADING_STOP" });
            setTimeout(() => {
                cb(response);
            });
        })
        .catch((err) => {
            throw err;
        });
};

export const batchDelete = (dataType = "device_task", ids = [], cb = (callbackData) => {}) => (
    dispatch: IDispatch<any>
) => {
    dispatch({ type: "LOADING_START" });
    dispatch({ type: "DELETE_" + typeStr(dataType) + "_LOADING" });
    return axios
        .patch(
            `${API_URL}/delete_requests.json`,
            {
                type: dataType,
                ids: ids
                    .map((id) => parseInt(id, 10))
                    .filter((id) => id !== null)
                    .join(","),
            },
            {
                headers: { authorization: user().token },
            }
        )
        .then((response) => {
            dispatch({ type: "STOP_DELETE_" + typeStr(dataType) + "_LOADING" });
            dispatch({ type: "LOADING_STOP" });
            setTimeout(() => {
                cb(response);
            });
        })
        .catch((err) => {
            throw err;
        });
};
