import React from "react";
import { PrimaryButton, SmallPrimaryButton } from "../../components/Button";
import { FieldWrapper } from "../../components/FieldWrapper";
import { uniqueID } from "../../utils";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class SelectTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Auswahl";
    public icon = "list";
    public fieldType = "Auswahl";
    public items;
    public attributeNames = ["items"];
    public getConfigurables() {
        return [Width];
    }

    public toHotColumn() {
        return {
            editor: "select",
            selectOptions: this.items ? this.items.map((item) => item.name) : [],
        };
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        if (!field.items) {
            field.items = [];
        }

        const addItem = () => {
            field.items.push({
                name: "",
                key: "",
                id: uniqueID("*"),
                isDefault: false,
            });
        };

        const save = () => {
            schema.setFieldOption(field, "items", field.items);
        };

        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <input
                            type={field.designerInputType}
                            name={field.inputName("label")}
                            className="form-control"
                            defaultValue={field.label}
                            ref={register}
                        />
                    </div>
                </div>
                <div className="powerform-flex mt-3">
                    <div className="powerform-w100">
                        Auswahlmöglichkeiten
                        <br />
                        {field.items.map((item) => {
                            return (
                                <div key={item.id} className="row mb-2">
                                    <div className="col-6">
                                        <input
                                            onBlur={(ev) => {
                                                item.name = ev.target.value;
                                                save();
                                            }}
                                            className="form-control"
                                            type="text"
                                            placeholder={"Titel der Auswahl"}
                                            defaultValue={item.name}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <SmallPrimaryButton onClick={() => addItem()}>
                            Option hinzufügen
                        </SmallPrimaryButton>
                    </div>
                </div>
            </FieldWrapper>
        );
    }
}
