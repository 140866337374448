import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import {
    CREATE_VACATION,
    DELETE_VACATION,
    GET_VACATIONS,
    GET_VACATIONS_FOR_EMPLOYEE,
    UPDATE_VACATION,
} from "./types";

export function deleteVacation(vacation: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/vacations/${vacation.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: DELETE_VACATION,
                });
            })
            .catch((err) => console.error(err));
}

export function updateVacation(vacation: any) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .put(`${API_URL}/vacations/${vacation.id}.json`, vacation, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: UPDATE_VACATION,
                });
            })
            .catch((err) => console.error(err));
}

export function createVacation(vacation: any) {
    return async (dispatch: IDispatch<any>) => {
        try {
            const response = await axios.post(`${API_URL}/vacations.json`, vacation, {
                headers: { authorization: user().token },
            });
            dispatch({
                payload: response.data,
                type: CREATE_VACATION,
            });
        } catch (err) {
            throw err;
        }
    };
}

export const fetchVacationsForEmployee = (employee: any) => {
    return (dispatch: IDispatch<any>) => {
        axios
            .get(`${API_URL}/employees/${employee.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_VACATIONS_FOR_EMPLOYEE,
                    payload: response.data.vacations,
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const fetchVacations = () => {
    return (dispatch: IDispatch<any>) => {
        axios
            .get(`${API_URL}/vacations.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_VACATIONS,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};
