import React from "react";

export const renderField = ({ input, type, placeholder, meta: { touched, error } }) => (
    <div className={`mb-3 mt-2 input ${touched && error ? "is-invalid" : ""} `}>
        <input
            type={type}
            className={`form-control  ${touched && error ? "is-invalid" : ""} ${
                touched && !error ? "is-valid" : ""
            }`}
            placeholder={placeholder}
            {...input}
        />
        {touched && error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
);

export const renderTextarea = ({ input, type, placeholder, meta: { touched, error } }) => (
    <div className={`mb-3 mt-2 input ${touched && error ? "is-invalid" : ""} `}>
        <textarea
            rows={5}
            className={`form-control  ${touched && error ? "is-invalid" : ""} ${
                touched && !error ? "is-valid" : ""
            }`}
            placeholder={placeholder}
            {...input}
        />
        {touched && error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
);

export default renderField;
