import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import { FRONTEND_URL } from "../../config";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";

/**
 * Applies maximum length to input field
 */
export class Richtext extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = false;

    public keyName = "richtext";
    public title = "Formatierte Eingabe?";
    public description = "Stellt Textfeld als Richtext-Editor mit Formatierungsoptionen dar";
    public value = "";
    private counter = 0;

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: any;
    }): any {
        return (
            <AppearanceCheckbox
                label="Formatierte Eingabe"
                register={register}
                value={field.rawOptions[this.keyName]}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value !== false;
    }

    public configureInput(inputEl, register): any {
        /*this.counter++;
        if ((window as any).EDIT_MODE && this.counter <= 1 && this.value) {
            inputEl =
                ;
        }*/
        return inputEl;
    }

    public wakeValue(value) {
        return value;
    }
}
