import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

export const ModalAddressForm = ({ exclude = [], prefix = "", errors, register, address }) => {
    return (
        <>
            <Row>
                {exclude.indexOf("address_type") === -1 && (
                    <Col>
                        <label>Adressen-Typ</label>
                        <select
                            defaultValue={address.address_type || "billing"}
                            className={"form-control"}
                            name={prefix + "address_type"}
                            ref={register}
                        >
                            <option value={"billing"}>Rechnungsadresse</option>
                            <option value={"home"}>Firmensitz</option>
                        </select>
                    </Col>
                )}
                {exclude.indexOf("contact") === -1 && (
                    <Col>
                        <label>Empfänger</label>
                        <input
                            defaultValue={address.contact}
                            className={"form-control"}
                            name={prefix + "contact"}
                            ref={register({ required: true })}
                        />
                        {errors.contact && (
                            <InputError>Empfänger muss ausgefüllt werden</InputError>
                        )}
                    </Col>
                )}
                {exclude.indexOf("extension") === -1 && (
                    <Col>
                        <label>Adressenzusatz</label>
                        <input
                            defaultValue={address.extension}
                            className={"form-control"}
                            name={prefix + "extension"}
                            ref={register}
                        />
                        {errors.extension && (
                            <InputError>Adressenzusatz muss ausgefüllt werden</InputError>
                        )}
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <label>Straße, Nr.</label>
                    <input
                        defaultValue={address.street}
                        className={"form-control"}
                        name={prefix + "street"}
                        ref={register({ required: true })}
                    />
                    {errors.street && <InputError>Straße muss ausgefüllt werden</InputError>}
                </Col>
                <Col>
                    <label>Postleitzahl</label>
                    <input
                        defaultValue={address.postalcode}
                        className={"form-control"}
                        name={prefix + "postalcode"}
                        ref={register({
                            required: "Postleitzahl muss ausgefüllt werden",
                        })}
                    />
                    {errors.postalcode && <InputError>{errors.postalcode.message}</InputError>}
                </Col>
                <Col>
                    <label>Stadt</label>
                    <input
                        defaultValue={address.city}
                        className={"form-control"}
                        name={prefix + "city"}
                        ref={register({ required: true })}
                    />
                    {errors.city && <InputError>Stadt muss ausgefüllt werden</InputError>}
                </Col>
                <Col>
                    <label>Land</label>
                    <input
                        defaultValue={address.country || "Deutschland"}
                        className={"form-control"}
                        name={prefix + "country"}
                        ref={register({ required: true })}
                    />
                    {errors.country && <InputError>Land muss ausgefüllt werden</InputError>}
                </Col>
            </Row>
            <Row>
                <Col>
                    <label htmlFor={`address-${address.id}-billing_email`}>
                        E-Mail für Rechnungen
                    </label>
                    <input
                        id={`address-${address.id}-billing_email`}
                        defaultValue={address.billing_email}
                        className={"form-control"}
                        name="billing_email"
                        readOnly={false}
                        ref={register({})}
                    />
                    {errors.billing_email && (
                        <InputError>{errors.billing_email.message}</InputError>
                    )}
                </Col>
            </Row>
        </>
    );
};
