import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormData } from "../actions/formData";
import { SchemaBuilder } from "../spec/SchemaBuilder";

export const useSchema = (form) => {
    const formData = useSelector<any>((state) => state.formData.forms[form.id]);
    const isLoading = useSelector<any>((state) => state.formData.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!formData) {
            dispatch(fetchFormData(form));
        }
    }, [form]);

    const schemaBuilder: any = useMemo(() => {
        if (!formData) {
            return null;
        }

        const builder = new SchemaBuilder();
        builder.use(formData);
        return builder;
    }, [formData]);

    return { schemaBuilder, isLoading, formData };
};
