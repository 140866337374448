import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { uniqueID } from "../utils";
import { BaseField } from "./BaseField";
import { Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { LabelInput } from "./inputFields/label";

export class ParagraphField extends BaseField {
    public name = "";
    public label = "";
    public text = "";
    public inputType = "text";
    public designerInputType = "text";
    public hashCode = uniqueID();
    public icon = "quote-right";
    public fieldType = "Paragraph";

    public attributeNames = ["collapsed", "isBoldText", "text"];

    public getConfigurables() {
        return [Conditional, Width];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ layout, designer, schema, register, field, provided }) {
        return (
            <FieldWrapper
                register={register}
                provided={provided}
                field={field}
                schema={schema}
                designer={designer}
            >
                <div className="powerform-flex">
                    <LabelInput register={register} keyName={"text"} field={field} />
                </div>
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
    }) {
        const printLines = (text) => {
            return (
                <div>
                    {text.split("\n").map((p, i) => {
                        return <p key={i} dangerouslySetInnerHTML={{ __html: p }}></p>;
                    })}
                </div>
            );
        };
        return field.configureInput(
            <span>
                <div>{printLines(field.text)}</div>
            </span>
        );
    }
}
