// TODO: consistent naming [EntityActionSubAction] e.g. EntityLoadingStart
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const AUTH_USER = "AUTH_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const CURRENT_USER = "CURRENT_USER";
export const GET_DEVICES = "GET_DEVICES";
export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
export const ADD_FORM = "ADD_FORM";
export const FORM_CREATE = "FORM_CREATE";
export const NEW_DEVICE_REGISTRATION = "NEW_DEVICE_REGISTRATION";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const ADD_DEVICE = "ADD_DEVICE";
export const GET_FORMS = "GET_FORMS";
export const UPDATE_FORM = "UPDATE_FORM";
export const FORM_SUBMISSION_CREATED = "FORM_SUBMISSION_CREATED";
export const FORM_ENTRIES_LOADED = "FORM_ENTRIES_LOADED";
export const FORM_ENTRIES_DEVICE_LOADED = "FORM_ENTRIES_DEVICE_LOADED";
export const DELETE_FORM = "DELETE_FORM";
export const FORMS_LOADING_START = "FORMS_LOADING_START";
export const FORMS_LOADING_STOP = "FORMS_LOADING_STOP";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const ADD_ENTITY = "ADD_ENTITY";
export const GET_ENTITIES = "GET_ENTITIES";
export const DELETE_ENTITY = "DELETE_ENTITY";
export const CREATE_ENTITY = "CREATE_ENTITY";
export const UPDATE_ENTITY = "UPDATE_ENTITY";
export const FORM_ENTRIES_LIST_LOADED = "FORM_ENTRIES_LIST_LOADED";
export const FORM_ENTRIES_FORM_LOADED = "FORM_ENTRIES_FORM_LOADED";
export const FORM_ENTRIES_SINGLE_LOADED = "FORM_ENTRIES_SINGLE_LOADED";
export const FORM_REGENERATING_PDF = "FORM_REGENERATING_PDF";
export const NEW_DEVICE_TASK = "NEW_DEVICE_TASK";
export const NEW_DEVICE_TASKS = "NEW_DEVICE_TASKS";
export const CREATE_CUSTOM_FIELD = "CREATE_CUSTOM_FIELD";
export const GET_CUSTOM_FIELDS = "GET_CUSTOM_FIELDS";
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD";
export const DELETE_CUSTOM_FIELD = "DELETE_CUSTOM_FIELD";
export const ADD_CUSTOM_FIELD = "ADD_CUSTOM_FIELD";
export const REMOVE_DEVICE_TASK = "REMOVE_DEVICE_TASK";
export const FORM_ENTRIES_ENTITY_LOADED = "FORM_ENTRIES_ENTITY_LOADED";
export const FORM_ENTRIES_DELETED = "FORM_ENTRIES_DELETED";
export const FORM_ENTRIES_LIST_LOADED_PAGE = "FORM_ENTRIES_LIST_LOADED_PAGE";
export const SET_USERS = "SET_USERS";
export const SET_COMPANY = "SET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const FORM_ENTRIES_LOADING = "FORM_ENTRIES_LOADING";
export const FORM_ENTRIES_LOADING_STOP = "FORM_ENTRIES_LOADING_STOP";
export const FORM_ENTRIES_SET_MIGRATION_PANES = "FORM_ENTRIES_SET_MIGRATION_PANES";
export const SET_ROOT_FOLDERS = "SET_ROOT_FOLDERS";
export const SET_FOLDERS = "SET_FOLDERS";
export const SET_FOLDER_ENTRIES = "SET_FOLDER_ENTRIES";
export const FOLDERS_LOADING_START = "FOLDERS_LOADING_START";
export const FOLDERS_LOADING_STOP = "FOLDERS_LOADING_STOP";
export const FOLDERS_TREE_LOADING_START = "FOLDERS_TREE_LOADING_START";
export const FOLDERS_TREE_LOADING_STOP = "FOLDERS_TREE_LOADING_STOP";
export const FOLDERS_SUBTREE_LOADING_START = "FOLDERS_SUBTREE_LOADING_START";
export const FOLDERS_SUBTREE_LOADING_STOP = "FOLDERS_SUBTREE_LOADING_STOP";
export const ADD_FOLDERS = "ADD_FOLDERS";
export const CLEAR_FOLDERS = "CLEAR_FOLDERS";
export const SET_DIRECTORY = "SET_DIRECTORY";
export const SET_TREE = "SET_TREE";
export const SET_SUBTREE = "SET_SUBTREE";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_PERMISSIONS_RESOURCES = "SET_PERMISSIONS_RESOURCES";
export const SET_PERMISSIONS_USERS = "SET_PERMISSIONS_USERS";
export const SET_PERMISSIONS_ROLES = "SET_PERMISSIONS_ROLES";
export const PERMISSIONS_LOADING_START = "PERMISSIONS_LOADING_START";
export const PERMISSIONS_LOADING_STOP = "PERMISSIONS_LOADING_STOP";
export const ADD_PERMISSION = "ADD_PERMISSION";
export const SET_ROLES = "SET_ROLES";
export const REMOVE_PERMISSION = "REMOVE_PERMISSION";
export const CUSTOMERS_LOADING_START = "CUSTOMERS_LOADING_START";
export const CUSTOMERS_LOADING_STOP = "CUSTOMERS_LOADING_STOP";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_TEMPLATES = "SET_TEMPLATES";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const SEARCH_FOLDERS = "SEARCH_FOLDERS";
export const SEARCH_FOLDERS_LOADING_STOP = "SEARCH_FOLDERS_LOADING_STOP";
export const SEARCH_FOLDERS_LOADING_START = "SEARCH_FOLDERS_LOADING_START";
export const SEARCH_FOLDERS_QUERY = "SEARCH_FOLDERS_QUERY";
export const EDITOR_OPEN = "EDITOR_OPEN";
export const EDITOR_SAVING = "EDITOR_SAVING";
export const EDITOR_CLOSE = "EDITOR_CLOSE";
export const SET_CUSTOMER_ADDRESSES = "SET_ADDRESSES";
export const CUSTOMER_UPDATE_ADDRESS = "CUSTOMER_UPDATE_ADDRESS";
export const CUSTOMER_NEW_ADDRESS = "CUSTOMER_NEW_ADDRESS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS";
export const CUSTOMER_NEW_ENTITY = "CUSTOMER_NEW_ENTITY";
export const CUSTOMER_UPDATE_ENTITY = "CUSTOMER_UPDATE_ENTITY";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SEARCH_LOADING_START = "SEARCH_LOADING_START";
export const SEARCH_LOADING_STOP = "SEARCH_LOADING_STOP";
export const SET_FOLDER_SELECTION = "SET_FOLDER_SELECTION";
export const ADD_FOLDER_TO_SELECTION = "ADD_FOLDER_TO_SELECTION";
export const REMOVE_FOLDER_FROM_SELECTION = "REMOVE_FOLDER_FROM_SELECTION";
export const SET_FOLDER_SELECTION_ITEMS = "SET_FOLDER_SELECTION_ITEMS";
export const FORM_DATA_LOADING_START = "FORM_DATA_LOADING_START";
export const FORM_DATA_LOADING_STOP = "FORM_DATA_LOADING_STOP";
export const FORM_DATA_LOADED = "FORM_DATA_LOADED";
export const GET_DEVICE_TASKS = "GET_DEVICE_TASKS";
export const TASKS_LOADING_STOP = "TASKS_LOADING_STOP";
export const TASKS_LOADING_START = "TASKS_LOADING_START";
export const SET_TASKS = "SET_TASKS";
export const SET_DEVICE_TASKS = "SET_DEVICE_TASKS";
export const DELETE_DEVICE_TASK = "DELETE_DEVICE_TASK";
export const INVENTORY_ITEM_LOADING_START = "INVENTORY_ITEM_LOADING_START";
export const INVENTORY_ITEM_LOADING_STOP = "INVENTORY_ITEM_LOADING_STOP";
export const GET_INVENTORY_ITEMS = "GET_INVENTORY_ITEMS";
export const NEW_INVENTORY_ITEM = "NEW_INVENTORY_ITEM";
export const CREATE_INVENTORY_ITEM = "CREATE_INVENTORY_ITEM";
export const EDIT_INVENTORY_ITEM = "EDIT_INVENTORY_ITEM";
export const UPDATE_INVENTORY_ITEM = "UPDATE_INVENTORY_ITEM";
export const DELETE_INVENTORY_ITEM = "DELETE_INVENTORY_ITEM";
export const GET_MANUFACTURERS = "GET_MANUFACTURERS";
export const CREATE_MANUFACTURER = "CREATE_MANUFACTURER";
export const MANUFACTURERS_LOADING_START = "MANUFACTURERS_LOADING_START";
export const MANUFACTURERS_LOADING_STOP = "MANUFACTURERS_LOADING_STOP";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CATEGORIES_LOADING_START = "CATEGORIES_LOADING_START";
export const CATEGORIES_LOADING_STOP = "CATEGORIES_LOADING_STOP";
export const FORM_REVISION_SINGLE_LOADED = "FORM_REVISION_SINGLE_LOADED";
export const FORM_REVISION_LOADING_STOP = "FORM_REVISION_LOADING_STOP";
export const FORM_REVISION_LOADING_START = "FORM_REVISION_LOADING_START";
export const FORM_REVISIONS_LOADED = "FORM_REVISIONS_LOADED";
export const FORM_ENTRIES_CREATE = "FORM_ENTRIES_CREATE";
export const FORM_GROUP_LOADED = "FORM_GROUP_LOADED";
export const FORM_GROUPS_SET = "FORM_GROUPS_SET";
export const FORM_GROUP_ADD = "FORM_GROUP_ADD";
export const NEW_UPLOAD = "NEW_UPLOAD";
export const NEW_UPLOADS = "NEW_UPLOADS";
export const NEW_UPLOAD_INFO = "NEW_UPLOAD_INFO";
export const EDITOR_FORM_ENTRY = "EDITOR_FORM_ENTRY";
export const EDITOR_FORM_ENTRY_SET = "EDITOR_FORM_ENTRY_SET";
export const EDITOR_FORM_ENTRY_FORM_CHANGED = "EDITOR_FORM_ENTRY_FORM_CHANGED";
export const EDITOR_FORM_ENTRY_FORM_REVISION_CHANGED = "EDITOR_FORM_ENTRY_FORM_REVISION_CHANGED";
export const EDITOR_FORM_ENTRY_ENTITY_CHANGED = "EDITOR_FORM_ENTRY_ENTITY_CHANGED";
export const EDITOR_FORM_ENTRY_CUSTOMER_CHANGED = "EDITOR_FORM_ENTRY_CUSTOMER_CHANGED";
export const SET_FORM_ENTRY_DATA = "SET_FORM_ENTRY_DATA";
export const EDITOR_FORM_ENTRY_FORM_DATA_CHANGED = "EDITOR_FORM_ENTRY_FORM_DATA_CHANGED";
export const EDITOR_FORM_ENTRY_NAME_CHANGED = "EDITOR_FORM_ENTRY_NAME_CHANGED";
export const EDITOR_FORM_SNAPSHOT_CHANGED = "EDITOR_FORM_SNAPSHOT_CHANGED";
export const NEW_MIGRATION = "NEW_MIGRATION";
export const NEW_TEMPLATE = "NEW_TEMPLATE";
export const CREATE_CALENDAR_EVENT = "CREATE_CALENDAR_EVENT";
export const DELETE_CALENDAR_EVENT = "DELETE_CALENDAR_EVENT";
export const GET_CALENDAR_EVENTS = "GET_CALENDAR_EVENTS";
export const ADD_CALENDAR_EVENT = "ADD_CALENDAR_EVENT";
export const CALENDAR_EVENTS_LOADING_START = "CALENDAR_EVENTS_LOADING_START";
export const CALENDAR_EVENTS_LOADING_STOP = "CALENDAR_EVENTS_LOADING_STOP";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";
export const UPDATE_CALENDAR_EVENT = "UPDATE_CALENDAR_EVENT";
export const GET_DETAILED_EVENTS = "GET_DETAILED_EVENTS";
export const ADD_DETAILED_EVENT = "ADD_DETAILED_EVENT";
export const NOTIFICATION_GROUPS_LIST_LOADED = "NOTIFICATION_GROUPS_LIST_LOADED";
export const NOTIFICATION_GROUPS_LOADING_START = "NOTIFICATION_GROUPS_LOADING_START";
export const NOTIFICATION_GROUPS_LOADING_STOP = "NOTIFICATION_GROUPS_LOADING_STOP";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const EMPLOYEES_LOADING_START = "EMPLOYEES_LOADING_START";
export const EMPLOYEES_LOADING_STOP = "EMPLOYEES_LOADING_STOP";
export const EMPLOYEES_SET_EDITING = "EMPLOYEES_SET_EDITING";
export const CREATE_VACATION = "CREATE_VACATION";
export const DELETE_VACATION = "DELETE_VACATION";
export const GET_VACATIONS_FOR_EMPLOYEE = "GET_VACATIONS_FOR_EMPLOYEE";
export const GET_VACATIONS = "GET_VACATIONS";
export const GET_EMPLOYEE_BY_ID = "GET_EMPLOYEE_BY_ID";
export const UPDATE_VACATION = "UPDATE_VACATION";
export const GET_REVENUE = "GET_REVENUE";
export const GET_PAID_REVENUE = "GET_PAID_REVENUE";
export const REVENUE_LOADING_START = "REVENUE_LOADING_START";
export const REVENUE_LOADING_STOP = "REVENUE_LOADING_STOP";
export const FINANCES_CUSTOMER_CHANGED = "FINANCES_CUSTOMER_CHANGED";
export const FINANCES_ENTITY_CHANGED = "FINANCES_ENTITY_CHANGED";
export const FINANCES_CHANGE_INVOICE_TYPE = "FINANCES_CHANGE_INVOICE_TYPE";
export const FINANCES_RESET_STATE = "FINANCES_RESET_STATE";
export const FINANCES_CHANGE_NET = "FINANCES_CHANGE_NET";
export const GET_TOTAL_REVENUE = "GET_INITIAL_REVENUE";
export const GET_TOTAL_PAID_REVENUE = "GET_INITIAL_PAID_REVENUE";
export const FINANCES_SET_DATE = "FINANCES_SET_DATE";
export const ZIP_REQUEST = "ZIP_REQUEST";
export const CREATE_CALENDAR_EVENT_TYPE = "CREATE_CALENDAR_EVENT_TYPE";
export const DELETE_CALENDAR_EVENT_TYPE = "DELETE_CALENDAR_EVENT_TYPE";
export const UPDATE_CALENDAR_EVENT_TYPE = "UPDATE_CALENDAR_EVENT_TYPE";
export const GET_CALENDAR_EVENT_TYPES = "GET_CALENDAR_EVENT_TYPES";
export const CREATE_CALENDAR_EVENT_PARTICIPANT = "CREATE_CALENDAR_EVENT_PARTICIPANT";
export const DELETE_CALENDAR_EVENT_PARTICIPANT = "DELETE_CALENDAR_EVENT_PARTICIPANT";
export const UPDATE_CALENDAR_EVENT_PARTICIPANT = "UPDATE_CALENDAR_EVENT_PARTICIPANT";
export const GET_CALENDAR_EVENT_PARTICIPANTS = "GET_CALENDAR_EVENT_PARTICIPANTS";
export const UPDATE_EMPLOYEE_CERT = "UPDATE_EMPLOYEE_CERT";
export const CREATE_EMPLOYEE_CERT = "CREATE_EMPLOYEE_CERT";
export const GET_TERMS = "GET_TERMS";
export const GET_STATES = "GET_STATES";
export const GET_CUSTOMERS = "GET_CUSTOMERS";

export const DEVICES_AUTOCOMPLETE = "DEVICES_AUTOCOMPLETE";
export const CUSTOMERS_AUTOCOMPLETE = "CUSTOMERS_AUTOCOMPLETE";
export const ENTITIES_AUTOCOMPLETE = "ENTITIES_AUTOCOMPLETE";
export const FORMS_AUTOCOMPLETE = "FORMS_AUTOCOMPLETE";
export const DEVICES_AUTOCOMPLETE_START = "DEVICES_AUTOCOMPLETE_START";
export const CUSTOMERS_AUTOCOMPLETE_START = "CUSTOMERS_AUTOCOMPLETE_START";
export const ENTITIES_AUTOCOMPLETE_START = "ENTITIES_AUTOCOMPLETE_START";
export const FORMS_AUTOCOMPLETE_START = "FORMS_AUTOCOMPLETE_START";
export const FORMS_SYNC_STATUS = "FORMS_SYNC_STATUS";
export const FORMS_SYNC = "FORMS_SYNC";
export const FORMS_SYNC_SELECTION = "FORMS_SYNC_SELECTION";

export const CREATE_TIME_TRACKING = "CREATE_TIME_TRACKING";

export const DELETE_TIME_TRACKING = "DELETE_TIME_TRACKING";

export const GET_TIME_TRACKING = "GET_TIME_TRACKING";
export const UPDATE_TIME_TRACKING = "UPDATE_TIME_TRACKING";

export const TIME_TRACKING_LOADING_STOP = "TIME_TRACKING_LOADING_STOP";
export const TIME_TRACKING_LOADING_START = "TIME_TRACKING_LOADING_START";

export const CREATE_FOLDER_NOTE = "CREATE_FOLDER_NOTE";
export const UPDATE_FOLDER_NOTE = "UPDATE_FOLDER_NOTE";
export const DELETE_FOLDER_NOTE = "DELETE_FOLDER_NOTE";
export const GET_FOLDER_NOTES = "GET_FOLDER_NOTES";
