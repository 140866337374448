import { createCustomer, getCustomersAJAX } from "@rpforms/shared/build/actions/customer";
import { createEntityAJAX, getEntityAJAX } from "@rpforms/shared/build/actions/entities";
import { getFormsAJAX } from "@rpforms/shared/build/actions/forms";
import React from "react";
import { Col, FormLabel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";

export const ResourceForm = ({ folder, onChange, type }) => {
    const dispatch = useDispatch();

    const getEntities = (inputValue) =>
        new Promise((resolve) => {
            getEntityAJAX(inputValue).then((response) => {
                resolve(
                    response.data.map((entity) => {
                        return {
                            value: entity.id,
                            label: entity.name,
                        };
                    })
                );
            });
        });

    const getCustomers = (inputValue) =>
        new Promise((resolve) => {
            getCustomersAJAX(inputValue).then((response) => {
                resolve(
                    response.data.map((customer) => {
                        return {
                            value: customer.id,
                            label: customer.name,
                        };
                    })
                );
            });
        });

    const getForms = () =>
        new Promise((resolve) => {
            getFormsAJAX().then((response) => {
                resolve(
                    response.data.map((form) => {
                        return {
                            value: form.id,
                            label: form.name,
                        };
                    })
                );
            });
        });

    const createEntity = async (inputVal) => {
        const entity: any = await dispatch(createEntityAJAX({ title: inputVal }));
        updateResource({
            label: entity.name,
            value: entity.id,
        });
    };

    const createNewCustomer = async (inputVal) => {
        const req: any = await dispatch(createCustomer({ name: inputVal }));
        const customer = req.data;
        updateResource({
            label: customer.name,
            value: customer.id,
        });
    };

    const updateName = (ev) => {
        onChange({ name: ev.target.value });
    };

    const updateResource = (resource) => {
        onChange({
            resource_type: type,
            resource_id: resource.value,
            resource_label: resource.label,
        });
    };

    return (
        <div>
            {type === "Folder" && (
                <>
                    <FormLabel>Name</FormLabel>
                    <input
                        type={"text"}
                        value={folder.name}
                        className={"form-control"}
                        onChange={updateName}
                    />
                </>
            )}

            {type === "Customer" && (
                <>
                    <FormLabel>Kunde</FormLabel>
                    <AsyncCreatableSelect
                        onChange={updateResource}
                        onCreateOption={createNewCustomer}
                        loadOptions={getCustomers}
                        value={{ value: folder.resource_id, label: folder.resource_label }}
                        placeholder={"Kunde auswählen oder anlegen..."}
                        creatable
                        cacheOptions
                        defaultOptions
                    />
                </>
            )}

            {type === "Entity" && (
                <>
                    <FormLabel>Objekt</FormLabel>
                    <AsyncCreatableSelect
                        onChange={updateResource}
                        onCreateOption={createEntity}
                        loadOptions={getEntities}
                        value={{ value: folder.resource_id, label: folder.resource_label }}
                        placeholder={"Objekt auswählen oder anlegen..."}
                        creatable
                        cacheOptions
                        defaultOptions
                    />
                </>
            )}

            {type == "Form" && (
                <>
                    <FormLabel>Protokoll</FormLabel>
                    <AsyncCreatableSelect
                        onChange={updateResource}
                        loadOptions={getForms}
                        value={{ value: folder.resource_id, label: folder.resource_label }}
                        placeholder={"Protokoll auswählen"}
                        cacheOptions
                        defaultOptions
                    />
                </>
            )}
        </div>
    );
};
