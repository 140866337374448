import React from "react";
import styled from "styled-components";
export const FolderViewPanes = styled.div`
    display: flex;
    max-width: 100%;
    overflow: auto;
`;
const FolderViewPaneWrapper = styled.div`
    border-right: 1px solid #ddd;
`;
export const FolderViewPane = ({ children }) => {
    return <FolderViewPaneWrapper data-pane>{children}</FolderViewPaneWrapper>;
};
