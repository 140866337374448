import React, { useEffect, useState } from "react";
import { MigratorPane } from "./MigrationPane";

export const EntryInfoCard = ({ entry }) => {
    return (
        <div>
            <h3>{entry.name}</h3>
            Version: <strong>{entry.form_revision.version}</strong> /{" "}
            {entry.latest_revision_version}
        </div>
    );
};

export default ({ children }) => {
    return <MigratorPane>{children}</MigratorPane>;
};
