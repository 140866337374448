import React from "react";
import { InputGroup } from "react-bootstrap";
import CurrencyInput from "react-currency-masked-input";
import { positiveNumberKeyDownEvent } from "../../utils";

export const NumberInput = (props) => {
    return (
        <InputGroup style={{ flexWrap: "inherit" }}>
            <CurrencyInput
                className={"form-control"}
                {...props}
                onKeyDown={(e) =>
                    props.min !== null && props.min >= 0 ? positiveNumberKeyDownEvent(e) : null
                }
            />
            <InputGroup.Append>
                <InputGroup.Text>{props.unit}</InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    );
};
