import { CURRENT_USER } from "../actions/types";

const initialState = {
    isLoggedIn: false,
    username: "guest"
};

export default function(state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case CURRENT_USER:
            return { ...state, ...action.payload };
    }

    return state;
}
