import React, { memo } from "react";
import { API_URL } from "../../config";
import { SmallDangerButton, SmallPrimaryButton } from "../Button";
import { LocalUploadPreview } from "./LocalUploadPreview";
import styled from "styled-components";

export const UploadPreview = memo(
    ({ upload, onDeleteUpload, onEditUpload, setActiveUploadIndex, index }: any) => {
        if (!upload?.file && !upload?.id && !upload?.upload_id) {
            // TODO: Investigate this
            return <></>;
        }

        const renderImage = () => {
            const alt = `Bild ${index}`;
            let src = "";

            if (upload.file?.uuid) {
                return <LocalUploadPreview upload={upload} />;
            } else if (upload.file?.source) {
                src = upload.file.source;
            } else if (upload.id) {
                src = `${API_URL}/uploads/${upload.id}.json?redirect=1`;
            } else if (upload.upload_id) {
                src = `${API_URL}/uploads/${upload.upload_id}.json?redirect=1`;
            }

            return src ? <LazyImage src={src} alt={alt} /> : null;
        };
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                    }}
                    key={upload.id}
                >
                    <div>
                        <div>{upload.name}</div>
                        {upload &&
                            upload.subfields &&
                            Object.keys(upload.subfields).map((label) => (
                                <div key={label}>
                                    <strong>{label}</strong>: {upload.subfields[label]}
                                </div>
                            ))}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {renderImage()}
                        <SmallPrimaryButton
                            className="hidden-print"
                            onClick={() => setActiveUploadIndex(index)}
                        >
                            Ansehen
                        </SmallPrimaryButton>
                        <SmallPrimaryButton
                            className="hidden-print"
                            onClick={() => onEditUpload(upload)}
                        >
                            Bearbeiten
                        </SmallPrimaryButton>
                        <SmallDangerButton
                            className="hidden-print"
                            onClick={() => onDeleteUpload(upload)}
                        >
                            X
                        </SmallDangerButton>
                    </div>
                </div>
                <hr />
            </>
        );
    }
);

const LazyImage = styled.img.attrs((props: any) => ({
    loading: "lazy",
    height: 48,
    width: 48,
    src: props.src,
    alt: props.alt,
}))`
    object-fit: cover;
`;
