import React, { useState } from "react";
import styled from "styled-components";
import { DangerButton, SmallPrimaryButton } from "../components/Button";
import { LocalUploadPreview } from "../components/uploadField/LocalUploadPreview";
import { API_URL } from "../config";

interface GalleryProps {
    images: any[];
    startingActiveIndex?: number;
    onClose?: () => void;
}

export const Gallery = ({ images, startingActiveIndex, onClose }: GalleryProps) => {
    const [activeImageIndex, setActiveImageIndex] = useState(startingActiveIndex ?? 0);

    const getImagePath = (image: any) => {
        if (image.resource) {
            return `${API_URL}/uploads/${image.resource.id}.json?redirect=1`;
        }
        if (image.file?.source) {
            return image.file.source;
        }
        if (image.id) {
            return `${API_URL}/images/${image.id}.json?redirect=1`;
        }
        if (image.upload_id) {
            return `${API_URL}/images/${image.upload_id}.json?redirect=1`;
        }
        return null;
    };

    const handleClose = () => {
        setActiveImageIndex(null);
        if (onClose) {
            onClose();
        }
    };

    const prevButtonUsable = activeImageIndex > 0;
    const nextButtonUsable = activeImageIndex < images.length - 1;

    return (
        <>
            {activeImageIndex !== null && (
                <CustomModal>
                    <DangerButton onClick={handleClose}>X</DangerButton>
                    <Content>
                        <ImageContainer>
                            {images[activeImageIndex].file?.uuid ? (
                                <LocalUploadPreview upload={images[activeImageIndex]} full={true} />
                            ) : (
                                <Image src={getImagePath(images[activeImageIndex])} />
                            )}
                        </ImageContainer>
                        <NavigationContainer>
                            <ButtonPlaceholder>
                                <NavButton
                                    onClick={() => setActiveImageIndex(activeImageIndex - 1)}
                                    disabled={!prevButtonUsable}
                                >
                                    <i className="fa fa-arrow-left" /> Vorheriges Bild
                                </NavButton>
                            </ButtonPlaceholder>
                            <ButtonPlaceholder>
                                <NavButton
                                    onClick={() => setActiveImageIndex(activeImageIndex + 1)}
                                    disabled={!nextButtonUsable}
                                >
                                    <i className="fa fa-arrow-right" /> Nächstes Bild
                                </NavButton>
                            </ButtonPlaceholder>
                        </NavigationContainer>
                    </Content>
                </CustomModal>
            )}
        </>
    );
};

const CustomModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.95);
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;

    ${DangerButton} {
        position: absolute;
        top: 20px;
        right: 20px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`;

const ImageContainer = styled.div`
    width: 75%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    padding: 20px;
`;

const NavigationContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px;
`;

const ButtonPlaceholder = styled.div`
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
`;

const NavButton = styled(SmallPrimaryButton)`
    z-index: 107;

    &:disabled {
        border: none;
        outline: none;
        cursor: not-allowed;
        pointer-events: none;
        box-shadow: none;
    }
`;
