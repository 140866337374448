import * as actions from "@rpforms/shared/build/actions/devices";
import { Sidebar } from "@rpforms/shared/build/components/layout/Sidebar";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { translucentColor } from "@rpforms/shared/build/utils";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Route, withRouter } from "react-router-dom";
import styled from "styled-components";
import FormsPage from "../forms/FormsPage";
import { FormVariablesPage } from "../forms/FormVariablesPage";
import StateMachineSettingsPage from "../state_machine/StateMachineSettingsPage";
import AdminCustomersPage from "./AdminCustomersPage";
import AdminSettingsPage from "./AdminSettingsPage";
import EmployeeSettingsPage from "./EmployeeSettingsPage";
import FolderSettingsPage from "./FolderSettingsPage";
import CustomFieldPage from "../custom_fields/CustomFieldPage";
import CalendarEventTypesPage from "../calendar/CalendarEventTypesPage";

export const SettingsArea = styled.div`
    display: flex;
    min-height: calc(100vh - 254px); // minus top and header
    .content {
        padding: 20px;
        width: calc(100% - 300px);
    }
`;
export const SettingsHeadline = styled.h2`
    font-size: 24px;
    span {
        font-family: "Merriweather", serif;
    }
    margin-top: 20px;
`;

export const SettingsCategory = styled.div`
    color: #777;
    font-weight: bold;
    padding: 10px 0 10px 30px;
    text-transform: uppercase;
    font-size: 14px;
    background: #f1f1f1;
    border-bottom: 1px solid #ddd;
`;

export const SettingsList = styled.div`
    a {
        display: block;
        padding: 15px 30px;
        border-bottom: 1px solid #f1f1f1;
        &:hover {
            color: #333;
        }
        &.active {
            background-color: ${(props) => translucentColor(props.theme.primary_color, 0.15)};
            border-bottom: 1px solid ${(props) => translucentColor(props.theme.primary_color, 0.35)};
        }
    }
`;

class SettingsPage extends Component {
    public props;
    public componentWillMount() {}

    public render() {
        return (
            <>
                <PageNavbar
                    title="Einstellungen"
                    description="Hier können Sie die Anwendung verwalten."
                ></PageNavbar>
                <SettingsArea>
                    <Sidebar>
                        <SettingsCategory>Firma</SettingsCategory>
                        <SettingsList>
                            <NavLink activeClassName="active" to={"/settings/company/admins"}>
                                Administratoren
                            </NavLink>
                            <NavLink activeClassName="active" to={"/settings/company/employees"}>
                                Mitarbeiter
                            </NavLink>
                            <NavLink activeClassName="active" to={"/settings/company/folders"}>
                                Ordnerstruktur
                            </NavLink>
                        </SettingsList>
                        <SettingsCategory>Protokolle</SettingsCategory>
                        <SettingsList>
                            <NavLink activeClassName="active" to={"/settings/protocols/forms"}>
                                Formulare
                            </NavLink>
                            <NavLink activeClassName="active" to={"/settings/protocols/pdflayout"}>
                                PDF Layout
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={"/settings/protocols/custom_fields/"}
                            >
                                Eigene Felder
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={"/settings/protocols/state_machines/"}
                            >
                                State-Machine
                            </NavLink>
                        </SettingsList>
                        <SettingsCategory>Terminplaner</SettingsCategory>
                        <SettingsList>
                            <NavLink activeClassName="active" to={"/settings/calendar/types"}>
                                Eventtypen
                            </NavLink>
                        </SettingsList>
                        <SettingsCategory>Stammdaten</SettingsCategory>
                        <SettingsList>
                            <NavLink activeClassName="active" to={"/settings/customers"}>
                                Kunden
                            </NavLink>
                        </SettingsList>
                    </Sidebar>
                    <div className="content">
                        <Route path="/settings/company/admins/" exact render={AdminSettingsPage} />
                        <Route
                            path="/settings/company/employees/"
                            exact
                            render={EmployeeSettingsPage}
                        />
                        <Route path="/settings/protocols/forms/" render={FormsPage} />
                        <Route path="/settings/protocols/variables/" render={FormVariablesPage} />
                        <Route path="/settings/protocols/custom_fields/" render={CustomFieldPage} />
                        <Route
                            path="/settings/protocols/state_machines/"
                            render={StateMachineSettingsPage}
                        />
                        <Route path="/settings/calendar/types" render={CalendarEventTypesPage} />
                        <Route path="/settings/customers" render={AdminCustomersPage} />

                        <Route
                            path="/settings/company/folders/"
                            exact
                            render={FolderSettingsPage}
                        />
                    </div>
                </SettingsArea>
            </>
        );
    }
}

function mapStateToProps(state) {
    return { state: state.device };
}

export default withRouter(connect(mapStateToProps, actions)(SettingsPage));
