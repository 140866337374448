import React from "react";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, MaxLength, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";
import { TextField } from "./TextField";

export class PasswordField extends TextField {
    public icon = "asterisk";
    public fieldType = "Passwort";

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Required, Description, DefaultValue, Width, Hint];
    }
}
