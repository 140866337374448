import { fetchFormGroups, translucentColor } from "@rpforms/shared";
import React, { useEffect } from "react";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { SmallButton } from "@rpforms/shared/build/components/Button";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar, { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DeviceName, ListRow } from "../../components/forms/FormListRow";
import FormStructure from "../../components/forms/FormStructure";
import { RootState } from "../../reducers";
import { Link, withRouter } from "react-router-dom";

export const ListFormGroupsPage = () => {
    const dispatch = useDispatch();
    const formGroups = useSelector<RootState, any>((state) => state.formGroups.items);
    useEffect(() => {
        dispatch(fetchFormGroups());
    }, []);

    return (
        <>
            <PageNavbar
                title="State-Machine"
                description="Hier können Sie Formular-Gruppen verwalten und bearbeiten."
            ></PageNavbar>

            <Content>
                {formGroups.map((fg) => {
                    return (
                        <ListRow key={`form-group-${fg.id}`}>
                            <DeviceName>{fg.name}</DeviceName>
                            <RightAligned>
                                <Link to={`/settings/protocols/state_machines/${fg.id}`}>
                                    <SmallButton success={true}>Bearbeiten</SmallButton>
                                </Link>
                            </RightAligned>
                        </ListRow>
                    );
                })}
            </Content>
        </>
    );
};

export default withRouter(ListFormGroupsPage);
