import { BootstrapLayout } from "@rpforms/shared/build/components/BootstrapLayout";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormPresenter } from "../FormPresenter";

export const PreviewForm = ({ form, children }: any) => {
    return (
        <div className="form-container preview-container" data-form-id={form.id}>
            <FormPresenter layout={BootstrapLayout} presenter={this} form={form} />
        </div>
    );
};

export default withRouter(PreviewForm);
