import React, { useEffect } from "react";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { getCustomFields } from "@rpforms/shared/build/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { Link, withRouter } from "react-router-dom";
import { WidgetCard, WidgetIcon, WidgetName } from "../../components/AddField";

export const ListFieldsPage = () => {
    const dispatch = useDispatch();
    const customFields = useSelector<RootState, any>((state) => state.customFields);
    useEffect(() => {
        dispatch(getCustomFields());
    }, []);

    return (
        <>
            <PageNavbar
                title="Eigene Felder"
                description="Hier können Sie Ihre eigenen Felder verwalten und bearbeiten."
            >
                <PageNavAction
                    link="/settings/protocols/custom_fields/new"
                    primary={true}
                    icon="plus"
                    title="Neues Feld"
                />
            </PageNavbar>

            <Content>
                {customFields.customFields.map((customField) => {
                    return (
                        <Link
                            to={"/settings/protocols/custom_fields/" + customField.id}
                            key={customField.id}
                        >
                            <WidgetCard onClick={() => {}}>
                                <WidgetIcon>
                                    <i className={`fa fa-${customField.icon}`} />
                                </WidgetIcon>
                                <WidgetName>{customField.name}</WidgetName>
                            </WidgetCard>
                        </Link>
                    );
                })}
            </Content>
        </>
    );
};

export default withRouter(ListFieldsPage);
