import { FORMS_SYNC } from "@rpforms/shared";
import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import FormListRow from "./FormListRow";

const FormList = ({ forms = [], showEmptySpace = true }) => {
    const formsState = useSelector<RootState, any>((state) => state.forms);
    const dispatch = useDispatch();
    return (
        <>
            {showEmptySpace &&
                (!forms ||
                    (forms.length === 0 && (
                        <EmptySpace
                            link="/settings/protocols/forms/new"
                            title="Keine Formulare gefunden"
                            description="Möchten Sie ein neues Formular anlegen?"
                            cta="Formular anlegen"
                        />
                    )))}
            {forms && forms.length ? (
                forms.map((form) => {
                    return (
                        <div style={{ display: "flex" }} key={form.id}>
                            {formsState.syncSelectionEnabled && (
                                <input
                                    type="checkbox"
                                    id={`sync-form-${form.id}`}
                                    style={{ margin: "10px" }}
                                    checked={formsState.syncForms.includes(form.id)}
                                    name="sync-form"
                                    onChange={(e) => {
                                        if (formsState.syncForms.includes(form.id)) {
                                            dispatch({
                                                type: FORMS_SYNC,
                                                payload: formsState.syncForms.filter(
                                                    (fId) => fId !== form.id
                                                ),
                                            });
                                        } else {
                                            dispatch({
                                                type: FORMS_SYNC,
                                                payload: [...formsState.syncForms, form.id],
                                            });
                                        }
                                    }}
                                />
                            )}
                            <FormListRow form={form} />
                        </div>
                    );
                })
            ) : (
                <></>
            )}
        </>
    );
};

export default FormList;
