import React from "react";
import { PlainLayout } from "./PlainLayout";

/**
 * Wraps elements with bootstrap classes
 */
export class BootstrapLayout extends PlainLayout {
    public static wrapFieldset(field: any) {
        return <div className="form-group">{field}</div>;
    }

    public static wrapRow(field: any) {
        return <div className="row">{field}</div>;
    }

    /**
     * @param column
     */
    public static columnClass() {
        return "col-sm";
    }
}
