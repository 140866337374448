import * as actions from "@rpforms/shared/build/actions/devices";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import NewEntity from "../../components/entities/NewEntity";

class NewServicePositionPage extends Component {
    public props;
    public componentWillMount() {}

    public render() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-8 m-auto pt-5">
                            <div className="card">
                                <div className="card-header">
                                    <div className="m-0 card-title">Neue Objekt anlegen</div>
                                </div>
                                <div className="card-body">
                                    <NewEntity />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return { state: state.device };
}

export default withRouter(connect(mapStateToProps, actions)(NewServicePositionPage));
