import { fetchCustomersForAutocomplete } from "@rpforms/shared";
import { toSelectOptions } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const CustomerSelect = ({
    customerValue = null,
    onSelect,
    defaultValue = null,
    ...props
}) => {
    const [value, setValue] = useState(null);

    const customers = useSelector<RootState, any>((store) => store.customers.autocomplete);
    const defaultCustomer = useSelector<RootState, any>((store) => {
        return typeof defaultValue === "number"
            ? toSelectOptions([store.customers.autocomplete.find((c) => c.id === defaultValue)])[0]
            : defaultValue;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchCustomersForAutocomplete({}, (customersData) => {
                if (defaultValue != null) {
                    setValue(
                        toSelectOptions(customersData.filter((c) => c.id === defaultValue))[0]
                    );
                }
            })
        );
    }, []);

    useEffect(() => {
        if (!customers || customers.length <= 0) {
        } else {
            setValue(toSelectOptions(customers.filter((c) => c.id === defaultValue))[0]);
        }
    }, [customerValue]);

    const onChange = (x) => {
        setValue(x);
        onSelect(x);
    };

    return (
        <Select
            onChange={onChange}
            defaultValue={defaultCustomer}
            options={toSelectOptions(customers)}
            value={value}
            placeholder={"Auswählen..."}
            {...props}
        />
    );
};
