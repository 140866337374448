import * as actions from "@rpforms/shared/build/actions/auth";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import renderField from "@rpforms/shared/build/components/layout/Forms";
import { validateEmail } from "@rpforms/shared/build/utils";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";

class Signin extends Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            validated: false,
        };
    }

    public handleFormSubmit(props) {
        this.props.signinUser(props);
    }

    public toggleValidation() {
        this.setState({
            ...this.state,
            validated: true,
        });
    }

    public render() {
        const { handleSubmit } = this.props;

        return (
            <div className="form-container">
                <form noValidate onSubmit={handleSubmit(this.handleFormSubmit)}>
                    {/* Email */}
                    <Field
                        name="email"
                        required="true"
                        component={renderField}
                        type="text"
                        placeholder="E-mail"
                    />

                    {/* Password */}
                    <Field
                        name="password"
                        required="true"
                        component={renderField}
                        type="password"
                        placeholder="Passwort"
                    />

                    {/* Server error message */}
                    {this.props.errorMessage && this.props.errorMessage.signin && (
                        <div className="error-container signin-error">
                            Oops! {this.props.errorMessage.signin}
                        </div>
                    )}

                    <PrimaryButton
                        type="submit"
                        onClick={() => {
                            this.toggleValidation();
                        }}
                    >
                        Anmelden
                    </PrimaryButton>
                </form>
            </div>
        );
    }
}

function validate(formProps) {
    const errors: any = {};

    if (!formProps.email) {
        errors.email = "E-mail muss ausgefüllt werden.";
    }

    if (formProps.email && formProps.email.length > 0 && !validateEmail(formProps.email)) {
        errors.email = "Keine gültige E-mail Adresse.";
    }

    if (!formProps.password) {
        errors.password = "Passwort muss ausgefüllt werden.";
    }

    return errors;
}

function mapStateToProps(state) {
    return { errorMessage: state.auth.error };
}

export default withRouter(
    connect(mapStateToProps, actions)(reduxForm({ form: "signin", validate })(Signin))
);
