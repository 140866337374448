import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import { FORM_DATA_LOADED, FORM_DATA_LOADING_START, FORM_DATA_LOADING_STOP } from "./types";

export const fetchFormData = (form, cb = (f, formData) => {}) => {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({ type: FORM_DATA_LOADING_START });

        const response = await axios.get(`${API_URL}/forms/${form.id}/data.json`, {
            headers: { authorization: user().token },
        });
        cb(form, response.data);
        dispatch({
            type: FORM_DATA_LOADED,
            payload: { form, data: response.data },
        });
        dispatch({ type: FORM_DATA_LOADING_STOP });
    };
};
