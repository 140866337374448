import { fetchFormsForAutocomplete } from "@rpforms/shared";
import { toSelectOptions } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const FormSelector = ({
    formValue = null,
    onSelect,
    defaultValue = null,
    filterBy = (form) => true,
    includeAllOption = false,
    ...props
}) => {
    const [value, setValue] = useState(null);

    const forms = useSelector<RootState, any>((store) => store.forms);
    const defaultForm = useSelector<RootState, any>((store) => {
        return typeof defaultValue === "number"
            ? toSelectOptions([store.forms.autocomplete.find((c) => c.id === defaultValue)])[0]
            : defaultValue;
    });

    const options = () => {
        if (includeAllOption) {
            return toSelectOptions([
                {
                    id: 0,
                    name: "---ALLE FORMULAR-TYPEN---",
                },
                ...forms.autocomplete.filter(filterBy),
            ]);
        } else {
            return toSelectOptions(forms.autocomplete.filter(filterBy));
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (forms.autocompleteIsLoading) {
            return;
        }
        if (!formValue?.id) {
            return;
        }
        if (!forms.autocomplete || (forms.autocomplete.length <= 0 && formValue)) {
            dispatch(
                fetchFormsForAutocomplete((formsData) => {
                    setValue(toSelectOptions(formsData.filter((f) => f.id === formValue.id))[0]);
                })
            );
        } else {
            setValue(toSelectOptions(forms.autocomplete.filter((c) => c.id === formValue.id))[0]);
        }
    }, [formValue, forms.autocompleteIsLoading]);

    const onChange = (option) => {
        setValue(option);
        onSelect(option);
    };

    const defaultProps = () => {
        if (defaultForm) {
            return { defaultValue: defaultForm };
        } else {
            return {};
        }
    };

    return (
        <Select
            {...defaultProps()}
            onChange={onChange}
            options={options()}
            value={value}
            {...props}
        />
    );
};
