import {
    EDITOR_FORM_ENTRY,
    EDITOR_FORM_ENTRY_CUSTOMER_CHANGED,
    EDITOR_FORM_ENTRY_ENTITY_CHANGED,
    EDITOR_FORM_ENTRY_FORM_CHANGED,
    EDITOR_FORM_ENTRY_FORM_DATA_CHANGED,
    EDITOR_FORM_ENTRY_FORM_REVISION_CHANGED,
    EDITOR_FORM_ENTRY_NAME_CHANGED,
    EDITOR_FORM_ENTRY_SET,
    EDITOR_FORM_SNAPSHOT_CHANGED,
    FORM_ENTRIES_CREATE,
    FORM_ENTRIES_DELETED,
    FORM_ENTRIES_DEVICE_LOADED,
    FORM_ENTRIES_ENTITY_LOADED,
    FORM_ENTRIES_FORM_LOADED,
    FORM_ENTRIES_LIST_LOADED,
    FORM_ENTRIES_LIST_LOADED_PAGE,
    FORM_ENTRIES_LOADING,
    FORM_ENTRIES_LOADING_STOP,
    FORM_ENTRIES_SET_MIGRATION_PANES,
    FORM_ENTRIES_SINGLE_LOADED,
    FORM_REGENERATING_PDF,
    SET_FORM_ENTRY_DATA,
    ZIP_REQUEST,
} from "../actions/types";

const initialState = {
    byDevice: {},
    byEntity: {},
    byForm: {},
    byId: {},
    isLoading: false,
    regeneratingPDF: false,
    list: [],
    data: {
        form: {
            name: "bidde auswähle",
        },
        form_revision: {
            form_data: {},
        },
        snapshot_nr: 0,
    },
    migrationPanes: [],
    zip: {
        message: "",
    },
};

export default function (
    state = {
        ...initialState,
        data: { ...initialState.data, test: 123 },
    },
    action: IReduxAction<any>
) {
    switch (action.type) {
        case FORM_REGENERATING_PDF:
            return { ...state, regeneratingPDF: action.payload };
        case FORM_ENTRIES_LOADING:
            return { ...state, isLoading: true };
        case FORM_ENTRIES_LOADING_STOP:
            return { ...state, isLoading: false };
        case EDITOR_FORM_ENTRY:
            return {
                ...state,
                data: initialState.data,
            };
        case EDITOR_FORM_ENTRY_SET:
            return {
                ...state,
                data: action.payload,
            };
        case EDITOR_FORM_ENTRY_FORM_CHANGED:
            if (!action.payload) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        form_id: null,
                        form: {},
                        form_revision: {},
                        form_revision_id: null,
                        form_data: {},
                    },
                };
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    form_id: action.payload.id,
                    form: action.payload,
                    form_revision: action.payload.latest_revision,
                    form_revision_id: action.payload.latest_revision.id,
                    form_data: {},
                },
            };
        case EDITOR_FORM_ENTRY_FORM_REVISION_CHANGED:
            const fd = action.payload.form_revision.form_data;
            return {
                ...state,
                data: {
                    ...state.data,
                    form: {
                        form_data: typeof fd === "object" ? fd : JSON.parse(fd),
                    },
                    form_revision_id: action.payload.form_revision.id,
                    form_revision: action.payload.form_revision,
                },
            };
        case EDITOR_FORM_ENTRY_ENTITY_CHANGED:
            return {
                ...state,
                data: {
                    ...state.data,
                    entity_id: action.payload.entity_id,
                    entity: action.payload.entity,
                },
            };
        case EDITOR_FORM_ENTRY_CUSTOMER_CHANGED:
            return {
                ...state,
                data: {
                    ...state.data,
                    customer_id: action.payload.customer_id,
                    customer: action.payload.customer,
                },
            };
        case EDITOR_FORM_SNAPSHOT_CHANGED:
            return {
                ...state,
                data: {
                    ...state.data,
                    snapshot_nr: action.payload.snapshot_nr,
                },
            };
        case SET_FORM_ENTRY_DATA:
            return {
                ...state,
                data: action.payload.data,
            };
        case EDITOR_FORM_ENTRY_FORM_DATA_CHANGED:
            return {
                ...state,
                data: {
                    ...state.data,
                    form_data: action.payload.form_data,
                    name: action.payload.name,
                },
            };
        case EDITOR_FORM_ENTRY_NAME_CHANGED:
            return {
                ...state,
                data: { ...state.data, name: action.payload.name },
            };
        case FORM_ENTRIES_CREATE:
            return {
                ...state,
                data: initialState.data,
            };
        case FORM_ENTRIES_DELETED:
            const byId = Object.keys(state.byId)
                .filter((entryID) => entryID !== action.payload.entry.id)
                .map((id) => (state as any).byId[id]);
            return {
                ...state,
                byId,
                list: state.list.filter((obj: any) => obj.id !== action.payload.entry.id),
            };
        case FORM_ENTRIES_DEVICE_LOADED:
            return {
                ...state,
                byDevice: {
                    ...state.byDevice,
                    [`device_${action.payload.device.id}`]: action.payload.data.form_entries,
                },
            };

        case FORM_ENTRIES_ENTITY_LOADED:
            return {
                ...state,
                byEntity: {
                    ...state.byEntity,
                    [`entity_${action.payload.entity.id}`]: action.payload.data.form_entries,
                },
            };

        case FORM_ENTRIES_FORM_LOADED:
            return {
                ...state,
                byForm: {
                    ...state.byForm,
                    [`form_${action.payload.form.id}`]: action.payload.data.form_entries,
                },
            };

        case FORM_ENTRIES_SINGLE_LOADED:
            return {
                ...state,
                list: [...state.list].map((entry: any) =>
                    entry.id === action.payload.id ? action.payload : entry
                ),
                byId: {
                    ...state.byId,
                    [`entry_${action.payload.id}`]: action.payload,
                },
            };

        case FORM_ENTRIES_LIST_LOADED_PAGE:
            return { ...state, list: action.payload };
        case FORM_ENTRIES_LIST_LOADED:
            return { ...state, list: action.payload };
        case FORM_ENTRIES_SET_MIGRATION_PANES:
            return {
                ...state,
                migrationPanes: [...action.payload],
            };
        case ZIP_REQUEST:
            return {
                ...state,
                zip: action.payload,
            };
    }

    return state;
}
