export const COMPANY_ID = process.env.REACT_APP_COMPANY_ID || 1;

/**
 * If you want to test against the staging api use: https://api.staging.rpforms.kega.network
 * You also need to make the same change in /shared/src/config.ts
 */
export const API_URL = process.env.REACT_APP_BACKEND_URL;
declare const window: any;
// tslint:disable-next-line:no-var-requires
window.STAMP_URL = require("./images/stamp.png");
// tslint:disable-next-line:no-var-requires
window.LOGO_URL = require("./images/logo.svg");
// tslint:disable-next-line:no-var-requires
window.DORMAKABA = require("./images/dormakaba.jpg");
