import { toSelectOptions, toSelectOptionsWithAll } from "@rpforms/shared";
import { fetchFormsForAutocomplete } from "@rpforms/shared/build/actions/forms";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const FormFilter = ({ onSelect, includeAll = "alle Formulare", value, ...props }) => {
    const dispatch = useDispatch();
    const forms = useSelector<RootState, any>((store) => store.forms);

    useEffect(() => {
        if (forms.autocompleteIsLoading) {
            return;
        }
        if (!forms.autocomplete || forms.autocomplete.length <= 0) {
            dispatch(
                fetchFormsForAutocomplete((data) => {
                    onSelect(getDefaultValue(data));
                })
            );
        } else {
            onSelect(getDefaultValue(forms.autocomplete));
        }
    }, []);

    const getDefaultValue = (d) => {
        let dv = value;
        if (!Array.isArray(dv)) {
            dv = [dv];
        }
        const defaultValueIds = dv.filter((x) => x != null).map((x) => parseInt(x.value, 10));
        const arr = d.filter((c) => defaultValueIds.includes(c.id));
        const ret = props.isMulti
            ? toSelectOptions(arr, "name", "id")
            : toSelectOptionsWithAll(arr, includeAll, "name", "id");
        return props.isMulti ? ret : ret[0];
    };

    const onChange = (option) => {
        onSelect(option);
    };

    return (
        <Select
            onChange={onChange}
            options={
                props.isMulti
                    ? toSelectOptions(forms.autocomplete)
                    : toSelectOptionsWithAll(forms.autocomplete, includeAll)
            }
            value={value}
            placeholder={includeAll}
            {...props}
        />
    );
};
