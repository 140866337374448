import React from "react";

export const AppearanceCheckbox = ({
    register,
    label,
    field,
    keyName,
    children = [],
    value,
    placeholder = "",
}) => {
    return (
        <div>
            <label className="d-flex align-items-center" htmlFor={field.appearanceName(keyName)}>
                <input
                    value={value}
                    defaultChecked={value}
                    type="checkbox"
                    placeholder={placeholder}
                    ref={register}
                    style={{ width: 40 }}
                    name={field.appearanceName(keyName)}
                    id={field.appearanceName(keyName)}
                />
                {label}
            </label>
        </div>
    );
};
