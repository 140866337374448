import { sharedHistory } from "@rpforms/shared";
import * as actions from "@rpforms/shared/build/actions/entities";
import { createEntity } from "@rpforms/shared/build/actions/entities";
import { PrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import renderField from "@rpforms/shared/build/components/layout/Forms";
import React, { Component, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { RootState } from "../../reducers";
import { CustomerSelect } from "../customers/select";

const NewEntity = ({ handleSubmit, errorMessage }) => {
    const [validated, setValidated] = useState(false);
    const [customer, setCustomer] = useState({});
    const dispatch = useDispatch();
    const company = useSelector<RootState, any>((state) => {
        return state.company;
    });

    const handleFormSubmit = async (props) => {
        const newEntity = await dispatch(
            createEntity(company, {
                ...props,
                customer_id: (customer as any).value,
            })
        );
        toast.success("✌️Objekt erstellt", { autoClose: 2000 });
        sharedHistory.push("master_data/objects/");
    };

    const toggleValidation = () => {
        setValidated(!validated);
    };

    return (
        <div className="form-container">
            <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
                <label>Kunde</label>
                <CustomerSelect onSelect={setCustomer} />
                <br />
                <label>Objektname</label>
                <Field
                    name="title"
                    required="true"
                    component={renderField}
                    type="text"
                    placeholder="Name des Objekts"
                />
                <br />
                <label>Objekt Nr.</label>
                <Field
                    name="entity_nr"
                    required="true"
                    component={renderField}
                    type="text"
                    placeholder="Objektnummer"
                />
                {errorMessage && errorMessage.title && (
                    <div className="error-container signin-error">Oops! {errorMessage.title}</div>
                )}

                {/* Create button */}
                <PrimaryButton type="submit" onClick={toggleValidation} className="btn btn-primary">
                    Weiter <i className="fa fa-chevron-right" />
                </PrimaryButton>
            </form>
        </div>
    );
};

function validate(formProps) {
    const errors: any = {};

    if (!formProps.title) {
        errors.title = "Das Objekt muss einen Namen haben.";
    }

    if (!formProps.entity_nr) {
        errors.entity_nr = "Das Objekt muss eine Nr. haben.";
    }

    return errors;
}

function mapStateToProps(state) {
    return { errorMessage: state.forms.error };
}

export default withRouter(
    connect(
        mapStateToProps,
        actions
    )(
        reduxForm({
            form: "newentity",
            validate,
        })(NewEntity)
    )
);
