import React from "react";
import { BaseField } from "../BaseField";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";

/**
 * keep values for saving or overriding
 */
export class KeepExisting extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = false;

    public keyName = "keepexisting";
    public title = "Inhalt nicht automatisch überschreiben";
    public description =
        "Wenn Autofill & diese Option aktiviert ist, wird Inhalt nicht automatisch überschrieben.";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: BaseField;
    }): any {
        return (
            <AppearanceCheckbox
                label={this.title}
                register={register}
                value={field.rawOptions[this.keyName]}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value !== false;
    }

    public wakeValue(value) {
        return value;
    }
}
