import { map, uniq, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { RepairForms } from "./RepairForms";

export const RepairBox = ({ tasks, calendarEvent, initial }) => {
    const [enabled, setEnabled] = useState(initial);
    const [selected, setSelected] = useState([]);
    const [text, setText] = useState("");
    const onChange = (el) => {
        (window as any).reparea.value = (window as any).reparea.value
            ? (window as any).reparea.value +
              "\n" +
              (el.form_data["rep_text"] ||
                  (typeof el.form_data["rep_text"] === "undefined" ? "<Leeres Feld>" : ""))
            : el.form_data["rep_text"] ||
              (typeof el.form_data["rep_text"] === "undefined" ? "<Leeres Feld>" : "");
    };

    useEffect(() => {
        if (!(window as any).reparea) {
            return;
        }
        (window as any).reparea.value = calendarEvent.reptext || "";
    }, [enabled]);

    return (
        <div
            style={{
                padding: "20px",
                background: "rgb(228,246,255, 0.5)",
                fontSize: "18px",
                borderRadius: 8,
            }}
        >
            <div>
                <input
                    type={"checkbox"}
                    name={"repairbox"}
                    defaultChecked={initial}
                    id="repairbox"
                    onChange={(e: any) => setEnabled((window as any).repairbox.checked)}
                />{" "}
                <label htmlFor={"repairbox"}>Basiert auf Reparaturaufnahme</label>
            </div>
            {enabled ? (
                <div>
                    <textarea
                        id={"reparea"}
                        style={{ width: "400px", height: "200px" }}
                        className="form-control rounded-0"
                    ></textarea>
                    <div className={"p-5"}>
                        {uniqBy(tasks, "entity_id").map((task: any) => (
                            <RepairForms
                                entityId={task.entity_id}
                                entityName={task.entity_name}
                                onChange={onChange}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};
