import React from "react";

export const AppearanceText = ({
    register,
    value,
    field,
    keyName,
    children = [],
    placeholder = "",
}) => {
    return (
        <div>
            <input
                className="form-control"
                defaultValue={value}
                type="text"
                ref={register}
                name={field.appearanceName(keyName)}
            />
        </div>
    );
};
