import React, { useState } from "react";
import styled from "styled-components";

export const PercentageIndicator = styled.span`
    color: #fff;
    border-radius: 4px;
    padding: 2px 4px;
    text-align: center;
    background-color: #249dff;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
    display: inline-block;
    height: 22px;
    margin-top: 8px;
`;

export const AppearanceSlider = ({
    register,
    label,
    field,
    min = 0,
    max = 100,
    step = 10,
    keyName,
    children = [],
    value,
    placeholder = "",
    hidePercentage = false,
}) => {
    const [percentage, setPercentage] = useState(value);
    return (
        <div>
            <div className="d-flex">
                <input
                    className="form-control"
                    defaultValue={value != null ? value : "100"}
                    type="range"
                    placeholder={placeholder}
                    ref={register}
                    min={min}
                    max={max}
                    step={step}
                    onChange={(ev) => {
                        setPercentage(ev.target.value);
                    }}
                    name={field.appearanceName(keyName)}
                />
                <PercentageIndicator>
                    {percentage != null ? percentage : 100}
                    {hidePercentage || "%"}
                </PercentageIndicator>
            </div>
            {label}
        </div>
    );
};
