import { IonButton } from "@ionic/react";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { user } from "../../actions/actions_helper";
import { API_URL } from "../../config";
import { extensionFromMimeType, findParent, resizeImage, template, uniqueID } from "../../utils";
import { BootstrapLayout } from "../BootstrapLayout";
import { IAsset } from "../../AssetManager";

const extensionFor = (file) => {
    const fileParts = file.split(".");
    const ext = fileParts.pop();
    return "." + (ext || "jpg");
};

const dataurl = async ({ upload }: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = async (metadata) => {
            resolve({
                metadata: {
                    ...metadata,
                    extension: extensionFromMimeType(upload[0].type) || "jpg",
                },
                source: await resizeImage(fileReader.result as string, 1024, 1024),
                extension: extensionFromMimeType(upload[0].type) || "jpg",
            });
        };

        fileReader.readAsDataURL(upload[0]);
    });
};

const read = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = async (metadata) => {
            resolve(await resizeImage(fileReader.result as string, 1024, 1024));
        };

        fileReader.readAsDataURL(file);
    });
};

const generateUploadName = ({ namePattern, variables, preparedFields, modalRef, uploads }) => {
    let index = null;
    if (variables && variables.uploads) {
        index = variables.uploads.length + 1;
    }

    const name = template(
        namePattern,
        {
            ...variables,
            file: {
                extension: "jpg",
            },
            subfield: preparedFields,
            upload: {
                index,
            },
        },
        findParent(modalRef.current, "form").id
    );

    let uploadName = name;
    let iter = 1;
    while (uploads.find((u) => u.name === uploadName)) {
        const ext = extensionFor(name);
        uploadName = name.replace(ext, "") + "_" + iter + ext;
        if (iter++ > 100) {
            uploadName = name.replace(ext, "") + "_" + new Date() + ext;
            break;
        }
    }

    return uploadName;
};

const toAsset = async (
    { upload }: { upload: FileList },
    name: string,
    dispatch
): Promise<IAsset> => {
    const uuid = uniqueID();
    const extension = extensionFromMimeType(upload[0].type) || "jpg";
    const path = "images/" + uuid + "." + extension;
    // For ipad
    if (typeof (window as any).Capacitor !== "undefined") {
        /**
         * Inside the app context, we want to store the asset in the filesystem
         */
        const iPadAsset: IAsset = {
            uploaded: false,
            metadata: {},
            path,
            uuid,
        };

        await window.AssetManager.write(iPadAsset, await read(upload[0]));

        const job = {
            id: uniqueID(),
            title: "Bildübertragung " + iPadAsset.uuid,
            description: "Formular übertragen",
            state: "idle",
            type: "uploadAsset",
            data: iPadAsset,
        };

        window.db.sync.put(job);
        window.db.tasks.put({ asset: iPadAsset, status: 3 /* IN_QUEUE */ });
        return iPadAsset;
    }

    /**
     * In the web context, we want to upload the asset immediately to rails
     */
    const asset = {
        uploaded: false,
        metadata: {},
        path,
        uuid,
    };
    const image = await read(upload[0]);
    await fetch(`${API_URL}/form_entry_assets.json`, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: user().token,
        },
        body: JSON.stringify({
            data: asset,
            image,
        }),
    });

    return {
        uploaded: true,
        metadata: asset.metadata,
        path: asset.path,
        uuid: asset.uuid,
    };
};

export const UploadForm = ({
    namePattern,
    onUpload,
    subfields,
    editUpload,
    uploads,
    variables = {},
    hashCodeRef,
    autoSave = false,
}: any) => {
    const modalRef: any = useRef();
    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data: any) => {
        const preparedFields: any = [...modalRef.current.querySelectorAll("[name]")].reduce(
            (prev: any, cur: any) => {
                if (cur.name === "upload") {
                    return prev;
                }

                return { ...prev, [cur.name]: cur.value };
            },
            {}
        );

        const uploadName = generateUploadName({
            modalRef,
            namePattern,
            preparedFields,
            uploads,
            variables,
        });

        const asset = editUpload ? editUpload.file : await toAsset(data, uploadName, dispatch);
        onUpload({
            uuid: editUpload ? editUpload.uuid : asset.uuid,
            subfields: preparedFields,
            file: asset,
            image: data.upload ? await dataurl(data) : {},
            name: uploadName,
            hashCodeRef,
            __attachment: true,
            __asset: true,
        });
    };

    const handleChange = async (data) => {
        if (!autoSave) {
            return;
        }
        await onSubmit({
            upload: modalRef.current.querySelector("input[type='file']").files,
        });

        if (modalRef.current) {
            modalRef.current.querySelector("input[type='file']").value = null;
        }
        handleSubmit(onSubmit);
    };

    return (
        <form ref={modalRef} onSubmit={handleSubmit(onSubmit)}>
            {!editUpload && (
                <input
                    ref={register}
                    onInput={handleChange}
                    name={"upload"}
                    type={"file"}
                    multiple={false}
                />
            )}
            {!autoSave && (
                <>
                    <br />
                    <br />
                    {subfields &&
                        subfields.map((subfield: any) => (
                            <subfield.InputComponent
                                layout={BootstrapLayout}
                                name={subfield.hashCode}
                                key={subfield.hashCode}
                                field={subfield}
                                rawValue={editUpload ? editUpload.subfields[subfield.hashCode] : ""}
                                register={register}
                            />
                        ))}
                    <IonButton type={"submit"}>
                        Bild {editUpload ? "bearbeiten" : "speichern"}
                    </IonButton>
                </>
            )}
        </form>
    );
};
