import { fetchEntitiesForAutocomplete, folderNameRegex } from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import { highestEntityNr, padIncrement } from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";
import { CustomerSelect } from "../customers/select";
import { ModalAddressForm } from "./ModalAddressForm";

interface IModalEntity {
    editor: IEditor;
    next: ({}: any) => Promise<void>;
}

const STARTING_ENTITIES_NR = "00001";
export const ModalEntity = ({ editor, next }: IModalEntity) => {
    const delInTags = () => {
        return entity?.terms?.filter((t) => t.name === "Gekündigt").length > 0;
    };

    const wartungsvertragInTags = () => {
        return entity?.terms?.filter((t) => t.name === "Wartungsvertrag").length > 0;
    };

    const [extraArgs, setExtraArgs] = useState({});
    const [entity, setEntity] = useState<IEntity>({ ...editor.config.preload });
    const [taggedAsDeleted, setTaggedAsDeleted] = useState(delInTags());
    const [taggedAsWartungsvertrag, setTaggedAsWartungsvertrag] = useState(wartungsvertragInTags);
    const { register, handleSubmit, watch, errors } = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        if (entity.entity_nr) {
            return;
        }
        const loadEntityNr = async () => {
            const entities: any = await dispatch(fetchEntitiesForAutocomplete());
            const nextEntityNr = entities.length
                ? padIncrement(highestEntityNr(entities))
                : STARTING_ENTITIES_NR;
            setEntity({
                ...entity,
                entity_nr: "O" + nextEntityNr,
            });
        };
        loadEntityNr();
    }, []);

    const isNew = editor.config.new;

    const onSubmit = async (data) => {
        const terms = [];
        if (taggedAsWartungsvertrag) {
            terms.push({ name: "Wartungsvertrag" });
        }
        if (taggedAsDeleted) {
            terms.push({ name: "Gekündigt" });
        }
        next({
            entity: {
                ...editor.config.preload,
                ...data,
                ...extraArgs,
                terms,
            },
        }).then(() => dispatch(fetchEntitiesForAutocomplete()));
    };

    const updateCustomer = (option: any) => {
        setExtraArgs({ ...extraArgs, customer_id: option.value });
    };

    // Map ReactHookForm errors to regular errors
    const addressErrors = {
        street: errors["address_attributes.street"],
        city: errors["address_attributes.city"],
        country: errors["address_attributes.country"],
        postalcode: errors["address_attributes.postalcode"],
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{isNew ? "Neues Objekt erstellen" : "Objekt bearbeiten"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!entity.customer_id && (
                    <Row>
                        <Col>
                            <label>Kunde</label>
                            <CustomerSelect
                                defaultValue={entity.customer_id}
                                onSelect={updateCustomer}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <label>Name</label>
                        <input
                            defaultValue={entity.name}
                            className={"form-control"}
                            name="name"
                            ref={register({
                                required: "Name muss ausgefüllt sein",
                                pattern: {
                                    value: folderNameRegex(),
                                    message: "Name muss mit Buchstabe oder Zahl enden",
                                },
                            })}
                        />
                        {errors.name && <InputError>{errors.name.message}</InputError>}
                    </Col>
                    <Col>
                        <label>Objektnummer</label>
                        <input
                            defaultValue={entity.entity_nr}
                            className={"form-control"}
                            name="entity_nr"
                            readOnly={true}
                            ref={register({
                                required: "Objektnummer muss ausgefüllt werden.",
                                pattern: {
                                    value: /^O[0-9]{4,5}$/i,
                                    message: "Objektnummer muss folgendes Format haben: O1111",
                                },
                            })}
                        />
                        {errors.entity_nr && <InputError>{errors.entity_nr.message}</InputError>}
                    </Col>
                </Row>
                <ModalAddressForm
                    exclude={["address_type", "contact", "extension"]}
                    prefix={"address_attributes."}
                    address={entity.address}
                    register={register}
                    errors={addressErrors}
                />
                <Row>
                    <Col>
                        <label>Leistungsempfänger</label>
                        <input
                            defaultValue={entity.service_contact}
                            className={"form-control"}
                            name="service_contact"
                            ref={register({})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Ansprechpartner vor Ort</label>
                        <input
                            defaultValue={entity.contact_name}
                            className={"form-control"}
                            name="contact_name"
                            ref={register({})}
                        />
                    </Col>
                    <Col>
                        <label>Ansprechpartner vor Ort Telefon</label>
                        <input
                            type="tel"
                            defaultValue={entity.contact_phone}
                            className={"form-control"}
                            name="contact_phone"
                            ref={register({
                                pattern: {
                                    value: /^\+?[0-9]*\/*\s*(\([0-9]+\))?\s*([-–]?\s*[0-9]+)*$/,
                                    message: "Bitte gebe eine gültige Telefonnummer ein.",
                                },
                            })}
                        />
                        {errors.contact_phone && (
                            <InputError>{errors.contact_phone.message}</InputError>
                        )}
                    </Col>

                    <Col>
                        <label>Ansprechpartner vor Ort E-Mail</label>
                        <input
                            type="email"
                            defaultValue={entity.contact_email}
                            className={"form-control"}
                            name="contact_email"
                            ref={register({})}
                        />
                        {errors.contact_email && (
                            <InputError>{errors.contact_email.message}</InputError>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Ansprechpartner Terminierung</label>
                        <input
                            defaultValue={entity.timing_contact_name}
                            className={"form-control"}
                            name="timing_contact_name"
                            ref={register({})}
                        />
                    </Col>
                    <Col>
                        <label>Ansprechpartner Terminierung Telefon</label>
                        <input
                            type="tel"
                            defaultValue={entity.timing_contact_phone}
                            className={"form-control"}
                            name="timing_contact_phone"
                            ref={register({
                                pattern: {
                                    value: /^\+?[0-9]*\/*\s*(\([0-9]+\))?\s*([-–]?\s*[0-9]+)*$/,
                                    message: "Bitte gebe eine gültige Telefonnummer ein.",
                                },
                            })}
                        />
                        {errors.timing_contact_phone && (
                            <InputError>{errors.timing_contact_phone.message}</InputError>
                        )}
                    </Col>

                    <Col>
                        <label>Ansprechpartner Terminierung E-Mail</label>
                        <input
                            type="email"
                            defaultValue={entity.timing_contact_email}
                            className={"form-control"}
                            name="timing_contact_email"
                            ref={register({})}
                        />
                        {errors.timing_contact_email && (
                            <InputError>{errors.timing_contact_email.message}</InputError>
                        )}
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col>
                        <Form.Check type="checkbox" id={"entity-terms"}>
                            <Form.Check.Input
                                type="checkbox"
                                checked={taggedAsDeleted}
                                onChange={(ev) => {
                                    setTaggedAsDeleted(!taggedAsDeleted);
                                }}
                            />
                            <Form.Check.Label className={"mt-0"}>
                                <strong>als „Gekündigt“ markieren</strong>
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                    <Col>
                        <Form.Check type="checkbox" id={"entity-wartungsvertrag"}>
                            <Form.Check.Input
                                type="checkbox"
                                checked={taggedAsWartungsvertrag}
                                onChange={(ev) => {
                                    setTaggedAsWartungsvertrag(!taggedAsWartungsvertrag);
                                }}
                            />
                            <Form.Check.Label className={"mt-0"}>
                                <strong>Wartungsvertrag abgeschlossen</strong>
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {editor.saving && <>speichert …</>}
                <PrimaryButton
                    disabled={editor.saving}
                    style={editor.saving ? { background: "gray" } : {}}
                    type={"submit"}
                >
                    {isNew ? "Erstellen" : "Speichern"}
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
