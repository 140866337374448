import { fetchNotificationGroups } from "@rpforms/shared/build/actions/notification_groups";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { range } from "@rpforms/shared/build/utils";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParam } from "react-use";
import styled from "styled-components";
import { ListRow } from "../../components/forms/FormListRow";
import { NotificationRow } from "../../components/notifications/NotificationRow";

const Centered = styled.div`
    display: flex;
    justify-content: center;
`;

const ListNotificationPage = () => {
    const notificationGroups = useSelector<any, any>((state) => state.notificationGroups);
    const initialPage = parseInt(useSearchParam("page"), 10) || 1;
    const [page, setPage] = useState(initialPage);
    const dispatch = useDispatch();
    const pagesOffset = 5;

    useEffect(() => {
        dispatch(push(`/notifications/all?page=${page}`));
        dispatch(fetchNotificationGroups(page));
    }, [page]);

    const openPage = (newPage) => {
        setPage(newPage);
    };

    if (notificationGroups.isLoading) {
        return <Loader />;
    }

    const pageCount = () => {
        const startRange = Math.max(2, page - pagesOffset);
        const endRange = Math.min(pages - 1, page + pagesOffset);
        return [1, ...range(startRange, endRange), pages];
    };

    return (
        <>
            <PageNavbar
                title="Benachrichtigungen"
                description="Hier können Sie Ihre Benachrichtigungen einsehen."
            />
            {notificationGroups.notification_groups.length === 0 ? (
                <Content>
                    <EmptySpace
                        title={"Keine Einträge gefunden."}
                        description={
                            "Übertrage ein ausgefülltes Formular mit einem iPad damit es hier erscheint."
                        }
                        cta={false}
                    />
                </Content>
            ) : (
                <>
                    <Content>
                        {notificationGroups.notification_groups.map((notificationGroup) => (
                            <div
                                style={{ cursor: "pointer", marginTop: "20px" }}
                                key={notificationGroup.id}
                            >
                                <h3>{notificationGroup.name}</h3>
                                {notificationGroup.notifications.map((n) => (
                                    <ListRow key={n.id}>
                                        <NotificationRow notification={n} />
                                    </ListRow>
                                ))}
                            </div>
                        ))}
                    </Content>
                    {false && (
                        <div>
                            <Centered>
                                <Pagination>
                                    {pageCount().map((p) => {
                                        return (
                                            <Pagination.Item
                                                active={p === page}
                                                key={`page_${p}`}
                                                onClick={() => openPage(p)}
                                            >
                                                {p}
                                            </Pagination.Item>
                                        );
                                    })}
                                </Pagination>
                            </Centered>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ListNotificationPage;
