import React, { useState } from "react";
import styled from "styled-components";
import AddField from "../components/AddField";
import { FieldWrapper } from "../components/FieldWrapper";
import { PlainLayout } from "../components/PlainLayout";
import { BaseField } from "./BaseField";
import { Conditional } from "./configurables/Conditional";
import { ISubfields } from "./interfaces/ISubfields";
import { AutoCompleteTableRow } from "./table/AutoCompleteTableRow";
import { CheckboxTableRow } from "./table/CheckboxTableRow";
import { CustomRendererTableRow } from "./table/CustomRendererTableRow";
import { DateTableRow } from "./table/DateTableRow";
import { DropdownTableRow } from "./table/DropdownTableRow";
import { SelectTableRow } from "./table/SelectTableRow";
import { TextTableRow } from "./table/TextTableRow";
import { TimeTableRow } from "./table/TimeTableRow";

import { Modal } from "react-bootstrap";
import { Description, Hint, MaxLength, Required, Width } from "./configurables";
import { Label, LabelInput } from "./inputFields/label";
import { Table } from "./table";
import { ImageTableRow } from "./table/ImageTableRow";
import { IndexTableRow } from "./table/IndexTableRow";

const TableWrapper = styled.div`
    .wtHolder {
        height: auto !important;
    }
`;

export class TableField extends BaseField implements ISubfields {
    public name = "";
    public label = "Tabelle";
    public subfields = [];
    public icon = "table";
    public fieldType = "Tabelle";
    public initialValue = [];
    public attributeNames = ["initialValue"];

    public getConfigurables() {
        return [Conditional, MaxLength, Required, Description, Width, Hint];
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        rawValue,
        readonly = false,
        variables = { isPrinting: false },
    }) {
        if (!rawValue) {
            rawValue = field.rawValue;
        }
        let data = rawValue || [];
        if (typeof data === "string") {
            data = JSON.parse(data);
        }

        if (!Array.isArray(data)) {
            data = [];
        }
        const colHeaders = [];

        field.subfields = field.subfields
            .map((subfield) => {
                if (subfield.fieldType === "Bildupload" && variables.isPrinting) {
                    data = data.map((row) =>
                        row.filter((element) => element.hashCodeRef !== subfield.hashCode)
                    );
                    subfield.hide = true;
                }
                colHeaders.push(subfield.label);
                return subfield;
            })
            .filter((s) => !s.hide);

        const columns = field.subfields.map((subfield) => field.subfieldToColumn(subfield));

        return (
            <div className={layout.wrapComponentCSS(field.configureCSS(""))}>
                <Label layout={layout} field={field} />
                <Table
                    id={field.hashCode}
                    initialData={data}
                    columns={columns}
                    readonly={readonly}
                />
                {layout.wrapHint(field.rawOptions?.hint ? <p>{field.rawOptions.hint}</p> : <></>)}
            </div>
        );
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        const handleNewField = (fieldClass) => {
            schema.subfield(field, new fieldClass(schema));
            designer.save(schema, { delaySave: true });
        };

        const [showDataAggregator, toggle] = useState(false);
        const [tableField, setTableField] = useState(null);
        if (!tableField) {
            setTableField(new TableField({ field }));
        }

        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Titel</label>
                <LabelInput register={register} field={field} />
                <div className="form-group">
                    Hier können Sie die Spalten der Tabelle definieren.
                    <div className="powerform-row-edit">
                        {field.subfields ? (
                            field.subfields.map((subfield) => (
                                <subfield.EditComponent
                                    register={register}
                                    key={subfield.hashCode}
                                    schema={schema}
                                    designer={designer}
                                    field={subfield}
                                    entry={null}
                                />
                            ))
                        ) : (
                            <span />
                        )}
                    </div>
                    <hr />
                    Vorschau
                    {tableField ? (
                        tableField.InputComponent({
                            layout: PlainLayout,
                            schema,
                            field,
                            entry,
                            register,
                        })
                    ) : (
                        <></>
                    )}
                    <hr />
                    <div>
                        <br />
                        <div className="d-flex">
                            <AddField
                                small={true}
                                fields={[
                                    IndexTableRow,
                                    TextTableRow,
                                    DateTableRow,
                                    ImageTableRow,
                                    TimeTableRow,
                                    AutoCompleteTableRow,
                                    CustomRendererTableRow,
                                    DropdownTableRow,
                                    SelectTableRow,
                                    CheckboxTableRow,
                                ]}
                                handleNewField={handleNewField}
                            />

                            <Modal
                                isShowing={showDataAggregator}
                                hide={() => toggle(false)}
                                title={`Datensätze vorgeben`}
                                keyboard={false}
                                backdrop="static"
                            >
                                <>
                                    <TableWrapper className="modal-body">
                                        <p>
                                            Hier können Sie Datensätze der Tabelle vorgeben die beim
                                            Benutzer vorausgefüllt werden.
                                        </p>
                                        {tableField ? (
                                            tableField.InputComponent({
                                                layout: PlainLayout,
                                                schema,
                                                field,
                                                entry,
                                                register,
                                            })
                                        ) : (
                                            <></>
                                        )}
                                    </TableWrapper>
                                    <div className="modal-footer">
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                const tableField: any = document.querySelector(
                                                    `[name='${field.hashCode}']`
                                                );
                                                const data = JSON.parse(tableField.value);
                                                data.pop(); // remove last row
                                                schema.setFieldOption(field, "initialValue", data);
                                                toggle(false);
                                            }}
                                            className="btn btn-primary"
                                        >
                                            Speichern
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => toggle(false)}
                                            data-dismiss="modal"
                                        >
                                            Schließen
                                        </button>
                                    </div>
                                </>
                            </Modal>
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    public PresenterComponent({ field }: { field: any }): any {
        const colHeaders = [];
        let data = [];
        try {
            data = JSON.parse(field.rawValue);
        } catch (e) {
            data = [];
        }

        field.subfields.forEach((subfield) => {
            colHeaders.push(subfield.label);
        });

        if (data.length === 0) {
            // place empty row so that headers are visible
            data.push(colHeaders.map((e) => null));
        }

        const columns = field.subfields.map((subfield) => field.subfieldToColumn(subfield));

        return (
            <div>
                <strong>{field.label}:</strong>

                <div>
                    <Table id={field.hashCode} initialData={data} columns={columns} />
                </div>
            </div>
        );
    }

    public subfieldToColumn(subfield) {
        return {
            ...subfield.toHotColumn(),
            formName: this.formName,
            title: subfield.label,
            width: subfield.rawOptions ? subfield.rawOptions.width : 100,
        };
    }
}
