import React from "react";
import { BaseField } from "../BaseField";
import { Configurable } from "./Configurable";

/**
 * Applies maximum length to input field
 */
export class DefaultValue extends Configurable {
    public schema;
    public designer;
    public field;

    public keyName = "defaultValue";
    public title = "Standardwert";
    public description = "Hier können Sie einen Standardwert für das Feld vorgeben";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: BaseField;
    }): any {
        return (
            <>
                <span className="text-danger">Diese Funktion wurde temporär deaktiviert</span>
            </>
        );
    }

    public sleepValue(value): any {
        return value;
    }

    public wakeValue(value) {
        return value;
    }
}
