import * as actions from "@rpforms/shared/build/actions/formEntries";
import * as formActions from "@rpforms/shared/build/actions/forms";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ViewPdfEntry from "../../components/entries/ViewPdfEntry";

class ViewPdfEntryPage extends Component {
    public props;
    public state: any = {};

    public componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const auth = params.get("auth");
        const isPrinting = params.has("isPrinting");
        if (auth) {
            localStorage.setItem(
                "user",
                JSON.stringify({
                    username: "pdfbot",
                    id: 1,
                    exp: +new Date() + +new Date(),
                    token: auth,
                })
            );
        }

        this.props.getFormEntryForPdf(this.props.match.params.id, () => {});
        this.props.getFormByEntry(this.props.match.params.id, () => {});
        this.setState({ isPrinting });
    }

    public render() {
        const entry = this.props.formEntries.byId[`entry_${this.props.match.params.id}`];
        const form =
            entry && this.props.forms
                ? this.props.forms.forms.find((f) => f.id === entry.form.id)
                : null;
        return (
            <>
                {entry && form ? (
                    <ViewPdfEntry form={form} entry={entry} isPrinting={this.state.isPrinting} />
                ) : (
                    <Loader />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        formEntries: state.formEntries,
        forms: state.forms,
    };
}

export default withRouter(
    connect(mapStateToProps, { ...actions, ...formActions })(ViewPdfEntryPage)
);
