import { ConfigField } from "@rpforms/shared/build/fields/ConfigField";
import React from "react";

import styled from "styled-components";
import { FormHeader, FormLandscape, FormPortrait, FormTitle } from "./FormPresenter";

// tslint:disable-next-line:no-var-requires
const logoPath = require("../images/logo.svg");
const FormW = styled.div``;
/**
 * Presents a schemaBuilder in a way that users can submit entries
 * This does not store any submitted forms as they are processed by an external URL
 * By not handling the form submission (other than basic validation) we ensure
 * that powerform can be embedded into any existing webpage and even
 * replace forms with it without fiddling with the backend.
 *
 * @param hideSubmit
 * @param schema {any} The schemaBuilder you want to present to the user
 * @param layout
 * @param useFormTag
 * @param form
 * @param saveState
 * @constructor
 */
export const DataPresenter = ({ schema, layout, useFormTag = false, form, saveState }) => {
    const formID = form.name.split("_")[0];
    let formName = form.name.replace(formID + "_", "");

    (window as any).__GLOBAL_CONDITIONALS = true;

    const config = { layout: FormPortrait, isLandscape: false };
    // find a config block
    schema.fields().map((field) => {
        if (field instanceof ConfigField) {
            config.isLandscape = field.displayLandscape === "on";
            config.layout = config.isLandscape ? FormLandscape : config.layout;
            formName = (config as any).title;
        }
    });

    const cssPrintPage = {
        [FormPortrait]: `@page { size: A4; } `,
        [FormLandscape]: `@page { size: A4 landscape; }`,
    };

    const styleSheet = document.createElement("style");
    styleSheet.innerHTML = cssPrintPage[config.layout];
    document.body.appendChild(styleSheet);
    const sheets = schema.asPDFSheets();
    const tagName: any = useFormTag ? "form" : "div";

    if (schema.getConfig()) {
        formName = schema.getConfig().title;
    }

    return (
        <FormW as={tagName} className="form-container" data-form={formID}>
            <div style={{ paddingBottom: 0, padding: 0, margin: 0 }}>
                <div className={(window as any).EDIT_MODE ? "" : "print-area"}>
                    {sheets.map((sheet, index) => {
                        return (
                            <div className="sheet" key={`sheet_${index}`}>
                                {index === 0 && (
                                    <FormHeader style={{ margin: 0 }}>
                                        <FormTitle>
                                            {formName}
                                            <br />
                                        </FormTitle>
                                        <img src={logoPath} />
                                    </FormHeader>
                                )}

                                <div
                                    style={{
                                        paddingTop: "15px",
                                        display: "block",
                                        position: "relative",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {sheet.map((field) => {
                                        const rawValue = saveState[field.hashCode];

                                        return (
                                            <field.WrapComponent
                                                layout={layout}
                                                key={field.hashCode}
                                                schema={schema}
                                                field={field}
                                                entry={null}
                                                component={
                                                    <field.InputComponent
                                                        layout={layout}
                                                        schema={schema}
                                                        field={field}
                                                        entry={null}
                                                        rawValue={rawValue}
                                                        saveState={saveState}
                                                    />
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    <div className={"rpfe-footnote"} style={{ position: "relative" }}>
                        Die Prüfung wurde nach bestem Wissen und Gewissen gemäß Herstellerangaben
                        einschließlich der gültigen EN und DIN durchgeführt. Für versteckte Mängel,
                        die bei der Anwendung der erforderlichen Sorgfalt nicht zu erkennen sind,
                        wird eine Haftung ausgeschlossen. Weitere Hinweise entnehmen Sie dem
                        Prüfbuch, dem dieses Prüfprotokoll beizufügen ist. Das Prüfbuch ist, sofern
                        nötig, vom Betreiber zu pflegen und aufzubewahren und auf Verlangen
                        bereitzuhalten.
                    </div>
                </div>
            </div>
        </FormW>
    );
};
