import React, { useState } from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { uniqueID } from "../utils";
import { BaseField } from "./BaseField";
import { PercentageIndicator } from "./configurables/fields/AppearanceSlider";

export class SpacerField extends BaseField {
    public name = "";
    public label = "";
    public inputType = "text";
    public designerInputType = "text";
    public hashCode = uniqueID();
    public icon = "arrows-v";
    public fieldType = "Abstand";

    public attributeNames = ["spaceHeight"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        const [percentage, setPercentage] = useState(field.spaceHeight);
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <input
                        className="form-control"
                        defaultValue={field.spaceHeight || 100}
                        type="range"
                        placeholder="Abstand in Pixel"
                        ref={register}
                        min={0}
                        max={500}
                        step={10}
                        onChange={(ev) => {
                            setPercentage(ev.target.value);
                        }}
                        name={field.inputName("spaceHeight")}
                    />
                    <PercentageIndicator>{percentage || 100}px</PercentageIndicator>
                </div>
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({ layout, schema, field, entry = null, register, name = field.name }) {
        return <div style={{ height: field.spaceHeight + "px" }} />;
    }
}
