import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import { CURRENT_USER, SET_USERS } from "./types";

/**
 * Fetch all users
 */
export function fetchUsers() {
    return (dispatch: IDispatch<any>) => {
        axios
            .get(`${API_URL}/users.json`, { headers: { authorization: user().token } })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: SET_USERS,
                });
            });
    };
}

export function setCurrentUser() {
    return (dispatch: IDispatch<any>) => {
        axios.get(API_URL, { headers: { authorization: user().token } }).then((response) => {
            dispatch({
                payload: response.data,
                type: CURRENT_USER,
            });
        });
    };
}
