import {
    FORM_DATA_LOADED,
    FORM_DATA_LOADING_START,
    FORM_DATA_LOADING_STOP,
} from "../actions/types";

const initialState = {
    isLoading: false,
    forms: {},
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case FORM_DATA_LOADING_START:
            return { ...state, isLoading: true };
        case FORM_DATA_LOADING_STOP:
            return { ...state, isLoading: false };

        case FORM_DATA_LOADED:
            return {
                ...state,
                isLoading: false,
                forms: {
                    ...state.forms,
                    [action.payload.form.id]: action.payload.data.form_data,
                },
            };
    }
    return state;
}
