import * as actions from "@rpforms/shared/build/actions/folders";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormCheck, FormLabel, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ResourceForm } from "./ResourceForm";
import { ResourceSelect } from "./ResourceSelect";
import { TemplateSelect } from "./TemplateSelect";

const ModalWrapper = styled(Modal)`
    .modal-dialog {
        max-width: 700px;
    }
`;

export const FolderEdit = ({ editFolder, show, parent, onHide }: any) => {
    const [showTemplate, setShowTemplate] = useState(false);
    const [template] = useState(false);
    const [folder, updateFolder] = useState<any>({
        resource_type: "Folder",
    });

    useEffect(() => {
        if (editFolder) {
            updateFolder(editFolder);
        }
    }, []);

    const dispatch = useDispatch();

    const [resource, setResource] = useState({
        label: "Ordner",
        value: "Folder",
    });

    const setFolderResource = (resource) => {
        updateFolder({
            ...folder,
            ...resource,
        });
    };

    const setFolderTemplate = (template: any) => {
        updateFolder({
            ...folder,
            template_id: template.value,
        });
    };

    const create = () => {
        dispatch(actions.createFolder({ ...folder, parent_id: parent.id }));
        onHide();
    };

    const update = () => {
        dispatch(actions.updateFolder(folder));
        onHide();
    };

    return (
        <ModalWrapper
            size={"lg"}
            show={true}
            onHide={onHide}
            animation={true}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {folder.id ? `${folder.name} bearbeiten` : `${resource.label} anlegen`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <FormLabel>Typ</FormLabel>
                        <ResourceSelect setResource={setResource} current={folder.resource_type} />
                    </Col>
                    <Col>
                        <ResourceForm
                            folder={folder}
                            type={resource.value}
                            onChange={setFolderResource}
                        />
                    </Col>
                </Row>
                {!folder.id && (
                    <div>
                        <hr />
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                                onChange={() => setShowTemplate(!showTemplate)}
                                type="checkbox"
                                label="Template anwenden"
                            />
                        </Form.Group>
                        {showTemplate && <TemplateSelect setTemplate={setFolderTemplate} />}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={folder.id ? update : create}>
                    {folder.id ? "Speichern" : "Anlegen"}
                </Button>
            </Modal.Footer>
        </ModalWrapper>
    );
};
