import { inventoryItemFields, inventoryItemTypes } from "@rpforms/shared";
import { EDIT_INVENTORY_ITEM, NEW_INVENTORY_ITEM } from "@rpforms/shared/build/actions";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { SmallButton, SmallDangerButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import React from "react";
import { Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { COMPANY_ID } from "../../config";
import { ListRow } from "../forms/FormListRow";

const InventoryItemTableRow = ({
    inventoryItem,
    deleteInventoryItem = null,
    type = "repair",
    presentationType = "table-row",
}: any) => {
    const dispatch = useDispatch();

    const confirmAction = (cb) => {
        if (confirm("Sind Sie sich sicher dieses Objekt zu löschen?")) {
            cb();
        }
    };

    const editInventoryItem = () => {
        dispatch(
            openEditor({
                endpoint: `inventory_items`,
                endpointCompany: false,
                new: !inventoryItem.id,
                preload: {
                    sales_price: 0,
                    buying_price: 0,
                    manufacturer: inventoryItem.manufacturer || {},
                    category: inventoryItem.category || {},
                    company_id: COMPANY_ID,
                    ...inventoryItem,
                },
                type: !inventoryItem.id ? NEW_INVENTORY_ITEM : EDIT_INVENTORY_ITEM,
            })
        );
    };

    const tr = (
        <tr key={`inbentory-item-row${inventoryItem.id}`}>
            {inventoryItemFields(type).map((fieldHash) => {
                const field = fieldHash.attribute_name;
                if (field === "name") {
                    return (
                        <td key={"attr-" + field} className={fieldHash.className}>
                            <strong>{inventoryItem[field]}</strong>
                        </td>
                    );
                } else if (["manufacturer", "category"].includes(field)) {
                    return (
                        <td key={"attr-" + field} className={fieldHash.className}>
                            {inventoryItem[field + "_id"] && inventoryItem[field + "_name"]}
                            {!inventoryItem[field + "_id"] && <>–</>}
                        </td>
                    );
                } else {
                    return (
                        <td key={"attr-" + field} className={fieldHash.className}>
                            {inventoryItem[field]}
                        </td>
                    );
                }
            })}
            <td>
                <RightAligned>
                    {presentationType === "list-row" && (
                        <Badge
                            className="mx-1"
                            style={{
                                backgroundColor: "#777",
                                color: "#ffffff",
                            }}
                        >
                            {inventoryItemTypes()[inventoryItem.inventory_type]}
                        </Badge>
                    )}
                    <SmallButton onClick={editInventoryItem}>Bearbeiten</SmallButton>
                    {deleteInventoryItem !== null && (
                        <SmallDangerButton
                            onClick={() => confirmAction(() => deleteInventoryItem(inventoryItem))}
                        >
                            <i className={"fa fa-trash"}></i>
                        </SmallDangerButton>
                    )}
                </RightAligned>
            </td>
        </tr>
    );

    const r = () => {
        if (presentationType === "table-row") {
            return tr;
        } else if (presentationType === "list-row") {
            return (
                <ListRow>
                    <table>{tr}</table>
                </ListRow>
            );
        }
    };

    return r();
};

export default withRouter(InventoryItemTableRow);
