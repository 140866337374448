import React from "react";
import { withRouter } from "react-router-dom";
import NewForm from "../../components/forms/NewForm";
import FormsPage from "./FormsPage";

export const NewFormPage = () => {
    return (
        <div className="card">
            <div className="card-header">
                <div className="m-0 card-title">Neues Formular anlegen</div>
            </div>
            <div className="card-body">
                <NewForm />
            </div>
        </div>
    );
};
export default withRouter(NewFormPage);
