import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getFolderLocation, getRevenues } from "@rpforms/shared";
import { RootState } from "../../reducers";
import { Pagination } from "react-bootstrap";
import { FinancesType } from "../../pages/finances/FinancesPage";
import { push } from "connected-react-router";

interface ContainerProps {
    width?: "half";
}

const ListContainer = styled.div<ContainerProps>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const ListItem = styled.div`
    width: 49%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f1f1f1;
    margin-top: 5px;
    max-height: 250px;
    padding: 0.5rem 1rem;

    .left {
        display: flex;
    }

    &:nth-child(2n) {
        background-color: #dfdfdf;
    }

    button {
        border: none;
        background-color: transparent;
    }
`;

const FormEntry = styled.div`
    display: flex;
    align-items: center;
`;

export default function ListOffers({ maxDate }: { maxDate: number }) {
    const {
        form_entries,
        total,
        page,
        customer_id,
        entity_id,
        selectedYear,
        offerType,
    } = useSelector<RootState, any>((state) => state.finances);
    const [generatedPages, setGeneratedPages] = useState(generatePages(total));

    const dispatch = useDispatch();

    useEffect(() => {
        setGeneratedPages(generatePages(total));
    }, [total]);

    const clickHandler = async (id) => {
        const req = await dispatch(
            getFolderLocation({
                resource_id: id,
                resource_type: "FormEntry",
            })
        );
        dispatch(push("/folders?open=" + encodeURIComponent(JSON.stringify(req.data))));
    };

    const handlePaginationClick = (number) => {
        dispatch(
            getRevenues(
                null,
                customer_id ?? null,
                entity_id ?? null,
                selectedYear ?? new Date().getFullYear(),
                null,
                null,
                FinancesType.OFFER,
                offerType,
                number
            )
        );
    };

    return (
        <>
            <ListContainer>
                {form_entries?.map((entry, index) => (
                    <ListItem key={entry.id}>
                        <div className="left">
                            <FormEntry>{entry.name}</FormEntry>
                        </div>
                        <button onClick={() => clickHandler(entry.id)}>Ansehen</button>
                    </ListItem>
                ))}
            </ListContainer>
            <Pagination>
                {generatedPages.map((p) => {
                    return (
                        <Pagination.Item
                            active={page === p || (p === 1 && page === null)}
                            key={p}
                            onClick={() => handlePaginationClick(p)}
                        >
                            {p}
                        </Pagination.Item>
                    );
                })}
            </Pagination>
        </>
    );
}

function generatePages(count) {
    const pages = [];
    // change in FinanceController.rb as well
    for (let i = 1; i <= Math.ceil(count / 30); i++) {
        pages.push(i);
    }
    return pages;
}
