import React from "react";

export const ServicePositionTableFoot = ({ data }) => {
    return (
        <tfoot>
            <tr>
                <td colSpan={3}>
                    <small>
                        {data.total} {data.total === 1 ? "Service" : "Services"}
                    </small>
                </td>
            </tr>
        </tfoot>
    );
};

export default ServicePositionTableFoot;
