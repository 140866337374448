import { EDITOR_CLOSE, EDITOR_OPEN, EDITOR_SAVING } from "../actions/types";

const initialState: IEditor = {
    config: null,
    open: false,
    saving: false
};

export default function(state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case EDITOR_SAVING:
            return { ...state, saving: action.payload };
        case EDITOR_OPEN:
            return { ...state, open: true, config: action.payload };
        case EDITOR_CLOSE:
            return { ...state, open: false, saving: false, config: null };
    }

    return state;
}
