import {
    ADD_CALENDAR_EVENT,
    ADD_DETAILED_EVENT,
    CALENDAR_EVENTS_LOADING_START,
    CALENDAR_EVENTS_LOADING_STOP,
    CREATE_CALENDAR_EVENT,
    DELETE_CALENDAR_EVENT,
    DELETE_DEVICE_TASK,
    GET_CALENDAR_EVENTS,
    GET_DETAILED_EVENTS,
    NEW_DEVICE_TASK,
    NEW_DEVICE_TASKS,
    SET_CALENDAR_EVENTS,
    SET_DEVICE_TASKS,
    UPDATE_CALENDAR_EVENT,
} from "./../actions/types/index";

const initialState = {
    isLoading: true,
    calendarEvents: [],
    tasks: [],
    detailedEvents: [],
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case CREATE_CALENDAR_EVENT:
            if (
                state.calendarEvents.find((event: ICalendarEvent) => event.id === action.payload.id)
            ) {
                return { ...state };
            }
            return {
                ...state,
                calendarEvents: [...state.calendarEvents, action.payload],
            };
        case ADD_CALENDAR_EVENT:
            if (
                state.calendarEvents.find((event: ICalendarEvent) => event.id === action.payload.id)
            ) {
                return { ...state };
            }
            return {
                ...state,
                calendarEvents: [...state.calendarEvents, action.payload],
            };
        case SET_CALENDAR_EVENTS:
            return { ...state, calendarEvents: action.payload };
        case DELETE_CALENDAR_EVENT:
            return {
                ...state,
                calendarEvents: state.calendarEvents.filter(
                    (event: ICalendarEvent) => event.id !== action.payload.id
                ),
            };
        case DELETE_DEVICE_TASK:
            return {
                ...state,
                tasks: state.tasks.filter((task) => task.id !== action.payload.id),
            };
        case GET_CALENDAR_EVENTS:
            const events = action.payload;
            state.calendarEvents.forEach((event) => {
                if (events.find((ev) => ev.id === event.id)) {
                    return;
                }

                events.push(event);
            });

            return {
                ...state,
                calendarEvents: events,
            };
        case CALENDAR_EVENTS_LOADING_START:
            return { ...state, isLoading: true };
        case CALENDAR_EVENTS_LOADING_STOP:
            return { ...state, isLoading: false };
        case NEW_DEVICE_TASK:
            return {
                ...state,
                tasks: [...state.tasks, action.payload],
            };
        case NEW_DEVICE_TASKS:
            return {
                ...state,
                tasks: [...state.tasks, ...action.payload],
            };
        case SET_DEVICE_TASKS:
            return { ...state, tasks: action.payload };
        case UPDATE_CALENDAR_EVENT:
            const calendarEvents = state.calendarEvents.map((event: any) => {
                if (event.id === action.payload.id) {
                    return action.payload;
                }
                return event;
            });
            return { ...state, calendarEvents };
        case ADD_DETAILED_EVENT:
            if (
                state.detailedEvents.find((event: ICalendarEvent) => event.id === action.payload.id)
            )
                return { ...state };
            return {
                ...state,
                detailedEvents: [...state.detailedEvents, action.payload],
            };
        case GET_DETAILED_EVENTS:
            return { ...state, detailedEvents: action.payload };
    }

    return state;
}
