import { SET_USERS } from "../actions/types";

const initialState = {
    users: []
};

export default function(state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case SET_USERS:
            return { ...state, users: action.payload };
    }

    return state;
}
