import {
    SEARCH_LOADING_START,
    SEARCH_LOADING_STOP,
    SET_SEARCH_QUERY,
    SET_SEARCH_RESULTS,
} from "../actions/types";

const initialState = {
    query: "",
    results: {
        customers: [],
        entities: [],
        form_entries: [],
        inventory_items: [],
        timestamp: 0,
    },
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return {
                ...state,
                query: action.payload,
            };
        case SET_SEARCH_RESULTS:
            if (!state.results.timestamp || action.payload.timestamp > state.results.timestamp) {
                return {
                    ...state,
                    results: action.payload,
                };
            }
            return state;
        case SEARCH_LOADING_START:
            return {
                ...state,
                isLoading: true,
            };
        case SEARCH_LOADING_STOP:
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
}
