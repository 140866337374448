import React from "react";

/**
 * Presents a schemaBuilder in a way that users can submit entries
 * This does not store any submitted forms as they are processed by an external URL
 * By not handling the form submission (other than basic validation) we ensure
 * that powerform can be embedded into any existing webpage and even
 * replace forms with it without fiddling with the backend.
 *
 * @param schema {PowerFormSchema} The schemaBuilder you want to present to the user
 * @constructor
 */
export const PDFPresenter = ({ form, presenter, entry, schema }) => {
    return <></>;
};
