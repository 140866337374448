import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

export const NoticeTextarea = styled.textarea`
    margin: 5px;
    min-height: 100px;

    &::placeholder {
        color: #dee2e6 !important;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #dee2e6 !important;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #dee2e6 !important;
    }
`;

const AutoTextArea = (props: any) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState("");
    const [textAreaHeight, setTextAreaHeight] = useState("auto");
    const [parentHeight, setParentHeight] = useState("auto");

    useEffect(() => {
        if (props.value != null) {
            setText(props.value.toString());
        }
    }, []);

    useEffect(() => {
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        setTextAreaHeight(`${textAreaRef.current!.scrollHeight}px`);
    }, [text]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaHeight("auto");
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        setText(event.target.value);

        if (props.onChange) {
            props.onChange(event);
        }
    };

    const selectAddr = (addr) => {
        setText(addr.name);
        textAreaRef.current.value = addr.name;
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div
            style={{
                minHeight: parentHeight,
            }}
        >
            <NoticeTextarea
                {...props}
                ref={textAreaRef}
                rows={1}
                style={{
                    height: textAreaHeight,
                }}
                onChange={onChangeHandler}
            />
            {!props.readOnly &&
            props.showBillingAddressSelector &&
            props.showBillingAddresses.length ? (
                <div>
                    Weitere Adressen:
                    <br />
                    {props.showBillingAddresses.map((addr) => (
                        <div style={{ marginBottom: "10px" }} key={addr.id}>
                            <Button size={"sm"} onClick={() => selectAddr(addr)}>
                                {addr.compact_name}
                            </Button>
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default AutoTextArea;
