import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import {
    CATEGORIES_LOADING_START,
    CATEGORIES_LOADING_STOP,
    CREATE_CATEGORY,
    GET_CATEGORIES,
} from "./types";

export function getCategories(page = 1, pageSize = -1) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        dispatch({ type: CATEGORIES_LOADING_START });
        axios
            .get(
                `${API_URL}/categories.json?order_by=name&order_dir=ASC&page=${page}&page_size=${pageSize}`,
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: GET_CATEGORIES,
                    payload: response.data,
                });
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                dispatch({ type: CATEGORIES_LOADING_STOP });
            });
    };
}

export function createCategoryAJAX(category: any) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        dispatch({ type: CATEGORIES_LOADING_START });
        try {
            const res = await axios.post(
                `${API_URL}/categories.json`,
                {
                    category: { ...category },
                },
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: CREATE_CATEGORY,
                payload: res.data,
            });
            dispatch({ type: CATEGORIES_LOADING_STOP });
            return res.data;
        } catch (err) {
            throw err;
        }
    };
}
