import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class DateTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Datum";
    public icon = "calendar";
    public fieldType = "Datum";

    public getConfigurables() {
        return [Width];
    }

    public toHotColumn(): {} {
        return {
            type: "date",
        };
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label}
                    ref={register}
                />
            </FieldWrapper>
        );
    }
}
