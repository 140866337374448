import React from "react";
import { BaseField } from "../BaseField";
import { Configurable } from "./Configurable";

/**
 * Applies maximum length to input field
 */
export class HashCode extends Configurable {
    public schema;
    public designer;
    public field;

    public keyName = "name";
    public title = "Name (Identifier)";
    public description =
        "Setzen Sie hier einen einfach verständlichen Namen um dieses Feld später automatisch auszufüllen und/oder es mit anderen Feldern aus anderen Formularen zu verknüpfen";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: BaseField;
    }): any {
        return <></>;
    }

    public sleepValue(value): any {
        return value;
    }

    public wakeValue(value) {
        return value;
    }
}
