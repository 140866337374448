import { shade } from "@rpforms/shared/build/utils";
import React from "react";
import styled, { css } from "styled-components";

const TheIcon = () => (
    <svg height="64" viewBox="0 0 64 64" width="64" version="1.1">
        <path
            className="f1"
            d="m 57,53.5011 0,-26.989543 c 0,-1.384444 -1.1235,-2.651935 -2.5,-2.498995 l -20,0 -4.5,4.09e-4 -20.5,0 C 7,24.012971 7,26.40791 7,26.40791 L 7,53.501 C 7,54.88545 8.115,56 9.5,56 l 45,0 c 1.385,0 2.5,-1.11455 2.5,-2.499 z"
            style={{ opacity: 0.2, fill: "#000000", fillOpacity: 1 }}
        />
        <path
            className="f2"
            d="M 7,47.50009 7,12.512516 C 7,11.128033 8.1235,9.847102 9.5,10 L 26,10 c 3,0 3,1.509999 3,5 l 25.628,0 c 0,0 2.3718,0.01159 2.3718,2.510755 l 0,29.989635 c 0,1.38448 -1.115,2.49917 -2.5,2.49917 l -44.999,0 c -1.385,0 -2.5,-1.11459 -2.5,-2.49917 z"
        />
        <path
            className="f3"
            d="m 57,51.5011 0,-26.989543 c 0,-1.384444 -1.1235,-2.651935 -2.5,-2.498995 l -20,0 -4.5,4.09e-4 -20.5,0 C 7,22.012971 7,24.40791 7,24.40791 L 7,51.501 C 7,52.88545 8.115,54 9.5,54 l 45,0 c 1.385,0 2.5,-1.11455 2.5,-2.499 z"
            style={{ opacity: 0.2, fill: "#000", fillOpacity: 1 }}
        />
        <rect
            className="f4"
            rx="2.444"
            ry="2.5"
            y="18"
            x="10"
            height="20"
            width="44"
            style={{ opacity: 1, fill: "#dcdcdc", fillOpacity: 1 }}
        />
        <path
            className="f5"
            style={{ fill: "rgb(0, 188, 212)", fillOpacity: 1 }}
            d="m 57,52.501 0,-26.989543 c 0,-1.384444 -1.1235,-2.651935 -2.5,-2.498995 l -20,0 -4.5,4.09e-4 -20.5,0 C 7,23.012871 7,25.40781 7,25.40781 L 7,52.5009 c 0,1.38445 1.115,2.499 2.5,2.499 l 45,0 c 1.385,0 2.5,-1.11455 2.5,-2.499 z"
        />
        <path
            className="f6"
            style={{ opacity: 0.1, fill: "#ffffff", fillOpacity: 1 }}
            d="M 9.5,10 C 8.1235,9.847102 7,11.127236 7,12.511719 l 0,1.001953 C 7,12.129189 8.1235,10.847102 9.5,11 L 26,11 c 3,0 3,1.509999 3,5 l 25.628906,0 C 54.628906,16 57,16.01255 57,18.511719 l 0,-1 C 57,15.012554 54.628906,15 54.628906,15 L 29,15 c 0,-3.490001 0,-5 -3,-5 L 9.5,10 Z"
        />
    </svg>
);

export const FolderIcon = styled(TheIcon)`
    svg {
        width: ${(props) => props.width || "64px"};
        height: ${(props) => props.height || props.width || "64px"};
    }

    .homeIcon {
        display: none;
    }

    .printerIcon {
        display: none;
    }

    .userIcon {
      display: none,
    }

    .taskIcon {
      display: none;
    }

    .f2 {
        // back
        fill: ${(props) => shade(props.theme.primary_color, -15)} !important;
    }
    .f5 {
        // front
        fill: ${(props) => props.theme.primary_color} !important;
    }

    ${(props) =>
        props.home &&
        css`
            .homeIcon {
                display: block;
            }
        `}

    ${(props) =>
        props.document &&
        css`
            .printerIcon {
                display: block;
            }
        `}

    ${(props) =>
        props.user &&
        css`
            .userIcon {
                display: block;
            }
        `}

     ${(props) =>
         props.task &&
         css`
             .taskIcon {
                 display: block;
             }
         `}
`;
