import React from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, MaxLength, Required, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";

export class SeparatorField extends BaseField {
    public icon = "minus";
    public fieldType = "Trennlinie";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Width];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper
                register={register}
                field={field}
                schema={schema}
                designer={designer}
            ></FieldWrapper>
        );
    }

    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
    }) {
        return (
            <>
                <hr
                    style={{
                        backgroundColor: "#5FC1D1",
                        width: (field.rawOptions.width || 100) + "%",
                    }}
                />
            </>
        );
    }

    public PresenterComponent({ field }: { field: any }): any {
        return <></>;
    }
}
