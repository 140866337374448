import React from "react";
import Signin from "../../components/auth/SignIn";

export const LoginPage = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-8 m-auto pt-5">
                    <div className="card">
                        <div className="card-body">
                            Bitte melden Sie sich an.
                            <Signin />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
