import React from "react";

/**
 * A small layout engine which allows you to wrap and modify the HTML components
 * before they hit the DOM. Implementing this layout allows for high flexibility while maintaining a slim and compact
 * codebase.
 */
export class PlainLayout {
    /**
     * Wraps a label
     * @param label
     */
    public static wrapLabel(label: string) {
        return <>{label}</>;
    }

    public static wrapDescription(description: string) {
        return <div className="rpfe-description">{description}</div>;
    }

    public static wrapHint(hint: string) {
        return <div className="rpfe-hint">{hint}</div>;
    }

    /**
     * Wraps an input element
     * @param input
     */
    public static wrapInput(input: string) {
        return <>{input}</>;
    }

    /**
     * Wraps the whole form component
     * @param component
     */
    public static wrapComponent(component: any) {
        return component;
    }

    /**
     * Wraps a row element
     * @param component
     */
    public static wrapRow(component: any) {
        return component;
    }

    /**
     * Adds specific css classes to a box
     */
    public static wrapComponentCSS(css = "") {
        css = css.replace(/powerform-cols/, this.columnClass());
        return css;
    }

    /**
     * Wraps a group of multiple fields
     * @param fieldset
     */
    public static wrapFieldset(fieldset: any) {
        return fieldset;
    }

    /**
     * Outer component wrapper, currently unused.
     * @param field
     */
    public static wrapField(field: any) {
        return <>{field}</>;
    }

    /**
     * Implement logic for CSS rows
     * @param row
     */
    public static rowClass(row: any) {
        return "";
    }

    /**
     * Implement logic for CSS columns
     * @param column
     */
    public static columnClass() {
        return "powerform-col";
    }
}
