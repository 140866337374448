import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { EditFormGroupPage } from "./EditFormGroupPage";
import { ListFormGroupsPage } from "./ListFormGroupsPage";

const StateMachineSettingsPage = () => {
    return (
        <Switch>
            <Route
                path="/settings/protocols/state_machines/"
                exact
                component={ListFormGroupsPage}
            />
            <Route
                path="/settings/protocols/state_machines/:id"
                exact
                component={EditFormGroupPage}
            />
        </Switch>
    );
};

export default withRouter(StateMachineSettingsPage);
