import {
    FINANCES_CHANGE_INVOICE_TYPE,
    FINANCES_CUSTOMER_CHANGED,
    FINANCES_ENTITY_CHANGED,
    FINANCES_RESET_STATE,
    FINANCES_SET_DATE,
    FINANCES_CHANGE_NET,
    GET_PAID_REVENUE,
    GET_REVENUE,
    GET_TOTAL_PAID_REVENUE,
    GET_TOTAL_REVENUE,
    REVENUE_LOADING_START,
    REVENUE_LOADING_STOP,
} from "../actions/types";

const initialState = {
    isLoading: false,
    isTotalLoading: false,
    form_entries: [],
    total: 0,
    totalSalesPriceFormatted: "",
    totalPaidSalesPriceFormatted: "",
    customer_id: null,
    customer: null,
    entity_id: null,
    entity: null,
    customerRevenueFormatted: 0,
    isCustomerPaidRevenueLoading: false,
    customerPaidRevenueFormatted: 0,
    invoiceType: 0,
    offerType: "",
    selectedYear: "",
    selectedMonth: "",
    selectedDay: "",
    zipUrl: null,
    page: 1,
    isNet: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case "START_TOTAL_REVENUE_LOADING":
            return {
                ...state,
                isTotalLoading: true,
            };
        case "STOP_TOTAL_REVENUE_LOADING":
            return {
                ...state,
                isTotalLoading: false,
            };
        case GET_TOTAL_REVENUE:
        case GET_TOTAL_PAID_REVENUE:
        case GET_REVENUE:
            return {
                ...state,
                form_entries: action.payload.form_entries,
                form_entry_ids: action.payload.form_entry_ids,
                disposal_form_entry_ids: action.payload.disposal_form_entry_ids,
                repair_form_entry_ids: action.payload.repair_form_entry_ids,
                service_form_entry_ids: action.payload.service_form_entry_ids,

                total: action.payload.total,
                repair_count: action.payload.repair_count,
                disposal_count: action.payload.disposal_count,
                service_count: action.payload.service_count,
                voucher_count: action.payload.voucher_count,

                totalPaidSalesPriceFormatted: action.payload.paid.total_sales_price_formatted,
                totalNetPaidSalesPriceFormatted:
                    action.payload.paid.total_net_sales_price_formatted,
                repairPaidSalesPriceFormatted: action.payload.paid.repair_sales_price_formatted,
                repairNetPaidSalesPriceFormatted:
                    action.payload.paid.repair_net_sales_price_formatted,
                disposalPaidSalesPriceFormatted: action.payload.paid.disposal_sales_price_formatted,
                disposalNetPaidSalesPriceFormatted:
                    action.payload.paid.disposal_net_sales_price_formatted,
                servicePaidSalesPriceFormatted: action.payload.paid.service_sales_price_formatted,
                serviceNetPaidSalesPriceFormatted:
                    action.payload.paid.service_net_sales_price_formatted,

                voucherPaidSalesPriceFormatted: action.payload.paid.voucher_sales_price_formatted,
                voucherNetPaidSalesPriceFormatted:
                    action.payload.paid.voucher_net_sales_price_formatted,

                totalSalesPriceFormatted: action.payload.total_sales_price_formatted,
                totalNetSalesPriceFormatted: action.payload.total_net_sales_price_formatted,
                voucherSalesPriceFormatted: action.payload.voucher_sales_price_formatted,
                voucherNetSalesPriceFormatted: action.payload.voucher_net_sales_price_formatted,
                repairSalesPriceFormatted: action.payload.repair_sales_price_formatted,
                repairNetSalesPriceFormatted: action.payload.repair_net_sales_price_formatted,
                disposalSalesPriceFormatted: action.payload.disposal_sales_price_formatted,
                disposalNetSalesPriceFormatted: action.payload.disposal_net_sales_price_formatted,
                serviceSalesPriceFormatted: action.payload.service_sales_price_formatted,
                serviceNetSalesPriceFormatted: action.payload.service_net_sales_price_formatted,
                customerRevenueFormatted: action.payload?.total_sales_price_formatted,

                page: action.payload.page,
            };
        case REVENUE_LOADING_START:
            return { ...state, isLoading: true };
        case REVENUE_LOADING_STOP:
            return { ...state, isLoading: false };
        case GET_PAID_REVENUE:
            return {
                ...state,
                voucherSalesPriceFormatted: action.payload.voucher_sales_price_formatted,
                voucherNetSalesPriceFormatted: action.payload.voucher_net_sales_price_formatted,
                totalPaidSalesPriceFormatted: action.payload.paid.total_sales_price_formatted,
                repairPaidSalesPriceFormatted: action.payload.paid.repair_sales_price_formatted,
                disposalPaidSalesPriceFormatted: action.payload.paid.disposal_sales_price_formatted,
                servicePaidSalesPriceFormatted: action.payload.paid.service_sales_price_formatted,
                customerPaidRevenueFormatted: action.payload?.paid.total_sales_price_formatted,
            };
        case FINANCES_CUSTOMER_CHANGED:
            return {
                ...state,
                customer_id: action.payload.customer_id,
                customer: action.payload.customer,
            };
        case FINANCES_ENTITY_CHANGED:
            return {
                ...state,
                entity_id: action.payload.entity_id,
                entity: action.payload.entity,
            };
        case FINANCES_CHANGE_INVOICE_TYPE:
            return {
                ...state,
                invoiceType: action.payload.buttonId,
                offerType: action.payload.offerType,
            };
        case FINANCES_RESET_STATE:
            return initialState;
        case FINANCES_SET_DATE:
            return {
                ...state,
                selectedYear: action.payload.year,
                selectedMonth: action.payload.month,
                selectedDay: action.payload.day,
            };
        case FINANCES_CHANGE_NET:
            return {
                ...state,
                isNet: action.payload,
            };
        default:
            return state;
    }
}
