import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import { FORM_GROUP_ADD, FORM_GROUPS_SET } from "./types";

export const addFormGroups = (payload) => {
    return {
        type: FORM_GROUPS_SET,
        payload,
    };
};

export const addFormGroup = (payload) => {
    return {
        type: FORM_GROUP_ADD,
        payload,
    };
};

export const fetchFormGroups = () => {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .get(`${API_URL}/form_groups.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch(addFormGroups(response.data));
            })
            .catch((err) => {
                throw err;
            });
};

export const fetchFormGroup = (id: number) => {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .get(`${API_URL}/form_groups/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch(addFormGroup(response.data));
            });
};

export function updateFormGroup(formGroup: any) {
    return (dispatch: IDispatch<any>) => {
        axios
            .put(
                `${API_URL}/form_groups/${formGroup.id}.json`,
                {
                    form_group: {
                        ...formGroup,
                    },
                },
                { headers: { authorization: user().token } }
            )
            .then((res) => {
                /*dispatch({
                type: CREATE_INVENTORY_ITEM,
                payload: res.data,
            });*/
                fetchFormGroups()(dispatch);
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                // dispatch({ type: STOP_INVENTORY_ITEM_LOADING });
            });
    };
}
