import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Title = styled.h3`
    text-transform: uppercase;
    font-family: "Merriweather", serif;
`;
const Description = styled.h3`
    font-size: 18px;
    margin: 10px 0 50px;
`;

const EmptySpaceWrapper = styled.div`
    padding: 80px;
    border-radius: 10px;
    border: 2px dashed #ddd;
    text-align: center;
    color: #aaa;
`;

export const EmptySpace = ({ link, title, cta, description = "" }) => {
    return (
        <EmptySpaceWrapper>
            <Title>{title}</Title>
            <Description>{description}</Description>
            {cta && (
                <Link to={link}>
                    <button className="btn btn-primary btn-lg">{cta}</button>
                </Link>
            )}
        </EmptySpaceWrapper>
    );
};
