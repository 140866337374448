import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { UploadForm } from "./UploadForm";
import { UploadModal } from "./UploadModal";
import { UploadsList } from "./UploadsList";

export const UploadField = ({
    uploads = [],
    namePattern,
    subfields,
    setUploads,
    count = false,
    autoSave = false,
    modal = true,
    showImages = true,
    variables = {},
    hashCodeRef,
    readonly = false,
}: any) => {
    const [showUploader, setShowUploader] = useState(false);
    const [editUpload, setEditUpload] = useState(false);

    useEffect(() => {
        setUploads(uploads);
    }, [uploads]);

    const onDeleteUpload = async (upload: any) => {
        const newUploads = uploads.filter((u: any) => u.uuid !== upload.uuid);
        setUploads(newUploads);
    };

    const handleClose = () => setShowUploader(false);
    const handleOpen = () => {
        setEditUpload(false);
        setShowUploader(true);
    };

    const onEditUpload = (upload) => {
        setShowUploader(true);
        setEditUpload(upload);
    };

    const onUpload = (upload: any) => {
        // Replace existing.
        if (uploads.find((u) => u.uuid === upload.uuid)) {
            setUploads([...uploads.filter((u) => u.uuid !== upload.uuid), upload]);
            handleClose();
            return;
        }

        setUploads([...uploads, upload]);
        handleClose();
    };

    return (
        <div>
            <div className="hidden-print">
                {modal && <Button onClick={() => handleOpen()}>Bild hinzufügen</Button>}
            </div>
            {showImages && uploads && (
                <UploadsList
                    uploads={uploads}
                    onEditUpload={onEditUpload}
                    onDeleteUpload={onDeleteUpload}
                />
            )}

            {uploads && !showImages && <div>{uploads.length} Anhänge</div>}
            {modal && (
                <UploadModal isOpen={showUploader} handleClose={handleClose}>
                    <UploadForm
                        editUpload={editUpload}
                        onUpload={onUpload}
                        namePattern={namePattern}
                        subfields={subfields}
                        variables={variables}
                        uploads={uploads}
                        count={count}
                        hashCodeRef={hashCodeRef}
                    />
                </UploadModal>
            )}

            {!modal && (
                <UploadForm
                    count={count}
                    autoSave={autoSave}
                    onUpload={onUpload}
                    variables={variables}
                    uploads={uploads}
                    namePattern={namePattern}
                    subfields={subfields}
                    hashCodeRef={hashCodeRef}
                />
            )}
        </div>
    );
};
