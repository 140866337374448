// @ts-ignore
import React, { useEffect, useState } from "react";
import { CirclePicker } from "react-color";
import CreatableSelect from "react-select/creatable";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { FieldWrapper } from "../components/FieldWrapper";
import { SmallButton, SmallDangerButton } from "../components/layout/Button";
import { AlignCenterVertically } from "../components/layout/Content";
import { arrayMove, getContrastColor, uniqueID } from "../utils";
import { BaseField } from "./BaseField";
import { DefaultValue, Description, Hint, Required, Width } from "./configurables";
import { Conditional } from "./configurables/Conditional";
import { Label, LabelInput } from "./inputFields/label";

const DragHandle = SortableHandle(() => (
    <div
        style={{
            height: "45px",
            paddingRight: "10px",
        }}
    >
        <i className="fa fa-bars"></i>
    </div>
));
const EditOptionContainer: any = SortableContainer(({ children }) => {
    return <ul>{children}</ul>;
});
const EditOptionItem = SortableElement(({ children }: any) => (
    <AlignCenterVertically>
        <DragHandle />
        {children}
    </AlignCenterVertically>
));
export const EditOptions = ({ defaultValue = [], onChange, parentHashcode }) => {
    const [items, setItems] = useState(defaultValue);

    useEffect(() => {
        onChange(items);
    }, [items]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems((currentItems) => arrayMove(currentItems, oldIndex, newIndex));
    };

    const addItem = () => {
        setItems([
            ...items,
            {
                name: "",
                key: "",
                id: uniqueID("*"),
                color: "",
                isDefault: false,
            },
        ]);
    };

    const removeItem = (toRemove) => {
        setItems((current) => current.filter((item) => item.id !== toRemove.id));
    };

    const setColor = (theField, value) => {
        setItems((current) =>
            current.map((item) => {
                item.color = theField.id === item.id ? value : item.color;
                return item;
            })
        );
    };

    const updateName = (theField, name) => {
        setItems((current) =>
            current.map((item) => {
                item.name = theField.id === item.id ? name : item.name;
                item.id = theField.id === item.id ? `*${parentHashcode}-${name}` : item.id;
                return item;
            })
        );
    };

    return (
        <div className="powerform-w100">
            <label>Auswahlmöglichkeiten</label>
            <br />
            <EditOptionContainer onSortEnd={onSortEnd} useDragHandle>
                {items.map((item, index) => {
                    return (
                        <EditOptionItem key={item.id} index={index}>
                            <div className="row mb-4">
                                <div className="col-6">
                                    <input
                                        onBlur={(ev) => {
                                            updateName(item, (ev.target as any).value);
                                        }}
                                        className="form-control"
                                        type="text"
                                        placeholder={"Titel der Auswahl"}
                                        defaultValue={item.name}
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="d-flex justify-space-between">
                                        <div>
                                            <CirclePicker
                                                circleSize={12}
                                                color={item.color}
                                                onChangeComplete={(color) => {
                                                    setColor(item, color.hex);
                                                }}
                                            />
                                        </div>
                                        <div className={"text-right"} style={{ width: "100%" }}>
                                            <a
                                                onClick={() => removeItem(item)}
                                                className="d-block ml-2 mr-2 text-danger"
                                            >
                                                Löschen
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </EditOptionItem>
                    );
                })}
            </EditOptionContainer>
            <SmallButton onClick={() => addItem()}>Option hinzufügen</SmallButton>
        </div>
    );
};

export class RadioField extends BaseField {
    public label = "Liste";
    public displayMode = "switch";
    public selectables = [];
    public allowMultiple = false;
    public icon = "dot-circle-o";
    public items = [];
    public fieldType = "Switch";
    public attributeNames = ["displayMode", "allowMultiple", "inlineView", "items"];

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [Conditional, Required, Description, DefaultValue, Width, Hint];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        const save = (items) => {
            schema.setFieldOption(field, "items", items);
            field.items = items;
        };

        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <LabelInput register={register} field={field} />
                    </div>
                </div>
                <div className="powerform-flex mt-3">
                    <EditOptions
                        defaultValue={field.items}
                        onChange={save}
                        parentHashcode={field.hashCode}
                    />
                </div>
                <div className="powerform-flex mt-3">
                    <div className="powerform-w100">
                        <label>Anzeigen als</label>

                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "radio"}
                                value="radio"
                            />{" "}
                            Einfache Auswahl{" "}
                            <input
                                style={{
                                    filter: "grayscale(1)",
                                    marginLeft: "40px",
                                }}
                                type="radio"
                                checked={true}
                                readOnly={true}
                            />
                        </div>
                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "checkboxes"}
                                value="checkboxes"
                            />{" "}
                            Checkboxes
                            <input
                                style={{
                                    filter: "grayscale(1)",
                                    marginLeft: "40px",
                                }}
                                type="checkbox"
                                checked={true}
                                readOnly={true}
                            />{" "}
                            <input
                                style={{ filter: "grayscale(1)" }}
                                type="checkbox"
                                checked={false}
                                readOnly={true}
                            />{" "}
                            <input
                                style={{ filter: "grayscale(1)" }}
                                type="checkbox"
                                checked={false}
                                readOnly={true}
                            />
                        </div>
                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "switch"}
                                value="switch"
                            />{" "}
                            Schaltflächen
                            <div
                                style={{
                                    display: "inline-block",
                                    marginLeft: "20px",
                                }}
                            >
                                <button style={{ transform: "scale(0.6)" }}>1</button>
                                <button style={{ transform: "scale(0.6)" }}>2</button>
                                <button style={{ transform: "scale(0.6)" }}>3</button>
                            </div>
                        </div>
                        <div>
                            <input
                                type="radio"
                                ref={register}
                                name={field.inputName("displayMode")}
                                defaultChecked={field.displayMode === "select"}
                                value="select"
                            />{" "}
                            Auswahlliste <select />
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("allowMultiple")}
                            className="form-control"
                            defaultChecked={field.allowMultiple}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>Mehrfachauswahl erlauben</label>
                        <div className="text-muted">
                            Soll der Benutzer die Möglichkeit haben mehrere Elemente auszuwählen?
                            <br />
                            <small className="text-danger">
                                Achtung: Kann die Darstellung des Feldes verändern.
                            </small>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-3 align-items-center">
                    <div style={{ width: "50px" }}>
                        <input
                            type="checkbox"
                            name={field.inputName("inlineView")}
                            className="form-control"
                            defaultChecked={field.inlineView}
                            ref={register}
                        />
                    </div>
                    <div style={{ width: "100%" }}>
                        <label>In einer Zeile darstellen</label>
                        <div className="text-muted">
                            Sollen die Optionen in einer Zeile dargestellt werden?
                            <br />
                            <small className="text-danger">
                                Achtung: Funktioniert nur bei <strong>Einfacher Auswahl</strong> und{" "}
                                <strong>Checkboxes</strong>.
                            </small>
                        </div>
                    </div>
                </div>
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name = field.hashCode,
        rawValue,
        saveState = {},
        readonly = false,
    }) {
        const [showButton, setShowButton] = useState(
            field.displayMode === "switch" &&
                (rawValue || field.rawValue) &&
                typeof rawValue === "string"
        );

        if (field.displayMode === "select" && field.allowMultiple) {
            if (typeof rawValue === "string") {
                try {
                    rawValue = JSON.parse(rawValue);
                    if (!Array.isArray(rawValue) || !rawValue.length || !rawValue[0].label) {
                        rawValue = null;
                    }
                } catch (e) {}
            } else if (!Array.isArray(rawValue)) {
                rawValue = [];
            }
        }

        if (field.displayMode === "checkboxes") {
            rawValue = [];
            for (const key in saveState) {
                if (saveState.hasOwnProperty(key) && key.indexOf(field.hashCode) === 0) {
                    rawValue.push(saveState[key]);
                }
            }
        }

        const [value, setValue] = useState(rawValue);
        const [selectOpts, setSelectOpts] = useState(rawValue);
        const isRV = (item) =>
            item.id === value || (Array.isArray(value) && value.includes(item.id));

        if (!field.items) {
            field.items = [];
        }

        const isPDFMode = !(window as any).EDIT_MODE;
        const activeItem = field.items.find(isRV);

        const itemLabel = activeItem ? activeItem.name : "";

        let inputElement: any = <>{field.displayMode}</>;
        const switchHiddenInput = (
            <input
                type={"hidden"}
                value={value}
                data-label={itemLabel}
                data-override-type={"radio"}
                name={name}
                data-name={readonly ? "" : name}
                className={"clear-radio"}
            />
        );

        switch (field.displayMode) {
            case "radio":
                inputElement = (
                    <div>
                        {layout.wrapDescription(
                            field.rawOptions.description ? (
                                <p>{field.rawOptions.description}</p>
                            ) : (
                                <></>
                            )
                        )}
                        {field.items.map((item) => {
                            return (
                                <div key={item.id} className="form-check">
                                    {field.configureInput(
                                        <input
                                            defaultChecked={isRV(item)}
                                            className="form-check-input"
                                            type="radio"
                                            defaultValue={item.id}
                                            id={item.id + "_" + name}
                                            name={name}
                                            data-name={readonly ? "" : name}
                                            readOnly={readonly}
                                        />
                                    )}
                                    <label
                                        className="form-check-label"
                                        htmlFor={item.id + "_" + name}
                                    >
                                        {item.name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                );
                break;
            case "checkboxes":
                const uniqid = uniqueID();
                inputElement = (
                    <div>
                        {layout.wrapDescription(
                            field.rawOptions.description ? (
                                <p>{field.rawOptions.description}</p>
                            ) : (
                                <></>
                            )
                        )}
                        {field.items.map((item, index) => {
                            return (
                                <div key={item.id} className="form-check">
                                    {field.configureInput(
                                        <input
                                            className="switch-check-input form-check-input"
                                            data-label={item.name}
                                            data-key={name}
                                            type="checkbox"
                                            defaultValue={item.id}
                                            id={`${name}_${index}`}
                                            defaultChecked={isRV(item)}
                                            name={`${name}_${index}`}
                                            data-name={readonly ? "" : name}
                                            readOnly={readonly}
                                        />
                                    )}
                                    <label
                                        className="form-check-label"
                                        htmlFor={`${name}_${index}`}
                                    >
                                        {item.name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                );
                break;
            case "select":
                // TODO: refactor.
                const selectColors = rawValue
                    ? {
                          backgroundColor: field.items.find(isRV)
                              ? field.items.find(isRV).color || "#f1f1f1"
                              : field.items[0] && field.items[0].color
                              ? field.items[0].color
                              : "#f1f1f1",
                          color: field.items.find(isRV)
                              ? field.items[0] && field.items[0].color
                                  ? "#fff"
                                  : "#000"
                              : getContrastColor(field.items[0].color || "#ffffff"),
                      }
                    : {
                          backgroundColor:
                              field.items.length && field.items[0].color
                                  ? field.items[0].color
                                  : "#fff",
                          color:
                              field.items[0] && field.items[0].color
                                  ? getContrastColor(field.items[0].color)
                                  : "#000",
                      };

                if (field.allowMultiple) {
                    inputElement = (
                        <div>
                            <input
                                type={"hidden"}
                                value={JSON.stringify(selectOpts)}
                                name={name}
                                data-name={readonly ? "" : name}
                                className={"hidden-field"}
                            />

                            {layout.wrapDescription(
                                field.rawOptions.description ? (
                                    <p>{field.rawOptions.description}</p>
                                ) : (
                                    <></>
                                )
                            )}
                            {field.configureInput(
                                <CreatableSelect
                                    defaultValue={selectOpts}
                                    closeMenuOnSelect={false}
                                    isDisabled={readonly}
                                    isMulti
                                    isClearable
                                    onInputChange={() => {}}
                                    onChange={(v) => {
                                        setSelectOpts(v);
                                    }}
                                    options={field.items.map((item) => {
                                        return {
                                            label: item.name,
                                            value: item.id,
                                        };
                                    })}
                                />
                            )}
                        </div>
                    );
                    if (!(window as any).EDIT_MODE && rawValue && Array.isArray(rawValue)) {
                        try {
                            inputElement = <>{rawValue.map((option) => option.label).join(", ")}</>;
                        } catch (e) {
                            // tslint:disable-next-line:no-console
                            console.error(e);
                        }
                    }

                    if (!(window as any).EDIT_MODE && !rawValue) {
                        inputElement = <>-</>;
                    }

                    if (!(window as any).EDIT_MODE && rawValue && !Array.isArray(rawValue)) {
                        inputElement = <>-</>;
                    }
                } else {
                    const select = (
                        <select
                            disabled={readonly}
                            style={selectColors}
                            className="form-control"
                            onInput={(ev) => {
                                const itemId = (ev.target as any).value;
                                setValue(itemId);
                                (ev.target as any).style.backgroundColor = "#ddd";
                                (ev.target as any).style.color = "#000";
                                field.items.forEach((item) => {
                                    if (item.id === itemId) {
                                        (ev.target as any).style.backgroundColor = item.color;
                                        (ev.target as any).style.color =
                                            (window as any).EDIT_MODE && item.color
                                                ? "#fff"
                                                : "#000";
                                    }
                                });
                            }}
                            value={value}
                            name={name}
                            data-name={readonly ? "" : name}
                            multiple={field.allowMultiple}
                        >
                            {field.items.map((item) => {
                                return (
                                    <option value={item.id} key={item.id}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    );

                    inputElement = (
                        <div>
                            {layout.wrapDescription(
                                field.rawOptions.description ? (
                                    <p>{field.rawOptions.description}</p>
                                ) : (
                                    <></>
                                )
                            )}
                            {field.configureInput(select)}
                        </div>
                    );
                    if (!(window as any).EDIT_MODE && rawValue && Array.isArray(rawValue)) {
                        try {
                            inputElement = <>{rawValue.map((option) => option.label).join(", ")}</>;
                        } catch (e) {
                            // tslint:disable-next-line:no-console
                            console.error(e);
                        }
                    }

                    if (!(window as any).EDIT_MODE && rawValue && !Array.isArray(rawValue)) {
                        try {
                            const item = field.items.find(isRV);
                            if (item) {
                                inputElement = (
                                    <div>
                                        <div style={{ color: item.color || "#000" }}>
                                            {item.name}
                                        </div>
                                        <div style={{ display: "none" }}>{select}</div>
                                    </div>
                                );
                            } else {
                                return <>{field.items ? field.items[0].name : "-"}</>;
                            }
                        } catch (e) {
                            // tslint:disable-next-line:no-console
                            console.error(e);
                        }
                    }
                    if (!(window as any).EDIT_MODE && !rawValue) {
                        inputElement = <>-</>;
                    }
                }
                break;
            case "switch":
                inputElement = (
                    <div>
                        {layout.wrapDescription(
                            field.rawOptions.description ? (
                                <p>{field.rawOptions.description}</p>
                            ) : (
                                <></>
                            )
                        )}

                        {switchHiddenInput}

                        <ul className="nav nav-pills">
                            {field.items.map((item) => {
                                const isActive = isRV(item);
                                return (
                                    <li className="nav-item" key={item.id}>
                                        <a
                                            onClick={(ev) => {
                                                if (!readonly) {
                                                    setValue(item.id);
                                                    setShowButton(true);
                                                }
                                            }}
                                            data-id={item.id}
                                            className={`nav-link`}
                                            style={{
                                                backgroundColor: isActive
                                                    ? item.color || "#5FC1D1"
                                                    : "#ddd",
                                                color:
                                                    (window as any).EDIT_MODE && isActive
                                                        ? "#fff"
                                                        : "#000",
                                            }}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
                if (isPDFMode && rawValue) {
                    try {
                        const activeSelection = field.items.find(isRV) || field.items[0];
                        inputElement = (
                            <div style={{ color: activeSelection.color || "#000" }}>
                                {switchHiddenInput}
                                {activeSelection.name}
                            </div>
                        );
                    } catch (e) {
                        // tslint:disable-next-line:no-console
                        console.error(e);
                    }
                }
                break;
            default:
                inputElement = (
                    <>Ungültiges Anzeigeformat. Das Feld wurde noch nicht korrekt eingestellt.</>
                );
        }

        const resetField = (ev) => {
            setShowButton(false);
            setValue("");

            ev.preventDefault();
            ev.stopPropagation();
            return false;
        };

        return layout.wrapComponent(
            <div className={layout.wrapComponentCSS(field.configureCSS())}>
                {layout.wrapFieldset(
                    <>
                        <div className={"d-flex"}>
                            <Label layout={layout} field={field} />{" "}
                            {(window as any).EDIT_MODE && showButton && !readonly && (
                                <SmallDangerButton
                                    style={{
                                        marginLeft: "15px",
                                        display: "inline-block",
                                    }}
                                    onClick={resetField}
                                >
                                    X
                                </SmallDangerButton>
                            )}
                        </div>
                        {inputElement}

                        {layout.wrapHint(
                            field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>
                        )}
                    </>
                )}
            </div>
        );
    }

    public rawValueToOption(field, optionId) {
        const option = field.items.find((item) => {
            return item.id === optionId;
        });
        if (!option) {
            return "Keine Option ausgewählt.";
        }
        return option.name;
    }
}
