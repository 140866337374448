import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { useDebounce } from "react-use";
import styled from "styled-components";
import { signoutUser } from "../../actions";
import { getContrastColor, translucentColor } from "../../utils";
import { fetchNotificationGroups } from "../../actions/notification_groups";
import { ChatGptTrigger } from "@rpforms/web/src/components/chatgpt/ChatGptTrigger";

const NavbarWrapper = styled.div`
    .navbar {
        display: flex;
        font-weight: bold;
        justify-content: space-between;

        &.navbar-lead {
            background: linear-gradient(
                to bottom,
                ${(props) => translucentColor(props.theme.primary_color || "#00bcd4", 0.8)},
                ${(props) => props.theme.primary_color || "#00bcd4"}
            );

            font-size: 24px;
            font-family: "Merriweather", serif;
            color: ${(props) =>
                getContrastColor(props.theme.primary_color || "#00bcd4")} !important;
        }

        .text-link {
            position: relative;
            top: -10px;
        }
    }
    a {
        transition: all 0.2s ease-in-out;
    }

    a:hover {
        color: #fff;
        transform: scale(1.2);
    }
`;
const SubnavWrapper = styled.nav`
    padding: 20px 40px 0;
    background: ${(props) => props.theme.primary_color || "#00bcd4"} !important;

    ul {
        width: 100%;
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            flex-grow: 1;
            margin: 0;
            color: ${(props) => getContrastColor(props.theme.primary_color || "#00bcd4")};
            text-align: center;

            a {
                display: block;
                color: ${(props) => getContrastColor(props.theme.primary_color || "#00bcd4")};
                border-radius: 10px 10px 0 0;
                padding: 10px 5px 5px;
                text-decoration: none;
                transition: 0.2s ease-in-out;
                transition-property: transform, color, background-color, box-shadow;
                margin: 0 20px;
                font-size: 16px;

                &:hover,
                &.active {
                    transform: scale(1.2);
                }

                &.active,
                &.active-without-shadow {
                    background: #fff;
                    color: ${(props) => props.theme.primary_color || "#00bcd4"};
                    cursor: pointer;
                    box-shadow: 0 35px 8px 8px rgba(0, 0, 0, 0.3);
                    text-decoration: none;
                }
                &.active-without-shadow {
                    box-shadow: none !important;
                }
            }

            i {
                display: block;
                font-size: 20px;
            }

            font-weight: 300;
        }
    }
`;

const SearchInput = styled.div`
    input {
        border-radius: 50px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: inset 1px 1px 8px rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.5);
        transition: all ease-in-out 0.3s;
        padding: 0 10px;
        font-size: 12px;
        height: 40px;
        width: 450px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    input::placeholder {
        color: #888 !important;
    }

    input:focus {
        border: 1px solid #fff;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        outline: none;
        color: #000;
    }
`;

const NotificationSymbol = styled.div`
    position: absolute;
    top: 0px;
    right: -5px;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 150px;
    gap: 20px;
`;

export const Navbar = () => {
    const auth = useSelector<any, any>((state) => state.auth);
    const company = useSelector<any, any>((state) => state.company);
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const notificationGroups = useSelector<any, any>((state) => state.notificationGroups);

    useDebounce(
        () => {
            setSearchTerm(searchQuery);
        },
        300,
        [searchQuery]
    );

    useEffect(() => {
        dispatch((fetchNotificationGroups as any)(1));
    }, []);

    useEffect(() => {
        if (searchTerm) {
            dispatch(push(`/search/${encodeURIComponent(searchTerm)}`));
        }
    }, [dispatch, searchTerm]);

    let currentSearch = useSelector<any, any>((state) => state.search.query);
    try {
        currentSearch = decodeURIComponent(currentSearch);
    } catch (e) {
        return;
    }

    const signout = () => {
        dispatch(signoutUser());
    };

    const search = ({ currentTarget }) => setSearchQuery(currentTarget.value);

    const onSearchSubmit = (ev) => {
        ev.preventDefault();
        return false;
    };

    const certNotifications =
        notificationGroups &&
        notificationGroups.notification_groups.find((group) => group.name === "Zertifizierung");
    const hasNotificationIndicator =
        certNotifications?.notifications && certNotifications.notifications.length > 0;

    return (
        <NavbarWrapper className={"main-nav"}>
            <nav className="navbar navbar-light text-white navbar-lead">
                {company.name}
                <SearchInput>
                    <input
                        defaultValue={currentSearch}
                        onChange={search}
                        placeholder="Suche..."
                        type={"search"}
                    />
                </SearchInput>
                {/*
                Entfernt für scriptfeld.
                <form onSubmit={onSearchSubmit}>*/}
                {/*</form>*/}
                <IconContainer>
                    <NavLink to="/notifications/all" style={{ position: "relative" }}>
                        <i className="fa fa-bell"></i>
                        {hasNotificationIndicator && <NotificationSymbol />}
                    </NavLink>
                    <a href="#" onClick={signout}>
                        <i className="fa fa-sign-out"></i>
                    </a>

                    <NavLink to="/settings/company/identity">
                        <i className="fa fa-cog"></i>
                    </NavLink>
                </IconContainer>
            </nav>
            <SubnavWrapper className="navbar navbar-light bg-primary subnav">
                {auth.authenticated ? (
                    <ul>
                        <li data-engine="ipads">
                            <NavLink to="/folders?ref=nav" activeClassName="active">
                                <i className="fa fa-folder"></i>
                                <span>Struktur</span>
                            </NavLink>
                        </li>
                        <li data-engine="viewer">
                            <NavLink to="/entries" activeClassName="active">
                                <i className="fa fa-table"></i>
                                <span>Protokolle</span>
                            </NavLink>
                        </li>
                        <li data-engine="finances">
                            <NavLink to="/finances" activeClassName="active">
                                <i className="fa fa-dollar"></i>
                                <span>Finanzen</span>
                            </NavLink>
                        </li>
                        <li data-engine="calendar">
                            <NavLink to="/calendar" activeClassName="active">
                                <i className="fa fa-calendar"></i>
                                <span>Terminplaner</span>
                            </NavLink>
                        </li>
                        <li data-engine="master_data">
                            <NavLink to="/master_data" activeClassName="active">
                                <i className="fa fa-database"></i>
                                <span>Stammdaten</span>
                            </NavLink>
                        </li>
                        <li data-engine="inventory_items">
                            <NavLink to="/inventory_items/repair" activeClassName="active">
                                <i className="fa fa-fire-extinguisher"></i>
                                <span>Leistungsverzeichnis</span>
                            </NavLink>
                        </li>
                    </ul>
                ) : (
                    <NavLink
                        className="text-link text-white"
                        to="/auth/login"
                        activeClassName="active"
                    >
                        <i className="fa fa-key pr-1"></i>
                        <span>Anmelden</span>
                    </NavLink>
                )}
            </SubnavWrapper>
        </NavbarWrapper>
    );
};

export default withRouter(Navbar);
