import * as actions from "@rpforms/shared/build/actions/devices";
import { SmallButton, SmallPrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import React from "react";
import { connect } from "react-redux";
import { Link, Route, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ListRow } from "../forms/FormListRow";

export const DeviceName = styled.div`
    font-weight: bold;
`;

const DeviceListRow = ({ device, deleteDevice, history }: any) => {
    const id = device.id;
    return (
        <>
            <ListRow>
                <DeviceName>{device.name}</DeviceName>
                <RightAligned>
                    <Link to={`/master_data/devices/view-${id}/`}>
                        <SmallButton>Ansehen</SmallButton>
                    </Link>
                </RightAligned>
            </ListRow>
        </>
    );
};

function mapStateToProps(state) {
    return { state: state.device };
}

export default withRouter(connect(mapStateToProps, actions)(DeviceListRow));
