import {
    ADD_DEVICE,
    DELETE_DEVICE,
    DEVICES_AUTOCOMPLETE,
    DEVICES_AUTOCOMPLETE_START,
    GET_DEVICES,
    LOADING_START,
    LOADING_STOP,
    NEW_DEVICE_REGISTRATION,
    UPDATE_DEVICE,
} from "../actions/types";

const initialState = {
    devices: [],
    isLoading: false,
    newDevice: {},
    autocomplete: [],
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case DEVICES_AUTOCOMPLETE_START:
            return { ...state, autocompleteIsLoading: true };
        case DEVICES_AUTOCOMPLETE:
            return {
                ...state,
                autocomplete: action.payload,
                autocompleteIsLoading: false,
            };
        case LOADING_START:
            return { ...state, isLoading: true };
        case LOADING_STOP:
            return { ...state, isLoading: false };

        case GET_DEVICES:
            return { ...state, devices: action.payload };
        case DELETE_DEVICE:
            return {
                ...state,
                devices: state.devices.filter((device: any) => {
                    return device.id !== action.payload.id;
                }),
            };

        case NEW_DEVICE_REGISTRATION:
            return { ...state, newDevice: action.payload };

        case ADD_DEVICE:
            return { ...state, devices: [...state.devices, action.payload] };

        case UPDATE_DEVICE:
            const devices = state.devices.map((device: any) => {
                if (device.id === action.payload.id) {
                    return action.payload;
                }

                return device;
            });
            return { ...state, devices };
    }

    return state;
}
