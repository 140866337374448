import { FormHeader } from "@rpforms/web/src/components/FormRenderer";
import React, { useState } from "react";
// tslint:disable-next-line:no-var-requires
import styled from "styled-components";
import { FieldWrapper } from "../components/FieldWrapper";
import { uniqueID } from "../utils";
import { BaseField } from "./BaseField";
import { AppearanceSlider, PercentageIndicator } from "./configurables/fields/AppearanceSlider";

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 170px;
    width: 100%;
    img {
        height: 70px;
        margin-bottom: 30px;
    }

    h2 {
        color: #29338a;
        font-size: 16px;
        font-family: "Times New Roman";
    }

    h3 {
        color: #29338a;
        font-size: 16px;
        font-family: "Times New Roman";
        span {
            font-size: 18px;
        }
    }
`;

export class HeaderField extends BaseField {
    public name = "";
    public label = "";
    public hashCode = uniqueID();
    public icon = "header";
    public fieldType = "Header";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field }) {
        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                Hier lässt sich nichts bearbeiten.
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({ layout, schema, field, entry = null, register, name = field.name }) {
        return (
            <HeaderWrapper>
                <img
                    src={(window as any).LOGO_URL}
                    height={"80px"}
                    style={{ height: "100px !important" }}
                />
            </HeaderWrapper>
        );
    }

    /**
     * Do not wrap element with the usual divs which are required for layout
     */
    public WrapComponent({ layout, entry, schema, field, component }) {
        return <>{component}</>;
    }
}
