import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Col, Dropdown, Modal, Row, Table as BTTable } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CreatableSelect from "react-select/creatable";
import { BootstrapLayout } from "../../components/BootstrapLayout";
import { SmallDangerButton } from "../../components/layout/Button";
import { UploadField } from "../../components/uploadField/UploadField";
import { useFormConfig } from "../../hooks/useFormConfig";
import { stripTags, template, translucentColor } from "../../utils";

const colorStyles = {
    option: (styles, state) => {
        return {
            ...styles,
            ":hover": {
                color: translucentColor(state.data.cell?.color || "#5fc1cd", 0.9),
                backgroundColor: translucentColor(state.data.cell?.color || "#5fc1cd", 0.1),
            },
        };
    },
    multiValue: (styles, state) => {
        return {
            ...styles,
            color: translucentColor(state.data.cell?.color || "#5fc1cd", 0.9),
            backgroundColor: translucentColor(state.data.cell?.color || "#5fc1cd", 0.1),
        };
    },
};
const TableActions: any = ({
    activeRows,
    addRow,
    editRow,
    removeRow,
    addRowAbove,
    addRowBelow,
    copyRows,
    selectAllRows,
    readonly = false,
}) => {
    return (
        <Dropdown as={ButtonGroup}>
            {!readonly && (
                <Button
                    variant={"primary"}
                    style={{ borderColor: "#34aebd", backgroundColor: "#5fc1cd" }}
                    onClick={selectAllRows}
                >
                    Alle Zeilen markieren
                </Button>
            )}

            {activeRows.length <= 1 && !readonly && (
                <Button
                    variant={"primary"}
                    style={{ borderColor: "#34aebd", backgroundColor: "#5fc1cd" }}
                    onClick={addRow}
                >
                    Zeile hinzufügen
                </Button>
            )}

            {activeRows.length === 1 && (
                <>
                    <Dropdown.Toggle
                        split
                        variant={"primary"}
                        style={{
                            marginRight: "10px",
                            borderColor: "#34aebd",
                            backgroundColor: "#5fc1cd",
                        }}
                        id="dropdown-split-basic"
                    />

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={addRowAbove}>
                            Über aktiver Zeile einfügen
                        </Dropdown.Item>
                        <Dropdown.Item onClick={addRowBelow}>
                            Unter aktiver Zeile einfügen
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </>
            )}

            {activeRows.length > 0 && (
                <>
                    <Button
                        style={{
                            borderColor: "#34aebd",
                            backgroundColor: "#5fc1cd",
                        }}
                        onClick={editRow}
                    >
                        {activeRows.length} {activeRows.length === 1 ? "Zeile" : "Zeilen"}{" "}
                        bearbeiten
                    </Button>

                    <Button
                        style={{
                            borderColor: "#34aebd",
                            backgroundColor: "#5fc1cd",
                        }}
                        onClick={copyRows}
                    >
                        {activeRows.length} {activeRows.length === 1 ? "Zeile" : "Zeilen"}{" "}
                        duplizieren
                    </Button>
                </>
            )}
            {activeRows.length > 0 && (
                <Button
                    style={{ borderColor: "#34aebd", backgroundColor: "#5fc1cd" }}
                    onClick={() => {
                        if (
                            !confirm(
                                `Sind Sie sicher dass ${
                                    activeRows.length === 0 ? "Zeile" : "Zeilen"
                                } Zeile entfernt werden soll?`
                            )
                        ) {
                            return;
                        }
                        removeRow();
                    }}
                >
                    {activeRows.length} {activeRows.length === 1 ? "Zeile" : "Zeilen"} löschen
                </Button>
            )}
        </Dropdown>
    );
};

const TableColumnValue = ({ column, index }) => {
    if (!column) {
        return <></>;
    }

    if (column.type === "imageUpload") {
        const uploads = (column.value || []).filter((v) => {
            return v.file != null || v.id != null || v.upload_id != null;
        });
        return (
            <div key={`image-upload-${index}`}>
                <i className="fa fa-file"></i> {uploads.length}
            </div>
        );
    }

    if (column.type === "index") {
        return (
            <span key={`table-colum-value-${index}`} style={{ wordBreak: "keep-all" }}>
                {index + 1}
            </span>
        );
    }

    if (column.type === "autocomplete") {
        if (column.value && !Array.isArray(column.value)) {
            column.value = [column.value];
        }
        return (
            <div key={`table-colum-value-${index}`}>
                {column.value &&
                    column.value.map((val, index2) => {
                        return (
                            <React.Fragment key={`table-colum-value-${index2}`}>
                                <span style={{ color: val.cell ? val.cell.color : "#222" }}>
                                    {val.label}
                                </span>
                                {index2 < column.value.length - 1 ? (
                                    <span>
                                        ,<br />
                                    </span>
                                ) : (
                                    ""
                                )}
                            </React.Fragment>
                        );
                    })}
            </div>
        );
    }

    return (
        <span key={`table-colum-value-${index}`}>
            {typeof column.value === "string" ? column.value : <></>}
        </span>
    );
};

const TablePresenter = ({ activeRows, columns, data, onRowClick }) => {
    const tableBody = useMemo(() => {
        return data.map((dataRow, index) => {
            return (
                <tr
                    onClick={() => onRowClick(index)}
                    className={activeRows.includes(index) ? "tr tr-active" : "tr"}
                    key={index}
                >
                    {dataRow.map((dataColumn, columnIndex) => {
                        return (
                            <td
                                key={columnIndex}
                                style={
                                    dataColumn.title === "Baujahr"
                                        ? { whiteSpace: "nowrap" }
                                        : undefined
                                } // https://trello.com/c/AS0rfb2w
                            >
                                <TableColumnValue
                                    column={dataColumn}
                                    key={`ci-${columnIndex}`}
                                    index={index}
                                />
                            </td>
                        );
                    })}
                </tr>
            );
        });
    }, [data, activeRows]);

    return (
        <BTTable responsive>
            <thead>
                <tr>
                    {columns.map((col, index) => {
                        return <th key={index}>{stripTags(col.title)}</th>;
                    })}
                </tr>
            </thead>
            <tbody>{tableBody}</tbody>
        </BTTable>
    );
};

const TableColumnEditor: any = ({ column, totalIndex, onChange, index, rowIndex, table }) => {
    const [uploads, setUploads] = useState(Array.isArray(column.value) ? column.value : []);
    const { entities } = useSelector<any, any>((state) => state);
    const { entity_id, task_id }: any = useParams() as any;
    const task = useSelector((state: any) => {
        return state.tasks?.tasks.find(({ id }) => {
            return String(id) === task_id;
        });
    });
    const entity = entities?.entities.find((entity: any) => {
        return entity_id === String(entity.id);
    });

    const form = useSelector<any, any>((state) => {
        return state.forms?.forms.find((f) => f.id === task?.form_id);
    });

    const formData =
        typeof form?.form_data === "string" ? JSON.parse(form.form_data) : form?.form_data || {};
    const config = useFormConfig(formData.fields);

    useEffect(() => {
        if (column.type === "imageUpload") {
            onChange({ column, index, value: uploads });
        }
    }, [uploads]);

    const clearField = () => {
        onChange({ column, index, value: null });
    };

    const selectValues = () => {
        return column.source.map((cellVal) => {
            return {
                label: cellVal.name || cellVal,
                value: cellVal.name || cellVal,
                cell: cellVal,
            };
        });
    };

    const defaultValueForSelect = () => {
        if (column.field?.rawOptions?.autofill && column.source) {
            const refValue = template(column.field.rawOptions.autofill, {}, column.formName);
            const defValue = selectValues().find(
                (c) => c.label === refValue || c.value === refValue
            );
            return [defValue];
        }
        return column.value;
    };

    return (
        <div key={`col-${index}`}>
            {column.type === "index" && <></>}

            {column.type === "date" && (
                <div style={{ display: "flex" }}>
                    <input
                        type="date"
                        placeholder={""}
                        onChange={(v) =>
                            onChange({
                                column,
                                index,
                                value: v.currentTarget.value,
                            })
                        }
                        className="form-control"
                        defaultValue={column.value}
                    />
                    {column.value && (
                        <SmallDangerButton
                            style={{
                                marginLeft: "15px",
                                display: "inline-block",
                            }}
                            onClick={clearField}
                        >
                            X
                        </SmallDangerButton>
                    )}
                </div>
            )}

            {column.type === "time" && (
                <input
                    type="time"
                    placeholder={""}
                    onChange={(v) =>
                        onChange({
                            column,
                            index,
                            value: v.currentTarget.value,
                        })
                    }
                    className="form-control"
                    defaultValue={column.value}
                />
            )}

            {column.type === "autocomplete" && (
                <div key={`createable-select-${index}`}>
                    <CreatableSelect
                        closeMenuOnSelect={false}
                        isMulti
                        isClearable
                        defaultValue={defaultValueForSelect()}
                        styles={colorStyles}
                        menuPlacement={"auto"}
                        menuShouldScrollIntoView={true}
                        onChange={(v) => {
                            onChange({ column, index, value: v });
                        }}
                        options={selectValues().filter((r) => typeof r.value !== "object")}
                    />
                </div>
            )}

            {column.type === "imageUpload" && (
                <>
                    <UploadField
                        setUploads={setUploads}
                        namePattern={column.namePattern}
                        layout={BootstrapLayout}
                        subfields={[]}
                        uploads={uploads}
                        modal={false}
                        autoSave={true}
                        count={true}
                        variables={{
                            index: rowIndex,
                            row: { index: `${rowIndex + 1}` },
                            form,
                            task,
                            entity,
                            config,
                            uploads,
                        }}
                        hashCodeRef={column.hashCodeRef}
                    />
                </>
            )}

            {!column.type && (
                <input
                    defaultValue={column.value}
                    onChange={(ev) =>
                        onChange({
                            column,
                            index,
                            value: ev.currentTarget.value,
                        })
                    }
                    type={"text"}
                    className={"form-control"}
                />
            )}
        </div>
    );
};

const TableEditor: any = ({
    columns,
    data,
    indexCount,
    activeRows,
    onRevert,
    onChange,
    rowIndex,
    isVisible,
    onHide,
    onSave,
}) => {
    return (
        <Modal size="lg" show={isVisible} backdrop="static" onHide={onHide} keyboard={false}>
            <Modal.Header>
                <Button variant="secondary" onClick={onRevert}>
                    Schließen
                </Button>
                {activeRows.length > 1 && <h3>{activeRows.length} Zeilen bearbeiten</h3>}
                <Button variant="primary" onClick={onSave}>
                    Speichern
                </Button>
            </Modal.Header>
            <Modal.Body>
                {columns.map((column, index) => {
                    return (
                        <Row key={index}>
                            {column.type !== "index" && (
                                <>
                                    <Col className="table-editor-cell">
                                        <strong>{stripTags(column.title)}</strong>
                                    </Col>
                                    <Col className="table-editor-cell">
                                        <TableColumnEditor
                                            index={index}
                                            table={data}
                                            rowIndex={rowIndex}
                                            totalIndex={data.length + 1}
                                            column={column}
                                            onChange={onChange}
                                            activeRows={activeRows}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    );
                })}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onRevert}>
                    Schließen
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Speichern
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const Table: any = ({ initialData, id, columns, readonly = false }) => {
    const [activeRowIndizes, setActiveRowIndizes]: any = useState([]);
    const [editorVisible, setEditorVisibility] = useState(false);
    const [rowIndex, setRowIndex] = useState();
    const [data, setData] = useState(initialData);

    const [editingRow, setEditingRow] = useState([]);
    const [editingMultiple, setEditingMultiple] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const [appendDirection, setAppendDirection] = useState("append");

    const toggleActiveRow = (row) => {
        if (activeRowIndizes.includes(row)) {
            setActiveRowIndizes(activeRowIndizes.filter((r) => r !== row));
            return;
        }

        return setActiveRowIndizes([...activeRowIndizes, row]);
    };

    const addRow = (direction = "append") => {
        setIsEditing(false);
        setAppendDirection(direction);
        setEditingRow(
            columns.map((c) => {
                c.value = undefined;
                return c;
            })
        );

        switch (direction) {
            case "append":
                setRowIndex(data.length as any);
                break;
            case "below":
                setRowIndex(data.length);
                break;
            case "above":
                setRowIndex((data.length - 1) as any);
                break;
        }

        setEditorVisibility(true);
    };

    const addRowBelow = () => {
        addRow("below");
    };

    const addRowAbove = () => {
        addRow("above");
    };

    const copyRow = () => {
        setData((data) => {
            const activeRows = JSON.parse(
                JSON.stringify(activeRowIndizes.map((index) => data[index]))
            );

            return [...data, ...activeRows];
        });
        setActiveRowIndizes([]);
    };

    const onSave = () => {
        setEditorVisibility(false);
        setData((data) => {
            // append
            if (isEditing === false) {
                const eRow = editingRow.map((e) => {
                    return { ...e, field: null };
                });
                const newRow = JSON.parse(JSON.stringify(eRow));
                if (appendDirection === "append") {
                    return [...data, newRow];
                }

                if (activeRowIndizes.length === 1) {
                    const activeRow = activeRowIndizes[0];
                    if (appendDirection === "above") {
                        const dataBefore = data.slice(0, activeRow);
                        const dataAfter = data.slice(activeRow);
                        return [...dataBefore, newRow, ...dataAfter];
                    }

                    if (appendDirection === "below") {
                        const dataBefore = data.slice(0, activeRow + 1);
                        const dataAfter = data.slice(activeRow + 1);
                        return [...dataBefore, newRow, ...dataAfter];
                    }
                }
            }

            // update
            return data.map((dataRow, index) => {
                if (!activeRowIndizes.includes(index)) {
                    return dataRow;
                }

                if (activeRowIndizes.length > 1) {
                    return dataRow.map((v, dataRowIndex) => {
                        if (editingRow[dataRowIndex] && editingRow[dataRowIndex].value) {
                            return {
                                ...v,
                                value: editingRow[dataRowIndex].value,
                            };
                        }
                        return v;
                    });
                }

                if (activeRowIndizes[0] !== index) {
                    return dataRow;
                }

                return JSON.parse(JSON.stringify(editingRow));
            });
        });
        setEditingRow([]);
        setActiveRowIndizes([]);
    };

    const removeRow = () => {
        setData((data) => {
            const newData = [...data];
            activeRowIndizes.forEach((activeRow: number) => {
                newData[activeRow] = null;
            });

            return newData.filter((n) => !!n);
        });

        setActiveRowIndizes([]);
    };

    const editRow = () => {
        if (activeRowIndizes.length === 1) {
            setRowIndex(activeRowIndizes[0]);
            setEditingRow(data[activeRowIndizes[0]]);
        } else {
            setRowIndex(data.length as any);
            setEditingRow(
                columns.map((c) => {
                    c.value = undefined;
                    return c;
                })
            );
        }
        setIsEditing(true);
        setEditorVisibility(true);
    };

    const onChange = ({ column, index, value }) => {
        setEditingRow((editing) => {
            editing[index].value = value;
            return editing;
        });
        return;
    };

    const revert = () => {
        setEditingRow([]);
        setEditorVisibility(false);
    };

    return (
        <div>
            <input type={"hidden"} name={id} id={id} defaultValue={JSON.stringify(data)} />

            <TableEditor
                isVisible={editorVisible}
                onHide={() => setEditorVisibility(false)}
                data={data}
                rowIndex={rowIndex}
                columns={editingRow}
                activeRows={activeRowIndizes}
                onSave={onSave}
                onChange={onChange}
                onRevert={revert}
            />

            <TablePresenter
                activeRows={activeRowIndizes}
                columns={columns}
                data={data}
                onRowClick={(row) => toggleActiveRow(row)}
            />

            <TableActions
                editRow={editRow}
                copyRows={copyRow}
                removeRow={removeRow}
                activeRows={activeRowIndizes}
                addRow={() => {
                    setActiveRowIndizes([]);
                    addRow("append");
                }}
                selectAllRows={() => {
                    setActiveRowIndizes(data.map((_, i) => i));
                }}
                addRowBelow={addRowBelow}
                addRowAbove={addRowAbove}
                readonly={readonly}
            />
        </div>
    );
};
