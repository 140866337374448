import axios from "axios";
import { push } from "connected-react-router";
import moment from "moment";
import { toast } from "react-toastify";
import { API_URL, COMPANY_ID } from "../config";
import { isEmpty } from "../utils";
import { getCollection, user } from "./actions_helper";
import {
    ADD_CALENDAR_EVENT,
    ADD_DETAILED_EVENT,
    CALENDAR_EVENTS_LOADING_START,
    CALENDAR_EVENTS_LOADING_STOP,
    CREATE_CALENDAR_EVENT,
    DELETE_CALENDAR_EVENT,
    GET_CALENDAR_EVENTS,
    NEW_DEVICE_TASK,
    NEW_DEVICE_TASKS,
    SET_CALENDAR_EVENTS,
    UPDATE_CALENDAR_EVENT,
} from "./types";

export function getCalendarEvents(
    range = {
        start: moment().format(),
        end: moment().add(1, "months").format(),
    }
) {
    return (dispatch: IDispatch<any>) => {
        dispatch({ type: CALENDAR_EVENTS_LOADING_START });
        axios
            .get(`${API_URL}/calendar_events.json?start=${range.start}&end=${range.end}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: GET_CALENDAR_EVENTS,
                    payload: response.data,
                });
                dispatch({ type: CALENDAR_EVENTS_LOADING_STOP });
            });
    };
}

export function getCalendarEventById(id: string) {
    return (dispatch: IDispatch<any>) => {
        dispatch({ type: CALENDAR_EVENTS_LOADING_START });
        axios
            .get(`${API_URL}/calendar_events/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: ADD_CALENDAR_EVENT,
                    payload: response.data,
                });
                dispatch({
                    type: ADD_DETAILED_EVENT,
                    payload: response.data,
                });
                dispatch({ type: CALENDAR_EVENTS_LOADING_STOP });
            });
    };
}

export function newDeviceTask(opts: any) {
    return (dispatch: IDispatch<IReduxAction<JSON>>) => {
        axios
            .post(`${API_URL}/calendar_events/${opts.calendar_event_id}/device_tasks.json`, opts, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: NEW_DEVICE_TASK,
                    payload: response.data,
                });
            });
    };
}

export function newDeviceTasks(opts: any) {
    return (dispatch: IDispatch<IReduxAction<JSON>>) => {
        axios
            .post(`${API_URL}/calendar_events/${opts.calendar_event_id}/device_tasks.json`, opts, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: NEW_DEVICE_TASKS,
                    payload: response.data,
                });
            });
    };
}

export function createCalendarEvent(
    calendarEvent: ICalendarEvent,
    redirect?: boolean,
    redirect2?: boolean
) {
    return (dispatch: IDispatch<any>) =>
        axios
            .post(
                `${API_URL}/calendar_events.json`,
                {
                    calendar_event: calendarEvent,
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                const data = response.data;
                dispatch({
                    payload: data,
                    type: CREATE_CALENDAR_EVENT,
                });
                if (redirect) {
                    toast.success("✌️ Event gespeichert", { autoClose: 2000 });
                    dispatch(push(`/master_data/devices/view-${data.device_id}/event-${data.id}`));
                }

                if (redirect2) {
                    toast.success("✌️ Event erstellt", { autoClose: 2000 });
                    window.location.href = `/master_data/devices/view-${
                        data.device_id || null
                    }/event-${data.id}`;
                    return;
                }
                return data;
            })
            .catch((err) => {
                throw err;
            });
}

export function updateCalendarEvent(calendarEvent: ICalendarEvent, cb) {
    return (dispatch: IDispatch<any>) =>
        axios
            .patch(
                `${API_URL}/calendar_events/${calendarEvent.id}.json`,
                {
                    calendar_event: calendarEvent,
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: UPDATE_CALENDAR_EVENT,
                });
                if (cb) {
                    cb();
                }
            })
            .catch((err) => {
                throw err;
            });
}

export function deleteCalendarEvent(calendarEvent: ICalendarEvent) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/calendar_events/${calendarEvent.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: DELETE_CALENDAR_EVENT,
                });
                toast.success("Das Event wurde erfolgreich gelöscht", { autoClose: 2000 });
                dispatch(push("/calendar"));
            })
            .catch((err) => {
                toast.error("Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut", {
                    autoClose: 3000,
                });
                throw err;
            });
}

export function createEditCalendarEvent(entryId: number, notify = false) {
    console.log(entryId, notify, toast);
    return (dispatch: IDispatch<any>) => {
        dispatch({ type: CALENDAR_EVENTS_LOADING_START });
        axios
            .post(
                `${API_URL}/calendar_events.json`,
                {
                    entryId,
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                if (notify) {
                    alert("Nachbearbeitungsauftrag wurde erfolgreich erstellt");
                }

                const data = response.data;
                dispatch({
                    payload: data,
                    type: ADD_CALENDAR_EVENT,
                });
                dispatch({ type: CALENDAR_EVENTS_LOADING_STOP });
                return data;
            })
            .catch((err) => {
                if (notify) {
                    alert(
                        "Dem Formular konnte kein Gerät zugeordnet werden, " +
                            "bitte erstellen Sie die Nachbearbeitung manuell im Kalender"
                    );
                }
                throw err;
            });
    };
}

export function moveEvents(options: {
    ids: any;
    device_id: number; // target
}) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.patch(`${API_URL}/calendar_events.json`, options, {
            headers: { authorization: user().token },
        });

        return response.data;
    };
}

export function fetchCalendarEvents(
    page = 1,
    cb: any,
    clear = false,
    opts?: {
        q?: any;
        page_size?: number;
        page?: number;
        hidden_fields?: any;
    },
    pageSize = 10
) {
    opts.page_size = pageSize;
    opts.page = page;
    if (isEmpty(opts.hidden_fields)) {
        opts.hidden_fields = "";
    }
    return getCollection("calendar_events", opts, cb);
}

export function fetchCalendarEventsForDevice(
    page = 1,
    cb: any,
    clear = false,
    opts?: {
        q?: any;
        page_size?: number;
        page?: number;
        hidden_fields?: any;
    },
    pageSize = 10,
    deviceId: any = null
) {
    opts.page_size = pageSize;
    opts.page = page;
    if (isEmpty(opts.hidden_fields)) {
        opts.hidden_fields = "";
    }
    return getCollection("calendar_events", opts, cb, `/devices/${deviceId}/`);
}
