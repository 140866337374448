import { BaseField } from "../fields/BaseField";
import { PageBreakField } from "../fields/PageBreakField";
import { moveArrayElement, stripTags, template, uniqueID } from "../utils";
import { DataPresenter } from "./DataPresenter";
import { FieldClassMapping } from "./FieldClassMapping";

const DEFAULT_NAME_PATTERN =
    "{date:current:MMDD}_{field:stockwerk}_{field:entity_location}_{field:rpnummer}_{config:slug}";

export enum LogLevel {
    VERBOSE = 1,
    INFO = 2,
    WARNING = 4,
    ERROR = 8,
    DEBUG = 0,
    SILENT = -1,
}

export enum Direction {
    DOWN = -1,
    UP = 1,
}

const SCHEMA_BUILDER__ID = 1;

export class SchemaBuilder {
    public static INITIAL_SCHEMA = {
        fields: [],
        form: {
            name: "",
            endpoint: "",
        },
    };
    public __ID = SCHEMA_BUILDER__ID;
    private logLevel: number = LogLevel.SILENT;

    private schema: any = SchemaBuilder.INITIAL_SCHEMA;

    public setLogLevel(newLogLevel) {
        this.logLevel = newLogLevel;
    }

    public getConfig() {
        const config = this.flatFields().find((c) => c.rawField.type === "ConfigField");
        return config;
    }

    public formEntryName(variables: any) {
        const config = this.flatFields().find((c) => c.rawField.type === "ConfigField");

        if (config && config.overrideName && config.overridePattern) {
            return template(config.overridePattern || DEFAULT_NAME_PATTERN, {
                config,
                ...variables,
            }).replace(/[\/\\]/g, "-");
        }
        return template(DEFAULT_NAME_PATTERN, { ...variables }).replace(/[\/\\]/g, "-");
    }

    /**
     * Load a JSON powerform schemaBuilder into the current application
     * @param payload
     */
    public use(payload) {
        this.schema = payload;
        if (typeof payload === "string") {
            payload = JSON.parse(payload);
            this.schema = payload;
        }

        if (payload.fields) {
            payload.fields = payload.fields.filter((field) => !!field);
            this.schema.fields = payload.fields.map(this.parseField.bind(this));
        } else {
            this.schema.fields = [];
        }

        this.schema.form = payload.form ? payload.form : { name: "", endpoint: "" };

        const logField = (field) => {
            this.getConsole().groupCollapsed(
                "Field " + stripTags(field.label) + " (" + field.hashCode + ")"
            );
            this.getConsole().groupCollapsed("Attributes");
            for (const key in field) {
                if (key !== "subfields") {
                    this.getConsole().log(key.toUpperCase(), ":", field[key]);
                }
            }
            this.getConsole().groupEnd();

            if (field.subfields && field.subfields.length) {
                this.getConsole().groupCollapsed("Subfields");
                field.subfields.map(logField);
                this.getConsole().groupEnd();
            }

            this.getConsole().groupEnd();
        };

        this.getConsole().group("Form Schema loaded");
        this.schema.fields.map(logField);
        this.getConsole().groupEnd();

        return this;
    }

    public setHashCode(theField, newHashCode) {
        const walkFields = (field) => {
            if (field.hashCode === theField.hashCode) {
                theField.hashCode = newHashCode;
            }

            if (field.subfields && field.subfields.length) {
                field.subfields = field.subfields.map(walkFields);
            }

            return field;
        };
        this.schema.fields = this.schema.fields.map(walkFields);
    }

    public sheetHeight(sheet) {}

    public getConsole(logLevel = LogLevel.DEBUG) {
        if (logLevel > this.logLevel) {
            return {
                group: () => {},
                groupEnd: () => {},
                log: () => {},
                error: () => {},
                groupCollapsed: () => {},
            };
        }
        return console;
    }

    public asPDFSheets(formID) {
        let sheets = [];
        let sheet = [];
        const walkField = (field: any) => {
            sheet.push(field);
            if (field instanceof PageBreakField) {
                sheets.push(sheet);
                sheet = [];
            }
        };

        this.schema.fields.forEach(walkField);

        if (sheet.length) {
            sheets = [...sheets, sheet];
        }

        return sheets;
    }

    public applyTreeStructure(newStructure) {
        const newSchema = [];

        const walkField = (node) => {
            if (node.children) {
                node.field.subfields = node.children.map(walkField);
            }
            return node.field;
        };

        // omit the root node
        this.schema.fields = newStructure.children.map(walkField);
    }

    /**
     * Sets an option of the field by providing a field, a keyname and the value
     * @param theField {{hashCode: string}} Provide an field or plain object with a hashCode field
     * @param keyName string The key you want to set.
     * @param value The value. Do note that this value needs to be json compatible.
     */
    public setFieldOption(theField, keyName, value) {
        const walkFields = (field) => {
            if (field.hashCode === theField.hashCode) {
                if (!theField.rawOptions) {
                    theField.rawOptions = {};
                }
                theField.rawOptions[keyName] = value;
            }

            if (field.subfields && field.subfields.length) {
                field.subfields = field.subfields.map(walkFields);
            }

            return field;
        };

        this.schema.fields = this.schema.fields.map(walkFields);
        return this;
    }

    /**
     * Returns a flat hierarchy of fields without nesting.
     * This is useful if you want to build lists of all fields within the schema.
     */
    public flatFieldHierachy() {
        const fields = [];
        const walkField = (field) => {
            fields.push(field);
            if (field.subfields && field.subfields.length) {
                field.subfields.forEach(walkField);
            }
        };

        this.schema.fields.forEach(walkField);
        return fields;
    }

    /**
     * Returns a structured object representing the given entry on the given schema
     */
    public dataPresenter(entry) {
        const dataPresenter = new DataPresenter(this, entry);
        return dataPresenter;
    }

    public createTree() {
        const tree = {
            module: "Formular",
            children: [],
            root: true,
        };

        const walkFields = (field) => {
            const treeNode: any = { module: field.label || field.type, field };

            if (field.subfields && field.subfields.length) {
                treeNode.children = field.subfields.map(walkFields);
            }
            return treeNode;
        };

        tree.children = this.schema.fields.map(walkFields);
        return tree;
    }

    /**
     * Recursively resolve all fields
     * @param field
     */
    public parseField(field, overrideHashCode = "") {
        // is already parsed
        if (field instanceof BaseField) {
            return field;
        }

        try {
            if (field.hasOwnProperty("subfields") && field.subfields.length > 0) {
                field.subfields = field.subfields.map((subfield) =>
                    this.parseField(subfield, overrideHashCode)
                );
            }
        } catch (typeError) {
            this.getConsole().error("Could not parse field: ", field);
            return;
        }

        if (field.hasOwnProperty("leftSiblings") && field.leftSiblings.length > 0) {
            field.leftSiblings = field.leftSiblings.map((sibling) =>
                this.parseField(sibling, overrideHashCode)
            );
        }

        if (field.hasOwnProperty("rightSiblings") && field.rightSiblings.length > 0) {
            field.rightSiblings = field.rightSiblings.map((sibling) =>
                this.parseField(sibling, overrideHashCode)
            );
        }

        if (overrideHashCode === "random") {
            field.hashCode = uniqueID();
        }

        const fieldClass = new FieldClassMapping[field.type](field);
        const data = fieldClass.unserialize(field);
        return data;
    }

    /**
     * Sets or gets the version
     * @param version
     */
    public version(version) {
        if (!version) {
            return this.schema.powerform;
        }
        this.schema.powerform = version;
        return this;
    }

    /**
     * Sets form values
     * @param newValues
     */
    public form(newValues) {
        if (!newValues) {
            return this.schema.form;
        }
        this.schema = { ...this.schema, ...newValues };
        return this;
    }

    /**
     * Adds a new field to the schemaBuilder
     * @return SchemaBuilder self
     */
    public field(newField) {
        this.schema.fields.push(newField);
        return this;
    }

    public customField(fieldData) {
        const newField = this.parseField(JSON.parse(fieldData), "random");
        this.schema.fields.push(newField);
        return this;
    }

    public duplicate(existingField) {
        const clonedField = this.parseField(existingField.serialize(), "");
        clonedField.hashCode = uniqueID();
        const renameSubfields = (schemaField: any) => {
            if (schemaField.hasOwnProperty("subfields") && schemaField.subfields.length > 0) {
                schemaField.subfields = schemaField.subfields.map(renameSubfields.bind(this));
            }

            schemaField.hashCode = uniqueID();
            return schemaField;
        };

        if (existingField.subfields) {
            const subfields = existingField.subfields.map((f) => f.serialize());
            clonedField.subfields = subfields.map((subfield) =>
                this.parseField(subfield, "random")
            );
        } else {
            clonedField.subfields = [];
        }

        this.fieldBelow(clonedField, existingField);
        return clonedField;
    }

    /**
     * Adds a field below an existing field in the schema
     * @param newField The field you want to add to your schema
     * @param existingField A reference to the existing field. At minimal provide an object with a {hashCode} property.
     */
    public fieldBelow(newField, existingField) {
        if (typeof newField === "string") {
            newField = this.parseField(JSON.parse(newField), "random");
        }

        // find field in hierarchy
        const walkFields = (subset) => {
            const newFields = [];
            subset.forEach((field) => {
                newFields.push(field);
                if (field.hashCode === existingField.hashCode) {
                    newFields.push(newField);
                }

                if (field.hasOwnProperty("subfields") && field.subfields.length > 0) {
                    field.subfields = walkFields(field.subfields);
                }
            });

            return newFields;
        };

        this.schema.fields = walkFields(this.schema.fields);
        return this;
    }

    /**
     * Adds a subfield to the provided parent field
     * @param parentField
     * @param subField
     */
    public subfield(parentField, subField) {
        if (typeof subField.rawOptions === "undefined") {
            subField.rawOptions = {};
        }
        const addSubfieldIfNeeded = (schemaField: any) => {
            if (schemaField.hasOwnProperty("subfields") && schemaField.subfields.length > 0) {
                schemaField.subfields = schemaField.subfields.map(addSubfieldIfNeeded.bind(this));
            }

            // match by using the hashCode
            if (schemaField.hashCode === parentField.hashCode) {
                if (!schemaField.hasOwnProperty("subfields")) {
                    schemaField.subfields = [];
                }
                schemaField.subfields.push(subField);
            }
            return schemaField;
        };

        this.schema.fields = this.schema.fields.map(addSubfieldIfNeeded);
        return this;
    }

    /**
     * Adds or returns a list of fields into the schemaBuilder
     */
    public fields(newFields = []) {
        if (!newFields.length) {
            return this.schema.fields;
        }

        for (const newField of newFields) {
            this.field(newField);
        }

        return this.schema.fields;
    }

    /**
     * Updates the schemaBuilder with updated values
     * @param newFieldData
     */
    public updateFields(newFieldData) {
        // Recursively update each field if necessary
        const updateField = (schemaField: any, data) => {
            if (schemaField.hasOwnProperty("subfields") && schemaField.subfields.length > 0) {
                schemaField.subfields = schemaField.subfields.map((subfield) =>
                    updateField(subfield, data)
                );
            }

            // match by using the hashCode
            if (schemaField.hashCode === data.hashCode) {
                for (const key in data.values) {
                    // only if the key is present in the provided data hash
                    if (!data.values.hasOwnProperty(key)) {
                        continue;
                    }
                    schemaField[key] = data.values[key];
                }
            }

            return schemaField;
        };

        for (const hashCode in newFieldData) {
            if (!newFieldData.hasOwnProperty(hashCode)) {
                continue;
            }
            const data = { hashCode, values: newFieldData[hashCode] };
            this.schema.fields = this.schema.fields.map((schemaField) =>
                updateField(schemaField, data)
            );
        }
    }

    /**
     * Removes a field from the schema
     * @param fieldToRemove
     */
    public remove(fieldToRemove) {
        const checkField = (field) => {
            let keep = true;
            if (field.hasOwnProperty("subfields") && field.subfields && field.subfields.length) {
                field.subfields = field.subfields.filter(checkField);
            }

            if (field.hashCode === fieldToRemove.hashCode) {
                keep = false;
            }

            return keep;
        };
        this.schema.fields = this.schema.fields.filter(checkField);
    }

    /**
     * Moves a field up one position if possible
     */
    public moveFieldUp(targetField) {
        return this.moveField(targetField, Direction.UP);
    }

    /**
     * Sorts the targetField in the field hierarchy.
     * The sort parameter defines whether the field should be moved up or down.
     * @param targetField
     * @param sort
     */
    public moveField(targetField, sort: Direction = 1) {
        const sortField = (target, set, position) => {
            let index = 0;
            let targetIndex = -1;
            set.forEach((field) => {
                if (field.hasOwnProperty("subfields") && field.subfields.length) {
                    field.subfields = sortField(target, field.subfields, position);
                }
                if (field.hashCode === targetField.hashCode) {
                    targetIndex = index;
                }
                index++;
            });

            if (targetIndex > -1) {
                return moveArrayElement(set, targetIndex, targetIndex + position);
            }

            return set;
        };

        this.schema.fields = sortField(targetField, this.schema.fields, sort);
        return true;
    }

    /**
     * Moves a field down one position if possible
     */
    public moveFieldDown(targetField) {
        this.moveField(targetField, Direction.DOWN);
    }

    /**
     * Returns all fields embedded into the current form
     */
    public flatFields() {
        const fields = [];
        const parseField = (field) => {
            if (field.hasOwnProperty("subfields") && field.subfields.length > 0) {
                field.subfields.forEach(parseField.bind(this));
            }

            fields.push(field);
        };

        this.schema.fields.forEach(parseField);
        return fields;
    }

    public export() {
        const exportedSchema: any = { ...this.schema };
        exportedSchema.fields = this.schema.fields.map((field: any) => field.serialize());

        return exportedSchema;
    }
}
