export const COMPANY_ID = process.env.REACT_APP_COMPANY_ID || 1;

export const API_URL =
    localStorage.getItem("endpoint") ||
    process.env.REACT_APP_BACKEND_URL;

export const FRONTEND_URL =
    process.env.NODE_ENV === "production"
        ? "https://rpforms.kega.network"
        : "http://localhost:3000";
