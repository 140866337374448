import React, { Dispatch, SetStateAction } from "react";
import Select from "react-select";

const options = [
    { label: "Alle", value: "alle" },
    { label: "Mit Wartungsvertrag", value: "wartungsvertrag" },
];

const LegacyEntityFilter = ({
    entityFilter,
    setEntityFilter,
}: {
    entityFilter: string;
    setEntityFilter: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <Select
            value={options.find((o) => o.value === entityFilter)}
            onChange={(option) => setEntityFilter(option.value)}
            options={options}
        />
    );
};

export default LegacyEntityFilter;
