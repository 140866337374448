import { fetchCalendarEvents, fetchCalendarEventsForDevice } from "@rpforms/shared";
import { changeDeviceCalendarColor, deviceByID } from "@rpforms/shared/build/actions";
import { getCalendarEvents } from "@rpforms/shared/build/actions/calendar_events";
import { fetchDeviceTasks } from "@rpforms/shared/build/actions/tasks";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { BoxCard, CardBody, CardTitle } from "@rpforms/shared/build/components/layout/Widget";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import moment from "moment";

import { Button, DangerButton, PrimaryButton } from "@rpforms/shared/build/components/Button";
import React, { useEffect, useState } from "react";
import { SwatchesPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { ListCalendarEvents } from "../../../components/devices/calendar_events/ListCalendarEvents";

moment.locale("de");

const ViewDevicePage = () => {
    const { id }: { id: string } = useParams();
    const device = useSelector<any, any>((state) =>
        state.device.devices.find((dev: any) => dev.id === parseInt(id, 10))
    );
    const allCalendarEvents = useSelector<any, any>((state) =>
        state.calendarEvents.calendarEvents.filter((ce) => {
            return parseInt(ce.device_id, 10) === parseInt(id, 10);
        })
    );
    const loading = useSelector<any, any>((state) => state.calendarEvents.isLoading);
    const dispatch = useDispatch();
    const [calendarColor, setCalendarColor] = useState("");
    const [colorSwitcherShown, setColorSwitcherShown] = useState(false);
    const [deviceCalendarEvents, setDeviceCalendarEvents] = useState([]);

    const handleOnChangeColorClick = () => {
        setColorSwitcherShown(true);
    };

    const handleColorChange = () => {
        dispatch(changeDeviceCalendarColor(device, calendarColor));
        setColorSwitcherShown(false);
    };

    const handleColorChangeCancelClick = () => {
        setColorSwitcherShown(false);
    };

    const handleOnColorChangeComplete = (color: { hex: string }) => {
        setCalendarColor(color.hex);
    };

    useEffect(() => {
        dispatch(
            fetchCalendarEventsForDevice(
                1,
                (events) => {
                    setDeviceCalendarEvents(
                        events.filter((x) => parseInt(x.device_id, 10) === parseInt(id, 10))
                    );
                },
                false,
                {
                    "q[was_submitted_eq]": false,
                    "q[calendar_event_type_id_in][]": [1, 2, 3, 4, 5],
                },
                -1,
                id
            )
        );
        dispatch(deviceByID(id));
    }, []);

    if (!device || !(allCalendarEvents.length >= 0) || loading) {
        return <Loader />;
    }

    const calendarEvents = allCalendarEvents.filter(
        (event) => event.device_id === device.id && event.was_submitted === false
    );

    return (
        <>
            <PageNavbar
                title={device.name}
                description="Hier finden Sie eine Detailübersicht über dieses Gerät."
            >
                <PageNavAction
                    link="/master_data/devices"
                    primary={true}
                    icon="list"
                    title="Zurück zu allen Geräten"
                />
            </PageNavbar>
            <Content>
                <div className="row">
                    <div className="col-12 col-sm-4">
                        <BoxCard>
                            <CardTitle>Sicherheit</CardTitle>
                            <CardBody>
                                <p>
                                    <strong>PIN </strong>
                                    <span>{device.pin}</span>
                                </p>
                                <p>
                                    <strong>PUK </strong>
                                    <span>{device.puk}</span>
                                </p>
                                <p>
                                    <strong>Token </strong>
                                    <code>{device.device_token}</code>
                                </p>
                                <p>
                                    <strong>Token Ablaufdatum: </strong>
                                    <span>{device.device_token_expires_at}</span>
                                </p>
                                <p>
                                    <strong>Apple ID: </strong>
                                    <span>{device.apple_id}</span>
                                </p>
                                <p>
                                    <strong>Apple Password: </strong>
                                    <span>{device.apple_password}</span>
                                </p>
                            </CardBody>
                        </BoxCard>
                    </div>
                    <div className="col-12 col-sm-8">
                        <BoxCard>
                            <CardTitle>
                                Terminplaner
                                {false && process.env.REACT_APP_API_ENDPOINT !== "production" && (
                                    <Button
                                        onClick={() => dispatch(fetchDeviceTasks(device, true))}
                                    >
                                        cleanup
                                    </Button>
                                )}
                            </CardTitle>
                            <CardBody>
                                <p
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <strong>Terminplanerfarbe:</strong>
                                    <i
                                        style={{
                                            color: device.calendar_color || "#3174ad",
                                            paddingLeft: "5px",
                                            paddingRight: "10px",
                                        }}
                                        className="fa fa-square fa-2x"
                                    ></i>
                                    {!colorSwitcherShown && (
                                        <PrimaryButton onClick={handleOnChangeColorClick}>
                                            Farbe ändern
                                        </PrimaryButton>
                                    )}
                                </p>
                                {colorSwitcherShown && (
                                    <div style={{ width: 300 }}>
                                        <SwatchesPicker
                                            color={calendarColor}
                                            onChangeComplete={handleOnColorChangeComplete}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <DangerButton onClick={handleColorChangeCancelClick}>
                                                Abbrechen
                                            </DangerButton>
                                            <PrimaryButton onClick={handleColorChange}>
                                                Bestätigen
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                )}
                                {deviceCalendarEvents.length > 0 && (
                                    <>
                                        <p>
                                            <strong>Kalenderevents</strong>
                                        </p>
                                        <ListCalendarEvents
                                            device={device}
                                            calendarEvents={deviceCalendarEvents}
                                        />
                                    </>
                                )}
                            </CardBody>
                        </BoxCard>
                    </div>
                </div>
            </Content>
        </>
    );
};

export default withRouter(ViewDevicePage);
