import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";

export const RowVerticallyCentered = styled(Row)`
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
`;
