import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { ConfigField } from "../fields/ConfigField";
import { ErrorBoundary } from "../ErrorBoundary";

export const Form = styled.div`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    max-width: 210mm;
    min-height: 297mm;
    margin: 40px auto;
    padding-bottom: 20px;
`;

export const FormPortrait = styled.div`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    min-height: 297mm;
    margin: 40px auto;
    padding-bottom: 20px;
`;

export const FormLandscape = styled.div`
    background: #fff;
    padding: 40px 20px;
    border: 1px solid #f0f0f0;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    min-width: 297mm;
    min-height: 210mm;
    margin: 40px auto;
    padding-bottom: 20px;
`;

export const FormTitle = styled.h2`
    padding: 0;
    margin: 0;
    max-width: 80%;
    word-break: break-word;
    hyphens: auto;
`;

export const FormHeader = styled.div`
    border-bottom: 1px solid #ddd;
    padding: 20px;
    margin: -40px -20px 40px;
    display: flex;
    border-bottom: 1px solid #ddd;

    justify-content: space-between;
    img {
        height: 80px;
    }
`;

/**
 * Presents a schemaBuilder in a way that users can submit entries
 * This does not store any submitted forms as they are processed by an external URL
 * By not handling the form submission (other than basic validation) we ensure
 * that powerform can be embedded into any existing webpage and even
 * replace forms with it without fiddling with the backend.
 *
 * @param schema {PowerFormSchema} The schemaBuilder you want to present to the user
 * @constructor
 */
export const FormPresenter = React.memo(
    ({ qid = 0, layout, entity, form, schema, logoPath, saveState = {} }: any) => {
        const register = {};
        const formRef: any = useRef();
        const fields = useMemo(() => {
            return schema.fields();
        }, []);

        useEffect(() => {
            fields.map((field) => {
                if (field.wasRendered) {
                    field.wasRendered();
                }
            });
        }, []);

        let formID = "";
        let formName = "";
        if (form && form.name) {
            formID = form.name.split("_")[0];
            formName = form.name.replace(formID + "_", "");
        } else {
            formID = "";
            formName = "";
        }

        const config = { layout: FormPortrait };
        // find a config block
        fields.map((field) => {
            if (field instanceof ConfigField) {
                config.layout = field.displayLandscape == "on" ? FormLandscape : config.layout;
            }
        });

        const cssPrintPage = {
            [FormPortrait]: `@page { size: A4; }`,
            [FormLandscape]: `@page { size: A4 landscape; }`,
        };

        const styleSheet = document.createElement("style");
        styleSheet.innerHTML = cssPrintPage[config.layout];
        document.body.appendChild(styleSheet);

        const variables = {
            customer: entity?.customer,
            entity: {
                ...entity,
                address: entity?.address
                    ? `${entity?.address.street}, ${entity?.address.postalcode} ${entity?.address.city}`
                    : "",
            },
        };

        (window as any).__GLOBAL_CONDITIONALS = true;

        const renderedFields = useMemo(() => {
            return fields.map((field) => {
                const rawValue = saveState[field.hashCode];
                return (
                    <ErrorBoundary
                        title={field.label}
                        message={"Dieses Feld ist fehlerhaft und es wurde zur Sicherheit entfernt."}
                        key={field.hashCode}
                    >
                        <field.WrapComponent
                            layout={layout}
                            key={field.hashCode}
                            schema={schema}
                            field={field}
                            entry={null}
                            component={
                                <field.InputComponent
                                    variables={variables}
                                    layout={layout}
                                    register={register}
                                    schema={schema}
                                    field={field}
                                    entry={null}
                                    saveState={saveState}
                                    rawValue={rawValue}
                                />
                            }
                        />
                    </ErrorBoundary>
                );
            });
        }, [fields]);

        return (
            <config.layout id="form" ref={formRef}>
                <FormHeader>
                    <FormTitle>{formName}</FormTitle>
                    <img src={logoPath} />
                </FormHeader>

                <div className="d-flex flex-wrap">
                    <div>{renderedFields}</div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className={"rpfe-footnote"}>
                        Die Prüfung wurde nach bestem Wissen und Gewissen gemäß Herstellerangaben
                        und ASR 1.7 durchgeführt. Für versteckte Mängel, die bei der Anwendung der
                        erforderlichen Sorgfalt nicht zu erkennen sind, wird eine Haftung
                        ausgeschlossen. Weitere Hinweise entnehmen Sie dem Prüfbuch, dem dieses
                        Prüfprotokoll beizufügen ist. Das Prüfbuch ist, sofern nötig, vom Betreiber
                        zu pflegen und aufzubewahren und auf Verlangen bereitzuhalten.
                        <small>{qid}</small>
                    </div>
                    <br />
                    <br />
                </div>
            </config.layout>
        );
    }
);
