import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class CustomRendererTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Dynamische Zelle";
    public icon = "asterisk";
    public fieldType = "Dynamische Zelle";
    public attributeNames = ["cellType"];

    public getConfigurables() {
        return [Width];
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label}
                    ref={register}
                />
                <br />
                <label>Zelltyp</label>
                <br />
                <select
                    defaultValue={field.cellType}
                    name={field.inputName("cellType")}
                    ref={register}
                >
                    <option value="">Standard Zelle</option>
                    <option value="https://rpforms.kega.network/customcells/fireextinguisher.json">
                        Feuerlöscher Zelle
                    </option>
                </select>
            </FieldWrapper>
        );
    }
}
