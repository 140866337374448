import axios from "axios";
import { push } from "connected-react-router";
import moment from "moment";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import { getFolderLocation } from "./folders";
import {
    FINANCES_CHANGE_INVOICE_TYPE,
    FINANCES_CHANGE_NET,
    FINANCES_CUSTOMER_CHANGED,
    FINANCES_ENTITY_CHANGED,
    FINANCES_SET_DATE,
    GET_REVENUE,
    REVENUE_LOADING_START,
    REVENUE_LOADING_STOP,
} from "./types";

const getFormId = (type?: string) => {
    if (type === "offer") {
        return "000_angebot";
    }
    return "000_rechnung";
};

// used to filter the offers by their AASMState
const getOfferAASMStates = (offerState?: string) => {
    if (offerState === "beauftragt") {
        return [1, 2];
    }
    if (offerState === "notBeauftragt") {
        return [0, 3, 6];
    }
};

export function getTotalRevenues(date = new Date()) {
    return (dispatch) => {
        console.log("get total", date);
        dispatch({ type: REVENUE_LOADING_STOP });
    };
}

export function getRevenues(
    offerType: string = null,
    customerId: string = null,
    entityId: string = null,
    year: string | number = null,
    month: string | number = null,
    day: string | number = null,
    type: string = "invoice",
    offerState: "beauftragt" | "notBeauftragt" | null = null,
    page?: number,
    all?: boolean
) {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        dispatch({ type: REVENUE_LOADING_START });
        dispatch({ type: "START_TOTAL_REVENUE_LOADING" });

        // Define the date range to return items by. By default we pick all (1999-9999)
        // Then depending on how many fields the customer fills out
        // we either pick on a daily, weekly or yearly basis.
        let range = [
            moment().set({
                year: 1999,
                month: 1,
                date: 1,
            }),
            moment().set({ year: 8999, month: 12, date: 30 }),
        ];

        if (day) {
            range = [
                moment()
                    .set({
                        year: Number(year),
                        month: Number(month) - 1,
                        date: Number(day),
                    })
                    .startOf("date"),
                moment()
                    .set({
                        year: Number(year),
                        month: Number(month) - 1,
                        date: Number(day),
                    })
                    .endOf("date"),
            ];
        } else if (month) {
            range = [
                moment()
                    .set({
                        year: Number(year),
                        month: Number(month) - 1,
                    })
                    .startOf("month"),
                moment()
                    .set({
                        year: Number(year),
                        month: Number(month) - 1,
                    })
                    .endOf("month"),
            ];
        } else if (year) {
            range = [
                moment()
                    .set({ year: Number(year) })
                    .startOf("year"),
                moment()
                    .set({ year: Number(year) })
                    .endOf("year"),
            ];
        }

        const [from, to] = range.map((m) => m.toISOString());
        const url = `${API_URL}/finances/total.json?type=${type}&offerType=${
            offerType || offerState
        }&page=${page}&customerId=${customerId}&entityId=${entityId}&from=${from}&to=${to}`;
        const response = await axios.get(url, {
            headers: { authorization: user().token },
        });

        // Populate redux store
        dispatch({ type: GET_REVENUE, payload: response.data });
        dispatch({ type: REVENUE_LOADING_STOP });
        dispatch({ type: "STOP_TOTAL_REVENUE_LOADING" });
    };
}

export function onUpdateCustomer(customer) {
    return async (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({
            type: FINANCES_CUSTOMER_CHANGED,
            payload: {
                customer_id: customer?.id ?? null,
                customer: customer ?? null,
            },
        });
        dispatch({
            type: FINANCES_ENTITY_CHANGED,
            payload: {
                entity_id: null,
                entity: null,
            },
        });
        dispatch({
            type: FINANCES_CHANGE_INVOICE_TYPE,
            payload: { buttonId: 0, offerType: "" },
        });
    };
}

export function onUpdateEntity(entity) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        if (entity) {
            dispatch({
                type: FINANCES_ENTITY_CHANGED,
                payload: {
                    entity_id: entity.id,
                    entity,
                },
            });
        } else {
            dispatch({
                type: FINANCES_ENTITY_CHANGED,
                payload: {
                    entity_id: null,
                    entity: null,
                },
            });
        }
        dispatch({
            type: FINANCES_CHANGE_INVOICE_TYPE,
            payload: { buttonId: 0, offerType: "" },
        });
    };
}

export function onUpdateNet(isNet: boolean) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({
            type: FINANCES_CHANGE_NET,
            payload: isNet,
        });
    };
}

export const openInFolder = (entryId: number) => {
    return async (dispatch: any) => {
        const req = await dispatch(
            getFolderLocation({
                resource_id: entryId,
                resource_type: "FormEntry",
            })
        );
        dispatch(push("/folders?open=" + encodeURIComponent(JSON.stringify(req.data))));
    };
};
