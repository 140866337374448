import { formByID } from "@rpforms/shared";
import { updateForm } from "@rpforms/shared/build/actions/forms";
import { Sidebar } from "@rpforms/shared/build/components/layout/Sidebar";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import { useSchema } from "@rpforms/shared/build/hooks/useSchema";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-ui-tree/dist/react-ui-tree.css";
import styled from "styled-components";
import { FormDesigner } from "../FormDesigner";
import { FormGroupSelect } from "./FormGroupSelect";
import { TreeView } from "./TreeView";

export const JSONArea = styled.textarea`
    width: 100%;
    min-height: 600px;
    border: none;
    font-size: 14px;
    font-family: monospace;
`;

export const EditFormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    .sidebar {
        margin-right: 40px;
        &.visible {
            min-width: 300px;
            width: 300px;
        }
        transition: width ease-in-out 0.4s;
        &.hidden {
            min-width: 0;
            width: 0;
        }
        position: relative;
        .toggler {
            position: absolute;
            top: 10px;
            right: -31px;
            z-index: 10;
            background-color: #fff;
            width: 30px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 0 5px 5px 0;
        }
    }

    .fields {
        margin-top: 10px;
        width: 100%;
    }
    .preview {
        width: 100%;
    }
`;

const EditForm: any = ({ form }) => {
    const [treeView, showTreeView] = useState(true);
    const [key, setKey] = useState("editor");
    const dispatch = useDispatch();
    const { schemaBuilder, isLoading } = useSchema(form);

    const newField = async (fieldClass) => {
        if (typeof fieldClass === "function") {
            schemaBuilder.field(new fieldClass(schemaBuilder));
        } else {
            schemaBuilder.customField(fieldClass.field_data);
        }
        dispatch(
            updateForm(form, {
                form_data: await schemaBuilder.export(),
            })
        );
    };

    const newFieldBelow = (field) => {
        return async (fieldClass) => {
            if (typeof fieldClass === "function") {
                schemaBuilder.fieldBelow(new fieldClass(schemaBuilder), field);
            } else {
                schemaBuilder.fieldBelow(fieldClass.field_data, field);
            }
            dispatch(
                updateForm(form, {
                    form_data: await schemaBuilder.export(),
                })
            );
        };
    };

    const save = async (schema = schemaBuilder, { delaySave = false, notify = true }) => {
        dispatch(
            updateForm(
                form,
                {
                    form_data: schema.export(),
                },
                delaySave,
                () => {
                    // dispatch(formByID(form.id));
                    if (notify) {
                        toast.success("✌️Formular gespeichert", {
                            position: "bottom-right",
                            autoClose: 2000,
                        });
                    }
                }
            )
        );
    };

    const saveForm = async (payload) => {
        schemaBuilder.form(payload.form);
        schemaBuilder.updateFields(payload.fields);
        dispatch(
            updateForm(
                form,
                {
                    form_data: await schemaBuilder.export(),
                },
                false,
                () => {
                    toast.success("✌️Formular gespeichert", {
                        position: "bottom-right",
                        autoClose: 2000,
                    });
                }
            )
        );
    };

    const changeGroup = async (newGroup) => {
        dispatch(
            updateForm(
                form,
                {
                    form_data: await schemaBuilder.export(),
                    form_group_id: newGroup.value,
                },
                false,
                () => {
                    toast.success("✌️Formular gespeichert", {
                        position: "bottom-right",
                        autoClose: 2000,
                    });
                }
            )
        );
    };

    const designer = {
        save,
        saveForm,
        newFieldBelow,
        newField,
    };

    if (!schemaBuilder || isLoading) {
        return <Loader />;
    }

    return (
        <>
            <br />
            <Tabs id={"main"} activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey="editor" title={"Editor"}>
                    <EditFormWrapper>
                        <Sidebar className={`sidebar ${treeView ? "visible" : "hidden"}`}>
                            <TreeView
                                onChange={() =>
                                    save(schemaBuilder, {
                                        delaySave: false,
                                        notify: false,
                                    })
                                }
                                schemaBuilder={schemaBuilder}
                            />
                            <span onClick={() => showTreeView(!treeView)} className={"toggler"}>
                                <i className={`fa fa-chevron-${treeView ? "left" : "right"}`}></i>
                            </span>
                        </Sidebar>
                        <div className={"fields"}>
                            <strong>Felder</strong>
                            <FormDesigner
                                handleFormSave={saveForm.bind(this)}
                                designer={designer}
                                schema={schemaBuilder}
                            />
                        </div>
                    </EditFormWrapper>
                </Tab>
                <Tab eventKey="json" title={"JSON"}>
                    <div>
                        {schemaBuilder && (
                            <JSONArea
                                value={JSON.stringify(
                                    { ...form, ...schemaBuilder.export() },
                                    null,
                                    4
                                )}
                            ></JSONArea>
                        )}
                    </div>
                </Tab>
                <Tab eventKey={"settings"} title={"Einstellungen"}>
                    <h4>Gruppe</h4>
                    <FormGroupSelect
                        defaultValue={
                            form.form_group
                                ? { value: form.form_group.id, label: form.form_group.name }
                                : null
                        }
                        onSelect={changeGroup}
                    />
                </Tab>
            </Tabs>
        </>
    );
};

export default withRouter(EditForm);
