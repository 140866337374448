import {
    CATEGORIES_LOADING_START,
    CATEGORIES_LOADING_STOP,
    CREATE_CATEGORY,
    GET_CATEGORIES,
} from "../actions/types";

const initialState = {
    categories: [],
    category: {},
    isLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_CATEGORIES:
            return { ...state, ...action.payload };
        case CREATE_CATEGORY:
            return {
                ...state,
                category: action.payload,
                categories: [...state.categories, action.payload],
            };
        case CATEGORIES_LOADING_START:
            return { ...state, isLoading: true };
        case CATEGORIES_LOADING_STOP:
            return { ...state, isLoading: false };
    }
    return state;
}
