import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const image = "/stamp.png";

const CanvasWrapper = styled.div`
    canvas {
        touch-action: none;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
`;

const Point = function(x, y, time) {
    this.x = x;
    this.y = y;
    this.time = time || new Date().getTime();
};

Point.prototype.velocityFrom = function(start) {
    return this.time !== start.time ? this.distanceTo(start) / (this.time - start.time) : 1;
};

Point.prototype.distanceTo = function(start) {
    return Math.sqrt(Math.pow(this.x - start.x, 2) + Math.pow(this.y - start.y, 2));
};

const Bezier = function(startPoint, control1, control2, endPoint) {
    this.startPoint = startPoint;
    this.control1 = control1;
    this.control2 = control2;
    this.endPoint = endPoint;
};

// Returns approximated length.
Bezier.prototype.length = function() {
    let steps = 10,
        length = 0,
        i,
        t,
        cx,
        cy,
        px,
        py,
        xdiff,
        ydiff;

    for (i = 0; i <= steps; i++) {
        t = i / steps;
        cx = this._point(t, this.startPoint.x, this.control1.x, this.control2.x, this.endPoint.x);
        cy = this._point(t, this.startPoint.y, this.control1.y, this.control2.y, this.endPoint.y);
        if (i > 0) {
            xdiff = cx - px;
            ydiff = cy - py;
            length += Math.sqrt(xdiff * xdiff + ydiff * ydiff);
        }
        px = cx;
        py = cy;
    }
    return length;
};

Bezier.prototype._point = function(t, start, c1, c2, end) {
    return (
        start * (1.0 - t) * (1.0 - t) * (1.0 - t) +
        3.0 * c1 * (1.0 - t) * (1.0 - t) * t +
        3.0 * c2 * (1.0 - t) * t * t +
        end * t * t * t
    );
};

class SignaturePadCtrl {
    public onUpdate;

    public pointsSkippedFromBeingAdded = 0;
    private velocityFilterWeight: number;
    private minWidth: number;
    private maxWidth: number;
    private dotSize: () => number;
    private penColor: string;
    private backgroundColor: string;
    private throttle: number | ((func: () => void, wait?: number) => () => void);
    private throttleOptions: { trailing: boolean; leading: boolean };
    private minPointDistance: number;
    private onEnd: any;
    private onBegin: any;
    private ctx: any;
    private canvas: any;
    private arePointsDisplayed: boolean;
    private mouseButtonDown: boolean;
    private points: any;
    private isEmpty: boolean;
    private lastVelocity: number;
    private lastWidth: number;

    constructor(canvas, options) {
        const opts = options || {};
        this.velocityFilterWeight = opts.velocityFilterWeight || 0.7;
        this.minWidth = opts.minWidth || 0.5;
        this.maxWidth = opts.maxWidth || 2.5;
        this.dotSize = opts.dotSize || (this.minWidth + this.maxWidth) / 2;
        this.penColor = opts.penColor || "black";
        this.backgroundColor = opts.backgroundColor || "rgba(0,0,0,0)";
        this.throttle = opts.throttle || 0;
        this.throttleOptions = {
            leading: true,
            trailing: true,
        };
        this.minPointDistance = opts.minPointDistance || 0;
        this.onEnd = opts.onEnd;
        this.onBegin = opts.onBegin;

        this.canvas = canvas;
        this.ctx = canvas.getContext("2d");
        this.ctx.lineCap = "round";
        this.clear();

        this.handleMouseEvents();
        this.handleTouchEvents();
    }

    public handleMouseUp(event) {
        if (event.which === 1 && this.mouseButtonDown) {
            this.mouseButtonDown = false;
            this.strokeEnd(event);
        }
    }

    public handleTouchStart(event) {
        if (event.targetTouches.length === 1) {
            const touch = event.changedTouches[0];
            this.strokeBegin(touch);
        }
    }

    public handleTouchEnd(event) {
        const wasCanvasTouched = event.target === this.canvas;
        if (wasCanvasTouched) {
            event.preventDefault();
            this.strokeEnd(event);
        }
    }

    public handleMouseMove(event) {
        event.preventDefault();
        if (this.mouseButtonDown) {
            this.strokeUpdate(event);
            if (this.arePointsDisplayed) {
                const point = this.createPoint(event);
                this.drawMark(point.x, point.y, 5);
            }
        }
    }

    public handleMouseDown(event) {
        if (event.which === 1) {
            this.mouseButtonDown = true;
            this.strokeBegin(event);
        }
    }

    public clear() {
        const ctx = this.ctx;
        const canvas = this.canvas;

        ctx.fillStyle = this.backgroundColor;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        this.reset();
    }

    public showPointsToggle() {
        this.arePointsDisplayed = !this.arePointsDisplayed;
    }

    public toDataURL(imageType, quality) {
        const canvas = this.canvas;
        return canvas.toDataURL.apply(canvas, arguments);
    }

    public fromDataURL(dataUrl) {
        const image = new Image();
        const ratio = window.devicePixelRatio || 1;
        const width = this.canvas.width / ratio;
        const height = this.canvas.height / ratio;

        this.reset();
        image.src = dataUrl;
        image.onload = () => {
            this.ctx.drawImage(image, 0, 0, width, height);
        };
        this.isEmpty = false;
    }

    public strokeUpdate(event) {
        const point = this.createPoint(event);
        if (this.isPointToBeUsed(point)) {
            this.addPoint(point);
        }
    }

    public isPointToBeUsed(point) {
        // Simplifying, De-noise
        if (!this.minPointDistance) {
            return true;
        }

        const points = this.points;
        if (points && points.length) {
            const lastPoint = points[points.length - 1];
            if (point.distanceTo(lastPoint) < this.minPointDistance) {
                // log(++pointsSkippedFromBeingAdded);
                return false;
            }
        }
        return true;
    }

    public strokeBegin(event) {
        this.reset();
        this.strokeUpdate(event);
        if (typeof this.onBegin === "function") {
            this.onBegin(event);
        }
    }

    public strokeDraw(point) {
        const ctx = this.ctx;
        const dotSize = typeof this.dotSize === "function" ? this.dotSize() : this.dotSize;

        ctx.beginPath();
        this.drawPoint(point.x, point.y, dotSize);
        ctx.closePath();
        ctx.fill();
    }

    public strokeEnd(event) {
        const canDrawCurve = this.points.length > 2;
        const point = this.points[0];

        if (!canDrawCurve && point) {
            this.strokeDraw(point);
        }
        if (typeof this.onEnd === "function") {
            this.onEnd(event);
        }
    }

    public handleMouseEvents() {
        this.mouseButtonDown = false;

        this.canvas.addEventListener("mousedown", (event) => {
            this.handleMouseDown(event);
        });
        this.canvas.addEventListener("mousemove", (event) => {
            this.handleMouseMove(event);
        });
        document.addEventListener("mouseup", (event) => {
            this.handleMouseUp(event);
        });
    }

    public handleTouchEvents() {
        // Pass touch events to canvas element on mobile IE11 and Edge.
        this.canvas.style.msTouchAction = "none";
        this.canvas.style.touchAction = "none";

        this.canvas.addEventListener("touchstart", (event) => {
            this.handleTouchStart(event);
        });
        this.canvas.addEventListener("touchmove", (event) => {
            this.handleTouchMove(event);
        });
        this.canvas.addEventListener("touchend", (event) => {
            this.handleTouchEnd(event);
        });
    }

    public handleTouchMove(event) {
        // Prevent scrolling.
        event.preventDefault();

        const touch = event.targetTouches[0];
        this.strokeUpdate(touch);
        if (this.arePointsDisplayed) {
            const point = this.createPoint(touch);
            this.drawMark(point.x, point.y, 5);
        }
    }

    public on() {
        this.handleMouseEvents();
        this.handleTouchEvents();
    }

    public off() {
        this.canvas.removeEventListener("mousedown", this.handleMouseDown);
        this.canvas.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);

        this.canvas.removeEventListener("touchstart", this.handleTouchStart);
        this.canvas.removeEventListener("touchmove", this.handleTouchMove);
        this.canvas.removeEventListener("touchend", this.handleTouchEnd);
    }

    public reset() {
        this.points = [];
        this.lastVelocity = 0;
        this.lastWidth = (this.minWidth + this.maxWidth) / 2;
        this.isEmpty = true;
        this.ctx.fillStyle = this.penColor;
    }

    public createPoint(event) {
        const rect = this.canvas.getBoundingClientRect();
        return new Point(event.clientX - rect.left, event.clientY - rect.top, null);
    }

    public addPoint(point) {
        let points = this.points,
            c2,
            c3,
            curve,
            tmp;

        points.push(point);

        if (points.length > 2) {
            // To reduce the initial lag make it work with 3 points
            // by copying the first point to the beginning.
            if (points.length === 3) {
                points.unshift(points[0]);
            }

            tmp = this.calculateCurveControlPoints(points[0], points[1], points[2]);
            c2 = tmp.c2;
            tmp = this.calculateCurveControlPoints(points[1], points[2], points[3]);
            c3 = tmp.c1;
            curve = new Bezier(points[1], c2, c3, points[2]);
            this.addCurve(curve);

            // Remove the first element from the list,
            // so that we always have no more than 4 points in points array.
            points.shift();
        }
    }

    public calculateCurveControlPoints(s1, s2, s3) {
        const dx1 = s1.x - s2.x,
            dy1 = s1.y - s2.y,
            dx2 = s2.x - s3.x,
            dy2 = s2.y - s3.y,
            m1 = {
                x: (s1.x + s2.x) / 2.0,
                y: (s1.y + s2.y) / 2.0,
            },
            m2 = {
                x: (s2.x + s3.x) / 2.0,
                y: (s2.y + s3.y) / 2.0,
            },
            l1 = Math.sqrt(1.0 * dx1 * dx1 + dy1 * dy1),
            l2 = Math.sqrt(1.0 * dx2 * dx2 + dy2 * dy2),
            dxm = m1.x - m2.x,
            dym = m1.y - m2.y,
            k = l2 / (l1 + l2),
            cm = {
                x: m2.x + dxm * k,
                y: m2.y + dym * k,
            },
            tx = s2.x - cm.x,
            ty = s2.y - cm.y;

        return {
            c1: new Point(m1.x + tx, m1.y + ty, null),
            c2: new Point(m2.x + tx, m2.y + ty, null),
        };
    }

    public addCurve(curve) {
        let startPoint = curve.startPoint,
            endPoint = curve.endPoint,
            velocity,
            newWidth;

        velocity = endPoint.velocityFrom(startPoint);
        velocity =
            this.velocityFilterWeight * velocity +
            (1 - this.velocityFilterWeight) * this.lastVelocity;

        newWidth = this.strokeWidth(velocity);
        this.drawCurve(curve, this.lastWidth, newWidth);

        this.lastVelocity = velocity;
        this.lastWidth = newWidth;
    }

    public drawPoint(x, y, size) {
        const ctx = this.ctx;

        ctx.moveTo(x, y);
        ctx.arc(x, y, size, 0, 2 * Math.PI, false);
        this.isEmpty = false;
    }

    public drawMark(x, y, size) {
        const ctx = this.ctx;

        ctx.save();
        ctx.moveTo(x, y);
        ctx.arc(x, y, size, 0, 2 * Math.PI, false);
        ctx.fillStyle = "rgba(255, 0, 0, 0.2)";
        ctx.fill();
        ctx.restore();
    }

    public drawCurve(curve, startWidth, endWidth) {
        const widthDelta = endWidth - startWidth;
        let ctx = this.ctx,
            drawSteps,
            width,
            i,
            t,
            tt,
            ttt,
            u,
            uu,
            uuu,
            x,
            y;

        drawSteps = Math.floor(curve.length());
        ctx.beginPath();
        for (i = 0; i < drawSteps; i++) {
            // Calculate the Bezier (x, y) coordinate for this step.
            t = i / drawSteps;
            tt = t * t;
            ttt = tt * t;
            u = 1 - t;
            uu = u * u;
            uuu = uu * u;

            x = uuu * curve.startPoint.x;
            x += 3 * uu * t * curve.control1.x;
            x += 3 * u * tt * curve.control2.x;
            x += ttt * curve.endPoint.x;

            y = uuu * curve.startPoint.y;
            y += 3 * uu * t * curve.control1.y;
            y += 3 * u * tt * curve.control2.y;
            y += ttt * curve.endPoint.y;

            width = startWidth + ttt * widthDelta;
            this.drawPoint(x, y, width);
        }
        ctx.closePath();
        ctx.fill();
    }

    public strokeWidth(velocity) {
        return Math.max(this.maxWidth / (velocity + 1), this.minWidth);
    }
}

export const SignaturePad = ({
    signatureUpdated,
    preload = "",
    addStamp = false,
    smallSignature,
    target,
}) => {
    let signPad;
    const signPadEl = useRef();
    const drawStamp = (ev) => {
        if (!signPadEl.current) {
            return;
        }

        const renderingContext2d = (signPadEl as any).current.getContext("2d");
        const image = new Image();

        // TODO: stamp
        /*image.src = (window as any).STAMP_URL;
        image.onload = () => {
            renderingContext2d.globalAlpha = 0.3;
            renderingContext2d.drawImage(
                image,
                smallSignature ? 0 : 75,
                smallSignature ? 0 : 25,
                smallSignature ? 300 : 600,
                smallSignature ? 150 : 300
            );
            renderingContext2d.globalAlpha = 1.0;
        };
        ev.preventDefault();
        ev.stopPropagation();
        return false;
        */
    };

    const clearCanvas = (ev) => {
        if (!signPadEl.current) {
            return;
        }
        const renderingContext2d = (signPadEl as any).current.getContext("2d");
        renderingContext2d.clearRect(
            0,
            0,
            (signPadEl as any).current.width,
            (signPadEl as any).current.height
        );
        if (addStamp) {
            drawStamp(ev);
        }
        (document.querySelector(`[name='${target}']`) as any).value = "";
        ev.preventDefault();
        ev.stopPropagation();
        return false;
    };

    const drawPreload = () => {
        /*if (!signPadEl.current) {
            return;
        }
        const renderingContext2d = (signPadEl as any).current.getContext("2d");
        renderingContext2d.clearRect(
            0,
            0,
            (signPadEl as any).current.width,
            (signPadEl as any).current.height
        );
        const image = new Image();
        image.src = preload;
        image.onload = () => {
            renderingContext2d.drawImage(
                image,
                smallSignature ? 0 : 175,
                smallSignature ? 0 : 125,
                smallSignature ? 300 : 400,
                smallSignature ? 150 : 200
            );
        };*/
    };

    useEffect(() => {
        signPad = new SignaturePadCtrl(signPadEl.current, {
            backgroundColor: "rgba(255, 255, 255, 1)",
            penColor: "rgb(0, 0, 0)",
            velocityFilterWeight: 0.7,
            minWidth: 0.5,
            maxWidth: 2.5,
            throttle: 30, // max x milli seconds on event update, OBS! this introduces lag for event update
            minPointDistance: 3,
            onEnd: () => {
                const dataURI = (signPadEl.current as any).toDataURL();

                if (dataURI) {
                    signatureUpdated(dataURI);
                }
            },
        });

        setTimeout(() => {
            if (preload && typeof preload === "string") {
                drawPreload();
                return;
            }

            if (addStamp) {
                drawStamp(new Event("synthethic", {}));
            }
        }, 100);
    }, []);

    const [rerender, setRerender] = useState(0);
    useEffect(() => {
        (signPadEl.current as any).width = (signPadEl.current as any).getBoundingClientRect().width;
        (signPadEl.current as any).height = (signPadEl.current as any).width * 0.6;
    }, [window.innerWidth, window.innerHeight]);

    const resizeHandler = () => {
        setRerender(+new Date());
    };
    window.onresize = resizeHandler;
    return (
        <CanvasWrapper>
            <canvas
                id="signature-pad"
                style={{
                    margin: "0 auto",
                    width: "100%",
                    height: "100%",
                    display: "block",
                    backgroundImage: addStamp ? "url('" + (window as any).STAMP_URL + "')" : null,
                    backgroundColor: "rgba(255,255,255,0.7)",
                    backgroundBlendMode: "lighten",
                    backgroundSize: "100% 100%",
                }}
                ref={signPadEl}
                width={450}
                height={150}
            />

            <br />
        </CanvasWrapper>
    );
};
