import {getCategories} from "@rpforms/shared/build/actions";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelect from "react-select/creatable";

import {RootState} from "../../reducers";

export const CategorySelect = ({...props}) => {
    const categories = useSelector<RootState, any>((store) => store.categories.categories);
    const isLoading = useSelector<RootState, any>((store) => store.categories.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const toSelect = (category) => {
        return {
            value: category.id,
            label: category.name,
        };
    };

    const formatCreatingLabel = (inputValue) => {
        return `Erstelle … ${inputValue}`;
    };

    return (
        <CreatableSelect
            options={categories.map(toSelect)}
            isLoading={isLoading}
            isDisabled={isLoading}
            formatCreateLabel={formatCreatingLabel}
            {...props}
        />
    );
};
