import React, { useEffect } from "react";
import { Form, FormHeader, FormTitle } from "./FormPresenter";

export const DataPresenter = ({ hideSubmit = false, schema, layout, form, presenter }) => {
    const formID = form.name.split("_")[0];
    const formName = form.name.replace(formID + "_", "");
    (window as any).__GLOBAL_CONDITIONALS = true;
    return (
        <Form>
            <FormHeader>
                <FormTitle>{formName}</FormTitle>
                <img src={process.env.LOGO_PATH} />
            </FormHeader>
            <div className="d-flex flex-wrap">
                {schema.fields().map((field) => {
                    return (
                        <field.WrapComponent
                            layout={layout}
                            key={field.hashCode}
                            schema={schema}
                            field={field}
                            entry={null}
                            component={
                                <field.InputComponent
                                    layout={layout}
                                    register={() => {}}
                                    schema={schema}
                                    field={field}
                                    entry={null}
                                />
                            }
                        />
                    );
                })}
            </div>
        </Form>
    );
};
