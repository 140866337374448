import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { AutoFill } from "../configurables/AutoFill";
import { LabelInput } from "../inputFields/label";
import { ISubfields } from "../interfaces/ISubfields";
import { EditOptions } from "../RadioField";
import { TableRow } from "./TableRow";

export class DropdownTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Dropdown Auswahl";
    public icon = "caret-square-o-down";
    public fieldType = "Dropdown Auswahl";
    public items;
    public attributeNames = ["items"];

    public toHotColumn(): {} {
        const column = {
            type: "autocomplete",
            strict: false,
            source: this.items ? this.items : [],
            visibleRows: 4,
            trimDropdown: false,
            field: this,
        };
        return column;
    }

    public getConfigurables() {
        return [Width, AutoFill];
    }

    // noinspection JSMethodCanBeStatic
    public EditComponent({ layout, schema, field, entry = null, register, designer }) {
        const save = (items) => {
            schema.setFieldOption(field, "items", items);
            field.items = items;
        };

        return (
            <FieldWrapper register={register} field={field} schema={schema} designer={designer}>
                <div className="powerform-flex">
                    <div className="powerform-w50">
                        <label>Überschrift</label>
                        <LabelInput register={register} field={field} />
                    </div>
                </div>
                <div className="powerform-flex mt-3">
                    <div className="powerform-w100">
                        <label>Auswahlmöglichkeiten</label>
                        <br />
                        <EditOptions
                            defaultValue={field.items}
                            onChange={save}
                            parentHashcode={field.hashCode}
                        />
                    </div>
                </div>
            </FieldWrapper>
        );
    }
}
