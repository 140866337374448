import React from "react";
import { DefaultValue, Description, Hint, Required, Width } from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { HashCode } from "./configurables/HashCode";
import { TextField } from "./TextField";

export class EmailField extends TextField {
    public icon = "envelope";
    public inputType = "email";
    public fieldType = "E-mail";

    constructor(field) {
        super(field);
    }

    public getConfigurables() {
        return [HashCode, AutoFill, Conditional, Required, Description, DefaultValue, Width, Hint];
    }
}
