import React from "react";

interface IAddressRow {
    address: IAddress;
    slim: boolean;
}

export const AddressRow = ({ address, slim = false }: IAddressRow) => {
    return (
        <div>
            {!slim && (
                <div>
                    {address.address_type === "billing" ? "Rechnungsadresse" : "Sitz"} —{" "}
                    {address.contact}
                    <br />
                    {address.extension}
                    {address.billing_email && (
                        <>
                            <br /> <br />
                            {"E-Mail-Adresse für Rechnungen"} — {address.billing_email}
                            <br /> <br />
                        </>
                    )}
                </div>
            )}
            <div>{address.street}</div>
            <div>
                {address.postalcode} {address.city}
                <br />
                {address.country}
            </div>
        </div>
    );
};
