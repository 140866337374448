import {
    ADD_CUSTOM_FIELD,
    CREATE_CUSTOM_FIELD,
    DELETE_CUSTOM_FIELD,
    GET_CUSTOM_FIELDS,
    UPDATE_CUSTOM_FIELD,
} from "../actions/types";

const initialState = {
    customFields: [],
    loaded: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_CUSTOM_FIELDS:
            return { ...state, customFields: action.payload, loaded: true };

        case CREATE_CUSTOM_FIELD:
            return { ...state, customFields: [...state.customFields, action.payload] };

        case UPDATE_CUSTOM_FIELD:
            const customFields = state.customFields.map((field: any) => {
                if (field.id === action.payload.id) {
                    return action.payload;
                }
                return field;
            });
            return { ...state, customFields };

        case ADD_CUSTOM_FIELD:
            return { ...state, customFields: [...state.customFields, action.payload] };

        case DELETE_CUSTOM_FIELD:
            return {
                ...state,
                customFields: state.customFields.filter((field) => field.id !== action.payload.id),
            };
    }

    return state;
}
