import axios from "axios";
import { push } from "connected-react-router";
import { API_URL, COMPANY_ID } from "../config";
import { defaultOpts, getCollection, user } from "./actions_helper";
import { fetchFormData } from "./formData";
import {
    ADD_FORM,
    DELETE_FORM,
    EDITOR_FORM_ENTRY_FORM_CHANGED,
    FORM_DATA_LOADED,
    FORMS_AUTOCOMPLETE,
    FORMS_AUTOCOMPLETE_START,
    FORMS_SYNC,
    FORMS_SYNC_STATUS,
    UPDATE_FORM,
} from "./types";

/**
 * Error helper
 */
export function formError(CONST: string, error: any) {
    return {
        type: CONST,
        payload: error,
    };
}

export const renameForm = (form: any) => {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.patch(
            `${API_URL}/forms/${form.id}.json`,
            {
                form: {
                    name: form.name,
                },
            },
            { headers: { authorization: user().token } }
        );
        dispatch({
            type: UPDATE_FORM,
            payload: response.data,
        });
    };
};

export function updateForm(form: any, data: any, delaySave = false, cb = null) {
    return (dispatch: IDispatch<any>) => {
        if (delaySave) {
            dispatch({ type: FORM_DATA_LOADED, payload: { form, data } });
            return dispatch({
                type: UPDATE_FORM,
                payload: {
                    form: { ...form },
                    ...data,
                },
            });
        }

        axios
            .patch(
                `${API_URL}/forms/${form.id}.json`,
                {
                    form: {
                        ...data,
                        form_data: JSON.stringify(data.form_data),
                    },
                },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                dispatch({
                    type: UPDATE_FORM,
                    payload: response.data,
                });
                dispatch({ type: FORM_DATA_LOADED, payload: { form, data } });
                // TODO: remove callback
                if (cb) {
                    cb();
                }
            });
    };
}

export function getFormsAJAX(query = "") {
    return axios.get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/forms/`, {
        headers: { authorization: user().token },
    });
}

export function getEntityFormsAJAX(entityId) {
    const qs = [];

    return axios.get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/forms/?entity=${entityId}`, {
        headers: { authorization: user().token },
    });
}

export function getForms(cb = () => {}, opts = {}) {
    return getCollection("forms", opts, cb);
}

export function fetchForms(page = 1, cb: any = () => {}, opts = defaultOpts, pageSize = "all") {
    opts.page_size = pageSize;
    opts.page = page;
    return getCollection("forms", opts, cb);
}

export function fetchFormsForAutocomplete(cb = null) {
    return (dispatch) => {
        dispatch({
            type: FORMS_AUTOCOMPLETE_START,
        });
        return axios
            .get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/forms/`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: FORMS_AUTOCOMPLETE,
                    payload: response.data,
                });
                if (cb) {
                    cb(response.data);
                }
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function deleteForm(form: any) {
    return (dispatch: IDispatch<any>) => {
        axios
            .delete(`${API_URL}/forms/${form.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_FORM,
                    payload: form,
                });
            });
    };
}

export function createForm(company: any, form: any) {
    return (dispatch: IDispatch<any>) => {
        return axios
            .post(
                `${API_URL}/forms.json`,
                { form: { ...form } },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch(push("/settings/protocols/forms/edit-" + response.data.id));
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function formByID(id: number, cb = (form, formData) => {}) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        return axios
            .get(`${API_URL}/forms/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({ type: ADD_FORM, payload: response.data });
                fetchFormData(response.data, cb)(dispatch);
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function getFormForNewFormEntry(id: number, cb = (f, formData) => {}) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        return axios
            .get(`${API_URL}/forms/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: EDITOR_FORM_ENTRY_FORM_CHANGED,
                    payload: response.data,
                });
                fetchFormData(response.data, cb)(dispatch);
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function syncForms(ids: any, endpoint, devcieToken, cb = (message, statusCode) => {}) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({ type: FORMS_SYNC_STATUS, payload: true });
        return axios
            .post(
                `${API_URL}/forms/sync.json`,
                {
                    form: {
                        id: ids,
                    },
                    device_token: devcieToken,
                    endpoint,
                },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({ type: FORMS_SYNC, payload: [] });
                if (cb) {
                    cb(response.data.message, response.status);
                }
            })
            .catch((error) => {
                if (parseInt(error.status, 10) >= 500) {
                    throw error;
                } else {
                    dispatch({ type: FORMS_SYNC_STATUS, payload: false });
                    if (cb) {
                        cb(error.response.data.message, error.response.status);
                    }
                }
            });
    };
}
