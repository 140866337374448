import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SidebarWrapper = styled.div`
    width: 300px;
    background: #fff;
    border-right: 1px solid #ccc;
`;

export const Sidebar = ({ className = "", children }) => {
    return <SidebarWrapper className={className}>{children}</SidebarWrapper>;
};
