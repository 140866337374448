import {
    ADD_FORM,
    DELETE_FORM,
    FORM_CREATE,
    FORMS_AUTOCOMPLETE,
    FORMS_AUTOCOMPLETE_START,
    FORMS_LOADING_START,
    FORMS_LOADING_STOP,
    FORMS_SYNC,
    FORMS_SYNC_SELECTION,
    FORMS_SYNC_STATUS,
    GET_FORMS,
    UPDATE_FORM,
} from "../actions/types";

const initialState = {
    forms: [],
    isLoading: false,
    autocomplete: [],
    autocompleteIsLoading: false,
    syncForms: [],
    syncIsLoading: false,
    syncSelectionEnabled: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case FORMS_SYNC_SELECTION:
            return { ...state, syncSelectionEnabled: action.payload };
        case FORMS_SYNC_STATUS:
            return { ...state, syncIsLoading: action.payload };
        case FORMS_SYNC:
            return {
                ...state,
                syncForms: action.payload,
                syncIsLoading: false,
            };
        case FORMS_AUTOCOMPLETE_START:
            return { ...state, autocompleteIsLoading: true };
        case FORMS_AUTOCOMPLETE:
            return {
                ...state,
                autocomplete: action.payload,
                autocompleteIsLoading: false,
            };
        case ADD_FORM:
            return {
                ...state,
                forms: [
                    action.payload,
                    ...state.forms.filter((form: any) => form.id !== action.payload.id),
                ],
            };
        case GET_FORMS:
            return { ...state, forms: action.payload };
        case DELETE_FORM:
            return {
                ...state,
                forms: state.forms.filter((form: any) => {
                    return form.id !== action.payload.id;
                }),
            };
        case FORM_CREATE:
            return { ...state, forms: [action.payload, ...state.forms] };
        case FORMS_LOADING_START:
            return { ...state, isLoading: true };
        case FORMS_LOADING_STOP:
            return { ...state, isLoading: false };
        case UPDATE_FORM:
            const forms = state.forms.map((form: any) => {
                if (form.id === action.payload.id) {
                    return action.payload;
                }
                return form;
            });
            return { ...state, forms };
    }

    return state;
}
