import styled from "styled-components";

export const PopoverContent = styled.div`
    .popover-body {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
    }
`;

export const Scrollable = styled.div`
    overflow-x: auto;
    max-width: 350px;
    max-height: 300px;
    padding: 0 20px;
`;

export const PopoverTitle = styled.h3`
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-align: center;
`;

export default {
    PopoverContent,
    Scrollable,
    PopoverTitle,
};
