import { stripTags } from "@rpforms/shared/build/utils";
import React from "react";
import Tree from "react-ui-tree";

export const TreeView = ({ onChange, schemaBuilder, inputType = "Formular" }) => {
    const spanStyle: any = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    };

    const handleDblClick = (ev, node) => {
        ev.preventDefault();
        ev.stopPropagation();
        const element: any = document.querySelector(`.rpfe-field-${node.field.hashCode}`);
        element.scrollIntoViewIfNeeded();
        element.classList.remove("highlight");
        element.classList.add("highlight");
        return false;
    };

    const handleChange = (newStructure) => {
        schemaBuilder.applyTreeStructure(newStructure);
        onChange();
    };

    return (
        <>
            <Tree
                paddingLeft={20}
                tree={schemaBuilder.createTree()}
                renderNode={(node) => (
                    <div
                        style={{ padding: "5px 0", display: "flex", alignItems: "center" }}
                        onDoubleClick={(ev) => handleDblClick(ev, node)}
                    >
                        <i className={`fa fa-fw fa-${node.field ? node.field.icon : "folder-o"}`} />{" "}
                        <span
                            style={spanStyle}
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                                __html: node.field
                                    ? stripTags(node.field.label) || node.field.fieldType
                                    : "",
                            }}
                        />
                        {node.root && inputType}
                    </div>
                )}
                onChange={handleChange}
            />
        </>
    );
};
