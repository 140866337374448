import { toSelectOptions, toSelectOptionsWithAll } from "@rpforms/shared";
import { fetchTerms } from "@rpforms/shared/build/actions/terms";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const TermFilter = ({
    onSelect,
    value = null,
    includeAll = "tagged & untagged",
    ...props
}) => {
    const dispatch = useDispatch();
    const terms = useSelector<RootState, any>((store) => store.terms.data.terms);

    useEffect(() => {
        if ((!terms.isLoading && !terms) || terms.length <= 0) {
            dispatch(
                fetchTerms(-1, (data) => {
                    onSelect(getDefaultValue(data.terms));
                })
            );
        } else {
            onSelect(getDefaultValue(terms));
        }
    }, []);

    const getDefaultValue = (d) => {
        let dv = value;
        if (!Array.isArray(dv)) {
            dv = [dv];
        }
        const defaultValueIds = dv.filter((x) => x != null).map((x) => parseInt(x.value, 10));
        const arr = d.filter((c) => defaultValueIds.includes(c.id));
        const ret = props.isMulti ? toSelectOptions(arr) : toSelectOptionsWithAll(arr, includeAll);
        return props.isMulti ? ret : ret[0];
    };

    const onChange = (option) => {
        onSelect(option);
    };

    return (
        <Select
            onChange={onChange}
            options={
                props.isMulti ? toSelectOptions(terms) : toSelectOptionsWithAll(terms, includeAll)
            }
            value={value}
            placeholder={includeAll}
            {...props}
        />
    );
};
