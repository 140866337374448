import axios from "axios";
import { API_URL } from "../config";
import { isEmpty } from "../utils";
import { getCollection, user } from "./actions_helper";
import {
    ADD_FORM,
    FORM_ENTRIES_CREATE,
    FORM_ENTRIES_DELETED,
    FORM_ENTRIES_ENTITY_LOADED,
    FORM_ENTRIES_FORM_LOADED,
    FORM_ENTRIES_LOADING,
    FORM_ENTRIES_LOADING_STOP,
    FORM_ENTRIES_SINGLE_LOADED,
    FORM_REGENERATING_PDF,
    ZIP_REQUEST,
} from "./types";

export function getFormEntry(id: number, cb = null) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        Promise.all([
            axios.get(`${API_URL}/form_entries/${id}.json`, {
                headers: { authorization: user().token },
            }),
        ]).then((results) => {
            const data = results.map((req) => req.data);
            dispatch({
                type: FORM_ENTRIES_SINGLE_LOADED,
                payload: {
                    ...data[0],
                },
            });
            if (cb) {
                cb(data[0], data[0].form_data);
            }
        });
    };
}

export function getFormEntryForPdf(id: number, cb = null) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        Promise.all([
            axios.get(`${API_URL}/form_entries/${id}.json?pdf=1`, {
                headers: { authorization: user().token },
            }),
        ]).then((results) => {
            const data = results.map((req) => req.data);
            dispatch({
                type: FORM_ENTRIES_SINGLE_LOADED,
                payload: {
                    ...data[0],
                },
            });
            if (cb) {
                cb(data[0], data[0].form_data);
            }
        });
    };
}

export function getFormByEntry(id: number) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        return axios
            .get(`${API_URL}/form_entries/${id}/form.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({ type: ADD_FORM, payload: response.data });
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function fetchAllFormEntries(
    page = 1,
    cb: any,
    clear = false,
    opts = {
        page_size: undefined,
        page: undefined,
        hidden_fields: undefined,
        has_notifications: undefined,
    },
    pageSize = 10
) {
    opts.page_size = pageSize;
    opts.page = page;
    if (isEmpty(opts.hidden_fields)) {
        opts.hidden_fields = "form_data,uploads,latest_revision_version,latest_revision_id";
    }
    return getCollection("form_entries", opts, cb);
}

export function fetchFormFormEntries(form: any, page = 1) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .get(`${API_URL}/forms/${form.id}/form_entries.json?page=${page}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: FORM_ENTRIES_FORM_LOADED,
                    payload: { form, data: response.data },
                });
            })
            .catch((err) => {
                throw err;
            });
}

export function fetchEntityFormEntries(entity: any) {
    const qs = [];
    qs.push(`q[entity_id_eq]=${entity.id}`);
    qs.push("hidden_fields=form_data,uploads,latest_revision_version,latest_revision_id");
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .get(`${API_URL}/form_entries.json?${qs.join("&")}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: FORM_ENTRIES_ENTITY_LOADED,
                    payload: {
                        entity,
                        data: response.data,
                    },
                });
            })
            .catch((err) => {
                throw err;
            });
}

export function updateFormEntry(entry: any) {
    return (dispatch: IDispatch<any>) =>
        axios
            .patch(
                `${API_URL}/form_entries/${entry.id}.json`,
                { form_entry: entry },
                { headers: { authorization: user().token } }
            )
            .then(() => {
                getFormEntry(entry.id)(dispatch);
            })
            .catch((err) => {
                throw err;
            });
}

export function updateFormEntryFrontend(
    id,
    formData,
    formEntryData,
    mode,
    cb = (formEntry) => {},
    catcher = (err) => {}
) {
    return (dispatch: IDispatch<any>) =>
        axios
            .post(
                `${API_URL}/form_entries/${id}/edit.json`,
                {
                    form_data: formData,
                    form_entry: { ...formEntryData },
                    mode,
                },
                {
                    headers: {
                        authorization: user().token,
                    },
                }
            )
            .then((res) => {
                cb(res.data);
            })
            .catch((err) => {
                catcher(err);
            });
}

export function deleteUpload(upload: any, cb = (data) => {}) {
    return () =>
        axios
            .delete(`${API_URL}/uploads/${upload.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                if (cb) {
                    cb(response);
                }
            });
}

export function deleteFormEntry(entry: any, cb = () => {}) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/form_entries/${entry.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: FORM_ENTRIES_DELETED,
                    payload: {
                        entry,
                        data: response.data,
                    },
                });
                cb();
            })
            .catch((err) => {
                throw err;
            });
}

export const regeneratePDF = (id) => {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({
            type: FORM_REGENERATING_PDF,
            payload: id,
        });
        axios
            .post(
                `${API_URL}/form_entries/${id}/regenerate.json`,
                {},
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                const data = response.data;
                dispatch({
                    type: FORM_ENTRIES_SINGLE_LOADED,
                    payload: {
                        ...data[0],
                    },
                });
                dispatch({
                    type: FORM_REGENERATING_PDF,
                    payload: false,
                });
            })
            .catch((err) => {
                alert(err);
                dispatch({
                    type: FORM_REGENERATING_PDF,
                    payload: false,
                });
            });
    };
};

export function fetchFormEntriesAJAX(
    entityId,
    year,
    formIds,
    opts = {
        showData: false,
        page_size: 999,
        page: 1,
        order_by: "created_at",
        order_dir: "asc",
    }
) {
    if (!opts.page_size) {
        opts.page_size = 999;
    }
    if (!opts.page) {
        opts.page = 1;
    }
    if (!opts.order_by) {
        opts.order_by = "created_at";
    }
    if (!opts.order_dir) {
        opts.order_dir = "asc";
    }

    const qs = [];
    if (entityId) {
        qs.push(`q[entity_id_eq]=${entityId}`);
    }
    if (formIds.length > 0) {
        qs.push(formIds.map((formId) => `q[form_id_in][]=${formId}`).join("&"));
    }
    if (year) {
        qs.push(`q[dated_at_gt]=${year}-01-01&q[dated_at_lt]=${year}-12-31`);
    }

    if (opts.showData) {
        qs.push(`show_data=true`);
    } else {
        qs.push("hidden_fields=form_data,uploads,latest_revision_version,latest_revision_id");
    }

    return axios.get(
        `${API_URL}/form_entries.json?${qs.join("&")}&page_size=${opts.page_size}&page=${
            opts.page
        }&order_by=${opts.order_by}&order_dir=${opts.order_dir}`,
        {
            headers: { authorization: user().token },
        }
    );
}

export function createFormEntry(
    formEntryPayload: any,
    cb = (formEntry) => {},
    catcher = (formEntry) => {}
) {
    return (dispatch: IDispatch<IReduxAction<any>>) => {
        dispatch({ type: FORM_ENTRIES_LOADING });
        const url = `${API_URL}/forms/${formEntryPayload.form_id}/form_entries.json?source=web`;
        return axios
            .post(
                url,
                { form_entry: formEntryPayload },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: FORM_ENTRIES_CREATE,
                    payload: {
                        data: response.data,
                    },
                });
                cb(response.data);
                dispatch({ type: FORM_ENTRIES_LOADING_STOP });
            })
            .catch((err) => {
                dispatch({ type: FORM_ENTRIES_LOADING_STOP });
                catcher(err);
            });
    };
}

export function updateFormEntryStatus(id, name, terms = null) {
    return (dispatch: IDispatch<any>) => {
        axios
            .put(
                `${API_URL}/form_entries/${id}/event.json`,
                { name, terms },
                { headers: { authorization: user().token } }
            )
            .then(() => {
                getFormEntry(id)(dispatch);
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function downloadZIPRequest(ids) {
    return (dispatch: IDispatch<any>) => {
        axios
            .post(
                `${API_URL}/form_entries/zip`,
                { id: ids },
                { headers: { authorization: user().token } }
            )
            .then((response) => {
                dispatch({
                    type: ZIP_REQUEST,
                    payload: {
                        message: response.data.message,
                    },
                });
            })
            .catch((err) => {
                throw err;
            });
    };
}
