import React, { useEffect, useRef, useState } from "react";
import { FieldWrapper } from "../components/FieldWrapper";
import { template } from "../utils";
import { BaseField } from "./BaseField";
import {
    DefaultValue,
    Description,
    Hint,
    MaxLength,
    ReadOnly,
    Required,
    Unique,
    Width,
} from "./configurables";
import { AutoFill } from "./configurables/AutoFill";
import { Conditional } from "./configurables/Conditional";
import { KeepExisting } from "./configurables/KeepExisting";
import { NonMigratable } from "./configurables/NonMigratable";
import { NonTemplatable } from "./configurables/NonTemplatable";
import { Label, LabelInput } from "./inputFields/label";

export class TextField extends BaseField {
    public name = "";
    public label = "";
    public icon = "asterisk";
    public fieldType = "Texteingabe";

    public getConfigurables() {
        return [
            AutoFill,
            KeepExisting,
            NonMigratable,
            NonTemplatable,
            Conditional,
            MaxLength,
            Required,
            Description,
            DefaultValue,
            Width,
            Hint,
            ReadOnly,
        ];
    }

    /**
     * Renders the field in edit mode
     * @param designer
     * @param schema
     * @param register Registers the input field in the global form submission
     * @param field
     */
    public EditComponent({ designer, schema, register, field, provided = null }) {
        return (
            <FieldWrapper
                register={register}
                provided={provided}
                field={field}
                schema={schema}
                designer={designer}
            >
                <div>
                    <LabelInput register={register} field={field} />
                </div>
            </FieldWrapper>
        );
    }

    /**
     * Renders the component to input data in it.
     * @param layout
     * @param schema
     * @param field
     * @param entry
     * @param register
     * @param name
     */
    public InputComponent({
        layout,
        schema,
        field,
        entry = null,
        register,
        name,
        rawValue,
        variables = {},
        readonly = false,
    }: any) {
        if (!name) {
            name = field.hashCode;
        }

        if (rawValue === null) {
            rawValue = "";
        }

        if (typeof rawValue === "object") {
            rawValue = "";
        }

        const optionReadonly = field.rawOptions.readonly;

        const inEditMode = (window as any).EDIT_MODE;
        const hasAutofill = field.rawOptions.autofill;
        const required = field.rawOptions.required;
        const hasValue = !!rawValue;
        const keepExisting = field.rawOptions.keepexisting;

        if (inEditMode && hasAutofill) {
            if (!hasValue) {
                rawValue = template(field.rawOptions.autofill, variables, field.formName);
            }

            if (hasValue && !keepExisting) {
                rawValue = template(field.rawOptions.autofill, variables, field.formName);
            }
        }

        const inputAttributes = () => {
            if (optionReadonly) {
                return {
                    value: rawValue,
                };
            }
            return {
                "data-autofill-value": rawValue ? rawValue : "",
                defaultValue: rawValue ? rawValue : "",
            };
        };

        return (
            <>
                {layout.wrapLabel(field.configureLabel(<Label layout={layout} field={field} />))}
                {layout.wrapDescription(
                    field.rawOptions.description ? <p>{field.rawOptions.description}</p> : <></>
                )}
                {rawValue && !(window as any).EDIT_MODE && typeof rawValue !== "object" && (
                    <p
                        style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                        }}
                    >
                        {rawValue}
                    </p>
                )}

                {(!rawValue || (window as any).EDIT_MODE) &&
                    layout.wrapInput(
                        field.configureInput(
                            <input
                                onKeyUp={(ev) => {
                                    const lastValue = ev.currentTarget.getAttribute(
                                        "data-last-value"
                                    );
                                    ev.currentTarget.setAttribute(
                                        "data-last-value",
                                        ev.currentTarget.value
                                    );
                                    if (
                                        ev.keyCode === 8 &&
                                        ev.currentTarget.value === "" &&
                                        lastValue === ""
                                    ) {
                                        // back and empty
                                        ev.currentTarget.value = ev.currentTarget.getAttribute(
                                            "data-autofill-value"
                                        );
                                    }
                                }}
                                readOnly={readonly || optionReadonly}
                                type="text"
                                name={name}
                                data-name={readonly ? "" : name}
                                required={required}
                                className="form-control hidden-print"
                                {...inputAttributes()}
                            />,
                            register,
                            schema
                        )
                    )}
                {!rawValue && <div className={"visible-print"}>-</div>}
                {layout.wrapHint(field.rawOptions.hint ? <p>{field.rawOptions.hint}</p> : <></>)}
            </>
        );
    }

    public PDFComponent({ field, styles }) {
        return <></>;
    }
}
