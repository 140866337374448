import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchFolders } from "@rpforms/shared";

export const useFetchFolders = ({ search, hierarchy }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchFolders({
                parentId: false,
            })
        );
    }, []);

    useEffect(() => {
        hierarchy.forEach((hierarchyFolder) => {
            if (hierarchyFolder?.id) {
                dispatch(
                    fetchFolders({
                        ...hierarchyFolder,
                    })
                );
            }
        });
    }, [hierarchy]);
};
