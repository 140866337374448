import { fetchFormGroups } from "@rpforms/shared";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const FormGroupSelect = ({ onSelect, defaultValue = null }) => {
    const formGroups = useSelector<RootState, any>((store) => store.formGroups.items);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFormGroups());
    }, []);

    const toSelect = (group) => {
        return {
            value: group.id,
            label: group.name,
        };
    };

    return (
        <Select
            onChange={onSelect}
            defaultValue={defaultValue}
            options={formGroups.map(toSelect)}
        ></Select>
    );
};
