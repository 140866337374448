import { fetchFolders, NEW_UPLOAD_INFO, PrimaryButton } from "@rpforms/shared";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import React, { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const ModalUpload = ({ editor, next }) => {
    const ALLOWED_MIME_TYPES = [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
    ];

    const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10MB
    const [upload, setUpload] = useState<any>(false);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        setUploading(true);
        const uploadData = { ...editor.config.preload, name: upload.name };
        await next({}, (payload) => {
            // transform payload before transmitting
            const formData = new FormData();
            formData.append("file", file);
            formData.append("data", JSON.stringify(uploadData));
            return formData;
        });
        setUploading(false);
        toast.success("Datei hochgeladen");
        if (editor.config.preload.folder) {
            dispatch(fetchFolders(editor.config.preload.folder));
        }
        dispatch(
            openEditor({
                type: NEW_UPLOAD_INFO,
            })
        );
    };

    const processUpload = (ev) => {
        const { files } = ev.target;
        const reader = new FileReader();

        const uploadedFile = files[0];

        reader.onload = (ev) => {
            const { result: preview } = ev.target;
            setUpload({
                name: uploadedFile.name,
                size: uploadedFile.size,
                type: uploadedFile.type,
                preview: uploadedFile.type.indexOf("image/") === 0 ? preview : false,
            });
        };

        reader.onerror = () => {
            setUpload({
                name: uploadedFile.name,
                size: uploadedFile.size,
                type: uploadedFile.type,
                preview: false,
            });
        };
        setFile(uploadedFile);
        reader.readAsDataURL(uploadedFile);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{editor.config.preload.title || "Neue Datei erstellen"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <label>Name</label>
                        <input
                            className={"form-control"}
                            type={"file"}
                            name="name"
                            onChange={processUpload}
                            ref={register({
                                required: "Datei muss ausgewählt sein",
                            })}
                        />
                        {errors.name && errors.name.message}
                    </Col>
                </Row>
                {upload && (
                    <>
                        <br />
                        {upload.size > MAX_FILE_SIZE && (
                            <Alert variant={"danger"}>
                                Diese Datei ist zu groß und kann nicht hochgeladen werden.
                            </Alert>
                        )}
                        {!ALLOWED_MIME_TYPES.includes(upload.type) && (
                            <Alert variant={"danger"}>
                                Nur PDFs und Bilder werden unterstützt.
                            </Alert>
                        )}
                        <Row>
                            <Col>
                                {upload.preview && (
                                    <>
                                        <img
                                            src={upload.preview}
                                            style={{
                                                maxWidth: "100px",
                                                marginRight: "10px",
                                            }}
                                        />{" "}
                                    </>
                                )}
                                {upload.name}
                            </Col>
                        </Row>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton
                    disabled={
                        !ALLOWED_MIME_TYPES.includes(upload.type) ||
                        upload.size > MAX_FILE_SIZE ||
                        uploading
                    }
                    type={"submit"}
                >
                    Hochladen
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
