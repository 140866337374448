import {
    cancelRequest,
    fetchAllFormEntries,
    fetchFormsForAutocomplete,
    isEmpty,
    toSelectOptions,
    toStr,
} from "@rpforms/shared";
import * as actions from "@rpforms/shared/build/actions/formEntries";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { EmptySpace } from "@rpforms/shared/build/components/universal/EmptySpace";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { range } from "@rpforms/shared/build/utils";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Pagination, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useSearchParam } from "react-use";
import styled from "styled-components";
import { CustomerFilter } from "../../components/entries/CustomerFilter";
import { DeviceFilter } from "../../components/entries/DeviceFilter";
import { EntityFilter } from "../../components/entries/EntityFilter";
import EntryRow from "../../components/entries/EntryRow";
import { FormFilter } from "../../components/entries/FormFilter";
import NotificationsFilter from "../../components/entries/NotificationsFilter";
import { StateFilter } from "../../components/entries/StateFilter";
import { TermFilter } from "../../components/entries/TermFilter";
import { RootState } from "../../reducers";

const Centered = styled.div`
    display: flex;
    justify-content: center;
`;

const AllEntriesPage = () => {
    const formEntries = useSelector<RootState, any>((rootState) => rootState.formEntries);
    const entitiesDB = useSelector<RootState, any>((rootState) => rootState.entities);
    const [formsDB, setFormsDB] = useState([]);

    const pageParam = useSearchParam("page");
    const pageSizeParam = useSearchParam("page_size");

    const idsParams = useSearchParam("ids");
    const fromParam = useSearchParam("from");
    const untilParam = useSearchParam("until");
    const devicesParam = useSearchParam("device_ids");
    const formParam = useSearchParam("form_ids");
    const stateParam = useSearchParam("states");
    const hasNotificationsParam = useSearchParam("has_notifications");
    const customerParam = useSearchParam("customer_ids");
    const entityParam = useSearchParam("entity_ids");
    const termsParam = useSearchParam("term_ids");

    const field = useSearchParam("field") || "";
    const fieldValue = useSearchParam("fieldValue") || null;
    const fieldComparator = useSearchParam("fieldComparator") || null;

    useEffect(() => {
        dispatch(
            fetchFormsForAutocomplete((data) => {
                setFormsDB(data);
            })
        );
    }, []);

    const getInitialParameter = (p, n, select = true) => {
        const sp = select ? toSelectOptions(toStr(p).split(",")) : p;
        if (!isEmpty(sp)) {
            return sp;
        }
        try {
            const ret = JSON.parse(sessionStorage.getItem(n));
            if (ret == null && ["form_entries.from", "form_entries.until"].includes(n)) {
                return "";
            }
            return ret;
        } catch (e) {
            if (["form_entries.from", "form_entries.until"].includes(n)) {
                return "";
            }
            return null;
        }
    };

    const setInitialParameter = (n, v) => {
        sessionStorage.setItem(n, JSON.stringify(v));
    };

    const unsetInitialParameters = () => {
        sessionStorage.removeItem("form_entries.from");
        sessionStorage.removeItem("form_entries.until");
        sessionStorage.removeItem("form_entries.devices");
        sessionStorage.removeItem("form_entries.forms");
        sessionStorage.removeItem("form_entries.has_notifications");
        sessionStorage.removeItem("form_entries.states");
        sessionStorage.removeItem("form_entries.customers");
        sessionStorage.removeItem("form_entries.entities");
        sessionStorage.removeItem("form_entries.terms");
    };

    const initialPageSize = parseInt(pageSizeParam, 10) || 10;
    const initialPage = parseInt(pageParam, 10) || 1;
    const [page, setPage] = useState(getInitialParameter(initialPage, "form_entries.page", false));
    const [pageSize, setPageSize] = useState(
        getInitialParameter(initialPageSize, "form_entries.page_size", false)
    );

    const [ids, setIds] = useState(getInitialParameter(idsParams, "form_entries.ids", false));
    const [from, setFrom] = useState(getInitialParameter(fromParam, "form_entries.from", false));
    const [until, setUntil] = useState(
        getInitialParameter(untilParam, "form_entries.until", false)
    );
    const [devices, setDevices] = useState(
        getInitialParameter(devicesParam, "form_entries.devices")
    );
    const [forms, setForms] = useState(getInitialParameter(formParam, "form_entries.forms"));
    const [states, setStates] = useState(getInitialParameter(stateParam, "form_entries.states"));
    const [hasNotifications, setHasNotifications] = useState(
        !!getInitialParameter(hasNotificationsParam, "form_entries.has_notifications", false)
    );
    const [customers, setCustomers] = useState(
        getInitialParameter(customerParam, "form_entries.customers")
    );
    const [entities, setEntities] = useState(
        getInitialParameter(entityParam, "form_entries.entities")
    );
    const [terms, setTerms] = useState(getInitialParameter(termsParam, "form_entries.terms"));

    const dispatch = useDispatch();

    const [currentUrl, setCurrentUrl] = useState("/entries");

    const [resetted, setResetted] = useState(false);

    useEffect(() => {
        if (resetted) {
            setResetted((prevState) => false);
            return;
        }
        const opts = {
            hidden_fields:
                "disposal_sales_price,disposal_sales_price_formatted,repair_sales_price,repair_sales_price_formatted,service_sales_price,service_sales_price_formatted,total_sales_price,total_sales_price_formatted,form_data,uploads,latest_revision_version,latest_revision_id",
        };
        let url = `/entries?page=${page}&page_size=${pageSize}`;
        if (ids && ids.length > 0) {
            const idsArr = ids.split(",").filter((x) => x != null);
            url += `&ids=${idsArr.join(",")}`;
            opts["q[id_in][]"] = idsArr;
        }
        if (from) {
            url += `&from=${from}`;
            opts["q[created_at_gteq]"] = from;
        }
        if (until) {
            url += `&from=${until}`;
            opts["q[created_at_lteq]"] = `${until} 23:59:59`;
        }
        if (devices && devices.length > 0) {
            const deviceIds = devices.map((d) => d.value);
            url += `&device_ids=${deviceIds.join(",")}`;
            if (deviceIds.length === 1 && deviceIds[0] === "-1") {
                opts["q[device_id_blank]"] = true;
            } else {
                opts["q[device_id_in][]"] = deviceIds;
            }
        }
        if (forms && forms.length > 0) {
            const formIds = forms.map((d) => d.value);
            url += `&form_ids=${formIds.join(",")}`;
            opts["q[form_id_in][]"] = formIds;
        }
        if (states && states.length > 0) {
            const stateIds = states.map((d) => d.value);
            url += `&states=${stateIds.join(",")}`;
            opts["state_in[]"] = stateIds;
        }
        if (customers && customers.length > 0) {
            const customerIds = customers.map((d) => d.value);
            url += `&customer_ids=${customerIds.join(",")}`;
            opts["q[customer_id_in][]"] = customerIds;
        }
        if (entities && entities.length > 0) {
            const entityIds = entities.map((d) => d.value);
            url += `&entity_ids=${entityIds.join(",")}`;
            opts["q[entity_id_in][]"] = entityIds;
        }
        if (hasNotifications) {
            url += `&has_notifications=${hasNotifications}`;
            opts.has_notifications = true;
        }
        if (terms && terms.length > 0) {
            const termIds = terms.map((d) => d.value);
            url += `&term_ids=${termIds.join(",")}`;
            opts["term_id_in[]"] = termIds;
        }
        if (field) {
            url += `&field=${field}`;
            opts.field = field;
            if (fieldValue) {
                url += `&fieldValue=${fieldValue}`;
                opts.fieldValue = fieldValue;
                if (!fieldComparator) {
                    url += `&fieldComparator=eq`;
                    opts.fieldComparator = "eq";
                }
            } else {
                if (!fieldComparator) {
                    url += `&fieldComparator=exists`;
                    opts.fieldComparator = "exists";
                }
            }
            if (fieldComparator) {
                url += `&fieldComparator=${fieldComparator}`;
                opts.fieldComparator = fieldComparator;
            }
        }
        if (currentUrl !== url) {
            setCurrentUrl(url);
            dispatch(push(url));
            dispatch(fetchAllFormEntries(page, () => {}, true, opts, pageSize));
        }
    }, [
        ids,
        page,
        pageSize,
        from,
        until,
        devices,
        forms,
        states,
        hasNotifications,
        customers,
        entities,
        terms,
        resetted,
    ]);

    const openPage = (newPage) => {
        setPage(newPage);
    };

    const pages = () => {
        const pagesOffset = 5;
        return [1, ...range(Math.max(2, page - pagesOffset), page + pagesOffset)];
    };

    const resetFilter = () => {
        setResetted((prevState) => {
            unsetInitialParameters();
            setIds(null);
            setFrom("");
            setUntil("");
            setDevices(null);
            setForms(null);
            setHasNotifications(false);
            setStates(null);
            setCustomers(null);
            setEntities(null);
            setTerms(null);
            return true;
        });
    };

    return (
        <>
            <PageNavbar
                title="Protokolle"
                description="Hier können Sie übertragene Formulare verwalten."
            >
                {!formEntries.isLoading && (
                    <>
                        <Container>
                            <Row
                                style={{
                                    alignItems: "flex-end",
                                    width: "600px",
                                }}
                            ></Row>
                        </Container>
                        <PageNavAction
                            onClick={() => {
                                const offerFormId = parseInt(
                                    window.location.href.includes("staging") ||
                                        window.location.href.includes("localhost")
                                        ? "26"
                                        : "55",
                                    10
                                );

                                if (formsDB.length === 0) {
                                    return;
                                }

                                setForms([
                                    {
                                        value: offerFormId,
                                        label: formsDB.find((r) => r.id === offerFormId).name,
                                    },
                                ]);
                                setStates([{ value: "accepted", label: "Akzeptiert" }]);
                            }}
                            primary={false}
                            icon="check"
                            title="Akzeptierte Angebote"
                        />
                        <PageNavAction
                            link="/entries/editor"
                            primary={true}
                            icon="plus"
                            title="Protokoll erstellen"
                        />
                    </>
                )}
            </PageNavbar>

            <div>
                <Content>
                    <Row style={{ paddingBottom: "20px" }}>
                        <Col>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <label
                                    style={{ minWidth: "20%", paddingTop: "6px" }}
                                    htmlFor={"date-from"}
                                >
                                    Von:
                                </label>
                                <input
                                    style={{ width: "auto" }}
                                    id={"date-from"}
                                    type={"date"}
                                    name={"created_at.gte"}
                                    className="form-control"
                                    onInput={(v) => {
                                        setInitialParameter(
                                            "form_entries.from",
                                            (v.target as any).value
                                        );
                                        setFrom((v.target as any).value);
                                    }}
                                    onChange={() => {}}
                                    value={from}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <label
                                    style={{ minWidth: "20%", paddingTop: "6px" }}
                                    htmlFor={"date-until"}
                                >
                                    Bis:
                                </label>
                                <input
                                    style={{ width: "auto" }}
                                    id={"date-until"}
                                    type={"date"}
                                    name={"created_at.lteq"}
                                    className="form-control"
                                    onInput={(v) => {
                                        setInitialParameter(
                                            "form_entries.until",
                                            (v.target as any).value
                                        );
                                        setUntil((v.target as any).value);
                                    }}
                                    onChange={() => {}}
                                    value={until}
                                />
                            </div>
                        </Col>
                        <Col>
                            <DeviceFilter
                                onSelect={(v) => {
                                    setInitialParameter("form_entries.devices", v);
                                    setDevices(v);
                                }}
                                value={devices}
                                isMulti
                                isSearchable
                            ></DeviceFilter>
                        </Col>
                        <Col>
                            <FormFilter
                                onSelect={(v) => {
                                    setInitialParameter("form_entries.forms", v);
                                    setForms(v);
                                }}
                                value={forms}
                                isMulti
                                isSearchable
                            />
                            <NotificationsFilter
                                onCheck={(e) => {
                                    const hasN = e.target.checked;
                                    setInitialParameter("form_entries.has_notifications", hasN);
                                    setHasNotifications(hasN);
                                }}
                                value={hasNotifications}
                            />
                        </Col>
                        <Col>
                            <StateFilter
                                onSelect={(v) => {
                                    setInitialParameter("form_entries.states", v);
                                    setStates(v);
                                }}
                                value={states}
                                isMulti
                                isSearchable
                            />
                        </Col>
                        <Col>
                            <CustomerFilter
                                onSelect={(v) => {
                                    setInitialParameter("form_entries.customers", v);
                                    setCustomers(v);
                                }}
                                value={customers}
                                isMulti
                                isSearchable
                            />
                            <EntityFilter
                                onSelect={(v) => {
                                    setInitialParameter("form_entries.entities", v);
                                    setEntities(v);
                                }}
                                optionsFilter={(entityOptions) => {
                                    if (isEmpty(customers)) {
                                        return entityOptions;
                                    }
                                    const customerIds = customers.map((c) => parseInt(c.value, 10));
                                    return entityOptions.filter((o) => {
                                        const entity = entitiesDB.autocomplete.find(
                                            (e) => e.id === o.value
                                        );
                                        return (
                                            entity &&
                                            customerIds.includes(parseInt(entity.customer_id, 10))
                                        );
                                    });
                                }}
                                value={entities}
                                isMulti
                                isSearchable
                            />
                        </Col>
                        <Col>
                            <TermFilter
                                onSelect={(v) => {
                                    setInitialParameter("form_entries.terms", v);
                                    setTerms(v);
                                }}
                                value={terms}
                                isMulti
                                isSearchable
                            />
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                            <Button onClick={resetFilter}>Filter zurücksetzen</Button>
                        </Col>
                    </Row>
                    {formEntries.isLoading && <Loader />}
                    {!formEntries.isLoading && formEntries.list.length > 0 && (
                        <div>
                            {formEntries.list.map((entry) => {
                                return <EntryRow entry={entry} key={entry.id} />;
                            })}
                        </div>
                    )}
                </Content>
            </div>

            {!formEntries.isLoading && formEntries.list.length === 0 && (
                <Content>
                    <EmptySpace
                        title={"Keine Protokolle gefunden."}
                        description={
                            "Übertrage ein ausgefülltes Formular mit einem iPad damit es hier erscheint."
                        }
                        cta={false}
                    />
                </Content>
            )}
            <div>
                <Centered>
                    <Pagination>
                        {pages().map((p) => {
                            return (
                                <Pagination.Item
                                    active={p === page}
                                    key={`page_${p}`}
                                    onClick={() => openPage(p)}
                                >
                                    {p}
                                </Pagination.Item>
                            );
                        })}
                    </Pagination>
                </Centered>
            </div>
        </>
    );
};

function mapStateToProps(state) {
    return { device: state.device, formEntries: state.formEntries };
}

export default withRouter(connect(mapStateToProps, actions)(AllEntriesPage));
