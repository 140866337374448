import axios from "axios";
import React from "react";
import { FieldWrapper } from "../../components/FieldWrapper";
import { Width } from "../configurables";
import { ISubfields } from "../interfaces/ISubfields";
import { TableRow } from "./TableRow";

export class AutoCompleteTableRow extends TableRow implements ISubfields {
    public subfields = [];
    public label = "Text mit Vorschlägen";
    public icon = "magic";
    public fieldType = "Text mit Vorschlägen";
    public items;
    public attributeNames = ["dataSource"];

    public toHotColumn(): {} {
        return {
            type: "autocomplete",
            visibleRows: 4,
            trimDropdown: false,
            source(query, process) {
                return axios.post(`${this.dataSource}`, { query }).then((req) => {
                    process(req.data);
                });
            },
            strict: true,
        };
    }

    public getConfigurables() {
        return [Width];
    }

    public EditComponent({ schema, designer, field, entry = null, register }) {
        return (
            <FieldWrapper designer={designer} schema={schema} field={field}>
                <label>Überschrift</label>
                <input
                    type={field.designerInputType}
                    name={field.inputName("label")}
                    className="form-control"
                    defaultValue={field.label}
                    ref={register}
                />
                <br />
                <label>Datenquelle</label>
                <input
                    type={"text"}
                    name={field.inputName("dataSource")}
                    className="form-control"
                    defaultValue={field.dataSource}
                    ref={register}
                />
                <span className={"hint"}>
                    Geben Sie hier den Link zu einer Datenquelle im JSON Format an.
                </span>
            </FieldWrapper>
        );
    }
}
