import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import companyReducer from "./companyReducer";
import customerReducer from "./customerReducer";
import customFieldsReducer from "./customFieldsReducer";
import deviceReducer from "./deviceReducer";
import directoryReducer from "./directoryReducer";
import editorReducer from "./editorReducer";
import formDataReducer from "./formDataReducer";
import formEntriesReducer from "./formEntriesReducer";
import formGroupReducer from "./formGroupReducer";
import formReducer from "./formReducer";
import inventoryItemReducer from "./inventoryItemReducer";
import manufacturerReducer from "./manufacturerReducer";
import notificationGroupReducer from "./notificationGroupReducer";
import permissionsReducer from "./permissionsReducer";
import searchReducer from "./searchReducer";
import statesReducer from "./statesReducer";
import termsReducer from "./termsReducer";
import userReducer from "./userReducer";
import usersReducer from "./usersReducer";
import timeTrackingReducer from "./timeTrackingReducer";
export default {
    permissionsReducer,
    authReducer,
    companyReducer,
    customerReducer,
    customFieldsReducer,
    deviceReducer,
    directoryReducer,
    formGroupReducer,
    editorReducer,
    formEntriesReducer,
    formReducer,
    searchReducer,
    userReducer,
    usersReducer,
    formDataReducer,
    inventoryItemReducer,
    manufacturerReducer,
    categoryReducer,
    notificationGroupReducer,
    termsReducer,
    statesReducer,
    timeTrackingReducer,
};
