import React, { useState, useMemo } from "react";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Sidebar } from "@rpforms/shared/build/components/layout/Sidebar";
import { TreeView } from "../../components/forms/TreeView";
import { EditFormWrapper } from "../../components/forms/EditForm";
import AsyncSelect from "react-select/async";
import { FormDesigner } from "../../components/FormDesigner";
import ICONS from "@rpforms/shared/build/icons";
import CustomFieldDesigner from "./CustomFieldDesigner";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";

export const SetFieldData = ({
    name,
    setName,
    icon,
    setIcon,
    fieldData,
    setFieldData,
    schemaBuilder,
}) => {
    const [jsonAreaDisabled, setJsonAreaDisabled] = useState(true);

    const save = async (schema = schemaBuilder) => {
        setFieldData(await schema.export().fields);
    };

    const newFieldBelow = (field) => {
        return async (fieldClass) => {
            if (typeof fieldClass === "function") {
                schemaBuilder.fieldBelow(new fieldClass(schemaBuilder), field);
            } else {
                schemaBuilder.fieldBelow(fieldClass.field_data, field);
            }
            setFieldData(await schemaBuilder.export().fields);
        };
    };

    const newField = async (fieldClass) => {
        if (typeof fieldClass === "function") {
            schemaBuilder.field(new fieldClass(schemaBuilder));
        } else {
            schemaBuilder.customField(fieldClass.field_data);
        }
        setFieldData(await schemaBuilder.export().fields);
    };

    const updateFieldChange = async (payload) => {
        await schemaBuilder.updateFields(payload.fields);
        setFieldData(await schemaBuilder.export().fields);
    };

    const designer = {
        save,
        updateFieldChange,
        newFieldBelow,
        newField,
    };

    const promiseOptions = (inputValue: string) =>
        new Promise((resolve) => {
            resolve(
                ICONS.map((n) => {
                    return {
                        value: n,
                        label: n,
                    };
                }).filter((i) => i.label.includes(inputValue.toLowerCase()))
            );
        });

    const formatOptionLabel = ({ value, label }) => (
        <div style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
            <i className={`fa fa-${value} fa-fw`}></i>
            <div>{label}</div>
        </div>
    );

    return (
        <Content>
            <h3 className="ml-1 mb-3">Name</h3>
            <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                className={"form-control mb-4"}
            />

            <h3 className="ml-1 mb-3">Symbol</h3>
            <AsyncSelect
                formatOptionLabel={formatOptionLabel}
                value={icon}
                onChange={(n) => setIcon(n.value)}
                placeholder={icon.length > 0 ? icon : "Select..."}
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                className="mb-4"
            />
            <div className="row d-flex align-items-baseline">
                <h3 className="col-5  ml-1 mb-3">Daten als JSON</h3>
                <div className="col-4 offset-2 d-flex form-check form-switch">
                    <input
                        className="form-check-input"
                        onChange={() => setJsonAreaDisabled(!jsonAreaDisabled)}
                        type="checkbox"
                        id="switchJsonArea"
                    />
                    <label className="form-check-label"> JSON direkt bearbeitbar</label>
                </div>
            </div>
            <textarea
                disabled={jsonAreaDisabled}
                value={fieldData.length > 0 ? JSON.stringify(fieldData, null, 4) : null}
                onChange={(e) => setFieldData(JSON.parse(e.target.value))}
                className={"mb-4 w-100"}
                style={{ height: "200px" }}
            />
            <h3 className="ml-1 mb-3">Daten als Felder (nur ein Feld auf der obersten Ebene)</h3>
            <EditFormWrapper>
                <Sidebar>
                    <TreeView
                        inputType="Eigenes Feld"
                        onChange={() => {
                            save(schemaBuilder);
                        }}
                        schemaBuilder={schemaBuilder}
                    />
                </Sidebar>
                <div className={"fields"}>
                    <CustomFieldDesigner
                        designer={designer}
                        schema={schemaBuilder}
                        handleFieldSave={updateFieldChange}
                    />
                </div>
            </EditFormWrapper>
        </Content>
    );
};

export default SetFieldData;
