import { SmallPrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import { RightAligned } from "@rpforms/shared/build/components/universal/PageNavbar";
import React from "react";
import { Link, Route } from "react-router-dom";
import styled from "styled-components";
import { ListRow } from "../forms/FormListRow";
import FormEntries from "./FormEntries";

export const FormName = styled.div`
    font-weight: bold;
    display: flex;
`;

const FormEntriesRow = ({ form }: any) => {
    return (
        <>
            <ListRow>
                <FormName>{form.name}</FormName>
                <RightAligned>
                    <Link to={`/entries/forms/form-${form.id}`}>
                        <SmallPrimaryButton>Einträge anzeigen</SmallPrimaryButton>
                    </Link>
                </RightAligned>
            </ListRow>
            <Route
                path={`/entries/forms/form-${form.id}`}
                render={() => <FormEntries form={form}></FormEntries>}
            />
        </>
    );
};

export default FormEntriesRow;
