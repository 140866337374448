import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { defaultOpts, getCollection, user } from "./actions_helper";
import {
    ADD_CUSTOMER,
    CREATE_CUSTOMER,
    CUSTOMERS_AUTOCOMPLETE,
    CUSTOMERS_AUTOCOMPLETE_START,
    CUSTOMERS_LOADING_START,
    CUSTOMERS_LOADING_STOP,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_ADDRESS,
    SET_CUSTOMERS,
    UPDATE_CUSTOMER,
} from "./types";

export function getCustomersAJAX(query: string) {
    return axios.get(
        `${API_URL}/companies/${COMPANY_ID}/customers.json?query=${encodeURIComponent(query)}`,
        {
            headers: { authorization: user().token },
        }
    );
}

export function deleteCustomer(customer: any, cb = (data) => {}) {
    return (dispatch: IDispatch<IReduxAction<any>>) =>
        axios
            .delete(`${API_URL}/companies/${COMPANY_ID}/customers/${customer.id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    payload: response.data,
                    type: DELETE_CUSTOMER,
                });
                cb(response.data);
            });
}

export function createCustomer(customer: any) {
    return async (dispatch: IDispatch<any>) => {
        try {
            const response = await axios.post(
                `${API_URL}/companies/${COMPANY_ID}/customers.json`,
                {
                    customer: { ...customer, company_id: COMPANY_ID },
                },
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                payload: response.data,
                type: CREATE_CUSTOMER,
            });
        } catch (err) {
            throw err;
        }
    };
}

export function updateCustomer(customer: any) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.patch(
            `${API_URL}/companies/${COMPANY_ID}/customers/${customer.id}.json`,
            {
                customer,
            },
            {
                headers: {
                    authorization: user().token,
                },
            }
        );
        dispatch({
            payload: response.data,
            type: UPDATE_CUSTOMER,
        });
    };
}

export function fetchCustomer(id: number): any {
    return async (dispatch: IDispatch<any>) => {
        try {
            dispatch({
                type: CUSTOMERS_LOADING_START,
            });
            const response = await axios.get(
                `${API_URL}/companies/${COMPANY_ID}/customers/${id}.json`,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                payload: response.data,
                type: ADD_CUSTOMER,
            });
            return response.data;
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: CUSTOMERS_LOADING_STOP,
            });
        }
    };
}

export function deleteCustomerAddress(address: IAddress) {
    return async (dispatch: IDispatch<any>) => {
        const response = await axios.delete(
            `${API_URL}/companies/${COMPANY_ID}/customers/${address.customer_id}/addresses/${address.id}.json`,
            {
                headers: { authorization: user().token },
            }
        );
        dispatch({
            payload: address,
            type: DELETE_CUSTOMER_ADDRESS,
        });
        return response.data;
    };
}

export function fetchCustomers(page = 1, cb: any = () => {}, opts = defaultOpts, pageSize = "all") {
    opts.page_size = pageSize;
    opts.page = page;
    return getCollection("customers", opts, cb);
}

export function fetchCustomersForAutocomplete(data = { with: "" }, cb = null) {
    return (dispatch) => {
        dispatch({
            type: CUSTOMERS_AUTOCOMPLETE_START,
        });
        return axios
            .get(`${API_URL}/companies/${COMPANY_ID}/autocomplete/customers/?with=${data.with}`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: CUSTOMERS_AUTOCOMPLETE,
                    payload: response.data,
                });
                if (cb) {
                    cb(response.data);
                }
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    };
}
