import { folderNameRegex, isEmpty, mergeFolders } from "@rpforms/shared";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import useForm from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../reducers";
import { FolderIcon } from "./FolderIcon";

const StyledModalTitle = styled(Modal.Title)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    font-size: 16px !important;
    width: 100%;
    // align svg icons with fonts
    svg {
        position: relative;
        top: -2px;
    }
`;

export const FolderMerger = ({ folder, setVisibility }) => {
    const { selection, selectionEnabled } = useSelector<RootState, any>((state) => state.directory);
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, errors } = useForm();
    const [newName, setNewName] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isEmpty(selection)) {
            setNewName(selection[0].name);
        }
    }, [selection]);

    const merge = async () => {
        setIsLoading(true);
        const folderIds = selection.map((f) => f.id);
        const targetFolderId = folderIds[0];
        await dispatch(
            mergeFolders({
                folder_ids: folderIds,
                target_folder_id: targetFolderId,
                name: newName,
            })
        );
        setIsLoading(false);
        setVisibility(false);
        window.location.reload(true);
    };

    return (
        <Modal
            show={folder}
            onHide={() => setVisibility(false)}
            size={"lg"}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <StyledModalTitle>
                    <div>
                        Zusammenführen von {selection.length} <FolderIcon height={16} width={16} />{" "}
                        Ordnern
                    </div>
                </StyledModalTitle>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
                <Row style={{ padding: "10px 20px" }}>
                    <Col>Zusammengeführt werden: {selection.map((f) => f.name).join(", ")}</Col>
                </Row>
                <Row style={{ padding: "20px" }}>
                    <Col>
                        <label>Neuer Name</label>
                        <input
                            value={newName}
                            className={"form-control"}
                            name="name"
                            ref={register({
                                required: "Name muss ausgefüllt sein",
                                pattern: {
                                    value: folderNameRegex(),
                                    message: "Name muss mit Buchstabe oder Zahl enden",
                                },
                            })}
                            onChange={(ev) => {
                                setNewName(ev.currentTarget.value);
                            }}
                        />
                        {errors.name && <InputError>{errors.name.message}</InputError>}
                    </Col>
                </Row>
                <div style={{ padding: "20px" }}>
                    {isLoading && <>speichert …</>}
                    {newName && (
                        <PrimaryButton onClick={merge} disabled={isLoading}>
                            Zusammenführen
                        </PrimaryButton>
                    )}
                    <br />
                </div>
            </Modal.Body>
        </Modal>
    );
};
