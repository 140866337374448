// @ts-ignore
import React, { forwardRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AppearanceBox } from "../fields/configurables/fields/AppearanceBox";
import { AppearanceDescription } from "../fields/configurables/fields/AppearanceDescription";
import { AppearanceLabel } from "../fields/configurables/fields/AppearanceLabel";

export const FieldAppearance: any = forwardRef(
    ({ hide, fieldAppearance, designer, schema, field }: any, ref) => {
        const [configurables, setConfigurables] = useState(false);
        const { handleSubmit, register, errors }: any = useForm();

        if (!configurables) {
            const newConfigurables = field.getConfigurables().map((configurable) => {
                // setup configurable
                const configurableField = new configurable({
                    register,
                    designer,
                    schema,
                    field,
                });
                return configurableField;
            });
            setConfigurables(newConfigurables);
            return;
        }

        const onSubmit = (value) => {
            // allow fields to transform the value before saving
            (configurables as any).forEach((configurableField) => {
                if (value.hasOwnProperty(configurableField.keyName)) {
                    configurableField.value = value[configurableField.keyName];
                }
                schema.setFieldOption(
                    field,
                    configurableField.keyName,
                    configurableField.sleepValue(configurableField.value)
                );
            });

            designer.save(schema, { silent: false, notify: false });
            hide();
            return false;
        };

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-body">
                    {(configurables as any).map((configurableField) => {
                        return (
                            <AppearanceBox key={field.appearanceName(configurableField.keyName)}>
                                <AppearanceLabel>{configurableField.title}</AppearanceLabel>
                                <AppearanceDescription>
                                    {configurableField.description}
                                </AppearanceDescription>
                                {configurableField.EditComponent({
                                    register,
                                    schema,
                                    designer,
                                    field,
                                })}
                            </AppearanceBox>
                        );
                    })}
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-primary">
                        Speichern
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={hide}
                        data-dismiss="modal"
                    >
                        Schließen
                    </button>
                </div>
            </form>
        );
    }
);
