import { getRevenues } from "@rpforms/shared";
import { isEmpty } from "lodash";
import { default as React, useEffect } from "react";
import { Col, Container, Row, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import FinancesCustomerDetails from "../../components/finances/FinancesCustomerDetails";
import FinancesDateSelector from "../../components/finances/FinancesDateSelector";
import FinancesFilter from "../../components/finances/FinancesFilter";
import { RootState } from "../../reducers";

interface TitleProps {
    small?: boolean;
    medium?: boolean;
}

interface AmountProps {
    small?: boolean;
    medium?: boolean;
}

export const TitleContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const Title = styled.h3<TitleProps>`
    margin: 0;
    padding: 0;
    font-size: ${(props) => (props.small ? "18px" : props.medium ? "24px" : "32px")};
`;

export const BigAmount = styled.h3<AmountProps>`
    display: inline-block;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: ${(props) => (props.small ? "16px" : props.medium ? "24px" : "28px")};
    color: ${(props) => (props.color ? props.color : "inherit")};
`;

export const TotalOverview = ({ maxDate, setMaxDate, yesterday, today }) => {
    const dispatch = useDispatch();

    // Immediately load total revenue
    useEffect(() => {
        dispatch(
            getRevenues(
                null,
                null,
                null,
                new Date().getFullYear(),
                null,
                null,
                "invoices",
                null,
                null
            )
        );
    }, []);

    return (
        <Container fluid>
            <Row style={{ marginTop: "2rem", alignItems: "flex-end" }}>
                <Col>
                    <FinancesFilter />
                </Col>
                <Col lg={4}>
                    <FinancesDateSelector />
                </Col>
            </Row>
            <FinancesCustomerDetails maxDate={maxDate} type={"invoice"} />
        </Container>
    );
};
