import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import EditFieldPage from "./EditFieldPage";
import ListFieldPage from "./ListFieldPage";
import NewFieldPage from "./NewFieldPage";

const CustomFieldsPage = () => {
    return (
        <Switch>
            <Route path="/settings/protocols/custom_fields/new" exact component={NewFieldPage} />
            <Route path="/settings/protocols/custom_fields/" exact component={ListFieldPage} />
            <Route path="/settings/protocols/custom_fields/:id" component={EditFieldPage} />
        </Switch>
    );
};

export default withRouter(CustomFieldsPage);
