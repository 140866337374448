import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CustomerSelect } from "../customers/select";
import { EntitySelect } from "../entities/select";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "../../reducers";
import { onUpdateCustomer, onUpdateEntity, onUpdateNet } from "@rpforms/shared";

const StyledContainer = styled(Container)`
    position: relative;

    .reset-customers {
        position: absolute;
        top: 65px;
        left: 0;

        button {
            border: none;
            background-color: transparent;
            font-size: 14px;
        }
    }
`;

export default function FinancesFilter() {
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const customers = useSelector<RootState, any>((state) => state.customers.autocomplete);
    const entities = useSelector<RootState, any>((state) => state.entities.autocomplete);
    const { customer, entity, isNet } = useSelector<RootState, any>((state) => state.finances);
    const dispatch = useDispatch();

    const onCustomerSelect = (option) => {
        if (!option.value) {
            dispatch(onUpdateCustomer(null));
            return;
        }
        setSelectedCustomerId(option.value);
        const customer = customers.find((c) => c.id === option.value);
        dispatch(onUpdateCustomer(customer));
    };

    const onEntitySelect = (option) => {
        if (!option.value) {
            dispatch(onUpdateEntity(null));
            return;
        }
        const entity = entities.find((e) => e.id === option.value);
        dispatch(onUpdateEntity(entity));
    };

    const onChangeIsNet = (isNet) => {
        dispatch(onUpdateNet(isNet));
    };

    return (
        <StyledContainer fluid>
            <Row>
                <Col>
                    <Row>
                        <Col style={{ minWidth: "250px" }}>
                            Kunde: <CustomerSelect onSelect={onCustomerSelect} />
                        </Col>

                        <Col style={{ minWidth: "250px" }}>
                            Objekt:
                            <EntitySelect
                                filterBy={(e) => (customer ? e.customer_id === customer.id : true)}
                                entityValue={entity}
                                onSelect={onEntitySelect}
                                defaultValue={entity}
                            />
                        </Col>
                        <Col style={{ minWidth: "150px" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                }}
                            >
                                <label>Nettopreise anzeigen?</label>
                                <input
                                    type="checkbox"
                                    checked={isNet}
                                    onChange={(e) => onChangeIsNet(e.target.checked)}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {customer && (
                <div className="reset-customers">
                    <button onClick={() => window.location.reload()}>Auswahl aufheben</button>
                </div>
            )}
        </StyledContainer>
    );
}
