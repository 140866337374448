import React from "react";
import { Configurable } from "./Configurable";
import { AppearanceCheckbox } from "./fields/AppearanceCheckbox";

/**
 * Applies maximum length to input field
 */
export class IsChecked extends Configurable {
    public schema;
    public designer;
    public field;
    public defaultValue = false;

    public keyName = "auto_checked";
    public title = "Vorausgefüllt?";
    public description = "Soll das Feld bereits im Vorfeld ausgefüllt werden?";
    public value = "";

    public EditComponent({
        register,
        schema,
        designer,
        field,
    }: {
        register: any;
        schema: any;
        designer: any;
        field: any;
    }): any {
        return (
            <AppearanceCheckbox
                label="Vorausgefüllt"
                register={register}
                value={field.rawOptions[this.keyName]}
                placeholder={this.title}
                field={field}
                keyName={this.keyName}
            />
        );
    }

    public sleepValue(value): any {
        return value !== false;
    }

    public wakeValue(value) {
        return value || value === "true";
    }
}
