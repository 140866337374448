import {
    fetchEntitiesForAutocomplete,
    toSelectOptions,
    toSelectOptionsWithAll,
} from "@rpforms/shared";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../reducers";

export const EntityFilter = ({
    onSelect,
    value,
    includeAll = "alle Objekte",
    optionsFilter = (options) => {
        return options;
    },
    ...props
}) => {
    const dispatch = useDispatch();
    const entities = useSelector<RootState, any>((store) => store.entities);

    useEffect(() => {
        if (entities.autocompleteIsLoading) {
            return;
        }
        if (!entities.autocomplete || entities.autocomplete.length <= 0) {
            dispatch(
                fetchEntitiesForAutocomplete((data) => {
                    onSelect(getDefaultValue(data));
                })
            );
        } else {
            onSelect(getDefaultValue(entities.autocomplete));
        }
    }, []);

    const getDefaultValue = (d) => {
        let dv = value;
        if (!Array.isArray(dv)) {
            dv = [dv];
        }
        const defaultValueIds = dv.filter((x) => x != null).map((x) => parseInt(x.value, 10));
        const arr = d.filter((c) => defaultValueIds.includes(c.id));
        const ret = props.isMulti ? toSelectOptions(arr) : toSelectOptionsWithAll(arr, includeAll);
        return props.isMulti ? ret : ret[0];
    };

    const onChange = (option) => {
        onSelect(option);
    };

    return (
        <Select
            onChange={onChange}
            options={
                props.isMulti
                    ? optionsFilter(toSelectOptions(entities.autocomplete))
                    : optionsFilter(toSelectOptionsWithAll(entities.autocomplete, includeAll))
            }
            value={value}
            placeholder={includeAll}
            {...props}
        />
    );
};
