import { PrimaryButton } from "@rpforms/shared/build/components/layout/Button";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useForm from "react-hook-form";
import { useSelector } from "react-redux";
import { EntitySelect } from "../entities/select";

interface IModalAddress {
    editor: IEditor;
    next: (payload) => {};
}

export const ModalFormEntry = ({ editor, next }: IModalAddress) => {
    const [override, setOverride] = useState(false);
    const [entity, setEntity] = useState();
    const entities = useSelector<any>((state) => state.entities.entities);

    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = (data) => {
        next({ ...editor.config.preload, entity, override });
    };

    const changeEntity = (selectedEntity) => {
        setEntity(entities.find((e) => e.id === selectedEntity.value));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>Protokoll erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Objekt
                <EntitySelect onSelect={changeEntity}></EntitySelect>
                <label htmlFor={"override"}>
                    <input
                        id={"override"}
                        type={"checkbox"}
                        name={"override"}
                        value={override}
                        onChange={() => setOverride(!override)}
                    />{" "}
                    Altes Protokoll überschreiben
                </label>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton type={"submit"}>{"Speichern"}</PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
