import {
    fetchCustomersForAutocomplete,
    fetchEntitiesForAutocomplete,
    fetchFormsForAutocomplete,
    FORM_ENTRIES_SET_MIGRATION_PANES,
    formByID,
    getFormEntry,
} from "@rpforms/shared";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import {
    doNothing,
    findInFields,
    htmlId,
    template,
    templateVariables,
} from "@rpforms/shared/build/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../reducers";
import FormEntryFilter from "../entries/FormEntryFilter";
import { FormRenderer } from "../FormRenderer";

export const MigratorPane = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    > div {
        margin: 20px;
        width: 100%;
        h2 {
            font-size: 16px;
            text-align: center;
        }
    }

    overflow: auto !important;
`;

export const Pane = styled.div`
    min-width: 780px;
    margin-right: 50px;
    form {
        width: 100% !important;
    }
`;
export const PaneHeader = styled.div`
    height: 150px;
    text-align: center;
`;

export const MigrationPane = ({ migrationPaneIndex }) => {
    const migrationPanes = useSelector<RootState, any>((state) => state.formEntries.migrationPanes);
    const entities = useSelector<RootState, any>((state) => state.entities);
    const forms = useSelector<RootState, any>((state) => state.forms);
    const customers = useSelector<RootState, any>((state) => state.customers);
    const paneData = useSelector<any, any>(
        (state) => state.formEntries.migrationPanes[migrationPaneIndex]
    );
    const [cachedFormId, setCachedFormId] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (paneData?.form_entry?.form_id) {
            setCachedFormId(paneData?.form_entry?.form_id);
        }
        if (
            !customers.autocompleteIsLoading &&
            (!customers.autocomplete || customers.autocomplete.length <= 0)
        ) {
            dispatch(fetchCustomersForAutocomplete());
        }
        if (
            !entities.autocompleteIsLoading &&
            (!entities.autocomplete || entities.autocomplete.length <= 0)
        ) {
            dispatch(fetchEntitiesForAutocomplete());
        }
        if (
            !forms.autocompleteIsLoading &&
            (!forms.autocomplete || forms.autocomplete.length <= 0)
        ) {
            dispatch(fetchFormsForAutocomplete());
        }
    }, [paneData]);

    const setFormEntryData = (data) => {
        const newPaneDatas = migrationPanes.map((p, i) => {
            if (i === migrationPaneIndex) {
                return {
                    ...p,
                    form_entry: { ...p.form_entry, ...data },
                    isHidden: false,
                };
            }
            return p;
        });
        dispatch({
            type: FORM_ENTRIES_SET_MIGRATION_PANES,
            payload: newPaneDatas,
        });
    };

    const onUpdateCustomer = (option) => {
        const customer = customers.autocomplete.find((c) => c.id === option.value);
        setFormEntryData({
            ...paneData.form_entry,
            customer_id: option.value,
            entity_id: null,
            entity: null,
            id: null,
            form_entry_id: null,
            form_id: null,
            form: null,
            form_revision_id: null,
            form_revision: {
                form_data: {},
            },
            name: "bitte wählen",
            customer,
        });
    };

    const onUpdateEntity = (option) => {
        const entity = entities.autocomplete.find((e) => e.id === option.value);
        setFormEntryData({
            ...paneData.form_entry,
            entity_id: option.value,
            entity,
        });
        // only for template page
        dispatch(
            formByID(cachedFormId, (f, formData) => {
                if (isNew()) {
                    setFormEntryData({
                        ...paneData.form_entry,
                        entity_id: option.value,
                        entity,
                        form_id: formData.id,
                        form: formData,
                        form_revision_id: formData.latest_revision.id,
                        form_revision: {
                            ...paneData.form_entry.form_revision,
                            form_data: formData.latest_revision.form_data,
                        },
                        name: formData.name,
                    });
                } else {
                    setFormEntryData({
                        ...paneData.form_entry,
                        entity_id: option.value,
                        entity,
                        form_id: formData.id,
                        form: formData,
                        form_revision_id: formData.latest_revision.id,
                        form_revision: {
                            ...paneData.form_entry.form_revision,
                            form_data: formData.latest_revision.form_data,
                        },
                        id: null,
                        name: formData.name,
                    });
                }
            })
        );
    };

    const onUpdateForm = (option) => {
        if (option.value > 0) {
            const newPaneDatas = migrationPanes.map((p, i) => {
                if (i === migrationPaneIndex) {
                    return {
                        ...p,
                        form_entry: { ...p.form_entry, form_id: option.value },
                        isHidden: true,
                    };
                }
                return p;
            });
            dispatch({
                type: FORM_ENTRIES_SET_MIGRATION_PANES,
                payload: newPaneDatas,
            });

            dispatch(
                formByID(option.value, (f, formData) => {
                    if (isNew()) {
                        setFormEntryData({
                            ...paneData.form_entry,
                            form_id: formData.id,
                            form: formData,
                            form_revision_id: formData.latest_revision.id,
                            form_revision: {
                                ...paneData.form_entry.form_revision,
                                form_data: formData.latest_revision.form_data,
                            },
                            name: formData.name,
                        });
                    } else {
                        setFormEntryData({
                            ...paneData.form_entry,
                            form_id: formData.id,
                            form: formData,
                            form_revision_id: formData.latest_revision.id,
                            form_revision: {
                                ...paneData.form_entry.form_revision,
                                form_data: formData.latest_revision.form_data,
                            },
                            id: null,
                            name: formData.name,
                        });
                    }
                })
            );
        } else {
            if (!isNew()) {
                setFormEntryData({
                    ...paneData.form_entry,
                    form_id: null,
                    form_revision_id: null,
                    form_revision: {
                        form_data: {},
                    },
                    name: "bitte wählen",
                    form_data: {},
                });
            }
        }
    };

    const onUpdateFormEntry = (option) => {
        setFormEntryData({});
        if (option.value && option.value > 0) {
            dispatch(
                getFormEntry(option.value, (fe) => {
                    setFormEntryData({ ...paneData.form_entry, ...fe });
                })
            );
        }
    };

    const isNew = () => {
        return paneData.form_entry && paneData.form_entry.id == null;
    };

    const isLoading = () => {
        return (
            !paneData ||
            paneData.isHidden ||
            !paneData.form_entry ||
            !paneData.form_entry.form_data ||
            !paneData.form_entry.customer_id
        );
    };

    const formEntryName = (snapshot) => {
        if (typeof paneData.form_entry.form_revision.form_data === "string") {
            try {
                const fields = JSON.parse(paneData.form_entry.form_revision.form_data);
                const configField = findInFields(fields.fields, (f) => {
                    return f.type === "ConfigField";
                });
                if (configField && configField.overrideName) {
                    return template(
                        configField.overridePattern,
                        templateVariables(paneData.form_entry),
                        htmlId(paneData.form_entry)
                    );
                }
            } catch (e) {
                console.log(e);
            }
        }
        return paneData.form_entry.name;
    };

    const updatePaneData = (snapshot) => {
        setFormEntryData({ form_data: snapshot, name: formEntryName(snapshot) });
    };

    return (
        <Pane>
            <PaneHeader>
                {!isLoading() && (
                    <FormEntryFilter
                        onUpdateCustomer={onUpdateCustomer}
                        onUpdateEntity={onUpdateEntity}
                        onUpdateForm={onUpdateForm}
                        onUpdateFormEntry={onUpdateFormEntry}
                        formIncludeAllOption={!isNew()}
                        hideFormEntrySelector={true}
                        hideTermsCheckbox={isNew()}
                        formEntry={paneData.form_entry}
                        customerAndEntity={isNew() ? "editable" : ""}
                        formFilter={(form) => {
                            if (paneData?.source_form_entry != null) {
                                return (
                                    form?.form_group?.folder_structure_type ===
                                    paneData?.source_form_entry.form?.form_group
                                        ?.folder_structure_type
                                );
                            }
                            return true;
                        }}
                    />
                )}
            </PaneHeader>
            {!isLoading() && (
                <FormRenderer
                    onSubmit={doNothing}
                    onChange={updatePaneData}
                    name={paneData.form_entry.name}
                    formEntry={paneData.form_entry}
                    readonly={!isNew()}
                />
            )}
            {isLoading() && <Loader />}
        </Pane>
    );
};
