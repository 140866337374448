import {
    ADD_FOLDER_TO_SELECTION,
    ADD_FOLDERS,
    CLEAR_FOLDERS,
    CREATE_FOLDER_NOTE,
    FOLDERS_LOADING_START,
    FOLDERS_LOADING_STOP,
    FOLDERS_SUBTREE_LOADING_START,
    FOLDERS_SUBTREE_LOADING_STOP,
    FOLDERS_TREE_LOADING_START,
    FOLDERS_TREE_LOADING_STOP,
    REMOVE_FOLDER_FROM_SELECTION,
    SEARCH_FOLDERS,
    SEARCH_FOLDERS_LOADING_START,
    SEARCH_FOLDERS_LOADING_STOP,
    SEARCH_FOLDERS_QUERY,
    SET_DIRECTORY,
    SET_FOLDER_SELECTION,
    SET_FOLDER_SELECTION_ITEMS,
    SET_SUBTREE,
    SET_TEMPLATES,
    SET_TREE,
    UPDATE_FOLDER_NOTE,
} from "../actions/types";

const initialState = {
    directory: {},
    folders: [],
    search: {
        query: "",
        scope: "folder",
        results: false,
        searching: false,
    },
    selection: [],
    selectionEnabled: false,
    searching: false,
    templates: [],
    isLoading: false,
    treeIsLoading: false,
    subTreeIsLoading: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case SET_TEMPLATES:
            return {
                ...state,
                templates: action.payload,
            };

        case SET_FOLDER_SELECTION:
            return {
                ...state,
                selectionEnabled: action.payload,
            };

        case SET_FOLDER_SELECTION_ITEMS:
            return {
                ...state,
                selection: action.payload,
            };

        case ADD_FOLDER_TO_SELECTION:
            return {
                ...state,
                selection: [...state.selection, action.payload],
            };

        case REMOVE_FOLDER_FROM_SELECTION:
            return {
                ...state,
                selection: [...state.selection].filter((f: any) => f.id !== action.payload.id),
            };

        case SET_DIRECTORY:
            return {
                ...state,
                directory: { ...state.directory, ...action.payload },
            };

        case SEARCH_FOLDERS_LOADING_START:
            return {
                ...state,
                search: {
                    ...state.search,
                    searching: false,
                },
            };

        case SEARCH_FOLDERS_LOADING_STOP:
            return {
                ...state,
                search: {
                    ...state.search,
                    searching: false,
                },
            };

        case SEARCH_FOLDERS_QUERY:
            return {
                ...state,
                search: {
                    ...state.search,
                    query: action.payload.query,
                    scope: action.payload.scope,
                },
            };

        case SEARCH_FOLDERS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: action.payload,
                },
            };
        case CREATE_FOLDER_NOTE:
            return {
                ...state,
                folders: state.folders.map((folder: any) => {
                    if (folder.id === action.payload.folder_id) {
                        const newNote = {
                            ...action.payload,
                        };
                        return { ...folder, folder_notes: [...folder.folder_notes, newNote] };
                    }
                    return folder;
                }),
            };
        case UPDATE_FOLDER_NOTE:
            return {
                ...state,
                folders: state.folders.map((folder: any) => {
                    if (folder.id === action.payload.folder_id) {
                        const existingNote = {
                            ...action.payload,
                        };
                        return {
                            ...folder,
                            folder_notes: [...folder.folder_notes].map((folder_note: any) =>
                                folder_note.id === existingNote.id ? existingNote : folder_note
                            ),
                        };
                    }
                    return folder;
                }),
            };
        case SET_TREE:
            return { ...state, tree: action.payload };
        case SET_SUBTREE:
            return { ...state, subTree: action.payload };

        case CLEAR_FOLDERS:
            return { ...state, folders: [] };

        case ADD_FOLDERS:
            const folders = [...state.folders].map((folder: any) => {
                const willBeUpdated = action.payload.find((f: any) => f.id === folder.id);
                if (willBeUpdated) {
                    return { ...folder, ...willBeUpdated };
                }
                return folder;
            });

            // add remaining folders
            action.payload.forEach((folder: any) => {
                if (!folders.find((f) => f.id === folder.id)) {
                    folders.push(folder);
                }
            });

            return {
                ...state,
                folders,
            };

        case FOLDERS_LOADING_START:
            return {
                ...state,
                isLoading: true,
            };

        case FOLDERS_LOADING_STOP:
            return {
                ...state,
                isLoading: false,
            };
        case FOLDERS_TREE_LOADING_START:
            return { ...state, treeIsLoading: true };
        case FOLDERS_TREE_LOADING_STOP:
            return { ...state, treeIsLoading: false };
        case FOLDERS_SUBTREE_LOADING_START:
            return { ...state, subTreeIsLoading: true };
        case FOLDERS_SUBTREE_LOADING_STOP:
            return { ...state, subTreeIsLoading: false };
    }

    return state;
}
