import axios from "axios";
import { API_URL } from "../config";
import { user } from "./actions_helper";
import { CREATE_FOLDER_NOTE, DELETE_FOLDER_NOTE, UPDATE_FOLDER_NOTE } from "./types";

interface IFolderNote {
    id?: number;
    folderId: number;
    title: string;
    content: string;
}

export const createFolderNote = ({ folderId, title, content }: IFolderNote) => {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        const response = await axios
            .post(
                `${API_URL}/folder_notes.json`,
                { folder_id: folderId, title, content },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: CREATE_FOLDER_NOTE,
                    payload: response.data,
                });
                return response;
            });

        return response.data;
    };
};

export const updateFolderNote = ({ id, title, content }: IFolderNote) => {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        const response = await axios
            .put(
                `${API_URL}/folder_notes/${id}.json`,
                { id, title, content },
                {
                    headers: { authorization: user().token },
                }
            )
            .then((response) => {
                dispatch({
                    type: UPDATE_FOLDER_NOTE,
                    payload: response.data,
                });
                return response;
            });

        return response.data;
    };
};

export const deleteFolderNote = (id: number) => {
    return async (dispatch: IDispatch<IReduxAction<JSON>>) => {
        const response = await axios
            .delete(`${API_URL}/folder_notes/${id}.json`, {
                headers: { authorization: user().token },
            })
            .then((response) => {
                dispatch({
                    type: DELETE_FOLDER_NOTE,
                    payload: response.data,
                });
                return response;
            });

        return response.data;
    };
};
