import axios from "axios";
import { API_URL, COMPANY_ID } from "../config";
import { user } from "./actions_helper";
import {
    SEARCH_LOADING_START,
    SEARCH_LOADING_STOP,
    SET_SEARCH_QUERY,
    SET_SEARCH_RESULTS,
} from "./types";

export function search(query: string) {
    return async (dispatch: IDispatch<any>) => {
        dispatch({
            type: SEARCH_LOADING_START,
        });
        dispatch({
            type: SET_SEARCH_QUERY,
            payload: query,
        });
        try {
            const timestamp = +new Date();

            const result = await axios.get(
                API_URL + "/companies/" + COMPANY_ID + "/search.json?query=" + query,
                {
                    headers: { authorization: user().token },
                }
            );
            dispatch({
                type: SET_SEARCH_RESULTS,
                payload: { ...result.data, timestamp },
            });
        } catch (err) {
            throw err;
        } finally {
            dispatch({
                type: SEARCH_LOADING_STOP,
            });
        }
    };
}
