import {
    CREATE_TIME_TRACKING,
    DELETE_TIME_TRACKING,
    GET_TIME_TRACKING,
    TIME_TRACKING_LOADING_START,
    TIME_TRACKING_LOADING_STOP,
    UPDATE_TIME_TRACKING,
} from "../actions/types";

const initialState = {
    timeTrackings: [],
    isLoading: false,
    isEditing: false,
};

export default function (state = initialState, action: IReduxAction<any>) {
    switch (action.type) {
        case GET_TIME_TRACKING:
            return { ...state, timeTrackings: [...action.payload] };
        case UPDATE_TIME_TRACKING:
            return {
                ...state,
                timeTrackings: state.timeTrackings.map((tt) => {
                    if (tt.id === action.payload.id) {
                        return action.payload;
                    }

                    return tt;
                }),
            };
        case CREATE_TIME_TRACKING:
            return { ...state, timeTrackings: [...state.timeTrackings, action.payload] };
        case DELETE_TIME_TRACKING:
            return {
                ...state,
                timeTrackings: state.timeTrackings.filter((e: any) => {
                    return parseInt(e.id, 10) !== parseInt(action.payload.id, 10);
                }),
            };
        case TIME_TRACKING_LOADING_STOP:
            return { ...state, isLoading: false };
        case TIME_TRACKING_LOADING_START:
            return { ...state, isLoading: true };
    }

    return state;
}
