import {
    fetchFormGroups,
    toSelectOptions,
    updateFormGroup
} from "@rpforms/shared";
import {Loader} from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction
    from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import pako from "pako";
import React, {useEffect, useMemo, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {connect, useDispatch, useSelector} from "react-redux";
import {useParams, withRouter} from "react-router-dom";
import Select from "react-select";
import {toast} from "react-toastify";
import {RootState} from "../../reducers";

export const EditFormGroupPage = () => {
    const {id} = useParams();
    const formGroup = useSelector<RootState, any>((state) =>
        state.formGroups.items.find((fg) => fg.id === parseInt(id, 10))
    );
    const dispatch = useDispatch();

    const [inputData, setInputData] = useState(null);
    const [possibleEventsStr, setPossibleEventsStr] = useState("");
    const [imgSrc, setImgSrc] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!formGroup) {
            dispatch(fetchFormGroups());
        } else {
            setInputData(formGroup);
            setPossibleEventsStr(formGroup.possible_events.join("\n"));
        }
    }, []);

    useEffect(() => {
        if (formGroup != null) {
            setInputData(formGroup);
            setPossibleEventsStr(formGroup.possible_events.join("\n"));
            setIsLoading(false);
        }
    }, [formGroup]);

    useEffect(() => {
        if (inputData != null) {
            setInputData({
                ...inputData,
                possible_events: possibleEventsStr.split("\n"),
            });
        }
    }, [possibleEventsStr]);

    useEffect(() => {
        if (inputData != null) {
            setImgSrc(encodeStateMachine());
        }
    }, [inputData]);

    const handleSaveButtonClick = async () => {
        setIsLoading(true);
        await dispatch(updateFormGroup(inputData));
        toast.success("✌️ Formular-Gruppe aktualisiert", {autoClose: 2000});
        setIsLoading(false);
    };

    const folderStructureTypeOptions = [
        {
            value: "form",
            label: "Formular-Struktur"
        },
        {value: "assignment", label: "Auftrags-Struktur"}];

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    };

    const textEncode = (str) => {
        if (window.TextEncoder) {
            return new TextEncoder().encode(str);
        }
        const utf8 = unescape(encodeURIComponent(str));
        const result = new Uint8Array(utf8.length);
        for (let i = 0; i < utf8.length; i++) {
            result[i] = utf8.charCodeAt(i);
        }
        return result;
    };

    const encodeStateMachine = () => {
        const allWildCardStates = [];
        const allSourceCardStates = [];
        const allTargetCardStates = [];
        let allStates = [];
        inputData.possible_events.forEach((e) => {
            if (e.includes("->")) {
                const x = e.split("->");
                allSourceCardStates.push(x[0]);
                allTargetCardStates.push(x[1]);
                allStates.push(x[0]);
                allStates.push(x[1]);
            } else {
                allWildCardStates.push(e);
                allStates.push(e);
            }
        });
        allStates = allStates.filter(onlyUnique);
        const diagramSource = `@startuml
title ${inputData.name}
${allStates
            .map((s) => {
                return `state ${s}`;
            })
            .join("\n")}
${allWildCardStates
            .map((s1) => {
                return allWildCardStates
                    .map((s2) => {
                        if (s1 === s2) {
                            return "";
                        }
                        return `${s1} --> ${s2}`;
                    })
                    .join("\n");
            })
            .join("\n")}
@enduml`;
        const compressed = pako.deflate(textEncode(diagramSource), {
            level: 9,
            to: "string",
        });
        const result = btoa(compressed).replace(/\+/g, "-").replace(/\//g, "_");
        return "https://kroki.io/plantuml/svg/" + result;
    };

    return (
        <>
            <PageNavbar
                title="State-Machine bearbeiten"
                description="Hier können sie eine bestehende Formular-Gruppe bearbeiten."
            >
                {inputData && (
                    <input value={inputData.name} onChange={(ev) => {
                        setInputData({...inputData, name: ev.target.value});
                    }}/>
                )}
                <PageNavAction
                    success={true}
                    disabled={isLoading}
                    onClick={() => handleSaveButtonClick()}
                    icon="save"
                    title="Speichern & Abschicken"
                />

                <PageNavAction
                    link="/settings/protocols/state_machines"
                    icon="list"
                    title="Alle State-Machine"
                />
            </PageNavbar>
            {isLoading && <Loader/>}
            {!isLoading && (
                <Row>
                    <Col>
                        <Select
                            options={folderStructureTypeOptions}
                            defaultValue={folderStructureTypeOptions.find((f) => f.value === inputData.folder_structure_type)}
                            onChange={(selected) =>
                                setInputData(
                                    {
                                        ...inputData,
                                        folder_structure_type: selected.value
                                    }
                                )
                            }/>
                        <textarea
                            value={possibleEventsStr}
                            onChange={(ev) => {
                                setPossibleEventsStr(ev.target.value);
                                return false;
                            }}
                            rows={20}
                            cols={60}
                        />
                    </Col>
                    <Col>
                        <img src={imgSrc}/>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default withRouter(EditFormGroupPage);
